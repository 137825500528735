import jscookie from "js-cookie";

export enum Cookies {
  DOMAIN = "DOMAIN",
}

export const getCookie = (cookie: Cookies): string | null => {
  return jscookie.get(cookie) || null;
};

export const setCookie = (cookie: Cookies, value: string): void => {
  jscookie.set(cookie, value);
};
