import { useQuery } from "@tanstack/react-query";
import { Domain } from "../types/types";
import queryTypes from "../types/hookTypes";
import { getUserDomains } from "../services/proxy/proxy";

const useHook = () => {
  return useQuery<Array<Domain>>([queryTypes.userDomains], getUserDomains);
};

export default useHook;
