import client from '../../utils/axiosClient';

export type TQualityOverview = {
  range: Array<{ year: number; month: number }>;
  rows: {
    administrationId: string;
    administrationName: string;
    rangeScore: number;
    scores: {
      [year: number]: { [month: number]: number };
    };
  }[];
};

export const getCompletenessV2 = async (
  yearStart: number,
  yearEnd: number,
  monthStart: number,
  monthEnd: number,
  userId: string | null,
  accountID: string | null,
) => {
  const response = await client.get<TQualityOverview>('/api/completeness', {
    params: {
      yearStart,
      yearEnd,
      monthStart,
      monthEnd,
      userId,
      accountID,
    },
  });
  return response.data;
};
