import MoreVert from '@mui/icons-material/MoreVert';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import { Dropdown, Menu, MenuButton, MenuItem } from '@mui/joy';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageHeader } from '../../components/pageHeader';
import { RangeSelect } from '../../components/rangeSelect';
import Spinner from '../../components/spinner';
import { useNotes } from '../../hooks/notes/useNotes';
import { useAssignedAdministrations } from '../../hooks/useAssignedAdministrations';
import useDivision from '../../hooks/useDivision';
import { useMe } from '../../hooks/useMe';
import { useQualityByDivsion } from '../../hooks/useQualityByDivsion';
import useFilterStore from '../../stores/filter';
import { useTaskStore } from '../../stores/taskStore';
import { TaskUpsertModal } from '../tasks/taskUpsertModal';
import ConfigureChecks from './configureChecks';
import { exportToPDF, transformDataPdf } from './export';
import CompletenessTable from './table';
import { ViewTypeRadioGroup, ViewTypeRadioGroupOptions } from './viewTypeRadioGroup';
import { useRecommendationsByDivision } from '../../hooks/useRecommendationsByDivision';
import Recommendations from './recommendations';

type Props = {
  renderHeader?: boolean;
};

export default function CompletenessDrilldown({ renderHeader = true }: Readonly<Props>) {
  const params = useParams();
  const [configureChecksModalOpen, setConfigureChecksModalOpen] = useState(false);
  const [recommendationsModalOpen, setRecommendationsModalOpen] = useState(false);
  const { divisionId: divisionIdString } = params;
  const divisionId = divisionIdString as string;
  const divisionQuery = useDivision(divisionId);
  const { year, range } = useFilterStore();
  const completenessQuery = useQualityByDivsion(divisionId, range.yearStart, range.yearEnd, range.monthStart + 1, range.monthEnd + 1);
  const notesQuery = useNotes({
    administrationIds: [divisionId],
  });
  const userQuery = useMe();
  const assignedAdministrationsQuery = useAssignedAdministrations();
  const [viewType, setViewType] = useState<ViewTypeRadioGroupOptions>(ViewTypeRadioGroupOptions.checks);
  const taskStore = useTaskStore();

  const onClickCreateTask = () => {
    taskStore.setTaskModalOpen(true);
  };

  const onClickExport = () => {
    // exportToExcel(
    //   transformData(completenessQuery.data),
    //   `kwaliteit ${divisionQuery.data?.Description}`
    // );
  };

  const onClickExportPdf = async () => {
    await notesQuery.refetch();
    if (!notesQuery.isSuccess || !completenessQuery.data) {
      return;
    }
    const notes = notesQuery.data.map((note) => {
      const date = new Date(Number.parseInt(note.createdAt, 10)).toLocaleString('nl-NL').slice(0, 10);
      return `${date}: ${note.text}`;
    });

    exportToPDF(transformDataPdf(completenessQuery.data), `kwaliteit ${divisionQuery.data?.Description}`, completenessQuery.data.range, notes);
  };

  useEffect(() => {
    completenessQuery.refetch();
  }, [configureChecksModalOpen, completenessQuery]);

  if (divisionQuery.isLoading) {
    return <Spinner />;
  }

  const canEdit =
    assignedAdministrationsQuery.data?.some((admin) => admin.AdministrationID === divisionId) ||
    userQuery.data?.Role === 'super' ||
    userQuery.data?.Role === 'admin';

  return (
    <>
      <TaskUpsertModal />
      <Stack direction="column" gap={3}>
        {renderHeader && <PageHeader>{divisionQuery.data?.Description}</PageHeader>}

        <Stack direction={'row'} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Stack direction={'row'} gap={5}>
            <RangeSelect />
            <ViewTypeRadioGroup
              onChange={(newValue) => {
                setViewType(newValue);
              }}
              value={viewType}
            />
          </Stack>
          <Stack direction={'row'} gap={3}>
            <FormControl disabled={!notesQuery.isSuccess}>
              <FormLabel>{''}</FormLabel>
              <Dropdown>
                <MenuButton sx={{ marginTop: '1rem' }} slots={{ root: IconButton }} slotProps={{ root: { variant: 'soft', color: 'neutral' } }}>
                  <Typography sx={{ paddingX: '.75rem' }}>Acties</Typography>
                  <MoreVert />
                </MenuButton>
                <Menu>
                  <MenuItem onClick={onClickCreateTask}>Taak maken</MenuItem>
                  <MenuItem onClick={onClickExportPdf}>Export PDF</MenuItem>
                  <MenuItem onClick={onClickExport} disabled>
                    Export Excel
                  </MenuItem>
                </Menu>
              </Dropdown>
            </FormControl>

            <FormControl>
              <FormLabel>{''}</FormLabel>
              <Button
                endDecorator={<AutoAwesomeRoundedIcon />}
                onClick={() => {
                  setRecommendationsModalOpen(true);
                }}
                disabled={!canEdit}
                sx={{ marginTop: '1rem' }}
                color={'success'}
                variant="soft"
              >
                AI checks
              </Button>
            </FormControl>

            <FormControl>
              <FormLabel>{''}</FormLabel>
              <Button
                variant="soft"
                endDecorator={<SettingsRoundedIcon />}
                onClick={() => {
                  setConfigureChecksModalOpen(true);
                }}
                disabled={!canEdit || !completenessQuery.isSuccess}
                sx={{ marginTop: '1rem' }}
              >
                Checks
              </Button>
            </FormControl>
          </Stack>
        </Stack>

        <CompletenessTable
          viewType={viewType}
          division={divisionId}
          year={year}
          monthEnd={range.monthEnd}
          monthStart={range.monthStart}
          yearStart={range.yearStart}
          yearEnd={range.yearEnd}
        />

        {/* eslint-disable-next-line react/jsx-no-undef */}
        <Recommendations divisionCode={divisionId} open={recommendationsModalOpen} setOpen={setRecommendationsModalOpen} />
        <ConfigureChecks divisionCode={divisionId} open={configureChecksModalOpen} setOpen={setConfigureChecksModalOpen} />
      </Stack>
    </>
  );
}
