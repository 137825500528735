import { useQuery } from "@tanstack/react-query";
import { getBalanceChecksByAdministration } from "../../services/api/insights/getBalanceChecksByAdministration";
import queryTypes from "../../types/hookTypes";

export const useBalanceChecksByAdministration = (administrationId: string) => {
  return useQuery(
    [queryTypes.balanceChecksByAdministration, administrationId],
    () => getBalanceChecksByAdministration(administrationId)
  );
};
