import client from "../../../utils/axiosClient";

export type TBalanceCheck = {
  id: string;
  rgsCode: string;
  lowerBound: number;
  upperBound: number;
  icon: string;
  administrationId: string;
  label: string;
};

export const getBalanceChecksByAdministration = async (
  administrationId: string
) => {
  const response = await client.get(
    `/api/insights/checks/balance/${administrationId}`
  );
  return response.data as TBalanceCheck[];
};
