import { Autocomplete } from "@mui/joy";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import useDivisions from "../hooks/useDivisions";
import useFilterStore from "../stores/filter";

export const DivisionSelect = ({
  onChange,
  disabled = false,
}: {
  onChange?: (division: string) => void;
  disabled?: boolean;
}) => {
  const { selectedDivision, setSelectedDivision } = useFilterStore();
  const divisionsQuery = useDivisions();

  const administrations = divisionsQuery.data || [];

  const options: { key: string; label: string }[] = administrations.map(
    (administration) => {
      return {
        key: administration.Code.toString(),
        label: administration.Description,
      };
    }
  );

  return (
    <FormControl>
      <FormLabel>Administratie</FormLabel>
      <Autocomplete
        disabled={disabled}
        placeholder={"Selecteer een administratie"}
        onChange={(_, value) => {
          setSelectedDivision(value?.key || "");
          if (onChange) {
            onChange(value?.key || "");
          }
        }}
        options={options}
      ></Autocomplete>
    </FormControl>
  );
};
