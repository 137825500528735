import Chip from "@mui/joy/Chip";

type Props = {
  progress: number;
};

export const QualityProgressBar = ({ progress }: Props) => {
  let color = "success";
  if (progress < 100) {
    color = "warning";
  }

  if (progress < 30) {
    color = "danger";
  }

  return (
    <Chip color={color as any} onClick={function () {}} variant="soft">
      {progress}%
    </Chip>
  );
};
