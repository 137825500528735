import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export const ConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  content,
  disabled = false,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  content: string;
  disabled?: boolean;
}) => {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={disabled}>
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus disabled={disabled}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
