import client from "../../../utils/axiosClient";

type IProfitLossResult = {
  amount: number;
  rgsCode: string;
  administrationId: string;
};

export const getPeriodTotal = async (
  administrationId: string,
  rgsCode: string
) => {
  const response = await client.get(
    `/api/dataviz/${administrationId}/profitLoss`,
    {
      params: {
        rgsCode,
      },
    }
  );
  return response.data as IProfitLossResult;
};
