import client from "../../../utils/axiosClient";

type TBalanceChecksOverview = {
  rgsCode: string;
  amount: number;
};

const getBalanceChecks = async () => {
  const response = await client.get("/api/insights/checks/balance");
  return response.data as TBalanceChecksOverview[];
};

export default getBalanceChecks;
