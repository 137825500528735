import * as FileSaver from "file-saver";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable"; // If you get an error, try import { autoTable } from 'jspdf-autotable';
import toast from "react-hot-toast";
import * as XLSX from "xlsx";
import { Range } from "../../components/rangeSelect";
import { decideIfRed } from "./utils";
import { TQualityDrilldown } from "../../services/api/getCompletenessByDivision";
import { formatter } from "../../translations/numberFormatter";

type TIndicator = "X" | "✓";

export type QualitySheet = {
  label: string;
  jaarVolledig: TIndicator;
  jan: TIndicator;
  feb: TIndicator;
  mrt: TIndicator;
  apr: TIndicator;
  mei: TIndicator;
  jun: TIndicator;
  jul: TIndicator;
  aug: TIndicator;
  sep: TIndicator;
  okt: TIndicator;
  nov: TIndicator;
  dec: TIndicator;
}[];

export type TPdfExport = {
  [column: string]: string | number;
};

export const transformData = (jsonData: TPdfExport[]) => {
  const transformedData: QualitySheet = jsonData.map((row: any) => {
    return {
      label: row.key,
      jaarVolledig: row.values.every((value: any) => value) ? "✓" : "X", // Check if all values are true (✓) or false (X
      jan: row.values[0] ? "✓" : "X",
      feb: row.values[1] ? "✓" : "X",
      mrt: row.values[2] ? "✓" : "X",
      apr: row.values[3] ? "✓" : "X",
      mei: row.values[4] ? "✓" : "X",
      jun: row.values[5] ? "✓" : "X",
      jul: row.values[6] ? "✓" : "X",
      aug: row.values[7] ? "✓" : "X",
      sep: row.values[8] ? "✓" : "X",
      okt: row.values[9] ? "✓" : "X",
      nov: row.values[10] ? "✓" : "X",
      dec: row.values[11] ? "✓" : "X",
    };
  });
  return transformedData;
};

export const exportToExcel = (jsonData: any, fileName: string) => {
  try {
    // Convert JSON to worksheet
    const ws = XLSX.utils.json_to_sheet(jsonData);
    // Create a new Workbook
    const wb = XLSX.utils.book_new();
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    // Define binary string to write the workbook
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    // Convert binary string to character sequence
    const buf = new ArrayBuffer(wbout.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < wbout.length; i++) {
      view[i] = wbout.charCodeAt(i) & 0xff;
    }
    // Save to file
    const blob = new Blob([buf], { type: "application/octet-stream" });
    FileSaver.saveAs(blob, fileName + ".xlsx");
    toast.success("Data is geëxporteerd naar Excel");
  } catch (error) {
    console.error(error);
    toast.error("Er is iets misgegaan bij het exporteren naar Excel");
  }
};

export const transformDataPdf = (jsonData: TQualityDrilldown): TPdfExport[] => {
  const output: TPdfExport[] = jsonData.rows.map((row) => {
    const outputRow: { [column: string]: string | number } = {};
    outputRow["label"] = row.qualityCheckId;
    let isComplete = true;

    jsonData.range.forEach(({ month, year }) => {
      const data = row.rows[year][month];
      const formattedTotal = formatter.format(data.periodTotal);
      outputRow[`${month}/${year}`] = formattedTotal;
      outputRow[`${month}/${year}Color`] = data.outlier ? "red" : "black";
      if (!data.periodTotal) isComplete = false;
    });
    // outputRow["isComplete"] = isComplete ? "Ja" : "Nee";
    return outputRow;
  });

  return output;
};

export const exportToPDF = (
  jsonData: TPdfExport[],
  fileName: string,
  range: { year: number; month: number }[],
  stringList: string[] // Add this parameter to accept the list of strings
) => {
  try {
    const doc = new jsPDF("l", "pt", "a4");

    const columns = [
      { header: "Label", dataKey: "label" },
      // { header: "volledig", dataKey: "isComplete" },
      ...range.map(({ month, year }) => ({
        header: `${month}/${year}`,
        dataKey: `${month}/${year}`,
      })),
    ];
    autoTable(doc, {
      columns,
      body: jsonData,
      headStyles: {
        fillColor: [175, 17, 199], // RGB for purple
        textColor: 255, // White text color
        halign: "center", // Center align header text
      },
      columnStyles: {
        label: {
          cellWidth: 100,
          halign: "right", // Align label column to the right
        },
        ...range.reduce((styles, { month, year }) => {
          const colKey = `${month}/${year}`;
          styles[colKey] = { halign: "right" }; // Align all range columns to the right
          return styles;
        }, {} as any),
      },

      styles: {
        fontSize: 8, // Set the font size to be smaller
      },

      didParseCell: function (data) {
        // Apply custom colors based on the color properties in the processed data
        if (
          data.row.section === "body" &&
          data.column.dataKey &&
          data.row.raw
        ) {
          const colorProperty = `${data.column.dataKey}Color`;
          const color = (data.row.raw as any)[colorProperty];
          data.cell.styles.textColor = color;
        }
      },
    });

    // Get the last y-coordinate from the table
    const tableHeight = (doc as any).lastAutoTable.finalY || 0;
    let yPos = tableHeight + 20; // Add some space after the table

    // Add the list of strings below the table
    stringList.forEach((text, index) => {
      doc.text(text, 40, yPos + index * 20); // Adjust the position as needed
    });

    // Save the PDF
    doc.save(`${fileName}.pdf`);
    toast.success("Data is geëxporteerd naar PDF");
  } catch (error) {
    console.error(error);
    toast.error("Er is iets misgegaan bij het exporteren naar PDF");
  }
};
