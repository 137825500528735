import client from "../../utils/axiosClient";

export const assignAdministrationToUser = async (
  userId: string,
  administrationId: string
) => {
  const response = await client.post(
    `/api/users/${userId}/assignAdministration`,
    { administrationId }
  );
  return response.data;
};
