export type TRgsCode = {
  code: string;
  descriptionShort: string;
  descriptionLong: string;
};

export const getRgsCode = (code: string): TRgsCode | undefined => {
  return rgsCodes.find((rgsCode) => rgsCode.code === code);
};

export const rgsCodes: TRgsCode[] = [
  { code: "B", descriptionShort: "BALANS", descriptionLong: "BALANS" },
  {
    code: "BIva",
    descriptionShort: "Immateriële vaste activa",
    descriptionLong: "Immateriële vaste activa",
  },
  {
    code: "BIvaKou",
    descriptionShort: "Kosten van oprichting en van uitgifte van aandelen",
    descriptionLong: "Kosten van oprichting en van uitgifte van aandelen",
  },
  {
    code: "BIvaKouVvp",
    descriptionShort:
      "Verkrijgings- of vervaardigingsprijs kosten van oprichting en van uitgifte van aandelen",
    descriptionLong:
      "Verkrijgings- of vervaardigingsprijs kosten van oprichting en van uitgifte van aandelen",
  },
  {
    code: "BIvaKouVvpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) kosten van oprichting en van uitgifte van aandelen",
  },
  {
    code: "BIvaKouVvpInv",
    descriptionShort: "Investeringen",
    descriptionLong:
      "Investeringen kosten van oprichting en van uitgifte van aandelen",
  },
  {
    code: "BIvaKouVvpAdo",
    descriptionShort: "Bij overname verkregen activa",
    descriptionLong:
      "Bij overname verkregen activa kosten van oprichting en van uitgifte van aandelen",
  },
  {
    code: "BIvaKouVvpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong:
      "Desinvesteringen kosten van oprichting en van uitgifte van aandelen",
  },
  {
    code: "BIvaKouVvpDda",
    descriptionShort: "Afstotingen",
    descriptionLong:
      "Afstotingen kosten van oprichting en van uitgifte van aandelen",
  },
  {
    code: "BIvaKouVvpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong:
      "Omrekeningsverschillen kosten van oprichting en van uitgifte van aandelen",
  },
  {
    code: "BIvaKouVvpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties kosten van oprichting en van uitgifte van aandelen",
  },
  {
    code: "BIvaKouAkp",
    descriptionShort: "Actuele kostprijs",
    descriptionLong:
      "Actuele kostprijs kosten van oprichting en van uitgifte van aandelen",
  },
  {
    code: "BIvaKouAkpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) kosten van oprichting en van uitgifte van aandelen",
  },
  {
    code: "BIvaKouAkpInv",
    descriptionShort: "Investeringen",
    descriptionLong:
      "Investeringen kosten van oprichting en van uitgifte van aandelen",
  },
  {
    code: "BIvaKouAkpAdo",
    descriptionShort: "Bij overname verkregen activa",
    descriptionLong:
      "Bij overname verkregen activa kosten van oprichting en van uitgifte van aandelen",
  },
  {
    code: "BIvaKouAkpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong:
      "Desinvesteringen kosten van oprichting en van uitgifte van aandelen",
  },
  {
    code: "BIvaKouAkpDda",
    descriptionShort: "Afstotingen",
    descriptionLong:
      "Afstotingen kosten van oprichting en van uitgifte van aandelen",
  },
  {
    code: "BIvaKouAkpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong:
      "Omrekeningsverschillen kosten van oprichting en van uitgifte van aandelen",
  },
  {
    code: "BIvaKouAkpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties kosten van oprichting en van uitgifte van aandelen",
  },
  {
    code: "BIvaKouCae",
    descriptionShort: "Cumulatieve afschrijvingen en waardeverminderingen",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen kosten van oprichting en van uitgifte van aandelen",
  },
  {
    code: "BIvaKouCaeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) kosten van oprichting en van uitgifte van aandelen",
  },
  {
    code: "BIvaKouCaeAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong:
      "Afschrijvingen kosten van oprichting en van uitgifte van aandelen",
  },
  {
    code: "BIvaKouCaeDca",
    descriptionShort: "Afschrijving op desinvesteringen",
    descriptionLong:
      "Afschrijving op desinvesteringen kosten van oprichting en van uitgifte van aandelen",
  },
  {
    code: "BIvaKouCaeWvr",
    descriptionShort: "Bijzondere waardeverminderingen",
    descriptionLong:
      "Bijzondere waardeverminderingen kosten van oprichting en van uitgifte van aandelen",
  },
  {
    code: "BIvaKouCaeTvw",
    descriptionShort: "Terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen kosten van oprichting en van uitgifte van aandelen",
  },
  {
    code: "BIvaKouCuh",
    descriptionShort: "Cumulatieve herwaarderingen",
    descriptionLong:
      "Cumulatieve herwaarderingen kosten van oprichting en van uitgifte van aandelen",
  },
  {
    code: "BIvaKouCuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) kosten van oprichting en van uitgifte van aandelen",
  },
  {
    code: "BIvaKouCuhHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong:
      "Herwaarderingen kosten van oprichting en van uitgifte van aandelen",
  },
  {
    code: "BIvaKouCuhAfh",
    descriptionShort: "Afschrijving herwaarderingen",
    descriptionLong:
      "Afschrijving herwaarderingen kosten van oprichting en van uitgifte van aandelen",
  },
  {
    code: "BIvaKouCuhDeh",
    descriptionShort: "Desinvestering herwaarderingen",
    descriptionLong:
      "Desinvestering herwaarderingen kosten van oprichting en van uitgifte van aandelen",
  },
  {
    code: "BIvaKoo",
    descriptionShort: "Kosten van ontwikkeling",
    descriptionLong: "Kosten van ontwikkeling",
  },
  {
    code: "BIvaKooVvp",
    descriptionShort: "Verkrijgings- of vervaardigingsprijs",
    descriptionLong:
      "Verkrijgings- of vervaardigingsprijs kosten van ontwikkeling",
  },
  {
    code: "BIvaKooVvpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) kosten van ontwikkeling",
  },
  {
    code: "BIvaKooVvpInv",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen kosten van ontwikkeling",
  },
  {
    code: "BIvaKooVvpAdo",
    descriptionShort: "Bij overname verkregen activa",
    descriptionLong: "Bij overname verkregen activa kosten van ontwikkeling",
  },
  {
    code: "BIvaKooVvpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen kosten van ontwikkeling",
  },
  {
    code: "BIvaKooVvpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen kosten van ontwikkeling",
  },
  {
    code: "BIvaKooVvpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen kosten van ontwikkeling",
  },
  {
    code: "BIvaKooVvpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties kosten van ontwikkeling",
  },
  {
    code: "BIvaKooAkp",
    descriptionShort: "Actuele kostprijs",
    descriptionLong: "Actuele kostprijs kosten van ontwikkeling",
  },
  {
    code: "BIvaKooAkpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) kosten van ontwikkeling",
  },
  {
    code: "BIvaKooAkpInv",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen kosten van ontwikkeling",
  },
  {
    code: "BIvaKooAkpAdo",
    descriptionShort: "Bij overname verkregen activa",
    descriptionLong: "Bij overname verkregen activa kosten van ontwikkeling",
  },
  {
    code: "BIvaKooAkpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen kosten van ontwikkeling",
  },
  {
    code: "BIvaKooAkpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen kosten van ontwikkeling",
  },
  {
    code: "BIvaKooAkpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen kosten van ontwikkeling",
  },
  {
    code: "BIvaKooAkpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties kosten van ontwikkeling",
  },
  {
    code: "BIvaKooCae",
    descriptionShort: "Cumulatieve afschrijvingen en waardeverminderingen",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen kosten van ontwikkeling",
  },
  {
    code: "BIvaKooCaeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) kosten van ontwikkeling",
  },
  {
    code: "BIvaKooCaeAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong: "Afschrijvingen kosten van ontwikkeling",
  },
  {
    code: "BIvaKooCaeDca",
    descriptionShort: "Afschrijving op desinvesteringen",
    descriptionLong: "Afschrijving op desinvesteringen kosten van ontwikkeling",
  },
  {
    code: "BIvaKooCaeWvr",
    descriptionShort: "Bijzondere waardeverminderingen",
    descriptionLong: "Bijzondere waardeverminderingen kosten van ontwikkeling",
  },
  {
    code: "BIvaKooCaeTvw",
    descriptionShort: "Terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen kosten van ontwikkeling",
  },
  {
    code: "BIvaKooCuh",
    descriptionShort: "Cumulatieve herwaarderingen",
    descriptionLong: "Cumulatieve herwaarderingen kosten van ontwikkeling",
  },
  {
    code: "BIvaKooCuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) kosten van ontwikkeling",
  },
  {
    code: "BIvaKooCuhHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong: "Herwaarderingen kosten van ontwikkeling",
  },
  {
    code: "BIvaKooCuhAfh",
    descriptionShort: "Afschrijving herwaarderingen",
    descriptionLong: "Afschrijving herwaarderingen kosten van ontwikkeling",
  },
  {
    code: "BIvaKooCuhDeh",
    descriptionShort: "Desinvestering herwaarderingen",
    descriptionLong: "Desinvestering herwaarderingen kosten van ontwikkeling",
  },
  {
    code: "BIvaCev",
    descriptionShort: "Concessies, vergunningen en intellectuele eigendom",
    descriptionLong: "Concessies, vergunningen en intellectuele eigendom",
  },
  {
    code: "BIvaCevVvp",
    descriptionShort: "Verkrijgings- of vervaardigingsprijs",
    descriptionLong:
      "Verkrijgings- of vervaardigingsprijs concessies, vergunningen en intellectuele eigendom",
  },
  {
    code: "BIvaCevVvpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) concessies, vergunningen en intellectuele eigendom",
  },
  {
    code: "BIvaCevVvpInv",
    descriptionShort: "Investeringen",
    descriptionLong:
      "Investeringen concessies, vergunningen en intellectuele eigendom",
  },
  {
    code: "BIvaCevVvpAdo",
    descriptionShort: "Bij overname verkregen activa",
    descriptionLong:
      "Bij overname verkregen activa concessies, vergunningen en intellectuele eigendom",
  },
  {
    code: "BIvaCevVvpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong:
      "Desinvesteringen concessies, vergunningen en intellectuele eigendom",
  },
  {
    code: "BIvaCevVvpDda",
    descriptionShort: "Afstotingen",
    descriptionLong:
      "Afstotingen concessies, vergunningen en intellectuele eigendom",
  },
  {
    code: "BIvaCevVvpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong:
      "Omrekeningsverschillen concessies, vergunningen en intellectuele eigendom",
  },
  {
    code: "BIvaCevVvpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties concessies, vergunningen en intellectuele eigendom",
  },
  {
    code: "BIvaCevAkp",
    descriptionShort: "Actuele kostprijs",
    descriptionLong:
      "Actuele kostprijs concessies, vergunningen en intellectuele eigendom",
  },
  {
    code: "BIvaCevAkpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) concessies, vergunningen en intellectuele eigendom",
  },
  {
    code: "BIvaCevAkpInv",
    descriptionShort: "Investeringen",
    descriptionLong:
      "Investeringen concessies, vergunningen en intellectuele eigendom",
  },
  {
    code: "BIvaCevAkpAdo",
    descriptionShort: "Bij overname verkregen activa",
    descriptionLong:
      "Bij overname verkregen activa concessies, vergunningen en intellectuele eigendom",
  },
  {
    code: "BIvaCevAkpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong:
      "Desinvesteringen concessies, vergunningen en intellectuele eigendom",
  },
  {
    code: "BIvaCevAkpDda",
    descriptionShort: "Afstotingen",
    descriptionLong:
      "Afstotingen concessies, vergunningen en intellectuele eigendom",
  },
  {
    code: "BIvaCevAkpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong:
      "Omrekeningsverschillen concessies, vergunningen en intellectuele eigendom",
  },
  {
    code: "BIvaCevAkpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties concessies, vergunningen en intellectuele eigendom",
  },
  {
    code: "BIvaCevCae",
    descriptionShort: "Cumulatieve afschrijvingen en waardeverminderingen",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen concessies, vergunningen en intellectuele eigendom",
  },
  {
    code: "BIvaCevCaeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) concessies, vergunningen en intellectuele eigendom",
  },
  {
    code: "BIvaCevCaeAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong:
      "Afschrijvingen concessies, vergunningen en intellectuele eigendom",
  },
  {
    code: "BIvaCevCaeDca",
    descriptionShort: "Afschrijving op desinvesteringen",
    descriptionLong:
      "Afschrijving op desinvesteringen concessies, vergunningen en intellectuele eigendom",
  },
  {
    code: "BIvaCevCaeWvr",
    descriptionShort: "Bijzondere waardeverminderingen",
    descriptionLong:
      "Bijzondere waardeverminderingen concessies, vergunningen en intellectuele eigendom",
  },
  {
    code: "BIvaCevCaeTvw",
    descriptionShort: "Terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen concessies, vergunningen en intellectuele eigendom",
  },
  {
    code: "BIvaCevCuh",
    descriptionShort: "Cumulatieve herwaarderingen",
    descriptionLong:
      "Cumulatieve herwaarderingen concessies, vergunningen en intellectuele eigendom",
  },
  {
    code: "BIvaCevCuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) concessies, vergunningen en intellectuele eigendom",
  },
  {
    code: "BIvaCevCuhHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong:
      "Herwaarderingen concessies, vergunningen en intellectuele eigendom",
  },
  {
    code: "BIvaCevCuhAfh",
    descriptionShort: "Afschrijving herwaarderingen",
    descriptionLong:
      "Afschrijving herwaarderingen concessies, vergunningen en intellectuele eigendom",
  },
  {
    code: "BIvaCevCuhDeh",
    descriptionShort: "Desinvestering herwaarderingen",
    descriptionLong:
      "Desinvestering herwaarderingen concessies, vergunningen en intellectuele eigendom",
  },
  {
    code: "BIvaGoo",
    descriptionShort: "Goodwill",
    descriptionLong: "Goodwill",
  },
  {
    code: "BIvaGooVvp",
    descriptionShort: "Verkrijgings- of vervaardigingsprijs",
    descriptionLong: "Verkrijgings- of vervaardigingsprijs goodwill",
  },
  {
    code: "BIvaGooVvpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) goodwill",
  },
  {
    code: "BIvaGooVvpInv",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen goodwill",
  },
  {
    code: "BIvaGooVvpAdo",
    descriptionShort: "Bij overname verkregen activa",
    descriptionLong: "Bij overname verkregen activa goodwill",
  },
  {
    code: "BIvaGooVvpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen goodwill",
  },
  {
    code: "BIvaGooVvpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen goodwill",
  },
  {
    code: "BIvaGooVvpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen goodwill",
  },
  {
    code: "BIvaGooVvpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties goodwill",
  },
  {
    code: "BIvaGooAkp",
    descriptionShort: "Actuele kostprijs",
    descriptionLong: "Actuele kostprijs goodwill",
  },
  {
    code: "BIvaGooAkpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) goodwill",
  },
  {
    code: "BIvaGooAkpInv",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen goodwill",
  },
  {
    code: "BIvaGooAkpAdo",
    descriptionShort: "Bij overname verkregen activa",
    descriptionLong: "Bij overname verkregen activa goodwill",
  },
  {
    code: "BIvaGooAkpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen goodwill",
  },
  {
    code: "BIvaGooAkpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen goodwill",
  },
  {
    code: "BIvaGooAkpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen goodwill",
  },
  {
    code: "BIvaGooAkpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties goodwill",
  },
  {
    code: "BIvaGooCae",
    descriptionShort: "Cumulatieve afschrijvingen en waardeverminderingen",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen goodwill",
  },
  {
    code: "BIvaGooCaeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) goodwill",
  },
  {
    code: "BIvaGooCaeAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong: "Afschrijvingen goodwill",
  },
  {
    code: "BIvaGooCaeDca",
    descriptionShort: "Afschrijving op desinvesteringen",
    descriptionLong: "Afschrijving op desinvesteringen goodwill",
  },
  {
    code: "BIvaGooCaeWvr",
    descriptionShort: "Bijzondere waardeverminderingen",
    descriptionLong: "Bijzondere waardeverminderingen goodwill",
  },
  {
    code: "BIvaGooCaeTvw",
    descriptionShort: "Terugneming van bijzondere waardeverminderingen",
    descriptionLong: "Terugneming van bijzondere waardeverminderingen goodwill",
  },
  {
    code: "BIvaGooCuh",
    descriptionShort: "Cumulatieve herwaarderingen",
    descriptionLong: "Cumulatieve herwaarderingen goodwill",
  },
  {
    code: "BIvaGooCuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) goodwill",
  },
  {
    code: "BIvaGooCuhHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong: "Herwaarderingen goodwill",
  },
  {
    code: "BIvaGooCuhAfh",
    descriptionShort: "Afschrijving herwaarderingen",
    descriptionLong: "Afschrijving herwaarderingen concessies, goodwill",
  },
  {
    code: "BIvaGooCuhAvg",
    descriptionShort:
      "Aanpassingen van de goodwill als gevolg van later geïdentificeerde activa en passiva en veranderingen in de waarde ervan",
    descriptionLong:
      "Aanpassingen van de goodwill als gevolg van later geïdentificeerde activa en passiva en veranderingen in de waarde ervan",
  },
  {
    code: "BIvaGooCuhDeh",
    descriptionShort: "Desinvestering herwaarderingen",
    descriptionLong: "Desinvestering herwaarderingen goodwill",
  },
  {
    code: "BIvaVoi",
    descriptionShort: "Vooruitbetalingen op immateriële vaste activa",
    descriptionLong: "Vooruitbetalingen op immateriële vaste activa",
  },
  {
    code: "BIvaVoiVvp",
    descriptionShort: "Verkrijgings- of vervaardigingsprijs",
    descriptionLong:
      "Verkrijgings- of vervaardigingsprijs vooruitbetalingen op immateriële vaste activa",
  },
  {
    code: "BIvaVoiVvpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vooruitbetalingen op immateriële vaste activa",
  },
  {
    code: "BIvaVoiVvpInv",
    descriptionShort: "Investeringen",
    descriptionLong:
      "Investeringen vooruitbetalingen op immateriële vaste activa",
  },
  {
    code: "BIvaVoiVvpAdo",
    descriptionShort: "Bij overname verkregen activa",
    descriptionLong:
      "Bij overname verkregen activa vooruitbetalingen op immateriële vaste activa",
  },
  {
    code: "BIvaVoiVvpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong:
      "Desinvesteringen vooruitbetalingen op immateriële vaste activa",
  },
  {
    code: "BIvaVoiVvpDda",
    descriptionShort: "Afstotingen",
    descriptionLong:
      "Afstotingen vooruitbetalingen op immateriële vaste activa",
  },
  {
    code: "BIvaVoiVvpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong:
      "Omrekeningsverschillen vooruitbetalingen op immateriële vaste activa",
  },
  {
    code: "BIvaVoiVvpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties vooruitbetalingen op immateriële vaste activa",
  },
  {
    code: "BIvaVoiAkp",
    descriptionShort: "Actuele kostprijs",
    descriptionLong:
      "Actuele kostprijs vooruitbetalingen op immateriële vaste activa",
  },
  {
    code: "BIvaVoiAkpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vooruitbetalingen op immateriële vaste activa",
  },
  {
    code: "BIvaVoiAkpInv",
    descriptionShort: "Investeringen",
    descriptionLong:
      "Investeringen vooruitbetalingen op immateriële vaste activa",
  },
  {
    code: "BIvaVoiAkpAdo",
    descriptionShort: "Bij overname verkregen activa",
    descriptionLong:
      "Bij overname verkregen activa vooruitbetalingen op immateriële vaste activa",
  },
  {
    code: "BIvaVoiAkpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong:
      "Desinvesteringen vooruitbetalingen op immateriële vaste activa",
  },
  {
    code: "BIvaVoiAkpDda",
    descriptionShort: "Afstotingen",
    descriptionLong:
      "Afstotingen vooruitbetalingen op immateriële vaste activa",
  },
  {
    code: "BIvaVoiAkpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong:
      "Omrekeningsverschillen vooruitbetalingen op immateriële vaste activa",
  },
  {
    code: "BIvaVoiAkpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties vooruitbetalingen op immateriële vaste activa",
  },
  {
    code: "BIvaVoiCae",
    descriptionShort: "Cumulatieve afschrijvingen en waardeverminderingen",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen vooruitbetalingen op immateriële vaste activa",
  },
  {
    code: "BIvaVoiCaeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vooruitbetalingen op immateriële vaste activa",
  },
  {
    code: "BIvaVoiCaeAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong:
      "Afschrijvingen vooruitbetalingen op immateriële vaste activa",
  },
  {
    code: "BIvaVoiCaeDca",
    descriptionShort: "Afschrijving op desinvesteringen",
    descriptionLong:
      "Afschrijving op desinvesteringen vooruitbetalingen op immateriële vaste activa",
  },
  {
    code: "BIvaVoiCaeWvr",
    descriptionShort: "Bijzondere waardeverminderingen",
    descriptionLong:
      "Bijzondere waardeverminderingen vooruitbetalingen op immateriële vaste activa",
  },
  {
    code: "BIvaVoiCaeTvw",
    descriptionShort: "Terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen vooruitbetalingen op immateriële vaste activa",
  },
  {
    code: "BIvaVoiCuh",
    descriptionShort: "Cumulatieve herwaarderingen",
    descriptionLong:
      "Cumulatieve herwaarderingen vooruitbetalingen op immateriële vaste activa",
  },
  {
    code: "BIvaVoiCuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vooruitbetalingen op immateriële vaste activa",
  },
  {
    code: "BIvaVoiCuhHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong:
      "Herwaarderingen vooruitbetalingen op immateriële vaste activa",
  },
  {
    code: "BIvaVoiCuhAfh",
    descriptionShort: "Afschrijving herwaarderingen",
    descriptionLong:
      "Afschrijving herwaarderingen vooruitbetalingen op immateriële vaste activa",
  },
  {
    code: "BIvaVoiCuhDeh",
    descriptionShort: "Desinvestering herwaarderingen",
    descriptionLong:
      "Desinvestering herwaarderingen vooruitbetalingen op immateriële vaste activa",
  },
  {
    code: "BIvaBou",
    descriptionShort: "Bouwclaims",
    descriptionLong: "Bouwclaims",
  },
  {
    code: "BIvaBouVvp",
    descriptionShort: "Verkrijgings- of vervaardigingsprijs bouwclaims",
    descriptionLong: "Verkrijgings- of vervaardigingsprijs bouwclaims",
  },
  {
    code: "BIvaBouVvpBeg",
    descriptionShort: "Beginbalans bouwclaims",
    descriptionLong: "Beginbalans bouwclaims",
  },
  {
    code: "BIvaBouVvpInv",
    descriptionShort: "Investeringen bouwclaims",
    descriptionLong: "Investeringen bouwclaims",
  },
  {
    code: "BIvaBouVvpAdo",
    descriptionShort: "Aankopen door overnames bouwclaims",
    descriptionLong: "Aankopen door overnames bouwclaims",
  },
  {
    code: "BIvaBouVvpDes",
    descriptionShort: "Desinvesteringen bouwclaims",
    descriptionLong: "Desinvesteringen bouwclaims",
  },
  {
    code: "BIvaBouVvpDda",
    descriptionShort: "Desinvesteringen door afstotingen bouwclaims",
    descriptionLong: "Desinvesteringen door afstotingen bouwclaims",
  },
  {
    code: "BIvaBouVvpOmv",
    descriptionShort: "Omrekeningsverschillen bouwclaims",
    descriptionLong: "Omrekeningsverschillen bouwclaims",
  },
  {
    code: "BIvaBouVvpOvm",
    descriptionShort: "Overige mutaties bouwclaims",
    descriptionLong: "Overige mutaties bouwclaims",
  },
  {
    code: "BIvaBouAkp",
    descriptionShort: "Actuele kostprijs",
    descriptionLong: "Actuele kostprijs bouwclaims",
  },
  {
    code: "BIvaBouAkpBeg",
    descriptionShort: "Beginbalans bouwclaims",
    descriptionLong: "Beginbalans bouwclaims",
  },
  {
    code: "BIvaBouAkpInv",
    descriptionShort: "Investeringen bouwclaims",
    descriptionLong: "Investeringen bouwclaims",
  },
  {
    code: "BIvaBouAkpAdo",
    descriptionShort: "Aankopen door overnames bouwclaims",
    descriptionLong: "Aankopen door overnames bouwclaims",
  },
  {
    code: "BIvaBouAkpDes",
    descriptionShort: "Desinvesteringen bouwclaims",
    descriptionLong: "Desinvesteringen bouwclaims",
  },
  {
    code: "BIvaBouAkpDda",
    descriptionShort: "Desinvesteringen door afstotingen bouwclaims",
    descriptionLong: "Desinvesteringen door afstotingen bouwclaims",
  },
  {
    code: "BIvaBouAkpOmv",
    descriptionShort: "Omrekeningsverschillen bouwclaims",
    descriptionLong: "Omrekeningsverschillen bouwclaims",
  },
  {
    code: "BIvaBouAkpOvm",
    descriptionShort: "Overige mutaties bouwclaims",
    descriptionLong: "Overige mutaties bouwclaims",
  },
  {
    code: "BIvaBouCae",
    descriptionShort:
      "Cumulatieve afschrijvingen en waardeverminderingen bouwclaims",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen bouwclaims",
  },
  {
    code: "BIvaBouCaeBeg",
    descriptionShort: "Beginbalans bouwclaims",
    descriptionLong: "Beginbalans bouwclaims",
  },
  {
    code: "BIvaBouCaeAfs",
    descriptionShort: "Afschrijvingen bouwclaims",
    descriptionLong: "Afschrijvingen bouwclaims",
  },
  {
    code: "BIvaBouCaeDca",
    descriptionShort:
      "Desinvestering cumulatieve afschrijvingen en waardeverminderingen bouwclaims",
    descriptionLong:
      "Desinvestering cumulatieve afschrijvingen en waardeverminderingen bouwclaims",
  },
  {
    code: "BIvaBouCaeWvr",
    descriptionShort: "Waardeverminderingen bouwclaims",
    descriptionLong: "Waardeverminderingen bouwclaims",
  },
  {
    code: "BIvaBouCaeTvw",
    descriptionShort: "Terugneming van waardeverminderingen bouwclaims",
    descriptionLong: "Terugneming van waardeverminderingen bouwclaims",
  },
  {
    code: "BIvaBouCuh",
    descriptionShort: "Cumulatieve herwaarderingen bouwclaims",
    descriptionLong: "Cumulatieve herwaarderingen bouwclaims",
  },
  {
    code: "BIvaBouCuhBeg",
    descriptionShort: "Beginbalans bouwclaims",
    descriptionLong: "Beginbalans bouwclaims",
  },
  {
    code: "BIvaBouCuhHer",
    descriptionShort: "Herwaarderingen bouwclaims",
    descriptionLong: "Herwaarderingen bouwclaims",
  },
  {
    code: "BIvaBouCuhAfh",
    descriptionShort: "Afschrijving herwaarderingen bouwclaims",
    descriptionLong: "Afschrijving herwaarderingen bouwclaims",
  },
  {
    code: "BIvaBouCuhDeh",
    descriptionShort: "Desinvestering herwaarderingen bouwclaims",
    descriptionLong: "Desinvestering herwaarderingen bouwclaims",
  },
  {
    code: "BIvaOiv",
    descriptionShort: "Overige immateriële vaste activa",
    descriptionLong: "Overige immateriële vaste activa",
  },
  {
    code: "BIvaOivVvp",
    descriptionShort: "Verkrijgings- of vervaardigingsprijs",
    descriptionLong:
      "Verkrijgings- of vervaardigingsprijs overige immateriële vaste activa",
  },
  {
    code: "BIvaOivVvpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) overige immateriële vaste activa",
  },
  {
    code: "BIvaOivVvpInv",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen overige immateriële vaste activa",
  },
  {
    code: "BIvaOivVvpAdo",
    descriptionShort: "Bij overname verkregen activa",
    descriptionLong:
      "Bij overname verkregen activa overige immateriële vaste activa",
  },
  {
    code: "BIvaOivVvpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen overige immateriële vaste activa",
  },
  {
    code: "BIvaOivVvpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen overige immateriële vaste activa",
  },
  {
    code: "BIvaOivVvpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen overige immateriële vaste activa",
  },
  {
    code: "BIvaOivVvpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties overige immateriële vaste activa",
  },
  {
    code: "BIvaOivAkp",
    descriptionShort: "Actuele kostprijs",
    descriptionLong: "Actuele kostprijs overige immateriële vaste activa",
  },
  {
    code: "BIvaOivAkpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) overige immateriële vaste activa",
  },
  {
    code: "BIvaOivAkpInv",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen overige immateriële vaste activa",
  },
  {
    code: "BIvaOivAkpAdo",
    descriptionShort: "Bij overname verkregen activa",
    descriptionLong:
      "Bij overname verkregen activa overige immateriële vaste activa",
  },
  {
    code: "BIvaOivAkpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen overige immateriële vaste activa",
  },
  {
    code: "BIvaOivAkpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen overige immateriële vaste activa",
  },
  {
    code: "BIvaOivAkpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen overige immateriële vaste activa",
  },
  {
    code: "BIvaOivAkpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties overige immateriële vaste activa",
  },
  {
    code: "BIvaOivCae",
    descriptionShort: "Cumulatieve afschrijvingen en waardeverminderingen",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen overige immateriële vaste activa",
  },
  {
    code: "BIvaOivCaeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) overige immateriële vaste activa",
  },
  {
    code: "BIvaOivCaeAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong: "Afschrijvingen overige immateriële vaste activa",
  },
  {
    code: "BIvaOivCaeDca",
    descriptionShort: "Afschrijving op desinvesteringen",
    descriptionLong:
      "Afschrijving op desinvesteringen overige immateriële vaste activa",
  },
  {
    code: "BIvaOivCaeWvr",
    descriptionShort: "Bijzondere waardeverminderingen",
    descriptionLong:
      "Bijzondere waardeverminderingen overige immateriële vaste activa",
  },
  {
    code: "BIvaOivCaeTvw",
    descriptionShort: "Terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen overige immateriële vaste activa",
  },
  {
    code: "BIvaOivCuh",
    descriptionShort: "Cumulatieve herwaarderingen",
    descriptionLong:
      "Cumulatieve herwaarderingen overige immateriële vaste activa",
  },
  {
    code: "BIvaOivCuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) overige immateriële vaste activa",
  },
  {
    code: "BIvaOivCuhHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong: "Herwaarderingen overige immateriële vaste activa",
  },
  {
    code: "BIvaOivCuhAfh",
    descriptionShort: "Afschrijving herwaarderingen",
    descriptionLong:
      "Afschrijving herwaarderingen overige immateriële vaste activa",
  },
  {
    code: "BIvaOivCuhDeh",
    descriptionShort: "Desinvestering herwaarderingen",
    descriptionLong:
      "Desinvestering herwaarderingen overige immateriële vaste activa",
  },
  {
    code: "BMva",
    descriptionShort: "Materiële vaste activa",
    descriptionLong: "Materiële vaste activa",
  },
  {
    code: "BMvaTer",
    descriptionShort: "Terreinen",
    descriptionLong: "Terreinen",
  },
  {
    code: "BMvaTerVvp",
    descriptionShort: "Verkrijgings- of vervaardigingsprijs",
    descriptionLong: "Verkrijgings- of vervaardigingsprijs terreinen",
  },
  {
    code: "BMvaTerVvpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) terreinen",
  },
  {
    code: "BMvaTerVvpIna",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen terreinen",
  },
  {
    code: "BMvaTerVvpAdo",
    descriptionShort: "Verwervingen via fusies en overnames",
    descriptionLong: "Verwervingen via fusies en overnames terreinen",
  },
  {
    code: "BMvaTerVvpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen terreinen",
  },
  {
    code: "BMvaTerVvpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen terreinen",
  },
  {
    code: "BMvaTerVvpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen terreinen",
  },
  {
    code: "BMvaTerVvpOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen terreinen",
  },
  {
    code: "BMvaTerVvpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties terreinen",
  },
  {
    code: "BMvaTerAkp",
    descriptionShort: "Actuele kostprijs",
    descriptionLong: "Actuele kostprijs terreinen",
  },
  {
    code: "BMvaTerAkpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) terreinen",
  },
  {
    code: "BMvaTerAkpIna",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen terreinen",
  },
  {
    code: "BMvaTerAkpAdo",
    descriptionShort: "Verwervingen via fusies en overnames",
    descriptionLong: "Verwervingen via fusies en overnames terreinen",
  },
  {
    code: "BMvaTerAkpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen terreinen",
  },
  {
    code: "BMvaTerAkpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen terreinen",
  },
  {
    code: "BMvaTerAkpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen terreinen",
  },
  {
    code: "BMvaTerAkpOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen terreinen",
  },
  {
    code: "BMvaTerAkpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties terreinen",
  },
  {
    code: "BMvaTerCae",
    descriptionShort: "Cumulatieve afschrijvingen en waardeverminderingen",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen terreinen",
  },
  {
    code: "BMvaTerCaeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) terreinen",
  },
  {
    code: "BMvaTerCaeAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong: "Afschrijvingen terreinen",
  },
  {
    code: "BMvaTerCaeDca",
    descriptionShort: "Afschrijving op desinvesteringen",
    descriptionLong: "Afschrijving op desinvesteringen terreinen",
  },
  {
    code: "BMvaTerCaeWvr",
    descriptionShort: "Bijzondere waardeverminderingen",
    descriptionLong: "Bijzondere waardeverminderingen terreinen",
  },
  {
    code: "BMvaTerCaeTvw",
    descriptionShort: "Terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen terreinen",
  },
  {
    code: "BMvaTerCuh",
    descriptionShort: "Cumulatieve herwaarderingen",
    descriptionLong: "Cumulatieve herwaarderingen terreinen",
  },
  {
    code: "BMvaTerCuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) terreinen",
  },
  {
    code: "BMvaTerCuhHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong: "Herwaarderingen terreinen",
  },
  {
    code: "BMvaTerCuhAfh",
    descriptionShort: "Afschrijving herwaarderingen",
    descriptionLong: "Afschrijving herwaarderingen terreinen",
  },
  {
    code: "BMvaTerCuhDeh",
    descriptionShort: "Desinvestering herwaarderingen",
    descriptionLong: "Desinvestering herwaarderingen terreinen",
  },
  {
    code: "BMvaBeg",
    descriptionShort: "Bedrijfsgebouwen",
    descriptionLong: "Bedrijfsgebouwen",
  },
  {
    code: "BMvaBegVvp",
    descriptionShort: "Verkrijgings- of vervaardigingsprijs",
    descriptionLong: "Verkrijgings- of vervaardigingsprijs bedrijfsgebouwen",
  },
  {
    code: "BMvaBegVvpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) bedrijfsgebouwen",
  },
  {
    code: "BMvaBegVvpLie",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen bedrijfsgebouwen",
  },
  {
    code: "BMvaBegVvpAdo",
    descriptionShort: "Verwervingen via fusies en overnames",
    descriptionLong: "Verwervingen via fusies en overnames bedrijfsgebouwen",
  },
  {
    code: "BMvaBegVvpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen bedrijfsgebouwen",
  },
  {
    code: "BMvaBegVvpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen bedrijfsgebouwen",
  },
  {
    code: "BMvaBegVvpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen bedrijfsgebouwen",
  },
  {
    code: "BMvaBegVvpOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen bedrijfsgebouwen",
  },
  {
    code: "BMvaBegVvpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties bedrijfsgebouwen",
  },
  {
    code: "BMvaBegAkp",
    descriptionShort: "Actuele kostprijs",
    descriptionLong: "Actuele kostprijs bedrijfsgebouwen",
  },
  {
    code: "BMvaBegAkpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) bedrijfsgebouwen",
  },
  {
    code: "BMvaBegAkpLie",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen bedrijfsgebouwen",
  },
  {
    code: "BMvaBegAkpAdo",
    descriptionShort: "Verwervingen via fusies en overnames",
    descriptionLong: "Verwervingen via fusies en overnames bedrijfsgebouwen",
  },
  {
    code: "BMvaBegAkpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen bedrijfsgebouwen",
  },
  {
    code: "BMvaBegAkpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen bedrijfsgebouwen",
  },
  {
    code: "BMvaBegAkpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen bedrijfsgebouwen",
  },
  {
    code: "BMvaBegAkpOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen bedrijfsgebouwen",
  },
  {
    code: "BMvaBegAkpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties bedrijfsgebouwen",
  },
  {
    code: "BMvaBegCae",
    descriptionShort: "Cumulatieve afschrijvingen en waardeverminderingen",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen bedrijfsgebouwen",
  },
  {
    code: "BMvaBegCaeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) bedrijfsgebouwen",
  },
  {
    code: "BMvaBegCaeAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong: "Afschrijvingen bedrijfsgebouwen",
  },
  {
    code: "BMvaBegCaeDca",
    descriptionShort: "Afschrijving op desinvesteringen",
    descriptionLong: "Afschrijving op desinvesteringen bedrijfsgebouwen",
  },
  {
    code: "BMvaBegCaeWvr",
    descriptionShort: "Bijzondere waardeverminderingen",
    descriptionLong: "Bijzondere waardeverminderingen bedrijfsgebouwen",
  },
  {
    code: "BMvaBegCaeTvw",
    descriptionShort: "Terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen bedrijfsgebouwen",
  },
  {
    code: "BMvaBegCuh",
    descriptionShort: "Cumulatieve herwaarderingen",
    descriptionLong: "Cumulatieve herwaarderingen bedrijfsgebouwen",
  },
  {
    code: "BMvaBegCuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) bedrijfsgebouwen",
  },
  {
    code: "BMvaBegCuhHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong: "Herwaarderingen bedrijfsgebouwen",
  },
  {
    code: "BMvaBegCuhAfh",
    descriptionShort: "Afschrijving herwaarderingen",
    descriptionLong: "Afschrijving herwaarderingen bedrijfsgebouwen",
  },
  {
    code: "BMvaBegCuhDeh",
    descriptionShort: "Desinvestering herwaarderingen",
    descriptionLong: "Desinvestering herwaarderingen bedrijfsgebouwen",
  },
  {
    code: "BMvaVer",
    descriptionShort: "Verbouwingen",
    descriptionLong: "Verbouwingen",
  },
  {
    code: "BMvaVerVvp",
    descriptionShort: "Verkrijgings- of vervaardigingsprijs",
    descriptionLong: "Verkrijgings- of vervaardigingsprijs verbouwingen",
  },
  {
    code: "BMvaVerVvpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) verbouwingen",
  },
  {
    code: "BMvaVerVvpIna",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen verbouwingen",
  },
  {
    code: "BMvaVerVvpAdo",
    descriptionShort: "Verwervingen via fusies en overnames",
    descriptionLong: "Verwervingen via fusies en overnames verbouwingen",
  },
  {
    code: "BMvaVerVvpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen verbouwingen",
  },
  {
    code: "BMvaVerVvpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen verbouwingen",
  },
  {
    code: "BMvaVerVvpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen verbouwingen",
  },
  {
    code: "BMvaVerVvpOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen verbouwingen",
  },
  {
    code: "BMvaVerVvpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties verbouwingen",
  },
  {
    code: "BMvaVerAkp",
    descriptionShort: "Actuele kostprijs",
    descriptionLong: "Actuele kostprijs verbouwingen",
  },
  {
    code: "BMvaVerAkpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) verbouwingen",
  },
  {
    code: "BMvaVerAkpIna",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen verbouwingen",
  },
  {
    code: "BMvaVerAkpAdo",
    descriptionShort: "Verwervingen via fusies en overnames",
    descriptionLong: "Verwervingen via fusies en overnames verbouwingen",
  },
  {
    code: "BMvaVerAkpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen verbouwingen",
  },
  {
    code: "BMvaVerAkpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen verbouwingen",
  },
  {
    code: "BMvaVerAkpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen verbouwingen",
  },
  {
    code: "BMvaVerAkpOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen verbouwingen",
  },
  {
    code: "BMvaVerAkpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties verbouwingen",
  },
  {
    code: "BMvaVerCae",
    descriptionShort: "Cumulatieve afschrijvingen en waardeverminderingen",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen verbouwingen",
  },
  {
    code: "BMvaVerCaeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) verbouwingen",
  },
  {
    code: "BMvaVerCaeAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong: "Afschrijvingen verbouwingen",
  },
  {
    code: "BMvaVerCaeDca",
    descriptionShort: "Afschrijving op desinvesteringen",
    descriptionLong: "Afschrijving op desinvesteringen verbouwingen",
  },
  {
    code: "BMvaVerCaeWvr",
    descriptionShort: "Bijzondere waardeverminderingen",
    descriptionLong: "Bijzondere waardeverminderingen verbouwingen",
  },
  {
    code: "BMvaVerCaeTvw",
    descriptionShort: "Terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen verbouwingen",
  },
  {
    code: "BMvaVerCuh",
    descriptionShort: "Cumulatieve herwaarderingen",
    descriptionLong: "Cumulatieve herwaarderingen verbouwingen",
  },
  {
    code: "BMvaVerCuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) verbouwingen",
  },
  {
    code: "BMvaVerCuhHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong: "Herwaarderingen verbouwingen",
  },
  {
    code: "BMvaVerCuhAfh",
    descriptionShort: "Afschrijving herwaarderingen",
    descriptionLong: "Afschrijving herwaarderingen verbouwingen",
  },
  {
    code: "BMvaVerCuhDeh",
    descriptionShort: "Desinvestering herwaarderingen",
    descriptionLong: "Desinvestering herwaarderingen verbouwingen",
  },
  {
    code: "BMvaMei",
    descriptionShort: "Machines en installaties",
    descriptionLong: "Machines en installaties",
  },
  {
    code: "BMvaMeiVvp",
    descriptionShort: "Verkrijgings- of vervaardigingsprijs",
    descriptionLong:
      "Verkrijgings- of vervaardigingsprijs machines en installaties",
  },
  {
    code: "BMvaMeiVvpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) machines en installaties",
  },
  {
    code: "BMvaMeiVvpIna",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen machines en installaties",
  },
  {
    code: "BMvaMeiVvpAdo",
    descriptionShort: "Verwervingen via fusies en overnames",
    descriptionLong:
      "Verwervingen via fusies en overnames machines en installaties",
  },
  {
    code: "BMvaMeiVvpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen machines en installaties",
  },
  {
    code: "BMvaMeiVvpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen machines en installaties",
  },
  {
    code: "BMvaMeiVvpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen machines en installaties",
  },
  {
    code: "BMvaMeiVvpOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen machines en installaties",
  },
  {
    code: "BMvaMeiVvpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties machines en installaties",
  },
  {
    code: "BMvaMeiAkp",
    descriptionShort: "Actuele kostprijs",
    descriptionLong: "Actuele kostprijs machines en installaties",
  },
  {
    code: "BMvaMeiAkpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) machines en installaties",
  },
  {
    code: "BMvaMeiAkpIna",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen machines en installaties",
  },
  {
    code: "BMvaMeiAkpAdo",
    descriptionShort: "Verwervingen via fusies en overnames",
    descriptionLong:
      "Verwervingen via fusies en overnames machines en installaties",
  },
  {
    code: "BMvaMeiAkpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen machines en installaties",
  },
  {
    code: "BMvaMeiAkpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen machines en installaties",
  },
  {
    code: "BMvaMeiAkpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen machines en installaties",
  },
  {
    code: "BMvaMeiAkpOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen machines en installaties",
  },
  {
    code: "BMvaMeiAkpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties machines en installaties",
  },
  {
    code: "BMvaMeiCae",
    descriptionShort: "Cumulatieve afschrijvingen en waardeverminderingen",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen machines en installaties",
  },
  {
    code: "BMvaMeiCaeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) machines en installaties",
  },
  {
    code: "BMvaMeiCaeAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong: "Afschrijvingen machines en installaties",
  },
  {
    code: "BMvaMeiCaeDca",
    descriptionShort: "Afschrijving op desinvesteringen",
    descriptionLong:
      "Afschrijving op desinvesteringen machines en installaties",
  },
  {
    code: "BMvaMeiCaeWvr",
    descriptionShort: "Bijzondere waardeverminderingen",
    descriptionLong: "Bijzondere waardeverminderingen machines en installaties",
  },
  {
    code: "BMvaMeiCaeTvw",
    descriptionShort: "Terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen machines en installaties",
  },
  {
    code: "BMvaMeiCuh",
    descriptionShort: "Cumulatieve herwaarderingen",
    descriptionLong: "Cumulatieve herwaarderingen machines en installaties",
  },
  {
    code: "BMvaMeiCuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) machines en installaties",
  },
  {
    code: "BMvaMeiCuhHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong: "Herwaarderingen machines en installaties",
  },
  {
    code: "BMvaMeiCuhAfh",
    descriptionShort: "Afschrijving herwaarderingen",
    descriptionLong: "Afschrijving herwaarderingen machines en installaties",
  },
  {
    code: "BMvaMeiCuhDeh",
    descriptionShort: "Desinvestering herwaarderingen",
    descriptionLong: "Desinvestering herwaarderingen machines en installaties",
  },
  {
    code: "BMvaObe",
    descriptionShort: "Andere vaste bedrijfsmiddelen",
    descriptionLong: "Andere vaste bedrijfsmiddelen",
  },
  {
    code: "BMvaObeVvp",
    descriptionShort: "Verkrijgings- of vervaardigingsprijs",
    descriptionLong:
      "Verkrijgings- of vervaardigingsprijs andere vaste bedrijfsmiddelen",
  },
  {
    code: "BMvaObeVvpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) andere vaste bedrijfsmiddelen",
  },
  {
    code: "BMvaObeVvpIna",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen andere vaste bedrijfsmiddelen",
  },
  {
    code: "BMvaObeVvpAdo",
    descriptionShort: "Verwervingen via fusies en overnames",
    descriptionLong:
      "Verwervingen via fusies en overnames andere vaste bedrijfsmiddelen",
  },
  {
    code: "BMvaObeVvpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen andere vaste bedrijfsmiddelen",
  },
  {
    code: "BMvaObeVvpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen andere vaste bedrijfsmiddelen",
  },
  {
    code: "BMvaObeVvpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen andere vaste bedrijfsmiddelen",
  },
  {
    code: "BMvaObeVvpOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen andere vaste bedrijfsmiddelen",
  },
  {
    code: "BMvaObeVvpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties andere vaste bedrijfsmiddelen",
  },
  {
    code: "BMvaObeAkp",
    descriptionShort: "Actuele kostprijs",
    descriptionLong: "Actuele kostprijs andere vaste bedrijfsmiddelen",
  },
  {
    code: "BMvaObeAkpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) andere vaste bedrijfsmiddelen",
  },
  {
    code: "BMvaObeAkpIna",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen andere vaste bedrijfsmiddelen",
  },
  {
    code: "BMvaObeAkpAdo",
    descriptionShort: "Verwervingen via fusies en overnames",
    descriptionLong:
      "Verwervingen via fusies en overnames andere vaste bedrijfsmiddelen",
  },
  {
    code: "BMvaObeAkpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen andere vaste bedrijfsmiddelen",
  },
  {
    code: "BMvaObeAkpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen andere vaste bedrijfsmiddelen",
  },
  {
    code: "BMvaObeAkpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen andere vaste bedrijfsmiddelen",
  },
  {
    code: "BMvaObeAkpOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen andere vaste bedrijfsmiddelen",
  },
  {
    code: "BMvaObeAkpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties andere vaste bedrijfsmiddelen",
  },
  {
    code: "BMvaObeCae",
    descriptionShort: "Cumulatieve afschrijvingen en waardeverminderingen",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen andere vaste bedrijfsmiddelen",
  },
  {
    code: "BMvaObeCaeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) andere vaste bedrijfsmiddelen",
  },
  {
    code: "BMvaObeCaeAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong: "Afschrijvingen andere vaste bedrijfsmiddelen",
  },
  {
    code: "BMvaObeCaeDca",
    descriptionShort: "Afschrijving op desinvesteringen",
    descriptionLong:
      "Afschrijving op desinvesteringen andere vaste bedrijfsmiddelen",
  },
  {
    code: "BMvaObeCaeWvr",
    descriptionShort: "Bijzondere waardeverminderingen",
    descriptionLong:
      "Bijzondere waardeverminderingen andere vaste bedrijfsmiddelen",
  },
  {
    code: "BMvaObeCaeTvw",
    descriptionShort: "Terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen andere vaste bedrijfsmiddelen",
  },
  {
    code: "BMvaObeCuh",
    descriptionShort: "Cumulatieve herwaarderingen",
    descriptionLong:
      "Cumulatieve herwaarderingen andere vaste bedrijfsmiddelen",
  },
  {
    code: "BMvaObeCuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) andere vaste bedrijfsmiddelen",
  },
  {
    code: "BMvaObeCuhHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong: "Herwaarderingen andere vaste bedrijfsmiddelen",
  },
  {
    code: "BMvaObeCuhAfh",
    descriptionShort: "Afschrijving herwaarderingen",
    descriptionLong:
      "Afschrijving herwaarderingen andere vaste bedrijfsmiddelen",
  },
  {
    code: "BMvaObeCuhDeh",
    descriptionShort: "Desinvestering herwaarderingen",
    descriptionLong:
      "Desinvestering herwaarderingen andere vaste bedrijfsmiddelen",
  },
  {
    code: "BMvaBei",
    descriptionShort: "Inventaris",
    descriptionLong: "Inventaris",
  },
  {
    code: "BMvaBeiVvp",
    descriptionShort: "Verkrijgings- of vervaardigingsprijs",
    descriptionLong: "Verkrijgings- of vervaardigingsprijs inventaris",
  },
  {
    code: "BMvaBeiVvpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) inventaris",
  },
  {
    code: "BMvaBeiVvpIna",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen inventaris",
  },
  {
    code: "BMvaBeiVvpAdo",
    descriptionShort: "Verwervingen via fusies en overnames",
    descriptionLong: "Verwervingen via fusies en overnames inventaris",
  },
  {
    code: "BMvaBeiVvpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen inventaris",
  },
  {
    code: "BMvaBeiVvpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen inventaris",
  },
  {
    code: "BMvaBeiVvpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen inventaris",
  },
  {
    code: "BMvaBeiVvpOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen inventaris",
  },
  {
    code: "BMvaBeiVvpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties inventaris",
  },
  {
    code: "BMvaBeiAkp",
    descriptionShort: "Actuele kostprijs",
    descriptionLong: "Actuele kostprijs inventaris",
  },
  {
    code: "BMvaBeiAkpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) inventaris",
  },
  {
    code: "BMvaBeiAkpIna",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen inventaris",
  },
  {
    code: "BMvaBeiAkpAdo",
    descriptionShort: "Verwervingen via fusies en overnames",
    descriptionLong: "Verwervingen via fusies en overnames inventaris",
  },
  {
    code: "BMvaBeiAkpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen inventaris",
  },
  {
    code: "BMvaBeiAkpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen inventaris",
  },
  {
    code: "BMvaBeiAkpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen inventaris",
  },
  {
    code: "BMvaBeiAkpOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen inventaris",
  },
  {
    code: "BMvaBeiAkpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties inventaris",
  },
  {
    code: "BMvaBeiCae",
    descriptionShort: "Cumulatieve afschrijvingen en waardeverminderingen",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen inventaris",
  },
  {
    code: "BMvaBeiCaeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) inventaris",
  },
  {
    code: "BMvaBeiCaeAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong: "Afschrijvingen inventaris",
  },
  {
    code: "BMvaBeiCaeDca",
    descriptionShort: "Afschrijving op desinvesteringen",
    descriptionLong: "Afschrijving op desinvesteringen inventaris",
  },
  {
    code: "BMvaBeiCaeWvr",
    descriptionShort: "Bijzondere waardeverminderingen",
    descriptionLong: "Bijzondere waardeverminderingen inventaris",
  },
  {
    code: "BMvaBeiCaeTvw",
    descriptionShort: "Terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen inventaris",
  },
  {
    code: "BMvaBeiCuh",
    descriptionShort: "Cumulatieve herwaarderingen",
    descriptionLong: "Cumulatieve herwaarderingen inventaris",
  },
  {
    code: "BMvaBeiCuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) inventaris",
  },
  {
    code: "BMvaBeiCuhHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong: "Herwaarderingen inventaris",
  },
  {
    code: "BMvaBeiCuhAfh",
    descriptionShort: "Afschrijving herwaarderingen",
    descriptionLong: "Afschrijving herwaarderingen inventaris",
  },
  {
    code: "BMvaBeiCuhDeh",
    descriptionShort: "Desinvestering herwaarderingen",
    descriptionLong: "Desinvestering herwaarderingen inventaris",
  },
  {
    code: "BMvaTev",
    descriptionShort: "Automobielen en overige transportmiddelen",
    descriptionLong: "Automobielen en overige transportmiddelen",
  },
  {
    code: "BMvaTevVvp",
    descriptionShort: "Verkrijgings- of vervaardigingsprijs",
    descriptionLong:
      "Verkrijgings- of vervaardigingsprijs automobielen en overige transportmiddelen",
  },
  {
    code: "BMvaTevVvpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) automobielen en overige transportmiddelen",
  },
  {
    code: "BMvaTevVvpIna",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen automobielen en overige transportmiddelen",
  },
  {
    code: "BMvaTevVvpAdo",
    descriptionShort: "Verwervingen via fusies en overnames",
    descriptionLong:
      "Verwervingen via fusies en overnames automobielen en overige transportmiddelen",
  },
  {
    code: "BMvaTevVvpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong:
      "Desinvesteringen automobielen en overige transportmiddelen",
  },
  {
    code: "BMvaTevVvpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen automobielen en overige transportmiddelen",
  },
  {
    code: "BMvaTevVvpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong:
      "Omrekeningsverschillen automobielen en overige transportmiddelen",
  },
  {
    code: "BMvaTevVvpOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen automobielen en overige transportmiddelen",
  },
  {
    code: "BMvaTevVvpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties automobielen en overige transportmiddelen",
  },
  {
    code: "BMvaTevAkp",
    descriptionShort: "Actuele kostprijs",
    descriptionLong:
      "Actuele kostprijs automobielen en overige transportmiddelen",
  },
  {
    code: "BMvaTevAkpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) automobielen en overige transportmiddelen",
  },
  {
    code: "BMvaTevAkpIna",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen automobielen en overige transportmiddelen",
  },
  {
    code: "BMvaTevAkpAdo",
    descriptionShort: "Verwervingen via fusies en overnames",
    descriptionLong:
      "Verwervingen via fusies en overnames automobielen en overige transportmiddelen",
  },
  {
    code: "BMvaTevAkpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong:
      "Desinvesteringen automobielen en overige transportmiddelen",
  },
  {
    code: "BMvaTevAkpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen automobielen en overige transportmiddelen",
  },
  {
    code: "BMvaTevAkpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong:
      "Omrekeningsverschillen automobielen en overige transportmiddelen",
  },
  {
    code: "BMvaTevAkpOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen automobielen en overige transportmiddelen",
  },
  {
    code: "BMvaTevAkpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties automobielen en overige transportmiddelen",
  },
  {
    code: "BMvaTevCae",
    descriptionShort: "Cumulatieve afschrijvingen en waardeverminderingen",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen automobielen en overige transportmiddelen",
  },
  {
    code: "BMvaTevCaeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) automobielen en overige transportmiddelen",
  },
  {
    code: "BMvaTevCaeAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong: "Afschrijvingen automobielen en overige transportmiddelen",
  },
  {
    code: "BMvaTevCaeDca",
    descriptionShort: "Afschrijving op desinvesteringen",
    descriptionLong:
      "Afschrijving op desinvesteringen automobielen en overige transportmiddelen",
  },
  {
    code: "BMvaTevCaeWvr",
    descriptionShort: "Bijzondere waardeverminderingen",
    descriptionLong:
      "Bijzondere waardeverminderingen automobielen en overige transportmiddelen",
  },
  {
    code: "BMvaTevCaeTvw",
    descriptionShort: "Terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen automobielen en overige transportmiddelen",
  },
  {
    code: "BMvaTevCuh",
    descriptionShort: "Cumulatieve herwaarderingen",
    descriptionLong:
      "Cumulatieve herwaarderingen automobielen en overige transportmiddelen",
  },
  {
    code: "BMvaTevCuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) automobielen en overige transportmiddelen",
  },
  {
    code: "BMvaTevCuhHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong:
      "Herwaarderingen automobielen en overige transportmiddelen",
  },
  {
    code: "BMvaTevCuhAfh",
    descriptionShort: "Afschrijving herwaarderingen",
    descriptionLong:
      "Afschrijving herwaarderingen automobielen en overige transportmiddelen",
  },
  {
    code: "BMvaTevCuhDeh",
    descriptionShort: "Desinvestering herwaarderingen",
    descriptionLong:
      "Desinvestering herwaarderingen automobielen en overige transportmiddelen",
  },
  {
    code: "BMvaHuu",
    descriptionShort: "Huurdersinvesteringen",
    descriptionLong: "Huurdersinvesteringen",
  },
  {
    code: "BMvaHuuVvp",
    descriptionShort: "Verkrijgings- of vervaardigingsprijs",
    descriptionLong:
      "Verkrijgings- of vervaardigingsprijs huurdersinvesteringen",
  },
  {
    code: "BMvaHuuVvpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) huurdersinvesteringen",
  },
  {
    code: "BMvaHuuVvpIna",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen huurdersinvesteringen",
  },
  {
    code: "BMvaHuuVvpAdo",
    descriptionShort: "Verwervingen via fusies en overnames",
    descriptionLong:
      "Verwervingen via fusies en overnames huurdersinvesteringen",
  },
  {
    code: "BMvaHuuVvpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen huurdersinvesteringen",
  },
  {
    code: "BMvaHuuVvpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen huurdersinvesteringen",
  },
  {
    code: "BMvaHuuVvpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen huurdersinvesteringen",
  },
  {
    code: "BMvaHuuVvpOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen huurdersinvesteringen",
  },
  {
    code: "BMvaHuuVvpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties huurdersinvesteringen",
  },
  {
    code: "BMvaHuuAkp",
    descriptionShort: "Actuele kostprijs",
    descriptionLong: "Actuele kostprijs huurdersinvesteringen",
  },
  {
    code: "BMvaHuuAkpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) huurdersinvesteringen",
  },
  {
    code: "BMvaHuuAkpIna",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen huurdersinvesteringen",
  },
  {
    code: "BMvaHuuAkpAdo",
    descriptionShort: "Verwervingen via fusies en overnames",
    descriptionLong:
      "Verwervingen via fusies en overnames huurdersinvesteringen",
  },
  {
    code: "BMvaHuuAkpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen huurdersinvesteringen",
  },
  {
    code: "BMvaHuuAkpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen huurdersinvesteringen",
  },
  {
    code: "BMvaHuuAkpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen huurdersinvesteringen",
  },
  {
    code: "BMvaHuuAkpOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen huurdersinvesteringen",
  },
  {
    code: "BMvaHuuAkpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties huurdersinvesteringen",
  },
  {
    code: "BMvaHuuCae",
    descriptionShort: "Cumulatieve afschrijvingen en waardeverminderingen",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen huurdersinvesteringen",
  },
  {
    code: "BMvaHuuCaeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) huurdersinvesteringen",
  },
  {
    code: "BMvaHuuCaeAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong: "Afschrijvingen huurdersinvesteringen",
  },
  {
    code: "BMvaHuuCaeDca",
    descriptionShort: "Afschrijving op desinvesteringen",
    descriptionLong: "Afschrijving op desinvesteringen huurdersinvesteringen",
  },
  {
    code: "BMvaHuuCaeWvr",
    descriptionShort: "Bijzondere waardeverminderingen",
    descriptionLong: "Bijzondere waardeverminderingen huurdersinvesteringen",
  },
  {
    code: "BMvaHuuCaeTvw",
    descriptionShort: "Terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen huurdersinvesteringen",
  },
  {
    code: "BMvaHuuCuh",
    descriptionShort: "Cumulatieve herwaarderingen",
    descriptionLong: "Cumulatieve herwaarderingen huurdersinvesteringen",
  },
  {
    code: "BMvaHuuCuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) huurdersinvesteringen",
  },
  {
    code: "BMvaHuuCuhHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong: "Herwaarderingen huurdersinvesteringen",
  },
  {
    code: "BMvaHuuCuhAfh",
    descriptionShort: "Afschrijving herwaarderingen",
    descriptionLong: "Afschrijving herwaarderingen huurdersinvesteringen",
  },
  {
    code: "BMvaHuuCuhDeh",
    descriptionShort: "Desinvestering herwaarderingen",
    descriptionLong: "Desinvestering herwaarderingen huurdersinvesteringen",
  },
  {
    code: "BMvaVli",
    descriptionShort: "Vliegtuigen",
    descriptionLong: "Vliegtuigen",
  },
  {
    code: "BMvaVliVvp",
    descriptionShort: "Verkrijgings- of vervaardigingsprijs",
    descriptionLong: "Verkrijgings- of vervaardigingsprijs vliegtuigen",
  },
  {
    code: "BMvaVliVvpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) vliegtuigen",
  },
  {
    code: "BMvaVliVvpIna",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen vliegtuigen",
  },
  {
    code: "BMvaVliVvpAdo",
    descriptionShort: "Verwervingen via fusies en overnames",
    descriptionLong: "Verwervingen via fusies en overnames vliegtuigen",
  },
  {
    code: "BMvaVliVvpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen vliegtuigen",
  },
  {
    code: "BMvaVliVvpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen vliegtuigen",
  },
  {
    code: "BMvaVliVvpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen vliegtuigen",
  },
  {
    code: "BMvaVliVvpOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen vliegtuigen",
  },
  {
    code: "BMvaVliVvpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties vliegtuigen",
  },
  {
    code: "BMvaVliAkp",
    descriptionShort: "Actuele kostprijs",
    descriptionLong: "Actuele kostprijs vliegtuigen",
  },
  {
    code: "BMvaVliAkpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) vliegtuigen",
  },
  {
    code: "BMvaVliAkpIna",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen vliegtuigen",
  },
  {
    code: "BMvaVliAkpAdo",
    descriptionShort: "Verwervingen via fusies en overnames",
    descriptionLong: "Verwervingen via fusies en overnames vliegtuigen",
  },
  {
    code: "BMvaVliAkpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen vliegtuigen",
  },
  {
    code: "BMvaVliAkpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen vliegtuigen",
  },
  {
    code: "BMvaVliAkpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen vliegtuigen",
  },
  {
    code: "BMvaVliAkpOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen vliegtuigen",
  },
  {
    code: "BMvaVliAkpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties vliegtuigen",
  },
  {
    code: "BMvaVliCae",
    descriptionShort: "Cumulatieve afschrijvingen en waardeverminderingen",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen vliegtuigen",
  },
  {
    code: "BMvaVliCaeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) vliegtuigen",
  },
  {
    code: "BMvaVliCaeAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong: "Afschrijvingen vliegtuigen",
  },
  {
    code: "BMvaVliCaeDca",
    descriptionShort: "Afschrijving op desinvesteringen",
    descriptionLong: "Afschrijving op desinvesteringen vliegtuigen",
  },
  {
    code: "BMvaVliCaeWvr",
    descriptionShort: "Bijzondere waardeverminderingen",
    descriptionLong: "Bijzondere waardeverminderingen vliegtuigen",
  },
  {
    code: "BMvaVliCaeTvw",
    descriptionShort: "Terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen vliegtuigen",
  },
  {
    code: "BMvaVliCuh",
    descriptionShort: "Cumulatieve herwaarderingen",
    descriptionLong: "Cumulatieve herwaarderingen vliegtuigen",
  },
  {
    code: "BMvaVliCuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) vliegtuigen",
  },
  {
    code: "BMvaVliCuhHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong: "Herwaarderingen vliegtuigen",
  },
  {
    code: "BMvaVliCuhAfh",
    descriptionShort: "Afschrijving herwaarderingen",
    descriptionLong: "Afschrijving herwaarderingen vliegtuigen",
  },
  {
    code: "BMvaVliCuhDeh",
    descriptionShort: "Desinvestering herwaarderingen",
    descriptionLong: "Desinvestering herwaarderingen vliegtuigen",
  },
  { code: "BMvaSch", descriptionShort: "Schepen", descriptionLong: "Schepen" },
  {
    code: "BMvaSchVvp",
    descriptionShort: "Verkrijgings- of vervaardigingsprijs",
    descriptionLong: "Verkrijgings- of vervaardigingsprijs schepen",
  },
  {
    code: "BMvaSchVvpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) schepen",
  },
  {
    code: "BMvaSchVvpIna",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen schepen",
  },
  {
    code: "BMvaSchVvpAdo",
    descriptionShort: "Verwervingen via fusies en overnames",
    descriptionLong: "Verwervingen via fusies en overnames schepen",
  },
  {
    code: "BMvaSchVvpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen schepen",
  },
  {
    code: "BMvaSchVvpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen schepen",
  },
  {
    code: "BMvaSchVvpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen schepen",
  },
  {
    code: "BMvaSchVvpOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen schepen",
  },
  {
    code: "BMvaSchVvpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties schepen",
  },
  {
    code: "BMvaSchAkp",
    descriptionShort: "Actuele kostprijs",
    descriptionLong: "Actuele kostprijs schepen",
  },
  {
    code: "BMvaSchAkpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) schepen",
  },
  {
    code: "BMvaSchAkpIna",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen schepen",
  },
  {
    code: "BMvaSchAkpAdo",
    descriptionShort: "Verwervingen via fusies en overnames",
    descriptionLong: "Verwervingen via fusies en overnames schepen",
  },
  {
    code: "BMvaSchAkpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen schepen",
  },
  {
    code: "BMvaSchAkpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen schepen",
  },
  {
    code: "BMvaSchAkpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen schepen",
  },
  {
    code: "BMvaSchAkpOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen schepen",
  },
  {
    code: "BMvaSchAkpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties schepen",
  },
  {
    code: "BMvaSchCae",
    descriptionShort: "Cumulatieve afschrijvingen en waardeverminderingen",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen schepen",
  },
  {
    code: "BMvaSchCaeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) schepen",
  },
  {
    code: "BMvaSchCaeAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong: "Afschrijvingen schepen",
  },
  {
    code: "BMvaSchCaeDca",
    descriptionShort: "Afschrijving op desinvesteringen",
    descriptionLong: "Afschrijving op desinvesteringen schepen",
  },
  {
    code: "BMvaSchCaeWvr",
    descriptionShort: "Bijzondere waardeverminderingen",
    descriptionLong: "Bijzondere waardeverminderingen schepen",
  },
  {
    code: "BMvaSchCaeTvw",
    descriptionShort: "Terugneming van bijzondere waardeverminderingen",
    descriptionLong: "Terugneming van bijzondere waardeverminderingen schepen",
  },
  {
    code: "BMvaSchCuh",
    descriptionShort: "Cumulatieve herwaarderingen",
    descriptionLong: "Cumulatieve herwaarderingen schepen",
  },
  {
    code: "BMvaSchCuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) schepen",
  },
  {
    code: "BMvaSchCuhHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong: "Herwaarderingen schepen",
  },
  {
    code: "BMvaSchCuhAfh",
    descriptionShort: "Afschrijving herwaarderingen",
    descriptionLong: "Afschrijving herwaarderingen schepen",
  },
  {
    code: "BMvaSchCuhDeh",
    descriptionShort: "Desinvestering herwaarderingen",
    descriptionLong: "Desinvestering herwaarderingen schepen",
  },
  {
    code: "BMvaMep",
    descriptionShort: "Meerjaren plantopstand",
    descriptionLong: "Meerjaren plantopstand",
  },
  {
    code: "BMvaMepVvp",
    descriptionShort: "Verkrijgings- of vervaardigingsprijs",
    descriptionLong:
      "Verkrijgings- of vervaardigingsprijs meerjaren plantopstand",
  },
  {
    code: "BMvaMepVvpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) meerjaren plantopstand",
  },
  {
    code: "BMvaMepVvpIna",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen meerjaren plantopstand",
  },
  {
    code: "BMvaMepVvpAdo",
    descriptionShort: "Verwervingen via fusies en overnames",
    descriptionLong:
      "Verwervingen via fusies en overnames meerjaren plantopstand",
  },
  {
    code: "BMvaMepVvpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen meerjaren plantopstand",
  },
  {
    code: "BMvaMepVvpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen meerjaren plantopstand",
  },
  {
    code: "BMvaMepVvpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen meerjaren plantopstand",
  },
  {
    code: "BMvaMepVvpOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen meerjaren plantopstand",
  },
  {
    code: "BMvaMepVvpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties meerjaren plantopstand",
  },
  {
    code: "BMvaMepCae",
    descriptionShort: "Cumulatieve afschrijvingen en waardeverminderingen",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen meerjaren plantopstand",
  },
  {
    code: "BMvaMepCaeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) meerjaren plantopstand",
  },
  {
    code: "BMvaMepCaeAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong: "Afschrijvingen meerjaren plantopstand",
  },
  {
    code: "BMvaMepCaeDca",
    descriptionShort: "Afschrijving op desinvesteringen",
    descriptionLong: "Afschrijving op desinvesteringen meerjaren plantopstand",
  },
  {
    code: "BMvaMepCaeWvr",
    descriptionShort: "Bijzondere waardeverminderingen",
    descriptionLong: "Bijzondere waardeverminderingen meerjaren plantopstand",
  },
  {
    code: "BMvaMepCaeTvw",
    descriptionShort: "Terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen meerjaren plantopstand",
  },
  {
    code: "BMvaMepCuh",
    descriptionShort: "Cumulatieve herwaarderingen",
    descriptionLong: "Cumulatieve herwaarderingen meerjaren plantopstand",
  },
  {
    code: "BMvaMepCuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) meerjaren plantopstand",
  },
  {
    code: "BMvaMepCuhHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong: "Herwaarderingen meerjaren plantopstand",
  },
  {
    code: "BMvaMepCuhAfh",
    descriptionShort: "Afschrijving herwaarderingen",
    descriptionLong: "Afschrijving herwaarderingen meerjaren plantopstand",
  },
  {
    code: "BMvaMepCuhDeh",
    descriptionShort: "Desinvestering herwaarderingen",
    descriptionLong: "Desinvestering herwaarderingen meerjaren plantopstand",
  },
  {
    code: "BMvaGeb",
    descriptionShort: "Gebruiksvee",
    descriptionLong: "Gebruiksvee",
  },
  {
    code: "BMvaGebVvp",
    descriptionShort: "Verkrijgings- of vervaardigingsprijs",
    descriptionLong: "Verkrijgings- of vervaardigingsprijs gebruiksvee",
  },
  {
    code: "BMvaGebVvpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) gebruiksvee",
  },
  {
    code: "BMvaGebVvpIna",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen gebruiksvee",
  },
  {
    code: "BMvaGebVvpAdo",
    descriptionShort: "Verwervingen via fusies en overnames",
    descriptionLong: "Verwervingen via fusies en overnames gebruiksvee",
  },
  {
    code: "BMvaGebVvpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen gebruiksvee",
  },
  {
    code: "BMvaGebVvpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen gebruiksvee",
  },
  {
    code: "BMvaGebVvpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen gebruiksvee",
  },
  {
    code: "BMvaGebVvpOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen gebruiksvee",
  },
  {
    code: "BMvaGebVvpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties gebruiksvee",
  },
  {
    code: "BMvaGebCae",
    descriptionShort: "Cumulatieve afschrijvingen en waardeverminderingen",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen gebruiksvee",
  },
  {
    code: "BMvaGebCaeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) gebruiksvee",
  },
  {
    code: "BMvaGebCaeAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong: "Afschrijvingen gebruiksvee",
  },
  {
    code: "BMvaGebCaeDca",
    descriptionShort: "Afschrijving op desinvesteringen",
    descriptionLong: "Afschrijving op desinvesteringen gebruiksvee",
  },
  {
    code: "BMvaGebCaeWvr",
    descriptionShort: "Bijzondere waardeverminderingen",
    descriptionLong: "Bijzondere waardeverminderingen gebruiksvee",
  },
  {
    code: "BMvaGebCaeTvw",
    descriptionShort: "Terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen gebruiksvee",
  },
  {
    code: "BMvaGebCuh",
    descriptionShort: "Cumulatieve herwaarderingen",
    descriptionLong: "Cumulatieve herwaarderingen gebruiksvee",
  },
  {
    code: "BMvaGebCuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) gebruiksvee",
  },
  {
    code: "BMvaGebCuhHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong: "Herwaarderingen gebruiksvee",
  },
  {
    code: "BMvaGebCuhAfh",
    descriptionShort: "Afschrijving herwaarderingen",
    descriptionLong: "Afschrijving herwaarderingen gebruiksvee",
  },
  {
    code: "BMvaGebCuhDeh",
    descriptionShort: "Desinvestering herwaarderingen",
    descriptionLong: "Desinvestering herwaarderingen gebruiksvee",
  },
  {
    code: "BMvaVbi",
    descriptionShort: "Vaste bedrijfsmiddelen in uitvoering",
    descriptionLong: "Vaste bedrijfsmiddelen in uitvoering",
  },
  {
    code: "BMvaVbiVvp",
    descriptionShort: "Verkrijgings- of vervaardigingsprijs",
    descriptionLong:
      "Verkrijgings- of vervaardigingsprijs vaste bedrijfsmiddelen in uitvoering",
  },
  {
    code: "BMvaVbiVvpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vaste bedrijfsmiddelen in uitvoering",
  },
  {
    code: "BMvaVbiVvpIna",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen vaste bedrijfsmiddelen in uitvoering",
  },
  {
    code: "BMvaVbiVvpAdo",
    descriptionShort: "Verwervingen via fusies en overnames",
    descriptionLong:
      "Verwervingen via fusies en overnames vaste bedrijfsmiddelen in uitvoering",
  },
  {
    code: "BMvaVbiVvpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen vaste bedrijfsmiddelen in uitvoering",
  },
  {
    code: "BMvaVbiVvpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen vaste bedrijfsmiddelen in uitvoering",
  },
  {
    code: "BMvaVbiVvpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong:
      "Omrekeningsverschillen vaste bedrijfsmiddelen in uitvoering",
  },
  {
    code: "BMvaVbiVvpOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen vaste bedrijfsmiddelen in uitvoering",
  },
  {
    code: "BMvaVbiVvpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties vaste bedrijfsmiddelen in uitvoering",
  },
  {
    code: "BMvaVbiAkp",
    descriptionShort: "Actuele kostprijs",
    descriptionLong: "Actuele kostprijs vaste bedrijfsmiddelen in uitvoering",
  },
  {
    code: "BMvaVbiAkpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vaste bedrijfsmiddelen in uitvoering",
  },
  {
    code: "BMvaVbiAkpIna",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen vaste bedrijfsmiddelen in uitvoering",
  },
  {
    code: "BMvaVbiAkpAdo",
    descriptionShort: "Verwervingen via fusies en overnames",
    descriptionLong:
      "Verwervingen via fusies en overnames vaste bedrijfsmiddelen in uitvoering",
  },
  {
    code: "BMvaVbiAkpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen vaste bedrijfsmiddelen in uitvoering",
  },
  {
    code: "BMvaVbiAkpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen vaste bedrijfsmiddelen in uitvoering",
  },
  {
    code: "BMvaVbiAkpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong:
      "Omrekeningsverschillen vaste bedrijfsmiddelen in uitvoering",
  },
  {
    code: "BMvaVbiAkpOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen vaste bedrijfsmiddelen in uitvoering",
  },
  {
    code: "BMvaVbiAkpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties vaste bedrijfsmiddelen in uitvoering",
  },
  {
    code: "BMvaVbiCae",
    descriptionShort: "Cumulatieve afschrijvingen en waardeverminderingen",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen vaste bedrijfsmiddelen in uitvoering",
  },
  {
    code: "BMvaVbiCaeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vaste bedrijfsmiddelen in uitvoering",
  },
  {
    code: "BMvaVbiCaeAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong: "Afschrijvingen vaste bedrijfsmiddelen in uitvoering",
  },
  {
    code: "BMvaVbiCaeDca",
    descriptionShort: "Afschrijving op desinvesteringen",
    descriptionLong:
      "Afschrijving op desinvesteringen vaste bedrijfsmiddelen in uitvoering",
  },
  {
    code: "BMvaVbiCaeWvr",
    descriptionShort: "Bijzondere waardeverminderingen",
    descriptionLong:
      "Bijzondere waardeverminderingen vaste bedrijfsmiddelen in uitvoering",
  },
  {
    code: "BMvaVbiCaeTvw",
    descriptionShort: "Terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen vaste bedrijfsmiddelen in uitvoering",
  },
  {
    code: "BMvaVbiCuh",
    descriptionShort: "Cumulatieve herwaarderingen",
    descriptionLong:
      "Cumulatieve herwaarderingen vaste bedrijfsmiddelen in uitvoering",
  },
  {
    code: "BMvaVbiCuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vaste bedrijfsmiddelen in uitvoering",
  },
  {
    code: "BMvaVbiCuhHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong: "Herwaarderingen vaste bedrijfsmiddelen in uitvoering",
  },
  {
    code: "BMvaVbiCuhAfh",
    descriptionShort: "Afschrijving herwaarderingen",
    descriptionLong:
      "Afschrijving herwaarderingen vaste bedrijfsmiddelen in uitvoering",
  },
  {
    code: "BMvaVbiCuhDeh",
    descriptionShort: "Desinvestering herwaarderingen",
    descriptionLong:
      "Desinvestering herwaarderingen vaste bedrijfsmiddelen in uitvoering",
  },
  {
    code: "BMvaVmv",
    descriptionShort: "Vooruitbetalingen op materiële vaste activa",
    descriptionLong: "Vooruitbetalingen op materiële vaste activa",
  },
  {
    code: "BMvaVmvVvp",
    descriptionShort: "Verkrijgings- of vervaardigingsprijs",
    descriptionLong:
      "Verkrijgings- of vervaardigingsprijs vooruitbetalingen op materiële vaste activa",
  },
  {
    code: "BMvaVmvVvpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vooruitbetalingen op materiële vaste activa",
  },
  {
    code: "BMvaVmvVvpIna",
    descriptionShort: "Investeringen",
    descriptionLong:
      "Investeringen vooruitbetalingen op materiële vaste activa",
  },
  {
    code: "BMvaVmvVvpAdo",
    descriptionShort: "Verwervingen via fusies en overnames",
    descriptionLong:
      "Verwervingen via fusies en overnames vooruitbetalingen op materiële vaste activa",
  },
  {
    code: "BMvaVmvVvpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong:
      "Desinvesteringen vooruitbetalingen op materiële vaste activa",
  },
  {
    code: "BMvaVmvVvpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen vooruitbetalingen op materiële vaste activa",
  },
  {
    code: "BMvaVmvVvpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong:
      "Omrekeningsverschillen vooruitbetalingen op materiële vaste activa",
  },
  {
    code: "BMvaVmvVvpOve",
    descriptionShort: "Overboekingen",
    descriptionLong:
      "Overboekingen vooruitbetalingen op materiële vaste activa",
  },
  {
    code: "BMvaVmvVvpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties vooruitbetalingen op materiële vaste activa",
  },
  {
    code: "BMvaVmvAkp",
    descriptionShort: "Actuele kostprijs",
    descriptionLong:
      "Actuele kostprijs vooruitbetalingen op materiële vaste activa",
  },
  {
    code: "BMvaVmvAkpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vooruitbetalingen op materiële vaste activa",
  },
  {
    code: "BMvaVmvAkpIna",
    descriptionShort: "Investeringen",
    descriptionLong:
      "Investeringen vooruitbetalingen op materiële vaste activa",
  },
  {
    code: "BMvaVmvAkpAdo",
    descriptionShort: "Verwervingen via fusies en overnames",
    descriptionLong:
      "Verwervingen via fusies en overnames vooruitbetalingen op materiële vaste activa",
  },
  {
    code: "BMvaVmvAkpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong:
      "Desinvesteringen vooruitbetalingen op materiële vaste activa",
  },
  {
    code: "BMvaVmvAkpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen vooruitbetalingen op materiële vaste activa",
  },
  {
    code: "BMvaVmvAkpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong:
      "Omrekeningsverschillen vooruitbetalingen op materiële vaste activa",
  },
  {
    code: "BMvaVmvAkpOve",
    descriptionShort: "Overboekingen",
    descriptionLong:
      "Overboekingen vooruitbetalingen op materiële vaste activa",
  },
  {
    code: "BMvaVmvAkpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties vooruitbetalingen op materiële vaste activa",
  },
  {
    code: "BMvaVmvCae",
    descriptionShort: "Cumulatieve afschrijvingen en waardeverminderingen",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen vooruitbetalingen op materiële vaste activa",
  },
  {
    code: "BMvaVmvCaeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vooruitbetalingen op materiële vaste activa",
  },
  {
    code: "BMvaVmvCaeAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong:
      "Afschrijvingen vooruitbetalingen op materiële vaste activa",
  },
  {
    code: "BMvaVmvCaeDca",
    descriptionShort: "Afschrijving op desinvesteringen",
    descriptionLong:
      "Afschrijving op desinvesteringen vooruitbetalingen op materiële vaste activa",
  },
  {
    code: "BMvaVmvCaeWvr",
    descriptionShort: "Bijzondere waardeverminderingen",
    descriptionLong:
      "Bijzondere waardeverminderingen vooruitbetalingen op materiële vaste activa",
  },
  {
    code: "BMvaVmvCaeTvw",
    descriptionShort: "Terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen vooruitbetalingen op materiële vaste activa",
  },
  {
    code: "BMvaVmvCuh",
    descriptionShort: "Cumulatieve herwaarderingen",
    descriptionLong:
      "Cumulatieve herwaarderingen vooruitbetalingen op materiële vaste activa",
  },
  {
    code: "BMvaVmvCuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vooruitbetalingen op materiële vaste activa",
  },
  {
    code: "BMvaVmvCuhHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong:
      "Herwaarderingen vooruitbetalingen op materiële vaste activa",
  },
  {
    code: "BMvaVmvCuhAfh",
    descriptionShort: "Afschrijving herwaarderingen",
    descriptionLong:
      "Afschrijving herwaarderingen vooruitbetalingen op materiële vaste activa",
  },
  {
    code: "BMvaVmvCuhDeh",
    descriptionShort: "Desinvestering herwaarderingen",
    descriptionLong:
      "Desinvestering herwaarderingen vooruitbetalingen op materiële vaste activa",
  },
  {
    code: "BMvaNad",
    descriptionShort:
      "Niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
    descriptionLong:
      "Niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
  },
  {
    code: "BMvaNadVvp",
    descriptionShort: "Verkrijgings- of vervaardigingsprijs",
    descriptionLong:
      "Verkrijgings- of vervaardigingsprijs niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
  },
  {
    code: "BMvaNadVvpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
  },
  {
    code: "BMvaNadVvpIna",
    descriptionShort: "Investeringen",
    descriptionLong:
      "Investeringen niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
  },
  {
    code: "BMvaNadVvpAdo",
    descriptionShort: "Verwervingen via fusies en overnames",
    descriptionLong:
      "Verwervingen via fusies en overnames niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
  },
  {
    code: "BMvaNadVvpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong:
      "Desinvesteringen niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
  },
  {
    code: "BMvaNadVvpDda",
    descriptionShort: "Afstotingen",
    descriptionLong:
      "Afstotingen niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
  },
  {
    code: "BMvaNadVvpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong:
      "Omrekeningsverschillen niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
  },
  {
    code: "BMvaNadVvpOve",
    descriptionShort: "Overboekingen",
    descriptionLong:
      "Overboekingen niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
  },
  {
    code: "BMvaNadVvpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
  },
  {
    code: "BMvaNadAkp",
    descriptionShort: "Actuele kostprijs",
    descriptionLong:
      "Actuele kostprijs niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
  },
  {
    code: "BMvaNadAkpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
  },
  {
    code: "BMvaNadAkpIna",
    descriptionShort: "Investeringen",
    descriptionLong:
      "Investeringen niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
  },
  {
    code: "BMvaNadAkpAdo",
    descriptionShort: "Verwervingen via fusies en overnames",
    descriptionLong:
      "Verwervingen via fusies en overnames niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
  },
  {
    code: "BMvaNadAkpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong:
      "Desinvesteringen niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
  },
  {
    code: "BMvaNadAkpDda",
    descriptionShort: "Afstotingen",
    descriptionLong:
      "Afstotingen niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
  },
  {
    code: "BMvaNadAkpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong:
      "Omrekeningsverschillen niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
  },
  {
    code: "BMvaNadAkpOve",
    descriptionShort: "Overboekingen",
    descriptionLong:
      "Overboekingen niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
  },
  {
    code: "BMvaNadAkpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
  },
  {
    code: "BMvaNadCae",
    descriptionShort: "Cumulatieve afschrijvingen en waardeverminderingen",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
  },
  {
    code: "BMvaNadCaeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
  },
  {
    code: "BMvaNadCaeAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong:
      "Afschrijvingen niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
  },
  {
    code: "BMvaNadCaeDca",
    descriptionShort: "Afschrijving op desinvesteringen",
    descriptionLong:
      "Afschrijving op desinvesteringen niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
  },
  {
    code: "BMvaNadCaeWvr",
    descriptionShort: "Bijzondere waardeverminderingen",
    descriptionLong:
      "Bijzondere waardeverminderingen niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
  },
  {
    code: "BMvaNadCaeTvw",
    descriptionShort: "Terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
  },
  {
    code: "BMvaNadCuh",
    descriptionShort: "Cumulatieve herwaarderingen",
    descriptionLong:
      "Cumulatieve herwaarderingen niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
  },
  {
    code: "BMvaNadCuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
  },
  {
    code: "BMvaNadCuhHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong:
      "Herwaarderingen niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
  },
  {
    code: "BMvaNadCuhAfh",
    descriptionShort: "Afschrijving herwaarderingen",
    descriptionLong:
      "Afschrijving herwaarderingen niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
  },
  {
    code: "BMvaNadCuhDeh",
    descriptionShort: "Desinvestering herwaarderingen",
    descriptionLong:
      "Desinvestering herwaarderingen niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
  },
  {
    code: "BMvaOrz",
    descriptionShort:
      "Onroerende en roerende zaken ten dienste van de exploitatie",
    descriptionLong:
      "Onroerende en roerende zaken ten dienste van de exploitatie",
  },
  {
    code: "BMvaOrzVvp",
    descriptionShort:
      "Verkrijgings- of vervaardigingsprijs ten dienste van de exploitatie",
    descriptionLong:
      "Verkrijgings- of vervaardigingsprijs ten dienste van de exploitatie",
  },
  {
    code: "BMvaOrzVvpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar)  ten dienste van de exploitatie",
  },
  {
    code: "BMvaOrzVvpIna",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen  ten dienste van de exploitatie",
  },
  {
    code: "BMvaOrzVvpAdo",
    descriptionShort: "Verwervingen via fusies en overnames",
    descriptionLong:
      "Verwervingen via fusies en overnames  ten dienste van de exploitatie",
  },
  {
    code: "BMvaOrzVvpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen  ten dienste van de exploitatie",
  },
  {
    code: "BMvaOrzVvpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen  ten dienste van de exploitatie",
  },
  {
    code: "BMvaOrzVvpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen  ten dienste van de exploitatie",
  },
  {
    code: "BMvaOrzVvpOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen  ten dienste van de exploitatie",
  },
  {
    code: "BMvaOrzVvpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties  ten dienste van de exploitatie",
  },
  {
    code: "BMvaOrzAkp",
    descriptionShort: "Actuele kostprijs",
    descriptionLong: "Actuele kostprijs ten dienste van de exploitatie",
  },
  {
    code: "BMvaOrzAkpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar)  ten dienste van de exploitatie",
  },
  {
    code: "BMvaOrzAkpIna",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen  ten dienste van de exploitatie",
  },
  {
    code: "BMvaOrzAkpAdo",
    descriptionShort: "Verwervingen via fusies en overnames",
    descriptionLong:
      "Verwervingen via fusies en overnames  ten dienste van de exploitatie",
  },
  {
    code: "BMvaOrzAkpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen  ten dienste van de exploitatie",
  },
  {
    code: "BMvaOrzAkpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen  ten dienste van de exploitatie",
  },
  {
    code: "BMvaOrzAkpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen  ten dienste van de exploitatie",
  },
  {
    code: "BMvaOrzAkpOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen  ten dienste van de exploitatie",
  },
  {
    code: "BMvaOrzAkpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties  ten dienste van de exploitatie",
  },
  {
    code: "BMvaOrzCae",
    descriptionShort: "Cumulatieve afschrijvingen en waardeverminderingen",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen  ten dienste van de exploitatie",
  },
  {
    code: "BMvaOrzCaeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar)  ten dienste van de exploitatie",
  },
  {
    code: "BMvaOrzCaeAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong:
      "Afschrijvingen niet aan de bedrijfsuitoefening  ten dienste van de exploitatie",
  },
  {
    code: "BMvaOrzCaeDca",
    descriptionShort: "Afschrijving op desinvesteringen",
    descriptionLong:
      "Afschrijving op desinvesteringen  ten dienste van de exploitatie",
  },
  {
    code: "BMvaOrzCaeWvr",
    descriptionShort: "Bijzondere waardeverminderingen",
    descriptionLong:
      "Bijzondere waardeverminderingen  ten dienste van de exploitatie",
  },
  {
    code: "BMvaOrzCaeTvw",
    descriptionShort: "Terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen  ten dienste van de exploitatie",
  },
  {
    code: "BMvaOrzCuh",
    descriptionShort: "Cumulatieve herwaarderingen",
    descriptionLong:
      "Cumulatieve herwaarderingen  ten dienste van de exploitatie",
  },
  {
    code: "BMvaOrzCuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar)  ten dienste van de exploitatie",
  },
  {
    code: "BMvaOrzCuhHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong: "Herwaarderingen  ten dienste van de exploitatie",
  },
  {
    code: "BMvaOrzCuhAfh",
    descriptionShort: "Afschrijving herwaarderingen",
    descriptionLong:
      "Afschrijving herwaarderingen  ten dienste van de exploitatie",
  },
  {
    code: "BMvaOrzCuhDeh",
    descriptionShort: "Desinvestering herwaarderingen",
    descriptionLong:
      "Desinvestering herwaarderingen  ten dienste van de exploitatie",
  },
  {
    code: "BVas",
    descriptionShort: "Vastgoedbeleggingen",
    descriptionLong: "Vastgoedbeleggingen",
  },
  {
    code: "BVasVio",
    descriptionShort:
      "Vastgoedbeleggingen in ontwikkeling bestemd voor eigen exploitatie",
    descriptionLong:
      "Vastgoedbeleggingen in ontwikkeling bestemd voor eigen exploitatie",
  },
  {
    code: "BVasVioVvp",
    descriptionShort: "Kostprijs",
    descriptionLong: "Kostprijs vastgoedbeleggingen in ontwikkeling",
  },
  {
    code: "BVasVioVvpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vastgoedbeleggingen in ontwikkeling",
  },
  {
    code: "BVasVioVvpIna",
    descriptionShort: "Initiële verkrijgingen",
    descriptionLong: "Initiële verkrijgingen ",
  },
  {
    code: "BVasVioVvpUne",
    descriptionShort: "Uitgaven na eerste waardering",
    descriptionLong: "Uitgaven na eerste waardering ",
  },
  {
    code: "BVasVioVvpAdo",
    descriptionShort: "Investeringen door overnames",
    descriptionLong: "Investeringen door overnames ",
  },
  {
    code: "BVasVioVvpHcv",
    descriptionShort:
      "Herclassificaties van en naar vastgoed verkocht onder voorwaarden",
    descriptionLong:
      "Herclassificaties van en naar vastgoed verkocht onder voorwaarden",
  },
  {
    code: "BVasVioVvpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen ",
  },
  {
    code: "BVasVioVvpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen",
  },
  {
    code: "BVasVioVvpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen ",
  },
  {
    code: "BVasVioVvpOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen ",
  },
  {
    code: "BVasVioVvpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties ",
  },
  {
    code: "BVasVioAkp",
    descriptionShort: "Actuele kostprijs",
    descriptionLong: "Actuele kostprijs vastgoedbeleggingen in ontwikkeling",
  },
  {
    code: "BVasVioAkpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vastgoedbeleggingen in ontwikkeling",
  },
  {
    code: "BVasVioAkpIna",
    descriptionShort: "Initiële verkrijgingen",
    descriptionLong: "Initiële verkrijgingen ",
  },
  {
    code: "BVasVioAkpUne",
    descriptionShort: "Uitgaven na eerste waardering",
    descriptionLong: "Uitgaven na eerste waardering ",
  },
  {
    code: "BVasVioAkpAdo",
    descriptionShort: "Investeringen door overnames",
    descriptionLong: "Investeringen door overnames ",
  },
  {
    code: "BVasVioAkpHcv",
    descriptionShort:
      "Herclassificaties van en naar vastgoed verkocht onder voorwaarden",
    descriptionLong:
      "Herclassificaties van en naar vastgoed verkocht onder voorwaarden",
  },
  {
    code: "BVasVioAkpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen ",
  },
  {
    code: "BVasVioAkpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen",
  },
  {
    code: "BVasVioAkpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen ",
  },
  {
    code: "BVasVioAkpOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen ",
  },
  {
    code: "BVasVioAkpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties ",
  },
  {
    code: "BVasVioCae",
    descriptionShort: "Cumulatieve afschrijvingen en waardeverminderingen",
    descriptionLong: "Cumulatieve afschrijvingen en waardeverminderingen ",
  },
  {
    code: "BVasVioCaeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BVasVioCaeAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong: "Afschrijvingen ",
  },
  {
    code: "BVasVioCaeDca",
    descriptionShort: "Afschrijving op desinvesteringen",
    descriptionLong: "Afschrijving op desinvesteringen ",
  },
  {
    code: "BVasVioCaeWvr",
    descriptionShort: "Bijzondere waardeverminderingen",
    descriptionLong: "Bijzondere waardeverminderingen ",
  },
  {
    code: "BVasVioCaeTvw",
    descriptionShort: "Terugneming van bijzondere waardeverminderingen",
    descriptionLong: "Terugneming van bijzondere waardeverminderingen ",
  },
  {
    code: "BVasVioCaeOve",
    descriptionShort: "Overboeking van waardevermindering",
    descriptionLong: "Overboeking van waardevermindering",
  },
  {
    code: "BVasVioCaeDes",
    descriptionShort: "Desinvestering van waardevermindering",
    descriptionLong: "Desinvestering van waardevermindering",
  },
  {
    code: "BVasVioCuh",
    descriptionShort: "Cumulatieve herwaarderingen",
    descriptionLong: "Cumulatieve herwaarderingen ",
  },
  {
    code: "BVasVioCuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BVasVioCuhRaw",
    descriptionShort:
      "Resultaat als gevolg van aanpassingen van de reële waarde",
    descriptionLong:
      "Resultaat als gevolg van aanpassingen van de reële waarde ",
  },
  {
    code: "BVasVioCuhHer",
    descriptionShort: "Herwaarderingen commercieel vastgoed in exploitatie",
    descriptionLong: "Herwaarderingen commercieel vastgoed in exploitatie",
  },
  {
    code: "BVasVioCuhDeh",
    descriptionShort: "Desinvestering herwaarderingen",
    descriptionLong: "Desinvestering herwaarderingen",
  },
  {
    code: "BVasVioCuhEfs",
    descriptionShort: "Effecten stelselwijziging",
    descriptionLong: "Effecten stelselwijziging",
  },
  {
    code: "BVasSvi",
    descriptionShort: "Vastgoedbeleggingen in exploitatie",
    descriptionLong: "Vastgoedbeleggingen in exploitatie",
  },
  {
    code: "BVasSviVvp",
    descriptionShort: "Kostprijs",
    descriptionLong: "Kostprijs vastgoedbeleggingen in exploitatie",
  },
  {
    code: "BVasSviVvpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vastgoedbeleggingen in exploitatie",
  },
  {
    code: "BVasSviVvpIna",
    descriptionShort: "Initiële verkrijgingen",
    descriptionLong: "Initiële verkrijgingen ",
  },
  {
    code: "BVasSviVvpUne",
    descriptionShort: "Uitgaven na eerste waardering",
    descriptionLong: "Uitgaven na eerste waardering ",
  },
  {
    code: "BVasSviVvpAdo",
    descriptionShort: "Investeringen door overnames",
    descriptionLong: "Investeringen door overnames ",
  },
  {
    code: "BVasSviVvpHcv",
    descriptionShort:
      "Herclassificaties van en naar vastgoed verkocht onder voorwaarden",
    descriptionLong:
      "Herclassificaties van en naar vastgoed verkocht onder voorwaarden",
  },
  {
    code: "BVasSviVvpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen ",
  },
  {
    code: "BVasSviVvpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen",
  },
  {
    code: "BVasSviVvpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen ",
  },
  {
    code: "BVasSviVvpOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen ",
  },
  {
    code: "BVasSviVvpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties ",
  },
  {
    code: "BVasSviVvpHnd",
    descriptionShort: "Herclassificaties van en naar vastgoed Niet Daeb",
    descriptionLong: "Herclassificaties van en naar vastgoed Niet Daeb",
  },
  {
    code: "BVasSviAkp",
    descriptionShort: "Actuele kostprijs",
    descriptionLong: "Actuele kostprijs vastgoedbeleggingen in exploitatie",
  },
  {
    code: "BVasSviAkpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vastgoedbeleggingen in exploitatie",
  },
  {
    code: "BVasSviAkpIna",
    descriptionShort: "Initiële verkrijgingen",
    descriptionLong: "Initiële verkrijgingen ",
  },
  {
    code: "BVasSviAkpUne",
    descriptionShort: "Uitgaven na eerste waardering",
    descriptionLong: "Uitgaven na eerste waardering ",
  },
  {
    code: "BVasSviAkpAdo",
    descriptionShort: "Investeringen door overnames",
    descriptionLong: "Investeringen door overnames ",
  },
  {
    code: "BVasSviAkpHcv",
    descriptionShort:
      "Herclassificaties van en naar vastgoed verkocht onder voorwaarden",
    descriptionLong:
      "Herclassificaties van en naar vastgoed verkocht onder voorwaarden",
  },
  {
    code: "BVasSviAkpDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen ",
  },
  {
    code: "BVasSviAkpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen",
  },
  {
    code: "BVasSviAkpOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen ",
  },
  {
    code: "BVasSviAkpOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen ",
  },
  {
    code: "BVasSviAkpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties ",
  },
  {
    code: "BVasSviAkpHnd",
    descriptionShort: "Herclassificaties van en naar vastgoed Niet Daeb",
    descriptionLong: "Herclassificaties van en naar vastgoed Niet Daeb",
  },
  {
    code: "BVasSviCae",
    descriptionShort: "Cumulatieve afschrijvingen en waardeverminderingen",
    descriptionLong: "Cumulatieve afschrijvingen en waardeverminderingen ",
  },
  {
    code: "BVasSviCaeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BVasSviCaeAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong: "Afschrijvingen ",
  },
  {
    code: "BVasSviCaeDca",
    descriptionShort: "Afschrijving op desinvesteringen",
    descriptionLong: "Afschrijving op desinvesteringen ",
  },
  {
    code: "BVasSviCaeWvr",
    descriptionShort: "Bijzondere waardeverminderingen",
    descriptionLong: "Bijzondere waardeverminderingen ",
  },
  {
    code: "BVasSviCaeTvw",
    descriptionShort: "Terugneming van bijzondere waardeverminderingen",
    descriptionLong: "Terugneming van bijzondere waardeverminderingen ",
  },
  {
    code: "BVasSviCaeDes",
    descriptionShort: "Desinvesteringen bijzondere waardeverminderingen",
    descriptionLong: "Desinvesteringen bijzondere waardeverminderingen",
  },
  {
    code: "BVasSviCaeHcb",
    descriptionShort:
      "Herclassificatie bijzondere waardevermindering en afschrijving",
    descriptionLong:
      "Herclassificatie bijzondere waardevermindering en afschrijving",
  },
  {
    code: "BVasSviCuh",
    descriptionShort: "Cumulatieve herwaarderingen",
    descriptionLong: "Cumulatieve herwaarderingen ",
  },
  {
    code: "BVasSviCuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BVasSviCuhRaw",
    descriptionShort:
      "Resultaat als gevolg van aanpassingen van de reële waarde",
    descriptionLong:
      "Resultaat als gevolg van aanpassingen van de reële waarde ",
  },
  {
    code: "BVasSviCuhHer",
    descriptionShort: "Herwaarderingen sociaal vastgoed in exploitatie",
    descriptionLong: "Herwaarderingen sociaal vastgoed in exploitatie",
  },
  {
    code: "BVasSviCuhDeh",
    descriptionShort: "Desinvestering herwaarderingen",
    descriptionLong: "Desinvestering herwaarderingen",
  },
  {
    code: "BVasSviCuhHch",
    descriptionShort: "Herclassificatie herwaarderingen",
    descriptionLong: "Herclassificatie herwaarderingen",
  },
  {
    code: "BVasSviCuhEfs",
    descriptionShort: "Effecten stelselwijziging",
    descriptionLong: "Effecten stelselwijziging",
  },
  {
    code: "BVasCvi",
    descriptionShort: "Niet -Daeb-vastgoed in exploitatie",
    descriptionLong: "Niet -Daeb-vastgoed in exploitatie",
  },
  {
    code: "BVasCviVvp",
    descriptionShort: "Kostprijs Niet-Daeb- vastgoed in exploitatie",
    descriptionLong: "Kostprijs Niet-Daeb- vastgoed in exploitatie",
  },
  {
    code: "BVasCviVvpBeg",
    descriptionShort: "Beginbalans commercieel vastgoed in exploitatie",
    descriptionLong: "Beginbalans commercieel vastgoed in exploitatie",
  },
  {
    code: "BVasCviVvpIna",
    descriptionShort: "Initiële verkrijgingen ",
    descriptionLong: "Initiële verkrijgingen ",
  },
  {
    code: "BVasCviVvpUne",
    descriptionShort: "Uitgaven na eerste waardering ",
    descriptionLong: "Uitgaven na eerste waardering ",
  },
  {
    code: "BVasCviVvpAdo",
    descriptionShort:
      "Aankopen door overnames commercieel vastgoed in exploitatie",
    descriptionLong:
      "Aankopen door overnames commercieel vastgoed in exploitatie",
  },
  {
    code: "BVasCviVvpHcv",
    descriptionShort:
      "Herclassificaties van en naar vastgoed verkocht onder voorwaarden",
    descriptionLong:
      "Herclassificaties van en naar vastgoed verkocht onder voorwaarden",
  },
  {
    code: "BVasCviVvpDes",
    descriptionShort: "Desinvesteringen commercieel vastgoed in exploitatie",
    descriptionLong: "Desinvesteringen commercieel vastgoed in exploitatie",
  },
  {
    code: "BVasCviVvpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen",
  },
  {
    code: "BVasCviVvpOmv",
    descriptionShort:
      "Omrekeningsverschillen commercieel vastgoed in exploitatie",
    descriptionLong:
      "Omrekeningsverschillen commercieel vastgoed in exploitatie",
  },
  {
    code: "BVasCviVvpOve",
    descriptionShort: "Overboekingen commercieel vastgoed in exploitatie",
    descriptionLong: "Overboekingen commercieel vastgoed in exploitatie",
  },
  {
    code: "BVasCviVvpOvm",
    descriptionShort: "Overige mutaties commercieel vastgoed in exploitatie",
    descriptionLong: "Overige mutaties commercieel vastgoed in exploitatie",
  },
  {
    code: "BVasCviVvpHcd",
    descriptionShort: "Herclassificaties van en naar vastgoed Daeb",
    descriptionLong: "Herclassificaties van en naar vastgoed Daeb",
  },
  {
    code: "BVasCviAkp",
    descriptionShort: "Actuele kostprijs Niet-Daeb- vastgoed in exploitatie",
    descriptionLong: "Actuele kostprijs Niet-Daeb- vastgoed in exploitatie",
  },
  {
    code: "BVasCviAkpBeg",
    descriptionShort: "Beginbalans commercieel vastgoed in exploitatie",
    descriptionLong: "Beginbalans commercieel vastgoed in exploitatie",
  },
  {
    code: "BVasCviAkpIna",
    descriptionShort: "Initiële verkrijgingen ",
    descriptionLong: "Initiële verkrijgingen ",
  },
  {
    code: "BVasCviAkpUne",
    descriptionShort: "Uitgaven na eerste waardering ",
    descriptionLong: "Uitgaven na eerste waardering ",
  },
  {
    code: "BVasCviAkpAdo",
    descriptionShort:
      "Aankopen door overnames commercieel vastgoed in exploitatie",
    descriptionLong:
      "Aankopen door overnames commercieel vastgoed in exploitatie",
  },
  {
    code: "BVasCviAkpHcv",
    descriptionShort:
      "Herclassificaties van en naar vastgoed verkocht onder voorwaarden",
    descriptionLong:
      "Herclassificaties van en naar vastgoed verkocht onder voorwaarden",
  },
  {
    code: "BVasCviAkpDes",
    descriptionShort: "Desinvesteringen commercieel vastgoed in exploitatie",
    descriptionLong: "Desinvesteringen commercieel vastgoed in exploitatie",
  },
  {
    code: "BVasCviAkpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen",
  },
  {
    code: "BVasCviAkpOmv",
    descriptionShort:
      "Omrekeningsverschillen commercieel vastgoed in exploitatie",
    descriptionLong:
      "Omrekeningsverschillen commercieel vastgoed in exploitatie",
  },
  {
    code: "BVasCviAkpOve",
    descriptionShort: "Overboekingen commercieel vastgoed in exploitatie",
    descriptionLong: "Overboekingen commercieel vastgoed in exploitatie",
  },
  {
    code: "BVasCviAkpOvm",
    descriptionShort: "Overige mutaties commercieel vastgoed in exploitatie",
    descriptionLong: "Overige mutaties commercieel vastgoed in exploitatie",
  },
  {
    code: "BVasCviAkpHcd",
    descriptionShort: "Herclassificaties van en naar vastgoed Daeb",
    descriptionLong: "Herclassificaties van en naar vastgoed Daeb",
  },
  {
    code: "BVasCviCae",
    descriptionShort:
      "Cumulatieve afschrijvingen en waardeverminderingen Niet-Daeb-vastgoed in exploitatie",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen Niet-Daeb-vastgoed in exploitatie",
  },
  {
    code: "BVasCviCaeBeg",
    descriptionShort: "Beginbalans commercieel vastgoed in exploitatie",
    descriptionLong: "Beginbalans commercieel vastgoed in exploitatie",
  },
  {
    code: "BVasCviCaeAfs",
    descriptionShort: "Afschrijvingen commercieel vastgoed in exploitatie",
    descriptionLong: "Afschrijvingen commercieel vastgoed in exploitatie",
  },
  {
    code: "BVasCviCaeDca",
    descriptionShort:
      "Desinvestering cumulatieve afschrijvingen en waardeverminderingen commercieel vastgoed in exploitatie",
    descriptionLong:
      "Desinvestering cumulatieve afschrijvingen en waardeverminderingen commercieel vastgoed in exploitatie",
  },
  {
    code: "BVasCviCaeWvr",
    descriptionShort:
      "Waardeverminderingen commercieel vastgoed in exploitatie",
    descriptionLong: "Waardeverminderingen commercieel vastgoed in exploitatie",
  },
  {
    code: "BVasCviCaeTvw",
    descriptionShort:
      "Terugneming van waardeverminderingen commercieel vastgoed in exploitatie",
    descriptionLong:
      "Terugneming van waardeverminderingen commercieel vastgoed in exploitatie",
  },
  {
    code: "BVasCviCaeDes",
    descriptionShort: "Desinvesteringen bijzondere waardeverminderingen",
    descriptionLong: "Desinvesteringen bijzondere waardeverminderingen",
  },
  {
    code: "BVasCviCaeHcb",
    descriptionShort:
      "Herclassificatie bijzondere waardevermindering en afschrijving",
    descriptionLong:
      "Herclassificatie bijzondere waardevermindering en afschrijving",
  },
  {
    code: "BVasCviCuh",
    descriptionShort:
      "Cumulatieve herwaarderingen Niet-Daeb-vastgoed in exploitatie",
    descriptionLong:
      "Cumulatieve herwaarderingen Niet-Daeb-vastgoed in exploitatie",
  },
  {
    code: "BVasCviCuhBeg",
    descriptionShort: "Beginbalans commercieel vastgoed in exploitatie",
    descriptionLong: "Beginbalans commercieel vastgoed in exploitatie",
  },
  {
    code: "BVasCviCuhRaw",
    descriptionShort:
      "Resultaat als gevolg van aanpassingen van de reële waarde",
    descriptionLong:
      "Resultaat als gevolg van aanpassingen van de reële waarde ",
  },
  {
    code: "BVasCviCuhHer",
    descriptionShort: "Herwaarderingen commercieel vastgoed in exploitatie",
    descriptionLong: "Herwaarderingen commercieel vastgoed in exploitatie",
  },
  {
    code: "BVasCviCuhDeh",
    descriptionShort: "Desinvestering herwaarderingen",
    descriptionLong: "Desinvestering herwaarderingen",
  },
  {
    code: "BVasCviCuhHch",
    descriptionShort: "Herclassificatie herwaarderingen",
    descriptionLong: "Herclassificatie herwaarderingen",
  },
  {
    code: "BVasCviCuhEfs",
    descriptionShort: "Effecten stelselwijziging",
    descriptionLong: "Effecten stelselwijziging",
  },
  {
    code: "BVasOzv",
    descriptionShort: "Onroerende zaken verkocht onder voorwaarden",
    descriptionLong: "Onroerende zaken verkocht onder voorwaarden",
  },
  {
    code: "BVasOzvVvp",
    descriptionShort:
      "Verkrijgings- of vervaardigingsprijs onroerende zaken verkocht onder voorwaarden",
    descriptionLong:
      "Verkrijgings- of vervaardigingsprijs onroerende zaken verkocht onder voorwaarden",
  },
  {
    code: "BVasOzvVvpBeg",
    descriptionShort: "Beginbalans onroerende zaken verkocht onder voorwaarden",
    descriptionLong: "Beginbalans onroerende zaken verkocht onder voorwaarden",
  },
  {
    code: "BVasOzvVvpIna",
    descriptionShort:
      "Investeringen nieuw aangeschaft onroerende zaken verkocht onder voorwaarden",
    descriptionLong:
      "Investeringen nieuw aangeschaft onroerende zaken verkocht onder voorwaarden",
  },
  {
    code: "BVasOzvVvpUne",
    descriptionShort: "Uitgaven na eerste waardering",
    descriptionLong: "Uitgaven na eerste waardering ",
  },
  {
    code: "BVasOzvVvpAdo",
    descriptionShort:
      "Aankopen door overnames onroerende zaken verkocht onder voorwaarden",
    descriptionLong:
      "Aankopen door overnames onroerende zaken verkocht onder voorwaarden",
  },
  {
    code: "BVasOzvVvpHcv",
    descriptionShort:
      "Herclassificaties van en naar vastgoed verkocht onder voorwaarden",
    descriptionLong:
      "Herclassificaties van en naar vastgoed verkocht onder voorwaarden",
  },
  {
    code: "BVasOzvVvpDes",
    descriptionShort:
      "Desinvesteringen onroerende zaken verkocht onder voorwaarden",
    descriptionLong:
      "Desinvesteringen onroerende zaken verkocht onder voorwaarden",
  },
  {
    code: "BVasOzvVvpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen",
  },
  {
    code: "BVasOzvVvpOmv",
    descriptionShort:
      "Omrekeningsverschillen onroerende zaken verkocht onder voorwaarden",
    descriptionLong:
      "Omrekeningsverschillen onroerende zaken verkocht onder voorwaarden",
  },
  {
    code: "BVasOzvVvpOve",
    descriptionShort:
      "Overboekingen onroerende zaken verkocht onder voorwaarden",
    descriptionLong:
      "Overboekingen onroerende zaken verkocht onder voorwaarden",
  },
  {
    code: "BVasOzvVvpOvm",
    descriptionShort:
      "Overige mutaties onroerende zaken verkocht onder voorwaarden",
    descriptionLong:
      "Overige mutaties onroerende zaken verkocht onder voorwaarden",
  },
  {
    code: "BVasOzvAkp",
    descriptionShort:
      "Actuele kostprijs onroerende zaken verkocht onder voorwaarden",
    descriptionLong:
      "Actuele kostprijs onroerende zaken verkocht onder voorwaarden",
  },
  {
    code: "BVasOzvAkpBeg",
    descriptionShort: "Beginbalans onroerende zaken verkocht onder voorwaarden",
    descriptionLong: "Beginbalans onroerende zaken verkocht onder voorwaarden",
  },
  {
    code: "BVasOzvAkpIna",
    descriptionShort:
      "Investeringen nieuw aangeschaft onroerende zaken verkocht onder voorwaarden",
    descriptionLong:
      "Investeringen nieuw aangeschaft onroerende zaken verkocht onder voorwaarden",
  },
  {
    code: "BVasOzvAkpUne",
    descriptionShort: "Uitgaven na eerste waardering",
    descriptionLong: "Uitgaven na eerste waardering ",
  },
  {
    code: "BVasOzvAkpAdo",
    descriptionShort:
      "Aankopen door overnames onroerende zaken verkocht onder voorwaarden",
    descriptionLong:
      "Aankopen door overnames onroerende zaken verkocht onder voorwaarden",
  },
  {
    code: "BVasOzvAkpHcv",
    descriptionShort:
      "Herclassificaties van en naar vastgoed verkocht onder voorwaarden",
    descriptionLong:
      "Herclassificaties van en naar vastgoed verkocht onder voorwaarden",
  },
  {
    code: "BVasOzvAkpDes",
    descriptionShort:
      "Desinvesteringen onroerende zaken verkocht onder voorwaarden",
    descriptionLong:
      "Desinvesteringen onroerende zaken verkocht onder voorwaarden",
  },
  {
    code: "BVasOzvAkpDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen",
  },
  {
    code: "BVasOzvAkpOmv",
    descriptionShort:
      "Omrekeningsverschillen onroerende zaken verkocht onder voorwaarden",
    descriptionLong:
      "Omrekeningsverschillen onroerende zaken verkocht onder voorwaarden",
  },
  {
    code: "BVasOzvAkpOve",
    descriptionShort:
      "Overboekingen onroerende zaken verkocht onder voorwaarden",
    descriptionLong:
      "Overboekingen onroerende zaken verkocht onder voorwaarden",
  },
  {
    code: "BVasOzvAkpOvm",
    descriptionShort:
      "Overige mutaties onroerende zaken verkocht onder voorwaarden",
    descriptionLong:
      "Overige mutaties onroerende zaken verkocht onder voorwaarden",
  },
  {
    code: "BVasOzvCae",
    descriptionShort:
      "Cumulatieve afschrijvingen en waardeverminderingen onroerende zaken verkocht onder voorwaarden",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen onroerende zaken verkocht onder voorwaarden",
  },
  {
    code: "BVasOzvCaeBeg",
    descriptionShort: "Beginbalans onroerende zaken verkocht onder voorwaarden",
    descriptionLong: "Beginbalans onroerende zaken verkocht onder voorwaarden",
  },
  {
    code: "BVasOzvCaeAfs",
    descriptionShort:
      "Afschrijvingen onroerende zaken verkocht onder voorwaarden",
    descriptionLong:
      "Afschrijvingen onroerende zaken verkocht onder voorwaarden",
  },
  {
    code: "BVasOzvCaeDca",
    descriptionShort:
      "Desinvestering cumulatieve afschrijvingen en waardeverminderingen onroerende zaken verkocht onder voorwaarden",
    descriptionLong:
      "Desinvestering cumulatieve afschrijvingen en waardeverminderingen onroerende zaken verkocht onder voorwaarden",
  },
  {
    code: "BVasOzvCaeWvr",
    descriptionShort:
      "Waardeverminderingen onroerende zaken verkocht onder voorwaarden",
    descriptionLong:
      "Waardeverminderingen onroerende zaken verkocht onder voorwaarden",
  },
  {
    code: "BVasOzvCaeTvw",
    descriptionShort:
      "Terugneming van waardeverminderingen onroerende zaken verkocht onder voorwaarden",
    descriptionLong:
      "Terugneming van waardeverminderingen onroerende zaken verkocht onder voorwaarden",
  },
  {
    code: "BVasOzvCuh",
    descriptionShort:
      "Cumulatieve herwaarderingen onroerende zaken verkocht onder voorwaarden",
    descriptionLong:
      "Cumulatieve herwaarderingen onroerende zaken verkocht onder voorwaarden",
  },
  {
    code: "BVasOzvCuhBeg",
    descriptionShort: "Beginbalans onroerende zaken verkocht onder voorwaarden",
    descriptionLong: "Beginbalans onroerende zaken verkocht onder voorwaarden",
  },
  {
    code: "BVasOzvCuhRaw",
    descriptionShort:
      "Resultaat als gevolg van aanpassingen van de reële waarde",
    descriptionLong:
      "Resultaat als gevolg van aanpassingen van de reële waarde ",
  },
  {
    code: "BVasOzvCuhHer",
    descriptionShort:
      "Herwaarderingen onroerende zaken verkocht onder voorwaarden",
    descriptionLong:
      "Herwaarderingen onroerende zaken verkocht onder voorwaarden",
  },
  {
    code: "BVasOzvCuhDeh",
    descriptionShort: "Desinvestering herwaarderingen",
    descriptionLong: "Desinvestering herwaarderingen",
  },
  {
    code: "BVasOzvCuhHch",
    descriptionShort: "Herclassificatie herwaarderingen",
    descriptionLong: "Herclassificatie herwaarderingen",
  },
  {
    code: "BVasOzvCuhEfs",
    descriptionShort: "Effecten stelselwijziging",
    descriptionLong: "Effecten stelselwijziging",
  },
  {
    code: "BFva",
    descriptionShort: "Financiële vaste activa",
    descriptionLong: "Financiële vaste activa",
  },
  {
    code: "BFvaDig",
    descriptionShort: "Deelnemingen in groepsmaatschappijen",
    descriptionLong: "Deelnemingen in groepsmaatschappijen",
  },
  {
    code: "BFvaDigNev",
    descriptionShort: "Verkrijgingsprijs",
    descriptionLong: "Verkrijgingsprijs deelnemingen in groepsmaatschappijen",
  },
  {
    code: "BFvaDigNevBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) deelnemingen in groepsmaatschappijen",
  },
  {
    code: "BFvaDigNevInv",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen deelnemingen in groepsmaatschappijen",
  },
  {
    code: "BFvaDigNevAdo",
    descriptionShort: "Bij overname verkregen activa",
    descriptionLong:
      "Bij overname verkregen activa deelnemingen in groepsmaatschappijen",
  },
  {
    code: "BFvaDigNevDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen deelnemingen in groepsmaatschappijen",
  },
  {
    code: "BFvaDigNevDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen deelnemingen in groepsmaatschappijen",
  },
  {
    code: "BFvaDigNevOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong:
      "Omrekeningsverschillen deelnemingen in groepsmaatschappijen",
  },
  {
    code: "BFvaDigNevOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties deelnemingen in groepsmaatschappijen",
  },
  {
    code: "BFvaDigCae",
    descriptionShort: "Cumulatieve afschrijvingen en waardeverminderingen",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen deelnemingen in groepsmaatschappijen",
  },
  {
    code: "BFvaDigCaeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) deelnemingen in groepsmaatschappijen",
  },
  {
    code: "BFvaDigCaeAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong: "Afschrijvingen deelnemingen in groepsmaatschappijen",
  },
  {
    code: "BFvaDigCaeDca",
    descriptionShort: "Afschrijving op desinvesteringen",
    descriptionLong:
      "Afschrijving op desinvesteringen deelnemingen in groepsmaatschappijen",
  },
  {
    code: "BFvaDigCaeWvr",
    descriptionShort: "Bijzondere waardeverminderingen",
    descriptionLong:
      "Bijzondere waardeverminderingen deelnemingen in groepsmaatschappijen",
  },
  {
    code: "BFvaDigCaeTvw",
    descriptionShort: "Terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen deelnemingen in groepsmaatschappijen",
  },
  {
    code: "BFvaDigCuh",
    descriptionShort: "Cumulatieve herwaarderingen",
    descriptionLong:
      "Cumulatieve herwaarderingen deelnemingen in groepsmaatschappijen",
  },
  {
    code: "BFvaDigCuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) deelnemingen in groepsmaatschappijen",
  },
  {
    code: "BFvaDigCuhHer",
    descriptionShort: "Herwaarderingen deelnemingen in groepsmaatschappijen",
    descriptionLong: "Herwaarderingen deelnemingen in groepsmaatschappijen",
  },
  {
    code: "BFvaDigCuhAir",
    descriptionShort: "Aandeel in resultaat deelnemingen",
    descriptionLong:
      "Aandeel in resultaat deelnemingen deelnemingen in groepsmaatschappijen",
  },
  {
    code: "BFvaDigCuhDvd",
    descriptionShort: "Dividend van deelnemingen",
    descriptionLong:
      "Dividend van deelnemingen deelnemingen in groepsmaatschappijen",
  },
  {
    code: "BFvaDigCuhAfh",
    descriptionShort: "Afschrijving herwaardering",
    descriptionLong: "Afschrijving herwaardering",
  },
  {
    code: "BFvaDigCuhDeh",
    descriptionShort: "Desinvestering herwaardering",
    descriptionLong: "Desinvestering herwaardering",
  },
  {
    code: "BFvaDio",
    descriptionShort: "Deelnemingen in overige verbonden maatschappijen",
    descriptionLong: "Deelnemingen in overige verbonden maatschappijen",
  },
  {
    code: "BFvaDioNev",
    descriptionShort: "Verkrijgingsprijs",
    descriptionLong:
      "Verkrijgingsprijs deelnemingen in overige verbonden maatschappijen",
  },
  {
    code: "BFvaDioNevBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) deelnemingen in overige verbonden maatschappijen",
  },
  {
    code: "BFvaDioNevInv",
    descriptionShort: "Investeringen",
    descriptionLong:
      "Investeringen deelnemingen in overige verbonden maatschappijen",
  },
  {
    code: "BFvaDioNevAdo",
    descriptionShort: "Bij overname verkregen activa",
    descriptionLong:
      "Bij overname verkregen activa deelnemingen in overige verbonden maatschappijen",
  },
  {
    code: "BFvaDioNevDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong:
      "Desinvesteringen deelnemingen in overige verbonden maatschappijen",
  },
  {
    code: "BFvaDioNevDda",
    descriptionShort: "Afstotingen",
    descriptionLong:
      "Afstotingen deelnemingen in overige verbonden maatschappijen",
  },
  {
    code: "BFvaDioNevOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong:
      "Omrekeningsverschillen deelnemingen in overige verbonden maatschappijen",
  },
  {
    code: "BFvaDioNevOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties deelnemingen in overige verbonden maatschappijen",
  },
  {
    code: "BFvaDioCae",
    descriptionShort: "Cumulatieve afschrijvingen en waardeverminderingen",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen deelnemingen in overige verbonden maatschappijen",
  },
  {
    code: "BFvaDioCaeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) deelnemingen in overige verbonden maatschappijen",
  },
  {
    code: "BFvaDioCaeAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong:
      "Afschrijvingen deelnemingen in overige verbonden maatschappijen",
  },
  {
    code: "BFvaDioCaeDca",
    descriptionShort: "Afschrijving op desinvesteringen",
    descriptionLong:
      "Afschrijving op desinvesteringen deelnemingen in overige verbonden maatschappijen",
  },
  {
    code: "BFvaDioCaeWvr",
    descriptionShort: "Bijzondere waardeverminderingen",
    descriptionLong:
      "Bijzondere waardeverminderingen deelnemingen in overige verbonden maatschappijen",
  },
  {
    code: "BFvaDioCaeTvw",
    descriptionShort: "Terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen deelnemingen in overige verbonden maatschappijen",
  },
  {
    code: "BFvaDioCuh",
    descriptionShort: "Cumulatieve herwaarderingen",
    descriptionLong:
      "Cumulatieve herwaarderingen deelnemingen in overige verbonden maatschappijen",
  },
  {
    code: "BFvaDioCuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) deelnemingen in overige verbonden maatschappijen",
  },
  {
    code: "BFvaDioCuhHer",
    descriptionShort:
      "Herwaarderingen deelnemingen in overige verbonden maatschappijen",
    descriptionLong:
      "Herwaarderingen deelnemingen in overige verbonden maatschappijen",
  },
  {
    code: "BFvaDioCuhAir",
    descriptionShort: "Aandeel in resultaat deelnemingen",
    descriptionLong:
      "Aandeel in resultaat deelnemingen deelnemingen in overige verbonden maatschappijen",
  },
  {
    code: "BFvaDioCuhDvd",
    descriptionShort: "Dividend van deelnemingen",
    descriptionLong:
      "Dividend van deelnemingen deelnemingen in overige verbonden maatschappijen",
  },
  {
    code: "BFvaVog",
    descriptionShort: "Vorderingen op groepsmaatschappijen",
    descriptionLong: "Vorderingen op groepsmaatschappijen",
  },
  {
    code: "BFvaVogVgl",
    descriptionShort: "Verkrijgingsprijs",
    descriptionLong: "Verkrijgingsprijs vorderingen op groepsmaatschappijen",
  },
  {
    code: "BFvaVogVglBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op groepsmaatschappijen",
  },
  {
    code: "BFvaVogVglVer",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen vorderingen op groepsmaatschappijen",
  },
  {
    code: "BFvaVogVglAfl",
    descriptionShort:
      "Aflossingen vorderingen op groepsmaatschappijen (langlopend)",
    descriptionLong:
      "Aflossingen vorderingen op groepsmaatschappijen (langlopend)",
  },
  {
    code: "BFvaVogVglRen",
    descriptionShort: "Rente vorderingen op groepsmaatschappijen (langlopend)",
    descriptionLong: "Rente vorderingen op groepsmaatschappijen (langlopend)",
  },
  {
    code: "BFvaVogVglAdo",
    descriptionShort: "Bij overname verkregen activa",
    descriptionLong:
      "Bij overname verkregen activa vorderingen op groepsmaatschappijen",
  },
  {
    code: "BFvaVogVglDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen vorderingen op groepsmaatschappijen",
  },
  {
    code: "BFvaVogVglDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen vorderingen op groepsmaatschappijen",
  },
  {
    code: "BFvaVogVglOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong:
      "Omrekeningsverschillen vorderingen op groepsmaatschappijen",
  },
  {
    code: "BFvaVogVglOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties vorderingen op groepsmaatschappijen",
  },
  {
    code: "BFvaVogCae",
    descriptionShort: "Cumulatieve afschrijvingen en waardeverminderingen",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen vorderingen op groepsmaatschappijen",
  },
  {
    code: "BFvaVogCaeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op groepsmaatschappijen",
  },
  {
    code: "BFvaVogCaeKod",
    descriptionShort:
      "Kortlopend deel vorderingen op groepsmaatschappijen (langlopend)",
    descriptionLong:
      "Kortlopend deel vorderingen op groepsmaatschappijen (langlopend)",
  },
  {
    code: "BFvaVogCaeAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong: "Afschrijvingen vorderingen op groepsmaatschappijen",
  },
  {
    code: "BFvaVogCaeDca",
    descriptionShort: "Afschrijving op desinvesteringen",
    descriptionLong:
      "Afschrijving op desinvesteringen vorderingen op groepsmaatschappijen",
  },
  {
    code: "BFvaVogCaeWvr",
    descriptionShort: "Bijzondere waardeverminderingen",
    descriptionLong:
      "Bijzondere waardeverminderingen vorderingen op groepsmaatschappijen",
  },
  {
    code: "BFvaVogCaeTvw",
    descriptionShort: "Terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen vorderingen op groepsmaatschappijen",
  },
  {
    code: "BFvaVogCuh",
    descriptionShort: "Cumulatieve herwaarderingen",
    descriptionLong:
      "Cumulatieve herwaarderingen vorderingen op groepsmaatschappijen",
  },
  {
    code: "BFvaVogCuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op groepsmaatschappijen",
  },
  {
    code: "BFvaVogCuhHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong: "Herwaarderingen",
  },
  {
    code: "BFvaVogCuhAir",
    descriptionShort: "Aandeel in resultaat deelnemingen",
    descriptionLong:
      "Aandeel in resultaat deelnemingen vorderingen op groepsmaatschappijen",
  },
  {
    code: "BFvaVogCuhDvd",
    descriptionShort: "Dividend van deelnemingen",
    descriptionLong:
      "Dividend van deelnemingen vorderingen op groepsmaatschappijen",
  },
  {
    code: "BFvaVogCuhAfh",
    descriptionShort: "Afschrijving herwaardering",
    descriptionLong: "Afschrijving herwaardering",
  },
  {
    code: "BFvaVogCuhDeh",
    descriptionShort: "Desinvestering herwaardering",
    descriptionLong: "Desinvestering herwaardering",
  },
  {
    code: "BFvaVov",
    descriptionShort: "Vorderingen op overige verbonden maatschappijen",
    descriptionLong: "Vorderingen op overige verbonden maatschappijen",
  },
  {
    code: "BFvaVovVol",
    descriptionShort: "Verkrijgingsprijs",
    descriptionLong:
      "Verkrijgingsprijs vorderingen op overige verbonden maatschappijen",
  },
  {
    code: "BFvaVovVolBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op overige verbonden maatschappijen",
  },
  {
    code: "BFvaVovVolVer",
    descriptionShort: "Investeringen",
    descriptionLong:
      "Investeringen vorderingen op overige verbonden maatschappijen",
  },
  {
    code: "BFvaVovVolAfl",
    descriptionShort:
      "Aflossingen vorderingen op overige verbonden maatschappijen (langlopend)",
    descriptionLong:
      "Aflossingen vorderingen op participanten en op maatschappijen waarin wordt deelgenomen (langlopend)",
  },
  {
    code: "BFvaVovVolAdo",
    descriptionShort: "Bij overname verkregen activa",
    descriptionLong:
      "Bij overname verkregen activa vorderingen op overige verbonden maatschappijen",
  },
  {
    code: "BFvaVovVolDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong:
      "Desinvesteringen vorderingen op overige verbonden maatschappijen",
  },
  {
    code: "BFvaVovVolDda",
    descriptionShort: "Afstotingen",
    descriptionLong:
      "Afstotingen vorderingen op overige verbonden maatschappijen",
  },
  {
    code: "BFvaVovVolOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong:
      "Omrekeningsverschillen vorderingen op overige verbonden maatschappijen",
  },
  {
    code: "BFvaVovVolOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties vorderingen op overige verbonden maatschappijen",
  },
  {
    code: "BFvaVovCae",
    descriptionShort: "Cumulatieve afschrijvingen en waardeverminderingen",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen vorderingen op overige verbonden maatschappijen",
  },
  {
    code: "BFvaVovCaeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op overige verbonden maatschappijen",
  },
  {
    code: "BFvaVovCaeKod",
    descriptionShort:
      "Kortlopend deel vorderingen op overige verbonden maatschappijen (langlopend)",
    descriptionLong:
      "Kortlopend deel vorderingen op overige verbonden maatschappijen (langlopend)",
  },
  {
    code: "BFvaVovCaeAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong:
      "Afschrijvingen vorderingen op overige verbonden maatschappijen",
  },
  {
    code: "BFvaVovCaeDca",
    descriptionShort: "Afschrijving op desinvesteringen",
    descriptionLong:
      "Afschrijving op desinvesteringen vorderingen op overige verbonden maatschappijen",
  },
  {
    code: "BFvaVovCaeWvr",
    descriptionShort: "Bijzondere waardeverminderingen",
    descriptionLong:
      "Bijzondere waardeverminderingen vorderingen op overige verbonden maatschappijen",
  },
  {
    code: "BFvaVovCaeTvw",
    descriptionShort: "Terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen vorderingen op overige verbonden maatschappijen",
  },
  {
    code: "BFvaVovCuh",
    descriptionShort: "Cumulatieve herwaarderingen",
    descriptionLong:
      "Cumulatieve herwaarderingen vorderingen op overige verbonden maatschappijen",
  },
  {
    code: "BFvaVovCuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op overige verbonden maatschappijen",
  },
  {
    code: "BFvaVovCuhHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong: "Herwaarderingen",
  },
  {
    code: "BFvaVovCuhAir",
    descriptionShort: "Aandeel in resultaat deelnemingen",
    descriptionLong:
      "Aandeel in resultaat deelnemingen vorderingen op overige verbonden maatschappijen",
  },
  {
    code: "BFvaVovCuhDvd",
    descriptionShort: "Dividend van deelnemingen",
    descriptionLong:
      "Dividend van deelnemingen vorderingen op overige verbonden maatschappijen",
  },
  {
    code: "BFvaAnd",
    descriptionShort: "Overige deelnemingen",
    descriptionLong: "Overige deelnemingen",
  },
  {
    code: "BFvaAndKpr",
    descriptionShort: "Verkrijgingsprijs",
    descriptionLong: "Verkrijgingsprijs overige deelnemingen",
  },
  {
    code: "BFvaAndKprBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) overige deelnemingen",
  },
  {
    code: "BFvaAndKprInv",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen overige deelnemingen",
  },
  {
    code: "BFvaAndKprAdo",
    descriptionShort: "Bij overname verkregen activa",
    descriptionLong: "Bij overname verkregen activa overige deelnemingen",
  },
  {
    code: "BFvaAndKprAfl",
    descriptionShort: "Aflossingen andere deelnemingen",
    descriptionLong: "Aflossingen andere deelnemingen",
  },
  {
    code: "BFvaAndKprRen",
    descriptionShort: "Rente andere deelnemingen",
    descriptionLong: "Rente andere deelnemingen",
  },
  {
    code: "BFvaAndKprDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen overige deelnemingen",
  },
  {
    code: "BFvaAndKprDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen overige deelnemingen",
  },
  {
    code: "BFvaAndKprOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen overige deelnemingen",
  },
  {
    code: "BFvaAndKprOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties overige deelnemingen",
  },
  {
    code: "BFvaAndCae",
    descriptionShort: "Cumulatieve afschrijvingen en waardeverminderingen",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen overige deelnemingen",
  },
  {
    code: "BFvaAndCaeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) overige deelnemingen",
  },
  {
    code: "BFvaAndCaeAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong: "Afschrijvingen overige deelnemingen",
  },
  {
    code: "BFvaAndCaeDca",
    descriptionShort: "Afschrijving op desinvesteringen",
    descriptionLong: "Afschrijving op desinvesteringen overige deelnemingen",
  },
  {
    code: "BFvaAndCaeWvr",
    descriptionShort: "Bijzondere waardeverminderingen",
    descriptionLong: "Bijzondere waardeverminderingen overige deelnemingen",
  },
  {
    code: "BFvaAndCaeTvw",
    descriptionShort: "Terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen overige deelnemingen",
  },
  {
    code: "BFvaAndCuh",
    descriptionShort: "Cumulatieve herwaarderingen",
    descriptionLong: "Cumulatieve herwaarderingen overige deelnemingen",
  },
  {
    code: "BFvaAndCuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) overige deelnemingen",
  },
  {
    code: "BFvaAndCuhHer",
    descriptionShort: "Herwaarderingen andere deelnemingen",
    descriptionLong: "Herwaarderingen andere deelnemingen",
  },
  {
    code: "BFvaAndCuhAir",
    descriptionShort: "Aandeel in resultaat deelnemingen",
    descriptionLong: "Aandeel in resultaat deelnemingen overige deelnemingen",
  },
  {
    code: "BFvaAndCuhDvd",
    descriptionShort: "Dividend van deelnemingen",
    descriptionLong: "Dividend van deelnemingen overige deelnemingen",
  },
  {
    code: "BFvaAndCuhAfh",
    descriptionShort: "Afschrijving herwaardering",
    descriptionLong: "Afschrijving herwaardering",
  },
  {
    code: "BFvaAndCuhDeh",
    descriptionShort: "Desinvestering herwaardering",
    descriptionLong: "Desinvestering herwaardering",
  },
  {
    code: "BFvaVop",
    descriptionShort:
      "Vorderingen op participanten en op maatschappijen waarin wordt deelgenomen",
    descriptionLong:
      "Vorderingen op participanten en op maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BFvaVopVpl",
    descriptionShort: "Verkrijgingsprijs",
    descriptionLong:
      "Verkrijgingsprijs vorderingen op participanten en op maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BFvaVopVplBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op participanten en op maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BFvaVopVplVer",
    descriptionShort: "Investeringen",
    descriptionLong:
      "Investeringen vorderingen op participanten en op maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BFvaVopVplAfl",
    descriptionShort:
      "Aflossingen vorderingen op participanten en op maatschappijen waarin wordt deelgenomen (langlopend)",
    descriptionLong:
      "Aflossingen vorderingen op participanten en op maatschappijen waarin wordt deelgenomen (langlopend)",
  },
  {
    code: "BFvaVopVplRen",
    descriptionShort:
      "Rente vorderingen op participnaten en op maatschappijen waarin wordt deelgenomen",
    descriptionLong:
      "Rente vorderingen op participnaten en op maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BFvaVopVplAdo",
    descriptionShort: "Bij overname verkregen activa",
    descriptionLong:
      "Bij overname verkregen activa vorderingen op participanten en op maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BFvaVopVplDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong:
      "Desinvesteringen vorderingen op participanten en op maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BFvaVopVplDda",
    descriptionShort: "Afstotingen",
    descriptionLong:
      "Afstotingen vorderingen op participanten en op maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BFvaVopVplOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong:
      "Omrekeningsverschillen vorderingen op participanten en op maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BFvaVopVplOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties vorderingen op participanten en op maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BFvaVopCae",
    descriptionShort: "Cumulatieve afschrijvingen en waardeverminderingen",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen vorderingen op participanten en op maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BFvaVopCaeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op participanten en op maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BFvaVopCaeKod",
    descriptionShort:
      "Kortlopend deel vorderingen op participanten en op maatschappijen waarin wordt deelgenomen (langlopend)",
    descriptionLong:
      "Kortlopend deel vorderingen op participanten en op maatschappijen waarin wordt deelgenomen (langlopend)",
  },
  {
    code: "BFvaVopCaeAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong:
      "Afschrijvingen vorderingen op participanten en op maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BFvaVopCaeDca",
    descriptionShort: "Afschrijving op desinvesteringen",
    descriptionLong:
      "Afschrijving op desinvesteringen vorderingen op participanten en op maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BFvaVopCaeWvr",
    descriptionShort: "Bijzondere waardeverminderingen",
    descriptionLong:
      "Bijzondere waardeverminderingen vorderingen op participanten en op maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BFvaVopCaeTvw",
    descriptionShort: "Terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen vorderingen op participanten en op maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BFvaVopCuh",
    descriptionShort: "Cumulatieve herwaarderingen",
    descriptionLong:
      "Cumulatieve herwaarderingen vorderingen op participanten en op maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BFvaVopCuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op participanten en op maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BFvaVopCuhHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong: "Herwaarderingen",
  },
  {
    code: "BFvaVopCuhAir",
    descriptionShort: "Aandeel in resultaat deelnemingen",
    descriptionLong:
      "Aandeel in resultaat deelnemingen vorderingen op participanten en op maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BFvaVopCuhDvd",
    descriptionShort: "Dividend van deelnemingen",
    descriptionLong:
      "Dividend van deelnemingen vorderingen op participanten en op maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BFvaVopCuhAfh",
    descriptionShort: "Afschrijving herwaardering",
    descriptionLong: "Afschrijving herwaardering",
  },
  {
    code: "BFvaVopCuhDeh",
    descriptionShort: "Desinvestering herwaardering",
    descriptionLong: "Desinvestering herwaardering",
  },
  {
    code: "BFvaOve",
    descriptionShort: "Overige effecten (langlopend)",
    descriptionLong: "Overige effecten (langlopend)",
  },
  {
    code: "BFvaOveWaa",
    descriptionShort: "Verkrijgingsprijs",
    descriptionLong: "Verkrijgingsprijs overige effecten (langlopend)",
  },
  {
    code: "BFvaOveWaaBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) overige effecten (langlopend)",
  },
  {
    code: "BFvaOveWaaInv",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen overige effecten (langlopend)",
  },
  {
    code: "BFvaOveWaaAdo",
    descriptionShort: "Bij overname verkregen activa",
    descriptionLong:
      "Bij overname verkregen activa overige effecten (langlopend)",
  },
  {
    code: "BFvaOveWaaVrk",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen overige effecten (langlopend)",
  },
  {
    code: "BFvaOveWaaWst",
    descriptionShort: "Waardestijgingen overige effecten",
    descriptionLong: "Waardestijgingen overige effecten",
  },
  {
    code: "BFvaOveWaaDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen overige effecten (langlopend)",
  },
  {
    code: "BFvaOveWaaOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen overige effecten (langlopend)",
  },
  {
    code: "BFvaOveWaaOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties overige effecten (langlopend)",
  },
  {
    code: "BFvaOveCuw",
    descriptionShort: "Cumulatieve afschrijvingen en waardeverminderingen",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen overige effecten (langlopend)",
  },
  {
    code: "BFvaOveCuwBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) overige effecten (langlopend)",
  },
  {
    code: "BFvaOveCuwAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong: "Afschrijvingen overige effecten (langlopend)",
  },
  {
    code: "BFvaOveCuwDca",
    descriptionShort: "Afschrijving op desinvesteringen",
    descriptionLong:
      "Afschrijving op desinvesteringen overige effecten (langlopend)",
  },
  {
    code: "BFvaOveCuwWvr",
    descriptionShort: "Bijzondere waardeverminderingen",
    descriptionLong:
      "Bijzondere waardeverminderingen overige effecten (langlopend)",
  },
  {
    code: "BFvaOveCuwTvw",
    descriptionShort: "Terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen overige effecten (langlopend)",
  },
  {
    code: "BFvaOveCuh",
    descriptionShort: "Cumulatieve herwaarderingen",
    descriptionLong:
      "Cumulatieve herwaarderingen overige effecten (langlopend)",
  },
  {
    code: "BFvaOveCuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) overige effecten (langlopend)",
  },
  {
    code: "BFvaOveCuhHer",
    descriptionShort: "Herwaarderingen overige effecten",
    descriptionLong: "Herwaarderingen overige effecten",
  },
  {
    code: "BFvaOveCuhAir",
    descriptionShort: "Aandeel in resultaat deelnemingen",
    descriptionLong:
      "Aandeel in resultaat deelnemingen overige effecten (langlopend)",
  },
  {
    code: "BFvaOveCuhDvd",
    descriptionShort: "Dividend van deelnemingen",
    descriptionLong: "Dividend van deelnemingen overige effecten (langlopend)",
  },
  {
    code: "BFvaOvr",
    descriptionShort: "Overige vorderingen",
    descriptionLong: "Overige vorderingen (langlopend)",
  },
  {
    code: "BFvaOvrVob",
    descriptionShort:
      "Hoofdsom leningen, voorschotten en garanties ten behoeve van bestuurders",
    descriptionLong:
      "Hoofdsom leningen, voorschotten en garanties ten behoeve van bestuurders (langlopend)",
  },
  {
    code: "BFvaOvrVobBe1",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op bestuurders 1",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op bestuurders 1 (langlopend)",
  },
  {
    code: "BFvaOvrVobBe2",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op bestuurders 2",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op bestuurders 2 (langlopend)",
  },
  {
    code: "BFvaOvrVobBe3",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op bestuurders 3",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op bestuurders 3 (langlopend)",
  },
  {
    code: "BFvaOvrVobBe4",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op bestuurders 4",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op bestuurders 4 (langlopend)",
  },
  {
    code: "BFvaOvrVobBe5",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op bestuurders 5",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op bestuurders 5 (langlopend)",
  },
  {
    code: "BFvaOvrVobBea",
    descriptionShort:
      "Toename leningen, voorschotten en garanties van bestuurders 1",
    descriptionLong:
      "Toename leningen, voorschotten en garanties van bestuurders 1 (langlopend)",
  },
  {
    code: "BFvaOvrVobBeb",
    descriptionShort:
      "Toename leningen, voorschotten en garanties van bestuurders 2",
    descriptionLong:
      "Toename leningen, voorschotten en garanties van bestuurders 2 (langlopend)",
  },
  {
    code: "BFvaOvrVobBec",
    descriptionShort:
      "Toename leningen, voorschotten en garanties van bestuurders 3",
    descriptionLong:
      "Toename leningen, voorschotten en garanties van bestuurders 3 (langlopend)",
  },
  {
    code: "BFvaOvrVobBed",
    descriptionShort:
      "Toename leningen, voorschotten en garanties van bestuurders 4",
    descriptionLong:
      "Toename leningen, voorschotten en garanties van bestuurders 4 (langlopend)",
  },
  {
    code: "BFvaOvrVobBee",
    descriptionShort:
      "Toename leningen, voorschotten en garanties van bestuurders 5",
    descriptionLong:
      "Toename leningen, voorschotten en garanties van bestuurders 5 (langlopend)",
  },
  {
    code: "BFvaOvrVobBef",
    descriptionShort:
      "Overige mutaties leningen, voorschotten en garanties van bestuurders 1",
    descriptionLong:
      "Overige mutaties leningen, voorschotten en garanties van bestuurders 1 (langlopend)",
  },
  {
    code: "BFvaOvrVobBeg",
    descriptionShort:
      "Overige mutaties leningen, voorschotten en garanties van bestuurders 2",
    descriptionLong:
      "Overige mutaties leningen, voorschotten en garanties van bestuurders 2 (langlopend)",
  },
  {
    code: "BFvaOvrVobBeh",
    descriptionShort:
      "Overige mutaties leningen, voorschotten en garanties van bestuurders 3",
    descriptionLong:
      "Overige mutaties leningen, voorschotten en garanties van bestuurders 3 (langlopend)",
  },
  {
    code: "BFvaOvrVobBei",
    descriptionShort:
      "Overige mutaties leningen, voorschotten en garanties van bestuurders 4",
    descriptionLong:
      "Overige mutaties leningen, voorschotten en garanties van bestuurders 4 (langlopend)",
  },
  {
    code: "BFvaOvrVobBej",
    descriptionShort:
      "Overige mutaties leningen, voorschotten en garanties van bestuurders 5",
    descriptionLong:
      "Overige mutaties leningen, voorschotten en garanties van bestuurders 5 (langlopend)",
  },
  {
    code: "BFvaOvrVoa",
    descriptionShort:
      "Cumulatieve aflossingen en waardeverminderingen leningen, voorschotten en garanties bestuurders",
    descriptionLong:
      "Cumulatieve aflossingen en waardeverminderingen leningen, voorschotten en garanties bestuurders (langlopend)",
  },
  {
    code: "BFvaOvrVoaBe1",
    descriptionShort:
      "Beginbalans cumulatieve aflossing vorderingen op bestuurders 1",
    descriptionLong:
      "Beginbalans cumulatieve aflossing vorderingen op bestuurders 1 (langlopend)",
  },
  {
    code: "BFvaOvrVoaBe2",
    descriptionShort:
      "Beginbalans cumulatieve aflossing vorderingen op bestuurders 2",
    descriptionLong:
      "Beginbalans cumulatieve aflossing vorderingen op bestuurders 2 (langlopend)",
  },
  {
    code: "BFvaOvrVoaBe3",
    descriptionShort:
      "Beginbalans cumulatieve aflossing vorderingen op bestuurders 3",
    descriptionLong:
      "Beginbalans cumulatieve aflossing vorderingen op bestuurders 3 (langlopend)",
  },
  {
    code: "BFvaOvrVoaBe4",
    descriptionShort:
      "Beginbalans cumulatieve aflossing vorderingen op bestuurders 4",
    descriptionLong:
      "Beginbalans cumulatieve aflossing vorderingen op bestuurders 4 (langlopend)",
  },
  {
    code: "BFvaOvrVoaBe5",
    descriptionShort:
      "Beginbalans cumulatieve aflossing vorderingen op bestuurders 5",
    descriptionLong:
      "Beginbalans cumulatieve aflossing vorderingen op bestuurders 5 (langlopend)",
  },
  {
    code: "BFvaOvrVoaBea",
    descriptionShort:
      "Aflossing / afname leningen, voorschotten en garanties bestuurders 1",
    descriptionLong:
      "Aflossing / afname leningen, voorschotten en garanties bestuurders 1 (langlopend)",
  },
  {
    code: "BFvaOvrVoaBeb",
    descriptionShort:
      "Aflossing / afname leningen, voorschotten en garanties bestuurders 2",
    descriptionLong:
      "Aflossing / afname leningen, voorschotten en garanties bestuurders 2 (langlopend)",
  },
  {
    code: "BFvaOvrVoaBec",
    descriptionShort:
      "Aflossing / afname leningen, voorschotten en garanties bestuurders 3",
    descriptionLong:
      "Aflossing / afname leningen, voorschotten en garanties bestuurders 3 (langlopend)",
  },
  {
    code: "BFvaOvrVoaBed",
    descriptionShort:
      "Aflossing / afname leningen, voorschotten en garanties bestuurders 4",
    descriptionLong:
      "Aflossing / afname leningen, voorschotten en garanties bestuurders 4 (langlopend)",
  },
  {
    code: "BFvaOvrVoaBee",
    descriptionShort:
      "Aflossing / afname leningen, voorschotten en garanties bestuurders 5",
    descriptionLong:
      "Aflossing / afname leningen, voorschotten en garanties bestuurders 5 (langlopend)",
  },
  {
    code: "BFvaOvrVgb",
    descriptionShort:
      "Hoofdsom leningen, voorschotten en garanties ten behoeve van gewezen bestuurders",
    descriptionLong:
      "Hoofdsom leningen, voorschotten en garanties ten behoeve van gewezen bestuurders (langlopend)",
  },
  {
    code: "BFvaOvrVgbBe1",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op gewezen bestuurders 1",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op gewezen bestuurders 1 (langlopend)",
  },
  {
    code: "BFvaOvrVgbBe2",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op gewezen bestuurders 2",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op gewezen bestuurders 2 (langlopend)",
  },
  {
    code: "BFvaOvrVgbBe3",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op gewezen bestuurders 3",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op gewezen bestuurders 3 (langlopend)",
  },
  {
    code: "BFvaOvrVgbBe4",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op gewezen bestuurders 4",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op gewezen bestuurders 4 (langlopend)",
  },
  {
    code: "BFvaOvrVgbBe5",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op gewezen bestuurders 5",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op gewezen bestuurders 5 (langlopend)",
  },
  {
    code: "BFvaOvrVgbBea",
    descriptionShort:
      "Toename leningen, voorschotten en garanties van gewezen bestuurders 1",
    descriptionLong:
      "Toename leningen, voorschotten en garanties van gewezen bestuurders 1",
  },
  {
    code: "BFvaOvrVgbBeb",
    descriptionShort:
      "Toename leningen, voorschotten en garanties van gewezen bestuurders 2",
    descriptionLong:
      "Toename leningen, voorschotten en garanties van gewezen bestuurders 2",
  },
  {
    code: "BFvaOvrVgbBec",
    descriptionShort:
      "Toename leningen, voorschotten en garanties van gewezen bestuurders 3",
    descriptionLong:
      "Toename leningen, voorschotten en garanties van gewezen bestuurders 3",
  },
  {
    code: "BFvaOvrVgbBed",
    descriptionShort:
      "Toename leningen, voorschotten en garanties van gewezen bestuurders 4",
    descriptionLong:
      "Toename leningen, voorschotten en garanties van gewezen bestuurders 4",
  },
  {
    code: "BFvaOvrVgbBee",
    descriptionShort:
      "Toename leningen, voorschotten en garanties van gewezen bestuurders 5",
    descriptionLong:
      "Toename leningen, voorschotten en garanties van gewezen bestuurders 5",
  },
  {
    code: "BFvaOvrVgbBef",
    descriptionShort:
      "Overige mutaties leningen, voorschotten en garanties van gewezen bestuurders 1",
    descriptionLong:
      "Overige mutaties leningen, voorschotten en garanties van gewezen bestuurders 1",
  },
  {
    code: "BFvaOvrVgbBeg",
    descriptionShort:
      "Overige mutaties leningen, voorschotten en garanties van gewezen bestuurders 2",
    descriptionLong:
      "Overige mutaties leningen, voorschotten en garanties van gewezen bestuurders 2",
  },
  {
    code: "BFvaOvrVgbBeh",
    descriptionShort:
      "Overige mutaties leningen, voorschotten en garanties van gewezen bestuurders 3",
    descriptionLong:
      "Overige mutaties leningen, voorschotten en garanties van gewezen bestuurders 3",
  },
  {
    code: "BFvaOvrVgbBei",
    descriptionShort:
      "Overige mutaties leningen, voorschotten en garanties van gewezen bestuurders 4",
    descriptionLong:
      "Overige mutaties leningen, voorschotten en garanties van gewezen bestuurders 4",
  },
  {
    code: "BFvaOvrVgbBej",
    descriptionShort:
      "Overige mutaties leningen, voorschotten en garanties van gewezen bestuurders 5",
    descriptionLong:
      "Overige mutaties leningen, voorschotten en garanties van gewezen bestuurders 5",
  },
  {
    code: "BFvaOvrVga",
    descriptionShort:
      "Cumulatieve aflossingen en waardeverminderingen leningen, voorschotten en garanties gewezen bestuurders (langlopend)",
    descriptionLong:
      "Cumulatieve aflossingen en waardeverminderingen leningen, voorschotten en garanties gewezen bestuurders (langlopend)",
  },
  {
    code: "BFvaOvrVgaBe1",
    descriptionShort:
      "Beginbalans cumulatieve aflossing vorderingen op gewezen bestuurders 1",
    descriptionLong:
      "Beginbalans cumulatieve aflossing vorderingen op gewezen bestuurders 1",
  },
  {
    code: "BFvaOvrVgaBe2",
    descriptionShort:
      "Beginbalans cumulatieve aflossing vorderingen op gewezen bestuurders 2",
    descriptionLong:
      "Beginbalans cumulatieve aflossing vorderingen op gewezen bestuurders 2",
  },
  {
    code: "BFvaOvrVgaBe3",
    descriptionShort:
      "Beginbalans cumulatieve aflossing vorderingen op gewezen bestuurders 3",
    descriptionLong:
      "Beginbalans cumulatieve aflossing vorderingen op gewezen bestuurders 3",
  },
  {
    code: "BFvaOvrVgaBe4",
    descriptionShort:
      "Beginbalans cumulatieve aflossing vorderingen op gewezen bestuurders 4",
    descriptionLong:
      "Beginbalans cumulatieve aflossing vorderingen op gewezen bestuurders 4",
  },
  {
    code: "BFvaOvrVgaBe5",
    descriptionShort:
      "Beginbalans cumulatieve aflossing vorderingen op gewezen bestuurders 5",
    descriptionLong:
      "Beginbalans cumulatieve aflossing vorderingen op gewezen bestuurders 5",
  },
  {
    code: "BFvaOvrVgaBea",
    descriptionShort:
      "Aflossing / afname leningen, voorschotten en garanties gewezen bestuurders 1",
    descriptionLong:
      "Aflossing / afname leningen, voorschotten en garanties gewezen bestuurders 1",
  },
  {
    code: "BFvaOvrVgaBeb",
    descriptionShort:
      "Aflossing / afname leningen, voorschotten en garanties gewezen bestuurders 2",
    descriptionLong:
      "Aflossing / afname leningen, voorschotten en garanties gewezen bestuurders 2",
  },
  {
    code: "BFvaOvrVgaBec",
    descriptionShort:
      "Aflossing / afname leningen, voorschotten en garanties gewezen bestuurders 3",
    descriptionLong:
      "Aflossing / afname leningen, voorschotten en garanties gewezen bestuurders 3",
  },
  {
    code: "BFvaOvrVgaBed",
    descriptionShort:
      "Aflossing / afname leningen, voorschotten en garanties gewezen bestuurders 4",
    descriptionLong:
      "Aflossing / afname leningen, voorschotten en garanties gewezen bestuurders 4",
  },
  {
    code: "BFvaOvrVgaBee",
    descriptionShort:
      "Aflossing / afname leningen, voorschotten en garanties gewezen bestuurders 5",
    descriptionLong:
      "Aflossing / afname leningen, voorschotten en garanties gewezen bestuurders 5",
  },
  {
    code: "BFvaOvrVoc",
    descriptionShort:
      "Hoofdsom leningen, voorschotten en garanties ten behoeve van commissarissen",
    descriptionLong:
      "Hoofdsom leningen, voorschotten en garanties ten behoeve van commissarissen (langlopend)",
  },
  {
    code: "BFvaOvrVocCo1",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op commissaris 1",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op commissaris 1 (langlopend)",
  },
  {
    code: "BFvaOvrVocCo2",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op commissaris 2",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op commissaris 2 (langlopend)",
  },
  {
    code: "BFvaOvrVocCo3",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op commissaris 3",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op commissaris 3 (langlopend)",
  },
  {
    code: "BFvaOvrVocCo4",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op commissaris 4",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op commissaris 4 (langlopend)",
  },
  {
    code: "BFvaOvrVocCo5",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op commissaris 5",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op commissaris 5 (langlopend)",
  },
  {
    code: "BFvaOvrVocBea",
    descriptionShort:
      "Toename leningen, voorschotten en garanties commissaris 1",
    descriptionLong:
      "Toename leningen, voorschotten en garanties commissaris 1",
  },
  {
    code: "BFvaOvrVocBeb",
    descriptionShort:
      "Toename leningen, voorschotten en garanties commissaris 2",
    descriptionLong:
      "Toename leningen, voorschotten en garanties commissaris 2",
  },
  {
    code: "BFvaOvrVocBec",
    descriptionShort:
      "Toename leningen, voorschotten en garanties commissaris 3",
    descriptionLong:
      "Toename leningen, voorschotten en garanties commissaris 3",
  },
  {
    code: "BFvaOvrVocBed",
    descriptionShort:
      "Toename leningen, voorschotten en garanties commissaris 4",
    descriptionLong:
      "Toename leningen, voorschotten en garanties commissaris 4",
  },
  {
    code: "BFvaOvrVocBee",
    descriptionShort:
      "Toename leningen, voorschotten en garanties commissaris 5",
    descriptionLong:
      "Toename leningen, voorschotten en garanties commissaris 5",
  },
  {
    code: "BFvaOvrVocBef",
    descriptionShort:
      "Overige mutaties leningen, voorschotten en garanties commissaris 1",
    descriptionLong:
      "Overige mutaties leningen, voorschotten en garanties commissaris 1",
  },
  {
    code: "BFvaOvrVocBeg",
    descriptionShort:
      "Overige mutaties leningen, voorschotten en garanties commissaris 2",
    descriptionLong:
      "Overige mutaties leningen, voorschotten en garanties commissaris 2",
  },
  {
    code: "BFvaOvrVocBeh",
    descriptionShort:
      "Overige mutaties leningen, voorschotten en garanties commissaris 3",
    descriptionLong:
      "Overige mutaties leningen, voorschotten en garanties commissaris 3",
  },
  {
    code: "BFvaOvrVocBei",
    descriptionShort:
      "Overige mutaties leningen, voorschotten en garanties commissaris 4",
    descriptionLong:
      "Overige mutaties leningen, voorschotten en garanties commissaris 4",
  },
  {
    code: "BFvaOvrVocBej",
    descriptionShort:
      "Overige mutaties leningen, voorschotten en garanties commissaris 5",
    descriptionLong:
      "Overige mutaties leningen, voorschotten en garanties commissaris 5",
  },
  {
    code: "BFvaOvrVca",
    descriptionShort:
      "Cumulatieve aflossingen en waardeverminderingen leningen, voorschotten en garanties commissarissen (langlopend)",
    descriptionLong:
      "Cumulatieve aflossingen en waardeverminderingen leningen, voorschotten en garanties commissarissen (langlopend)",
  },
  {
    code: "BFvaOvrVcaBe1",
    descriptionShort:
      "Beginbalans cumulatieve aflossing vorderingen op commissaris 1",
    descriptionLong:
      "Beginbalans cumulatieve aflossing vorderingen op commissaris 1",
  },
  {
    code: "BFvaOvrVcaBe2",
    descriptionShort:
      "Beginbalans cumulatieve aflossing vorderingen op commissaris 2",
    descriptionLong:
      "Beginbalans cumulatieve aflossing vorderingen op commissaris 2",
  },
  {
    code: "BFvaOvrVcaBe3",
    descriptionShort:
      "Beginbalans cumulatieve aflossing vorderingen op commissaris 3",
    descriptionLong:
      "Beginbalans cumulatieve aflossing vorderingen op commissaris 3",
  },
  {
    code: "BFvaOvrVcaBe4",
    descriptionShort:
      "Beginbalans cumulatieve aflossing vorderingen op commissaris 4",
    descriptionLong:
      "Beginbalans cumulatieve aflossing vorderingen op commissaris 4",
  },
  {
    code: "BFvaOvrVcaBe5",
    descriptionShort:
      "Beginbalans cumulatieve aflossing vorderingen op commissaris 5",
    descriptionLong:
      "Beginbalans cumulatieve aflossing vorderingen op commissaris 5",
  },
  {
    code: "BFvaOvrVcaBea",
    descriptionShort:
      "Aflossing / afname leningen, voorschotten en garanties commissaris 1",
    descriptionLong:
      "Aflossing / afname leningen, voorschotten en garanties commissaris 1",
  },
  {
    code: "BFvaOvrVcaBeb",
    descriptionShort:
      "Aflossing / afname leningen, voorschotten en garanties commissaris 2",
    descriptionLong:
      "Aflossing / afname leningen, voorschotten en garanties commissaris 2",
  },
  {
    code: "BFvaOvrVcaBec",
    descriptionShort:
      "Aflossing / afname leningen, voorschotten en garanties commissaris 3",
    descriptionLong:
      "Aflossing / afname leningen, voorschotten en garanties commissaris 3",
  },
  {
    code: "BFvaOvrVcaBed",
    descriptionShort:
      "Aflossing / afname leningen, voorschotten en garanties commissaris 4",
    descriptionLong:
      "Aflossing / afname leningen, voorschotten en garanties commissaris 4",
  },
  {
    code: "BFvaOvrVcaBee",
    descriptionShort:
      "Aflossing / afname leningen, voorschotten en garanties commissaris 5",
    descriptionLong:
      "Aflossing / afname leningen, voorschotten en garanties commissaris 5",
  },
  {
    code: "BFvaOvrVgc",
    descriptionShort:
      "Hoofdsom leningen, voorschotten en garanties ten behoeve van gewezen commissarissen",
    descriptionLong:
      "Hoofdsom leningen, voorschotten en garanties ten behoeve van gewezen commissarissen (langlopend)",
  },
  {
    code: "BFvaOvrVgcCo1",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op gewezen commissaris 1",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op gewezen commissaris 1 (langlopend)",
  },
  {
    code: "BFvaOvrVgcCo2",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op gewezen commissaris 2",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op gewezen commissaris 2 (langlopend)",
  },
  {
    code: "BFvaOvrVgcCo3",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op gewezen commissaris 3",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op gewezen commissaris 3 (langlopend)",
  },
  {
    code: "BFvaOvrVgcCo4",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op gewezen commissaris 4",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op gewezen commissaris 4 (langlopend)",
  },
  {
    code: "BFvaOvrVgcCo5",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op gewezen commissaris 5",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op gewezen commissaris 5 (langlopend)",
  },
  {
    code: "BFvaOvrVgcBea",
    descriptionShort:
      "Toename leningen, voorschotten en garanties gewezen commissaris 1",
    descriptionLong:
      "Toename leningen, voorschotten en garanties gewezen commissaris 1",
  },
  {
    code: "BFvaOvrVgcBeb",
    descriptionShort:
      "Toename leningen, voorschotten en garanties gewezen commissaris 2",
    descriptionLong:
      "Toename leningen, voorschotten en garanties gewezen commissaris 2",
  },
  {
    code: "BFvaOvrVgcBec",
    descriptionShort:
      "Toename leningen, voorschotten en garanties gewezen commissaris 3",
    descriptionLong:
      "Toename leningen, voorschotten en garanties gewezen commissaris 3",
  },
  {
    code: "BFvaOvrVgcBed",
    descriptionShort:
      "Toename leningen, voorschotten en garanties gewezen commissaris 4",
    descriptionLong:
      "Toename leningen, voorschotten en garanties gewezen commissaris 4",
  },
  {
    code: "BFvaOvrVgcBee",
    descriptionShort:
      "Toename leningen, voorschotten en garanties gewezen commissaris 5",
    descriptionLong:
      "Toename leningen, voorschotten en garanties gewezen commissaris 5",
  },
  {
    code: "BFvaOvrVgcBef",
    descriptionShort:
      "Overige mutaties leningen, voorschotten en garanties gewezen commissaris 1",
    descriptionLong:
      "Overige mutaties leningen, voorschotten en garanties gewezen commissaris 1",
  },
  {
    code: "BFvaOvrVgcBeg",
    descriptionShort:
      "Overige mutaties leningen, voorschotten en garanties gewezen commissaris 2",
    descriptionLong:
      "Overige mutaties leningen, voorschotten en garanties gewezen commissaris 2",
  },
  {
    code: "BFvaOvrVgcBeh",
    descriptionShort:
      "Overige mutaties leningen, voorschotten en garanties gewezen commissaris 3",
    descriptionLong:
      "Overige mutaties leningen, voorschotten en garanties gewezen commissaris 3",
  },
  {
    code: "BFvaOvrVgcBei",
    descriptionShort:
      "Overige mutaties leningen, voorschotten en garanties gewezen commissaris 4",
    descriptionLong:
      "Overige mutaties leningen, voorschotten en garanties gewezen commissaris 4",
  },
  {
    code: "BFvaOvrVgcBej",
    descriptionShort:
      "Overige mutaties leningen, voorschotten en garanties gewezen commissaris 5",
    descriptionLong:
      "Overige mutaties leningen, voorschotten en garanties gewezen commissaris 5",
  },
  {
    code: "BFvaOvrVaw",
    descriptionShort:
      "Cumulatieve aflossingen en waardeverminderingen leningen, voorschotten en garanties gewezen commissarissen (langlopend)",
    descriptionLong:
      "Cumulatieve aflossingen en waardeverminderingen leningen, voorschotten en garanties gewezen commissarissen (langlopend)",
  },
  {
    code: "BFvaOvrVawBe1",
    descriptionShort:
      "Beginbalans cumulatieve aflossing vorderingen op gewezen commissaris 1",
    descriptionLong:
      "Beginbalans cumulatieve aflossing vorderingen op gewezen commissaris 1",
  },
  {
    code: "BFvaOvrVawBe2",
    descriptionShort:
      "Beginbalans cumulatieve aflossing vorderingen op gewezen commissaris 2",
    descriptionLong:
      "Beginbalans cumulatieve aflossing vorderingen op gewezen commissaris 2",
  },
  {
    code: "BFvaOvrVawBe3",
    descriptionShort:
      "Beginbalans cumulatieve aflossing vorderingen op gewezen commissaris 3",
    descriptionLong:
      "Beginbalans cumulatieve aflossing vorderingen op gewezen commissaris 3",
  },
  {
    code: "BFvaOvrVawBe4",
    descriptionShort:
      "Beginbalans cumulatieve aflossing vorderingen op gewezen commissaris 4",
    descriptionLong:
      "Beginbalans cumulatieve aflossing vorderingen op gewezen commissaris 4",
  },
  {
    code: "BFvaOvrVawBe5",
    descriptionShort:
      "Beginbalans cumulatieve aflossing vorderingen op gewezen commissaris 5",
    descriptionLong:
      "Beginbalans cumulatieve aflossing vorderingen op gewezen commissaris 5",
  },
  {
    code: "BFvaOvrVawBea",
    descriptionShort:
      "Aflossing / afname leningen, voorschotten en garanties gewezen commissaris 1",
    descriptionLong:
      "Aflossing / afname leningen, voorschotten en garanties gewezen commissaris 1",
  },
  {
    code: "BFvaOvrVawBeb",
    descriptionShort:
      "Aflossing / afname leningen, voorschotten en garanties gewezen commissaris 2",
    descriptionLong:
      "Aflossing / afname leningen, voorschotten en garanties gewezen commissaris 2",
  },
  {
    code: "BFvaOvrVawBec",
    descriptionShort:
      "Aflossing / afname leningen, voorschotten en garanties gewezen commissaris 3",
    descriptionLong:
      "Aflossing / afname leningen, voorschotten en garanties gewezen commissaris 3",
  },
  {
    code: "BFvaOvrVawBed",
    descriptionShort:
      "Aflossing / afname leningen, voorschotten en garanties gewezen commissaris 4",
    descriptionLong:
      "Aflossing / afname leningen, voorschotten en garanties gewezen commissaris 4",
  },
  {
    code: "BFvaOvrVawBee",
    descriptionShort:
      "Aflossing / afname leningen, voorschotten en garanties gewezen commissaris 5",
    descriptionLong:
      "Aflossing / afname leningen, voorschotten en garanties gewezen commissaris 5",
  },
  {
    code: "BFvaOvrLvl",
    descriptionShort:
      "Hoofdsom vorderingen uit hoofde van leningen en voorschotten aan leden",
    descriptionLong:
      "Hoofdsom vorderingen uit hoofde van leningen en voorschotten aan leden (langlopend)",
  },
  {
    code: "BFvaOvrLvlLi1",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op lid 1",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op lid 1 (langlopend)",
  },
  {
    code: "BFvaOvrLvlLi2",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op lid 2",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op lid 2 (langlopend)",
  },
  {
    code: "BFvaOvrLvlLi3",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op lid 3",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op lid 3 (langlopend)",
  },
  {
    code: "BFvaOvrLvlLi4",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op lid 4",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op lid 4 (langlopend)",
  },
  {
    code: "BFvaOvrLvlLi5",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op lid 5",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op lid 5 (langlopend)",
  },
  {
    code: "BFvaOvrLvlBea",
    descriptionShort: "Toename vorderingen op lid 1",
    descriptionLong: "Toename vorderingen op lid 1",
  },
  {
    code: "BFvaOvrLvlBeb",
    descriptionShort: "Toename vorderingen op lid 2",
    descriptionLong: "Toename vorderingen op lid 2",
  },
  {
    code: "BFvaOvrLvlBec",
    descriptionShort: "Toename vorderingen op lid 3",
    descriptionLong: "Toename vorderingen op lid 3",
  },
  {
    code: "BFvaOvrLvlBed",
    descriptionShort: "Toename vorderingen op lid 4",
    descriptionLong: "Toename vorderingen op lid 4",
  },
  {
    code: "BFvaOvrLvlBee",
    descriptionShort: "Toename vorderingen op lid 5",
    descriptionLong: "Toename vorderingen op lid 5",
  },
  {
    code: "BFvaOvrLvlBef",
    descriptionShort: "Overige mutaties vorderingen op lid 1",
    descriptionLong: "Overige mutaties vorderingen op lid 1",
  },
  {
    code: "BFvaOvrLvlBeg",
    descriptionShort: "Overige mutaties vorderingen op lid 2",
    descriptionLong: "Overige mutaties vorderingen op lid 2",
  },
  {
    code: "BFvaOvrLvlBeh",
    descriptionShort: "Overige mutaties vorderingen op lid 3",
    descriptionLong: "Overige mutaties vorderingen op lid 3",
  },
  {
    code: "BFvaOvrLvlBei",
    descriptionShort: "Overige mutaties vorderingen op lid 4",
    descriptionLong: "Overige mutaties vorderingen op lid 4",
  },
  {
    code: "BFvaOvrLvlBej",
    descriptionShort: "Overige mutaties vorderingen op lid 5",
    descriptionLong: "Overige mutaties vorderingen op lid 5",
  },
  {
    code: "BFvaOvrLvc",
    descriptionShort:
      "Cumulatieve aflossingen en waardeverminderingen leningen en voorschotten aan leden (Langlopend)",
    descriptionLong:
      "Cumulatieve aflossingen en waardeverminderingen leningen en voorschotten aan leden (Langlopend)",
  },
  {
    code: "BFvaOvrLvcBe1",
    descriptionShort: "Beginbalans cumulatieve aflossing vorderingen op lid 1",
    descriptionLong: "Beginbalans cumulatieve aflossing vorderingen op lid 1",
  },
  {
    code: "BFvaOvrLvcBe2",
    descriptionShort: "Beginbalans cumulatieve aflossing vorderingen op lid 2",
    descriptionLong: "Beginbalans cumulatieve aflossing vorderingen op lid 2",
  },
  {
    code: "BFvaOvrLvcBe3",
    descriptionShort: "Beginbalans cumulatieve aflossing vorderingen op lid 3",
    descriptionLong: "Beginbalans cumulatieve aflossing vorderingen op lid 3",
  },
  {
    code: "BFvaOvrLvcBe4",
    descriptionShort: "Beginbalans cumulatieve aflossing vorderingen op lid 4",
    descriptionLong: "Beginbalans cumulatieve aflossing vorderingen op lid 4",
  },
  {
    code: "BFvaOvrLvcBe5",
    descriptionShort: "Beginbalans cumulatieve aflossing vorderingen op lid 5",
    descriptionLong: "Beginbalans cumulatieve aflossing vorderingen op lid 5",
  },
  {
    code: "BFvaOvrLvcBea",
    descriptionShort: "Aflossing / afname leningen en voorschotten lid 1",
    descriptionLong: "Aflossing / afname leningen en voorschotten lid 1",
  },
  {
    code: "BFvaOvrLvcBeb",
    descriptionShort: "Aflossing / afname leningen en voorschotten lid 2",
    descriptionLong: "Aflossing / afname leningen en voorschotten lid 2",
  },
  {
    code: "BFvaOvrLvcBec",
    descriptionShort: "Aflossing / afname leningen en voorschotten lid 3",
    descriptionLong: "Aflossing / afname leningen en voorschotten lid 3",
  },
  {
    code: "BFvaOvrLvcBed",
    descriptionShort: "Aflossing / afname leningen en voorschotten lid 4",
    descriptionLong: "Aflossing / afname leningen en voorschotten lid 4",
  },
  {
    code: "BFvaOvrLvcBee",
    descriptionShort: "Aflossing / afname leningen en voorschotten lid 5",
    descriptionLong: "Aflossing / afname leningen en voorschotten lid 5",
  },
  {
    code: "BFvaOvrHva",
    descriptionShort:
      "Hoofdsom vorderingen uit hoofde van leningen en voorschotten houders van aandelen op naam",
    descriptionLong:
      "Hoofdsom vorderingen uit hoofde van leningen en voorschotten houders van aandelen op naam (langlopend)",
  },
  {
    code: "BFvaOvrHvaAh1",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op aandeelhouder 1",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op aandeelhouder 1 (langlopend)",
  },
  {
    code: "BFvaOvrHvaAh2",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op aandeelhouder 2",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op aandeelhouder 2 (langlopend)",
  },
  {
    code: "BFvaOvrHvaAh3",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op aandeelhouder 3",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op aandeelhouder 3 (langlopend)",
  },
  {
    code: "BFvaOvrHvaAh4",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op aandeelhouder 4",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op aandeelhouder 4 (langlopend)",
  },
  {
    code: "BFvaOvrHvaAh5",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op aandeelhouder 5",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) vorderingen op aandeelhouder 5 (langlopend)",
  },
  {
    code: "BFvaOvrHvaBea",
    descriptionShort: "Toename vorderingen op aandeelhouder 1",
    descriptionLong: "Toename vorderingen op aandeelhouder 1",
  },
  {
    code: "BFvaOvrHvaBeb",
    descriptionShort: "Toename vorderingen op aandeelhouder 2",
    descriptionLong: "Toename vorderingen op aandeelhouder 2",
  },
  {
    code: "BFvaOvrHvaBec",
    descriptionShort: "Toename vorderingen op aandeelhouder 3",
    descriptionLong: "Toename vorderingen op aandeelhouder 3",
  },
  {
    code: "BFvaOvrHvaBed",
    descriptionShort: "Toename vorderingen op aandeelhouder 4",
    descriptionLong: "Toename vorderingen op aandeelhouder 4",
  },
  {
    code: "BFvaOvrHvaBee",
    descriptionShort: "Toename vorderingen op aandeelhouder 5",
    descriptionLong: "Toename vorderingen op aandeelhouder 5",
  },
  {
    code: "BFvaOvrHvaBef",
    descriptionShort: "Overige mutaties vorderingen op aandeelhouder 1",
    descriptionLong: "Overige mutaties vorderingen op aandeelhouder 1",
  },
  {
    code: "BFvaOvrHvaBeg",
    descriptionShort: "Overige mutaties vorderingen op aandeelhouder 2",
    descriptionLong: "Overige mutaties vorderingen op aandeelhouder 2",
  },
  {
    code: "BFvaOvrHvaBeh",
    descriptionShort: "Overige mutaties vorderingen op aandeelhouder 3",
    descriptionLong: "Overige mutaties vorderingen op aandeelhouder 3",
  },
  {
    code: "BFvaOvrHvaBei",
    descriptionShort: "Overige mutaties vorderingen op aandeelhouder 4",
    descriptionLong: "Overige mutaties vorderingen op aandeelhouder 4",
  },
  {
    code: "BFvaOvrHvaBej",
    descriptionShort: "Overige mutaties vorderingen op aandeelhouder 5",
    descriptionLong: "Overige mutaties vorderingen op aandeelhouder 5",
  },
  {
    code: "BFvaOvrHvc",
    descriptionShort:
      "Cumulatieve aflossingen en waardeverminderingen leningen en voorschotten houders van aandelen op naam (langlopend)",
    descriptionLong:
      "Cumulatieve aflossingen en waardeverminderingen leningen en voorschotten houders van aandelen op naam (langlopend)",
  },
  {
    code: "BFvaOvrHvcBe1",
    descriptionShort:
      "Beginbalans cumulatieve aflossing vorderingen op aandeelhouder 1",
    descriptionLong:
      "Beginbalans cumulatieve aflossing vorderingen op aandeelhouder 1",
  },
  {
    code: "BFvaOvrHvcBe2",
    descriptionShort:
      "Beginbalans cumulatieve aflossing vorderingen op aandeelhouder 2",
    descriptionLong:
      "Beginbalans cumulatieve aflossing vorderingen op aandeelhouder 2",
  },
  {
    code: "BFvaOvrHvcBe3",
    descriptionShort:
      "Beginbalans cumulatieve aflossing vorderingen op aandeelhouder 3",
    descriptionLong:
      "Beginbalans cumulatieve aflossing vorderingen op aandeelhouder 3",
  },
  {
    code: "BFvaOvrHvcBe4",
    descriptionShort:
      "Beginbalans cumulatieve aflossing vorderingen op aandeelhouder 4",
    descriptionLong:
      "Beginbalans cumulatieve aflossing vorderingen op aandeelhouder 4",
  },
  {
    code: "BFvaOvrHvcBe5",
    descriptionShort:
      "Beginbalans cumulatieve aflossing vorderingen op aandeelhouder 5",
    descriptionLong:
      "Beginbalans cumulatieve aflossing vorderingen op aandeelhouder 5",
  },
  {
    code: "BFvaOvrHvcBea",
    descriptionShort: "Aflossing / afname vorderingen op aandeelhouder 1",
    descriptionLong: "Aflossing / afname vorderingen op aandeelhouder 1",
  },
  {
    code: "BFvaOvrHvcBeb",
    descriptionShort: "Aflossing / afname vorderingen op aandeelhouder 2",
    descriptionLong: "Aflossing / afname vorderingen op aandeelhouder 2",
  },
  {
    code: "BFvaOvrHvcBec",
    descriptionShort: "Aflossing / afname vorderingen op aandeelhouder 3",
    descriptionLong: "Aflossing / afname vorderingen op aandeelhouder 3",
  },
  {
    code: "BFvaOvrHvcBed",
    descriptionShort: "Aflossing / afname vorderingen op aandeelhouder 4",
    descriptionLong: "Aflossing / afname vorderingen op aandeelhouder 4",
  },
  {
    code: "BFvaOvrHvcBee",
    descriptionShort: "Aflossing / afname vorderingen op aandeelhouder 5",
    descriptionLong: "Aflossing / afname vorderingen op aandeelhouder 5",
  },
  {
    code: "BFvaOvrTsl",
    descriptionShort: "Hoofdsom te vorderen subsidies (langlopend)",
    descriptionLong: "Hoofdsom te vorderen subsidies (langlopend)",
  },
  {
    code: "BFvaOvrTslTs1",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) te vorderen subsidie 1",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) te vorderen subsidie 1 (langlopend)",
  },
  {
    code: "BFvaOvrTslTs2",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) te vorderen subsidie 2",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) te vorderen subsidie 2 (langlopend)",
  },
  {
    code: "BFvaOvrTslTs3",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) te vorderen subsidie 3",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) te vorderen subsidie 3 (langlopend)",
  },
  {
    code: "BFvaOvrTslBea",
    descriptionShort: "Toename te vorderen subsidie 1",
    descriptionLong: "Toename te vorderen subsidie 1",
  },
  {
    code: "BFvaOvrTslBeb",
    descriptionShort: "Toename te vorderen subsidie 2",
    descriptionLong: "Toename te vorderen subsidie 2",
  },
  {
    code: "BFvaOvrTslBec",
    descriptionShort: "Toename te vorderen subsidie 3",
    descriptionLong: "Toename te vorderen subsidie 3",
  },
  {
    code: "BFvaOvrTslBef",
    descriptionShort: "Overige mutaties te vorderen subsidie 1",
    descriptionLong: "Overige mutaties te vorderen subsidie 1",
  },
  {
    code: "BFvaOvrTslBeg",
    descriptionShort: "Overige mutaties te vorderen subsidie 2",
    descriptionLong: "Overige mutaties te vorderen subsidie 2",
  },
  {
    code: "BFvaOvrTslBeh",
    descriptionShort: "Overige mutaties te vorderen subsidie 3",
    descriptionLong: "Overige mutaties te vorderen subsidie 3",
  },
  {
    code: "BFvaOvrTsc",
    descriptionShort:
      "Cumulatieve aflossingen en waardeverminderingen te vorderen subsidies (langlopend)",
    descriptionLong:
      "Cumulatieve aflossingen en waardeverminderingen te vorderen subsidies (langlopend)",
  },
  {
    code: "BFvaOvrTscBe1",
    descriptionShort:
      "Beginbalans cumulatieve aflossing te vorderen subsidies 1",
    descriptionLong:
      "Beginbalans cumulatieve aflossing te vorderen subsidies 1",
  },
  {
    code: "BFvaOvrTscBe2",
    descriptionShort:
      "Beginbalans cumulatieve aflossing te vorderen subsidies 2",
    descriptionLong:
      "Beginbalans cumulatieve aflossing te vorderen subsidies 2",
  },
  {
    code: "BFvaOvrTscBe3",
    descriptionShort:
      "Beginbalans cumulatieve aflossing te vorderen subsidies 3",
    descriptionLong:
      "Beginbalans cumulatieve aflossing te vorderen subsidies 3",
  },
  {
    code: "BFvaOvrTscBea",
    descriptionShort: "Aflossing / afname te vorderen subsidies 1",
    descriptionLong: "Aflossing / afname te vorderen subsidies 1",
  },
  {
    code: "BFvaOvrTscBeb",
    descriptionShort: "Aflossing / afname te vorderen subsidies 2",
    descriptionLong: "Aflossing / afname te vorderen subsidies 2",
  },
  {
    code: "BFvaOvrTscBec",
    descriptionShort: "Aflossing / afname te vorderen subsidies 3",
    descriptionLong: "Aflossing / afname te vorderen subsidies 3",
  },
  {
    code: "BFvaOvrWaa",
    descriptionShort: "Hoofdsom waarborgsommen (langlopend)",
    descriptionLong: "Hoofdsom waarborgsommen (langlopend)",
  },
  {
    code: "BFvaOvrWaaWb1",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) waarborgsom 1",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) waarborgsom 1 (langlopend)",
  },
  {
    code: "BFvaOvrWaaWb2",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) waarborgsom 2",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) waarborgsom 2 (langlopend)",
  },
  {
    code: "BFvaOvrWaaWb3",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) waarborgsom 3",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) waarborgsom 3 (langlopend)",
  },
  {
    code: "BFvaOvrWaaBea",
    descriptionShort: "Toename waarborgsom 1",
    descriptionLong: "Toename waarborgsom 1",
  },
  {
    code: "BFvaOvrWaaBeb",
    descriptionShort: "Toename waarborgsom 2",
    descriptionLong: "Toename waarborgsom 2",
  },
  {
    code: "BFvaOvrWaaBec",
    descriptionShort: "Toename waarborgsom 3",
    descriptionLong: "Toename waarborgsom 3",
  },
  {
    code: "BFvaOvrWaaBef",
    descriptionShort: "Overige mutaties waarborgsom 1",
    descriptionLong: "Overige mutaties waarborgsom 1",
  },
  {
    code: "BFvaOvrWaaBeg",
    descriptionShort: "Overige mutaties waarborgsom 2",
    descriptionLong: "Overige mutaties waarborgsom 2",
  },
  {
    code: "BFvaOvrWaaBeh",
    descriptionShort: "Overige mutaties waarborgsom 3",
    descriptionLong: "Overige mutaties waarborgsom 3",
  },
  {
    code: "BFvaOvrWac",
    descriptionShort:
      "Cumulatieve aflossingen en waardeverminderingen waarborgsommen (langlopend)",
    descriptionLong:
      "Cumulatieve aflossingen en waardeverminderingen waarborgsommen (langlopend)",
  },
  {
    code: "BFvaOvrWacBe1",
    descriptionShort: "Beginbalans cumulatieve aflossing waarborgsom 1",
    descriptionLong: "Beginbalans cumulatieve aflossing waarborgsom 1",
  },
  {
    code: "BFvaOvrWacBe2",
    descriptionShort: "Beginbalans cumulatieve aflossing waarborgsom 2",
    descriptionLong: "Beginbalans cumulatieve aflossing waarborgsom 2",
  },
  {
    code: "BFvaOvrWacBe3",
    descriptionShort: "Beginbalans cumulatieve aflossing waarborgsom 3",
    descriptionLong: "Beginbalans cumulatieve aflossing waarborgsom 3",
  },
  {
    code: "BFvaOvrWacBea",
    descriptionShort: "Aflossing / afname waarborgsom 1",
    descriptionLong: "Aflossing / afname waarborgsom 1",
  },
  {
    code: "BFvaOvrWacBeb",
    descriptionShort: "Aflossing / afname waarborgsom 2",
    descriptionLong: "Aflossing / afname waarborgsom 2",
  },
  {
    code: "BFvaOvrWacBec",
    descriptionShort: "Aflossing / afname waarborgsom 3",
    descriptionLong: "Aflossing / afname waarborgsom 3",
  },
  {
    code: "BFvaOvrLed",
    descriptionShort: "Hoofdsom ledenrekeningen (langlopend)",
    descriptionLong: "Hoofdsom ledenrekeningen (langlopend)",
  },
  {
    code: "BFvaOvrLedLe1",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) ledenrekeningen 1",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) ledenrekeningen 1 (langlopend)",
  },
  {
    code: "BFvaOvrLedLe2",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) ledenrekeningen 2",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) ledenrekeningen 2 (langlopend)",
  },
  {
    code: "BFvaOvrLedLe3",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) ledenrekeningen 3",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) ledenrekeningen 3 (langlopend)",
  },
  {
    code: "BFvaOvrLedBea",
    descriptionShort: "Toename ledenrekening 1",
    descriptionLong: "Toename ledenrekening 1",
  },
  {
    code: "BFvaOvrLedBeb",
    descriptionShort: "Toename ledenrekening 2",
    descriptionLong: "Toename ledenrekening 2",
  },
  {
    code: "BFvaOvrLedBec",
    descriptionShort: "Toename ledenrekening 3",
    descriptionLong: "Toename ledenrekening 3",
  },
  {
    code: "BFvaOvrLedBef",
    descriptionShort: "Overige mutaties ledenrekening 1",
    descriptionLong: "Overige mutaties ledenrekening 1",
  },
  {
    code: "BFvaOvrLedBeg",
    descriptionShort: "Overige mutaties ledenrekening 2",
    descriptionLong: "Overige mutaties ledenrekening 2",
  },
  {
    code: "BFvaOvrLedBeh",
    descriptionShort: "Overige mutaties ledenrekening 3",
    descriptionLong: "Overige mutaties ledenrekening 3",
  },
  {
    code: "BFvaOvrLec",
    descriptionShort:
      "Cumulatieve aflossingen en waardeverminderingen ledenrekeningen (langlopend)",
    descriptionLong:
      "Cumulatieve aflossingen en waardeverminderingen ledenrekeningen (langlopend)",
  },
  {
    code: "BFvaOvrLecBe1",
    descriptionShort: "Beginbalans cumulatieve aflossing ledenrekening 1",
    descriptionLong: "Beginbalans cumulatieve aflossing ledenrekening 1",
  },
  {
    code: "BFvaOvrLecBe2",
    descriptionShort: "Beginbalans cumulatieve aflossing ledenrekening 2",
    descriptionLong: "Beginbalans cumulatieve aflossing ledenrekening 2",
  },
  {
    code: "BFvaOvrLecBe3",
    descriptionShort: "Beginbalans cumulatieve aflossing ledenrekening 3",
    descriptionLong: "Beginbalans cumulatieve aflossing ledenrekening 3",
  },
  {
    code: "BFvaOvrLecBea",
    descriptionShort: "Aflossing / afname ledenrekening 1",
    descriptionLong: "Aflossing / afname ledenrekening 1",
  },
  {
    code: "BFvaOvrLecBeb",
    descriptionShort: "Aflossing / afname ledenrekening 2",
    descriptionLong: "Aflossing / afname ledenrekening 2",
  },
  {
    code: "BFvaOvrLecBec",
    descriptionShort: "Aflossing / afname ledenrekening 3",
    descriptionLong: "Aflossing / afname ledenrekening 3",
  },
  {
    code: "BFvaOvrOvl",
    descriptionShort: "Hoofdsom overige financiële vaste activa (langlopend)",
    descriptionLong: "Hoofdsom overige financiële vaste activa (langlopend)",
  },
  {
    code: "BFvaOvrOvlOv1",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) overige financiële vaste activa 1",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) overige financiële vaste activa 1 (langlopend)",
  },
  {
    code: "BFvaOvrOvlOv2",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) overige financiële vaste activa 2",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) overige financiële vaste activa 2 (langlopend)",
  },
  {
    code: "BFvaOvrOvlOv3",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) overige financiële vaste activa 3",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) overige financiële vaste activa 3 (langlopend)",
  },
  {
    code: "BFvaOvrOvlBea",
    descriptionShort: "Toename overige financiële vaste activa 1",
    descriptionLong: "Toename overige financiële vaste activa 1",
  },
  {
    code: "BFvaOvrOvlBeb",
    descriptionShort: "Toename overige financiële vaste activa 2",
    descriptionLong: "Toename overige financiële vaste activa 2",
  },
  {
    code: "BFvaOvrOvlBec",
    descriptionShort: "Toename overige financiële vaste activa 3",
    descriptionLong: "Toename overige financiële vaste activa 3",
  },
  {
    code: "BFvaOvrOvlBef",
    descriptionShort: "Overige mutaties overige financiële vaste activa 1",
    descriptionLong: "Overige mutaties overige financiële vaste activa 1",
  },
  {
    code: "BFvaOvrOvlBeg",
    descriptionShort: "Overige mutaties overige financiële vaste activa 2",
    descriptionLong: "Overige mutaties overige financiële vaste activa 2",
  },
  {
    code: "BFvaOvrOvlBeh",
    descriptionShort: "Overige mutaties overige financiële vaste activa 3",
    descriptionLong: "Overige mutaties overige financiële vaste activa 3",
  },
  {
    code: "BFvaOvrOvc",
    descriptionShort:
      "Cumulatieve aflossingen en waardeverminderingen overige financiële vaste activa (langlopend)",
    descriptionLong:
      "Cumulatieve aflossingen en waardeverminderingen overige financiële vaste activa (langlopend)",
  },
  {
    code: "BFvaOvrOvcBe1",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) cumulatieve aflossing overige financiële vaste activa 1",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) cumulatieve aflossing overige financiële vaste activa 1 (langlopend)",
  },
  {
    code: "BFvaOvrOvcBe2",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) cumulatieve aflossing overige financiële vaste activa 2",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) cumulatieve aflossing overige financiële vaste activa 2 (langlopend)",
  },
  {
    code: "BFvaOvrOvcBe3",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) cumulatieve aflossing overige financiële vaste activa 3",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) cumulatieve aflossing overige financiële vaste activa 3 (langlopend)",
  },
  {
    code: "BFvaOvrOvcBea",
    descriptionShort: "Aflossing / afname overige financiële vaste activa 1",
    descriptionLong: "Aflossing / afname overige financiële vaste activa 1",
  },
  {
    code: "BFvaOvrOvcBeb",
    descriptionShort: "Aflossing / afname overige financiële vaste activa 2",
    descriptionLong: "Aflossing / afname overige financiële vaste activa 2",
  },
  {
    code: "BFvaOvrOvcBec",
    descriptionShort: "Aflossing / afname overige financiële vaste activa 3",
    descriptionLong: "Aflossing / afname overige financiële vaste activa 3",
  },
  {
    code: "BFvaOvrSid",
    descriptionShort: "Storting ivm derivaten",
    descriptionLong: "Storting ivm derivaten",
  },
  {
    code: "BFvaOvrSidBeg",
    descriptionShort: "Saldo per begin boekjaar",
    descriptionLong: "Saldo per begin boekjaar",
  },
  {
    code: "BFvaOvrSidStr",
    descriptionShort: "Stortingen",
    descriptionLong: "Stortingen",
  },
  {
    code: "BFvaOvrSidOnt",
    descriptionShort: "Ontvangsten",
    descriptionLong: "Ontvangsten",
  },
  {
    code: "BFvaOvrAga",
    descriptionShort: "Agio afkoop leningen/derivaten",
    descriptionLong: "Agio afkoop leningen/derivaten",
  },
  {
    code: "BFvaOvrAgaBeg",
    descriptionShort: "Saldo per begin boekjaar",
    descriptionLong: "Saldo per begin boekjaar",
  },
  {
    code: "BFvaOvrAgaVlr",
    descriptionShort: "Vrijval ten laste van het resultaat",
    descriptionLong: "Vrijval ten laste van het resultaat",
  },
  {
    code: "BFvaOvrAgaGab",
    descriptionShort: "Geactiveerde afrekening break",
    descriptionLong: "Geactiveerde afrekening break",
  },
  {
    code: "BFvaLbv",
    descriptionShort: "Latente belastingvorderingen",
    descriptionLong: "Latente belastingvorderingen",
  },
  {
    code: "BFvaLbvBll",
    descriptionShort: "Compensabele verliezen",
    descriptionLong: "Compensabele verliezen",
  },
  {
    code: "BFvaLbvBllBeg",
    descriptionShort: "Saldo per begin boekjaar",
    descriptionLong: "Saldo per begin boekjaar",
  },
  {
    code: "BFvaLbvBllToe",
    descriptionShort: "Toename",
    descriptionLong: "Toename",
  },
  {
    code: "BFvaLbvBllAfn",
    descriptionShort: "Afname",
    descriptionLong: "Afname",
  },
  {
    code: "BFvaLbvVtv",
    descriptionShort: "Verrekenbare tijdelijke verschillen",
    descriptionLong: "Verrekenbare tijdelijke verschillen",
  },
  {
    code: "BFvaLbvVtvBeg",
    descriptionShort: "Saldo per begin boekjaar",
    descriptionLong: "Saldo per begin boekjaar",
  },
  {
    code: "BFvaLbvVtvToe",
    descriptionShort: "Toename",
    descriptionLong: "Toename",
  },
  {
    code: "BFvaLbvVtvAfn",
    descriptionShort: "Afname",
    descriptionLong: "Afname",
  },
  {
    code: "BFvaLbvVtg",
    descriptionShort:
      "Verrekenbare tijdelijke verschillen in verband met investeringen in groepsmaatschappijen enz.",
    descriptionLong:
      "Verrekenbare tijdelijke verschillen in verband met investeringen in groepsmaatschappijen enz.",
  },
  {
    code: "BFvaLbvVtgBeg",
    descriptionShort: "Saldo per begin boekjaar",
    descriptionLong: "Saldo per begin boekjaar",
  },
  {
    code: "BFvaLbvVtgToe",
    descriptionShort: "Toename",
    descriptionLong: "Toename",
  },
  {
    code: "BFvaLbvVtgAfn",
    descriptionShort: "Afname",
    descriptionLong: "Afname",
  },
  {
    code: "BFvaSub",
    descriptionShort: "Te vorderen BWS-subsidies",
    descriptionLong: "Te vorderen BWS-subsidies",
  },
  {
    code: "BFvaSubSub",
    descriptionShort: "Hoofdsom te vorderen BWS-subsidies",
    descriptionLong: "Hoofdsom te vorderen BWS-subsidies (langlopend)",
  },
  {
    code: "BFvaSubSubTs1",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) te vorderen BWS-subsidie 1",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) te vorderen BWS-subsidie 1 (langlopend)",
  },
  {
    code: "BFvaSubSubTs2",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) te vorderen BWS-subsidie 2",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) te vorderen BWS-subsidie 2 (langlopend)",
  },
  {
    code: "BFvaSubSubTs3",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) te vorderen BWS-subsidie 3",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) te vorderen BWS-subsidie 3 (langlopend)",
  },
  {
    code: "BFvaSubSubTs4",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) te vorderen BWS-subsidie 4",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) te vorderen BWS-subsidie 4 (langlopend)",
  },
  {
    code: "BFvaSubSubTs5",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) te vorderen BWS-subsidie 5",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) te vorderen BWS-subsidie 5 (langlopend)",
  },
  {
    code: "BFvaSubSubBea",
    descriptionShort: "Toename  te vorderen BWS-subsidie 1",
    descriptionLong: "Toename  te vorderen BWS-subsidie 1",
  },
  {
    code: "BFvaSubSubBeb",
    descriptionShort: "Toename  te vorderen BWS-subsidie 2",
    descriptionLong: "Toename  te vorderen BWS-subsidie 2",
  },
  {
    code: "BFvaSubSubBec",
    descriptionShort: "Toename  te vorderen BWS-subsidie 3",
    descriptionLong: "Toename  te vorderen BWS-subsidie 3",
  },
  {
    code: "BFvaSubSubBed",
    descriptionShort: "Toename  te vorderen BWS-subsidie 4",
    descriptionLong: "Toename  te vorderen BWS-subsidie 4",
  },
  {
    code: "BFvaSubSubBee",
    descriptionShort: "Toename  te vorderen BWS-subsidie 5",
    descriptionLong: "Toename  te vorderen BWS-subsidie 5",
  },
  {
    code: "BFvaSubSubBef",
    descriptionShort: "Overige mutaties  te vorderen BWS-subsidie 1",
    descriptionLong: "Overige mutaties  te vorderen BWS-subsidie 1",
  },
  {
    code: "BFvaSubSubBeg",
    descriptionShort: "Overige mutaties  te vorderen BWS-subsidie 2",
    descriptionLong: "Overige mutaties  te vorderen BWS-subsidie 2",
  },
  {
    code: "BFvaSubSubBeh",
    descriptionShort: "Overige mutaties  te vorderen BWS-subsidie 3",
    descriptionLong: "Overige mutaties  te vorderen BWS-subsidie 3",
  },
  {
    code: "BFvaSubSubBei",
    descriptionShort: "Overige mutaties  te vorderen BWS-subsidie 4",
    descriptionLong: "Overige mutaties  te vorderen BWS-subsidie 4",
  },
  {
    code: "BFvaSubSubBej",
    descriptionShort: "Overige mutaties  te vorderen BWS-subsidie 5",
    descriptionLong: "Overige mutaties  te vorderen BWS-subsidie 5",
  },
  {
    code: "BFvaSubSuc",
    descriptionShort:
      "Cumulatieve aflossingen en waardeverminderingen te vorderen BWS-subsidies (langlopend)",
    descriptionLong:
      "Cumulatieve aflossingen en waardeverminderingen te vorderen BWS-subsidies (langlopend)",
  },
  {
    code: "BFvaSubSucBe1",
    descriptionShort:
      "Beginbalans cumulatieve aflossing te vorderen BWS-subsidie 1",
    descriptionLong:
      "Beginbalans cumulatieve aflossing te vorderen BWS-subsidie 1",
  },
  {
    code: "BFvaSubSucBe2",
    descriptionShort:
      "Beginbalans cumulatieve aflossing te vorderen BWS-subsidie 2",
    descriptionLong:
      "Beginbalans cumulatieve aflossing te vorderen BWS-subsidie 2",
  },
  {
    code: "BFvaSubSucBe3",
    descriptionShort:
      "Beginbalans cumulatieve aflossing te vorderen BWS-subsidie 3",
    descriptionLong:
      "Beginbalans cumulatieve aflossing te vorderen BWS-subsidie 3",
  },
  {
    code: "BFvaSubSucBe4",
    descriptionShort:
      "Beginbalans cumulatieve aflossing te vorderen BWS-subsidie 4",
    descriptionLong:
      "Beginbalans cumulatieve aflossing te vorderen BWS-subsidie 4",
  },
  {
    code: "BFvaSubSucBe5",
    descriptionShort:
      "Beginbalans cumulatieve aflossing te vorderen BWS-subsidie 5",
    descriptionLong:
      "Beginbalans cumulatieve aflossing te vorderen BWS-subsidie 5",
  },
  {
    code: "BFvaSubSucBea",
    descriptionShort: "Aflossing / afname te vorderen BWS-subsidie 1",
    descriptionLong: "Aflossing / afname te vorderen BWS-subsidie 1",
  },
  {
    code: "BFvaSubSucBeb",
    descriptionShort: "Aflossing / afname te vorderen BWS-subsidie 2",
    descriptionLong: "Aflossing / afname te vorderen BWS-subsidie 2",
  },
  {
    code: "BFvaSubSucBec",
    descriptionShort: "Aflossing / afname te vorderen BWS-subsidie 3",
    descriptionLong: "Aflossing / afname te vorderen BWS-subsidie 3",
  },
  {
    code: "BFvaSubSucBed",
    descriptionShort: "Aflossing / afname te vorderen BWS-subsidie 4",
    descriptionLong: "Aflossing / afname te vorderen BWS-subsidie 4",
  },
  {
    code: "BFvaSubSucBee",
    descriptionShort: "Aflossing / afname te vorderen BWS-subsidie 5",
    descriptionLong: "Aflossing / afname te vorderen BWS-subsidie 5",
  },
  {
    code: "BFvaLen",
    descriptionShort: "Leningen u/g (langlopend)",
    descriptionLong: "Leningen u/g (langlopend)",
  },
  {
    code: "BFvaLenLen",
    descriptionShort: "Leningen u/g (langlopend)",
    descriptionLong: "Leningen u/g (langlopend)",
  },
  {
    code: "BFvaLenLenLn1",
    descriptionShort: "Beginbalans lening u/g 1 (langlopend)",
    descriptionLong: "Beginbalans lening u/g 1 (langlopend)",
  },
  {
    code: "BFvaLenLenAv1",
    descriptionShort: "Aflossingsverplichting (overboeking naar kortlopend)",
    descriptionLong: "Aflossingsverplichting (overboeking naar kortlopend)",
  },
  {
    code: "BFvaLenLenAf1",
    descriptionShort: "Aflossing / afname in boekjaar lening u/g 1",
    descriptionLong: "Aflossing / afname in boekjaar lening u/g 1",
  },
  {
    code: "BFvaLenLenUg1",
    descriptionShort: "Toename / uitgegeven in boekjaar lening u/g 1",
    descriptionLong: "Toename / uitgegeven in boekjaar lening u/g 1",
  },
  {
    code: "BFvaLenLenOv1",
    descriptionShort: "Overige mutaties lening u/g 1",
    descriptionLong: "Overige mutaties lening u/g 1",
  },
  {
    code: "BFvaLenLenLn2",
    descriptionShort: "Beginbalans lening u/g 2 (langlopend)",
    descriptionLong: "Beginbalans lening u/g 2 (langlopend)",
  },
  {
    code: "BFvaLenLenAv2",
    descriptionShort: "Aflossingsverplichting (overboeking naar kortlopend)",
    descriptionLong: "Aflossingsverplichting (overboeking naar kortlopend)",
  },
  {
    code: "BFvaLenLenAf2",
    descriptionShort: "Aflossing / afname in boekjaar lening u/g 2",
    descriptionLong: "Aflossing / afname in boekjaar lening u/g 2",
  },
  {
    code: "BFvaLenLenUg2",
    descriptionShort: "Toename / uitgegeven in boekjaar lening u/g 2",
    descriptionLong: "Toename / uitgegeven in boekjaar lening u/g 2",
  },
  {
    code: "BFvaLenLenOv2",
    descriptionShort: "Overige mutaties lening u/g 2",
    descriptionLong: "Overige mutaties lening u/g 2",
  },
  {
    code: "BFvaLenLenLn3",
    descriptionShort: "Beginbalans lening u/g 3 (langlopend)",
    descriptionLong: "Beginbalans lening u/g 3 (langlopend)",
  },
  {
    code: "BFvaLenLenAv3",
    descriptionShort: "Aflossingsverplichting (overboeking naar kortlopend)",
    descriptionLong: "Aflossingsverplichting (overboeking naar kortlopend)",
  },
  {
    code: "BFvaLenLenAf3",
    descriptionShort: "Aflossing / afname in boekjaar lening u/g 3",
    descriptionLong: "Aflossing / afname in boekjaar lening u/g 3",
  },
  {
    code: "BFvaLenLenUg3",
    descriptionShort: "Toename / uitgegeven in boekjaar lening u/g 3",
    descriptionLong: "Toename / uitgegeven in boekjaar lening u/g 3",
  },
  {
    code: "BFvaLenLenOv3",
    descriptionShort: "Overige mutaties lening u/g 3",
    descriptionLong: "Overige mutaties lening u/g 3",
  },
  {
    code: "BFvaLenLenLn4",
    descriptionShort: "Beginbalans lening u/g 4 (langlopend)",
    descriptionLong: "Beginbalans lening u/g 4 (langlopend)",
  },
  {
    code: "BFvaLenLenAv4",
    descriptionShort: "Aflossingsverplichting (overboeking naar kortlopend)",
    descriptionLong: "Aflossingsverplichting (overboeking naar kortlopend)",
  },
  {
    code: "BFvaLenLenAf4",
    descriptionShort: "Aflossing / afname in boekjaar lening u/g 4",
    descriptionLong: "Aflossing / afname in boekjaar lening u/g 4",
  },
  {
    code: "BFvaLenLenUg4",
    descriptionShort: "Toename / uitgegeven in boekjaar lening u/g 4",
    descriptionLong: "Toename / uitgegeven in boekjaar lening u/g 4",
  },
  {
    code: "BFvaLenLenOv4",
    descriptionShort: "Overige mutaties lening u/g 4",
    descriptionLong: "Overige mutaties lening u/g 4",
  },
  {
    code: "BFvaLenLenLn5",
    descriptionShort: "Beginbalans lening u/g 5 (langlopend)",
    descriptionLong: "Beginbalans lening u/g 5 (langlopend)",
  },
  {
    code: "BFvaLenLenAv5",
    descriptionShort: "Aflossingsverplichting (overboeking naar kortlopend)",
    descriptionLong: "Aflossingsverplichting (overboeking naar kortlopend)",
  },
  {
    code: "BFvaLenLenAf5",
    descriptionShort: "Aflossing / afname in boekjaar lening u/g 5",
    descriptionLong: "Aflossing / afname in boekjaar lening u/g 5",
  },
  {
    code: "BFvaLenLenUg5",
    descriptionShort: "Toename / uitgegeven in boekjaar lening u/g 5",
    descriptionLong: "Toename / uitgegeven in boekjaar lening u/g 5",
  },
  {
    code: "BFvaLenLenOv5",
    descriptionShort: "Overige mutaties lening u/g 5",
    descriptionLong: "Overige mutaties lening u/g 5",
  },
  {
    code: "BFvaIlg",
    descriptionShort: "Interne lening",
    descriptionLong: "Interne Lening",
  },
  {
    code: "BFvaIlgIlg",
    descriptionShort: "Hoofdsom interne lening",
    descriptionLong: "Hoofdsom interne lening",
  },
  {
    code: "BFvaIlgIlgBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) hoofdsom interne lening",
  },
  {
    code: "BFvaIlgIlgInv",
    descriptionShort: "Toename",
    descriptionLong: "Toename hoofdsom interne lening",
  },
  {
    code: "BFvaIlgIlgOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties hoofdsom interne lening",
  },
  {
    code: "BFvaIlgAvp",
    descriptionShort: "Aflossingsverplichting interne lening",
    descriptionLong: "Aflossingsverplichting interne lening",
  },
  {
    code: "BFvaIlgAil",
    descriptionShort: "Cumulatieve aflossing interne lening",
    descriptionLong: "Cumulatieve aflossing interne lening",
  },
  {
    code: "BFvaIlgAilBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) interne Lening",
  },
  {
    code: "BFvaIlgAilAfl",
    descriptionShort: "Aflossing / afname in boekjaar",
    descriptionLong: "Aflossing / afname in boekjaar interne Lening",
  },
  {
    code: "BFvaNvm",
    descriptionShort: "Netto vermogenswaarde niet-Daeb",
    descriptionLong: "Netto vermogenswaarde niet-Daeb",
  },
  {
    code: "BFvaNvmNvm",
    descriptionShort: "Verkrijgingsprijs",
    descriptionLong: "Verkrijgingsprijs netto vermogenswaarde niet-Daeb",
  },
  {
    code: "BFvaNvmNvmBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) netto vermogenswaarde niet-Daeb",
  },
  {
    code: "BFvaNvmNvmInv",
    descriptionShort: "Investeringen",
    descriptionLong: "Investeringen netto vermogenswaarde niet-Daeb",
  },
  {
    code: "BFvaNvmNvmAdo",
    descriptionShort: "Bij overname verkregen activa",
    descriptionLong:
      "Bij overname verkregen activa netto vermogenswaarde niet-Daeb",
  },
  {
    code: "BFvaNvmNvmDes",
    descriptionShort: "Desinvesteringen",
    descriptionLong: "Desinvesteringen netto vermogenswaarde niet-Daeb",
  },
  {
    code: "BFvaNvmNvmDda",
    descriptionShort: "Afstotingen",
    descriptionLong: "Afstotingen netto vermogenswaarde niet-Daeb",
  },
  {
    code: "BFvaNvmNvmOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen netto vermogenswaarde niet-Daeb",
  },
  {
    code: "BFvaNvmNvmOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties netto vermogenswaarde niet-Daeb",
  },
  {
    code: "BFvaNvmCae",
    descriptionShort: "Cumulatieve afschrijvingen en waardeverminderingen",
    descriptionLong:
      "Cumulatieve afschrijvingen en waardeverminderingen netto vermogenswaarde niet-Daeb",
  },
  {
    code: "BFvaNvmCaeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) netto vermogenswaarde niet-Daeb",
  },
  {
    code: "BFvaNvmCaeAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong: "Afschrijvingen netto vermogenswaarde niet-Daeb",
  },
  {
    code: "BFvaNvmCaeDca",
    descriptionShort: "Afschrijving op desinvesteringen",
    descriptionLong:
      "Afschrijving op desinvesteringen netto vermogenswaarde niet-Daeb",
  },
  {
    code: "BFvaNvmCaeWvr",
    descriptionShort: "Bijzondere waardeverminderingen",
    descriptionLong:
      "Bijzondere waardeverminderingen netto vermogenswaarde niet-Daeb",
  },
  {
    code: "BFvaNvmCaeTvw",
    descriptionShort: "Terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen netto vermogenswaarde niet-Daeb",
  },
  {
    code: "BFvaNvmCuh",
    descriptionShort: "Cumulatieve herwaarderingen",
    descriptionLong:
      "Cumulatieve herwaarderingen netto vermogenswaarde niet-Daeb",
  },
  {
    code: "BFvaNvmCuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) netto vermogenswaarde niet-Daeb",
  },
  {
    code: "BFvaNvmCuhHer",
    descriptionShort: "Herwaarderingen deelnemingen in groepsmaatschappijen",
    descriptionLong: "Herwaarderingen netto vermogenswaarde niet-Daeb",
  },
  {
    code: "BFvaNvmCuhAir",
    descriptionShort: "Aandeel in resultaat deelnemingen",
    descriptionLong:
      "Aandeel in resultaat deelnemingen netto vermogenswaarde niet-Daeb",
  },
  {
    code: "BFvaNvmCuhDvd",
    descriptionShort: "Dividend van deelnemingen",
    descriptionLong:
      "Dividend van deelnemingen netto vermogenswaarde niet-Daeb",
  },
  {
    code: "BFvaNvmCuhAfh",
    descriptionShort: "Afschrijving herwaardering",
    descriptionLong: "Afschrijving herwaardering",
  },
  {
    code: "BFvaNvmCuhDeh",
    descriptionShort: "Desinvestering herwaardering",
    descriptionLong: "Desinvestering herwaardering",
  },
  { code: "BVrd", descriptionShort: "Voorraden", descriptionLong: "Voorraden" },
  {
    code: "BVrdGeh",
    descriptionShort: "Voorraad grond- en hulpstoffen",
    descriptionLong: "Voorraad grond- en hulpstoffen",
  },
  {
    code: "BVrdGehVoo",
    descriptionShort: "Voorraad grond- en hulpstoffen, bruto",
    descriptionLong:
      "Voorraad grond- en hulpstoffen, bruto voorraad grond- en hulpstoffen",
  },
  {
    code: "BVrdGehTus",
    descriptionShort: "Tussenrekening voorraden",
    descriptionLong: "Tussenrekening voorraden",
  },
  {
    code: "BVrdGehVic",
    descriptionShort:
      "Voorraad grond- en hulpstoffen, voorziening incourante grond- en hulpstoffen",
    descriptionLong:
      "Voorraad grond- en hulpstoffen, voorziening incourante grond- en hulpstoffen voorraad grond- en hulpstoffen",
  },
  {
    code: "BVrdGehHvv",
    descriptionShort:
      "Voorraad grond- en hulpstoffen, herwaardering voorraden grond- en hulpstoffen",
    descriptionLong:
      "Voorraad grond- en hulpstoffen, herwaardering voorraden grond- en hulpstoffen voorraad grond- en hulpstoffen",
  },
  {
    code: "BVrdGehHvi",
    descriptionShort:
      "Herclassificatie van en naar vastgoedbelegging in ontwikkeling",
    descriptionLong:
      "Herclassificatie van en naar vastgoedbelegging in ontwikkeling bestemd voor eigen exploitatie",
  },
  {
    code: "BVrdHal",
    descriptionShort: "Halffabrikaten",
    descriptionLong: "Halffabrikaten",
  },
  {
    code: "BVrdHalVoo",
    descriptionShort: "Halffabrikaten, bruto",
    descriptionLong: "Halffabrikaten, bruto halffabrikaten",
  },
  {
    code: "BVrdHalVic",
    descriptionShort: "Halffabrikaten, voorziening incourante halffabrikaten",
    descriptionLong:
      "Halffabrikaten, voorziening incourante halffabrikaten halffabrikaten",
  },
  {
    code: "BVrdHalHvv",
    descriptionShort: "Halffabrikaten, herwaardering voorraden halffabrikaten",
    descriptionLong:
      "Halffabrikaten, herwaardering voorraden halffabrikaten halffabrikaten",
  },
  {
    code: "BVrdOwe",
    descriptionShort: "Onderhanden werk",
    descriptionLong: "Onderhanden werk",
  },
  {
    code: "BVrdOweVoo",
    descriptionShort: "Onderhanden werk, bruto",
    descriptionLong: "Onderhanden werk, bruto onderhanden werk",
  },
  {
    code: "BVrdOweGet",
    descriptionShort: "Gefactureerde termijnen onderhanden werk",
    descriptionLong:
      "Gefactureerde termijnen onderhanden werk onderhanden werk",
  },
  {
    code: "BVrdOweVzv",
    descriptionShort: "Voorziening verliezen onderhanden werk",
    descriptionLong: "Voorziening verliezen onderhanden werk onderhanden werk",
  },
  {
    code: "BVrdGep",
    descriptionShort: "Gereed product",
    descriptionLong: "Gereed product",
  },
  {
    code: "BVrdGepVoo",
    descriptionShort: "Gereed product, bruto",
    descriptionLong: "Gereed product, bruto gereed product",
  },
  {
    code: "BVrdGepVic",
    descriptionShort: "Gereed product, voorziening incourante gereed product",
    descriptionLong:
      "Gereed product, voorziening incourante gereed product gereed product",
  },
  {
    code: "BVrdGepHvv",
    descriptionShort: "Gereed product, herwaardering voorraden gereed product",
    descriptionLong:
      "Gereed product, herwaardering voorraden gereed product gereed product",
  },
  {
    code: "BVrdHan",
    descriptionShort: "Handelsgoederen",
    descriptionLong: "Handelsgoederen",
  },
  {
    code: "BVrdHanVoo",
    descriptionShort: "Handelsgoederen, bruto",
    descriptionLong: "Handelsgoederen, bruto handelsgoederen",
  },
  {
    code: "BVrdHanMgr",
    descriptionShort: "Handelsgoederen, marge-voorraden",
    descriptionLong: "Handelsgoederen, marge-voorraden",
  },
  {
    code: "BVrdHanTus",
    descriptionShort: "Tussenrekening voorraden",
    descriptionLong: "Tussenrekening voorraden",
  },
  {
    code: "BVrdHanVic",
    descriptionShort: "Handelsgoederen, voorziening incourante handelsgoederen",
    descriptionLong:
      "Handelsgoederen, voorziening incourante handelsgoederen handelsgoederen",
  },
  {
    code: "BVrdHanHvv",
    descriptionShort:
      "Handelsgoederen, herwaardering voorraden handelsgoederen",
    descriptionLong:
      "Handelsgoederen, herwaardering voorraden handelsgoederen handelsgoederen",
  },
  {
    code: "BVrdVrv",
    descriptionShort: "Vooruitbetalingen op voorraden",
    descriptionLong: "Vooruitbetalingen op voorraden",
  },
  {
    code: "BVrdVrvVoo",
    descriptionShort: "Voorraad vooruitbetaald op voorraden, bruto",
    descriptionLong:
      "Voorraad vooruitbetaald op voorraden, bruto vooruitbetalingen op voorraden",
  },
  {
    code: "BVrdVrvVic",
    descriptionShort:
      "Voorraad vooruitbetaald op voorraden, voorziening incourante vooruitbetaald op voorraden",
    descriptionLong:
      "Voorraad vooruitbetaald op voorraden, voorziening incourante vooruitbetaald op voorraden vooruitbetalingen op voorraden",
  },
  {
    code: "BVrdVrvHvv",
    descriptionShort:
      "Voorraad vooruitbetaald op voorraden, herwaardering voorraden vooruitbetaald op voorraden",
    descriptionLong:
      "Voorraad vooruitbetaald op voorraden, herwaardering voorraden vooruitbetaald op voorraden vooruitbetalingen op voorraden",
  },
  {
    code: "BVrdEmb",
    descriptionShort: "Emballage",
    descriptionLong: "Emballage",
  },
  {
    code: "BVrdEmbVoo",
    descriptionShort: "Voorraad emballage, bruto",
    descriptionLong: "Voorraad emballage, bruto emballage",
  },
  {
    code: "BVrdEmbVic",
    descriptionShort: "Emballage, voorziening incourante emballage",
    descriptionLong: "Emballage, voorziening incourante emballage emballage",
  },
  {
    code: "BVrdEmbHvv",
    descriptionShort: "Emballage, herwaardering voorraden emballage",
    descriptionLong: "Emballage, herwaardering voorraden emballage emballage",
  },
  {
    code: "BVrdVas",
    descriptionShort: "Vastgoed",
    descriptionLong: "Vastgoed",
  },
  {
    code: "BVrdVasVio",
    descriptionShort: "Vastgoed in ontwikkeling bestemd voor de verkoop",
    descriptionLong:
      "Vastgoed in ontwikkeling bestemd voor de verkoop vastgoed",
  },
  {
    code: "BVrdVasVbv",
    descriptionShort: "Vastgoed bestemd voor de verkoop",
    descriptionLong: "Vastgoed bestemd voor de verkoop vastgoed",
  },
  {
    code: "BVrdVasVic",
    descriptionShort: "Vastgoed, voorziening incourant vastgoed",
    descriptionLong: "Vastgoed, voorziening incourant vastgoed vastgoed",
  },
  {
    code: "BVrdVasHvv",
    descriptionShort: "Vastgoed, herwaardering van voorraden vastgoed",
    descriptionLong: "Vastgoed, herwaardering van voorraden vastgoed vastgoed",
  },
  {
    code: "BVrdNig",
    descriptionShort: "Niet gebruiksvee",
    descriptionLong: "Niet gebruiksvee",
  },
  {
    code: "BVrdNigVoo",
    descriptionShort: "Voorraad niet gebruiksvee",
    descriptionLong: "Voorraad niet gebruiksvee",
  },
  {
    code: "BVrdNigVic",
    descriptionShort: "Voorziening incourant niet gebruiksvee",
    descriptionLong: "Voorziening incourant niet gebruiksvee",
  },
  {
    code: "BVrdNigHvv",
    descriptionShort: "Herwaardering van voorraden niet gebruiksvee",
    descriptionLong: "Herwaardering van voorraden niet gebruiksvee",
  },
  {
    code: "BVrdVoo",
    descriptionShort: "Overige voorraden",
    descriptionLong: "Overige voorraden",
  },
  {
    code: "BVrdVooVoo",
    descriptionShort: "Overige voorraden",
    descriptionLong: "Overige voorraden",
  },
  {
    code: "BPro",
    descriptionShort: "Onderhanden projecten (activa)",
    descriptionLong: "Onderhanden projecten (activa)",
  },
  {
    code: "BProOnp",
    descriptionShort: "Onderhanden projecten in opdracht van derden",
    descriptionLong: "Onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGkn",
    descriptionShort:
      "Onderhanden projecten in opdracht van derden, geactiveerde kosten voor nog niet geleverde diensten",
    descriptionLong:
      "Onderhanden projecten in opdracht van derden, geactiveerde kosten voor nog niet geleverde diensten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGknBeg",
    descriptionShort: "Beginbalans geactiveerde kosten onderhanden projecten",
    descriptionLong:
      "Beginbalans geactiveerde kosten onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGknGeh",
    descriptionShort:
      "Grond- en hulpstoffen geactiveerde kosten onderhanden projecten",
    descriptionLong:
      "Grond- en hulpstoffen geactiveerde kosten onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGknArk",
    descriptionShort: "Arbeidskosten geactiveerde kosten onderhanden projecten",
    descriptionLong:
      "Arbeidskosten geactiveerde kosten onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGknOnd",
    descriptionShort:
      "Onderaanneming geactiveerde kosten onderhanden projecten",
    descriptionLong:
      "Onderaanneming geactiveerde kosten onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGknCon",
    descriptionShort:
      "Constructiematerialen geactiveerde kosten onderhanden projecten",
    descriptionLong:
      "Constructiematerialen geactiveerde kosten onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGknGet",
    descriptionShort:
      "Grond en terreinen geactiveerde kosten onderhanden projecten",
    descriptionLong:
      "Grond en terreinen geactiveerde kosten onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGknAie",
    descriptionShort:
      "Afschrijving installaties en uitrusting geactiveerde kosten onderhanden projecten",
    descriptionLong:
      "Afschrijving installaties en uitrusting geactiveerde kosten onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGknHvi",
    descriptionShort:
      "Huur van installaties en uitrusting geactiveerde kosten onderhanden projecten",
    descriptionLong:
      "Huur van installaties en uitrusting geactiveerde kosten onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGknTvi",
    descriptionShort:
      "Transport van installaties en uitrusting geactiveerde kosten onderhanden projecten",
    descriptionLong:
      "Transport van installaties en uitrusting geactiveerde kosten onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGknOet",
    descriptionShort:
      "Ontwerp en technische assistentie geactiveerde kosten onderhanden projecten",
    descriptionLong:
      "Ontwerp en technische assistentie geactiveerde kosten onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGknHeg",
    descriptionShort:
      "Herstellings- en garantiewerken geactiveerde kosten onderhanden projecten",
    descriptionLong:
      "Herstellings- en garantiewerken geactiveerde kosten onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGknCvd",
    descriptionShort:
      "Claims van derden geactiveerde kosten onderhanden projecten",
    descriptionLong:
      "Claims van derden geactiveerde kosten onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGknVez",
    descriptionShort:
      "Verzekeringskosten geactiveerde kosten onderhanden projecten",
    descriptionLong:
      "Verzekeringskosten geactiveerde kosten onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGknRst",
    descriptionShort:
      "Rentekosten schulden tijdens vervaardiging geactiveerde kosten onderhanden projecten",
    descriptionLong:
      "Rentekosten schulden tijdens vervaardiging geactiveerde kosten onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGknOvh",
    descriptionShort:
      "Overheadkosten geactiveerde kosten onderhanden projecten",
    descriptionLong:
      "Overheadkosten geactiveerde kosten onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGknAko",
    descriptionShort:
      "Algemene kosten (opslag) geactiveerde kosten onderhanden projecten",
    descriptionLong:
      "Algemene kosten (opslag) geactiveerde kosten onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGknWin",
    descriptionShort: "Winstopslag geactiveerde kosten onderhanden projecten",
    descriptionLong:
      "Winstopslag geactiveerde kosten onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGknLbe",
    descriptionShort:
      "Incidentele baten en lasten geactiveerde kosten onderhanden projecten",
    descriptionLong:
      "Incidentele baten en lasten geactiveerde kosten onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGknLdb",
    descriptionShort:
      "Interne doorbelastingen binnen fiscale eenheid geactiveerde kosten onderhanden projecten",
    descriptionLong:
      "Interne doorbelastingen binnen fiscale eenheid geactiveerde kosten onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGknOpw",
    descriptionShort:
      "Opgeleverde werken geactiveerde kosten onderhanden projecten",
    descriptionLong:
      "Opgeleverde werken geactiveerde kosten onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGknOvm",
    descriptionShort:
      "Overige mutaties geactiveerde kosten onderhanden projecten",
    descriptionLong:
      "Overige mutaties geactiveerde kosten onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpOpo",
    descriptionShort:
      "Onderhanden projecten in opdracht van derden, cumulatieve projectopbrengsten",
    descriptionLong:
      "Onderhanden projecten in opdracht van derden, cumulatieve projectopbrengsten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpOpv",
    descriptionShort:
      "Onderhanden projecten in opdracht van derden, voorschotten",
    descriptionLong:
      "Onderhanden projecten in opdracht van derden, voorschotten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGet",
    descriptionShort: "Gefactureerde termijnen",
    descriptionLong: "Gefactureerde termijnen",
  },
  {
    code: "BProOnpGetBeg",
    descriptionShort:
      "Beginbalans gefactureerde termijnen onderhanden projecten",
    descriptionLong:
      "Beginbalans gefactureerde termijnen onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGetBma",
    descriptionShort:
      "Belast met algemeen tarief gefactureerde termijnen onderhanden projecten",
    descriptionLong:
      "Belast met algemeen tarief gefactureerde termijnen onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGetBmv",
    descriptionShort:
      "Belast met verlaagd tarief gefactureerde termijnen onderhanden projecten",
    descriptionLong:
      "Belast met verlaagd tarief gefactureerde termijnen onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGetBmo",
    descriptionShort:
      "Belast met overige tarieven gefactureerde termijnen onderhanden projecten",
    descriptionLong:
      "Belast met overige tarieven gefactureerde termijnen onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGetBmn",
    descriptionShort:
      "Belast met nultarief of niet belast gefactureerde termijnen onderhanden projecten",
    descriptionLong:
      "Belast met nultarief of niet belast gefactureerde termijnen onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGetNbw",
    descriptionShort:
      "Niet belast wegens heffing verlegd gefactureerde termijnen onderhanden projecten",
    descriptionLong:
      "Niet belast wegens heffing verlegd gefactureerde termijnen onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGetLii",
    descriptionShort:
      "Installatie in landen binnen EU gefactureerde termijnen onderhanden projecten",
    descriptionLong:
      "Installatie in landen binnen EU gefactureerde termijnen onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGetLiu",
    descriptionShort:
      "Installatie in landen buiten EU gefactureerde termijnen onderhanden projecten",
    descriptionLong:
      "Installatie in landen buiten EU gefactureerde termijnen onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGetLdb",
    descriptionShort:
      "Interne doorbelastingen binnen fiscale eenheid gefactureerde termijnen onderhanden projecten",
    descriptionLong:
      "Interne doorbelastingen binnen fiscale eenheid gefactureerde termijnen onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGetOpw",
    descriptionShort:
      "Opgeleverde werken gefactureerde termijnen onderhanden projecten",
    descriptionLong:
      "Opgeleverde werken gefactureerde termijnen onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpGetOvm",
    descriptionShort:
      "Overige mutaties gefactureerde termijnen onderhanden projecten",
    descriptionLong:
      "Overige mutaties gefactureerde termijnen onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpOpi",
    descriptionShort:
      "Onderhanden projecten in opdracht van derden, inhoudingen op gedeclareerde termijnen",
    descriptionLong:
      "Onderhanden projecten in opdracht van derden, inhoudingen op gedeclareerde termijnen onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpVzv",
    descriptionShort: "Voorziening verliezen",
    descriptionLong:
      "Voorziening verliezen onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpVzvBeg",
    descriptionShort: "Beginbalans voorziening verliezen onderhanden projecten",
    descriptionLong:
      "Beginbalans voorziening verliezen onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpVzvToe",
    descriptionShort: "Toename voorziening verliezen onderhanden projecten",
    descriptionLong:
      "Toename voorziening verliezen onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpVzvOnt",
    descriptionShort: "Onttrekking voorziening verliezen onderhanden projecten",
    descriptionLong:
      "Onttrekking voorziening verliezen onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpVzvVri",
    descriptionShort: "Vrijval voorziening verliezen onderhanden projecten",
    descriptionLong:
      "Vrijval voorziening verliezen onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpVzvLdb",
    descriptionShort:
      "Interne doorbelastingen binnen fiscale eenheid voorziening verliezen onderhanden projecten",
    descriptionLong:
      "Interne doorbelastingen binnen fiscale eenheid voorziening verliezen onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpVzvOpw",
    descriptionShort:
      "Opgeleverde werken voorziening verliezen onderhanden projecten",
    descriptionLong:
      "Opgeleverde werken voorziening verliezen onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpVzvOvm",
    descriptionShort:
      "Overige mutaties voorziening verliezen onderhanden projecten",
    descriptionLong:
      "Overige mutaties voorziening verliezen onderhanden projecten onderhanden projecten in opdracht van derden",
  },
  {
    code: "BProOnpWin",
    descriptionShort: "Winstopslag onderhanden projecten",
    descriptionLong: "Winstopslag onderhanden projecten",
  },
  {
    code: "BVor",
    descriptionShort: "Vorderingen",
    descriptionLong: "Vorderingen",
  },
  {
    code: "BVorDeb",
    descriptionShort: "Vorderingen op handelsdebiteuren",
    descriptionLong: "Vorderingen op handelsdebiteuren",
  },
  {
    code: "BVorDebHad",
    descriptionShort: "Handelsdebiteuren nominaal",
    descriptionLong: "Handelsdebiteuren nominaal",
  },
  {
    code: "BVorDebHdi",
    descriptionShort: "Handelsdebiteuren intercompany",
    descriptionLong: "Handelsdebiteuren intercompany",
  },
  {
    code: "BVorDebVdd",
    descriptionShort:
      "Voorziening voor oninbaarheid op vorderingen op handelsdebiteuren",
    descriptionLong:
      "Voorziening voor oninbaarheid op vorderingen op handelsdebiteuren ",
  },
  {
    code: "BVorDebHdb",
    descriptionShort: "Huurdebiteuren",
    descriptionLong: "Huurdebiteuren",
  },
  {
    code: "BVorDebTus",
    descriptionShort:
      "Tussenrekening ontvangsten debiteuren (ontvangsten onderweg)",
    descriptionLong:
      "Tussenrekening ontvangsten debiteuren (ontvangsten onderweg)",
  },
  {
    code: "BVorDebVhd",
    descriptionShort:
      "Voorziening voor oninbaarheid op vorderingen op huurdebiteuren",
    descriptionLong:
      "Voorziening voor oninbaarheid op vorderingen op huurdebiteuren",
  },
  {
    code: "BVorVog",
    descriptionShort: "Vorderingen op groepsmaatschappijen (kortlopend)",
    descriptionLong: "Vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVogVr1",
    descriptionShort: "Rekening-courant groepsmaatschappij 1",
    descriptionLong: "Rekening-courant groepsmaatschappij 1",
  },
  {
    code: "BVorVogVr1Rec",
    descriptionShort: "Rekening courant",
    descriptionLong:
      "Rekening courant vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVogVr1Cwv",
    descriptionShort: "Cumulatieve waardeverminderingen",
    descriptionLong: "Cumulatieve waardeverminderingen",
  },
  {
    code: "BVorVogVr1Doo",
    descriptionShort: "Doorbelastingen",
    descriptionLong: "Doorbelastingen",
  },
  {
    code: "BVorVogVr1Tvd",
    descriptionShort: "Te vorderen dividend",
    descriptionLong:
      "Te vorderen dividend vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVogVr1Wve",
    descriptionShort: "Waardeveranderingen",
    descriptionLong: "Waardeveranderingen",
  },
  {
    code: "BVorVogVr1Ovm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige vorderingen vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVogVr2",
    descriptionShort: "Rekening-courant groepsmaatschappij 2",
    descriptionLong: "Rekening-courant groepsmaatschappij 2",
  },
  {
    code: "BVorVogVr2Rec",
    descriptionShort: "Rekening courant",
    descriptionLong:
      "Rekening courant vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVogVr2Cwv",
    descriptionShort: "Cumulatieve waardeverminderingen",
    descriptionLong: "Cumulatieve waardeverminderingen",
  },
  {
    code: "BVorVogVr2Doo",
    descriptionShort: "Doorbelastingen",
    descriptionLong: "Doorbelastingen",
  },
  {
    code: "BVorVogVr2Tvd",
    descriptionShort: "Te vorderen dividend",
    descriptionLong:
      "Te vorderen dividend vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVogVr2Wve",
    descriptionShort: "Waardeveranderingen",
    descriptionLong: "Waardeveranderingen",
  },
  {
    code: "BVorVogVr2Ovm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige vorderingen vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVogVr3",
    descriptionShort: "Rekening-courant groepsmaatschappij 3",
    descriptionLong: "Rekening-courant groepsmaatschappij 3",
  },
  {
    code: "BVorVogVr3Rec",
    descriptionShort: "Rekening courant",
    descriptionLong:
      "Rekening courant vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVogVr3Cwv",
    descriptionShort: "Cumulatieve waardeverminderingen",
    descriptionLong: "Cumulatieve waardeverminderingen",
  },
  {
    code: "BVorVogVr3Doo",
    descriptionShort: "Doorbelastingen",
    descriptionLong: "Doorbelastingen",
  },
  {
    code: "BVorVogVr3Tvd",
    descriptionShort: "Te vorderen dividend",
    descriptionLong:
      "Te vorderen dividend vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVogVr3Wve",
    descriptionShort: "Waardeveranderingen",
    descriptionLong: "Waardeveranderingen",
  },
  {
    code: "BVorVogVr3Ovm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige vorderingen vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVogVr4",
    descriptionShort: "Rekening-courant groepsmaatschappij 4",
    descriptionLong: "Rekening-courant groepsmaatschappij 4",
  },
  {
    code: "BVorVogVr4Rec",
    descriptionShort: "Rekening courant",
    descriptionLong:
      "Rekening courant vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVogVr4Cwv",
    descriptionShort: "Cumulatieve waardeverminderingen",
    descriptionLong: "Cumulatieve waardeverminderingen",
  },
  {
    code: "BVorVogVr4Doo",
    descriptionShort: "Doorbelastingen",
    descriptionLong: "Doorbelastingen",
  },
  {
    code: "BVorVogVr4Tvd",
    descriptionShort: "Te vorderen dividend",
    descriptionLong:
      "Te vorderen dividend vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVogVr4Wve",
    descriptionShort: "Waardeveranderingen",
    descriptionLong: "Waardeveranderingen",
  },
  {
    code: "BVorVogVr4Ovm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige vorderingen vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVogVr5",
    descriptionShort: "Rekening-courant groepsmaatschappij 5",
    descriptionLong: "Rekening-courant groepsmaatschappij 5",
  },
  {
    code: "BVorVogVr5Rec",
    descriptionShort: "Rekening courant",
    descriptionLong:
      "Rekening courant vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVogVr5Cwv",
    descriptionShort: "Cumulatieve waardeverminderingen",
    descriptionLong: "Cumulatieve waardeverminderingen",
  },
  {
    code: "BVorVogVr5Doo",
    descriptionShort: "Doorbelastingen",
    descriptionLong: "Doorbelastingen",
  },
  {
    code: "BVorVogVr5Tvd",
    descriptionShort: "Te vorderen dividend",
    descriptionLong:
      "Te vorderen dividend vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVogVr5Wve",
    descriptionShort: "Waardeveranderingen",
    descriptionLong: "Waardeveranderingen",
  },
  {
    code: "BVorVogVr5Ovm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige vorderingen vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVogVg1",
    descriptionShort: "Vordering / lening groepsmaatschappij 1",
    descriptionLong: "Vordering / lening Daeb",
  },
  {
    code: "BVorVogVg1Hoo",
    descriptionShort: "Saldo hoofdsom lening u/g",
    descriptionLong: "Saldo hoofdsom lening u/g",
  },
  {
    code: "BVorVogVg1Afl",
    descriptionShort: "Aflossing leningen u/g",
    descriptionLong:
      "Aflossing leningen u/g vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVogVg1Tvr",
    descriptionShort: "Te vorderen rente leningen u/g",
    descriptionLong:
      "Te vorderen rente leningen u/g vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVogDae",
    descriptionShort: "Rekening-courant DAEB",
    descriptionLong: "Rekening-courant DAEB",
  },
  {
    code: "BVorVogDaeRec",
    descriptionShort: "Rekening courant",
    descriptionLong: "Rekening courant",
  },
  {
    code: "BVorVogDaeCwv",
    descriptionShort: "Cumulatieve waardeverminderingen",
    descriptionLong: "Cumulatieve waardeverminderingen",
  },
  {
    code: "BVorVogDaeDoo",
    descriptionShort: "Doorbelastingen",
    descriptionLong: "Doorbelastingen",
  },
  {
    code: "BVorVogDaeTvd",
    descriptionShort: "Te vorderen dividend",
    descriptionLong: "Te vorderen dividend",
  },
  {
    code: "BVorVogDaeWve",
    descriptionShort: "Waardeveranderingen",
    descriptionLong: "Waardeveranderingen",
  },
  {
    code: "BVorVogDaeOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties",
  },
  {
    code: "BVorVogNda",
    descriptionShort: "Rekening-courant Niet-DAEB",
    descriptionLong: "Rekening-courant Niet-DAEB",
  },
  {
    code: "BVorVogNdaRec",
    descriptionShort: "Rekening courant",
    descriptionLong: "Rekening courant",
  },
  {
    code: "BVorVogNdaCwv",
    descriptionShort: "Cumulatieve waardeverminderingen",
    descriptionLong: "Cumulatieve waardeverminderingen",
  },
  {
    code: "BVorVogNdaDoo",
    descriptionShort: "Doorbelastingen",
    descriptionLong: "Doorbelastingen",
  },
  {
    code: "BVorVogNdaTvd",
    descriptionShort: "Te vorderen dividend",
    descriptionLong: "Te vorderen dividend",
  },
  {
    code: "BVorVogNdaWve",
    descriptionShort: "Waardeveranderingen",
    descriptionLong: "Waardeveranderingen",
  },
  {
    code: "BVorVogNdaOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties",
  },
  {
    code: "BVorVov",
    descriptionShort:
      "Vorderingen op overige verbonden maatschappijen (kortlopend)",
    descriptionLong:
      "Vorderingen op overige verbonden maatschappijen (kortlopend)",
  },
  {
    code: "BVorVovVr1",
    descriptionShort: "Rekening-courant overige verbonden maatschappij 1",
    descriptionLong: "Rekening-courant overige verbonden maatschappij 1",
  },
  {
    code: "BVorVovVr1Rec",
    descriptionShort: "Rekening courant",
    descriptionLong:
      "Rekening courant vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVovVr1Cwv",
    descriptionShort: "Cumulatieve waardeverminderingen",
    descriptionLong: "Cumulatieve waardeverminderingen",
  },
  {
    code: "BVorVovVr1Doo",
    descriptionShort: "Doorbelastingen",
    descriptionLong: "Doorbelastingen",
  },
  {
    code: "BVorVovVr1Tvd",
    descriptionShort: "Te vorderen dividend",
    descriptionLong:
      "Te vorderen dividend vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVovVr1Wve",
    descriptionShort: "Waardeveranderingen",
    descriptionLong: "Waardeveranderingen",
  },
  {
    code: "BVorVovVr1Ovm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige vorderingen vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVovVr2",
    descriptionShort: "Rekening-courant overige verbonden maatschappij 2",
    descriptionLong: "Rekening-courant overige verbonden maatschappij 2",
  },
  {
    code: "BVorVovVr2Rec",
    descriptionShort: "Rekening courant",
    descriptionLong:
      "Rekening courant vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVovVr2Cwv",
    descriptionShort: "Cumulatieve waardeverminderingen",
    descriptionLong: "Cumulatieve waardeverminderingen",
  },
  {
    code: "BVorVovVr2Doo",
    descriptionShort: "Doorbelastingen",
    descriptionLong: "Doorbelastingen",
  },
  {
    code: "BVorVovVr2Tvd",
    descriptionShort: "Te vorderen dividend",
    descriptionLong:
      "Te vorderen dividend vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVovVr2Wve",
    descriptionShort: "Waardeveranderingen",
    descriptionLong: "Waardeveranderingen",
  },
  {
    code: "BVorVovVr2Ovm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige vorderingen vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVovVr3",
    descriptionShort: "Rekening-courant overige verbonden maatschappij 3",
    descriptionLong: "Rekening-courant overige verbonden maatschappij 3",
  },
  {
    code: "BVorVovVr3Rec",
    descriptionShort: "Rekening courant",
    descriptionLong:
      "Rekening courant vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVovVr3Cwv",
    descriptionShort: "Cumulatieve waardeverminderingen",
    descriptionLong: "Cumulatieve waardeverminderingen",
  },
  {
    code: "BVorVovVr3Doo",
    descriptionShort: "Doorbelastingen",
    descriptionLong: "Doorbelastingen",
  },
  {
    code: "BVorVovVr3Tvd",
    descriptionShort: "Te vorderen dividend",
    descriptionLong:
      "Te vorderen dividend vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVovVr3Wve",
    descriptionShort: "Waardeveranderingen",
    descriptionLong: "Waardeveranderingen",
  },
  {
    code: "BVorVovVr3Ovm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige vorderingen vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVovVr4",
    descriptionShort: "Rekening-courant overige verbonden maatschappij 4",
    descriptionLong: "Rekening-courant overige verbonden maatschappij 4",
  },
  {
    code: "BVorVovVr4Rec",
    descriptionShort: "Rekening courant",
    descriptionLong:
      "Rekening courant vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVovVr4Cwv",
    descriptionShort: "Cumulatieve waardeverminderingen",
    descriptionLong: "Cumulatieve waardeverminderingen",
  },
  {
    code: "BVorVovVr4Doo",
    descriptionShort: "Doorbelastingen",
    descriptionLong: "Doorbelastingen",
  },
  {
    code: "BVorVovVr4Tvd",
    descriptionShort: "Te vorderen dividend",
    descriptionLong:
      "Te vorderen dividend vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVovVr4Wve",
    descriptionShort: "Waardeveranderingen",
    descriptionLong: "Waardeveranderingen",
  },
  {
    code: "BVorVovVr4Ovm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige vorderingen vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVovVr5",
    descriptionShort: "Rekening-courant overige verbonden maatschappij 5",
    descriptionLong: "Rekening-courant overige verbonden maatschappij 5",
  },
  {
    code: "BVorVovVr5Rec",
    descriptionShort: "Rekening courant",
    descriptionLong:
      "Rekening courant vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVovVr5Cwv",
    descriptionShort: "Cumulatieve waardeverminderingen",
    descriptionLong: "Cumulatieve waardeverminderingen",
  },
  {
    code: "BVorVovVr5Doo",
    descriptionShort: "Doorbelastingen",
    descriptionLong: "Doorbelastingen",
  },
  {
    code: "BVorVovVr5Tvd",
    descriptionShort: "Te vorderen dividend",
    descriptionLong:
      "Te vorderen dividend vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVovVr5Wve",
    descriptionShort: "Waardeveranderingen",
    descriptionLong: "Waardeveranderingen",
  },
  {
    code: "BVorVovVr5Ovm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige vorderingen vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVovVo1",
    descriptionShort: "Vordering / lening overige verbonden maatschappij 1",
    descriptionLong: "Vordering / lening overige verbonden maatschappij 1",
  },
  {
    code: "BVorVovVo1Hoo",
    descriptionShort: "Saldo hoofdsom lening u/g",
    descriptionLong: "Saldo hoofdsom lening u/g",
  },
  {
    code: "BVorVovVo1Afl",
    descriptionShort: "Aflossing leningen u/g",
    descriptionLong:
      "Aflossing leningen u/g vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVovVo1Tvr",
    descriptionShort: "Te vorderen rente leningen u/g",
    descriptionLong:
      "Te vorderen rente leningen u/g vorderingen op groepsmaatschappijen (kortlopend)",
  },
  {
    code: "BVorVop",
    descriptionShort:
      "Vorderingen op participanten en op maatschappijen waarin wordt deelgenomen (kortlopend)",
    descriptionLong:
      "Vorderingen op participanten en op maatschappijen waarin wordt deelgenomen (kortlopend)",
  },
  {
    code: "BVorVopVr1",
    descriptionShort:
      "Rekening-courant participant en op maatschappij waarin wordt deelgenomen 1 (kortlopend)",
    descriptionLong:
      "Rekening-courant participant en op maatschappij waarin wordt deelgenomen 1 (kortlopend)",
  },
  {
    code: "BVorVopVr1Rec",
    descriptionShort: "Rekening courant",
    descriptionLong:
      "Rekening courant vorderingen op participanten en op maatschappijen waarin wordt deelgenomen (kortlopend)",
  },
  {
    code: "BVorVopVr1Cwv",
    descriptionShort: "Cumulatieve waardeverminderingen",
    descriptionLong: "Cumulatieve waardeverminderingen",
  },
  {
    code: "BVorVopVr1Doo",
    descriptionShort: "Doorbelastingen",
    descriptionLong: "Doorbelastingen",
  },
  {
    code: "BVorVopVr1Tvd",
    descriptionShort: "Te vorderen dividend",
    descriptionLong:
      "Te vorderen dividend vorderingen op participanten en op maatschappijen waarin wordt deelgenomen (kortlopend)",
  },
  {
    code: "BVorVopVr1Wve",
    descriptionShort: "Waardeveranderingen",
    descriptionLong: "Waardeveranderingen",
  },
  {
    code: "BVorVopVr1Ovm",
    descriptionShort: "Overige vorderingen",
    descriptionLong:
      "Overige vorderingen vorderingen op participanten en op maatschappijen waarin wordt deelgenomen (kortlopend)",
  },
  {
    code: "BVorVopVr2",
    descriptionShort:
      "Rekening-courant participant en op maatschappij waarin wordt deelgenomen 2 (kortlopend)",
    descriptionLong:
      "Rekening-courant participant en op maatschappij waarin wordt deelgenomen 2 (kortlopend)",
  },
  {
    code: "BVorVopVr2Rec",
    descriptionShort: "Rekening courant",
    descriptionLong:
      "Rekening courant vorderingen op participanten en op maatschappijen waarin wordt deelgenomen (kortlopend)",
  },
  {
    code: "BVorVopVr2Cwv",
    descriptionShort: "Cumulatieve waardeverminderingen",
    descriptionLong: "Cumulatieve waardeverminderingen",
  },
  {
    code: "BVorVopVr2Doo",
    descriptionShort: "Doorbelastingen",
    descriptionLong: "Doorbelastingen",
  },
  {
    code: "BVorVopVr2Tvd",
    descriptionShort: "Te vorderen dividend",
    descriptionLong:
      "Te vorderen dividend vorderingen op participanten en op maatschappijen waarin wordt deelgenomen (kortlopend)",
  },
  {
    code: "BVorVopVr2Wve",
    descriptionShort: "Waardeveranderingen",
    descriptionLong: "Waardeveranderingen",
  },
  {
    code: "BVorVopVr2Ovm",
    descriptionShort: "Overige vorderingen",
    descriptionLong:
      "Overige vorderingen vorderingen op participanten en op maatschappijen waarin wordt deelgenomen (kortlopend)",
  },
  {
    code: "BVorVopVr3",
    descriptionShort:
      "Rekening-courant participant en op maatschappij waarin wordt deelgenomen 3 (kortlopend)",
    descriptionLong:
      "Rekening-courant participant en op maatschappij waarin wordt deelgenomen 3 (kortlopend)",
  },
  {
    code: "BVorVopVr3Rec",
    descriptionShort: "Rekening courant",
    descriptionLong:
      "Rekening courant vorderingen op participanten en op maatschappijen waarin wordt deelgenomen (kortlopend)",
  },
  {
    code: "BVorVopVr3Cwv",
    descriptionShort: "Cumulatieve waardeverminderingen",
    descriptionLong: "Cumulatieve waardeverminderingen",
  },
  {
    code: "BVorVopVr3Doo",
    descriptionShort: "Doorbelastingen",
    descriptionLong: "Doorbelastingen",
  },
  {
    code: "BVorVopVr3Tvd",
    descriptionShort: "Te vorderen dividend",
    descriptionLong:
      "Te vorderen dividend vorderingen op participanten en op maatschappijen waarin wordt deelgenomen (kortlopend)",
  },
  {
    code: "BVorVopVr3Wve",
    descriptionShort: "Waardeveranderingen",
    descriptionLong: "Waardeveranderingen",
  },
  {
    code: "BVorVopVr3Ovm",
    descriptionShort: "Overige vorderingen",
    descriptionLong:
      "Overige vorderingen vorderingen op participanten en op maatschappijen waarin wordt deelgenomen (kortlopend)",
  },
  {
    code: "BVorVopVr4",
    descriptionShort:
      "Rekening-courant participant en op maatschappij waarin wordt deelgenomen 4 (kortlopend)",
    descriptionLong:
      "Rekening-courant participant en op maatschappij waarin wordt deelgenomen 4 (kortlopend)",
  },
  {
    code: "BVorVopVr4Rec",
    descriptionShort: "Rekening courant",
    descriptionLong:
      "Rekening courant vorderingen op participanten en op maatschappijen waarin wordt deelgenomen (kortlopend)",
  },
  {
    code: "BVorVopVr4Cwv",
    descriptionShort: "Cumulatieve waardeverminderingen",
    descriptionLong: "Cumulatieve waardeverminderingen",
  },
  {
    code: "BVorVopVr4Doo",
    descriptionShort: "Doorbelastingen",
    descriptionLong: "Doorbelastingen",
  },
  {
    code: "BVorVopVr4Tvd",
    descriptionShort: "Te vorderen dividend",
    descriptionLong:
      "Te vorderen dividend vorderingen op participanten en op maatschappijen waarin wordt deelgenomen (kortlopend)",
  },
  {
    code: "BVorVopVr4Wve",
    descriptionShort: "Waardeveranderingen",
    descriptionLong: "Waardeveranderingen",
  },
  {
    code: "BVorVopVr4Ovm",
    descriptionShort: "Overige vorderingen",
    descriptionLong:
      "Overige vorderingen vorderingen op participanten en op maatschappijen waarin wordt deelgenomen (kortlopend)",
  },
  {
    code: "BVorVopVr5",
    descriptionShort:
      "Rekening-courant participant en op maatschappij waarin wordt deelgenomen 5 (kortlopend)",
    descriptionLong:
      "Rekening-courant participant en op maatschappij waarin wordt deelgenomen 5 (kortlopend)",
  },
  {
    code: "BVorVopVr5Rec",
    descriptionShort: "Rekening courant",
    descriptionLong:
      "Rekening courant vorderingen op participanten en op maatschappijen waarin wordt deelgenomen (kortlopend)",
  },
  {
    code: "BVorVopVr5Cwv",
    descriptionShort: "Cumulatieve waardeverminderingen",
    descriptionLong: "Cumulatieve waardeverminderingen",
  },
  {
    code: "BVorVopVr5Doo",
    descriptionShort: "Doorbelastingen",
    descriptionLong: "Doorbelastingen",
  },
  {
    code: "BVorVopVr5Tvd",
    descriptionShort: "Te vorderen dividend",
    descriptionLong:
      "Te vorderen dividend vorderingen op participanten en op maatschappijen waarin wordt deelgenomen (kortlopend)",
  },
  {
    code: "BVorVopVr5Wve",
    descriptionShort: "Waardeveranderingen",
    descriptionLong: "Waardeveranderingen",
  },
  {
    code: "BVorVopVr5Ovm",
    descriptionShort: "Overige vorderingen",
    descriptionLong:
      "Overige vorderingen vorderingen op participanten en op maatschappijen waarin wordt deelgenomen (kortlopend)",
  },
  {
    code: "BVorVopVo1",
    descriptionShort:
      "Vordering / lening participant en op maatschappij waarin wordt deelgenomen 1",
    descriptionLong:
      "Vordering / lening participant en op maatschappij waarin wordt deelgenomen 1",
  },
  {
    code: "BVorVopVo1Hoo",
    descriptionShort: "Saldo hoofdsom lening u/g",
    descriptionLong: "Saldo hoofdsom lening u/g",
  },
  {
    code: "BVorVopVo1Afl",
    descriptionShort: "Aflossing leningen u/g",
    descriptionLong:
      "Aflossing leningen u/g vorderingen op participanten en op maatschappijen waarin wordt deelgenomen (kortlopend)",
  },
  {
    code: "BVorVopVo1Tvr",
    descriptionShort: "Te vorderen rente leningen u/g",
    descriptionLong:
      "Te vorderen rente leningen u/g vorderingen op participanten en op maatschappijen waarin wordt deelgenomen (kortlopend)",
  },
  {
    code: "BVorVlc",
    descriptionShort: "Vorderingen op leden van de coöperatie",
    descriptionLong: "Vorderingen op leden van de coöperatie",
  },
  {
    code: "BVorVlcLi1",
    descriptionShort: "Vorderingen op lid A van de coöperatie",
    descriptionLong: "Vorderingen op lid A van de coöperatie",
  },
  {
    code: "BVorVlcLi2",
    descriptionShort: "Vorderingen op lid B van de coöperatie",
    descriptionLong: "Vorderingen op lid B van de coöperatie",
  },
  {
    code: "BVorVlcLi3",
    descriptionShort: "Vorderingen op lid C van de coöperatie",
    descriptionLong: "Vorderingen op lid C van de coöperatie",
  },
  {
    code: "BVorVlcLi4",
    descriptionShort: "Vorderingen op lid D van de coöperatie",
    descriptionLong: "Vorderingen op lid D van de coöperatie",
  },
  {
    code: "BVorVlcLi5",
    descriptionShort: "Vorderingen op lid E van de coöperatie",
    descriptionLong: "Vorderingen op lid E van de coöperatie",
  },
  {
    code: "BVorVao",
    descriptionShort: "Van aandeelhouders opgevraagde stortingen",
    descriptionLong: "Van aandeelhouders opgevraagde stortingen",
  },
  {
    code: "BVorVaoNtv",
    descriptionShort: "Nog te verrichten stortingen op aandelen",
    descriptionLong:
      "Nog te verrichten stortingen op aandelen van aandeelhouders opgevraagde stortingen",
  },
  {
    code: "BVorVaoVuh",
    descriptionShort:
      "Vorderingen uit hoofde van leningen en voorschotten aan leden of houders van aandelen op naam (kortlopend)",
    descriptionLong:
      "Vorderingen uit hoofde van leningen en voorschotten aan leden of houders van aandelen op naam (kortlopend) van aandeelhouders opgevraagde stortingen",
  },
  {
    code: "BVorVbk",
    descriptionShort: "Vorderingen uit hoofde van belastingen",
    descriptionLong: "Vorderingen uit hoofde van belastingen",
  },
  {
    code: "BVorVbkVbk",
    descriptionShort: "Vorderingen uit hoofde van belastingen",
    descriptionLong: "Vorderingen uit hoofde van belastingen",
  },
  {
    code: "BVorVbkTvo",
    descriptionShort: "Terug te vorderen Omzetbelasting",
    descriptionLong:
      "Terug te vorderen Omzetbelasting vorderingen uit hoofde van belastingen",
  },
  {
    code: "BVorVbkTvoToi",
    descriptionShort: "Terug te ontvangen binnenlandse omzetbelasting",
    descriptionLong:
      "Terug te ontvangen binnenlandse omzetbelasting vorderingen uit hoofde van belastingen",
  },
  {
    code: "BVorVbkTvoTou",
    descriptionShort: "Terug te ontvangen buitenlandse omzetbelasting",
    descriptionLong:
      "Terug te ontvangen buitenlandse omzetbelasting vorderingen uit hoofde van belastingen",
  },
  {
    code: "BVorVbkEob",
    descriptionShort: "Terug te vorderen EU omzetbelasting",
    descriptionLong: "Terug te vorderen EU omzetbelasting",
  },
  {
    code: "BVorVbkTvl",
    descriptionShort: "Terug te vorderen Loonheffing",
    descriptionLong:
      "Terug te vorderen Loonheffing vorderingen uit hoofde van belastingen",
  },
  {
    code: "BVorVbkTvv",
    descriptionShort: "Terug te vorderen Vennootschapsbelasting",
    descriptionLong:
      "Terug te vorderen Vennootschapsbelasting vorderingen uit hoofde van belastingen",
  },
  {
    code: "BVorVbkTtv",
    descriptionShort: "Terug te vorderen Dividendbelasting",
    descriptionLong:
      "Terug te vorderen Dividendbelasting vorderingen uit hoofde van belastingen",
  },
  {
    code: "BVorVbkInd",
    descriptionShort: "Ingehouden dividendbelasting",
    descriptionLong: "Ingehouden dividendbelasting",
  },
  {
    code: "BVorVbkTtb",
    descriptionShort: "Terug te vorderen overige belastingen",
    descriptionLong:
      "Terug te vorderen overige belastingen vorderingen uit hoofde van belastingen",
  },
  {
    code: "BVorLbv",
    descriptionShort: "Latente belastingvorderingen (kortlopend)",
    descriptionLong: "Latente belastingvorderingen (kortlopend)",
  },
  {
    code: "BVorLbvBlk",
    descriptionShort: "Latente belastingvorderingen (kortlopend)",
    descriptionLong: "Latente belastingvorderingen (kortlopend)",
  },
  {
    code: "BVorLbvCba",
    descriptionShort: "Te verrekenen met verliezen uit het verleden",
    descriptionLong:
      "Te verrekenen met verliezen uit het verleden latente belastingvorderingen (kortlopend)",
  },
  {
    code: "BVorLbvCfo",
    descriptionShort: "Te verrekenen met toekomstige verliezen",
    descriptionLong:
      "Te verrekenen met toekomstige verliezen latente belastingvorderingen (kortlopend)",
  },
  {
    code: "BVorTsk",
    descriptionShort: "Te vorderen subsidies",
    descriptionLong: "Te vorderen subsidies",
  },
  {
    code: "BVorTskTos",
    descriptionShort: "Te vorderen overheidssubsidies",
    descriptionLong: "Te vorderen overheidssubsidies te vorderen subsidies",
  },
  {
    code: "BVorTskTls",
    descriptionShort: "Te vorderen loonsubsidie",
    descriptionLong: "Te vorderen loonsubsidie te vorderen subsidies",
  },
  {
    code: "BVorTskTvs",
    descriptionShort: "Te vorderen overige subsidies",
    descriptionLong: "Te vorderen overige subsidies te vorderen subsidies",
  },
  {
    code: "BVorVpk",
    descriptionShort: "Vorderingen uit hoofde van pensioenen",
    descriptionLong: "Vorderingen uit hoofde van pensioenen",
  },
  {
    code: "BVorVpkTto",
    descriptionShort: "Terug te ontvangen pensioenpremies",
    descriptionLong:
      "Terug te ontvangen pensioenpremies vorderingen uit hoofde van pensioenen",
  },
  {
    code: "BVorVpkTop",
    descriptionShort: "Te ontvangen pensioenuitkeringen",
    descriptionLong:
      "Te ontvangen pensioenuitkeringen vorderingen uit hoofde van pensioenen",
  },
  {
    code: "BVorOvr",
    descriptionShort: "Overige vorderingen",
    descriptionLong: "Overige vorderingen",
  },
  {
    code: "BVorOvrLvb",
    descriptionShort:
      "Leningen, voorschotten en garanties ten behoeve van bestuurders en gewezen bestuurders",
    descriptionLong:
      "Leningen, voorschotten en garanties ten behoeve van bestuurders en gewezen bestuurders overige vorderingen (kortlopend)",
  },
  {
    code: "BVorOvrLvc",
    descriptionShort:
      "Leningen, voorschotten en garanties ten behoeve van commissarissen en gewezen commissarissen",
    descriptionLong:
      "Leningen, voorschotten en garanties ten behoeve van commissarissen en gewezen commissarissen overige vorderingen (kortlopend)",
  },
  {
    code: "BVorOvrLek",
    descriptionShort: "Ledenrekeningen",
    descriptionLong: "Ledenrekeningen overige vorderingen",
  },
  {
    code: "BVorOvrRcb",
    descriptionShort: "Rekening-courant bestuurders",
    descriptionLong:
      "Rekening-courant bestuurders overige vorderingen (kortlopend)",
  },
  {
    code: "BVorOvrRcbRb1",
    descriptionShort: "Rekening-courant bestuurder 1",
    descriptionLong: "Rekening-courant bestuurder 1",
  },
  {
    code: "BVorOvrRcbRb2",
    descriptionShort: "Rekening-courant bestuurder 2",
    descriptionLong: "Rekening-courant bestuurder 2",
  },
  {
    code: "BVorOvrRcbRb3",
    descriptionShort: "Rekening-courant bestuurder 3",
    descriptionLong: "Rekening-courant bestuurder 3",
  },
  {
    code: "BVorOvrRcbRb4",
    descriptionShort: "Rekening-courant bestuurder 4",
    descriptionLong: "Rekening-courant bestuurder 4",
  },
  {
    code: "BVorOvrRcbRb5",
    descriptionShort: "Rekening-courant bestuurder 5",
    descriptionLong: "Rekening-courant bestuurder 5",
  },
  {
    code: "BVorOvrRcc",
    descriptionShort: "Rekening-courant commissarissen",
    descriptionLong:
      "Rekening-courant commissarissen overige vorderingen (kortlopend)",
  },
  {
    code: "BVorOvrRccRc1",
    descriptionShort: "Rekening-courant commissaris 1",
    descriptionLong: "Rekening-courant commissaris 1",
  },
  {
    code: "BVorOvrRccRc2",
    descriptionShort: "Rekening-courant commissaris 2",
    descriptionLong: "Rekening-courant commissaris 2",
  },
  {
    code: "BVorOvrRccRc3",
    descriptionShort: "Rekening-courant commissaris 3",
    descriptionLong: "Rekening-courant commissaris 3",
  },
  {
    code: "BVorOvrRccRc4",
    descriptionShort: "Rekening-courant commissaris 4",
    descriptionLong: "Rekening-courant commissaris 4",
  },
  {
    code: "BVorOvrRccRc5",
    descriptionShort: "Rekening-courant commissaris 5",
    descriptionLong: "Rekening-courant commissaris 5",
  },
  {
    code: "BVorOvrRco",
    descriptionShort: "Rekening-courant overigen",
    descriptionLong: "Rekening-courant overigen (kortlopend)",
  },
  {
    code: "BVorOvrRcoRo1",
    descriptionShort: "Rekening-courant overige 1",
    descriptionLong: "Rekening-courant overige 1",
  },
  {
    code: "BVorOvrRcoRo2",
    descriptionShort: "Rekening-courant overige 2",
    descriptionLong: "Rekening-courant overige 2",
  },
  {
    code: "BVorOvrRcoRo3",
    descriptionShort: "Rekening-courant overige 3",
    descriptionLong: "Rekening-courant overige 3",
  },
  {
    code: "BVorOvrRcoRo4",
    descriptionShort: "Rekening-courant overige 4",
    descriptionLong: "Rekening-courant overige 4",
  },
  {
    code: "BVorOvrRcoRo5",
    descriptionShort: "Rekening-courant overige 5",
    descriptionLong: "Rekening-courant overige 5",
  },
  {
    code: "BVorOvrRca",
    descriptionShort: "Rekening-courant aandeelhouders",
    descriptionLong: "Rekening-courant aandeelhouders (kortlopend)",
  },
  {
    code: "BVorOvrRcaRa1",
    descriptionShort: "Rekening-courant aandeelhouder 1",
    descriptionLong: "Rekening-courant aandeelhouder 1",
  },
  {
    code: "BVorOvrRcaRa2",
    descriptionShort: "Rekening-courant aandeelhouder 2",
    descriptionLong: "Rekening-courant aandeelhouder 2",
  },
  {
    code: "BVorOvrRcaRa3",
    descriptionShort: "Rekening-courant aandeelhouder 3",
    descriptionLong: "Rekening-courant aandeelhouder 3",
  },
  {
    code: "BVorOvrRcaRa4",
    descriptionShort: "Rekening-courant aandeelhouder 4",
    descriptionLong: "Rekening-courant aandeelhouder 4",
  },
  {
    code: "BVorOvrRcaRa5",
    descriptionShort: "Rekening-courant aandeelhouder 5",
    descriptionLong: "Rekening-courant aandeelhouder 5",
  },
  {
    code: "BVorOvrWbs",
    descriptionShort: "Waarborgsommen",
    descriptionLong: "Waarborgsommen overige vorderingen",
  },
  {
    code: "BVorOvrVrb",
    descriptionShort: "Vooruitbetalingen",
    descriptionLong: "Vooruitbetalingen",
  },
  {
    code: "BVorOvrTvr",
    descriptionShort: "Te vorderen rente lening",
    descriptionLong: "Te vorderen rente lening",
  },
  {
    code: "BVorOvrTvo",
    descriptionShort: "Te vorderen overige rente",
    descriptionLong: "Te vorderen overige rente",
  },
  {
    code: "BVorOvrOvk",
    descriptionShort: "Overige vorderingen",
    descriptionLong: "Overige vorderingen overige vorderingen",
  },
  {
    code: "BVorOvrLen",
    descriptionShort: "Leningen u/g (kortlopend)",
    descriptionLong: "Leningen u/g (kortlopend)",
  },
  {
    code: "BVorOvrLenLn1",
    descriptionShort: "Lening u/g 1 (kortlopend)",
    descriptionLong: "Lening u/g 1 (kortlopend)",
  },
  {
    code: "BVorOvrLenLn2",
    descriptionShort: "Lening u/g 2 (kortlopend)",
    descriptionLong: "Lening u/g 2 (kortlopend)",
  },
  {
    code: "BVorOvrLenLn3",
    descriptionShort: "Lening u/g 3 (kortlopend)",
    descriptionLong: "Lening u/g 3 (kortlopend)",
  },
  {
    code: "BVorOvrLenLn4",
    descriptionShort: "Lening u/g 4 (kortlopend)",
    descriptionLong: "Lening u/g 4 (kortlopend)",
  },
  {
    code: "BVorOvrLenLn5",
    descriptionShort: "Lening u/g 5 (kortlopend)",
    descriptionLong: "Lening u/g 5 (kortlopend)",
  },
  {
    code: "BVorOvrIln",
    descriptionShort: "Interne lening (kortlopend)",
    descriptionLong: "Interne lening (kortlopend)",
  },
  {
    code: "BVorOvrMcd",
    descriptionShort: "Margin-call deposito",
    descriptionLong: "Margin-call deposito",
  },
  {
    code: "BVorOvrOvd",
    descriptionShort: "Overige vorderingen daeb",
    descriptionLong: "Overige vorderingen daeb",
  },
  {
    code: "BVorOvrOvn",
    descriptionShort: "Overige vorderingen niet-daeb",
    descriptionLong: "Overige vorderingen niet-daeb",
  },
  {
    code: "BVorOva",
    descriptionShort: "Overlopende activa",
    descriptionLong: "Overlopende activa",
  },
  {
    code: "BVorOvaVof",
    descriptionShort: "Vooruitbetaalde facturen",
    descriptionLong: "Vooruitbetaalde facturen overlopende activa",
  },
  {
    code: "BVorOvaVbs",
    descriptionShort: "Vooruitverzonden op bestellingen",
    descriptionLong: "Vooruitverzonden op bestellingen overlopende activa",
  },
  {
    code: "BVorOvaNtf",
    descriptionShort: "Nog te factureren of nog te verzenden facturen",
    descriptionLong:
      "Nog te factureren of nog te verzenden facturen overlopende activa",
  },
  {
    code: "BVorOvaNoo",
    descriptionShort: "Nog te ontvangen omzetbonificaties",
    descriptionLong: "Nog te ontvangen omzetbonificaties overlopende activa",
  },
  {
    code: "BVorOvaNtp",
    descriptionShort: "Nog te ontvangen provisies",
    descriptionLong: "Nog te ontvangen provisies overlopende activa",
  },
  {
    code: "BVorOvaNth",
    descriptionShort: "Nog te ontvangen huren",
    descriptionLong: "Nog te ontvangen huren overlopende activa",
  },
  {
    code: "BVorOvaNov",
    descriptionShort: "Nog te ontvangen vergoedingen",
    descriptionLong: "Nog te ontvangen vergoedingen overlopende activa",
  },
  {
    code: "BVorOvaNob",
    descriptionShort: "Nog te ontvangen bijdragen",
    descriptionLong: "Nog te ontvangen bijdragen overlopende activa",
  },
  {
    code: "BVorOvaVop",
    descriptionShort: "Vooruitbetaalde personeelskosten",
    descriptionLong: "Vooruitbetaalde personeelskosten overlopende activa",
  },
  {
    code: "BVorOvaVoh",
    descriptionShort: "Vooruitbetaalde huisvestingskosten",
    descriptionLong: "Vooruitbetaalde huisvestingskosten overlopende activa",
  },
  {
    code: "BVorOvaVem",
    descriptionShort: "Vooruitbetaalde exploitatie- en machinekosten",
    descriptionLong:
      "Vooruitbetaalde exploitatie- en machinekosten overlopende activa",
  },
  {
    code: "BVorOvaVov",
    descriptionShort: "Vooruitbetaalde verkoopkosten",
    descriptionLong: "Vooruitbetaalde verkoopkosten overlopende activa",
  },
  {
    code: "BVorOvaVak",
    descriptionShort: "Vooruitbetaalde autokosten",
    descriptionLong: "Vooruitbetaalde autokosten overlopende activa",
  },
  {
    code: "BVorOvaVtr",
    descriptionShort: "Vooruitbetaalde transportkosten",
    descriptionLong: "Vooruitbetaalde transportkosten overlopende activa",
  },
  {
    code: "BVorOvaVok",
    descriptionShort: "Vooruitbetaalde kantoorkosten",
    descriptionLong: "Vooruitbetaalde kantoorkosten overlopende activa",
  },
  {
    code: "BVorOvaVoo",
    descriptionShort: "Vooruitbetaalde organisatiekosten",
    descriptionLong: "Vooruitbetaalde organisatiekosten overlopende activa",
  },
  {
    code: "BVorOvaVas",
    descriptionShort: "Vooruitbetaalde assurantiekosten",
    descriptionLong: "Vooruitbetaalde assurantiekosten overlopende activa",
  },
  {
    code: "BVorOvaVae",
    descriptionShort: "Vooruitbetaalde accountants- en advieskosten",
    descriptionLong:
      "Vooruitbetaalde accountants- en advieskosten overlopende activa",
  },
  {
    code: "BVorOvaVoa",
    descriptionShort: "Vooruitbetaalde administratiekosten",
    descriptionLong: "Vooruitbetaalde administratiekosten overlopende activa",
  },
  {
    code: "BVorOvaVkf",
    descriptionShort: "Vooruitbetaalde kosten fondsenwerving",
    descriptionLong: "Vooruitbetaalde kosten fondsenwerving overlopende activa",
  },
  {
    code: "BVorOvaVan",
    descriptionShort: "Vooruitbetaalde andere kosten",
    descriptionLong: "Vooruitbetaalde andere kosten overlopende activa",
  },
  {
    code: "BVorOvaTor",
    descriptionShort: "Te ontvangen rente",
    descriptionLong: "Te ontvangen rente overlopende activa",
  },
  {
    code: "BVorOvaVbr",
    descriptionShort: "Vooruitbetaalde rente",
    descriptionLong: "Vooruitbetaalde rente overlopende activa",
  },
  {
    code: "BVorOvaOoa",
    descriptionShort: "Overige overlopende activa ",
    descriptionLong: "Overige overlopende activa overlopende activa",
  },
  {
    code: "BVorOvaErf",
    descriptionShort: "Vooruitbetaalde erfpacht",
    descriptionLong: "Vooruitbetaalde erfpacht",
  },
  {
    code: "BVorOvaNos",
    descriptionShort: "Nog te ontvangen servicekosten",
    descriptionLong: "Nog te ontvangen servicekosten",
  },
  {
    code: "BVorOvaNtr",
    descriptionShort: "Nog toe rekenen rente swaps",
    descriptionLong: "Nog toe rekenen rente swaps",
  },
  {
    code: "BVorOvaPen",
    descriptionShort: "Pensioenvordering onder overlopende activa",
    descriptionLong: "Pensioenvordering onder overlopende activa",
  },
  {
    code: "BVorTus",
    descriptionShort: "Tussenrekeningen",
    descriptionLong: "Tussenrekeningen",
  },
  {
    code: "BVorTusTbt",
    descriptionShort: "Tussenrekeningen betalingen",
    descriptionLong: "Tussenrekeningen betalingen",
  },
  {
    code: "BVorTusTbtTca",
    descriptionShort: "Tussenrekening contante aanbetalingen",
    descriptionLong:
      "Tussenrekening contante aanbetalingen tussenrekeningen betalingen",
  },
  {
    code: "BVorTusTbtTcb",
    descriptionShort: "Tussenrekening creditcardbetalingen",
    descriptionLong:
      "Tussenrekening creditcardbetalingen tussenrekeningen betalingen",
  },
  {
    code: "BVorTusTsa",
    descriptionShort: "Tussenrekeningen salarissen",
    descriptionLong: "Tussenrekeningen salarissen",
  },
  {
    code: "BVorTusTsaTbn",
    descriptionShort: "Tussenrekening brutoloon",
    descriptionLong: "Tussenrekening brutoloon tussenrekeningen salarissen",
  },
  {
    code: "BVorTusTsaTgb",
    descriptionShort: "Tussenrekening brutoinhouding",
    descriptionLong:
      "Tussenrekening brutoinhouding tussenrekeningen salarissen",
  },
  {
    code: "BVorTusTsaTnl",
    descriptionShort: "Tussenrekening nettoloon",
    descriptionLong: "Tussenrekening nettoloon tussenrekeningen salarissen",
  },
  {
    code: "BVorTusTsaTni",
    descriptionShort: "Tussenrekening nettoinhoudingen",
    descriptionLong:
      "Tussenrekening nettoinhoudingen tussenrekeningen salarissen",
  },
  {
    code: "BVorTusTin",
    descriptionShort: "Tussenrekeningen inkopen",
    descriptionLong: "Tussenrekeningen inkopen",
  },
  {
    code: "BVorTusTinTog",
    descriptionShort: "Tussenrekening nog te ontvangen goederen",
    descriptionLong:
      "Tussenrekening nog te ontvangen goederen tussenrekeningen inkopen",
  },
  {
    code: "BVorTusTinTof",
    descriptionShort: "Tussenrekening nog te ontvangen facturen",
    descriptionLong:
      "Tussenrekening nog te ontvangen facturen tussenrekeningen inkopen",
  },
  {
    code: "BVorTusTinTiv",
    descriptionShort: "Tussenrekening inkoopverschillen",
    descriptionLong:
      "Tussenrekening inkoopverschillen tussenrekeningen inkopen",
  },
  {
    code: "BVorTusTpj",
    descriptionShort: "Tussenrekeningen projecten",
    descriptionLong: "Tussenrekeningen projecten",
  },
  {
    code: "BVorTusTpjTpk",
    descriptionShort: "Tussenrekening projectkosten",
    descriptionLong: "Tussenrekening projectkosten tussenrekeningen projecten",
  },
  {
    code: "BVorTusTpjTpo",
    descriptionShort: "Tussenrekening projectopbrengsten",
    descriptionLong:
      "Tussenrekening projectopbrengsten tussenrekeningen projecten",
  },
  {
    code: "BVorTusTpjTpv",
    descriptionShort: "Tussenrekening projectverschillen",
    descriptionLong:
      "Tussenrekening projectverschillen tussenrekeningen projecten",
  },
  {
    code: "BVorTusTpr",
    descriptionShort: "Tussenrekeningen productie",
    descriptionLong: "Tussenrekeningen productie",
  },
  {
    code: "BVorTusTprTmv",
    descriptionShort: "Tussenrekening materiaalverbruik",
    descriptionLong:
      "Tussenrekening materiaalverbruik tussenrekeningen productie",
  },
  {
    code: "BVorTusTprTmu",
    descriptionShort: "Tussenrekening manuren",
    descriptionLong: "Tussenrekening manuren tussenrekeningen productie",
  },
  {
    code: "BVorTusTprTau",
    descriptionShort: "Tussenrekening machineuren",
    descriptionLong: "Tussenrekening machineuren tussenrekeningen productie",
  },
  {
    code: "BVorTusTprTbu",
    descriptionShort: "Tussenrekening te dekken budget",
    descriptionLong:
      "Tussenrekening te dekken budget tussenrekeningen productie",
  },
  {
    code: "BVorTusTprTbg",
    descriptionShort: "Tussenrekening budget",
    descriptionLong: "Tussenrekening budget tussenrekeningen productie",
  },
  {
    code: "BVorTusTdv",
    descriptionShort: "Tussenrekeningen dienstverlening",
    descriptionLong: "Tussenrekeningen dienstverlening",
  },
  {
    code: "BVorTusTdvTcp",
    descriptionShort: "Tussenrekening capaciteit",
    descriptionLong:
      "Tussenrekening capaciteit tussenrekeningen dienstverlening",
  },
  {
    code: "BVorTusTdvTma",
    descriptionShort: "Tussenrekening materialen",
    descriptionLong:
      "Tussenrekening materialen tussenrekeningen dienstverlening",
  },
  {
    code: "BVorTusTdvTuu",
    descriptionShort: "Tussenrekening uren",
    descriptionLong: "Tussenrekening uren tussenrekeningen dienstverlening",
  },
  {
    code: "BVorTusTdvInv",
    descriptionShort: "Inkomende verschotten",
    descriptionLong: "Inkomende verschotten tussenrekeningen dienstverlening",
  },
  {
    code: "BVorTusTdvVso",
    descriptionShort: "Voorschotten onbelast",
    descriptionLong: "Voorschotten onbelast tussenrekeningen dienstverlening",
  },
  {
    code: "BVorTusTdvVsb",
    descriptionShort: "Voorschotten belast",
    descriptionLong: "Voorschotten belast tussenrekeningen dienstverlening",
  },
  {
    code: "BVorTusTdvDvo",
    descriptionShort: "Doorberekende voorschotten onbelast",
    descriptionLong:
      "Doorberekende voorschotten onbelast tussenrekeningen dienstverlening",
  },
  {
    code: "BVorTusTdvDvb",
    descriptionShort: "Doorberekende voorschotten belast",
    descriptionLong:
      "Doorberekende voorschotten belast tussenrekeningen dienstverlening",
  },
  {
    code: "BVorTusTvr",
    descriptionShort: "Tussenrekening voorraden",
    descriptionLong: "Tussenrekening voorraden",
  },
  {
    code: "BVorTusTvrTvn",
    descriptionShort: "Tussenrekening voorraadverschillen",
    descriptionLong:
      "Tussenrekening voorraadverschillen tussenrekening voorraden",
  },
  {
    code: "BVorTusTvk",
    descriptionShort: "Tussenrekeningen verkopen",
    descriptionLong: "Tussenrekeningen verkopen",
  },
  {
    code: "BVorTusTvkTnf",
    descriptionShort: "Tussenrekening nog te factureren",
    descriptionLong:
      "Tussenrekening nog te factureren tussenrekeningen verkopen",
  },
  {
    code: "BVorTusTvkTng",
    descriptionShort: "Tussenrekening nog te verzenden goederen",
    descriptionLong:
      "Tussenrekening nog te verzenden goederen tussenrekeningen verkopen",
  },
  {
    code: "BVorTusTvkTve",
    descriptionShort: "Tussenrekening verkoopverschillen",
    descriptionLong:
      "Tussenrekening verkoopverschillen tussenrekeningen verkopen",
  },
  {
    code: "BVorTusTon",
    descriptionShort: "Tussenrekeningen ontvangsten",
    descriptionLong: "Tussenrekeningen ontvangsten",
  },
  {
    code: "BVorTusTonTco",
    descriptionShort: "Tussenrekening contante ontvangsten",
    descriptionLong:
      "Tussenrekening contante ontvangsten tussenrekeningen ontvangsten",
  },
  {
    code: "BVorTusTonTcv",
    descriptionShort: "Tussenrekening creditcardverkopen",
    descriptionLong:
      "Tussenrekening creditcardverkopen tussenrekeningen ontvangsten",
  },
  {
    code: "BVorTusTov",
    descriptionShort: "Tussenrekeningen overig",
    descriptionLong: "Tussenrekeningen overig",
  },
  {
    code: "BVorTusTovTbb",
    descriptionShort: "Tussenrekening beginbalans",
    descriptionLong: "Tussenrekening beginbalans tussenrekeningen overig",
  },
  {
    code: "BVorTusTovTvp",
    descriptionShort: "Tussenrekening vraagposten",
    descriptionLong: "Tussenrekening vraagposten tussenrekeningen overig",
  },
  {
    code: "BVorTusTovTov",
    descriptionShort: "Tussenrekening overige",
    descriptionLong: "Tussenrekening overige tussenrekeningen overig",
  },
  {
    code: "BVorTusLen",
    descriptionShort: "Tussenrekeningen leningen",
    descriptionLong: "Tussenrekeningen leningen",
  },
  {
    code: "BVorTusLenLog",
    descriptionShort: "Tussenrekening leningen OG",
    descriptionLong: "Tussenrekening leningen OG",
  },
  {
    code: "BVorTusLenLug",
    descriptionShort: "Tussenrekening leningen UG",
    descriptionLong: "Tussenrekening leningen UG",
  },
  {
    code: "BVorTusLenKog",
    descriptionShort: "Tussenrekening kasgeld OG",
    descriptionLong: "Tussenrekening kasgeld OG",
  },
  {
    code: "BVorTusLenKug",
    descriptionShort: "Tussenrekening kasgeld UG",
    descriptionLong: "Tussenrekening kasgeld UG",
  },
  {
    code: "BVorTusLenSde",
    descriptionShort: "Tussenrekening spaardeposito",
    descriptionLong: "Tussenrekening spaardeposito",
  },
  {
    code: "BVorTusLenDer",
    descriptionShort: "Tussenrekening derivaten",
    descriptionLong: "Tussenrekening derivaten",
  },
  {
    code: "BVorTusLenCfv",
    descriptionShort: "Tussenrekening leningen CFV",
    descriptionLong: "Tussenrekening leningen CFV",
  },
  {
    code: "BVorOvh",
    descriptionShort: "Overheid",
    descriptionLong: "Overheid",
  },
  {
    code: "BVorOvhVor",
    descriptionShort: "Overheid",
    descriptionLong: "Overheid",
  },
  {
    code: "BEff",
    descriptionShort: "Effecten (kortlopend)",
    descriptionLong: "Effecten (kortlopend)",
  },
  {
    code: "BEffAan",
    descriptionShort: "Aandelen",
    descriptionLong: "Aandelen",
  },
  {
    code: "BEffAanAbe",
    descriptionShort: "Aandelen beursgenoteerd",
    descriptionLong: "Aandelen beursgenoteerde effecten",
  },
  {
    code: "BEffAanAbeBeg",
    descriptionShort: "Beginbalans aandelen beursgenoteerd",
    descriptionLong: "Beginbalans aandelen beursgenoteerd",
  },
  {
    code: "BEffAanAbeAan",
    descriptionShort: "Aankoop",
    descriptionLong: "Aankoop beursgenoteerde effecten",
  },
  {
    code: "BEffAanAbeVrk",
    descriptionShort: "Verkoop",
    descriptionLong: "Verkoop beursgenoteerde effecten",
  },
  {
    code: "BEffAanAbeWvr",
    descriptionShort: "Waardeverminderingen",
    descriptionLong: "Waardeverminderingen beursgenoteerde effecten",
  },
  {
    code: "BEffAanAbeAsm",
    descriptionShort: "Afstempeling",
    descriptionLong: "Afstempeling beursgenoteerde effecten",
  },
  {
    code: "BEffAanAbeOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties beursgenoteerde effecten",
  },
  {
    code: "BEffAanAnb",
    descriptionShort: "Aandelen niet beursgenoteerd",
    descriptionLong: "Aandelen niet-beursgenoteerde effecten",
  },
  {
    code: "BEffAanAnbBeg",
    descriptionShort: "Beginbalans aandelen niet beursgenoteerd",
    descriptionLong: "Beginbalans aandelen niet beursgenoteerd",
  },
  {
    code: "BEffAanAnbAan",
    descriptionShort: "Aankoop",
    descriptionLong: "Aankoop niet-beursgenoteerde effecten",
  },
  {
    code: "BEffAanAnbVrk",
    descriptionShort: "Verkoop",
    descriptionLong: "Verkoop niet-beursgenoteerde effecten",
  },
  {
    code: "BEffAanAnbWvr",
    descriptionShort: "Waardeverminderingen",
    descriptionLong: "Waardeverminderingen niet-beursgenoteerde effecten",
  },
  {
    code: "BEffAanAnbAsm",
    descriptionShort: "Afstempeling",
    descriptionLong: "Afstempeling niet-beursgenoteerde effecten",
  },
  {
    code: "BEffAanAnbOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties niet-beursgenoteerde effecten",
  },
  {
    code: "BEffObl",
    descriptionShort: "Obligaties",
    descriptionLong: "Obligaties",
  },
  {
    code: "BEffOblObb",
    descriptionShort: "Obligaties beursgenoteerd",
    descriptionLong: "Obligaties beursgenoteerde effecten",
  },
  {
    code: "BEffOblObbBeg",
    descriptionShort: "Beginbalans obligaties beursgenoteerd",
    descriptionLong: "Beginbalans obligaties beursgenoteerd",
  },
  {
    code: "BEffOblObbAan",
    descriptionShort: "Aankoop",
    descriptionLong: "Aankoop beursgenoteerde effecten",
  },
  {
    code: "BEffOblObbVrk",
    descriptionShort: "Verkoop",
    descriptionLong: "Verkoop beursgenoteerde effecten",
  },
  {
    code: "BEffOblObbWvr",
    descriptionShort: "Waardeverminderingen obligaties beursgenoteerd",
    descriptionLong: "Waardeverminderingen obligaties beursgenoteerd",
  },
  {
    code: "BEffOblObbUil",
    descriptionShort: "Uitloting",
    descriptionLong: "Uitloting beursgenoteerde effecten",
  },
  {
    code: "BEffOblObbAsm",
    descriptionShort: "Afstempeling",
    descriptionLong: "Afstempeling beursgenoteerde effecten",
  },
  {
    code: "BEffOblObbOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties beursgenoteerde effecten",
  },
  {
    code: "BEffOblOnb",
    descriptionShort: "Obligaties niet beursgenoteerd",
    descriptionLong: "Obligaties niet-beursgenoteerde effecten",
  },
  {
    code: "BEffOblOnbBeg",
    descriptionShort: "Beginbalans obligaties niet beursgenoteerd",
    descriptionLong: "Beginbalans obligaties niet beursgenoteerd",
  },
  {
    code: "BEffOblOnbAan",
    descriptionShort: "Aankoop",
    descriptionLong: "Aankoop niet-beursgenoteerde effecten",
  },
  {
    code: "BEffOblOnbVrk",
    descriptionShort: "Verkoop",
    descriptionLong: "Verkoop niet-beursgenoteerde effecten",
  },
  {
    code: "BEffOblOnbWvr",
    descriptionShort: "Waardeverminderingen",
    descriptionLong: "Waardeverminderingen niet-beursgenoteerde effecten",
  },
  {
    code: "BEffOblOnbUil",
    descriptionShort: "Uitloting",
    descriptionLong: "Uitloting niet-beursgenoteerde effecten",
  },
  {
    code: "BEffOblOnbOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties niet-beursgenoteerde effecten",
  },
  {
    code: "BEffOve",
    descriptionShort: "Overige effecten",
    descriptionLong: "Overige effecten",
  },
  {
    code: "BEffOveOeb",
    descriptionShort: "Overige effecten beursgenoteerd",
    descriptionLong: "Overige effecten beursgenoteerde effecten",
  },
  {
    code: "BEffOveOebBeg",
    descriptionShort: "Beginbalans overige effecten beursgenoteerd",
    descriptionLong: "Beginbalans overige effecten beursgenoteerd",
  },
  {
    code: "BEffOveOebAan",
    descriptionShort: "Aankoop",
    descriptionLong: "Aankoop beursgenoteerde effecten",
  },
  {
    code: "BEffOveOebVrk",
    descriptionShort: "Verkoop",
    descriptionLong: "Verkoop beursgenoteerde effecten",
  },
  {
    code: "BEffOveOebWvr",
    descriptionShort: "Waardeverminderingen",
    descriptionLong: "Waardeverminderingen beursgenoteerde effecten",
  },
  {
    code: "BEffOveOebOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties beursgenoteerde effecten",
  },
  {
    code: "BEffOveOen",
    descriptionShort: "Overige effecten niet beursgenoteerd",
    descriptionLong: "Overige effecten niet-beursgenoteerde effecten",
  },
  {
    code: "BEffOveOenBeg",
    descriptionShort: "Beginbalans overige effecten niet beursgenoteerd",
    descriptionLong: "Beginbalans overige effecten niet beursgenoteerd",
  },
  {
    code: "BEffOveOenAan",
    descriptionShort: "Aankoop",
    descriptionLong: "Aankoop niet-beursgenoteerde effecten",
  },
  {
    code: "BEffOveOenVrk",
    descriptionShort: "Verkoop",
    descriptionLong: "Verkoop niet-beursgenoteerde effecten",
  },
  {
    code: "BEffOveOenWvr",
    descriptionShort: "Waardeverminderingen",
    descriptionLong: "Waardeverminderingen niet-beursgenoteerde effecten",
  },
  {
    code: "BEffOveOenOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties niet-beursgenoteerde effecten",
  },
  {
    code: "BEffOpt",
    descriptionShort: "Optierechten",
    descriptionLong: "Optierechten",
  },
  {
    code: "BEffOptOpb",
    descriptionShort: "Optierechten beursgenoteerd",
    descriptionLong: "Optierechten beursgenoteerde effecten",
  },
  {
    code: "BEffOptOpbAan",
    descriptionShort: "Aankoop",
    descriptionLong: "Aankoop beursgenoteerde effecten",
  },
  {
    code: "BEffOptOpbVrk",
    descriptionShort: "Verkoop",
    descriptionLong: "Verkoop beursgenoteerde effecten",
  },
  {
    code: "BEffOptOpbWvr",
    descriptionShort: "Waardeverminderingen",
    descriptionLong: "Waardeverminderingen beursgenoteerde effecten",
  },
  {
    code: "BEffOptOpbOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties beursgenoteerde effecten",
  },
  {
    code: "BEffOptOpn",
    descriptionShort: "Optierechten niet beursgenoteerd",
    descriptionLong: "Optierechten niet-beursgenoteerde effecten",
  },
  {
    code: "BEffOptOpnAan",
    descriptionShort: "Aankoop",
    descriptionLong: "Aankoop niet-beursgenoteerde effecten",
  },
  {
    code: "BEffOptOpnVrk",
    descriptionShort: "Verkoop",
    descriptionLong: "Verkoop niet-beursgenoteerde effecten",
  },
  {
    code: "BEffOptOpnWvr",
    descriptionShort: "Waardeverminderingen",
    descriptionLong: "Waardeverminderingen niet-beursgenoteerde effecten",
  },
  {
    code: "BEffOptOpnOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties niet-beursgenoteerde effecten",
  },
  {
    code: "BEffOpv",
    descriptionShort: "Optieverplichtingen",
    descriptionLong: "Optieverplichtingen",
  },
  {
    code: "BEffOpvOpb",
    descriptionShort: "Optieverplichtingen  beursgenoteerd",
    descriptionLong: "Optieverplichtingen beursgenoteerde effecten",
  },
  {
    code: "BEffOpvOpbAan",
    descriptionShort: "Aankoop",
    descriptionLong: "Aankoop beursgenoteerde optieverplichtingen",
  },
  {
    code: "BEffOpvOpbVrk",
    descriptionShort: "Verkoop",
    descriptionLong: "Verkoop beursgenoteerde optieverplichtingen",
  },
  {
    code: "BEffOpvOpbWvr",
    descriptionShort: "Waardeverminderingen",
    descriptionLong: "Waardeverminderingen beursgenoteerde optieverplichtingen",
  },
  {
    code: "BEffOpvOpbOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties beursgenoteerde optieverplichtingen",
  },
  {
    code: "BEffOpvOpn",
    descriptionShort: "Optieverplichtingen  niet beursgenoteerd",
    descriptionLong: "Optieverplichtingen niet-beursgenoteerde effecten",
  },
  {
    code: "BEffOpvOpnAan",
    descriptionShort: "Aankoop",
    descriptionLong: "Aankoop niet-beursgenoteerde optieverplichtingen",
  },
  {
    code: "BEffOpvOpnVrk",
    descriptionShort: "Verkoop",
    descriptionLong: "Verkoop niet-beursgenoteerde optieverplichtingen",
  },
  {
    code: "BEffOpvOpnWvr",
    descriptionShort: "Waardeverminderingen",
    descriptionLong:
      "Waardeverminderingen niet-beursgenoteerde optieverplichtingen",
  },
  {
    code: "BEffOpvOpnOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties niet-beursgenoteerde optieverplichtingen",
  },
  {
    code: "BEffDer",
    descriptionShort: "Derivaten",
    descriptionLong: "Derivaten",
  },
  {
    code: "BEffDerDer",
    descriptionShort: "Derivaten",
    descriptionLong: "Derivaten",
  },
  {
    code: "BEffDerDerPmd",
    descriptionShort: "Positieve marktwaarde derivaten",
    descriptionLong: "Positieve marktwaarde derivaten",
  },
  {
    code: "BEffDerDerPed",
    descriptionShort: "Positieve marktwaarde embedded derivaten",
    descriptionLong: "Positieve marktwaarde embedded derivaten",
  },
  {
    code: "BLim",
    descriptionShort: "Liquide middelen",
    descriptionLong: "Liquide middelen",
  },
  {
    code: "BLimKas",
    descriptionShort: "Kasmiddelen",
    descriptionLong: "Kasmiddelen",
  },
  {
    code: "BLimKasKas",
    descriptionShort: "Kas",
    descriptionLong: "Kas kasmiddelen",
  },
  {
    code: "BLimKasKlk",
    descriptionShort: "Kleine kas",
    descriptionLong: "Kleine kas kasmiddelen",
  },
  {
    code: "BLimBan",
    descriptionShort: "Tegoeden op bankgirorekeningen",
    descriptionLong: "Tegoeden op bankgirorekeningen",
  },
  {
    code: "BLimBanRba",
    descriptionShort: "Rekening-courant bank",
    descriptionLong: "Rekening-courant bank tegoeden op bankgirorekeningen",
  },
  {
    code: "BLimBanRbaBg1",
    descriptionShort: "Rekening-courant bank groep 1",
    descriptionLong: "Rekening-courant bank groep 1",
  },
  {
    code: "BLimBanRbaBg2",
    descriptionShort: "Rekening-courant bank groep 2",
    descriptionLong: "Rekening-courant bank groep 2",
  },
  {
    code: "BLimBanRbaBg3",
    descriptionShort: "Rekening-courant bank groep 3",
    descriptionLong: "Rekening-courant bank groep 3",
  },
  {
    code: "BLimBanRbaBg4",
    descriptionShort: "Rekening-courant bank groep 4",
    descriptionLong: "Rekening-courant bank groep 4",
  },
  {
    code: "BLimBanRbaBg5",
    descriptionShort: "Rekening-courant bank groep 5",
    descriptionLong: "Rekening-courant bank groep 5",
  },
  {
    code: "BLimBanRbaBg6",
    descriptionShort: "Rekening-courant bank groep 6",
    descriptionLong: "Rekening-courant bank groep 6",
  },
  {
    code: "BLimBanRbaBg7",
    descriptionShort: "Rekening-courant bank groep 7",
    descriptionLong: "Rekening-courant bank groep 7",
  },
  {
    code: "BLimBanRbaBg8",
    descriptionShort: "Rekening-courant bank groep 8",
    descriptionLong: "Rekening-courant bank groep 8",
  },
  {
    code: "BLimBanRbaBg9",
    descriptionShort: "Rekening-courant bank groep 9",
    descriptionLong: "Rekening-courant bank groep 9",
  },
  {
    code: "BLimBanRbaBg10",
    descriptionShort: "Rekening-courant bank groep 10",
    descriptionLong: "Rekening-courant bank groep 10",
  },
  {
    code: "BLimBanRbb",
    descriptionShort: "Rekening-courant bank- Naam A",
    descriptionLong:
      "Rekening-courant bank - Naam A - tegoeden op bankgirorekeningen",
  },
  {
    code: "BLimBanRbc",
    descriptionShort: "Rekening-courant bank- Naam B",
    descriptionLong:
      "Rekening-courant bank - Naam B - tegoeden op bankgirorekeningen",
  },
  {
    code: "BLimBanRbd",
    descriptionShort: "Rekening-courant bank- Naam C",
    descriptionLong:
      "Rekening-courant bank - Naam C - tegoeden op bankgirorekeningen",
  },
  {
    code: "BLimBanRbe",
    descriptionShort: "Rekening-courant bank- Naam D",
    descriptionLong:
      "Rekening-courant bank - Naam D - tegoeden op bankgirorekeningen",
  },
  {
    code: "BLimBanRbf",
    descriptionShort: "Rekening-courant bank- Naam E",
    descriptionLong:
      "Rekening-courant bank - Naam E - tegoeden op bankgirorekeningen",
  },
  {
    code: "BLimBanDrk",
    descriptionShort: "Depotrekening",
    descriptionLong: "Depotrekening tegoeden op bankgirorekeningen",
  },
  {
    code: "BLimBanDep",
    descriptionShort: "Depositorekening",
    descriptionLong: "Depositorekening tegoeden op bankgirorekeningen",
  },
  {
    code: "BLimBanBel",
    descriptionShort: "Beleggingsrekening",
    descriptionLong: "Beleggingsrekening tegoeden op bankgirorekeningen",
  },
  {
    code: "BLimBanGrb",
    descriptionShort: "G-rekening",
    descriptionLong: "G-rekening tegoeden op bankgirorekeningen",
  },
  {
    code: "BLimBanInb",
    descriptionShort: "Internetrekening",
    descriptionLong: "Internetrekening tegoeden op bankgirorekeningen",
  },
  {
    code: "BLimBanSpa",
    descriptionShort: "(Bedrijfs)spaarrekening",
    descriptionLong: "(Bedrijfs)spaarrekening",
  },
  {
    code: "BLimKru",
    descriptionShort: "Kruisposten",
    descriptionLong: "Kruisposten",
  },
  {
    code: "BLimKruSto",
    descriptionShort: "Stortingen onderweg",
    descriptionLong: "Stortingen onderweg kruisposten",
  },
  {
    code: "BLimKruKlu",
    descriptionShort: "Tussenrekening kluis",
    descriptionLong: "Tussenrekening kluis",
  },
  {
    code: "BLimKruPib",
    descriptionShort: "PIN betalingen",
    descriptionLong: "PIN betalingen kruisposten",
  },
  {
    code: "BLimKruCra",
    descriptionShort: "Creditcard afrekening",
    descriptionLong: "Creditcard afrekening kruisposten",
  },
  {
    code: "BLimKruWec",
    descriptionShort: "Wissels en cheques",
    descriptionLong: "Wissels en cheques kruisposten",
  },
  {
    code: "BEiv",
    descriptionShort: "Groepsvermogen - Eigen vermogen - Kapitaal",
    descriptionLong: "Groepsvermogen - Eigen vermogen - Kapitaal",
  },
  {
    code: "BEivGok",
    descriptionShort: "Aandelenkapitaal",
    descriptionLong: "Aandelenkapitaal",
  },
  {
    code: "BEivGokGea",
    descriptionShort: "Normale aandelen",
    descriptionLong: "Normale aandelen aandelenkapitaal",
  },
  {
    code: "BEivGokGeaBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BEivGokGeaUit",
    descriptionShort: "Uitgifte van aandelen",
    descriptionLong: "Uitgifte van aandelen ",
  },
  {
    code: "BEivGokGeaVrk",
    descriptionShort: "Verkoop van eigen aandelen",
    descriptionLong: "Verkoop van eigen aandelen ",
  },
  {
    code: "BEivGokGeaInk",
    descriptionShort: "Inkoop van eigen aandelen",
    descriptionLong: "Inkoop van eigen aandelen ",
  },
  {
    code: "BEivGokGeaInt",
    descriptionShort: "Intrekking van aandelen",
    descriptionLong: "Intrekking van aandelen ",
  },
  {
    code: "BEivGokGeaDiv",
    descriptionShort: "Dividenduitkeringen",
    descriptionLong: "Dividenduitkeringen ",
  },
  {
    code: "BEivGokGeaOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen ",
  },
  {
    code: "BEivGokGeaOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties ",
  },
  {
    code: "BEivGokPra",
    descriptionShort: "Preferente aandelen",
    descriptionLong: "Preferente aandelen ",
  },
  {
    code: "BEivGokPraBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BEivGokPraUit",
    descriptionShort: "Uitgifte van aandelen",
    descriptionLong: "Uitgifte van aandelen ",
  },
  {
    code: "BEivGokPraVrk",
    descriptionShort: "Verkoop van eigen aandelen",
    descriptionLong: "Verkoop van eigen aandelen ",
  },
  {
    code: "BEivGokPraInk",
    descriptionShort: "Inkoop van eigen aandelen",
    descriptionLong: "Inkoop van eigen aandelen ",
  },
  {
    code: "BEivGokPraInt",
    descriptionShort: "Intrekking van aandelen",
    descriptionLong: "Intrekking van aandelen ",
  },
  {
    code: "BEivGokPraDiv",
    descriptionShort: "Dividenduitkeringen",
    descriptionLong: "Dividenduitkeringen ",
  },
  {
    code: "BEivGokPraOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen ",
  },
  {
    code: "BEivGokPraOvm",
    descriptionShort: "Overige mutaties preferente aandelen",
    descriptionLong: "Overige mutaties preferente aandelen",
  },
  {
    code: "BEivGokPri",
    descriptionShort: "Prioriteitsaandelen",
    descriptionLong: "Prioriteitsaandelen ",
  },
  {
    code: "BEivGokPriBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BEivGokPriUit",
    descriptionShort: "Uitgifte van aandelen",
    descriptionLong: "Uitgifte van aandelen ",
  },
  {
    code: "BEivGokPriVrk",
    descriptionShort: "Verkoop van eigen aandelen",
    descriptionLong: "Verkoop van eigen aandelen ",
  },
  {
    code: "BEivGokPriInk",
    descriptionShort: "Inkoop van eigen aandelen",
    descriptionLong: "Inkoop van eigen aandelen ",
  },
  {
    code: "BEivGokPriInt",
    descriptionShort: "Intrekking van aandelen",
    descriptionLong: "Intrekking van aandelen ",
  },
  {
    code: "BEivGokPriDiv",
    descriptionShort: "Dividenduitkeringen",
    descriptionLong: "Dividenduitkeringen ",
  },
  {
    code: "BEivGokPriOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen ",
  },
  {
    code: "BEivGokPriOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties ",
  },
  {
    code: "BEivGokCva",
    descriptionShort: "Certificaten van aandelen",
    descriptionLong: "Certificaten van aandelen ",
  },
  {
    code: "BEivGokCvaBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BEivGokCvaUit",
    descriptionShort: "Uitgifte van aandelen",
    descriptionLong: "Uitgifte van aandelen ",
  },
  {
    code: "BEivGokCvaVrk",
    descriptionShort: "Verkoop van eigen aandelen",
    descriptionLong: "Verkoop van eigen aandelen ",
  },
  {
    code: "BEivGokCvaInk",
    descriptionShort: "Inkoop van eigen aandelen",
    descriptionLong: "Inkoop van eigen aandelen ",
  },
  {
    code: "BEivGokCvaInt",
    descriptionShort: "Intrekking van aandelen",
    descriptionLong: "Intrekking van aandelen ",
  },
  {
    code: "BEivGokCvaDiv",
    descriptionShort: "Dividenduitkeringen",
    descriptionLong: "Dividenduitkeringen ",
  },
  {
    code: "BEivGokCvaOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen ",
  },
  {
    code: "BEivGokCvaOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties ",
  },
  {
    code: "BEivGokZea",
    descriptionShort: "Stemrechtloze aandelen",
    descriptionLong: "Stemrechtloze aandelen ",
  },
  {
    code: "BEivGokZeaBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BEivGokZeaUit",
    descriptionShort: "Uitgifte van aandelen",
    descriptionLong: "Uitgifte van aandelen ",
  },
  {
    code: "BEivGokZeaVrk",
    descriptionShort: "Verkoop van eigen aandelen",
    descriptionLong: "Verkoop van eigen aandelen ",
  },
  {
    code: "BEivGokZeaInk",
    descriptionShort: "Inkoop van eigen aandelen",
    descriptionLong: "Inkoop van eigen aandelen ",
  },
  {
    code: "BEivGokZeaInt",
    descriptionShort: "Intrekking van aandelen",
    descriptionLong: "Intrekking van aandelen ",
  },
  {
    code: "BEivGokZeaDiv",
    descriptionShort: "Dividenduitkeringen",
    descriptionLong: "Dividenduitkeringen ",
  },
  {
    code: "BEivGokZeaOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen ",
  },
  {
    code: "BEivGokZeaOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties ",
  },
  {
    code: "BEivGokWia",
    descriptionShort: "Winstrechtloze aandelen",
    descriptionLong: "Winstrechtloze aandelen ",
  },
  {
    code: "BEivGokWiaBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BEivGokWiaUit",
    descriptionShort: "Uitgifte van aandelen",
    descriptionLong: "Uitgifte van aandelen ",
  },
  {
    code: "BEivGokWiaVrk",
    descriptionShort: "Verkoop van eigen aandelen",
    descriptionLong: "Verkoop van eigen aandelen ",
  },
  {
    code: "BEivGokWiaInk",
    descriptionShort: "Inkoop van eigen aandelen",
    descriptionLong: "Inkoop van eigen aandelen ",
  },
  {
    code: "BEivGokWiaInt",
    descriptionShort: "Intrekking van aandelen",
    descriptionLong: "Intrekking van aandelen ",
  },
  {
    code: "BEivGokWiaDiv",
    descriptionShort: "Dividenduitkeringen",
    descriptionLong: "Dividenduitkeringen ",
  },
  {
    code: "BEivGokWiaOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen ",
  },
  {
    code: "BEivGokWiaOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties ",
  },
  {
    code: "BEivGokEia",
    descriptionShort: "Eigen aandelen",
    descriptionLong: "Eigen aandelen ",
  },
  {
    code: "BEivGokEiaBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BEivGokEiaUit",
    descriptionShort: "Uitgifte van aandelen",
    descriptionLong: "Uitgifte van aandelen ",
  },
  {
    code: "BEivGokEiaVrk",
    descriptionShort: "Verkoop van eigen aandelen",
    descriptionLong: "Verkoop van eigen aandelen ",
  },
  {
    code: "BEivGokEiaInk",
    descriptionShort: "Inkoop van eigen aandelen",
    descriptionLong: "Inkoop van eigen aandelen ",
  },
  {
    code: "BEivGokEiaInt",
    descriptionShort: "Intrekking van aandelen",
    descriptionLong: "Intrekking van aandelen ",
  },
  {
    code: "BEivGokEiaDiv",
    descriptionShort: "Dividenduitkeringen",
    descriptionLong: "Dividenduitkeringen ",
  },
  {
    code: "BEivGokEiaOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen ",
  },
  {
    code: "BEivGokEiaOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties ",
  },
  {
    code: "BEivSev",
    descriptionShort: "Kapitaal stichting, coöperatie en vereniging",
    descriptionLong: "Kapitaal stichting, coöperatie en vereniging",
  },
  {
    code: "BEivSevSti",
    descriptionShort: "Stichtingskapitaal",
    descriptionLong: "Stichtingskapitaal eigen vermogen",
  },
  {
    code: "BEivSevStiBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) eigen vermogen",
  },
  {
    code: "BEivSevStiKap",
    descriptionShort: "Kapitaalmutaties",
    descriptionLong: "Kapitaalmutaties eigen vermogen",
  },
  {
    code: "BEivSevStiKac",
    descriptionShort: "Kapitaalcorrecties",
    descriptionLong: "Kapitaalcorrecties eigen vermogen",
  },
  {
    code: "BEivSevStiOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties eigen vermogen",
  },
  {
    code: "BEivSevVnk",
    descriptionShort: "Verenigingskapitaal",
    descriptionLong:
      "Verenigingskapitaal eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivSevVnkBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivSevVnkKap",
    descriptionShort: "Kapitaalmutaties",
    descriptionLong:
      "Kapitaalmutaties eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivSevVnkKac",
    descriptionShort: "Kapitaalcorrecties",
    descriptionLong:
      "Kapitaalcorrecties eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivSevVnkOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivSevCoo",
    descriptionShort: "Kapitaal participatie coöperatie",
    descriptionLong: "Kapitaal participatie coöperatie eigen vermogen",
  },
  {
    code: "BEivSevCooBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) eigen vermogen participatie",
  },
  {
    code: "BEivSevCooKap",
    descriptionShort: "Kapitaalmutaties",
    descriptionLong: "Kapitaalmutaties eigen vermogen participatie",
  },
  {
    code: "BEivSevCooKac",
    descriptionShort: "Kapitaalcorrecties",
    descriptionLong: "Kapitaalcorrecties eigen vermogen participatie",
  },
  {
    code: "BEivSevCooOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties eigen vermogen participatie",
  },
  {
    code: "BEivCok",
    descriptionShort: "Commanditair kapitaal",
    descriptionLong: "Commanditair kapitaal",
  },
  {
    code: "BEivCokCok",
    descriptionShort: "Commanditair kapitaal",
    descriptionLong: "Commanditair kapitaal eigen vermogen",
  },
  {
    code: "BEivCokCokBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) eigen vermogen",
  },
  {
    code: "BEivCokCokKap",
    descriptionShort: "Kapitaalmutaties",
    descriptionLong: "Kapitaalmutaties eigen vermogen",
  },
  {
    code: "BEivCokCokKac",
    descriptionShort: "Kapitaalcorrecties",
    descriptionLong: "Kapitaalcorrecties eigen vermogen",
  },
  {
    code: "BEivCokCokOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties eigen vermogen",
  },
  { code: "BEivAgi", descriptionShort: "Agio", descriptionLong: "Agio" },
  {
    code: "BEivAgiAgi",
    descriptionShort: "Agioreserve",
    descriptionLong: "Agioreserve agio",
  },
  {
    code: "BEivAgiAgiBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BEivAgiAgiSta",
    descriptionShort: "Stortingen door aandeelhouders",
    descriptionLong: "Stortingen door aandeelhouders ",
  },
  {
    code: "BEivAgiAgiAvv",
    descriptionShort: "Aanzuivering van verliezen",
    descriptionLong: "Aanzuivering van verliezen ",
  },
  {
    code: "BEivAgiAgiVve",
    descriptionShort: "Verkoop van eigen aandelen",
    descriptionLong: "Verkoop van eigen aandelen ",
  },
  {
    code: "BEivAgiAgiIve",
    descriptionShort: "Inkoop van eigen aandelen",
    descriptionLong: "Inkoop van eigen aandelen ",
  },
  {
    code: "BEivAgiAgiIva",
    descriptionShort: "Intrekking van aandelen",
    descriptionLong: "Intrekking van aandelen ",
  },
  {
    code: "BEivAgiAgiOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen ",
  },
  {
    code: "BEivAgiAgiRfh",
    descriptionShort: "Rechtstreekse mutatie als gevolg van foutherstel",
    descriptionLong: "Rechtstreekse mutatie als gevolg van foutherstel ",
  },
  {
    code: "BEivAgiAgiRov",
    descriptionShort: "Rechtstreekse mutatie als gevolg van overnames",
    descriptionLong: "Rechtstreekse mutatie als gevolg van overnames ",
  },
  {
    code: "BEivAgiAgiRaf",
    descriptionShort: "Rechtstreekse mutatie als gevolg van afstotingen",
    descriptionLong: "Rechtstreekse mutatie als gevolg van afstotingen ",
  },
  {
    code: "BEivAgiAgiOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties ",
  },
  {
    code: "BEivHer",
    descriptionShort: "Herwaarderingsreserves",
    descriptionLong: "Herwaarderingsreserves",
  },
  {
    code: "BEivHerHew",
    descriptionShort: "Herwaarderingsreserve",
    descriptionLong: "Herwaardering herwaarderingsreserves",
  },
  {
    code: "BEivHerHewBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BEivHerHewOhe",
    descriptionShort: "Herwaarderingsreserve ongegerealiseerde herwaardering",
    descriptionLong: "Herwaarderingsreserve ongegerealiseerde herwaardering",
  },
  {
    code: "BEivHerHewSte",
    descriptionShort: "Stelselwijziging",
    descriptionLong: "Stelselwijziging (correctie beginbalans)",
  },
  {
    code: "BEivHerHewBvs",
    descriptionShort: "Belastingeffect van stelselwijzigingen",
    descriptionLong:
      "Belastingeffect van stelselwijzigingen (correctie beginbalans)",
  },
  {
    code: "BEivHerHewGhw",
    descriptionShort:
      "Gerealiseerde herwaarderingen via winst- en verliesrekening",
    descriptionLong:
      "Gerealiseerde herwaarderingen via winst- en verliesrekening ",
  },
  {
    code: "BEivHerHewGhr",
    descriptionShort: "Gerealiseerde herwaarderingen via overige reserves",
    descriptionLong: "Gerealiseerde herwaarderingen via overige reserves ",
  },
  {
    code: "BEivHerHewGha",
    descriptionShort:
      "Gerealiseerde herwaarderingen via afgedekte activa of passiva",
    descriptionLong:
      "Gerealiseerde herwaarderingen via afgedekte activa of passiva ",
  },
  {
    code: "BEivHerHewBrh",
    descriptionShort: "Belastingeffecten op gerealiseerde herwaarderingen",
    descriptionLong: "Belastingeffecten op gerealiseerde herwaarderingen ",
  },
  {
    code: "BEivHerHewGvw",
    descriptionShort: "Gevormde herwaarderingen via winst- en verliesrekening",
    descriptionLong: "Gevormde herwaarderingen via winst- en verliesrekening ",
  },
  {
    code: "BEivHerHewGvr",
    descriptionShort: "Gevormde herwaarderingen via overige reserves",
    descriptionLong: "Gevormde herwaarderingen via overige reserves ",
  },
  {
    code: "BEivHerHewGva",
    descriptionShort: "Gevormde herwaarderingen via afgedekt activa of passiva",
    descriptionLong: "Gevormde herwaarderingen via afgedekt activa of passiva ",
  },
  {
    code: "BEivHerHewBvh",
    descriptionShort: "Belastingeffecten op gevormde herwaarderingen",
    descriptionLong: "Belastingeffecten op gevormde herwaarderingen ",
  },
  {
    code: "BEivHerHewOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen ",
  },
  {
    code: "BEivHerHewHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong: "Herwaarderingen ",
  },
  {
    code: "BEivHerHewVrh",
    descriptionShort: "Vrijval herwaardering herwaarderingsreserve",
    descriptionLong: "Vrijval herwaardering herwaarderingsreserve",
  },
  {
    code: "BEivHerHewOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties ",
  },
  {
    code: "BEivWer",
    descriptionShort: "Wettelijke reserves",
    descriptionLong: "Wettelijke reserves",
  },
  {
    code: "BEivWerNba",
    descriptionShort:
      "Negatieve bijschrijvingsreserve als gevolg van de omrekening van het aandelenkapitaal van een naamloze vennootschap naar de euro",
    descriptionLong:
      "Negatieve bijschrijvingsreserve als gevolg van de omrekening van het aandelenkapitaal van een naamloze vennootschap naar de euro wettelijke reserves",
  },
  {
    code: "BEivWerNbaBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BEivWerNbaOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen ",
  },
  {
    code: "BEivWerNbaOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties ",
  },
  {
    code: "BEivWerRla",
    descriptionShort:
      "Niet-uitkeerbare reserve als gevolg van de omrekening van het aandelenkapitaal van een naamloze vennootschap naar de euro",
    descriptionLong:
      "Niet-uitkeerbare reserve als gevolg van de omrekening van het aandelenkapitaal van een naamloze vennootschap naar de euro",
  },
  {
    code: "BEivWerRlaBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BEivWerRlaAvh",
    descriptionShort: "Allocatie van het resultaat",
    descriptionLong: "Allocatie van het resultaat ",
  },
  {
    code: "BEivWerRlaOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties ",
  },
  {
    code: "BEivWerRvi",
    descriptionShort: "Wettelijke reserve voor inbreng in natura",
    descriptionLong: "Wettelijke reserve voor inbreng in natura ",
  },
  {
    code: "BEivWerRviBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BEivWerRviSta",
    descriptionShort: "Stortingen door aandeelhouders",
    descriptionLong: "Stortingen door aandeelhouders ",
  },
  {
    code: "BEivWerRviAvv",
    descriptionShort: "Aanzuivering van verliezen",
    descriptionLong: "Aanzuivering van verliezen ",
  },
  {
    code: "BEivWerRviOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen ",
  },
  {
    code: "BEivWerRviOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties ",
  },
  {
    code: "BEivWerRvl",
    descriptionShort:
      "Wettelijke reserve voor financiering van transacties in eigen aandelen",
    descriptionLong:
      "Wettelijke reserve voor financiering van transacties in eigen aandelen ",
  },
  {
    code: "BEivWerRvlBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BEivWerRvlUva",
    descriptionShort: "Uitgifte van aandelen",
    descriptionLong: "Uitgifte van aandelen ",
  },
  {
    code: "BEivWerRvlSta",
    descriptionShort: "Stortingen door aandeelhouders",
    descriptionLong: "Stortingen door aandeelhouders ",
  },
  {
    code: "BEivWerRvlAvv",
    descriptionShort: "Aanzuivering van verliezen",
    descriptionLong: "Aanzuivering van verliezen ",
  },
  {
    code: "BEivWerRvlVve",
    descriptionShort: "Verkoop van eigen aandelen",
    descriptionLong: "Verkoop van eigen aandelen ",
  },
  {
    code: "BEivWerRvlIve",
    descriptionShort: "Inkoop van eigen aandelen",
    descriptionLong: "Inkoop van eigen aandelen ",
  },
  {
    code: "BEivWerRvlIva",
    descriptionShort: "Intrekking van aandelen",
    descriptionLong: "Intrekking van aandelen ",
  },
  {
    code: "BEivWerRvlDiv",
    descriptionShort: "Dividenduitkeringen",
    descriptionLong: "Dividenduitkeringen ",
  },
  {
    code: "BEivWerRvlOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen ",
  },
  {
    code: "BEivWerRvlUia",
    descriptionShort: "Uitgeoefende aandelen(optie)regelingen",
    descriptionLong: "Uitgeoefende aandelen(optie)regelingen ",
  },
  {
    code: "BEivWerRvlOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties ",
  },
  {
    code: "BEivWerRvg",
    descriptionShort:
      "Wettelijke reserve voor geactiveerde kosten van oprichting en uitgifte van aandelen",
    descriptionLong:
      "Wettelijke reserve voor geactiveerde kosten van oprichting en uitgifte van aandelen ",
  },
  {
    code: "BEivWerRvgBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BEivWerRvgUva",
    descriptionShort: "Uitgifte van aandelen",
    descriptionLong: "Uitgifte van aandelen ",
  },
  {
    code: "BEivWerRvgSta",
    descriptionShort: "Stortingen door aandeelhouders",
    descriptionLong: "Stortingen door aandeelhouders ",
  },
  {
    code: "BEivWerRvgAvv",
    descriptionShort: "Aanzuivering van verliezen",
    descriptionLong: "Aanzuivering van verliezen ",
  },
  {
    code: "BEivWerRvgVve",
    descriptionShort: "Verkoop van eigen aandelen",
    descriptionLong: "Verkoop van eigen aandelen ",
  },
  {
    code: "BEivWerRvgIve",
    descriptionShort: "Inkoop van eigen aandelen",
    descriptionLong: "Inkoop van eigen aandelen ",
  },
  {
    code: "BEivWerRvgIva",
    descriptionShort: "Intrekking van aandelen",
    descriptionLong: "Intrekking van aandelen ",
  },
  {
    code: "BEivWerRvgOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen ",
  },
  {
    code: "BEivWerRvgHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong: "Herwaarderingen ",
  },
  {
    code: "BEivWerRvgRsw",
    descriptionShort: "Rechtstreekse mutatie als gevolg van stelselwijzigingen",
    descriptionLong: "Rechtstreekse mutatie als gevolg van stelselwijzigingen ",
  },
  {
    code: "BEivWerRvgRfh",
    descriptionShort: "Rechtstreekse mutatie als gevolg van foutherstel",
    descriptionLong: "Rechtstreekse mutatie als gevolg van foutherstel ",
  },
  {
    code: "BEivWerRvgRom",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van omrekeningsverschillen",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van omrekeningsverschillen ",
  },
  {
    code: "BEivWerRvgRbw",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van bijzondere waardeverminderingen",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van bijzondere waardeverminderingen ",
  },
  {
    code: "BEivWerRvgRtw",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van terugneming van bijzondere waardeverminderingen ",
  },
  {
    code: "BEivWerRvgRov",
    descriptionShort: "Rechtstreekse mutatie als gevolg van overnames",
    descriptionLong: "Rechtstreekse mutatie als gevolg van overnames ",
  },
  {
    code: "BEivWerRvgRaf",
    descriptionShort: "Rechtstreekse mutatie als gevolg van afstotingen",
    descriptionLong: "Rechtstreekse mutatie als gevolg van afstotingen ",
  },
  {
    code: "BEivWerRvgRfi",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van financiële instrumenten",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van financiële instrumenten ",
  },
  {
    code: "BEivWerRvgVar",
    descriptionShort: "Verleende aandelen(optie) regelingen",
    descriptionLong: "Verleende aandelen(optie) regelingen ",
  },
  {
    code: "BEivWerRvgUia",
    descriptionShort: "Uitgeoefende aandelen(optie)regelingen",
    descriptionLong: "Uitgeoefende aandelen(optie)regelingen ",
  },
  {
    code: "BEivWerRvgOvm",
    descriptionShort:
      "Overige mutaties reserve voor geactiveerde kosten van oprichting en uitgifte van aandelen",
    descriptionLong:
      "Overige mutaties reserve voor geactiveerde kosten van oprichting en uitgifte van aandelen",
  },
  {
    code: "BEivWerRgk",
    descriptionShort:
      "Wettelijke reserve voor geactiveerde kosten van ontwikkeling",
    descriptionLong:
      "Wettelijke reserve voor geactiveerde kosten van ontwikkeling ",
  },
  {
    code: "BEivWerRgkBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BEivWerRgkOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen ",
  },
  {
    code: "BEivWerRgkHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong: "Herwaarderingen ",
  },
  {
    code: "BEivWerRgkRsw",
    descriptionShort: "Rechtstreekse mutatie als gevolg van stelselwijzigingen",
    descriptionLong: "Rechtstreekse mutatie als gevolg van stelselwijzigingen ",
  },
  {
    code: "BEivWerRgkRfh",
    descriptionShort: "Rechtstreekse mutatie als gevolg van foutherstel",
    descriptionLong: "Rechtstreekse mutatie als gevolg van foutherstel ",
  },
  {
    code: "BEivWerRgkRom",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van omrekeningsverschillen",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van omrekeningsverschillen ",
  },
  {
    code: "BEivWerRgkRbw",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van bijzondere waardeverminderingen",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van bijzondere waardeverminderingen ",
  },
  {
    code: "BEivWerRgkRtw",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van terugneming van bijzondere waardeverminderingen ",
  },
  {
    code: "BEivWerRgkRov",
    descriptionShort: "Rechtstreekse mutatie als gevolg van overnames",
    descriptionLong: "Rechtstreekse mutatie als gevolg van overnames ",
  },
  {
    code: "BEivWerRgkRaf",
    descriptionShort: "Rechtstreekse mutatie als gevolg van afstotingen",
    descriptionLong: "Rechtstreekse mutatie als gevolg van afstotingen ",
  },
  {
    code: "BEivWerRgkRfi",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van financiële instrumenten",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van financiële instrumenten ",
  },
  {
    code: "BEivWerRgkOvm",
    descriptionShort:
      "Overige mutaties reserve voor geactiveerde kosten van onderzoek en ontwikkeling",
    descriptionLong:
      "Overige mutaties reserve voor geactiveerde kosten van onderzoek en ontwikkeling",
  },
  {
    code: "BEivWerRed",
    descriptionShort: "Wettelijke reserve deelnemingen",
    descriptionLong: "Wettelijke reserve deelnemingen",
  },
  {
    code: "BEivWerRedBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BEivWerRedDot",
    descriptionShort: "Dotatie reserve deelnemingen",
    descriptionLong: "Dotatie reserve deelnemingen",
  },
  {
    code: "BEivWerRedOnt",
    descriptionShort: "Onttrekking reserve deelnemingen",
    descriptionLong: "Onttrekking reserve deelnemingen",
  },
  {
    code: "BEivWerRedDiv",
    descriptionShort: "Dividenduitkeringen",
    descriptionLong: "Dividenduitkeringen ",
  },
  {
    code: "BEivWerRedOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen ",
  },
  {
    code: "BEivWerRedHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong: "Herwaarderingen ",
  },
  {
    code: "BEivWerRedRsw",
    descriptionShort: "Rechtstreekse mutatie als gevolg van stelselwijzigingen",
    descriptionLong: "Rechtstreekse mutatie als gevolg van stelselwijzigingen ",
  },
  {
    code: "BEivWerRedRfh",
    descriptionShort: "Rechtstreekse mutatie als gevolg van foutherstel",
    descriptionLong: "Rechtstreekse mutatie als gevolg van foutherstel ",
  },
  {
    code: "BEivWerRedRom",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van omrekeningsverschillen",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van omrekeningsverschillen ",
  },
  {
    code: "BEivWerRedRbw",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van bijzondere waardeverminderingen",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van bijzondere waardeverminderingen ",
  },
  {
    code: "BEivWerRedRtw",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van terugneming van bijzondere waardeverminderingen ",
  },
  {
    code: "BEivWerRedRov",
    descriptionShort: "Rechtstreekse mutatie als gevolg van overnames",
    descriptionLong: "Rechtstreekse mutatie als gevolg van overnames ",
  },
  {
    code: "BEivWerRedRaf",
    descriptionShort: "Rechtstreekse mutatie als gevolg van afstotingen",
    descriptionLong: "Rechtstreekse mutatie als gevolg van afstotingen ",
  },
  {
    code: "BEivWerRedRfi",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van financiële instrumenten",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van financiële instrumenten ",
  },
  {
    code: "BEivWerRedOvm",
    descriptionShort: "Overige mutaties reserve deelnemingen",
    descriptionLong: "Overige mutaties reserve deelnemingen",
  },
  {
    code: "BEivWerRvo",
    descriptionShort: "Wettelijke reserve omrekeningsverschillen",
    descriptionLong:
      "Wettelijke reserve omrekeningsverschillen voor omrekening van het geïnvesteerde vermogen en het resultaat van deelnemingen",
  },
  {
    code: "BEivWerRvoBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BEivWerRvoDot",
    descriptionShort: "Dotatie reserve voor omrekeningsverschillen",
    descriptionLong: "Dotatie reserve voor omrekeningsverschillen",
  },
  {
    code: "BEivWerRvoOnt",
    descriptionShort: "Onttrekking reserve voor omrekeningsverschillen",
    descriptionLong: "Onttrekking reserve voor omrekeningsverschillen",
  },
  {
    code: "BEivWerRvoRom",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van omrekeningsverschillen",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van omrekeningsverschillen ",
  },
  {
    code: "BEivWerRvoOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties ",
  },
  {
    code: "BEivWerKoe",
    descriptionShort: "Reserve omrekeningsverschillen",
    descriptionLong: "Reserve omrekeningsverschillen",
  },
  {
    code: "BEivWerKoeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BEivWerKoeHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong: "Herwaarderingen ",
  },
  {
    code: "BEivWerKoeRsw",
    descriptionShort: "Rechtstreekse mutatie als gevolg van stelselwijzigingen",
    descriptionLong: "Rechtstreekse mutatie als gevolg van stelselwijzigingen ",
  },
  {
    code: "BEivWerKoeRfh",
    descriptionShort: "Rechtstreekse mutatie als gevolg van foutherstel",
    descriptionLong: "Rechtstreekse mutatie als gevolg van foutherstel ",
  },
  {
    code: "BEivWerKoeRom",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van omrekeningsverschillen",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van omrekeningsverschillen ",
  },
  {
    code: "BEivWerKoeRbw",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van bijzondere waardeverminderingen",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van bijzondere waardeverminderingen ",
  },
  {
    code: "BEivWerKoeRtw",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van terugneming van bijzondere waardeverminderingen ",
  },
  {
    code: "BEivWerKoeOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties ",
  },
  {
    code: "BEivStr",
    descriptionShort: "Statutaire reserves",
    descriptionLong: "Statutaire reserves",
  },
  {
    code: "BEivStrStr",
    descriptionShort: "Statutaire reserve",
    descriptionLong: "Statutaire reserve statutaire reserves",
  },
  {
    code: "BEivStrStrBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BEivStrStrAvv",
    descriptionShort: "Aanzuivering van verliezen",
    descriptionLong: "Aanzuivering van verliezen ",
  },
  {
    code: "BEivStrStrDiv",
    descriptionShort: "Dividenduitkeringen",
    descriptionLong: "Dividenduitkeringen ",
  },
  {
    code: "BEivStrStrOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen ",
  },
  {
    code: "BEivStrStrAvh",
    descriptionShort: "Allocatie van het resultaat",
    descriptionLong: "Allocatie van het resultaat ",
  },
  {
    code: "BEivStrStrRfh",
    descriptionShort: "Rechtstreekse mutatie als gevolg van foutherstel",
    descriptionLong: "Rechtstreekse mutatie als gevolg van foutherstel ",
  },
  {
    code: "BEivStrStrOvm",
    descriptionShort: "Overige mutaties statutaire reserve",
    descriptionLong: "Overige mutaties statutaire reserve",
  },
  {
    code: "BEivBef",
    descriptionShort: "Bestemmingsfondsen",
    descriptionLong: "Bestemmingsfondsen",
  },
  {
    code: "BEivBefBef",
    descriptionShort: "Bestemmingsfondsen",
    descriptionLong: "Bestemmingsfondsen bestemmingsfondsen",
  },
  {
    code: "BEivBefBefBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BEivBefBefDot",
    descriptionShort: "Toevoegingen aan het bestemmingsfonds",
    descriptionLong: "Toevoegingen aan het bestemmingsfonds ",
  },
  {
    code: "BEivBefBefOnt",
    descriptionShort: "Onttrekkingen aan het bestemmingsfonds",
    descriptionLong: "Onttrekkingen aan het bestemmingsfonds ",
  },
  {
    code: "BEivBer",
    descriptionShort: "Bestemmingsreserves",
    descriptionLong: "Bestemmingsreserves",
  },
  {
    code: "BEivBerBer",
    descriptionShort: "Bestemmingsreserve",
    descriptionLong: "Bestemmingsreserve bestemmingsreserves",
  },
  {
    code: "BEivBerBerBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BEivBerBerTad",
    descriptionShort: "Toevoegingen aan de bestemmingsreserve",
    descriptionLong: "Toevoegingen aan de bestemmingsreserve ",
  },
  {
    code: "BEivBerBerOad",
    descriptionShort: "Onttrekkingen aan de bestemmingsreserve",
    descriptionLong: "Onttrekkingen aan de bestemmingsreserve ",
  },
  {
    code: "BEivFij",
    descriptionShort:
      "Financiële instrumenten op basis van juridische vorm geclassificeerd als eigen vermogen",
    descriptionLong:
      "Financiële instrumenten op basis van juridische vorm geclassificeerd als eigen vermogen",
  },
  {
    code: "BEivFijFij",
    descriptionShort:
      "Financiële instrumenten op basis van juridische vorm geclassificeerd als eigen vermogen",
    descriptionLong:
      "Financiële instrumenten op basis van juridische vorm geclassificeerd als eigen vermogen",
  },
  {
    code: "BEivFijFijBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BEivFijFijOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen ",
  },
  {
    code: "BEivFijFijOvm",
    descriptionShort: "Overige mutaties statutaire reserve",
    descriptionLong: "Overige mutaties statutaire reserve",
  },
  {
    code: "BEivOvr",
    descriptionShort: "Overige reserves",
    descriptionLong: "Overige reserves",
  },
  {
    code: "BEivOvrAlr",
    descriptionShort: "Algemene reserve",
    descriptionLong: "Algemene reserve ",
  },
  {
    code: "BEivOvrAlrBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BEivOvrAlrSlw",
    descriptionShort: "Stelselwijziging (correctie beginbalans)",
    descriptionLong: "Stelselwijziging (correctie beginbalans)",
  },
  {
    code: "BEivOvrAlrUva",
    descriptionShort: "Uitgifte van aandelen",
    descriptionLong: "Uitgifte van aandelen ",
  },
  {
    code: "BEivOvrAlrSta",
    descriptionShort: "Stortingen door aandeelhouders",
    descriptionLong: "Stortingen door aandeelhouders ",
  },
  {
    code: "BEivOvrAlrAvv",
    descriptionShort: "Aanzuivering van verliezen",
    descriptionLong: "Aanzuivering van verliezen ",
  },
  {
    code: "BEivOvrAlrVve",
    descriptionShort: "Verkoop van eigen aandelen",
    descriptionLong: "Verkoop van eigen aandelen ",
  },
  {
    code: "BEivOvrAlrIve",
    descriptionShort: "Inkoop van eigen aandelen",
    descriptionLong: "Inkoop van eigen aandelen ",
  },
  {
    code: "BEivOvrAlrIva",
    descriptionShort: "Intrekking van aandelen",
    descriptionLong: "Intrekking van aandelen ",
  },
  {
    code: "BEivOvrAlrDiv",
    descriptionShort: "Dividenduitkeringen",
    descriptionLong: "Dividenduitkeringen ",
  },
  {
    code: "BEivOvrAlrOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen ",
  },
  {
    code: "BEivOvrAlrAvh",
    descriptionShort: "Allocatie van het resultaat",
    descriptionLong: "Allocatie van het resultaat ",
  },
  {
    code: "BEivOvrAlrHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong: "Herwaarderingen ",
  },
  {
    code: "BEivOvrAlrRsw",
    descriptionShort: "Rechtstreekse mutatie als gevolg van stelselwijzigingen",
    descriptionLong: "Rechtstreekse mutatie als gevolg van stelselwijzigingen ",
  },
  {
    code: "BEivOvrAlrRtw",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van terugneming van bijzondere waardeverminderingen ",
  },
  {
    code: "BEivOvrAlrRfh",
    descriptionShort: "Rechtstreekse mutatie als gevolg van foutherstel",
    descriptionLong: "Rechtstreekse mutatie als gevolg van foutherstel ",
  },
  {
    code: "BEivOvrAlrRom",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van omrekeningsverschillen",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van omrekeningsverschillen ",
  },
  {
    code: "BEivOvrAlrRbw",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van bijzondere waardeverminderingen",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van bijzondere waardeverminderingen ",
  },
  {
    code: "BEivOvrAlrRgo",
    descriptionShort: "Rechtstreekse mutatie als gevolg van goodwill",
    descriptionLong: "Rechtstreekse mutatie als gevolg van goodwill ",
  },
  {
    code: "BEivOvrAlrRov",
    descriptionShort: "Rechtstreekse mutatie als gevolg van overnames",
    descriptionLong: "Rechtstreekse mutatie als gevolg van overnames ",
  },
  {
    code: "BEivOvrAlrRaf",
    descriptionShort: "Rechtstreekse mutatie als gevolg van afstotingen",
    descriptionLong: "Rechtstreekse mutatie als gevolg van afstotingen ",
  },
  {
    code: "BEivOvrAlrRfi",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van financiële instrumenten",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van financiële instrumenten ",
  },
  {
    code: "BEivOvrAlrVar",
    descriptionShort: "Verleende aandelen(optie) regelingen",
    descriptionLong: "Verleende aandelen(optie) regelingen ",
  },
  {
    code: "BEivOvrAlrUia",
    descriptionShort: "Uitgeoefende aandelen(optie)regelingen",
    descriptionLong: "Uitgeoefende aandelen(optie)regelingen ",
  },
  {
    code: "BEivOvrAlrOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties ",
  },
  {
    code: "BEivOvrOrs",
    descriptionShort: "Overige reserves",
    descriptionLong: "Overige reserves ",
  },
  {
    code: "BEivOvrOrsBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BEivOvrOrsSlw",
    descriptionShort: "Stelselwijziging (correctie beginbalans)",
    descriptionLong: "Stelselwijziging (correctie beginbalans)",
  },
  {
    code: "BEivOvrOrsUva",
    descriptionShort: "Uitgifte van aandelen",
    descriptionLong: "Uitgifte van aandelen ",
  },
  {
    code: "BEivOvrOrsSta",
    descriptionShort: "Stortingen door aandeelhouders",
    descriptionLong: "Stortingen door aandeelhouders ",
  },
  {
    code: "BEivOvrOrsAvv",
    descriptionShort: "Aanzuivering van verliezen",
    descriptionLong: "Aanzuivering van verliezen ",
  },
  {
    code: "BEivOvrOrsVve",
    descriptionShort: "Verkoop van eigen aandelen",
    descriptionLong: "Verkoop van eigen aandelen ",
  },
  {
    code: "BEivOvrOrsIve",
    descriptionShort: "Inkoop van eigen aandelen",
    descriptionLong: "Inkoop van eigen aandelen ",
  },
  {
    code: "BEivOvrOrsIva",
    descriptionShort: "Intrekking van aandelen",
    descriptionLong: "Intrekking van aandelen ",
  },
  {
    code: "BEivOvrOrsDiv",
    descriptionShort: "Dividenduitkeringen",
    descriptionLong: "Dividenduitkeringen ",
  },
  {
    code: "BEivOvrOrsOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen ",
  },
  {
    code: "BEivOvrOrsAvh",
    descriptionShort: "Allocatie van het resultaat",
    descriptionLong: "Allocatie van het resultaat ",
  },
  {
    code: "BEivOvrOrsHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong: "Herwaarderingen ",
  },
  {
    code: "BEivOvrOrsRsw",
    descriptionShort: "Rechtstreekse mutatie als gevolg van stelselwijzigingen",
    descriptionLong: "Rechtstreekse mutatie als gevolg van stelselwijzigingen ",
  },
  {
    code: "BEivOvrOrsRtw",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van terugneming van bijzondere waardeverminderingen ",
  },
  {
    code: "BEivOvrOrsRfh",
    descriptionShort: "Rechtstreekse mutatie als gevolg van foutherstel",
    descriptionLong: "Rechtstreekse mutatie als gevolg van foutherstel ",
  },
  {
    code: "BEivOvrOrsRom",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van omrekeningsverschillen",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van omrekeningsverschillen ",
  },
  {
    code: "BEivOvrOrsRbw",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van bijzondere waardeverminderingen",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van bijzondere waardeverminderingen ",
  },
  {
    code: "BEivOvrOrsRgo",
    descriptionShort: "Rechtstreekse mutatie als gevolg van goodwill",
    descriptionLong: "Rechtstreekse mutatie als gevolg van goodwill ",
  },
  {
    code: "BEivOvrOrsRov",
    descriptionShort: "Rechtstreekse mutatie als gevolg van overnames",
    descriptionLong: "Rechtstreekse mutatie als gevolg van overnames ",
  },
  {
    code: "BEivOvrOrsRaf",
    descriptionShort: "Rechtstreekse mutatie als gevolg van afstotingen",
    descriptionLong: "Rechtstreekse mutatie als gevolg van afstotingen ",
  },
  {
    code: "BEivOvrOrsRfi",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van financiële instrumenten",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van financiële instrumenten ",
  },
  {
    code: "BEivOvrOrsVar",
    descriptionShort: "Verleende aandelen(optie) regelingen",
    descriptionLong: "Verleende aandelen(optie) regelingen ",
  },
  {
    code: "BEivOvrOrsUia",
    descriptionShort: "Uitgeoefende aandelen(optie)regelingen",
    descriptionLong: "Uitgeoefende aandelen(optie)regelingen ",
  },
  {
    code: "BEivOvrOrsOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties ",
  },
  {
    code: "BEivOvrCor",
    descriptionShort: "Continuïteitsreserve",
    descriptionLong: "Continuïteitsreserve",
  },
  {
    code: "BEivOvrCorBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BEivOvrCorToe",
    descriptionShort: "Toevoegingen aan reserves en fondsen",
    descriptionLong: "Toevoegingen aan reserves en fondsen",
  },
  {
    code: "BEivOvrCorOnt",
    descriptionShort: "Onttrekkingen uit reserves en fondsen",
    descriptionLong: "Onttrekkingen uit reserves en fondsen",
  },
  {
    code: "BEivOvrCorVrv",
    descriptionShort: "Vrijval van reserves en fondsen",
    descriptionLong: "Vrijval van reserves en fondsen",
  },
  {
    code: "BEivOvrCorOve",
    descriptionShort: "Overboekingen van reserves en fondsen",
    descriptionLong: "Overboekingen van reserves en fondsen",
  },
  {
    code: "BEivOvrCorHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong: "Herwaarderingen ",
  },
  {
    code: "BEivOvrCorRsw",
    descriptionShort: "Rechtstreekse mutatie als gevolg van stelselwijzigingen",
    descriptionLong: "Rechtstreekse mutatie als gevolg van stelselwijzigingen ",
  },
  {
    code: "BEivOvrCorRtw",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van terugneming van bijzondere waardeverminderingen ",
  },
  {
    code: "BEivOvrCorRfh",
    descriptionShort: "Rechtstreekse mutatie als gevolg van foutherstel",
    descriptionLong: "Rechtstreekse mutatie als gevolg van foutherstel ",
  },
  {
    code: "BEivOvrCorRom",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van omrekeningsverschillen",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van omrekeningsverschillen ",
  },
  {
    code: "BEivOvrCorRbw",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van bijzondere waardeverminderingen",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van bijzondere waardeverminderingen ",
  },
  {
    code: "BEivOvrCorRfi",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van financiële instrumenten",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van financiële instrumenten ",
  },
  {
    code: "BEivOvrCorOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties ",
  },
  {
    code: "BEivOre",
    descriptionShort: "Onverdeelde winst",
    descriptionLong: "Onverdeelde winst",
  },
  {
    code: "BEivOreOvw",
    descriptionShort: "Niet verdeelde winst",
    descriptionLong: "Niet verdeelde winst onverdeelde winst",
  },
  {
    code: "BEivOreOvwBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BEivOreOvwSlw",
    descriptionShort: "Stelselwijziging (correctie beginbalans)",
    descriptionLong: "Stelselwijziging (correctie beginbalans)",
  },
  {
    code: "BEivOreOvwDiv",
    descriptionShort: "Dividenduitkeringen",
    descriptionLong: "Dividenduitkeringen ",
  },
  {
    code: "BEivOreOvwOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen ",
  },
  {
    code: "BEivOreOvwAll",
    descriptionShort: "Allocatie van het resultaat",
    descriptionLong: "Allocatie van het resultaat ",
  },
  {
    code: "BEivOreOvwHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong: "Herwaarderingen ",
  },
  {
    code: "BEivOreOvwRms",
    descriptionShort: "Rechtstreekse mutatie als gevolg van stelselwijzigingen",
    descriptionLong: "Rechtstreekse mutatie als gevolg van stelselwijzigingen ",
  },
  {
    code: "BEivOreOvwRmt",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van terugneming van bijzondere waardeverminderingen ",
  },
  {
    code: "BEivOreOvwRmf",
    descriptionShort: "Rechtstreekse mutatie als gevolg van foutherstel",
    descriptionLong: "Rechtstreekse mutatie als gevolg van foutherstel ",
  },
  {
    code: "BEivOreOvwRmv",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van omrekeningsverschillen",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van omrekeningsverschillen ",
  },
  {
    code: "BEivOreOvwRmw",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van bijzondere waardeverminderingen",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van bijzondere waardeverminderingen ",
  },
  {
    code: "BEivOreOvwRmg",
    descriptionShort: "Rechtstreekse mutatie als gevolg van goodwill",
    descriptionLong: "Rechtstreekse mutatie als gevolg van goodwill ",
  },
  {
    code: "BEivOreOvwRmo",
    descriptionShort: "Rechtstreekse mutatie als gevolg van overnames",
    descriptionLong: "Rechtstreekse mutatie als gevolg van overnames ",
  },
  {
    code: "BEivOreOvwRma",
    descriptionShort: "Rechtstreekse mutatie als gevolg van afstotingen",
    descriptionLong: "Rechtstreekse mutatie als gevolg van afstotingen ",
  },
  {
    code: "BEivOreOvwRmd",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van financiële instrumenten",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van financiële instrumenten ",
  },
  {
    code: "BEivOreOvwOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties ",
  },
  {
    code: "BEivOreRvh",
    descriptionShort: "Resultaat van het boekjaar",
    descriptionLong: "Resultaat van het boekjaar ",
  },
  {
    code: "BEivOreRvhBeg",
    descriptionShort: "Beginbalans resultaat van het boekjaar",
    descriptionLong: "Beginbalans resultaat van het boekjaar",
  },
  {
    code: "BEivOreRvhDiv",
    descriptionShort: "Dividenduitkeringen",
    descriptionLong: "Dividenduitkeringen ",
  },
  {
    code: "BEivOreRvhOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen ",
  },
  {
    code: "BEivOreRvhAll",
    descriptionShort: "Allocatie van het resultaat",
    descriptionLong: "Allocatie van het resultaat ",
  },
  {
    code: "BEivOreRvhHer",
    descriptionShort: "Herwaarderingen",
    descriptionLong: "Herwaarderingen ",
  },
  {
    code: "BEivOreRvhRms",
    descriptionShort: "Rechtstreekse mutatie als gevolg van stelselwijzigingen",
    descriptionLong: "Rechtstreekse mutatie als gevolg van stelselwijzigingen ",
  },
  {
    code: "BEivOreRvhRmt",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van terugneming van bijzondere waardeverminderingen",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van terugneming van bijzondere waardeverminderingen ",
  },
  {
    code: "BEivOreRvhRmf",
    descriptionShort: "Rechtstreekse mutatie als gevolg van foutherstel",
    descriptionLong: "Rechtstreekse mutatie als gevolg van foutherstel ",
  },
  {
    code: "BEivOreRvhRmv",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van omrekeningsverschillen",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van omrekeningsverschillen ",
  },
  {
    code: "BEivOreRvhRmw",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van bijzondere waardeverminderingen",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van bijzondere waardeverminderingen ",
  },
  {
    code: "BEivOreRvhRmg",
    descriptionShort: "Rechtstreekse mutatie als gevolg van goodwill",
    descriptionLong: "Rechtstreekse mutatie als gevolg van goodwill ",
  },
  {
    code: "BEivOreRvhRmo",
    descriptionShort: "Rechtstreekse mutatie als gevolg van overnames",
    descriptionLong: "Rechtstreekse mutatie als gevolg van overnames ",
  },
  {
    code: "BEivOreRvhRma",
    descriptionShort: "Rechtstreekse mutatie als gevolg van afstotingen",
    descriptionLong: "Rechtstreekse mutatie als gevolg van afstotingen ",
  },
  {
    code: "BEivOreRvhRmd",
    descriptionShort:
      "Rechtstreekse mutatie als gevolg van financiële instrumenten",
    descriptionLong:
      "Rechtstreekse mutatie als gevolg van financiële instrumenten ",
  },
  {
    code: "BEivOreRvhOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties ",
  },
  {
    code: "BEivOreVde",
    descriptionShort:
      "Voorgestelde bedrag aan dividenduitkeringen aan houders van eigenvermogensinstrumenten (geclassificeerd als eigen vermogen)",
    descriptionLong:
      "Voorgestelde bedrag aan dividenduitkeringen aan houders van eigenvermogensinstrumenten (geclassificeerd als eigen vermogen)",
  },
  {
    code: "BEivOreVdeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BEivOreVdeDiv",
    descriptionShort: "Dividenduitkeringen",
    descriptionLong: "Dividenduitkeringen ",
  },
  {
    code: "BEivOreVdeOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen ",
  },
  {
    code: "BEivOreVdeAll",
    descriptionShort: "Allocatie van het resultaat",
    descriptionLong: "Allocatie van het resultaat ",
  },
  {
    code: "BEivOreVdeOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties ",
  },
  {
    code: "BEivOreUpd",
    descriptionShort:
      "Uit te keren preferent dividend die in mindering wordt gebracht op het resultaat na belastingen",
    descriptionLong:
      "Uit te keren preferent dividend die in mindering wordt gebracht op het resultaat na belastingen",
  },
  {
    code: "BEivOreUpdBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BEivOreUpdDiv",
    descriptionShort: "Dividenduitkeringen",
    descriptionLong: "Dividenduitkeringen ",
  },
  {
    code: "BEivOreUpdOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen ",
  },
  {
    code: "BEivOreUpdAll",
    descriptionShort: "Allocatie van het resultaat",
    descriptionLong: "Allocatie van het resultaat ",
  },
  {
    code: "BEivOreUpdOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties ",
  },
  {
    code: "BEivKap",
    descriptionShort: "Eigen vermogen onderneming natuurlijke personen",
    descriptionLong: "Eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKapOnd",
    descriptionShort: "Ondernemingsvermogen exclusief fiscale reserves fiscaal",
    descriptionLong:
      "Ondernemingsvermogen exclusief fiscale reserves fiscaal eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKapOndBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKapOndRgv",
    descriptionShort: "Rente geïnvesteerd vermogen",
    descriptionLong:
      "Rente geïnvesteerd vermogen eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKapOndArb",
    descriptionShort: "Arbeidsvergoeding",
    descriptionLong:
      "Arbeidsvergoeding  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKapOndVbv",
    descriptionShort: "Vergoeding buitenvennootschappelijk vermogen",
    descriptionLong:
      "Vergoeding buitenvennootschappelijk vermogen eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKapOndAow",
    descriptionShort: "Aandeel in de overwinst",
    descriptionLong:
      "Aandeel in de overwinst eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKapOndOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKapPrs",
    descriptionShort: "Privé-stortingen",
    descriptionLong: "Privé-stortingen",
  },
  {
    code: "BEivKapPrsPsk",
    descriptionShort: "Privé-storting kapitaal ",
    descriptionLong:
      "Privé-storting kapitaal  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKapPrsOns",
    descriptionShort: "Ontvangen schenkingen ",
    descriptionLong:
      "Ontvangen schenkingen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKapPrsOlp",
    descriptionShort: "Ontvangen loon, uitkeringen of pensioenen ",
    descriptionLong:
      "Ontvangen loon, uitkeringen of pensioenen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKapPrsOte",
    descriptionShort: "Ontvangen toeslagen en toelagen ",
    descriptionLong:
      "Ontvangen toeslagen en toelagen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKapPrsOnk",
    descriptionShort: "Ontvangen kostenvergoedingen ",
    descriptionLong:
      "Ontvangen kostenvergoedingen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKapPrsOpp",
    descriptionShort: "Opname privé-financieringen ",
    descriptionLong:
      "Opname privé-financieringen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKapPrsOsp",
    descriptionShort: "Opname privé-spaargelden ",
    descriptionLong:
      "Opname privé-spaargelden  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKapPrsVep",
    descriptionShort: "Verkoop privé-bezittingen ",
    descriptionLong:
      "Verkoop privé-bezittingen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKapPrsPzl",
    descriptionShort: "Privé-betaalde zakelijke lasten ",
    descriptionLong:
      "Privé-betaalde zakelijke lasten  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKapPrsOps",
    descriptionShort: "Overige privé-stortingen ",
    descriptionLong:
      "Overige privé-stortingen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKapPro",
    descriptionShort: "Privé-opnamen",
    descriptionLong: "Privé-opnamen",
  },
  {
    code: "BEivKapProPok",
    descriptionShort: "Privé-opname kapitaal ",
    descriptionLong:
      "Privé-opname kapitaal eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKapProPmv",
    descriptionShort: "Privé-gebruik materiële vaste activa ",
    descriptionLong:
      "Privé-gebruik materiële vaste activa eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKapProPrg",
    descriptionShort: "Privé-verbruik goederen ",
    descriptionLong:
      "Privé-verbruik goederen eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKapProPiz",
    descriptionShort: "Privé-aandeel in zakelijke lasten ",
    descriptionLong:
      "Privé-aandeel in zakelijke lasten eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKapProPpr",
    descriptionShort: "Privé-premies ",
    descriptionLong:
      "Privé-premies eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKapProPri",
    descriptionShort: "Privé-belastingen ",
    descriptionLong:
      "Privé-belastingen eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKapProPer",
    descriptionShort: "Privé-aflossingen en rente ",
    descriptionLong:
      "Privé-aflossingen en rente eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKapProPrk",
    descriptionShort: "Privé-aftrekbare kosten ",
    descriptionLong:
      "Privé-aftrekbare kosten eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKapProFor",
    descriptionShort: "Dotatie Fiscale Oudedags Reserve ",
    descriptionLong:
      "Dotatie Fiscale Oudedags Reserve eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKapProOvp",
    descriptionShort: "Overige privé-opnamen ",
    descriptionLong:
      "Overige privé-opnamen eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKapPoc",
    descriptionShort: "Privé-onttrekking contanten ",
    descriptionLong: "Privé-onttrekking contanten ",
  },
  {
    code: "BEivKapPng",
    descriptionShort: "Privé-onttrekking in natura en goederen",
    descriptionLong: "Privé-onttrekking in natura en goederen",
  },
  {
    code: "BEivKapPbe",
    descriptionShort: "Privé-belastingen",
    descriptionLong: "Privé-belastingen",
  },
  {
    code: "BEivKapPpr",
    descriptionShort: "Privé-premies ",
    descriptionLong: "Privé-premies ",
  },
  {
    code: "BEivKa2",
    descriptionShort: "Eigen vermogen firmant 2",
    descriptionLong: "Eigen vermogen firmant 2",
  },
  {
    code: "BEivKa2Ond",
    descriptionShort: "Ondernemingsvermogen exclusief fiscale reserves fiscaal",
    descriptionLong:
      "Ondernemingsvermogen exclusief fiscale reserves fiscaal eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa2OndBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa2OndRgv",
    descriptionShort: "Rente geïnvesteerd vermogen ",
    descriptionLong:
      "Rente geïnvesteerd vermogen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa2OndArb",
    descriptionShort: "Arbeidsvergoeding ",
    descriptionLong:
      "Arbeidsvergoeding  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa2OndVbv",
    descriptionShort: "Vergoeding buitenvennootschappelijk vermogen ",
    descriptionLong:
      "Vergoeding buitenvennootschappelijk vermogen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa2OndAow",
    descriptionShort: "Aandeel in de overwinst ",
    descriptionLong:
      "Aandeel in de overwinst  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa2OndOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa2Prs",
    descriptionShort: "Privé-stortingen firmant 2",
    descriptionLong: "Privé-stortingen firmant 2",
  },
  {
    code: "BEivKa2PrsPsk",
    descriptionShort: "Privé-storting kapitaal ",
    descriptionLong:
      "Privé-storting kapitaal  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa2PrsOns",
    descriptionShort: "Ontvangen schenkingen ",
    descriptionLong:
      "Ontvangen schenkingen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa2PrsOlp",
    descriptionShort: "Ontvangen loon, uitkeringen of pensioenen ",
    descriptionLong:
      "Ontvangen loon, uitkeringen of pensioenen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa2PrsOte",
    descriptionShort: "Ontvangen toeslagen en toelagen ",
    descriptionLong:
      "Ontvangen toeslagen en toelagen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa2PrsOnk",
    descriptionShort: "Ontvangen kostenvergoedingen ",
    descriptionLong:
      "Ontvangen kostenvergoedingen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa2PrsOpp",
    descriptionShort: "Opname privé-financieringen ",
    descriptionLong:
      "Opname privé-financieringen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa2PrsOsp",
    descriptionShort: "Opname privé-spaargelden ",
    descriptionLong:
      "Opname privé-spaargelden  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa2PrsVep",
    descriptionShort: "Verkoop privé-bezittingen ",
    descriptionLong:
      "Verkoop privé-bezittingen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa2PrsPzl",
    descriptionShort: "Privé-betaalde zakelijke lasten ",
    descriptionLong:
      "Privé-betaalde zakelijke lasten  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa2PrsOps",
    descriptionShort: "Overige privé-stortingen ",
    descriptionLong:
      "Overige privé-stortingen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa2Pro",
    descriptionShort: "Privé-opnamen firmant 2",
    descriptionLong: "Privé-opnamen firmant 2",
  },
  {
    code: "BEivKa2ProPok",
    descriptionShort: "Privé-opname kapitaal ",
    descriptionLong:
      "Privé-opname kapitaal  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa2ProPmv",
    descriptionShort: "Privé-gebruik materiële vaste activa ",
    descriptionLong:
      "Privé-gebruik materiële vaste activa  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa2ProPrg",
    descriptionShort: "Privé-verbruik goederen ",
    descriptionLong:
      "Privé-verbruik goederen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa2ProPiz",
    descriptionShort: "Privé-aandeel in zakelijke lasten ",
    descriptionLong:
      "Privé-aandeel in zakelijke lasten  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa2ProPpr",
    descriptionShort: "Privé-premies ",
    descriptionLong:
      "Privé-premies  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa2ProPri",
    descriptionShort: "Privé-belastingen ",
    descriptionLong:
      "Privé-belastingen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa2ProPer",
    descriptionShort: "Privé-aflossingen en rente ",
    descriptionLong:
      "Privé-aflossingen en rente  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa2ProPrk",
    descriptionShort: "Privé-aftrekbare kosten ",
    descriptionLong:
      "Privé-aftrekbare kosten  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa2ProFor",
    descriptionShort: "Dotatie Fiscale Oudedags Reserve ",
    descriptionLong:
      "Dotatie Fiscale Oudedags Reserve  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa2ProOvp",
    descriptionShort: "Overige privé-opnamen ",
    descriptionLong:
      "Overige privé-opnamen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa2Poc",
    descriptionShort: "Privé-onttrekking contanten firmant 2 ",
    descriptionLong: "Privé-onttrekking contanten firmant 2 ",
  },
  {
    code: "BEivKa2Png",
    descriptionShort: "Privé-onttrekking in natura en goederen firmant 2",
    descriptionLong: "Privé-onttrekking in natura en goederen firmant 2",
  },
  {
    code: "BEivKa2Pbe",
    descriptionShort: "Privé-belastingen firmant 2",
    descriptionLong: "Privé-belastingen firmant 2",
  },
  {
    code: "BEivKa2Ppr",
    descriptionShort: "Privé-premies firmant 2 ",
    descriptionLong: "Privé-premies firmant 2 ",
  },
  {
    code: "BEivKa3",
    descriptionShort: "Eigen vermogen firmant 3",
    descriptionLong: "Eigen vermogen firmant 3",
  },
  {
    code: "BEivKa3Ond",
    descriptionShort: "Ondernemingsvermogen exclusief fiscale reserves fiscaal",
    descriptionLong:
      "Ondernemingsvermogen exclusief fiscale reserves fiscaal eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa3OndBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa3OndRgv",
    descriptionShort: "Rente geïnvesteerd vermogen ",
    descriptionLong:
      "Rente geïnvesteerd vermogen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa3OndArb",
    descriptionShort: "Arbeidsvergoeding ",
    descriptionLong:
      "Arbeidsvergoeding  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa3OndVbv",
    descriptionShort: "Vergoeding buitenvennootschappelijk vermogen ",
    descriptionLong:
      "Vergoeding buitenvennootschappelijk vermogen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa3OndAow",
    descriptionShort: "Aandeel in de overwinst ",
    descriptionLong:
      "Aandeel in de overwinst  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa3OndOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa3Prs",
    descriptionShort: "Privé-stortingen firmant 3",
    descriptionLong: "Privé-stortingen firmant 3",
  },
  {
    code: "BEivKa3PrsPsk",
    descriptionShort: "Privé-storting kapitaal ",
    descriptionLong:
      "Privé-storting kapitaal  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa3PrsOns",
    descriptionShort: "Ontvangen schenkingen ",
    descriptionLong:
      "Ontvangen schenkingen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa3PrsOlp",
    descriptionShort: "Ontvangen loon, uitkeringen of pensioenen ",
    descriptionLong:
      "Ontvangen loon, uitkeringen of pensioenen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa3PrsOte",
    descriptionShort: "Ontvangen toeslagen en toelagen ",
    descriptionLong:
      "Ontvangen toeslagen en toelagen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa3PrsOnk",
    descriptionShort: "Ontvangen kostenvergoedingen ",
    descriptionLong:
      "Ontvangen kostenvergoedingen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa3PrsOpp",
    descriptionShort: "Opname privé-financieringen ",
    descriptionLong:
      "Opname privé-financieringen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa3PrsOsp",
    descriptionShort: "Opname privé-spaargelden ",
    descriptionLong:
      "Opname privé-spaargelden  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa3PrsVep",
    descriptionShort: "Verkoop privé-bezittingen ",
    descriptionLong:
      "Verkoop privé-bezittingen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa3PrsPzl",
    descriptionShort: "Privé-betaalde zakelijke lasten ",
    descriptionLong:
      "Privé-betaalde zakelijke lasten  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa3PrsOps",
    descriptionShort: "Overige privé-stortingen ",
    descriptionLong:
      "Overige privé-stortingen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa3Pro",
    descriptionShort: "Privé-opnamen firmant 3",
    descriptionLong: "Privé-opnamen firmant 3",
  },
  {
    code: "BEivKa3ProPok",
    descriptionShort: "Privé-opname kapitaal ",
    descriptionLong:
      "Privé-opname kapitaal  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa3ProPmv",
    descriptionShort: "Privé-gebruik materiële vaste activa ",
    descriptionLong:
      "Privé-gebruik materiële vaste activa  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa3ProPrg",
    descriptionShort: "Privé-verbruik goederen ",
    descriptionLong:
      "Privé-verbruik goederen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa3ProPiz",
    descriptionShort: "Privé-aandeel in zakelijke lasten ",
    descriptionLong:
      "Privé-aandeel in zakelijke lasten  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa3ProPpr",
    descriptionShort: "Privé-premies ",
    descriptionLong:
      "Privé-premies  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa3ProPri",
    descriptionShort: "Privé-belastingen ",
    descriptionLong:
      "Privé-belastingen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa3ProPer",
    descriptionShort: "Privé-aflossingen en rente ",
    descriptionLong:
      "Privé-aflossingen en rente  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa3ProPrk",
    descriptionShort: "Privé-aftrekbare kosten ",
    descriptionLong:
      "Privé-aftrekbare kosten  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa3ProFor",
    descriptionShort: "Dotatie Fiscale Oudedags Reserve ",
    descriptionLong:
      "Dotatie Fiscale Oudedags Reserve  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa3ProOvp",
    descriptionShort: "Overige privé-opnamen ",
    descriptionLong:
      "Overige privé-opnamen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa3Poc",
    descriptionShort: "Privé-onttrekking contanten firmant 3 ",
    descriptionLong: "Privé-onttrekking contanten firmant 3 ",
  },
  {
    code: "BEivKa3Png",
    descriptionShort: "Privé-onttrekking in natura en goederen firmant 3",
    descriptionLong: "Privé-onttrekking in natura en goederen firmant 3",
  },
  {
    code: "BEivKa3Pbe",
    descriptionShort: "Privé-belastingen firmant 3",
    descriptionLong: "Privé-belastingen firmant 3",
  },
  {
    code: "BEivKa3Ppr",
    descriptionShort: "Privé-premies firmant 3 ",
    descriptionLong: "Privé-premies firmant 3 ",
  },
  {
    code: "BEivKa4",
    descriptionShort: "Eigen vermogen firmant 4",
    descriptionLong: "Eigen vermogen firmant 4",
  },
  {
    code: "BEivKa4Ond",
    descriptionShort: "Ondernemingsvermogen exclusief fiscale reserves fiscaal",
    descriptionLong:
      "Ondernemingsvermogen exclusief fiscale reserves fiscaal eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa4OndBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa4OndRgv",
    descriptionShort: "Rente geïnvesteerd vermogen ",
    descriptionLong:
      "Rente geïnvesteerd vermogen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa4OndArb",
    descriptionShort: "Arbeidsvergoeding ",
    descriptionLong:
      "Arbeidsvergoeding  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa4OndVbv",
    descriptionShort: "Vergoeding buitenvennootschappelijk vermogen ",
    descriptionLong:
      "Vergoeding buitenvennootschappelijk vermogen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa4OndAow",
    descriptionShort: "Aandeel in de overwinst ",
    descriptionLong:
      "Aandeel in de overwinst  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa4OndOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa4Prs",
    descriptionShort: "Privé-stortingen firmant 4",
    descriptionLong: "Privé-stortingen firmant 4",
  },
  {
    code: "BEivKa4PrsPsk",
    descriptionShort: "Privé-storting kapitaal ",
    descriptionLong:
      "Privé-storting kapitaal  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa4PrsOns",
    descriptionShort: "Ontvangen schenkingen ",
    descriptionLong:
      "Ontvangen schenkingen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa4PrsOlp",
    descriptionShort: "Ontvangen loon, uitkeringen of pensioenen ",
    descriptionLong:
      "Ontvangen loon, uitkeringen of pensioenen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa4PrsOte",
    descriptionShort: "Ontvangen toeslagen en toelagen ",
    descriptionLong:
      "Ontvangen toeslagen en toelagen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa4PrsOnk",
    descriptionShort: "Ontvangen kostenvergoedingen ",
    descriptionLong:
      "Ontvangen kostenvergoedingen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa4PrsOpp",
    descriptionShort: "Opname privé-financieringen ",
    descriptionLong:
      "Opname privé-financieringen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa4PrsOsp",
    descriptionShort: "Opname privé-spaargelden ",
    descriptionLong:
      "Opname privé-spaargelden  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa4PrsVep",
    descriptionShort: "Verkoop privé-bezittingen ",
    descriptionLong:
      "Verkoop privé-bezittingen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa4PrsPzl",
    descriptionShort: "Privé-betaalde zakelijke lasten ",
    descriptionLong:
      "Privé-betaalde zakelijke lasten  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa4PrsOps",
    descriptionShort: "Overige privé-stortingen ",
    descriptionLong:
      "Overige privé-stortingen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa4Pro",
    descriptionShort: "Privé-opnamen firmant 4",
    descriptionLong: "Privé-opnamen firmant 4",
  },
  {
    code: "BEivKa4ProPok",
    descriptionShort: "Privé-opname kapitaal ",
    descriptionLong:
      "Privé-opname kapitaal  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa4ProPmv",
    descriptionShort: "Privé-gebruik materiële vaste activa ",
    descriptionLong:
      "Privé-gebruik materiële vaste activa  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa4ProPrg",
    descriptionShort: "Privé-verbruik goederen ",
    descriptionLong:
      "Privé-verbruik goederen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa4ProPiz",
    descriptionShort: "Privé-aandeel in zakelijke lasten ",
    descriptionLong:
      "Privé-aandeel in zakelijke lasten  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa4ProPpr",
    descriptionShort: "Privé-premies ",
    descriptionLong:
      "Privé-premies  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa4ProPri",
    descriptionShort: "Privé-belastingen ",
    descriptionLong:
      "Privé-belastingen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa4ProPer",
    descriptionShort: "Privé-aflossingen en rente ",
    descriptionLong:
      "Privé-aflossingen en rente  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa4ProPrk",
    descriptionShort: "Privé-aftrekbare kosten ",
    descriptionLong:
      "Privé-aftrekbare kosten  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa4ProFor",
    descriptionShort: "Dotatie Fiscale Oudedags Reserve ",
    descriptionLong:
      "Dotatie Fiscale Oudedags Reserve  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa4ProOvp",
    descriptionShort: "Overige privé-opnamen ",
    descriptionLong:
      "Overige privé-opnamen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa4Poc",
    descriptionShort: "Privé-onttrekking contanten firmant 4",
    descriptionLong: "Privé-onttrekking contanten firmant 4",
  },
  {
    code: "BEivKa4Png",
    descriptionShort: "Privé-onttrekking in natura en goederen firmant 4",
    descriptionLong: "Privé-onttrekking in natura en goederen firmant 4",
  },
  {
    code: "BEivKa4Pbe",
    descriptionShort: "Privé-belastingen firmant 4",
    descriptionLong: "Privé-belastingen firmant 4",
  },
  {
    code: "BEivKa4Ppr",
    descriptionShort: "Privé-premies firmant 4",
    descriptionLong: "Privé-premies firmant 4",
  },
  {
    code: "BEivKa5",
    descriptionShort: "Eigen vermogen firmant 5",
    descriptionLong: "Eigen vermogen firmant 5",
  },
  {
    code: "BEivKa5Ond",
    descriptionShort: "Ondernemingsvermogen exclusief fiscale reserves fiscaal",
    descriptionLong:
      "Ondernemingsvermogen exclusief fiscale reserves fiscaal eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa5OndBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa5OndRgv",
    descriptionShort: "Rente geïnvesteerd vermogen ",
    descriptionLong:
      "Rente geïnvesteerd vermogen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa5OndArb",
    descriptionShort: "Arbeidsvergoeding ",
    descriptionLong:
      "Arbeidsvergoeding  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa5OndVbv",
    descriptionShort: "Vergoeding buitenvennootschappelijk vermogen ",
    descriptionLong:
      "Vergoeding buitenvennootschappelijk vermogen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa5OndAow",
    descriptionShort: "Aandeel in de overwinst ",
    descriptionLong:
      "Aandeel in de overwinst  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa5OndOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa5Prs",
    descriptionShort: "Privé-stortingen firmant 5",
    descriptionLong: "Privé-stortingen firmant 5",
  },
  {
    code: "BEivKa5PrsPsk",
    descriptionShort: "Privé-storting kapitaal ",
    descriptionLong:
      "Privé-storting kapitaal  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa5PrsOns",
    descriptionShort: "Ontvangen schenkingen ",
    descriptionLong:
      "Ontvangen schenkingen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa5PrsOlp",
    descriptionShort: "Ontvangen loon, uitkeringen of pensioenen ",
    descriptionLong:
      "Ontvangen loon, uitkeringen of pensioenen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa5PrsOte",
    descriptionShort: "Ontvangen toeslagen en toelagen ",
    descriptionLong:
      "Ontvangen toeslagen en toelagen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa5PrsOnk",
    descriptionShort: "Ontvangen kostenvergoedingen ",
    descriptionLong:
      "Ontvangen kostenvergoedingen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa5PrsOpp",
    descriptionShort: "Opname privé-financieringen ",
    descriptionLong:
      "Opname privé-financieringen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa5PrsOsp",
    descriptionShort: "Opname privé-spaargelden ",
    descriptionLong:
      "Opname privé-spaargelden  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa5PrsVep",
    descriptionShort: "Verkoop privé-bezittingen ",
    descriptionLong:
      "Verkoop privé-bezittingen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa5PrsPzl",
    descriptionShort: "Privé-betaalde zakelijke lasten ",
    descriptionLong:
      "Privé-betaalde zakelijke lasten  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa5PrsOps",
    descriptionShort: "Overige privé-stortingen ",
    descriptionLong:
      "Overige privé-stortingen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa5Pro",
    descriptionShort: "Privé-opnamen firmant 5",
    descriptionLong: "Privé-opnamen firmant 5",
  },
  {
    code: "BEivKa5ProPok",
    descriptionShort: "Privé-opname kapitaal ",
    descriptionLong:
      "Privé-opname kapitaal  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa5ProPmv",
    descriptionShort: "Privé-gebruik materiële vaste activa ",
    descriptionLong:
      "Privé-gebruik materiële vaste activa  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa5ProPrg",
    descriptionShort: "Privé-verbruik goederen ",
    descriptionLong:
      "Privé-verbruik goederen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa5ProPiz",
    descriptionShort: "Privé-aandeel in zakelijke lasten ",
    descriptionLong:
      "Privé-aandeel in zakelijke lasten  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa5ProPpr",
    descriptionShort: "Privé-premies ",
    descriptionLong:
      "Privé-premies  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa5ProPri",
    descriptionShort: "Privé-belastingen ",
    descriptionLong:
      "Privé-belastingen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa5ProPer",
    descriptionShort: "Privé-aflossingen en rente ",
    descriptionLong:
      "Privé-aflossingen en rente  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa5ProPrk",
    descriptionShort: "Privé-aftrekbare kosten ",
    descriptionLong:
      "Privé-aftrekbare kosten  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa5ProFor",
    descriptionShort: "Dotatie Fiscale Oudedags Reserve ",
    descriptionLong:
      "Dotatie Fiscale Oudedags Reserve  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa5ProOvp",
    descriptionShort: "Overige privé-opnamen ",
    descriptionLong:
      "Overige privé-opnamen  eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivKa5Poc",
    descriptionShort: "Privé-onttrekking contanten firmant 5",
    descriptionLong: "Privé-onttrekking contanten firmant 5",
  },
  {
    code: "BEivKa5Png",
    descriptionShort: "Privé-onttrekking in natura en goederen firmant 5",
    descriptionLong: "Privé-onttrekking in natura en goederen firmant 5",
  },
  {
    code: "BEivKa5Pbe",
    descriptionShort: "Privé-belastingen firmant 5",
    descriptionLong: "Privé-belastingen firmant 5",
  },
  {
    code: "BEivKa5Ppr",
    descriptionShort: "Privé-premies firmant 5",
    descriptionLong: "Privé-premies firmant 5",
  },
  {
    code: "BEivOkc",
    descriptionShort: "Overige kapitaalcomponenten",
    descriptionLong: "Overige kapitaalcomponenten",
  },
  {
    code: "BEivOkcInk",
    descriptionShort: "Informeel kapitaal",
    descriptionLong: "Informeel kapitaal (alleen in BD-VPB)",
  },
  {
    code: "BEivOkcInkBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivOkcInkKap",
    descriptionShort: "Kapitaalmutaties",
    descriptionLong:
      "Kapitaalmutaties eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivOkcInkKac",
    descriptionShort: "Kapitaalcorrecties",
    descriptionLong:
      "Kapitaalcorrecties eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivOkcInkOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivOkcKeg",
    descriptionShort: "Kosten egalisatiereserve fiscaal",
    descriptionLong: "Kosten egalisatiereserve fiscaal (alleen in BD-VPB)",
  },
  {
    code: "BEivOkcKegBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivOkcKegDot",
    descriptionShort: "Dotatie",
    descriptionLong: "Dotatie eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivOkcKegAtg",
    descriptionShort: "Afname ten gunste van het resultaat",
    descriptionLong:
      "Afname ten gunste van het resultaat eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivOkcKegKtl",
    descriptionShort: "Kosten ten laste van reserve",
    descriptionLong:
      "Kosten ten laste van reserve eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivOkcKegOve",
    descriptionShort: "Overboekingen",
    descriptionLong:
      "Overboekingen eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivOkcKegVal",
    descriptionShort: "Valutaomrekeningsverschillen",
    descriptionLong:
      "Valutaomrekeningsverschillen eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivOkcKegOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFir",
    descriptionShort: "Fiscale reserves",
    descriptionLong: "Fiscale reserves",
  },
  {
    code: "BEivFirHer",
    descriptionShort: "Herinvesteringsreserve fiscaal",
    descriptionLong: "Herinvesteringsreserve fiscaal eigen vermogen",
  },
  {
    code: "BEivFirHerBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) eigen vermogen",
  },
  {
    code: "BEivFirHerDot",
    descriptionShort: "Dotatie",
    descriptionLong: "Dotatie eigen vermogen",
  },
  {
    code: "BEivFirHerAaw",
    descriptionShort: "Afname ten gunste van het resultaat",
    descriptionLong: "Afname ten gunste van het resultaat eigen vermogen",
  },
  {
    code: "BEivFirHerKtl",
    descriptionShort: "Kosten ten laste van reserve",
    descriptionLong: "Kosten ten laste van reserve eigen vermogen",
  },
  {
    code: "BEivFirHerOve",
    descriptionShort: "Overboekingen",
    descriptionLong: "Overboekingen eigen vermogen",
  },
  {
    code: "BEivFirHerVal",
    descriptionShort: "Valutaomrekeningsverschillen",
    descriptionLong: "Valutaomrekeningsverschillen eigen vermogen",
  },
  {
    code: "BEivFirHerOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties eigen vermogen",
  },
  {
    code: "BEivFirFor",
    descriptionShort: "Fiscale oudedagsreserve",
    descriptionLong:
      "Fiscale oudedagsreserve eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirForBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirForFor",
    descriptionShort: "Dotatie",
    descriptionLong: "Dotatie eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirForAtg",
    descriptionShort: "Afname ten gunste van het resultaat",
    descriptionLong:
      "Afname ten gunste van het resultaat eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirForKtl",
    descriptionShort: "Kosten ten laste van reserve",
    descriptionLong:
      "Kosten ten laste van reserve eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirForOve",
    descriptionShort: "Overboekingen",
    descriptionLong:
      "Overboekingen eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirForVal",
    descriptionShort: "Valutaomrekeningsverschillen",
    descriptionLong:
      "Valutaomrekeningsverschillen eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirForOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirOpw",
    descriptionShort: "Opwaarderingsreserve",
    descriptionLong:
      "Opwaarderingsreserve eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirOpwBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirOpwDot",
    descriptionShort: "Dotatie",
    descriptionLong: "Dotatie eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirOpwAtg",
    descriptionShort: "Afname ten gunste van het resultaat",
    descriptionLong:
      "Afname ten gunste van het resultaat eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirOpwAaw",
    descriptionShort: "Kosten ten laste van reserve",
    descriptionLong:
      "Kosten ten laste van reserve eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirOpwOve",
    descriptionShort: "Overboekingen",
    descriptionLong:
      "Overboekingen eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirOpwVal",
    descriptionShort: "Valutaomrekeningsverschillen",
    descriptionLong:
      "Valutaomrekeningsverschillen eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirOpwOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirRae",
    descriptionShort: "Reserve assurantie eigen risico",
    descriptionLong:
      "Reserve assurantie eigen risico eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirRaeBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirRaeDot",
    descriptionShort: "Dotatie",
    descriptionLong: "Dotatie eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirRaeAtg",
    descriptionShort: "Afname ten gunste van het resultaat",
    descriptionLong:
      "Afname ten gunste van het resultaat eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirRaeKtl",
    descriptionShort: "Kosten ten laste van reserve",
    descriptionLong:
      "Kosten ten laste van reserve eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirRaeOve",
    descriptionShort: "Overboekingen",
    descriptionLong:
      "Overboekingen eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirRaeVal",
    descriptionShort: "Valutaomrekeningsverschillen",
    descriptionLong:
      "Valutaomrekeningsverschillen eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirRaeOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirExp",
    descriptionShort: "Exportreserve",
    descriptionLong:
      "Exportreserve eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirExpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirExpDot",
    descriptionShort: "Dotatie",
    descriptionLong: "Dotatie eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirExpAtg",
    descriptionShort: "Afname ten gunste van het resultaat",
    descriptionLong:
      "Afname ten gunste van het resultaat eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirExpKtl",
    descriptionShort: "Kosten ten laste van reserve",
    descriptionLong:
      "Kosten ten laste van reserve eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirExpOve",
    descriptionShort: "Overboekingen",
    descriptionLong:
      "Overboekingen eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirExpVal",
    descriptionShort: "Valutaomrekeningsverschillen",
    descriptionLong:
      "Valutaomrekeningsverschillen eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirExpOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirRis",
    descriptionShort: "Risicoreserve",
    descriptionLong:
      "Risicoreserve eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirRisBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirRisDot",
    descriptionShort: "Dotatie",
    descriptionLong: "Dotatie eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirRisAtg",
    descriptionShort: "Afname ten gunste van het resultaat",
    descriptionLong:
      "Afname ten gunste van het resultaat eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirRisKtl",
    descriptionShort: "Kosten ten laste van reserve",
    descriptionLong:
      "Kosten ten laste van reserve eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirRisOve",
    descriptionShort: "Overboekingen",
    descriptionLong:
      "Overboekingen eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirRisVal",
    descriptionShort: "Valutaomrekeningsverschillen",
    descriptionLong:
      "Valutaomrekeningsverschillen eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirRisOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirTer",
    descriptionShort: "Terugkeerreserve",
    descriptionLong:
      "Terugkeerreserve eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirTerBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirTerDot",
    descriptionShort: "Dotatie",
    descriptionLong: "Dotatie eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirTerAaw",
    descriptionShort: "Afname ten gunste van het resultaat",
    descriptionLong:
      "Afname ten gunste van het resultaat eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirTerVri",
    descriptionShort: "Kosten ten laste van reserve",
    descriptionLong:
      "Kosten ten laste van reserve eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirTerOve",
    descriptionShort: "Overboekingen",
    descriptionLong:
      "Overboekingen eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirTerVal",
    descriptionShort: "Valutaomrekeningsverschillen",
    descriptionLong:
      "Valutaomrekeningsverschillen eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirTerOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirOfr",
    descriptionShort: "Overige fiscale reserves",
    descriptionLong:
      "Overige fiscale reserves eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirOfrBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirOfrDot",
    descriptionShort: "Dotatie",
    descriptionLong: "Dotatie eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirOfrAaw",
    descriptionShort: "Afname ten gunste van het resultaat",
    descriptionLong:
      "Afname ten gunste van het resultaat eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirOfrVri",
    descriptionShort: "Kosten ten laste van reserve",
    descriptionLong:
      "Kosten ten laste van reserve eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirOfrOve",
    descriptionShort: "Overboekingen",
    descriptionLong:
      "Overboekingen eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirOfrVal",
    descriptionShort: "Valutaomrekeningsverschillen",
    descriptionLong:
      "Valutaomrekeningsverschillen eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivFirOfrOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties eigen vermogen onderneming natuurlijke personen",
  },
  {
    code: "BEivAvd",
    descriptionShort: "Aandeel van derden",
    descriptionLong: "Aandeel van derden",
  },
  {
    code: "BEivAvdAvd",
    descriptionShort: "Aandeel van derden",
    descriptionLong: "Aandeel van derden aandeel van derden",
  },
  {
    code: "BEga",
    descriptionShort: "Egalisatierekening",
    descriptionLong: "Egalisatierekening",
  },
  {
    code: "BEgaEga",
    descriptionShort: "Egalisatierekening",
    descriptionLong: "Egalisatierekening",
  },
  {
    code: "BEgaEgaEga",
    descriptionShort: "Egalisatierekening",
    descriptionLong: "Egalisatierekening",
  },
  {
    code: "BEgaEgaEgaBeg",
    descriptionShort: "Beginbalans egalisatierekening",
    descriptionLong: "Beginbalans egalisatierekening",
  },
  {
    code: "BEgaEgaEgaDot",
    descriptionShort: "Dotatie egalisatierekening",
    descriptionLong: "Dotatie egalisatierekening",
  },
  {
    code: "BEgaEgaEgaOnt",
    descriptionShort: "Onttrekking egalisatierekening",
    descriptionLong: "Onttrekking egalisatierekening",
  },
  {
    code: "BEgaEgaEgaOvm",
    descriptionShort: "Overige mutaties egalisatierekening",
    descriptionLong: "Overige mutaties egalisatierekening",
  },
  {
    code: "BVrz",
    descriptionShort: "Voorzieningen",
    descriptionLong: "Voorzieningen",
  },
  {
    code: "BVrzVvp",
    descriptionShort: "Voorziening voor pensioenen",
    descriptionLong: "Voorziening voor pensioenen",
  },
  {
    code: "BVrzVvpVpd",
    descriptionShort: "Voorziening voor pensioenen directie in eigen beheer",
    descriptionLong:
      "Voorziening voor pensioenen directie in eigen beheer voorziening voor pensioenen",
  },
  {
    code: "BVrzVvpVpdBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BVrzVvpVpdToe",
    descriptionShort: "Toevoegingen aan voorzieningen",
    descriptionLong: "Toevoegingen aan voorzieningen ",
  },
  {
    code: "BVrzVvpVpdOnt",
    descriptionShort: "Gebruik van voorzieningen",
    descriptionLong: "Gebruik van voorzieningen ",
  },
  {
    code: "BVrzVvpVpdVri",
    descriptionShort: "Vrijval van voorziening",
    descriptionLong: "Vrijval van voorziening ",
  },
  {
    code: "BVrzVvpVpdOmv",
    descriptionShort: "Omrekeningsverschillen over voorzieningen",
    descriptionLong: "Omrekeningsverschillen over voorzieningen ",
  },
  {
    code: "BVrzVvpVpdOev",
    descriptionShort: "Oprenting van voorzieningen",
    descriptionLong: "Oprenting van voorzieningen ",
  },
  {
    code: "BVrzVvpBac",
    descriptionShort: "Backserviceverplichting",
    descriptionLong: "Backserviceverplichting ",
  },
  {
    code: "BVrzVvpBacBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BVrzVvpBacToe",
    descriptionShort: "Toevoegingen aan voorzieningen",
    descriptionLong: "Toevoegingen aan voorzieningen ",
  },
  {
    code: "BVrzVvpBacOnt",
    descriptionShort: "Gebruik van voorzieningen",
    descriptionLong: "Gebruik van voorzieningen ",
  },
  {
    code: "BVrzVvpBacVri",
    descriptionShort: "Vrijval van voorziening",
    descriptionLong: "Vrijval van voorziening ",
  },
  {
    code: "BVrzVvpBacOmv",
    descriptionShort: "Omrekeningsverschillen over voorzieningen",
    descriptionLong: "Omrekeningsverschillen over voorzieningen ",
  },
  {
    code: "BVrzVvpBacOev",
    descriptionShort: "Oprenting van voorzieningen",
    descriptionLong: "Oprenting van voorzieningen ",
  },
  {
    code: "BVrzVvb",
    descriptionShort: "Voorziening voor belastingen",
    descriptionLong: "Voorziening voor belastingen",
  },
  {
    code: "BVrzVvbVlb",
    descriptionShort: "Voorziening voor latente belastingverplichtingen",
    descriptionLong:
      "Voorziening voor latente belastingverplichtingen voorziening voor belastingen",
  },
  {
    code: "BVrzVvbVlbBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BVrzVvbVlbToe",
    descriptionShort: "Toevoegingen aan voorzieningen",
    descriptionLong: "Toevoegingen aan voorzieningen ",
  },
  {
    code: "BVrzVvbVlbOnt",
    descriptionShort: "Gebruik van voorzieningen",
    descriptionLong: "Gebruik van voorzieningen ",
  },
  {
    code: "BVrzVvbVlbVri",
    descriptionShort: "Vrijval van voorziening",
    descriptionLong: "Vrijval van voorziening ",
  },
  {
    code: "BVrzVvbVlbOmv",
    descriptionShort: "Omrekeningsverschillen over voorzieningen",
    descriptionLong: "Omrekeningsverschillen over voorzieningen ",
  },
  {
    code: "BVrzVvbVlbOev",
    descriptionShort: "Oprenting van voorzieningen",
    descriptionLong: "Oprenting van voorzieningen ",
  },
  {
    code: "BVrzVvbVlbOvm",
    descriptionShort:
      "Overige mutaties voorziening latente belastingverplichtingen",
    descriptionLong:
      "Overige mutaties voorziening latente belastingverplichtingen",
  },
  {
    code: "BVrzVvbVvb",
    descriptionShort: "Voorziening voor belastingen",
    descriptionLong:
      "Voorziening voor belastingen voorziening voor belastingen",
  },
  {
    code: "BVrzVvbVvbBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BVrzVvbVvbToe",
    descriptionShort: "Toevoegingen aan voorzieningen",
    descriptionLong: "Toevoegingen aan voorzieningen ",
  },
  {
    code: "BVrzVvbVvbOnt",
    descriptionShort: "Gebruik van voorzieningen",
    descriptionLong: "Gebruik van voorzieningen ",
  },
  {
    code: "BVrzVvbVvbVri",
    descriptionShort: "Vrijval van voorziening",
    descriptionLong: "Vrijval van voorziening ",
  },
  {
    code: "BVrzVvbVvbOmv",
    descriptionShort: "Omrekeningsverschillen over voorzieningen",
    descriptionLong: "Omrekeningsverschillen over voorzieningen ",
  },
  {
    code: "BVrzVvbVvbOev",
    descriptionShort: "Oprenting van voorzieningen",
    descriptionLong: "Oprenting van voorzieningen ",
  },
  {
    code: "BVrzOvz",
    descriptionShort: "Overige voorzieningen",
    descriptionLong: "Overige voorzieningen",
  },
  {
    code: "BVrzOvzVhe",
    descriptionShort: "Voorziening voor herstelkosten",
    descriptionLong: "Voorziening voor herstelkosten overige voorzieningen",
  },
  {
    code: "BVrzOvzVheBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BVrzOvzVheToe",
    descriptionShort: "Toevoegingen aan voorzieningen",
    descriptionLong: "Toevoegingen aan voorzieningen ",
  },
  {
    code: "BVrzOvzVheOnt",
    descriptionShort: "Gebruik van voorzieningen",
    descriptionLong: "Gebruik van voorzieningen ",
  },
  {
    code: "BVrzOvzVheVri",
    descriptionShort: "Vrijval van voorziening",
    descriptionLong: "Vrijval van voorziening ",
  },
  {
    code: "BVrzOvzVheOmv",
    descriptionShort: "Omrekeningsverschillen over voorzieningen",
    descriptionLong: "Omrekeningsverschillen over voorzieningen ",
  },
  {
    code: "BVrzOvzVheOev",
    descriptionShort: "Oprenting van voorzieningen",
    descriptionLong: "Oprenting van voorzieningen ",
  },
  {
    code: "BVrzOvzVvo",
    descriptionShort:
      "Voorziening voor opruiming van aanwezige milieuvervuiling",
    descriptionLong:
      "Voorziening voor opruiming van aanwezige milieuvervuiling ",
  },
  {
    code: "BVrzOvzVvoBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BVrzOvzVvoToe",
    descriptionShort: "Toevoegingen aan voorzieningen",
    descriptionLong: "Toevoegingen aan voorzieningen ",
  },
  {
    code: "BVrzOvzVvoOnt",
    descriptionShort: "Gebruik van voorzieningen",
    descriptionLong: "Gebruik van voorzieningen ",
  },
  {
    code: "BVrzOvzVvoVri",
    descriptionShort: "Vrijval van voorziening",
    descriptionLong: "Vrijval van voorziening ",
  },
  {
    code: "BVrzOvzVvoOmv",
    descriptionShort: "Omrekeningsverschillen over voorzieningen",
    descriptionLong: "Omrekeningsverschillen over voorzieningen ",
  },
  {
    code: "BVrzOvzVvoOev",
    descriptionShort: "Oprenting van voorzieningen",
    descriptionLong: "Oprenting van voorzieningen ",
  },
  {
    code: "BVrzOvzVuc",
    descriptionShort:
      "Voorziening uit hoofde van claims, geschillen en rechtsgedingen",
    descriptionLong:
      "Voorziening uit hoofde van claims, geschillen en rechtsgedingen ",
  },
  {
    code: "BVrzOvzVucBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BVrzOvzVucToe",
    descriptionShort: "Toevoegingen aan voorzieningen",
    descriptionLong: "Toevoegingen aan voorzieningen ",
  },
  {
    code: "BVrzOvzVucOnt",
    descriptionShort: "Gebruik van voorzieningen",
    descriptionLong: "Gebruik van voorzieningen ",
  },
  {
    code: "BVrzOvzVucVri",
    descriptionShort: "Vrijval van voorziening",
    descriptionLong: "Vrijval van voorziening ",
  },
  {
    code: "BVrzOvzVucOmv",
    descriptionShort: "Omrekeningsverschillen over voorzieningen",
    descriptionLong: "Omrekeningsverschillen over voorzieningen ",
  },
  {
    code: "BVrzOvzVucOev",
    descriptionShort: "Oprenting van voorzieningen",
    descriptionLong: "Oprenting van voorzieningen ",
  },
  {
    code: "BVrzOvzVvg",
    descriptionShort: "Voorziening voor groot onderhoud",
    descriptionLong: "Voorziening voor groot onderhoud ",
  },
  {
    code: "BVrzOvzVvgBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BVrzOvzVvgToe",
    descriptionShort: "Toevoegingen aan voorzieningen",
    descriptionLong: "Toevoegingen aan voorzieningen ",
  },
  {
    code: "BVrzOvzVvgOnt",
    descriptionShort: "Gebruik van voorzieningen",
    descriptionLong: "Gebruik van voorzieningen ",
  },
  {
    code: "BVrzOvzVvgVri",
    descriptionShort: "Vrijval van voorziening",
    descriptionLong: "Vrijval van voorziening ",
  },
  {
    code: "BVrzOvzVvgOmv",
    descriptionShort: "Omrekeningsverschillen over voorzieningen",
    descriptionLong: "Omrekeningsverschillen over voorzieningen ",
  },
  {
    code: "BVrzOvzVvgOev",
    descriptionShort: "Oprenting van voorzieningen",
    descriptionLong: "Oprenting van voorzieningen ",
  },
  {
    code: "BVrzOvzVwp",
    descriptionShort: "Voorziening voor verwijderingsverplichtingen",
    descriptionLong: "Voorziening voor verwijderingsverplichtingen ",
  },
  {
    code: "BVrzOvzVwpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BVrzOvzVwpToe",
    descriptionShort: "Toevoegingen aan voorzieningen",
    descriptionLong: "Toevoegingen aan voorzieningen ",
  },
  {
    code: "BVrzOvzVwpOnt",
    descriptionShort: "Gebruik van voorzieningen",
    descriptionLong: "Gebruik van voorzieningen ",
  },
  {
    code: "BVrzOvzVwpVri",
    descriptionShort: "Vrijval van voorziening",
    descriptionLong: "Vrijval van voorziening ",
  },
  {
    code: "BVrzOvzVwpOmv",
    descriptionShort: "Omrekeningsverschillen over voorzieningen",
    descriptionLong: "Omrekeningsverschillen over voorzieningen ",
  },
  {
    code: "BVrzOvzVwpOev",
    descriptionShort: "Oprenting van voorzieningen",
    descriptionLong: "Oprenting van voorzieningen ",
  },
  {
    code: "BVrzOvzVlc",
    descriptionShort: "Voorziening voor verlieslatende contracten",
    descriptionLong: "Voorziening voor verlieslatende contracten ",
  },
  {
    code: "BVrzOvzVlcBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BVrzOvzVlcToe",
    descriptionShort: "Toevoegingen aan voorzieningen",
    descriptionLong: "Toevoegingen aan voorzieningen ",
  },
  {
    code: "BVrzOvzVlcOnt",
    descriptionShort: "Gebruik van voorzieningen",
    descriptionLong: "Gebruik van voorzieningen ",
  },
  {
    code: "BVrzOvzVlcVri",
    descriptionShort: "Vrijval van voorziening",
    descriptionLong: "Vrijval van voorziening ",
  },
  {
    code: "BVrzOvzVlcOmv",
    descriptionShort: "Omrekeningsverschillen over voorzieningen",
    descriptionLong: "Omrekeningsverschillen over voorzieningen ",
  },
  {
    code: "BVrzOvzVlcOev",
    descriptionShort: "Oprenting van voorzieningen",
    descriptionLong: "Oprenting van voorzieningen ",
  },
  {
    code: "BVrzOvzVir",
    descriptionShort: "Voorziening in verband met reorganisaties",
    descriptionLong: "Voorziening in verband met reorganisaties ",
  },
  {
    code: "BVrzOvzVirBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BVrzOvzVirToe",
    descriptionShort: "Toevoegingen aan voorzieningen",
    descriptionLong: "Toevoegingen aan voorzieningen ",
  },
  {
    code: "BVrzOvzVirOnt",
    descriptionShort: "Gebruik van voorzieningen",
    descriptionLong: "Gebruik van voorzieningen ",
  },
  {
    code: "BVrzOvzVirVri",
    descriptionShort: "Vrijval van voorziening",
    descriptionLong: "Vrijval van voorziening ",
  },
  {
    code: "BVrzOvzVirOmv",
    descriptionShort: "Omrekeningsverschillen over voorzieningen",
    descriptionLong: "Omrekeningsverschillen over voorzieningen ",
  },
  {
    code: "BVrzOvzVirOev",
    descriptionShort: "Oprenting van voorzieningen",
    descriptionLong: "Oprenting van voorzieningen ",
  },
  {
    code: "BVrzOvzVid",
    descriptionShort: "Voorziening in verband met deelnemingen",
    descriptionLong: "Voorziening in verband met deelnemingen ",
  },
  {
    code: "BVrzOvzVidBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BVrzOvzVidToe",
    descriptionShort: "Toevoegingen aan voorzieningen",
    descriptionLong: "Toevoegingen aan voorzieningen ",
  },
  {
    code: "BVrzOvzVidOnt",
    descriptionShort: "Gebruik van voorzieningen",
    descriptionLong: "Gebruik van voorzieningen ",
  },
  {
    code: "BVrzOvzVidVri",
    descriptionShort: "Vrijval van voorziening",
    descriptionLong: "Vrijval van voorziening ",
  },
  {
    code: "BVrzOvzVidOmv",
    descriptionShort: "Omrekeningsverschillen over voorzieningen",
    descriptionLong: "Omrekeningsverschillen over voorzieningen ",
  },
  {
    code: "BVrzOvzVidOev",
    descriptionShort: "Oprenting van voorzieningen",
    descriptionLong: "Oprenting van voorzieningen ",
  },
  {
    code: "BVrzOvzVidOvm",
    descriptionShort: "Overige mutatie voorziening deelneming",
    descriptionLong: "Overige mutatie voorziening deelneming",
  },
  {
    code: "BVrzOvzGar",
    descriptionShort: "Garantievoorziening",
    descriptionLong: "Garantievoorziening ",
  },
  {
    code: "BVrzOvzGarBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BVrzOvzGarToe",
    descriptionShort: "Toevoegingen aan voorzieningen",
    descriptionLong: "Toevoegingen aan voorzieningen ",
  },
  {
    code: "BVrzOvzGarOnt",
    descriptionShort: "Gebruik van voorzieningen",
    descriptionLong: "Gebruik van voorzieningen ",
  },
  {
    code: "BVrzOvzGarVri",
    descriptionShort: "Vrijval van voorziening",
    descriptionLong: "Vrijval van voorziening ",
  },
  {
    code: "BVrzOvzGarOmv",
    descriptionShort: "Omrekeningsverschillen over voorzieningen",
    descriptionLong: "Omrekeningsverschillen over voorzieningen ",
  },
  {
    code: "BVrzOvzGarOev",
    descriptionShort: "Oprenting van voorzieningen",
    descriptionLong: "Oprenting van voorzieningen ",
  },
  {
    code: "BVrzOvzJub",
    descriptionShort: "Jubileumvoorziening",
    descriptionLong: "Jubileumvoorziening ",
  },
  {
    code: "BVrzOvzJubBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BVrzOvzJubToe",
    descriptionShort: "Toevoegingen aan voorzieningen",
    descriptionLong: "Toevoegingen aan voorzieningen ",
  },
  {
    code: "BVrzOvzJubOnt",
    descriptionShort: "Gebruik van voorzieningen",
    descriptionLong: "Gebruik van voorzieningen ",
  },
  {
    code: "BVrzOvzJubVri",
    descriptionShort: "Vrijval van voorziening",
    descriptionLong: "Vrijval van voorziening ",
  },
  {
    code: "BVrzOvzJubOmv",
    descriptionShort: "Omrekeningsverschillen over voorzieningen",
    descriptionLong: "Omrekeningsverschillen over voorzieningen ",
  },
  {
    code: "BVrzOvzJubOev",
    descriptionShort: "Oprenting van voorzieningen",
    descriptionLong: "Oprenting van voorzieningen ",
  },
  {
    code: "BVrzOvzArb",
    descriptionShort:
      "Voorziening voor verzekering van arbeidsongeschiktheidsrisico's",
    descriptionLong:
      "Voorziening voor verzekering van arbeidsongeschiktheidsrisico's ",
  },
  {
    code: "BVrzOvzArbBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BVrzOvzArbToe",
    descriptionShort: "Toevoegingen aan voorzieningen",
    descriptionLong: "Toevoegingen aan voorzieningen ",
  },
  {
    code: "BVrzOvzArbOnt",
    descriptionShort: "Gebruik van voorzieningen",
    descriptionLong: "Gebruik van voorzieningen ",
  },
  {
    code: "BVrzOvzArbVri",
    descriptionShort: "Vrijval van voorziening",
    descriptionLong: "Vrijval van voorziening ",
  },
  {
    code: "BVrzOvzArbOmv",
    descriptionShort: "Omrekeningsverschillen over voorzieningen",
    descriptionLong: "Omrekeningsverschillen over voorzieningen ",
  },
  {
    code: "BVrzOvzArbOev",
    descriptionShort: "Oprenting van voorzieningen",
    descriptionLong: "Oprenting van voorzieningen ",
  },
  {
    code: "BVrzOvzOvz",
    descriptionShort: "Overige voorzieningen",
    descriptionLong: "Overige voorzieningen ",
  },
  {
    code: "BVrzOvzOvzBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BVrzOvzOvzToe",
    descriptionShort: "Toevoegingen aan voorzieningen",
    descriptionLong: "Toevoegingen aan voorzieningen ",
  },
  {
    code: "BVrzOvzOvzOnt",
    descriptionShort: "Gebruik van voorzieningen",
    descriptionLong: "Gebruik van voorzieningen ",
  },
  {
    code: "BVrzOvzOvzVri",
    descriptionShort: "Vrijval van voorziening",
    descriptionLong: "Vrijval van voorziening ",
  },
  {
    code: "BVrzOvzOvzOmv",
    descriptionShort: "Omrekeningsverschillen over voorzieningen",
    descriptionLong: "Omrekeningsverschillen over voorzieningen ",
  },
  {
    code: "BVrzOvzOvzOev",
    descriptionShort: "Oprenting van voorzieningen",
    descriptionLong: "Oprenting van voorzieningen ",
  },
  {
    code: "BVrzOvzOvzOvm",
    descriptionShort: "Overige mutaties ",
    descriptionLong: "Overige mutaties ",
  },
  {
    code: "BVrzOvzOio",
    descriptionShort: "Voorziening onroerende zaken in ontwikkeling",
    descriptionLong: "Voorziening onroerende zaken in ontwikkeling",
  },
  {
    code: "BVrzOvzOioBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BVrzOvzOioToe",
    descriptionShort: "Toevoegingen aan voorzieningen",
    descriptionLong: "Toevoegingen aan voorzieningen ",
  },
  {
    code: "BVrzOvzOioOnt",
    descriptionShort: "Onttrekking van voorzieningen",
    descriptionLong: "Onttrekking van voorzieningen",
  },
  {
    code: "BVrzOvzOioVri",
    descriptionShort: "Vrijval van voorziening",
    descriptionLong: "Vrijval van voorziening ",
  },
  {
    code: "BVrzOvzOioOmv",
    descriptionShort: "Omrekeningsverschillen over voorzieningen",
    descriptionLong: "Omrekeningsverschillen over voorzieningen ",
  },
  {
    code: "BVrzOvzOioOev",
    descriptionShort: "Oprenting van voorzieningen",
    descriptionLong: "Oprenting van voorzieningen ",
  },
  {
    code: "BVrzOvzOioOvm",
    descriptionShort: "Overige mutaties ",
    descriptionLong: "Overige mutaties ",
  },
  {
    code: "BVrzOvzOiv",
    descriptionShort:
      "Voorziening onroerende zaken in ontwikkeling voor verkoop",
    descriptionLong:
      "Voorziening onroerende zaken in ontwikkeling voor verkoop",
  },
  {
    code: "BVrzOvzOivBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BVrzOvzOivToe",
    descriptionShort: "Toevoegingen aan voorzieningen",
    descriptionLong: "Toevoegingen aan voorzieningen ",
  },
  {
    code: "BVrzOvzOivOnt",
    descriptionShort: "Onttrekking van voorzieningen",
    descriptionLong: "Onttrekking van voorzieningen",
  },
  {
    code: "BVrzOvzOivVri",
    descriptionShort: "Vrijval van voorziening",
    descriptionLong: "Vrijval van voorziening ",
  },
  {
    code: "BVrzOvzOivOmv",
    descriptionShort: "Omrekeningsverschillen over voorzieningen",
    descriptionLong: "Omrekeningsverschillen over voorzieningen ",
  },
  {
    code: "BVrzOvzOivOev",
    descriptionShort: "Oprenting van voorzieningen",
    descriptionLong: "Oprenting van voorzieningen ",
  },
  {
    code: "BVrzOvzOivOvm",
    descriptionShort: "Overige mutaties ",
    descriptionLong: "Overige mutaties ",
  },
  {
    code: "BVrzOvzLob",
    descriptionShort: "Loopbaan begeleiding voorziening",
    descriptionLong: "Loopbaan begeleiding voorziening",
  },
  {
    code: "BVrzOvzLobBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BVrzOvzLobToe",
    descriptionShort: "Toevoegingen aan voorzieningen",
    descriptionLong: "Toevoegingen aan voorzieningen ",
  },
  {
    code: "BVrzOvzLobOnt",
    descriptionShort: "Onttrekking van voorzieningen",
    descriptionLong: "Onttrekking van voorzieningen",
  },
  {
    code: "BVrzOvzLobVri",
    descriptionShort: "Vrijval van voorziening",
    descriptionLong: "Vrijval van voorziening ",
  },
  {
    code: "BVrzOvzLobOmv",
    descriptionShort: "Omrekeningsverschillen over voorzieningen",
    descriptionLong: "Omrekeningsverschillen over voorzieningen ",
  },
  {
    code: "BVrzOvzLobOev",
    descriptionShort: "Oprenting van voorzieningen",
    descriptionLong: "Oprenting van voorzieningen ",
  },
  {
    code: "BVrzOvzZoa",
    descriptionShort:
      "Voorziening voor verzekering van ziekte of arbeidsongeschiktheid",
    descriptionLong:
      "Voorziening voor verzekering van ziekte of arbeidsongeschiktheid",
  },
  {
    code: "BVrzOvzZoaBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BVrzOvzZoaToe",
    descriptionShort: "Toevoegingen aan voorzieningen",
    descriptionLong: "Toevoegingen aan voorzieningen ",
  },
  {
    code: "BVrzOvzZoaOnt",
    descriptionShort: "Gebruik van voorzieningen",
    descriptionLong: "Gebruik van voorzieningen ",
  },
  {
    code: "BVrzOvzZoaVri",
    descriptionShort: "Vrijval van voorziening",
    descriptionLong: "Vrijval van voorziening ",
  },
  {
    code: "BVrzOvzZoaOmv",
    descriptionShort: "Omrekeningsverschillen over voorzieningen",
    descriptionLong: "Omrekeningsverschillen over voorzieningen ",
  },
  {
    code: "BVrzOvzZoaOev",
    descriptionShort: "Oprenting van voorzieningen",
    descriptionLong: "Oprenting van voorzieningen ",
  },
  {
    code: "BVrzOvzUhp",
    descriptionShort: "Voorziening uit hoofde van personeelsbeloningen",
    descriptionLong: "Voorziening uit hoofde van personeelsbeloningen",
  },
  {
    code: "BVrzOvzUhpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BVrzOvzUhpToe",
    descriptionShort: "Toevoegingen aan voorzieningen",
    descriptionLong: "Toevoegingen aan voorzieningen ",
  },
  {
    code: "BVrzOvzUhpOnt",
    descriptionShort: "Gebruik van voorzieningen",
    descriptionLong: "Gebruik van voorzieningen ",
  },
  {
    code: "BVrzOvzUhpVri",
    descriptionShort: "Vrijval van voorziening",
    descriptionLong: "Vrijval van voorziening ",
  },
  {
    code: "BVrzOvzUhpOmv",
    descriptionShort: "Omrekeningsverschillen over voorzieningen",
    descriptionLong: "Omrekeningsverschillen over voorzieningen ",
  },
  {
    code: "BVrzOvzUhpOev",
    descriptionShort: "Oprenting van voorzieningen",
    descriptionLong: "Oprenting van voorzieningen ",
  },
  {
    code: "BVrzOvzAgb",
    descriptionShort:
      "Voorziening uit hoofde van op aandelen gebaseerde betalingen",
    descriptionLong:
      "Voorziening uit hoofde van op aandelen gebaseerde betalingen",
  },
  {
    code: "BVrzOvzAgbBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BVrzOvzAgbToe",
    descriptionShort: "Toevoegingen aan voorzieningen",
    descriptionLong: "Toevoegingen aan voorzieningen ",
  },
  {
    code: "BVrzOvzAgbOnt",
    descriptionShort: "Gebruik van voorzieningen",
    descriptionLong: "Gebruik van voorzieningen ",
  },
  {
    code: "BVrzOvzAgbVri",
    descriptionShort: "Vrijval van voorziening",
    descriptionLong: "Vrijval van voorziening ",
  },
  {
    code: "BVrzOvzAgbOmv",
    descriptionShort: "Omrekeningsverschillen over voorzieningen",
    descriptionLong: "Omrekeningsverschillen over voorzieningen ",
  },
  {
    code: "BVrzOvzAgbOev",
    descriptionShort: "Oprenting van voorzieningen",
    descriptionLong: "Oprenting van voorzieningen ",
  },
  {
    code: "BVrzOvzVza",
    descriptionShort:
      "Voorziening voor verplichtingen uit hoofde van ziekte of arbeidsongeschiktheid",
    descriptionLong:
      "Voorziening voor verplichtingen uit hoofde van ziekte of arbeidsongeschiktheid",
  },
  {
    code: "BVrzOvzVzaBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BVrzOvzVzaToe",
    descriptionShort: "Toevoegingen aan voorzieningen",
    descriptionLong: "Toevoegingen aan voorzieningen ",
  },
  {
    code: "BVrzOvzVzaOnt",
    descriptionShort: "Gebruik van voorzieningen",
    descriptionLong: "Gebruik van voorzieningen ",
  },
  {
    code: "BVrzOvzVzaVri",
    descriptionShort: "Vrijval van voorziening",
    descriptionLong: "Vrijval van voorziening ",
  },
  {
    code: "BVrzOvzVzaOmv",
    descriptionShort: "Omrekeningsverschillen over voorzieningen",
    descriptionLong: "Omrekeningsverschillen over voorzieningen ",
  },
  {
    code: "BVrzOvzVzaOev",
    descriptionShort: "Oprenting van voorzieningen",
    descriptionLong: "Oprenting van voorzieningen ",
  },
  {
    code: "BVrzOih",
    descriptionShort:
      "Voorziening voor onrendabele investeringen en herstructureringen",
    descriptionLong:
      "Voorziening voor onrendabele investeringen en herstructureringen",
  },
  {
    code: "BVrzOihOrt",
    descriptionShort:
      "Voorziening voor onrendabele investeringen en herstructureringen",
    descriptionLong:
      "Voorziening voor onrendabele investeringen en herstructureringen",
  },
  {
    code: "BVrzOihOrtBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BVrzOihOrtTre",
    descriptionShort:
      "Toevoegingen aan voorzieningen ten laste van het resultaat",
    descriptionLong:
      "Toevoegingen aan voorzieningen ten laste van het resultaat",
  },
  {
    code: "BVrzOihOrtTev",
    descriptionShort:
      "Toevoegingen aan voorzieningen ten laste van het eigen vermogen",
    descriptionLong:
      "Toevoegingen aan voorzieningen ten laste van het eigen vermogen",
  },
  {
    code: "BVrzOihOrtOnt",
    descriptionShort: "Onttrekking van voorzieningen",
    descriptionLong: "Onttrekking van voorzieningen",
  },
  {
    code: "BVrzOihOrtVri",
    descriptionShort: "Vrijval van voorziening",
    descriptionLong: "Vrijval van voorziening ",
  },
  {
    code: "BVrzOihOrtOmv",
    descriptionShort: "Omrekeningsverschillen over voorzieningen",
    descriptionLong: "Omrekeningsverschillen over voorzieningen ",
  },
  {
    code: "BVrzOihOrtOev",
    descriptionShort: "Oprenting van voorzieningen",
    descriptionLong: "Oprenting van voorzieningen ",
  },
  {
    code: "BLas",
    descriptionShort: "Langlopende schulden",
    descriptionLong: "Langlopende schulden",
  },
  {
    code: "BLasAcl",
    descriptionShort: "Achtergestelde schulden",
    descriptionLong: "Achtergestelde schulden (langlopend)",
  },
  {
    code: "BLasAclAll",
    descriptionShort: "Hoofdsom achtergestelde schulden",
    descriptionLong: "Hoofdsom achtergestelde schulden (langlopend)",
  },
  {
    code: "BLasAclAllBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) achtergestelde schulden",
  },
  {
    code: "BLasAclAllToe",
    descriptionShort: "Aanvullend opgenomen / nieuwe financiering",
    descriptionLong: "Aanvullend opgenomen achtergestelde schulden",
  },
  {
    code: "BLasAclAllOvs",
    descriptionShort: "Bij overname verkregen schulden",
    descriptionLong: "Bij overname verkregen schulden achtergestelde schulden",
  },
  {
    code: "BLasAclAllAvs",
    descriptionShort: "Bij afstoting vervreemde schulden",
    descriptionLong:
      "Bij afstoting vervreemde schulden achtergestelde schulden",
  },
  {
    code: "BLasAclAllBir",
    descriptionShort: "Bijschrijving rente / oprenting",
    descriptionLong: "Bijschrijving rente achtergestelde schulden",
  },
  {
    code: "BLasAclAllOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen achtergestelde schulden",
  },
  {
    code: "BLasAclAllOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties achtergestelde schulden",
  },
  {
    code: "BLasAclAllOwv",
    descriptionShort: "Overige waardeveranderingen",
    descriptionLong: "Overige waardeveranderingen achtergestelde schulden",
  },
  {
    code: "BLasAclCla",
    descriptionShort: "Cumulatieve aflossingen achtergestelde schulden",
    descriptionLong:
      "Cumulatieve aflossingen achtergestelde schulden (langlopend)",
  },
  {
    code: "BLasAclClaBeg",
    descriptionShort: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Aflossingen beginbalans (overname eindsaldo vorig jaar) achtergestelde schulden",
  },
  {
    code: "BLasAclClaAfl",
    descriptionShort: "Aflossingen in boekjaar achtergestelde schulden",
    descriptionLong: "Aflossingen in boekjaar achtergestelde schulden",
  },
  {
    code: "BLasAclClaAvp",
    descriptionShort:
      "Aflossingsverplichting (overboeking naar kortlopend) achtergestelde schulden",
    descriptionLong:
      "Aflossingsverplichting (overboeking naar kortlopend) achtergestelde schulden",
  },
  {
    code: "BLasCol",
    descriptionShort: "Converteerbare leningen",
    descriptionLong: "Converteerbare leningen (langlopend)",
  },
  {
    code: "BLasColCll",
    descriptionShort: "Hoofdsom converteerbare leningen",
    descriptionLong: "Hoofdsom converteerbare leningen (langlopend)",
  },
  {
    code: "BLasColCllBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) converteerbare leningen",
  },
  {
    code: "BLasColCllToe",
    descriptionShort: "Aanvullend opgenomen / nieuwe financiering",
    descriptionLong: "Aanvullend opgenomen converteerbare leningen",
  },
  {
    code: "BLasColCllOvs",
    descriptionShort: "Bij overname verkregen schulden",
    descriptionLong: "Bij overname verkregen schulden",
  },
  {
    code: "BLasColCllAvs",
    descriptionShort: "Bij afstoting vervreemde schulden",
    descriptionLong: "Bij afstoting vervreemde schulden",
  },
  {
    code: "BLasColCllBir",
    descriptionShort: "Bijschrijving rente / oprenting",
    descriptionLong: "Bijschrijving rente converteerbare leningen",
  },
  {
    code: "BLasColCllOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen converteerbare leningen",
  },
  {
    code: "BLasColCllOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties converteerbare leningen",
  },
  {
    code: "BLasColCllOwv",
    descriptionShort: "Overige waardeveranderingen",
    descriptionLong: "Overige waardeveranderingen",
  },
  {
    code: "BLasColCla",
    descriptionShort: "Cumulatieve aflossingen converteerbare leningen",
    descriptionLong:
      "Cumulatieve aflossingen converteerbare leningen (langlopend)",
  },
  {
    code: "BLasColClaBeg",
    descriptionShort: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
  },
  {
    code: "BLasColClaAfl",
    descriptionShort: "Aflossingen in boekjaar converteerbare leningen",
    descriptionLong:
      "Aflossingen in boekjaar converteerbare leningen (langlopend)",
  },
  {
    code: "BLasColClaAvp",
    descriptionShort:
      "Aflossingsverplichting (overboeking naar kortlopend) converteerbare leningen",
    descriptionLong:
      "Aflossingsverplichting (overboeking naar kortlopend) converteerbare leningen (langlopend)",
  },
  {
    code: "BLasAoe",
    descriptionShort: "Obligatieleningen, pandbrieven en andere leningen",
    descriptionLong:
      "Obligatieleningen, pandbrieven en andere leningen (langlopend)",
  },
  {
    code: "BLasAoeAol",
    descriptionShort: "Hoofdsom andere obligaties en onderhandse leningen",
    descriptionLong:
      "Hoofdsom andere obligaties en onderhandse leningen (langlopend)",
  },
  {
    code: "BLasAoeAolBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) andere obligaties en onderhandse leningen",
  },
  {
    code: "BLasAoeAolToe",
    descriptionShort: "Aanvullend opgenomen",
    descriptionLong:
      "Aanvullend opgenomen andere obligaties en onderhandse leningen",
  },
  {
    code: "BLasAoeAolOvs",
    descriptionShort: "Bij overname verkregen schulden",
    descriptionLong: "Bij overname verkregen schulden",
  },
  {
    code: "BLasAoeAolAvs",
    descriptionShort: "Bij afstoting vervreemde schulden",
    descriptionLong: "Bij afstoting vervreemde schulden",
  },
  {
    code: "BLasAoeAolBir",
    descriptionShort: "Bijschrijving rente / oprenting",
    descriptionLong:
      "Bijschrijving rente / oprenting andere obligaties en onderhandse leningen",
  },
  {
    code: "BLasAoeAolOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong:
      "Omrekeningsverschillen andere obligaties en onderhandse leningen",
  },
  {
    code: "BLasAoeAolOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties andere obligaties en onderhandse leningen",
  },
  {
    code: "BLasAoeAolOwv",
    descriptionShort: "Overige waardeveranderingen",
    descriptionLong: "Overige waardeveranderingen",
  },
  {
    code: "BLasAoeCla",
    descriptionShort:
      "Cumulatieve aflossingen andere obligaties en onderhandse leningen",
    descriptionLong:
      "Cumulatieve aflossingen andere obligaties en onderhandse leningen (langlopend)",
  },
  {
    code: "BLasAoeClaBeg",
    descriptionShort: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
  },
  {
    code: "BLasAoeClaAfl",
    descriptionShort:
      "Aflossingen in boekjaar andere obligaties en onderhandse leningen",
    descriptionLong:
      "Aflossingen in boekjaar andere obligaties en onderhandse leningen (langlopend)",
  },
  {
    code: "BLasAoeClaAvp",
    descriptionShort:
      "Aflossingsverplichting (overboeking naar kortlopend) andere obligaties en onderhandse leningen",
    descriptionLong:
      "Aflossingsverplichting (overboeking naar kortlopend) andere obligaties en onderhandse leningen (langlopend)",
  },
  {
    code: "BLasFlv",
    descriptionShort: "Financiële lease verplichtingen",
    descriptionLong: "Financiële lease verplichtingen (langlopend)",
  },
  {
    code: "BLasFlvFlv",
    descriptionShort: "Hoofdsom financiële lease verplichtingen",
    descriptionLong: "Hoofdsom financiële lease verplichtingen (langlopend)",
  },
  {
    code: "BLasFlvFlvBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) financiële lease verplichtingen",
  },
  {
    code: "BLasFlvFlvToe",
    descriptionShort: "Aanvullend opgenomen",
    descriptionLong: "Aanvullend opgenomen financiële lease verplichtingen",
  },
  {
    code: "BLasFlvFlvOvs",
    descriptionShort: "Bij overname verkregen schulden",
    descriptionLong:
      "Bij overname verkregen schulden financiële lease verplichtingen",
  },
  {
    code: "BLasFlvFlvAvs",
    descriptionShort: "Bij afstoting vervreemde schulden",
    descriptionLong:
      "Bij afstoting vervreemde schulden financiële lease verplichtingen",
  },
  {
    code: "BLasFlvFlvBir",
    descriptionShort: "Bijschrijving rente / oprenting",
    descriptionLong:
      "Bijschrijving rente / oprenting financiële lease verplichtingen",
  },
  {
    code: "BLasFlvFlvOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen financiële lease verplichtingen",
  },
  {
    code: "BLasFlvFlvOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties financiële lease verplichtingen",
  },
  {
    code: "BLasFlvFlvOwv",
    descriptionShort: "Overige waardeveranderingen",
    descriptionLong:
      "Overige waardeveranderingen financiële lease verplichtingen",
  },
  {
    code: "BLasFlvCla",
    descriptionShort: "Cumulatieve aflossingen financiële lease verplichtingen",
    descriptionLong:
      "Cumulatieve aflossingen financiële lease verplichtingen (langlopend)",
  },
  {
    code: "BLasFlvClaBeg",
    descriptionShort: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
  },
  {
    code: "BLasFlvClaAfl",
    descriptionShort: "Aflossingen in boekjaar financiële lease verplichtingen",
    descriptionLong:
      "Aflossingen in boekjaar financiële lease verplichtingen (langlopend)",
  },
  {
    code: "BLasFlvClaAvp",
    descriptionShort:
      "Aflossingsverplichting (overboeking naar kortlopend) financiële lease verplichtingen",
    descriptionLong:
      "Aflossingsverplichting (overboeking naar kortlopend) financiële lease verplichtingen (langlopend)",
  },
  {
    code: "BLasSak",
    descriptionShort: "Schulden aan banken",
    descriptionLong: "Schulden aan banken (langlopend)",
  },
  {
    code: "BLasSakHvl",
    descriptionShort: "Hoofdsom hypotheken",
    descriptionLong: "Hoofdsom hypotheken van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakHvlBeg",
    descriptionShort:
      "Beginbalans hypotheken van kredietinstellingen (langlopend)",
    descriptionLong:
      "Beginbalans hypotheken van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakHvlToe",
    descriptionShort: "Toename hypotheken van kredietinstellingen (langlopend)",
    descriptionLong: "Toename hypotheken van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakHvlOvs",
    descriptionShort: "Bij overname verkregen schulden",
    descriptionLong:
      "Bij overname verkregen schulden hypotheken van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakHvlAvs",
    descriptionShort: "Bij afstoting vervreemde schulden",
    descriptionLong:
      "Bij afstoting vervreemde schulden hypotheken van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakHvlBir",
    descriptionShort: "Bijschrijving rente / oprenting",
    descriptionLong:
      "Bijschrijving rente / oprenting hypotheken van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakHvlOmv",
    descriptionShort:
      "Omrekeningsverschillen hypotheken van kredietinstellingen (langlopend)",
    descriptionLong:
      "Omrekeningsverschillen hypotheken van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakHvlOvm",
    descriptionShort:
      "Overige mutaties hypotheken van kredietinstellingen (langlopend)",
    descriptionLong:
      "Overige mutaties hypotheken van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakHvlOwv",
    descriptionShort: "Overige waardeveranderingen",
    descriptionLong:
      "Overige waardeveranderingen hypotheken van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakCla",
    descriptionShort: "Cumulatieve aflossingen hypotheken",
    descriptionLong:
      "Cumulatieve aflossingen hypotheken van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakClaBeg",
    descriptionShort: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
  },
  {
    code: "BLasSakClaAfl",
    descriptionShort: "Aflossingen in boekjaar hypotheken",
    descriptionLong:
      "Aflossingen in boekjaar hypotheken van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakClaAvp",
    descriptionShort:
      "Aflossingsverplichting (overboeking naar kortlopend) hypotheken",
    descriptionLong:
      "Aflossingsverplichting (overboeking naar kortlopend) hypotheken van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakFvl",
    descriptionShort: "Hoofdsom financieringen",
    descriptionLong:
      "Hoofdsom financieringen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakFvlBeg",
    descriptionShort:
      "Beginbalans financieringen van kredietinstellingen (langlopend)",
    descriptionLong:
      "Beginbalans financieringen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakFvlToe",
    descriptionShort:
      "Toename financieringen van kredietinstellingen (langlopend)",
    descriptionLong:
      "Toename financieringen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakFvlOvs",
    descriptionShort: "Bij overname verkregen schulden",
    descriptionLong:
      "Bij overname verkregen schulden financieringen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakFvlAvs",
    descriptionShort: "Bij afstoting vervreemde schulden",
    descriptionLong:
      "Bij afstoting vervreemde schulden financieringen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakFvlBir",
    descriptionShort: "Bijschrijving rente / oprenting",
    descriptionLong:
      "Bijschrijving rente / oprenting financieringen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakFvlOmv",
    descriptionShort:
      "Omrekeningsverschillen financieringen van kredietinstellingen (langlopend)",
    descriptionLong:
      "Omrekeningsverschillen financieringen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakFvlOvm",
    descriptionShort:
      "Overige mutaties financieringen van kredietinstellingen (langlopend)",
    descriptionLong:
      "Overige mutaties financieringen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakFvlOwv",
    descriptionShort: "Overige waardeveranderingen",
    descriptionLong:
      "Overige waardeveranderingen hypotheken van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakFca",
    descriptionShort: "Cumulatieve aflossingen financieringen",
    descriptionLong:
      "Cumulatieve aflossingen financieringen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakFcaBeg",
    descriptionShort: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
  },
  {
    code: "BLasSakFcaAfl",
    descriptionShort: "Aflossingen in boekjaar financieringen",
    descriptionLong:
      "Aflossingen in boekjaar financieringen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakFcaAvp",
    descriptionShort:
      "Aflossingsverplichting (overboeking naar kortlopend) financieringen",
    descriptionLong:
      "Aflossingsverplichting (overboeking naar kortlopend) financieringen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakLvl",
    descriptionShort: "Hoodsom leningen",
    descriptionLong: "Hoofdsom leningen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakLvlBeg",
    descriptionShort:
      "Beginbalans leningen van kredietinstellingen (langlopend)",
    descriptionLong:
      "Beginbalans leningen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakLvlToe",
    descriptionShort: "Toename leningen van kredietinstellingen (langlopend)",
    descriptionLong: "Toename leningen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakLvlOvs",
    descriptionShort: "Bij overname verkregen schulden",
    descriptionLong:
      "Bij overname verkregen schulden financieringen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakLvlAvs",
    descriptionShort: "Bij afstoting vervreemde schulden",
    descriptionLong:
      "Bij afstoting vervreemde schulden financieringen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakLvlBir",
    descriptionShort: "Bijschrijving rente / oprenting",
    descriptionLong:
      "Bijschrijving rente / oprenting financieringen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakLvlOmv",
    descriptionShort:
      "Omrekeningsverschillen leningen van kredietinstellingen (langlopend)",
    descriptionLong:
      "Omrekeningsverschillen leningen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakLvlOvm",
    descriptionShort:
      "Overige mutaties leningen van kredietinstellingen (langlopend)",
    descriptionLong:
      "Overige mutaties leningen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakLvlOwv",
    descriptionShort: "Overige waardeveranderingen",
    descriptionLong:
      "Overige waardeveranderingen hypotheken van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakLvlMvl",
    descriptionShort: "Marktwaardecorrectie van de vastrentende lening ",
    descriptionLong: "Marktwaardecorrectie van de vastrentende lening ",
  },
  {
    code: "BLasSakLca",
    descriptionShort: "Cumulatieve aflossingen leningen",
    descriptionLong:
      "Cumulatieve aflossingen leningen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakLcaBeg",
    descriptionShort: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
  },
  {
    code: "BLasSakLcaAfl",
    descriptionShort: "Aflossingen in boekjaar leningen",
    descriptionLong:
      "Aflossingen in boekjaar leningen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakLcaAvp",
    descriptionShort:
      "Aflossingsverplichting (overboeking naar kortlopend) leningen",
    descriptionLong:
      "Aflossingsverplichting (overboeking naar kortlopend) leningen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakLcaMvl",
    descriptionShort:
      "Aflossingsverplichting marktwaardecorrectie vastrentende lening (langlopend)",
    descriptionLong:
      "Aflossingsverplichting marktwaardecorrectie vastrentende lening (langlopend)",
  },
  {
    code: "BLasSakOvl",
    descriptionShort: "Hoofdsom overige schulden aan kredietinstellingen",
    descriptionLong:
      "Hoofdsom overige schulden aan kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakOvlBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) schulden aan kredietinstellingen",
  },
  {
    code: "BLasSakOvlToe",
    descriptionShort: "Aanvullend opgenomen",
    descriptionLong: "Aanvullend opgenomen schulden aan kredietinstellingen",
  },
  {
    code: "BLasSakOvlOvs",
    descriptionShort: "Bij overname verkregen schulden",
    descriptionLong:
      "Bij overname verkregen schulden financieringen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakOvlAvs",
    descriptionShort: "Bij afstoting vervreemde schulden",
    descriptionLong:
      "Bij afstoting vervreemde schulden financieringen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakOvlBir",
    descriptionShort: "Bijschrijving rente / oprenting",
    descriptionLong:
      "Bijschrijving rente / oprenting financieringen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakOvlOmv",
    descriptionShort:
      "Omrekeningsverschillen leningen van kredietinstellingen (langlopend)",
    descriptionLong:
      "Omrekeningsverschillen leningen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakOvlOvm",
    descriptionShort:
      "Overige mutaties leningen van kredietinstellingen (langlopend)",
    descriptionLong:
      "Overige mutaties leningen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakOvlOwv",
    descriptionShort: "Overige waardeveranderingen",
    descriptionLong:
      "Overige waardeveranderingen hypotheken van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakOca",
    descriptionShort:
      "Cumulatieve aflossingen overige schulden aan kredietinstellingen",
    descriptionLong:
      "Cumulatieve aflossingen overige schulden aan kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakOcaBeg",
    descriptionShort: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
  },
  {
    code: "BLasSakOcaAfl",
    descriptionShort:
      "Aflossingen in boekjaar overige schulden aan kredietinstellingen",
    descriptionLong:
      "Aflossingen in boekjaar overige schulden van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakOcaAvp",
    descriptionShort:
      "Aflossingsverplichting (overboeking naar kortlopend) overige schulden aan kredietinstellingen",
    descriptionLong:
      "Aflossingsverplichting (overboeking naar kortlopend) overige schulden aan kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakWsl",
    descriptionShort:
      "Hoofdsom schulden van kredietinstellingen geborgd door WSW",
    descriptionLong:
      "Hoofdsom schulden van kredietinstellingen geborgd door WSW (langlopend)",
  },
  {
    code: "BLasSakWslBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) schulden van kredietinstellingen geborgd door WSW ",
  },
  {
    code: "BLasSakWslToe",
    descriptionShort: "Toename leningen van kredietinstellingen (langlopend)",
    descriptionLong: "Toename leningen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakWslOvs",
    descriptionShort: "Bij overname verkregen schulden",
    descriptionLong:
      "Bij overname verkregen schulden financieringen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakWslAvs",
    descriptionShort: "Bij afstoting vervreemde schulden",
    descriptionLong:
      "Bij afstoting vervreemde schulden financieringen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakWslBir",
    descriptionShort: "Bijschrijving rente / oprenting",
    descriptionLong:
      "Bijschrijving rente / oprenting financieringen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakWslOmv",
    descriptionShort:
      "Omrekeningsverschillen leningen van kredietinstellingen (langlopend)",
    descriptionLong:
      "Omrekeningsverschillen leningen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakWslOvm",
    descriptionShort:
      "Overige mutaties leningen van kredietinstellingen (langlopend)",
    descriptionLong:
      "Overige mutaties leningen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakWslOwv",
    descriptionShort: "Overige waardeveranderingen",
    descriptionLong:
      "Overige waardeveranderingen schulden van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakWslMvl",
    descriptionShort: "Marktwaardecorrectie van de vastrentende lening ",
    descriptionLong: "Marktwaardecorrectie van de vastrentende lening ",
  },
  {
    code: "BLasSakWsa",
    descriptionShort:
      "Cumulatieve aflossingen schulden van kredietinstellingen geborgd door WSW",
    descriptionLong:
      "Cumulatieve aflossingen schulden van kredietinstellingen geborgd door WSW (langlopend)",
  },
  {
    code: "BLasSakWsaBeg",
    descriptionShort: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
  },
  {
    code: "BLasSakWsaAfl",
    descriptionShort: "Aflossingen in boekjaar geborgd door WSW",
    descriptionLong:
      "Aflossingen in boekjaar leningen van kredietinstellingen geborgd door WSW (langlopend)",
  },
  {
    code: "BLasSakWsaAvp",
    descriptionShort:
      "Aflossingsverplichting (overboeking naar kortlopend) geborgd door WSW",
    descriptionLong:
      "Aflossingsverplichting (overboeking naar kortlopend) schulden van kredietinstellingen geborgd door WSW (langlopend)",
  },
  {
    code: "BLasSakWsaMvl",
    descriptionShort:
      "Aflossingsverplichting marktwaardecorrectie vastrentende lening",
    descriptionLong:
      "Aflossingsverplichting marktwaardecorrectie vastrentende lening  geborgd door WSW (langlopend)",
  },
  {
    code: "BLasSakGol",
    descriptionShort:
      "Hoofdsom schulden van kredietinstellingen gegarandeerd door overheden",
    descriptionLong:
      "Hoofdsom schulden van kredietinstellingen gegarandeerd door overheden (langlopend)",
  },
  {
    code: "BLasSakGolBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) schulden van kredietinstellingen gegarandeerd door overheden",
  },
  {
    code: "BLasSakGolToe",
    descriptionShort: "Toename leningen van kredietinstellingen (langlopend)",
    descriptionLong: "Toename leningen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakGolOvs",
    descriptionShort: "Bij overname verkregen schulden",
    descriptionLong:
      "Bij overname verkregen schulden financieringen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakGolAvs",
    descriptionShort: "Bij afstoting vervreemde schulden",
    descriptionLong:
      "Bij afstoting vervreemde schulden financieringen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakGolBir",
    descriptionShort: "Bijschrijving rente / oprenting",
    descriptionLong:
      "Bijschrijving rente / oprenting financieringen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakGolOmv",
    descriptionShort:
      "Omrekeningsverschillen leningen van kredietinstellingen (langlopend)",
    descriptionLong:
      "Omrekeningsverschillen leningen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakGolOvm",
    descriptionShort:
      "Overige mutaties leningen van kredietinstellingen (langlopend)",
    descriptionLong:
      "Overige mutaties leningen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakGolOwv",
    descriptionShort: "Overige waardeveranderingen",
    descriptionLong:
      "Overige waardeveranderingen schulden van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakGolMvl",
    descriptionShort: "Marktwaardecorrectie van de vastrentende lening ",
    descriptionLong: "Marktwaardecorrectie van de vastrentende lening ",
  },
  {
    code: "BLasSakGoa",
    descriptionShort:
      "Cumulatieve aflossingen schulden van kredietinstellingen gegarandeerd door overheden",
    descriptionLong:
      "Cumulatieve aflossingen leningen van kredietinstellingen gegarandeerd door overheden (langlopend)",
  },
  {
    code: "BLasSakGoaBeg",
    descriptionShort: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
  },
  {
    code: "BLasSakGoaAfl",
    descriptionShort: "Aflossingen in boekjaar gegarandeerd door overheden",
    descriptionLong:
      "Aflossingen in boekjaar leningen van kredietinstellingen  gegarandeerd door overheden (langlopend)",
  },
  {
    code: "BLasSakGoaAvp",
    descriptionShort:
      "Aflossingsverplichting (overboeking naar kortlopend) gegarandeerd door overheden",
    descriptionLong:
      "Aflossingsverplichting (overboeking naar kortlopend) schulden van kredietinstellingen gegarandeerd door overheden (langlopend)",
  },
  {
    code: "BLasSakGoaMvl",
    descriptionShort:
      "Aflossingsverplichting marktwaardecorrectie vastrentende lening (langlopend)",
    descriptionLong:
      "Aflossingsverplichting marktwaardecorrectie vastrentende lening (langlopend)",
  },
  {
    code: "BLasSakObl",
    descriptionShort:
      "Hoofdsom obligolening van kredietinstellingen geborgd door WSW",
    descriptionLong:
      "Hoofdsom obligolening van kredietinstellingen geborgd door WSW (langlopend)",
  },
  {
    code: "BLasSakOblBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) schulden van kredietinstellingen geborgd door WSW ",
  },
  {
    code: "BLasSakOblToe",
    descriptionShort: "Toename leningen van kredietinstellingen (langlopend)",
    descriptionLong: "Toename leningen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakOblOvs",
    descriptionShort: "Bij overname verkregen schulden",
    descriptionLong:
      "Bij overname verkregen schulden financieringen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakOblAvs",
    descriptionShort: "Bij afstoting vervreemde schulden",
    descriptionLong:
      "Bij afstoting vervreemde schulden financieringen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakOblBir",
    descriptionShort: "Bijschrijving rente / oprenting",
    descriptionLong:
      "Bijschrijving rente / oprenting financieringen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakOblOmv",
    descriptionShort:
      "Omrekeningsverschillen leningen van kredietinstellingen (langlopend)",
    descriptionLong:
      "Omrekeningsverschillen leningen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakOblOvm",
    descriptionShort:
      "Overige mutaties leningen van kredietinstellingen (langlopend)",
    descriptionLong:
      "Overige mutaties leningen van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakOblOwv",
    descriptionShort: "Overige waardeveranderingen",
    descriptionLong:
      "Overige waardeveranderingen schulden van kredietinstellingen (langlopend)",
  },
  {
    code: "BLasSakOblMvl",
    descriptionShort: "Marktwaardecorrectie van de vastrentende lening ",
    descriptionLong: "Marktwaardecorrectie van de vastrentende lening ",
  },
  {
    code: "BLasSakOba",
    descriptionShort:
      "Cumulatieve aflossingen obligolening van kredietinstellingen geborgd door WSW",
    descriptionLong:
      "Cumulatieve aflossingen obligolening van kredietinstellingen geborgd door WSW (langlopend)",
  },
  {
    code: "BLasSakObaBeg",
    descriptionShort: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
  },
  {
    code: "BLasSakObaAfl",
    descriptionShort: "Aflossingen in boekjaar geborgd door WSW",
    descriptionLong:
      "Aflossingen in boekjaar leningen van kredietinstellingen geborgd door WSW (langlopend)",
  },
  {
    code: "BLasSakObaAvp",
    descriptionShort:
      "Aflossingsverplichting (overboeking naar kortlopend) geborgd door WSW",
    descriptionLong:
      "Aflossingsverplichting (overboeking naar kortlopend) schulden van kredietinstellingen geborgd door WSW (langlopend)",
  },
  {
    code: "BLasSakObaMvl",
    descriptionShort:
      "Aflossingsverplichting marktwaardecorrectie vastrentende lening",
    descriptionLong:
      "Aflossingsverplichting marktwaardecorrectie vastrentende lening  geborgd door WSW (langlopend)",
  },
  {
    code: "BLasVob",
    descriptionShort: "Ontvangen vooruitbetalingen op bestellingen",
    descriptionLong: "Ontvangen vooruitbetalingen op bestellingen (langlopend)",
  },
  {
    code: "BLasVobVob",
    descriptionShort: "Hoofdsom ontvangen vooruitbetalingen op bestellingen",
    descriptionLong:
      "Hoofdsom ontvangen vooruitbetalingen op bestellingen (langlopend)",
  },
  {
    code: "BLasVobVobBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) ontvangen vooruitbetalingen op bestellingen",
  },
  {
    code: "BLasVobVobToe",
    descriptionShort: "Toename",
    descriptionLong:
      "Toename ontvangen vooruitbetalingen op bestellingen (langlopend)",
  },
  {
    code: "BLasVobVobBir",
    descriptionShort: "Bijschrijving rente / oprenting",
    descriptionLong:
      "Bijschrijving rente / oprenting ontvangen vooruitbetalingen op bestellingen (langlopend)",
  },
  {
    code: "BLasVobVobOwv",
    descriptionShort: "Overige waardeveranderingen",
    descriptionLong:
      "Overige waardeveranderingen ontvangen vooruitbetalingen op bestellingen (langlopend)",
  },
  {
    code: "BLasVobVoc",
    descriptionShort:
      "Cumulatieve aflossingen ontvangen vooruitbetalingen op bestellingen",
    descriptionLong:
      "Cumulatieve aflossingen ontvangen vooruitbetalingen op bestellingen (langlopend)",
  },
  {
    code: "BLasVobVocBeg",
    descriptionShort: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) cumulatieve aflossingen vooruitbetalingen op bestellingen",
  },
  {
    code: "BLasVobVocAfl",
    descriptionShort: "Afname in boekjaar vooruitontvangen op bestellingen",
    descriptionLong:
      "Afname in boekjaar vooruitbetalingen op bestellingen (langlopend)",
  },
  {
    code: "BLasVobVocAvp",
    descriptionShort:
      "Afname (overboeking naar kortlopend) vooruit ontvangen op bestellingen",
    descriptionLong:
      "Afname (overboeking naar kortlopend) vooruitbetalingen op bestellingen",
  },
  {
    code: "BLasSal",
    descriptionShort: "Schulden aan leveranciers en handelskredieten",
    descriptionLong:
      "Schulden aan leveranciers en handelskredieten (langlopend)",
  },
  {
    code: "BLasSalSal",
    descriptionShort: "Hoofdsom schulden aan leveranciers en handelskredieten",
    descriptionLong:
      "Hoofdsom schulden aan leveranciers en handelskredieten (langlopend)",
  },
  {
    code: "BLasSalSalBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) schulden aan leveranciers en handelskredieten",
  },
  {
    code: "BLasSalSalToe",
    descriptionShort: "Toename",
    descriptionLong:
      "Toename schulden aan leveranciers en handelskredieten (langlopend)",
  },
  {
    code: "BLasSalSalBir",
    descriptionShort: "Bijschrijving rente / oprenting",
    descriptionLong:
      "Bijschrijving rente / oprenting schulden aan leveranciers en handelskredieten (langlopend)",
  },
  {
    code: "BLasSalSalOwv",
    descriptionShort: "Overige waardeveranderingen",
    descriptionLong:
      "Overige waardeveranderingen schulden aan leveranciers en handelskredieten (langlopend)",
  },
  {
    code: "BLasSalSac",
    descriptionShort:
      "Cumulatieve aflossingen schulden aan leveranciers en handelskredieten",
    descriptionLong:
      "Cumulatieve aflossingen schulden aan leveranciers en handelskredieten (langlopend)",
  },
  {
    code: "BLasSalSacBeg",
    descriptionShort: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Aflossingen beginbalans (overname eindsaldo vorig jaar) schulden aan leveranciers en handelskredieten",
  },
  {
    code: "BLasSalSacAfl",
    descriptionShort: "Afname in boekjaar leveranciers en handelskredieten",
    descriptionLong:
      "Afname in boekjaar schulden aan leveranciers en handelskredieten (langlopend)",
  },
  {
    code: "BLasSalSacAvp",
    descriptionShort:
      "Afname (overboeking naar kortlopend) leveranciers en handelskredieten",
    descriptionLong:
      "Afname (overboeking naar kortlopend) schulden aan leveranciers en handelskredieten",
  },
  {
    code: "BLasTbw",
    descriptionShort: "Te betalen wissels en cheques",
    descriptionLong: "Te betalen wissels en cheques (langlopend)",
  },
  {
    code: "BLasTbwTbw",
    descriptionShort: "Hoofdsom te betalen wissels en cheques",
    descriptionLong: "Hoofdsom te betalen wissels en cheques (langlopend)",
  },
  {
    code: "BLasTbwTbwBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) te betalen wissels en cheques",
  },
  {
    code: "BLasTbwTbwToe",
    descriptionShort: "Toename",
    descriptionLong: "Toename te betalen wissels en cheques (langlopend)",
  },
  {
    code: "BLasTbwTbwBir",
    descriptionShort: "Bijschrijving rente / oprenting",
    descriptionLong:
      "Bijschrijving rente / oprenting te betalen wissels en cheques (langlopend)",
  },
  {
    code: "BLasTbwTbwOwv",
    descriptionShort: "Overige waardeveranderingen",
    descriptionLong:
      "Overige waardeveranderingen te betalen wissels en cheques (langlopend)",
  },
  {
    code: "BLasTbwTbc",
    descriptionShort: "Cumulatieve aflossingen te betalen wissels en cheques",
    descriptionLong:
      "Cumulatieve aflossingen te betalen wissels en cheques (langlopend)",
  },
  {
    code: "BLasTbwTbcBeg",
    descriptionShort: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Aflossingen beginbalans (overname eindsaldo vorig jaar) te betalen wissels en cheques",
  },
  {
    code: "BLasTbwTbcAfl",
    descriptionShort: "Afname in boekjaar wissels en cheques",
    descriptionLong:
      "Afname in boekjaar te betalen wissels en cheques (langlopend)",
  },
  {
    code: "BLasTbwTbcAvp",
    descriptionShort: "Afname (overboeking naar kortlopend) wissels en cheques",
    descriptionLong:
      "Afname (overboeking naar kortlopend) te betalen wissels en cheques (langlopend)",
  },
  {
    code: "BLasSag",
    descriptionShort: "Schulden aan groepsmaatschappijen",
    descriptionLong: "Schulden aan groepsmaatschappijen (langlopend)",
  },
  {
    code: "BLasSagHoo",
    descriptionShort: "Hoofdsom schulden aan groepsmaatschappijen",
    descriptionLong: "Hoofdsom schulden aan groepsmaatschappijen (langlopend)",
  },
  {
    code: "BLasSagHooBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) schulden aan groepsmaatschappijen",
  },
  {
    code: "BLasSagHooAao",
    descriptionShort: "Aanvullend opgenomen / nieuwe financiering",
    descriptionLong: "Aanvullend opgenomen schulden aan groepsmaatschappijen",
  },
  {
    code: "BLasSagHooBir",
    descriptionShort: "Bijschrijving oprenting /amorisatie",
    descriptionLong: "Bijschrijving rente schulden aan groepsmaatschappijen",
  },
  {
    code: "BLasSagHooOmr",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen schulden aan groepsmaatschappijen",
  },
  {
    code: "BLasSagHooOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties schulden aan groepsmaatschappijen",
  },
  {
    code: "BLasSagAfl",
    descriptionShort: "Aflossingen schulden aan groepsmaatschappijen",
    descriptionLong:
      "Aflossingen schulden aan groepsmaatschappijen (langlopend)",
  },
  {
    code: "BLasSagAflBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) schulden aan groepsmaatschappijen",
  },
  {
    code: "BLasSagAflAfl",
    descriptionShort:
      "Aflossingen in boekjaar schulden aan groepsmaatschappijen",
    descriptionLong:
      "Aflossingen schulden aan groepsmaatschappijen (langlopend)",
  },
  {
    code: "BLasSagAflAvp",
    descriptionShort:
      "Aflossingsverplichting (overboeking naar kortlopend) groepsmaatschappijen",
    descriptionLong:
      "Aflossingsverplichting (overboeking naar kortlopend) schulden aan groepsmaatschappijen",
  },
  {
    code: "BLasSagAflTer",
    descriptionShort: "Terugboekingen",
    descriptionLong: "Terugboekingen schulden aan groepsmaatschappijen",
  },
  {
    code: "BLasSao",
    descriptionShort: "Schulden aan overige verbonden maatschappijen",
    descriptionLong:
      "Schulden aan overige verbonden maatschappijen (langlopend)",
  },
  {
    code: "BLasSaoHoo",
    descriptionShort: "Hoofdsom schulden aan overige verbonden maatschappijen",
    descriptionLong:
      "Hoofdsom schulden aan overige verbonden maatschappijen (langlopend)",
  },
  {
    code: "BLasSaoHooBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) schulden aan overige verbonden maatschappijen",
  },
  {
    code: "BLasSaoHooAao",
    descriptionShort: "Aanvullend opgenomen",
    descriptionLong:
      "Aanvullend opgenomen schulden aan overige verbonden maatschappijen",
  },
  {
    code: "BLasSaoHooBir",
    descriptionShort: "Bijschrijving rente",
    descriptionLong:
      "Bijschrijving rente schulden aan overige verbonden maatschappijen",
  },
  {
    code: "BLasSaoHooOmr",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong:
      "Omrekeningsverschillen schulden aan overige verbonden maatschappijen",
  },
  {
    code: "BLasSaoHooOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties schulden aan overige verbonden maatschappijen",
  },
  {
    code: "BLasSaoAfl",
    descriptionShort:
      "Aflossingen schulden aan overige verbonden maatschappijen",
    descriptionLong:
      "Aflossingen schulden aan overige verbonden maatschappijen (langlopend)",
  },
  {
    code: "BLasSaoAflBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) schulden aan overige verbonden maatschappijen",
  },
  {
    code: "BLasSaoAflAfl",
    descriptionShort:
      "Aflossingen in boekjaar schulden aan overige verbonden maatschappijen",
    descriptionLong:
      "Aflossingen schulden aan overige verbonden maatschappijen (langlopend)",
  },
  {
    code: "BLasSaoAflAvp",
    descriptionShort:
      "Aflossingsverplichting (overboeking naar kortlopend) overige verbonden maatschappijen",
    descriptionLong:
      "Aflossingsverplichting (overboeking naar kortlopend) schulden aan overige verbonden maatschappijen",
  },
  {
    code: "BLasSaoAflTer",
    descriptionShort: "Terugboekingen",
    descriptionLong:
      "Terugboekingen schulden aan overige verbonden maatschappijen",
  },
  {
    code: "BLasSap",
    descriptionShort:
      "Schulden aan participanten en aan maatschappijen waarin wordt deelgenomen",
    descriptionLong:
      "Schulden aan participanten en aan maatschappijen waarin wordt deelgenomen (langlopend)",
  },
  {
    code: "BLasSapHoo",
    descriptionShort:
      "Hoofdsom schulden aan participanten en aan maatschappijen waarin wordt deelgenomen",
    descriptionLong:
      "Hoofdsom schulden aan participanten en aan maatschappijen waarin wordt deelgenomen (langlopend)",
  },
  {
    code: "BLasSapHooBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) schulden aan participanten en aan maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BLasSapHooAao",
    descriptionShort: "Aanvullend opgenomen",
    descriptionLong:
      "Aanvullend opgenomen schulden aan participanten en aan maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BLasSapHooBir",
    descriptionShort: "Bijschrijving rente",
    descriptionLong:
      "Bijschrijving rente schulden aan participanten en aan maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BLasSapHooOmr",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong:
      "Omrekeningsverschillen schulden aan participanten en aan maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BLasSapHooOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong:
      "Overige mutaties schulden aan participanten en aan maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BLasSapAfl",
    descriptionShort:
      "Aflossingen schulden aan participanten en aan maatschappijen waarin wordt deelgenomen",
    descriptionLong:
      "Aflossingen schulden aan participanten en aan maatschappijen waarin wordt deelgenomen (langlopend)",
  },
  {
    code: "BLasSapAflBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) schulden aan participanten en aan maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BLasSapAflAfl",
    descriptionShort:
      "Aflossingen in boekjaar schulden aan participanten en aan maatschappijen waarin wordt deelgenomen",
    descriptionLong:
      "Aflossingen schulden aan participanten en aan maatschappijen waarin wordt deelgenomen (langlopend)",
  },
  {
    code: "BLasSapAflAvp",
    descriptionShort:
      "Aflossingsverplichting (overboeking naar kortlopend) participanten en aan maatschappijen",
    descriptionLong:
      "Aflossingsverplichting (overboeking naar kortlopend) schulden aan participanten en aan maatschappijen waarin wordt deelgenomen (langlopend)",
  },
  {
    code: "BLasSapAflTer",
    descriptionShort: "Terugboekingen",
    descriptionLong:
      "Terugboekingen schulden aan participanten en aan maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BLasBep",
    descriptionShort: "Belastingen en premies sociale verzekeringen",
    descriptionLong:
      "Belastingen en premies sociale verzekeringen (langlopend)",
  },
  {
    code: "BLasBepBep",
    descriptionShort: "Hoofdsom belastingen en premies sociale verzekeringen",
    descriptionLong:
      "Hoofdsom belastingen en premies sociale verzekeringen (langlopend)",
  },
  {
    code: "BLasBepBepBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) belastingen en premies sociale verzekeringen",
  },
  {
    code: "BLasBepBepToe",
    descriptionShort: "Toename",
    descriptionLong:
      "Toename belastingen en premies sociale verzekeringen (langlopend)",
  },
  {
    code: "BLasBepBepBir",
    descriptionShort: "Bijschrijving rente / oprenting",
    descriptionLong:
      "Bijschrijving rente / oprenting belastingen en premies sociale verzekeringen (langlopend)",
  },
  {
    code: "BLasBepBepOwv",
    descriptionShort: "Overige waardeveranderingen",
    descriptionLong:
      "Overige waardeveranderingen belastingen en premies sociale verzekeringen (langlopend)",
  },
  {
    code: "BLasBepBec",
    descriptionShort:
      "Cumulatieve aflossingen belastingen en premies sociale verzekeringen",
    descriptionLong:
      "Cumulatieve aflossingen belastingen en premies sociale verzekeringen (langlopend)",
  },
  {
    code: "BLasBepBecBeg",
    descriptionShort: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Aflossingen beginbalans (overname eindsaldo vorig jaar) belastingen en premies sociale verzekeringen",
  },
  {
    code: "BLasBepBecAfl",
    descriptionShort:
      "Afname in boekjaar belastingen en premies sociale verzekeringen",
    descriptionLong:
      "Afname in boekjaar belastingen en premies sociale verzekeringen (langlopend)",
  },
  {
    code: "BLasBepBecAvp",
    descriptionShort:
      "Afname (overboeking naar kortlopend) belastingen en premies sociale verzekeringen",
    descriptionLong:
      "Afname (overboeking naar kortlopend) belastingen en premies sociale verzekeringen (langlopend)",
  },
  {
    code: "BLasSuh",
    descriptionShort: "Schulden uit hoofde van belastingen",
    descriptionLong: "Schulden uit hoofde van belastingen (langlopend)",
  },
  {
    code: "BLasSuhSuh",
    descriptionShort: "Hoofdsom schulden uit hoofde van belastingen",
    descriptionLong:
      "Hoofdsom schulden uit hoofde van belastingen (langlopend)",
  },
  {
    code: "BLasSuhSuhBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) schulden uit hoofde van belastingen",
  },
  {
    code: "BLasSuhSuhToe",
    descriptionShort: "Toename",
    descriptionLong: "Toename schulden uit hoofde van belastingen (langlopend)",
  },
  {
    code: "BLasSuhSuhBir",
    descriptionShort: "Bijschrijving rente / oprenting",
    descriptionLong:
      "Bijschrijving rente / oprenting schulden uit hoofde van belastingen (langlopend)",
  },
  {
    code: "BLasSuhSuhOwv",
    descriptionShort: "Overige waardeveranderingen",
    descriptionLong:
      "Overige waardeveranderingen schulden uit hoofde van belastingen (langlopend)",
  },
  {
    code: "BLasSuhSuc",
    descriptionShort:
      "Cumulatieve aflossingen schulden uit hoofde van belastingen",
    descriptionLong:
      "Cumulatieve aflossingen schulden uit hoofde van belastingen (langlopend)",
  },
  {
    code: "BLasSuhSucBeg",
    descriptionShort: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) cumulatieve aflossing schulden uit hoofde van belastingen",
  },
  {
    code: "BLasSuhSucAfl",
    descriptionShort: "Afname in boekjaar uit hoofde van belastingen",
    descriptionLong:
      "Afname in boekjaar schulden uit hoofde van belastingen (langlopend)",
  },
  {
    code: "BLasSuhSucAvp",
    descriptionShort:
      "Afname (overboeking naar kortlopend) uit hoofde van belastingen",
    descriptionLong:
      "Afname (overboeking naar kortlopend) schulden uit hoofde van belastingen (langlopend)",
  },
  {
    code: "BLasStz",
    descriptionShort: "Schulden ter zake van pensioenen",
    descriptionLong: "Schulden ter zake van pensioenen (langlopend)",
  },
  {
    code: "BLasStzStz",
    descriptionShort: "Hoofdsom schulden ter zake van pensioenen",
    descriptionLong: "Hoofdsom schulden ter zake van pensioenen (langlopend)",
  },
  {
    code: "BLasStzStzBeg",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) schulden ter zake van pensioenen",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) schulden ter zake van pensioenen (langlopend)",
  },
  {
    code: "BLasStzStzToe",
    descriptionShort: "Toename",
    descriptionLong: "Toename schulden ter zake van pensioenen (langlopend)",
  },
  {
    code: "BLasStzStzBir",
    descriptionShort: "Bijschrijving rente / oprenting",
    descriptionLong:
      "Bijschrijving rente / oprenting schulden ter zake van pensioenen (langlopend)",
  },
  {
    code: "BLasStzStzOwv",
    descriptionShort: "Overige waardeveranderingen",
    descriptionLong:
      "Overige waardeveranderingen schulden ter zake van pensioenen (langlopend)",
  },
  {
    code: "BLasStzStc",
    descriptionShort:
      "Cumulatieve aflossingen schulden ter zake van pensioenen",
    descriptionLong:
      "Cumulatieve aflossingen schulden ter zake van pensioenen (langlopend)",
  },
  {
    code: "BLasStzStcBeg",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) cumulatieve aflossingen schulden pensioenen",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) cumulatieve aflossingen schulden pensioenen (langlopend)",
  },
  {
    code: "BLasStzStcAfl",
    descriptionShort: "Afname in boekjaar ter zake van pensioenen",
    descriptionLong:
      "Afname in boekjaar schulden ter zake van pensioenen (langlopend)",
  },
  {
    code: "BLasStzStcAvp",
    descriptionShort:
      "Afname (overboeking naar kortlopend) ter zake van pensioenen",
    descriptionLong:
      "Afname (overboeking naar kortlopend) schulden ter zake van pensioenen (langlopend)",
  },
  {
    code: "BLasNeg",
    descriptionShort: "Negatieve goodwill",
    descriptionLong: "Negatieve goodwill",
  },
  {
    code: "BLasNegBrw",
    descriptionShort: "Bruto waarde",
    descriptionLong: "Bruto waarde ",
  },
  {
    code: "BLasNegBrwBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BLasNegBrwAao",
    descriptionShort: "Aanvullend opgenomen",
    descriptionLong: "Aanvullend opgenomen ",
  },
  {
    code: "BLasNegBrwAag",
    descriptionShort:
      "Aanpassing als gevolg van later geïdentificeerde activa en passiva en veranderingen in de waarde ervan",
    descriptionLong:
      "Aanpassing als gevolg van later geïdentificeerde activa en passiva en veranderingen in de waarde ervan ",
  },
  {
    code: "BLasNegBrwAga",
    descriptionShort: "Afboeking als gevolg van afstotingen",
    descriptionLong: "Afboeking als gevolg van afstotingen ",
  },
  {
    code: "BLasNegBrwOvm",
    descriptionShort: "Overige mutaties bruto waarde negatieve goodwill",
    descriptionLong: "Overige mutaties bruto waarde negatieve goodwill",
  },
  {
    code: "BLasNegCbd",
    descriptionShort:
      "Cumulatief bedrag die ten gunste van de winst- en verliesrekening is gebracht",
    descriptionLong:
      "Cumulatief bedrag die ten gunste van de winst- en verliesrekening is gebracht ",
  },
  {
    code: "BLasNegCbdBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) ",
  },
  {
    code: "BLasNegCbdTgv",
    descriptionShort: "Ten gunste van winst- en verliesrekening gebracht",
    descriptionLong: "Ten gunste van winst- en verliesrekening gebracht ",
  },
  {
    code: "BLasNegCbdVtg",
    descriptionShort:
      "Vrijval ten gunste van winst- en verliesrekening, geen betrekking op toekomstige resultaten",
    descriptionLong:
      "Vrijval ten gunste van winst- en verliesrekening, geen betrekking op toekomstige resultaten ",
  },
  {
    code: "BLasOdv",
    descriptionShort: "Oudedagsverplichting",
    descriptionLong: "Oudedagsverplichting",
  },
  {
    code: "BLasOdvOdv",
    descriptionShort: "Oudedagsverplichting",
    descriptionLong: "Oudedagsverplichting",
  },
  {
    code: "BLasOdvOdvBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) oudedagsverplichting",
  },
  {
    code: "BLasOdvOdvToe",
    descriptionShort: "Aanvullend opgenomen / nieuwe opbouw",
    descriptionLong:
      "Aanvullend opgenomen / nieuwe opbouw oudedagsverplichtingoudedagsverplichting",
  },
  {
    code: "BLasOdvOdvAvs",
    descriptionShort: "Uitbetaald / bij afstoting vervreemde schulden",
    descriptionLong:
      "Uitbetaald / bij afstoting vervreemde schulden oudedagsverplichting",
  },
  {
    code: "BLasOdvOdvBir",
    descriptionShort: "Bijschrijving rente / oprenting",
    descriptionLong: "Bijschrijving rente / oprenting oudedagsverplichting",
  },
  {
    code: "BLasOdvOdvOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties oudedagsverplichting",
  },
  {
    code: "BLasPar",
    descriptionShort: "Participaties (geclassificeerd als vreemd vermogen)",
    descriptionLong:
      "Participaties (geclassificeerd als vreemd vermogen) (langlopend)",
  },
  {
    code: "BLasParPar",
    descriptionShort: "Hoofdsom participaties",
    descriptionLong: "Hoofdsom participaties (langlopend)",
  },
  {
    code: "BLasParParBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) participaties",
  },
  {
    code: "BLasParParToe",
    descriptionShort: "Aanvullend opgenomen / nieuwe financiering",
    descriptionLong: "Aanvullend opgenomen participaties",
  },
  {
    code: "BLasParParOvs",
    descriptionShort: "Bij overname verkregen schulden",
    descriptionLong: "Bij overname verkregen schulden participaties",
  },
  {
    code: "BLasParParAvs",
    descriptionShort: "Bij afstoting vervreemde schulden",
    descriptionLong: "Bij afstoting vervreemde schulden participaties",
  },
  {
    code: "BLasParParBir",
    descriptionShort: "Bijschrijving rente / oprenting",
    descriptionLong: "Bijschrijving rente participaties",
  },
  {
    code: "BLasParParOmv",
    descriptionShort: "Omrekeningsverschillen",
    descriptionLong: "Omrekeningsverschillen participaties",
  },
  {
    code: "BLasParParOvm",
    descriptionShort: "Overige mutaties",
    descriptionLong: "Overige mutaties participaties",
  },
  {
    code: "BLasParParOwv",
    descriptionShort: "Overige waardeveranderingen",
    descriptionLong: "Overige waardeveranderingen participaties",
  },
  {
    code: "BLasParCla",
    descriptionShort: "Cumulatieve aflossingen participaties",
    descriptionLong: "Cumulatieve aflossingen participaties (langlopend)",
  },
  {
    code: "BLasParClaBeg",
    descriptionShort: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Aflossingen beginbalans (overname eindsaldo vorig jaar) participaties",
  },
  {
    code: "BLasParClaAfl",
    descriptionShort: "Aflossingen in boekjaar participaties",
    descriptionLong: "Aflossingen in boekjaar participaties (langlopend)",
  },
  {
    code: "BLasParClaAvp",
    descriptionShort:
      "Aflossingsverplichting (overboeking naar kortlopend) participaties",
    descriptionLong:
      "Aflossingsverplichting (overboeking naar kortlopend) participaties (langlopend)",
  },
  {
    code: "BLasSoh",
    descriptionShort: "Schulden aan overheid (langlopend)",
    descriptionLong: "Schulden aan overheid (langlopend)",
  },
  {
    code: "BLasSohSoh",
    descriptionShort: "Hoofdsom schulden aan overheid",
    descriptionLong: "Hoofdsom schulden aan overheid (langlopend)",
  },
  {
    code: "BLasSohSohBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) schulden van overheid",
  },
  {
    code: "BLasSohSohToe",
    descriptionShort: "Toename schulden van overheid (langlopend)",
    descriptionLong: "Toename leningen schulden van overheid (langlopend)",
  },
  {
    code: "BLasSohSohOvs",
    descriptionShort: "Bij overname verkregen schulden",
    descriptionLong:
      "Bij overname verkregen schulden aan overheid (langlopend)",
  },
  {
    code: "BLasSohSohAvs",
    descriptionShort: "Bij afstoting vervreemde schulden",
    descriptionLong:
      "Bij afstoting vervreemde schulden van overheid (langlopend)",
  },
  {
    code: "BLasSohSohBir",
    descriptionShort: "Bijschrijving rente / oprenting",
    descriptionLong:
      "Bijschrijving rente / oprenting schulden van overheid (langlopend)",
  },
  {
    code: "BLasSohSohOmv",
    descriptionShort:
      "Omrekeningsverschillen schulden van overheid (langlopend)",
    descriptionLong:
      "Omrekeningsverschillen schulden van overheid (langlopend)",
  },
  {
    code: "BLasSohSohOvm",
    descriptionShort: "Overige mutaties schulden van overheid (langlopend)",
    descriptionLong: "Overige mutaties schulden van overheid (langlopend)",
  },
  {
    code: "BLasSohSohOwv",
    descriptionShort: "Overige waardeveranderingen",
    descriptionLong:
      "Overige waardeveranderingen schulden van overheid (langlopend)",
  },
  {
    code: "BLasSohAso",
    descriptionShort: "Cumulatieve aflossingen schulden aan overheid",
    descriptionLong:
      "Cumulatieve aflossingen schulden aan overheid (langlopend)",
  },
  {
    code: "BLasSohAsoBeg",
    descriptionShort: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
  },
  {
    code: "BLasSohAsoAfl",
    descriptionShort: "Aflossingen in boekjaar",
    descriptionLong:
      "Aflossingen in boekjaar leningen van overheid (langlopend)",
  },
  {
    code: "BLasSohAsoAvp",
    descriptionShort:
      "Aflossingsverplichting (overboeking naar kortlopend) overheid",
    descriptionLong:
      "Aflossingsverplichting (overboeking naar kortlopend) schulden van overheid (langlopend)",
  },
  {
    code: "BLasSohWsw",
    descriptionShort: "Hoofdsom schulden aan overheid geborgd door WSW",
    descriptionLong:
      "Hoofdsom schulden aan overheid geborgd door WSW (langlopend)",
  },
  {
    code: "BLasSohWswBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) schulden van overheid geborgd door WSW ",
  },
  {
    code: "BLasSohWswToe",
    descriptionShort: "Toename leningen van overheid (langlopend)",
    descriptionLong: "Toename leningen van overheid (langlopend)",
  },
  {
    code: "BLasSohWswOvs",
    descriptionShort: "Bij overname verkregen schulden",
    descriptionLong:
      "Bij overname verkregen leningen van overheid (langlopend)",
  },
  {
    code: "BLasSohWswAvs",
    descriptionShort: "Bij afstoting vervreemde schulden",
    descriptionLong:
      "Bij afstoting vervreemde leningen van overheid (langlopend)",
  },
  {
    code: "BLasSohWswBir",
    descriptionShort: "Bijschrijving rente / oprenting",
    descriptionLong:
      "Bijschrijving rente / oprenting leningen van overheid (langlopend)",
  },
  {
    code: "BLasSohWswOmv",
    descriptionShort:
      "Omrekeningsverschillen leningen van overheid (langlopend)",
    descriptionLong:
      "Omrekeningsverschillen leningen van overheid (langlopend)",
  },
  {
    code: "BLasSohWswOvm",
    descriptionShort: "Overige mutaties leningen van overheid (langlopend)",
    descriptionLong: "Overige mutaties leningen van overheid (langlopend)",
  },
  {
    code: "BLasSohWswOwv",
    descriptionShort: "Overige waardeveranderingen",
    descriptionLong:
      "Overige waardeveranderingen leningen van overheid (langlopend)",
  },
  {
    code: "BLasSohAws",
    descriptionShort:
      "Cumulatieve aflossingen schulden aan overheid geborgd door WSW",
    descriptionLong:
      "Cumulatieve aflossingen schulden aan overheid geborgd door WSW (langlopend)",
  },
  {
    code: "BLasSohAwsBeg",
    descriptionShort: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
  },
  {
    code: "BLasSohAwsAfl",
    descriptionShort: "Aflossingen in boekjaar overheid geborgd door WSW",
    descriptionLong:
      "Aflossingen in boekjaar leningen van overheid geborgd door WSW (langlopend)",
  },
  {
    code: "BLasSohAwsAvp",
    descriptionShort:
      "Aflossingsverplichting (overboeking naar kortlopend) overheid geborgd door WSW",
    descriptionLong:
      "Aflossingsverplichting (overboeking naar kortlopend) leningen van overheid geborgd door WSW (langlopend)",
  },
  {
    code: "BLasSohGos",
    descriptionShort:
      "Hoofdsom schulden aan overheid gegarandeerd door overheid",
    descriptionLong:
      "Hoofdsom schulden aan overheid gegarandeerd door overheid (langlopend)",
  },
  {
    code: "BLasSohGosBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) schulden van overheid gegarandeerd door overheden",
  },
  {
    code: "BLasSohGosToe",
    descriptionShort: "Toename leningen van overheid (langlopend)",
    descriptionLong: "Toename leningen van overheid (langlopend)",
  },
  {
    code: "BLasSohGosOvs",
    descriptionShort: "Bij overname verkregen schulden",
    descriptionLong:
      "Bij overname verkregen schulden van overheid (langlopend)",
  },
  {
    code: "BLasSohGosAvs",
    descriptionShort: "Bij afstoting vervreemde schulden",
    descriptionLong:
      "Bij afstoting vervreemde schulden van overheid (langlopend)",
  },
  {
    code: "BLasSohGosBir",
    descriptionShort: "Bijschrijving rente / oprenting",
    descriptionLong:
      "Bijschrijving rente / oprenting financieringen van overheid (langlopend)",
  },
  {
    code: "BLasSohGosOmv",
    descriptionShort:
      "Omrekeningsverschillen leningen van overheid (langlopend)",
    descriptionLong:
      "Omrekeningsverschillen leningen van overheid (langlopend)",
  },
  {
    code: "BLasSohGosOvm",
    descriptionShort: "Overige mutaties leningen van overheid (langlopend)",
    descriptionLong: "Overige mutaties leningen van overheid (langlopend)",
  },
  {
    code: "BLasSohGosOwv",
    descriptionShort: "Overige waardeveranderingen",
    descriptionLong:
      "Overige waardeveranderingen leningen van overheid (langlopend)",
  },
  {
    code: "BLasSohGoa",
    descriptionShort:
      "Cumulatieve aflossingen schulden aan overheid gegarandeerd door overheid",
    descriptionLong:
      "Cumulatieve aflossingen schulden aan overheid gegarandeerd door overheid (langlopend)",
  },
  {
    code: "BLasSohGoaBeg",
    descriptionShort: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Aflossingen beginbalans (overname eindsaldo vorig jaar)",
  },
  {
    code: "BLasSohGoaAfl",
    descriptionShort: "Aflossingen in boekjaar gegarandeerd door overheid",
    descriptionLong:
      "Aflossingen in boekjaar leningen gegarandeerd door overheid (langlopend)",
  },
  {
    code: "BLasSohGoaAvp",
    descriptionShort:
      "Aflossingsverplichting (overboeking naar kortlopend) gegarandeerd door overheid",
    descriptionLong:
      "Aflossingsverplichting (overboeking naar kortlopend) gegarandeerd door overheid (langlopend)",
  },
  {
    code: "BLasVhz",
    descriptionShort:
      "Verplichtingen uit hoofde van onroerende zaken verkocht onder voorwaarden",
    descriptionLong:
      "Verplichtingen uit hoofde van onroerende zaken verkocht onder voorwaarden (langlopend)",
  },
  {
    code: "BLasVhzVhz",
    descriptionShort:
      "Verplichtingen uit hoofde van onroerende zaken verkocht onder voorwaarden",
    descriptionLong:
      "Verplichtingen uit hoofde van onroerende zaken verkocht onder voorwaarden (langlopend)",
  },
  {
    code: "BLasVhzVhzBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) verplichtingen uit hoofde van onroerende zaken verkocht ondervoorwaarden (langlopend)",
  },
  {
    code: "BLasVhzVhzAan",
    descriptionShort:
      "Aankoop verplichtingen uit hoofde van onroerende zaken verkocht onder voorwaarden",
    descriptionLong:
      "Aankoop verplichtingen uit hoofde van onroerende zaken verkocht onder voorwaarden (langlopend)",
  },
  {
    code: "BLasVhzVhzVer",
    descriptionShort:
      "Verkoop verplichtingen uit hoofde van onroerende zaken verkocht onder voorwaarden",
    descriptionLong:
      "Verkoop verplichtingen uit hoofde van onroerende zaken verkocht onder voorwaarden (langlopend)",
  },
  {
    code: "BLasVhzVhzWaa",
    descriptionShort:
      "Waardestijging verplichtingen uit hoofde van onroerende zaken verkocht onder voorwaarden",
    descriptionLong:
      "Waardestijging verplichtingen uit hoofde van onroerende zaken verkocht onder voorwaarden (langlopend)",
  },
  {
    code: "BLasVhzVhzAfw",
    descriptionShort:
      "Afwaardering verplichtingen uit hoofde van onroerende zaken verekocht onder voorwaarden",
    descriptionLong:
      "Afwaardering verplichtingen uit hoofde van onroerende zaken verekocht onder voorwaarden",
  },
  {
    code: "BLasVhzVhzOve",
    descriptionShort:
      "Overige mutaties verplichtingen uit hoofde van onroerende zaken verkocht onder voorwaarden",
    descriptionLong:
      "Overige mutaties verplichtingen uit hoofde van onroerende zaken verkocht onder voorwaarden (langlopend)",
  },
  {
    code: "BLasOls",
    descriptionShort: "Overige schulden",
    descriptionLong: "Overige schulden (Langlopend)",
  },
  {
    code: "BLasOlsOsl",
    descriptionShort: "Hoofdsom overige schulden",
    descriptionLong: "Hoofdsom overige schulden (langlopend)",
  },
  {
    code: "BLasOlsOslBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) schulden ",
  },
  {
    code: "BLasOlsOslToe",
    descriptionShort: "Aanvullend opgenomen overige schulden",
    descriptionLong: "Aanvullend opgenomen overige schulden",
  },
  {
    code: "BLasOlsOslOvm",
    descriptionShort: "Overige mutaties overige schulden (langlopend)",
    descriptionLong: "Overige mutaties overige schulden (langlopend)",
  },
  {
    code: "BLasOlsAfl",
    descriptionShort: "Cumulatieve aflossingen overige schulden",
    descriptionLong: "Cumulatieve aflossingen overige schulden (langlopend)",
  },
  {
    code: "BLasOlsAflBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) schulden ",
  },
  {
    code: "BLasOlsAflAfl",
    descriptionShort: "Aflossingen overige schulden (langlopend)",
    descriptionLong: "Aflossingen overige schulden (langlopend)",
  },
  {
    code: "BLasOlsAflAvp",
    descriptionShort:
      "Aflossingsverplichting (overboeking naar kortlopend) overige schulden",
    descriptionLong:
      "Aflossingsverplichting (overboeking naar kortlopend) overige schulden",
  },
  {
    code: "BLasOlsIlg",
    descriptionShort: "Hoofdsom intern lening",
    descriptionLong: "Hoodsom intern lening (Langlopend)",
  },
  {
    code: "BLasOlsIlgBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) intern lening",
  },
  {
    code: "BLasOlsIlgToe",
    descriptionShort: "Aanvullend opgenomen intern lening",
    descriptionLong: "Aanvullend opgenomen intern lening",
  },
  {
    code: "BLasOlsIlgOvm",
    descriptionShort: "Overige mutaties intern lening (langlopend)",
    descriptionLong: "Overige mutaties intern lening (langlopend)",
  },
  {
    code: "BLasOlsIla",
    descriptionShort: "Aflossingen intern lening",
    descriptionLong: "Aflossingen intern lening (Langlopend)",
  },
  {
    code: "BLasOlsIlaBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) intern lening",
  },
  {
    code: "BLasOlsIlaAfl",
    descriptionShort: "Aflossingen intern lening (langlopend)",
    descriptionLong: "Aflossingen intern lening (Langlopend)",
  },
  {
    code: "BLasOlsIlaAvp",
    descriptionShort:
      "Aflossingsverplichting (overboeking naar kortlopend) intern lening",
    descriptionLong:
      "Aflossingsverplichting (overboeking naar kortlopend)  intern lening",
  },
  {
    code: "BLasOlsWbs",
    descriptionShort: "Hoofdsom waarborgsommen",
    descriptionLong: "Hoofdsom waarborgsommen (Langlopend)",
  },
  {
    code: "BLasOlsWbsBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) waarborgsommen",
  },
  {
    code: "BLasOlsWbsToe",
    descriptionShort: "Aanvullend opgenomen waarborgsommen",
    descriptionLong: "Aanvullend opgenomen waarborgsommen",
  },
  {
    code: "BLasOlsWbsOvm",
    descriptionShort: "Overige mutaties waarborgsommen (langlopend)",
    descriptionLong: "Overige mutaties waarborgsommen (langlopend)",
  },
  {
    code: "BLasOlsWba",
    descriptionShort: "Aflossingen waarborgsommen",
    descriptionLong: "Aflossingen waarborgsommen (langlopend)",
  },
  {
    code: "BLasOlsWbaBeg",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) aflossingen waarborgsommen",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) aflossingen waarborgsommen",
  },
  {
    code: "BLasOlsWbaAfl",
    descriptionShort: "Aflossingen waarborgsommen (langlopend)",
    descriptionLong: "Aflossingen waarborgsommen (langlopend)",
  },
  {
    code: "BLasOlsWbaAvp",
    descriptionShort:
      "Aflossingsverplichting (overboeking naar kortlopend) waarborgsommen",
    descriptionLong:
      "Aflossingsverplichting (overboeking naar kortlopend) waarborgsommen",
  },
  {
    code: "BLasOlsDer",
    descriptionShort: "Hoofdsom derivaten",
    descriptionLong: "Hoofdsom derivaten (Langlopend)",
  },
  {
    code: "BLasOlsDerBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) derivaten",
  },
  {
    code: "BLasOlsDerToe",
    descriptionShort: "Aanvullend opgenomen derivaten",
    descriptionLong: "Aanvullend opgenomen derivaten",
  },
  {
    code: "BLasOlsDerOvm",
    descriptionShort: "Overige mutaties derivaten (langlopend)",
    descriptionLong: "Overige mutaties derivaten (langlopend)",
  },
  {
    code: "BLasOlsDea",
    descriptionShort: "Aflossingen derivaten",
    descriptionLong: "Aflossingen derivaten (Langlopend)",
  },
  {
    code: "BLasOlsDeaBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong: "Beginbalans (overname eindsaldo vorig jaar) derivaten",
  },
  {
    code: "BLasOlsDeaAfl",
    descriptionShort: "Aflossingen derivaten (langlopend)",
    descriptionLong: "Aflossingen derivaten (langlopend)",
  },
  {
    code: "BLasOlsDeaAvp",
    descriptionShort:
      "Aflossingsverplichting (overboeking naar kortlopend) derivaten",
    descriptionLong:
      "Aflossingsverplichting (overboeking naar kortlopend) derivaten",
  },
  {
    code: "BLasOvp",
    descriptionShort: "Overlopende passiva",
    descriptionLong: "Overlopende passiva (Langlopend)",
  },
  {
    code: "BLasOvpOvp",
    descriptionShort: "Hoofdsom overlopende passiva",
    descriptionLong: "Hoofdsom overlopende passiva (langlopend)",
  },
  {
    code: "BLasOvpOvpBeg",
    descriptionShort: "Beginbalans (overname eindsaldo vorig jaar)",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) overlopende passiva",
  },
  {
    code: "BLasOvpOvpToe",
    descriptionShort: "Aanvullend opgenomen overlopende passiva",
    descriptionLong: "Aanvullend opgenomen overlopende passiva",
  },
  {
    code: "BLasOvpOvpOvm",
    descriptionShort: "Overige mutaties overlopende passiva (langlopend)",
    descriptionLong: "Overige mutaties overlopende passiva (langlopend)",
  },
  {
    code: "BLasOvpOva",
    descriptionShort: "Aflossingen overlopende passiva",
    descriptionLong: "Aflossingen overlopende passiva (langlopend)",
  },
  {
    code: "BLasOvpOvaBeg",
    descriptionShort:
      "Beginbalans (overname eindsaldo vorig jaar) cumulatieve aflossing overlopende passiva",
    descriptionLong:
      "Beginbalans (overname eindsaldo vorig jaar) cumulatieve aflossing overlopende passiva",
  },
  {
    code: "BLasOvpOvaAfl",
    descriptionShort: "Aflossingen overlopende passiva (langlopend)",
    descriptionLong: "Aflossingen overlopende passiva (langlopend)",
  },
  {
    code: "BLasOvpOvaAvp",
    descriptionShort:
      "Aflossingsverplichting (overboeking naar kortlopend) overlopende passiva",
    descriptionLong:
      "Aflossingsverplichting (overboeking naar kortlopend) overlopende passiva",
  },
  {
    code: "BSch",
    descriptionShort: "Kortlopende schulden",
    descriptionLong: "Kortlopende schulden",
  },
  {
    code: "BSchKol",
    descriptionShort: "Kortlopende leningen-schulden-verplichtingen",
    descriptionLong: "Kortlopende leningen-schulden-verplichtingen",
  },
  {
    code: "BSchKolAlk",
    descriptionShort: "Achtergestelde schulden",
    descriptionLong: "Achtergestelde schulden",
  },
  {
    code: "BSchKolClk",
    descriptionShort: "Converteerbare leningen",
    descriptionLong: "Converteerbare leningen",
  },
  {
    code: "BSchKolAok",
    descriptionShort: "Obligatieleningen, pandbrieven en andere leningen",
    descriptionLong: "Obligatieleningen, pandbrieven en andere leningen",
  },
  {
    code: "BSchKolAov",
    descriptionShort: "Aangegane verplichtingen overig",
    descriptionLong: "Aangegane verplichtingen overig",
  },
  {
    code: "BSchKolDer",
    descriptionShort: "Derivaten",
    descriptionLong: "Derivaten",
  },
  {
    code: "BSchKolDerNmd",
    descriptionShort: "Negatieve marktwaarde derivaten",
    descriptionLong: "Negatieve marktwaarde derivaten",
  },
  {
    code: "BSchKolDerNed",
    descriptionShort: "Negatieve marktwaarde embedded derivaten",
    descriptionLong: "Negatieve marktwaarde embedded derivaten",
  },
  {
    code: "BSchSoh",
    descriptionShort: "Schulden aan overheid (kortlopend)",
    descriptionLong: "Schulden aan overheid (kortlopend)",
  },
  {
    code: "BSchSohSoh",
    descriptionShort: "Schulden aan overheid (kortlopend)",
    descriptionLong: "Schulden aan overheid (kortlopend)",
  },
  {
    code: "BSchSak",
    descriptionShort: "Schulden aan banken",
    descriptionLong: "Schulden aan banken",
  },
  {
    code: "BSchSakRba",
    descriptionShort: "Rekening-courant bij kredietinstellingen",
    descriptionLong:
      "Rekening-courant bij kredietinstellingen schulden aan kredietinstellingen",
  },
  {
    code: "BSchSakRbaBg1",
    descriptionShort: "Rekening-courant bank groep 1",
    descriptionLong: "Rekening-courant bank groep 1",
  },
  {
    code: "BSchSakRbaBg2",
    descriptionShort: "Rekening-courant bank groep 2",
    descriptionLong: "Rekening-courant bank groep 2",
  },
  {
    code: "BSchSakRbaBg3",
    descriptionShort: "Rekening-courant bank groep 3",
    descriptionLong: "Rekening-courant bank groep 3",
  },
  {
    code: "BSchSakRbaBg4",
    descriptionShort: "Rekening-courant bank groep 4",
    descriptionLong: "Rekening-courant bank groep 4",
  },
  {
    code: "BSchSakRbaBg5",
    descriptionShort: "Rekening-courant bank groep 5",
    descriptionLong: "Rekening-courant bank groep 5",
  },
  {
    code: "BSchSakRbaBg6",
    descriptionShort: "Rekening-courant bank groep 6",
    descriptionLong: "Rekening-courant bank groep 6",
  },
  {
    code: "BSchSakRbaBg7",
    descriptionShort: "Rekening-courant bank groep 7",
    descriptionLong: "Rekening-courant bank groep 7",
  },
  {
    code: "BSchSakRbaBg8",
    descriptionShort: "Rekening-courant bank groep 8",
    descriptionLong: "Rekening-courant bank groep 8",
  },
  {
    code: "BSchSakRbaBg9",
    descriptionShort: "Rekening-courant bank groep 9",
    descriptionLong: "Rekening-courant bank groep 9",
  },
  {
    code: "BSchSakRbaBg10",
    descriptionShort: "Rekening-courant bank groep 10",
    descriptionLong: "Rekening-courant bank groep 10",
  },
  {
    code: "BSchSakRbb",
    descriptionShort: "Rekening-courant bij kredietinstellingen - Naam A",
    descriptionLong:
      "Rekening-courant bij kredietinstellingen - Naam A - schulden aan kredietinstellingen",
  },
  {
    code: "BSchSakRbc",
    descriptionShort: "Rekening-courant bij kredietinstellingen - Naam B",
    descriptionLong:
      "Rekening-courant bij kredietinstellingen - Naam B - schulden aan kredietinstellingen",
  },
  {
    code: "BSchSakRbd",
    descriptionShort: "Rekening-courant bij kredietinstellingen - Naam C",
    descriptionLong:
      "Rekening-courant bij kredietinstellingen - Naam C - schulden aan kredietinstellingen",
  },
  {
    code: "BSchSakRbe",
    descriptionShort: "Rekening-courant bij kredietinstellingen - Naam D",
    descriptionLong:
      "Rekening-courant bij kredietinstellingen - Naam D - schulden aan kredietinstellingen",
  },
  {
    code: "BSchSakRbf",
    descriptionShort: "Rekening-courant bij kredietinstellingen - Naam E",
    descriptionLong:
      "Rekening-courant bij kredietinstellingen - Naam E - schulden aan kredietinstellingen",
  },
  {
    code: "BSchVob",
    descriptionShort: "Ontvangen vooruitbetalingen op bestellingen",
    descriptionLong: "Ontvangen vooruitbetalingen op bestellingen",
  },
  {
    code: "BSchVobVgf",
    descriptionShort: "Vooruitgezonden facturen",
    descriptionLong:
      "Vooruitgezonden facturen ontvangen vooruitbetalingen op bestellingen",
  },
  {
    code: "BSchVobVob",
    descriptionShort: "Ontvangen vooruitbetalingen op bestellingen",
    descriptionLong:
      "Ontvangen vooruitbetalingen op bestellingen ontvangen vooruitbetalingen op bestellingen",
  },
  {
    code: "BSchCre",
    descriptionShort: "Schulden aan leveranciers en handelskredieten",
    descriptionLong: "Schulden aan leveranciers en handelskredieten",
  },
  {
    code: "BSchCreHac",
    descriptionShort: "Handelscrediteuren nominaal",
    descriptionLong:
      "Handelscrediteuren nominaal schulden aan leveranciers en handelskredieten",
  },
  {
    code: "BSchCreHci",
    descriptionShort: "Handelscrediteuren intercompany",
    descriptionLong: "Handelscrediteuren intercompany",
  },
  {
    code: "BSchCreVbk",
    descriptionShort: "Ontvangen vooruitbetalingen op bestellingen",
    descriptionLong: "Ontvangen vooruitbetalingen op bestellingen",
  },
  {
    code: "BSchCreKcr",
    descriptionShort: "Kostencrediteuren",
    descriptionLong: "Kostencrediteuren",
  },
  {
    code: "BSchCreTus",
    descriptionShort:
      "Tussenrekening betalingen crediteuren (betalingen onderweg)",
    descriptionLong:
      "Tussenrekening betalingen crediteuren (betalingen onderweg)",
  },
  {
    code: "BSchCreTtf",
    descriptionShort: "Tussenrekening te fiatteren facturen",
    descriptionLong: "Tussenrekening te fiatteren facturen",
  },
  {
    code: "BSchTbw",
    descriptionShort: "Te betalen wissels en cheques",
    descriptionLong: "Te betalen wissels en cheques",
  },
  {
    code: "BSchTbwAvp",
    descriptionShort: "Aflossingsverplichtingen",
    descriptionLong: "Aflossingsverplichtingen te betalen wissels en cheques",
  },
  {
    code: "BSchTbwTbr",
    descriptionShort: "Te betalen rente",
    descriptionLong: "Te betalen rente te betalen wissels en cheques",
  },
  {
    code: "BSchTbwOvs",
    descriptionShort: "Overige schulden",
    descriptionLong: "Overige schulden te betalen wissels en cheques",
  },
  {
    code: "BSchSag",
    descriptionShort: "Schulden aan groepsmaatschappijen",
    descriptionLong: "Schulden aan groepsmaatschappijen",
  },
  {
    code: "BSchSagSg1",
    descriptionShort: "Schuld aan groepsmaatschappij 1",
    descriptionLong: "Schuld aan groepsmaatschappij 1",
  },
  {
    code: "BSchSagSg1Klg",
    descriptionShort:
      "Kortlopend deel van langlopende schuld aan groepsmaatschappij 1",
    descriptionLong:
      "Kortlopend deel van langlopende schulden aan groepsmaatschappijen schulden aan groepsmaatschappijen",
  },
  {
    code: "BSchSagSg1Tbr",
    descriptionShort: "Te betalen rente",
    descriptionLong: "Te betalen rente schulden aan groepsmaatschappijen",
  },
  {
    code: "BSchSagSg1Rbg",
    descriptionShort: "Rekening-courant bij groepsmaatschappij 1",
    descriptionLong:
      "Rekening-courant bij groepsmaatschappijen schulden aan groepsmaatschappijen",
  },
  {
    code: "BSchSagSg2",
    descriptionShort: "Schuld aan groepsmaatschappij 2",
    descriptionLong: "Schuld aan groepsmaatschappij 2",
  },
  {
    code: "BSchSagSg2Klg",
    descriptionShort:
      "Kortlopend deel van langlopende schulden aan groepsmaatschappijen",
    descriptionLong:
      "Kortlopend deel van langlopende schulden aan groepsmaatschappijen schulden aan groepsmaatschappijen",
  },
  {
    code: "BSchSagSg2Tbr",
    descriptionShort: "Te betalen rente",
    descriptionLong: "Te betalen rente schulden aan groepsmaatschappijen",
  },
  {
    code: "BSchSagSg2Rbg",
    descriptionShort: "Rekening-courant bij groepsmaatschappij 2",
    descriptionLong:
      "Rekening-courant bij groepsmaatschappijen schulden aan groepsmaatschappijen",
  },
  {
    code: "BSchSagSg3",
    descriptionShort: "Schuld aan groepsmaatschappij 3",
    descriptionLong: "Schuld aan groepsmaatschappij 3",
  },
  {
    code: "BSchSagSg3Klg",
    descriptionShort:
      "Kortlopend deel van langlopende schulden aan groepsmaatschappijen",
    descriptionLong:
      "Kortlopend deel van langlopende schulden aan groepsmaatschappijen schulden aan groepsmaatschappijen",
  },
  {
    code: "BSchSagSg3Tbr",
    descriptionShort: "Te betalen rente",
    descriptionLong: "Te betalen rente schulden aan groepsmaatschappijen",
  },
  {
    code: "BSchSagSg3Rbg",
    descriptionShort: "Rekening-courant bij groepsmaatschappij 3",
    descriptionLong:
      "Rekening-courant bij groepsmaatschappijen schulden aan groepsmaatschappijen",
  },
  {
    code: "BSchSagSg4",
    descriptionShort: "Schuld aan groepsmaatschappij 4",
    descriptionLong: "Schuld aan groepsmaatschappij 4",
  },
  {
    code: "BSchSagSg4Klg",
    descriptionShort:
      "Kortlopend deel van langlopende schulden aan groepsmaatschappijen",
    descriptionLong:
      "Kortlopend deel van langlopende schulden aan groepsmaatschappijen schulden aan groepsmaatschappijen",
  },
  {
    code: "BSchSagSg4Tbr",
    descriptionShort: "Te betalen rente",
    descriptionLong: "Te betalen rente schulden aan groepsmaatschappijen",
  },
  {
    code: "BSchSagSg4Rbg",
    descriptionShort: "Rekening-courant bij groepsmaatschappij 4",
    descriptionLong:
      "Rekening-courant bij groepsmaatschappijen schulden aan groepsmaatschappijen",
  },
  {
    code: "BSchSagSg5",
    descriptionShort: "Schuld aan groepsmaatschappij 5",
    descriptionLong: "Schuld aan groepsmaatschappij 5",
  },
  {
    code: "BSchSagSg5Klg",
    descriptionShort:
      "Kortlopend deel van langlopende schulden aan groepsmaatschappijen",
    descriptionLong:
      "Kortlopend deel van langlopende schulden aan groepsmaatschappijen schulden aan groepsmaatschappijen",
  },
  {
    code: "BSchSagSg5Tbr",
    descriptionShort: "Te betalen rente",
    descriptionLong: "Te betalen rente schulden aan groepsmaatschappijen",
  },
  {
    code: "BSchSagSg5Rbg",
    descriptionShort: "Rekening-courant bij groepsmaatschappij 5",
    descriptionLong:
      "Rekening-courant bij groepsmaatschappijen schulden aan groepsmaatschappijen",
  },
  {
    code: "BSchSagDae",
    descriptionShort: "Schuld aan DAEB",
    descriptionLong: "Schuld aan DAEB",
  },
  {
    code: "BSchSagDaeKlg",
    descriptionShort: "Kortlopend deel van langlopende schulden aan DAEB",
    descriptionLong: "Kortlopend deel van langlopende schulden aan DAEB",
  },
  {
    code: "BSchSagDaeTbr",
    descriptionShort: "Te betalen rente",
    descriptionLong: "Te betalen rente",
  },
  {
    code: "BSchSagDaeRbg",
    descriptionShort: "Rekening-courant bij DAEB",
    descriptionLong: "Rekening-courant bij DAEB",
  },
  {
    code: "BSchSagNda",
    descriptionShort: "Schuld aan Niet-DAEB",
    descriptionLong: "Schuld aan Niet-DAEB",
  },
  {
    code: "BSchSagNdaKlg",
    descriptionShort: "Kortlopend deel van langlopende schulden aan Niet-DAEB",
    descriptionLong: "Kortlopend deel van langlopende schulden aan Niet-DAEB",
  },
  {
    code: "BSchSagNdaTbr",
    descriptionShort: "Te betalen rente",
    descriptionLong: "Te betalen rente",
  },
  {
    code: "BSchSagNdaRbg",
    descriptionShort: "Rekening-courant bij Niet-DAEB",
    descriptionLong: "Rekening-courant bij Niet-DAEB",
  },
  {
    code: "BSchSao",
    descriptionShort: "Schulden aan overige verbonden maatschappijen",
    descriptionLong: "Schulden aan overige verbonden maatschappijen",
  },
  {
    code: "BSchSaoSo1",
    descriptionShort: "Schuld aan overige verbonden maatschappij 1",
    descriptionLong: "Schuld aan overige verbonden maatschappij 1",
  },
  {
    code: "BSchSaoSo1Klo",
    descriptionShort:
      "Kortlopend deel van langlopende schuld aan overige verbonden maatschappij 1",
    descriptionLong:
      "Kortlopend deel van langlopende schulden aan overige verbonden maatschappijen schulden aan overige verbonden maatschappijen",
  },
  {
    code: "BSchSaoSo1Tbr",
    descriptionShort: "Te betalen rente",
    descriptionLong:
      "Te betalen rente schulden aan overige verbonden maatschappijen",
  },
  {
    code: "BSchSaoSo1Rbo",
    descriptionShort: "Rekening-courant bij overige verbonden maatschappij 1",
    descriptionLong:
      "Rekening-courant bij overige verbonden maatschappijen schulden aan overige verbonden maatschappijen",
  },
  {
    code: "BSchSaoSo2",
    descriptionShort: "Schuld aan overige verbonden maatschappij 2",
    descriptionLong: "Schuld aan overige verbonden maatschappij 2",
  },
  {
    code: "BSchSaoSo2Klo",
    descriptionShort:
      "Kortlopend deel van langlopende schuld aan overige verbonden maatschappij 2",
    descriptionLong:
      "Kortlopend deel van langlopende schulden aan overige verbonden maatschappijen schulden aan overige verbonden maatschappijen",
  },
  {
    code: "BSchSaoSo2Tbr",
    descriptionShort: "Te betalen rente",
    descriptionLong:
      "Te betalen rente schulden aan overige verbonden maatschappijen",
  },
  {
    code: "BSchSaoSo2Rbo",
    descriptionShort: "Rekening-courant bij overige verbonden maatschappij 2",
    descriptionLong:
      "Rekening-courant bij overige verbonden maatschappijen schulden aan overige verbonden maatschappijen",
  },
  {
    code: "BSchSaoSo3",
    descriptionShort: "Schuld aan overige verbonden maatschappij 3",
    descriptionLong: "Schuld aan overige verbonden maatschappij 3",
  },
  {
    code: "BSchSaoSo3Klo",
    descriptionShort:
      "Kortlopend deel van langlopende schuld aan overige verbonden maatschappij 3",
    descriptionLong:
      "Kortlopend deel van langlopende schulden aan overige verbonden maatschappijen schulden aan overige verbonden maatschappijen",
  },
  {
    code: "BSchSaoSo3Tbr",
    descriptionShort: "Te betalen rente",
    descriptionLong:
      "Te betalen rente schulden aan overige verbonden maatschappijen",
  },
  {
    code: "BSchSaoSo3Rbo",
    descriptionShort: "Rekening-courant bij overige verbonden maatschappij 3",
    descriptionLong:
      "Rekening-courant bij overige verbonden maatschappijen schulden aan overige verbonden maatschappijen",
  },
  {
    code: "BSchSaoSo4",
    descriptionShort: "Schuld aan overige verbonden maatschappij 4",
    descriptionLong: "Schuld aan overige verbonden maatschappij 4",
  },
  {
    code: "BSchSaoSo4Klo",
    descriptionShort:
      "Kortlopend deel van langlopende schuld aan overige verbonden maatschappij 4",
    descriptionLong:
      "Kortlopend deel van langlopende schulden aan overige verbonden maatschappijen schulden aan overige verbonden maatschappijen",
  },
  {
    code: "BSchSaoSo4Tbr",
    descriptionShort: "Te betalen rente",
    descriptionLong:
      "Te betalen rente schulden aan overige verbonden maatschappijen",
  },
  {
    code: "BSchSaoSo4Rbo",
    descriptionShort: "Rekening-courant bij overige verbonden maatschappij 4",
    descriptionLong:
      "Rekening-courant bij overige verbonden maatschappijen schulden aan overige verbonden maatschappijen",
  },
  {
    code: "BSchSaoSo5",
    descriptionShort: "Schuld aan overige verbonden maatschappij 5",
    descriptionLong: "Schuld aan overige verbonden maatschappij 5",
  },
  {
    code: "BSchSaoSo5Klo",
    descriptionShort:
      "Kortlopend deel van langlopende schuld aan overige verbonden maatschappij 5",
    descriptionLong:
      "Kortlopend deel van langlopende schulden aan overige verbonden maatschappijen schulden aan overige verbonden maatschappijen",
  },
  {
    code: "BSchSaoSo5Tbr",
    descriptionShort: "Te betalen rente",
    descriptionLong:
      "Te betalen rente schulden aan overige verbonden maatschappijen",
  },
  {
    code: "BSchSaoSo5Rbo",
    descriptionShort: "Rekening-courant bij overige verbonden maatschappij 5",
    descriptionLong:
      "Rekening-courant bij overige verbonden maatschappijen schulden aan overige verbonden maatschappijen",
  },
  {
    code: "BSchSap",
    descriptionShort:
      "Schulden aan participanten en aan maatschappijen waarin wordt deelgenomen",
    descriptionLong:
      "Schulden aan participanten en aan maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BSchSapSp1",
    descriptionShort:
      "Schulden aan participant en aan maatschappij waarin wordt deelgenomen 1",
    descriptionLong:
      "Schulden aan participant en aan maatschappij waarin wordt deelgenomen 1",
  },
  {
    code: "BSchSapSp1Klp",
    descriptionShort:
      "Kortlopend deel van langlopende schuld aan participant en maatschappij waarin wordt deelgenomen 1",
    descriptionLong:
      "Kortlopend deel van langlopende schulden aan participanten en maatschappijen waarin wordt deelgenomen schulden aan participanten en aan maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BSchSapSp1Tbr",
    descriptionShort: "Te betalen rente",
    descriptionLong:
      "Te betalen rente schulden aan participanten en aan maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BSchSapSp1Rbp",
    descriptionShort:
      "Rekening-courant bij participant en maatschappij waarin wordt deelgenomen 1",
    descriptionLong:
      "Rekening-courant bij participanten en maatschappijen waarin wordt deelgenomen schulden aan participanten en aan maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BSchSapSp2",
    descriptionShort:
      "Schulden aan participant en aan maatschappij waarin wordt deelgenomen 2",
    descriptionLong:
      "Schulden aan participant en aan maatschappij waarin wordt deelgenomen 2",
  },
  {
    code: "BSchSapSp2Klp",
    descriptionShort:
      "Kortlopend deel van langlopende schuld aan participant en maatschappij waarin wordt deelgenomen 2",
    descriptionLong:
      "Kortlopend deel van langlopende schulden aan participanten en maatschappijen waarin wordt deelgenomen schulden aan participanten en aan maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BSchSapSp2Tbr",
    descriptionShort: "Te betalen rente",
    descriptionLong:
      "Te betalen rente schulden aan participanten en aan maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BSchSapSp2Rbp",
    descriptionShort:
      "Rekening-courant bij participant en maatschappij waarin wordt deelgenomen 2",
    descriptionLong:
      "Rekening-courant bij participanten en maatschappijen waarin wordt deelgenomen schulden aan participanten en aan maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BSchSapSp3",
    descriptionShort:
      "Schulden aan participant en aan maatschappij waarin wordt deelgenomen 3",
    descriptionLong:
      "Schulden aan participant en aan maatschappij waarin wordt deelgenomen 3",
  },
  {
    code: "BSchSapSp3Klp",
    descriptionShort:
      "Kortlopend deel van langlopende schuld aan participant en maatschappij waarin wordt deelgenomen 3",
    descriptionLong:
      "Kortlopend deel van langlopende schulden aan participanten en maatschappijen waarin wordt deelgenomen schulden aan participanten en aan maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BSchSapSp3Tbr",
    descriptionShort: "Te betalen rente",
    descriptionLong:
      "Te betalen rente schulden aan participanten en aan maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BSchSapSp3Rbp",
    descriptionShort:
      "Rekening-courant bij participant en maatschappij waarin wordt deelgenomen 3",
    descriptionLong:
      "Rekening-courant bij participanten en maatschappijen waarin wordt deelgenomen schulden aan participanten en aan maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BSchSapSp4",
    descriptionShort:
      "Schulden aan participant en aan maatschappij waarin wordt deelgenomen 4",
    descriptionLong:
      "Schulden aan participant en aan maatschappij waarin wordt deelgenomen 4",
  },
  {
    code: "BSchSapSp4Klp",
    descriptionShort:
      "Kortlopend deel van langlopende schuld aan participant en maatschappij waarin wordt deelgenomen 4",
    descriptionLong:
      "Kortlopend deel van langlopende schulden aan participanten en maatschappijen waarin wordt deelgenomen schulden aan participanten en aan maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BSchSapSp4Tbr",
    descriptionShort: "Te betalen rente",
    descriptionLong:
      "Te betalen rente schulden aan participanten en aan maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BSchSapSp4Rbp",
    descriptionShort:
      "Rekening-courant bij participant en maatschappij waarin wordt deelgenomen 4",
    descriptionLong:
      "Rekening-courant bij participanten en maatschappijen waarin wordt deelgenomen schulden aan participanten en aan maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BSchSapSp5",
    descriptionShort:
      "Schulden aan participant en aan maatschappij waarin wordt deelgenomen 5",
    descriptionLong:
      "Schulden aan participant en aan maatschappij waarin wordt deelgenomen 5",
  },
  {
    code: "BSchSapSp5Klp",
    descriptionShort:
      "Kortlopend deel van langlopende schuld aan participant en maatschappij waarin wordt deelgenomen 5",
    descriptionLong:
      "Kortlopend deel van langlopende schulden aan participanten en maatschappijen waarin wordt deelgenomen schulden aan participanten en aan maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BSchSapSp5Tbr",
    descriptionShort: "Te betalen rente",
    descriptionLong:
      "Te betalen rente schulden aan participanten en aan maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BSchSapSp5Rbp",
    descriptionShort:
      "Rekening-courant bij participant en maatschappij waarin wordt deelgenomen 5",
    descriptionLong:
      "Rekening-courant bij participanten en maatschappijen waarin wordt deelgenomen schulden aan participanten en aan maatschappijen waarin wordt deelgenomen",
  },
  {
    code: "BSchShb",
    descriptionShort: "Schulden uit hoofde van belasting naar de winst",
    descriptionLong: "Schulden uit hoofde van belasting naar de winst",
  },
  {
    code: "BSchShbShb",
    descriptionShort: "Schulden uit hoofde van belasting naar de winst",
    descriptionLong: "Schulden uit hoofde van belasting naar de winst",
  },
  {
    code: "BSchFlk",
    descriptionShort: "Kortlopende financiële lease verplichtingen",
    descriptionLong: "Kortlopende financiële lease verplichtingen",
  },
  {
    code: "BSchFlkFlk",
    descriptionShort: "Financiële lease verplichtingen",
    descriptionLong: "Financiële lease verplichtingen",
  },
  {
    code: "BSchBep",
    descriptionShort: "Belastingen en premies sociale verzekeringen",
    descriptionLong: "Belastingen en premies sociale verzekeringen",
  },
  {
    code: "BSchBepBtw",
    descriptionShort: "Te betalen Omzetbelasting",
    descriptionLong: "Te betalen Omzetbelasting",
  },
  {
    code: "BSchBepBtwBeg",
    descriptionShort: "Beginbalans af te dragen omzetbelasting",
    descriptionLong: "Beginbalans af te dragen omzetbelasting",
  },
  {
    code: "BSchBepBtwOla",
    descriptionShort:
      "1a. Omzetbelasting leveringen/diensten belast met hoog tarief ",
    descriptionLong: "Omzetbelasting leveringen/diensten algemeen tarief ",
  },
  {
    code: "BSchBepBtwOlv",
    descriptionShort:
      "1b. Omzetbelasting leveringen/diensten belast met laag tarief ",
    descriptionLong: "Omzetbelasting leveringen/diensten verlaagd tarief ",
  },
  {
    code: "BSchBepBtwOlt",
    descriptionShort: "Omzetbelasting leveringen/diensten verlaagd tarief 9%",
    descriptionLong: "Omzetbelasting leveringen/diensten verlaagd tarief 9%",
  },
  {
    code: "BSchBepBtwOlo",
    descriptionShort:
      "1c. Omzetbelasting leveringen/diensten belast met overige tarieven, behalve 0%",
    descriptionLong: "Omzetbelasting leveringen/diensten overige tarieven ",
  },
  {
    code: "BSchBepBtwOop",
    descriptionShort: "1d. Omzetbelasting over privégebruik ",
    descriptionLong: "Omzetbelasting over privégebruik ",
  },
  {
    code: "BSchBepBtwOlw",
    descriptionShort:
      "2a. Omzetbelasting leveringen/diensten waarbij de omzetbelasting naar u is verlegd ",
    descriptionLong:
      "Omzetbelasting leveringen/diensten waarbij heffing is verlegd ",
  },
  {
    code: "BSchBepBtwOlb",
    descriptionShort:
      "4a. Omzetbelasting leveringen/diensten uit landen buiten de EU",
    descriptionLong:
      "Omzetbelasting leveringen/diensten uit landen buiten de EU ",
  },
  {
    code: "BSchBepBtwOlu",
    descriptionShort:
      "4b. Omzetbelasting leveringen/diensten uit landen binnen EU ",
    descriptionLong: "Omzetbelasting leveringen/diensten uit landen binnen EU ",
  },
  {
    code: "BSchBepBtwVoo",
    descriptionShort: "5b. Voorbelasting ",
    descriptionLong: "Voorbelasting ",
  },
  {
    code: "BSchBepBtwVvd",
    descriptionShort: "5d. Vermindering volgens de kleineondernemersregeling ",
    descriptionLong: "Vermindering volgens de kleineondernemersregeling ",
  },
  {
    code: "BSchBepBtwSva",
    descriptionShort: "Schatting vorige aangifte(n) ",
    descriptionLong: "Schatting vorige aangifte(n) ",
  },
  {
    code: "BSchBepBtwSda",
    descriptionShort: "Schatting deze aangifte ",
    descriptionLong: "Schatting deze aangifte ",
  },
  {
    code: "BSchBepBtwAfo",
    descriptionShort: "Afgedragen omzetbelasting ",
    descriptionLong: "Afgedragen omzetbelasting ",
  },
  {
    code: "BSchBepBtwNah",
    descriptionShort: "Naheffingsaanslagen omzetbelasting",
    descriptionLong: "Naheffingsaanslagen omzetbelasting",
  },
  {
    code: "BSchBepBtwOvm",
    descriptionShort: "Overige mutaties omzetbelasting",
    descriptionLong: "Overige mutaties omzetbelasting",
  },
  {
    code: "BSchBepEob",
    descriptionShort: "Te betalen EU omzetbelasting",
    descriptionLong: "Te betalen EU omzetbelasting",
  },
  {
    code: "BSchBepBaf",
    descriptionShort: "Omzetbelasting afdracht",
    descriptionLong: "Omzetbelasting afdracht",
  },
  {
    code: "BSchBepLhe",
    descriptionShort: "Te betalen Loonheffing",
    descriptionLong:
      "Te betalen Loonheffing belastingen en premies sociale verzekeringen",
  },
  {
    code: "BSchBepLheBeg",
    descriptionShort: "Beginbalans af te dragen loonheffing ",
    descriptionLong: "Beginbalans af te dragen loonheffing ",
  },
  {
    code: "BSchBepLheAal",
    descriptionShort: "Aangifte loonheffing ",
    descriptionLong: "Aangifte loonheffing ",
  },
  {
    code: "BSchBepLheAlh",
    descriptionShort: "Afgedragen Loonheffing ",
    descriptionLong: "Afgedragen Loonheffing ",
  },
  {
    code: "BSchBepLheNah",
    descriptionShort: "Naheffingsaanslagen loonheffing",
    descriptionLong: "Naheffingsaanslagen loonheffing",
  },
  {
    code: "BSchBepLheOvm",
    descriptionShort: "Overige mutaties loonheffing",
    descriptionLong: "Overige mutaties loonheffing",
  },
  {
    code: "BSchBepVpb",
    descriptionShort: "Te betalen Vennootschapsbelasting",
    descriptionLong: "Te betalen Vennootschapsbelasting",
  },
  {
    code: "BSchBepVpbBeg",
    descriptionShort: "Beginbalans af te dragen vennootschapsbelasting",
    descriptionLong: "Beginbalans af te dragen vennootschapsbelasting",
  },
  {
    code: "BSchBepVpbAav",
    descriptionShort: "Aangifte vennootschapsbelasting ",
    descriptionLong: "Aangifte vennootschapsbelasting ",
  },
  {
    code: "BSchBepVpbAgv",
    descriptionShort:
      "Voorlopige aanslag vennootschapsbelasting huidig boekjaar",
    descriptionLong:
      "Voorlopige aanslag vennootschapsbelasting huidig boekjaar",
  },
  {
    code: "BSchBepVpbVav",
    descriptionShort:
      "Voorlopige aanslag vennootschapsbelasting voorgaande boekjaren",
    descriptionLong:
      "Voorlopige aanslag vennootschapsbelasting voorgaande boekjaren",
  },
  {
    code: "BSchBepVpbTvv",
    descriptionShort: "Te verrekenen vennootschapsbelasting ",
    descriptionLong: "Te verrekenen vennootschapsbelasting ",
  },
  {
    code: "BSchBepVpbAfv",
    descriptionShort: "Afgedragen vennootschapsbelasting ",
    descriptionLong: "Afgedragen vennootschapsbelasting ",
  },
  {
    code: "BSchBepVpbNah",
    descriptionShort: "Naheffingsaanslagen vennootschapsbelasting",
    descriptionLong: "Naheffingsaanslagen vennootschapsbelasting",
  },
  {
    code: "BSchBepVpbOvm",
    descriptionShort: "Overige mutaties vennootschapsbelasting",
    descriptionLong: "Overige mutaties vennootschapsbelasting",
  },
  {
    code: "BSchBepOvb",
    descriptionShort: "Overige belastingen",
    descriptionLong: "Overige belastingen",
  },
  {
    code: "BSchBepOvbBib",
    descriptionShort: "Binnenlandse belastingen",
    descriptionLong: "Binnenlandse belastingen",
  },
  {
    code: "BSchBepOvbBut",
    descriptionShort: "Buitenlandse belastingen",
    descriptionLong: "Buitenlandse belastingen",
  },
  {
    code: "BSchBepOvbPrb",
    descriptionShort: "Provinciale belastingen",
    descriptionLong: "Provinciale belastingen",
  },
  {
    code: "BSchBepOvbGbe",
    descriptionShort: "Gemeentelijke belastingen",
    descriptionLong: "Gemeentelijke belastingen",
  },
  {
    code: "BSchBepOvbBgd",
    descriptionShort:
      "Belastingen op verkochte goederen en diensten uitgezonderd BTW",
    descriptionLong:
      "Belastingen op verkochte goederen en diensten uitgezonderd BTW",
  },
  {
    code: "BSchBepOvbTdb",
    descriptionShort: "Te betalen Dividendbelasting",
    descriptionLong:
      "Te betalen Dividendbelasting belastingen en premies sociale verzekeringen",
  },
  {
    code: "BSchBepOvbOvb",
    descriptionShort: "Te betalen overige belastingen",
    descriptionLong:
      "Te betalen overige belastingen belastingen en premies sociale verzekeringen",
  },
  {
    code: "BSchStz",
    descriptionShort: "Schulden ter zake van pensioenen",
    descriptionLong: "Schulden ter zake van pensioenen",
  },
  {
    code: "BSchStzPen",
    descriptionShort: "Te betalen pensioenuitkeringen",
    descriptionLong:
      "Te betalen pensioenuitkeringen schulden ter zake van pensioenen",
  },
  {
    code: "BSchAos",
    descriptionShort: "Aflossingsverplichtingen van langlopende leningen",
    descriptionLong: "Aflossingsverplichtingen van langlopende leningen",
  },
  {
    code: "BSchAosHvk",
    descriptionShort: "Hypotheken van kredietinstellingen (kortlopend)",
    descriptionLong: "Hypotheken van kredietinstellingen (kortlopend)",
  },
  {
    code: "BSchAosLvk",
    descriptionShort: "Leningen van kredietinstellingen (kortlopend)",
    descriptionLong: "Leningen van kredietinstellingen (kortlopend)",
  },
  {
    code: "BSchAosFvk",
    descriptionShort: "Financieringen van kredietinstellingen (kortlopend)",
    descriptionLong: "Financieringen van kredietinstellingen (kortlopend)",
  },
  {
    code: "BSchAosAos",
    descriptionShort: "Aflossingsverplichtingen",
    descriptionLong: "Aflossingsverplichtingen overige schulden",
  },
  {
    code: "BSchAosMvl",
    descriptionShort:
      "Marktwaardecorrectie van de vastrentende lening (kortlopend)",
    descriptionLong:
      "Marktwaardecorrectie van de vastrentende lening (kortlopend)",
  },
  {
    code: "BSchOpp",
    descriptionShort: "Onderhanden projecten (passiva) overige schulden",
    descriptionLong: "Onderhanden projecten (passiva) overige schulden",
  },
  {
    code: "BSchOppOpp",
    descriptionShort: "Onderhanden projecten (passiva)",
    descriptionLong: "Onderhanden projecten (passiva) overige schulden",
  },
  {
    code: "BSchOppOppGkn",
    descriptionShort:
      "Onderhanden projecten in opdracht van derden, geactiveerde kosten voor nog niet geleverde diensten",
    descriptionLong:
      "Onderhanden projecten in opdracht van derden, geactiveerde kosten voor nog niet geleverde diensten",
  },
  {
    code: "BSchOppOppOpo",
    descriptionShort:
      "Onderhanden projecten in opdracht van derden, cumulatieve projectopbrengsten",
    descriptionLong:
      "Onderhanden projecten in opdracht van derden, cumulatieve projectopbrengsten",
  },
  {
    code: "BSchOppOppOpv",
    descriptionShort:
      "Onderhanden projecten in opdracht van derden, voorschotten",
    descriptionLong:
      "Onderhanden projecten in opdracht van derden, voorschotten",
  },
  {
    code: "BSchOppOppGet",
    descriptionShort: "Gefactureerde termijnen",
    descriptionLong: "Gefactureerde termijnen",
  },
  {
    code: "BSchOppOppOpi",
    descriptionShort:
      "Onderhanden projecten in opdracht van derden, inhoudingen op gedeclareerde termijnen",
    descriptionLong:
      "Onderhanden projecten in opdracht van derden, inhoudingen op gedeclareerde termijnen",
  },
  {
    code: "BSchOppOppVzv",
    descriptionShort: "Voorziening verliezen",
    descriptionLong: "Voorziening verliezen",
  },
  {
    code: "BSchOppOppWin",
    descriptionShort: "Winstopslag onderhanden projecten",
    descriptionLong: "Winstopslag onderhanden projecten",
  },
  {
    code: "BSchSal",
    descriptionShort: "Salarisverwerking",
    descriptionLong: "Salarisverwerking",
  },
  {
    code: "BSchSalNet",
    descriptionShort: "Netto lonen",
    descriptionLong: "Netto lonen overige schulden",
  },
  {
    code: "BSchSalVpe",
    descriptionShort: "Voorschotten personeel",
    descriptionLong: "Voorschotten personeel",
  },
  {
    code: "BSchSalTan",
    descriptionShort: "Tantièmes",
    descriptionLong: "Tantièmes overige schulden",
  },
  {
    code: "BSchSalTvg",
    descriptionShort: "Te betalen vakantiegeld",
    descriptionLong: "Te betalen vakantiegeld overige schulden",
  },
  {
    code: "BSchSalTbv",
    descriptionShort: "Reservering vakantiedagen",
    descriptionLong: "Reservering vakantiedagen overige schulden",
  },
  {
    code: "BSchSalVab",
    descriptionShort: "Vakantiebonnen",
    descriptionLong: "Vakantiebonnen",
  },
  {
    code: "BSchSalBls",
    descriptionShort: "Bruto lonen en salarissen",
    descriptionLong: "Bruto lonen en salarissen",
  },
  {
    code: "BSchSalOrn",
    descriptionShort: "Overige reserveringen",
    descriptionLong: "Overige reserveringen overige schulden",
  },
  {
    code: "BSchSalPsv",
    descriptionShort: "Premies sociale verzekeringen",
    descriptionLong: "Premies sociale verzekeringen overige schulden",
  },
  {
    code: "BSchSalPer",
    descriptionShort: "Personeelsfonds / personeelsvereniging",
    descriptionLong: "Personeelsfonds / personeelsvereniging",
  },
  {
    code: "BSchSalOna",
    descriptionShort: "Overige netto-afdrachten",
    descriptionLong: "Overige netto-afdrachten",
  },
  {
    code: "BSchSalGra",
    descriptionShort: "Gratificaties",
    descriptionLong: "Gratificaties",
  },
  {
    code: "BSchSalOsf",
    descriptionShort: "Te betalen overige sociale fondsen",
    descriptionLong: "Te betalen overige sociale fondsen",
  },
  {
    code: "BSchSalReu",
    descriptionShort: "Reservering eindejaarsuitkering",
    descriptionLong: "Reservering eindejaarsuitkering",
  },
  {
    code: "BSchOvs",
    descriptionShort: "Overige schulden",
    descriptionLong: "Overige schulden",
  },
  {
    code: "BSchOvsRcb",
    descriptionShort: "Rekening-courant bestuurders (Kortlopend)",
    descriptionLong: "Rekening-courant bestuurders (Kortlopend)",
  },
  {
    code: "BSchOvsRcbRb1",
    descriptionShort: "Rekening-courant bestuurder 1",
    descriptionLong: "Rekening-courant bestuurder 1",
  },
  {
    code: "BSchOvsRcbRb2",
    descriptionShort: "Rekening-courant bestuurder 2",
    descriptionLong: "Rekening-courant bestuurder 2",
  },
  {
    code: "BSchOvsRcbRb3",
    descriptionShort: "Rekening-courant bestuurder 3",
    descriptionLong: "Rekening-courant bestuurder 3",
  },
  {
    code: "BSchOvsRcbRb4",
    descriptionShort: "Rekening-courant bestuurder 4",
    descriptionLong: "Rekening-courant bestuurder 4",
  },
  {
    code: "BSchOvsRcbRb5",
    descriptionShort: "Rekening-courant bestuurder 5",
    descriptionLong: "Rekening-courant bestuurder 5",
  },
  {
    code: "BSchOvsRcc",
    descriptionShort: "Rekening-courant commissarissen (Kortlopend)",
    descriptionLong: "Rekening-courant commissarissen (Kortlopend)",
  },
  {
    code: "BSchOvsRccRc1",
    descriptionShort: "Rekening-courant commissaris 1",
    descriptionLong: "Rekening-courant commissaris 1",
  },
  {
    code: "BSchOvsRccRc2",
    descriptionShort: "Rekening-courant commissaris 2",
    descriptionLong: "Rekening-courant commissaris 2",
  },
  {
    code: "BSchOvsRccRc3",
    descriptionShort: "Rekening-courant commissaris 3",
    descriptionLong: "Rekening-courant commissaris 3",
  },
  {
    code: "BSchOvsRccRc4",
    descriptionShort: "Rekening-courant commissaris 4",
    descriptionLong: "Rekening-courant commissaris 4",
  },
  {
    code: "BSchOvsRccRc5",
    descriptionShort: "Rekening-courant commissaris 5",
    descriptionLong: "Rekening-courant commissaris 5",
  },
  {
    code: "BSchOvsRco",
    descriptionShort: "Rekening-courant overigen (Kortlopend)",
    descriptionLong: "Rekening-courant overigen (Kortlopend)",
  },
  {
    code: "BSchOvsRcoRo1",
    descriptionShort: "Rekening-courant overige 1",
    descriptionLong: "Rekening-courant overige 1",
  },
  {
    code: "BSchOvsRcoRo2",
    descriptionShort: "Rekening-courant overige 2",
    descriptionLong: "Rekening-courant overige 2",
  },
  {
    code: "BSchOvsRcoRo3",
    descriptionShort: "Rekening-courant overige 3",
    descriptionLong: "Rekening-courant overige 3",
  },
  {
    code: "BSchOvsRcoRo4",
    descriptionShort: "Rekening-courant overige 4",
    descriptionLong: "Rekening-courant overige 4",
  },
  {
    code: "BSchOvsRcoRo5",
    descriptionShort: "Rekening-courant overige 5",
    descriptionLong: "Rekening-courant overige 5",
  },
  {
    code: "BSchOvsSaa",
    descriptionShort: "Schulden aan aandeelhouders",
    descriptionLong: "Schulden aan aandeelhouders",
  },
  {
    code: "BSchOvsSaaRa1",
    descriptionShort: "Rekening-courant aandeelhouder 1",
    descriptionLong: "Rekening-courant aandeelhouder 1",
  },
  {
    code: "BSchOvsSaaRa2",
    descriptionShort: "Rekening-courant aandeelhouder 2",
    descriptionLong: "Rekening-courant aandeelhouder 2",
  },
  {
    code: "BSchOvsSaaRa3",
    descriptionShort: "Rekening-courant aandeelhouder 3",
    descriptionLong: "Rekening-courant aandeelhouder 3",
  },
  {
    code: "BSchOvsSaaRa4",
    descriptionShort: "Rekening-courant aandeelhouder 4",
    descriptionLong: "Rekening-courant aandeelhouder 4",
  },
  {
    code: "BSchOvsSaaRa5",
    descriptionShort: "Rekening-courant aandeelhouder 5",
    descriptionLong: "Rekening-courant aandeelhouder 5",
  },
  {
    code: "BSchOvsOvi",
    descriptionShort: "Overige verplichtingen inzake personeel",
    descriptionLong: "Overige verplichtingen inzake personeel overige schulden",
  },
  {
    code: "BSchOvsTbd",
    descriptionShort: "Te betalen dividenduitkeringen",
    descriptionLong: "Te betalen dividenduitkeringen overige schulden",
  },
  {
    code: "BSchOvsGif",
    descriptionShort: "Giftenverplichtingen",
    descriptionLong: "Giftenverplichtingen",
  },
  {
    code: "BSchOvsSuv",
    descriptionShort: "Terugbetaling subsidies",
    descriptionLong: "Terugbetaling subsidies overige schulden",
  },
  {
    code: "BSchOvsStp",
    descriptionShort: "Te betalen pensioenpremies",
    descriptionLong:
      "Te betalen pensioenpremies schulden ter zake van pensioenen",
  },
  {
    code: "BSchOvsVvv",
    descriptionShort:
      "Verplichtingen uit hoofde van onroerende zaken verkocht onder voorwaarden",
    descriptionLong:
      "Verplichtingen uit hoofde van onroerende zaken verkocht onder voorwaarden",
  },
  {
    code: "BSchOvsVpo",
    descriptionShort: "Verplichtingen aan overheid",
    descriptionLong: "Verplichtingen aan overheid",
  },
  {
    code: "BSchOvsOvs",
    descriptionShort: "Overige schulden",
    descriptionLong: "Overige schulden overige schulden",
  },
  {
    code: "BSchOvsWaa",
    descriptionShort: "Waarborgsommen",
    descriptionLong: "Waarborgsommen overige schulden",
  },
  {
    code: "BSchOvsLed",
    descriptionShort: "Ledenrekeningen",
    descriptionLong: "Ledenrekeningen overige schulden",
  },
  {
    code: "BSchOvsLoy",
    descriptionShort: "Loyalty / membersaldo",
    descriptionLong: "Loyalty / membersaldo",
  },
  {
    code: "BSchOpa",
    descriptionShort: "Overlopende passiva",
    descriptionLong: "Overlopende passiva",
  },
  {
    code: "BSchOpaNto",
    descriptionShort: "Nog te ontvangen facturen",
    descriptionLong: "Nog te ontvangen facturen overlopende passiva",
  },
  {
    code: "BSchOpaNtb",
    descriptionShort: "Nog te betalen andere kosten",
    descriptionLong: "Nog te betalen andere kosten overlopende passiva",
  },
  {
    code: "BSchOpaTbr",
    descriptionShort: "Te betalen rente",
    descriptionLong: "Te betalen rente overlopende passiva",
  },
  {
    code: "BSchOpaVor",
    descriptionShort: "Vooruitontvangen rente",
    descriptionLong: "Vooruitontvangen rente overlopende passiva",
  },
  {
    code: "BSchOpaOop",
    descriptionShort: "Overige overlopende passiva",
    descriptionLong: "Overige overlopende passiva overlopende passiva",
  },
  {
    code: "BSchOpaNbs",
    descriptionShort: "Nog te betalen servicekosten",
    descriptionLong: "Nog te betalen servicekosten",
  },
  {
    code: "BSchOpaNom",
    descriptionShort: "Nog te betalen omzetbonificaties",
    descriptionLong: "Nog te betalen omzetbonificaties overlopende passiva",
  },
  {
    code: "BSchOpaNpr",
    descriptionShort: "Nog te betalen provisies",
    descriptionLong: "Nog te betalen provisies overlopende passiva",
  },
  {
    code: "BSchOpaNbh",
    descriptionShort: "Nog te betalen huren",
    descriptionLong: "Nog te betalen huren overlopende passiva",
  },
  {
    code: "BSchOpaNve",
    descriptionShort: "Nog te betalen vergoedingen",
    descriptionLong: "Nog te betalen vergoedingen overlopende passiva",
  },
  {
    code: "BSchOpaNbi",
    descriptionShort: "Nog te betalen bijdragen",
    descriptionLong: "Nog te betalen bijdragen overlopende passiva",
  },
  {
    code: "BSchOpaNpe",
    descriptionShort: "Nog te betalen personeelskosten",
    descriptionLong: "Nog te betalen personeelskosten overlopende passiva",
  },
  {
    code: "BSchOpaNhv",
    descriptionShort: "Nog te betalen huisvestingskosten",
    descriptionLong: "Nog te betalen huisvestingskosten overlopende passiva",
  },
  {
    code: "BSchOpaNee",
    descriptionShort: "Nog te betalen exploitatie- en machinekosten",
    descriptionLong:
      "Nog te betalen exploitatie- en machinekosten overlopende passiva",
  },
  {
    code: "BSchOpaNvk",
    descriptionShort: "Nog te betalen verkoopkosten",
    descriptionLong: "Nog te betalen verkoopkosten overlopende passiva",
  },
  {
    code: "BSchOpaNak",
    descriptionShort: "Nog te betalen autokosten",
    descriptionLong: "Nog te betalen autokosten overlopende passiva",
  },
  {
    code: "BSchOpaNtk",
    descriptionShort: "Nog te betalen transportkosten",
    descriptionLong: "Nog te betalen transportkosten overlopende passiva",
  },
  {
    code: "BSchOpaNkk",
    descriptionShort: "Nog te betalen kantoorkosten",
    descriptionLong: "Nog te betalen kantoorkosten overlopende passiva",
  },
  {
    code: "BSchOpaNok",
    descriptionShort: "Nog te betalen organisatiekosten",
    descriptionLong: "Nog te betalen organisatiekosten overlopende passiva",
  },
  {
    code: "BSchOpaNas",
    descriptionShort: "Nog te betalen assurantiekosten",
    descriptionLong: "Nog te betalen assurantiekosten overlopende passiva",
  },
  {
    code: "BSchOpaNaa",
    descriptionShort: "Nog te betalen accountants- en advieskosten",
    descriptionLong:
      "Nog te betalen accountants- en advieskosten overlopende passiva",
  },
  {
    code: "BSchOpaNad",
    descriptionShort: "Nog te betalen administratiekosten",
    descriptionLong: "Nog te betalen administratiekosten overlopende passiva",
  },
  {
    code: "BSchOpaNkf",
    descriptionShort: "Nog te betalen kosten fondsenwerving",
    descriptionLong: "Nog te betalen kosten fondsenwerving overlopende passiva",
  },
  {
    code: "BSchOpaErf",
    descriptionShort: "Vooruitontvangen erfpacht",
    descriptionLong: "Vooruitontvangen erfpacht",
  },
  {
    code: "BSchOpaHur",
    descriptionShort: "Vooruitontvangen huren",
    descriptionLong: "Vooruitontvangen huren",
  },
  {
    code: "BSchOpaVhr",
    descriptionShort: "Voorstanden huren",
    descriptionLong: "Voorstanden huren",
  },
  {
    code: "BSchOpaKsv",
    descriptionShort: "Kosten S&V verrekenbaar",
    descriptionLong: "Kosten S&V verrekenbaar",
  },
  {
    code: "BSchOpaNao",
    descriptionShort: "Opbrengsten S&V verrekenbaar",
    descriptionLong: "Opbrengsten S&V verrekenbaar",
  },
  {
    code: "BSchOpaVgo",
    descriptionShort: "Vooruitgefactureerde omzet",
    descriptionLong: "Vooruitgefactureerde omzet",
  },
  {
    code: "BSchOpaPen",
    descriptionShort: "Schulden pensioen onder overlopende passiva",
    descriptionLong: "Schulden pensioen onder overlopende passiva",
  },
  {
    code: "BSchTus",
    descriptionShort: "Tussenrekeningen",
    descriptionLong: "Tussenrekeningen",
  },
  {
    code: "BSchTusTbt",
    descriptionShort: "Tussenrekeningen betalingen",
    descriptionLong: "Tussenrekeningen betalingen",
  },
  {
    code: "BSchTusTbtTca",
    descriptionShort: "Tussenrekening contante aanbetalingen",
    descriptionLong:
      "Tussenrekening contante aanbetalingen tussenrekeningen betalingen",
  },
  {
    code: "BSchTusTbtTcb",
    descriptionShort: "Tussenrekening creditcardbetalingen",
    descriptionLong:
      "Tussenrekening creditcardbetalingen tussenrekeningen betalingen",
  },
  {
    code: "BSchTusTsa",
    descriptionShort: "Tussenrekeningen salarissen",
    descriptionLong: "Tussenrekeningen salarissen",
  },
  {
    code: "BSchTusTsaTbn",
    descriptionShort: "Tussenrekening brutoloon",
    descriptionLong: "Tussenrekening brutoloon tussenrekeningen salarissen",
  },
  {
    code: "BSchTusTsaTgb",
    descriptionShort: "Tussenrekening brutoinhouding",
    descriptionLong:
      "Tussenrekening brutoinhouding tussenrekeningen salarissen",
  },
  {
    code: "BSchTusTsaTnl",
    descriptionShort: "Tussenrekening nettoloon",
    descriptionLong: "Tussenrekening nettoloon tussenrekeningen salarissen",
  },
  {
    code: "BSchTusTsaTni",
    descriptionShort: "Tussenrekening nettoinhoudingen",
    descriptionLong:
      "Tussenrekening nettoinhoudingen tussenrekeningen salarissen",
  },
  {
    code: "BSchTusTin",
    descriptionShort: "Tussenrekeningen inkopen",
    descriptionLong: "Tussenrekeningen inkopen",
  },
  {
    code: "BSchTusTinTog",
    descriptionShort: "Tussenrekening nog te ontvangen goederen",
    descriptionLong:
      "Tussenrekening nog te ontvangen goederen tussenrekeningen inkopen",
  },
  {
    code: "BSchTusTinTof",
    descriptionShort: "Tussenrekening nog te ontvangen facturen",
    descriptionLong:
      "Tussenrekening nog te ontvangen facturen tussenrekeningen inkopen",
  },
  {
    code: "BSchTusTinTiv",
    descriptionShort: "Tussenrekening inkoopverschillen",
    descriptionLong:
      "Tussenrekening inkoopverschillen tussenrekeningen inkopen",
  },
  {
    code: "BSchTusTpj",
    descriptionShort: "Tussenrekeningen projecten",
    descriptionLong: "Tussenrekeningen projecten",
  },
  {
    code: "BSchTusTpjTpk",
    descriptionShort: "Tussenrekening projectkosten",
    descriptionLong: "Tussenrekening projectkosten tussenrekeningen projecten",
  },
  {
    code: "BSchTusTpjTpo",
    descriptionShort: "Tussenrekening projectopbrengsten",
    descriptionLong:
      "Tussenrekening projectopbrengsten tussenrekeningen projecten",
  },
  {
    code: "BSchTusTpjTpv",
    descriptionShort: "Tussenrekening projectverschillen",
    descriptionLong:
      "Tussenrekening projectverschillen tussenrekeningen projecten",
  },
  {
    code: "BSchTusTpr",
    descriptionShort: "Tussenrekeningen productie",
    descriptionLong: "Tussenrekeningen productie",
  },
  {
    code: "BSchTusTprTmv",
    descriptionShort: "Tussenrekening materiaalverbruik",
    descriptionLong:
      "Tussenrekening materiaalverbruik tussenrekeningen productie",
  },
  {
    code: "BSchTusTprTmu",
    descriptionShort: "Tussenrekening manuren",
    descriptionLong: "Tussenrekening manuren tussenrekeningen productie",
  },
  {
    code: "BSchTusTprTau",
    descriptionShort: "Tussenrekening machineuren",
    descriptionLong: "Tussenrekening machineuren tussenrekeningen productie",
  },
  {
    code: "BSchTusTprTbu",
    descriptionShort: "Tussenrekening te dekken budget",
    descriptionLong:
      "Tussenrekening te dekken budget tussenrekeningen productie",
  },
  {
    code: "BSchTusTprTbg",
    descriptionShort: "Tussenrekening budget",
    descriptionLong: "Tussenrekening budget tussenrekeningen productie",
  },
  {
    code: "BSchTusTdv",
    descriptionShort: "Tussenrekeningen dienstverlening",
    descriptionLong: "Tussenrekeningen dienstverlening",
  },
  {
    code: "BSchTusTdvTcp",
    descriptionShort: "Tussenrekening capaciteit",
    descriptionLong:
      "Tussenrekening capaciteit tussenrekeningen dienstverlening",
  },
  {
    code: "BSchTusTdvTma",
    descriptionShort: "Tussenrekening materialen",
    descriptionLong:
      "Tussenrekening materialen tussenrekeningen dienstverlening",
  },
  {
    code: "BSchTusTdvTuu",
    descriptionShort: "Tussenrekening uren",
    descriptionLong: "Tussenrekening uren tussenrekeningen dienstverlening",
  },
  {
    code: "BSchTusTdvInv",
    descriptionShort: "Inkomende verschotten",
    descriptionLong: "Inkomende verschotten tussenrekeningen dienstverlening",
  },
  {
    code: "BSchTusTdvVso",
    descriptionShort: "Voorschotten onbelast",
    descriptionLong: "Voorschotten onbelast tussenrekeningen dienstverlening",
  },
  {
    code: "BSchTusTdvVsb",
    descriptionShort: "Voorschotten belast",
    descriptionLong: "Voorschotten belast tussenrekeningen dienstverlening",
  },
  {
    code: "BSchTusTdvDvo",
    descriptionShort: "Doorberekende voorschotten onbelast",
    descriptionLong:
      "Doorberekende voorschotten onbelast tussenrekeningen dienstverlening",
  },
  {
    code: "BSchTusTdvDvb",
    descriptionShort: "Doorberekende voorschotten belast",
    descriptionLong:
      "Doorberekende voorschotten belast tussenrekeningen dienstverlening",
  },
  {
    code: "BSchTusTvr",
    descriptionShort: "Tussenrekening voorraden",
    descriptionLong: "Tussenrekening voorraden",
  },
  {
    code: "BSchTusTvrTvn",
    descriptionShort: "Tussenrekening voorraadverschillen",
    descriptionLong:
      "Tussenrekening voorraadverschillen tussenrekening voorraden",
  },
  {
    code: "BSchTusTvk",
    descriptionShort: "Tussenrekeningen verkopen",
    descriptionLong: "Tussenrekeningen verkopen",
  },
  {
    code: "BSchTusTvkTnf",
    descriptionShort: "Tussenrekening nog te factureren",
    descriptionLong:
      "Tussenrekening nog te factureren tussenrekeningen verkopen",
  },
  {
    code: "BSchTusTvkTng",
    descriptionShort: "Tussenrekening nog te verzenden goederen",
    descriptionLong:
      "Tussenrekening nog te verzenden goederen tussenrekeningen verkopen",
  },
  {
    code: "BSchTusTvkTve",
    descriptionShort: "Tussenrekening verkoopverschillen",
    descriptionLong:
      "Tussenrekening verkoopverschillen tussenrekeningen verkopen",
  },
  {
    code: "BSchTusTon",
    descriptionShort: "Tussenrekeningen ontvangsten",
    descriptionLong: "Tussenrekeningen ontvangsten",
  },
  {
    code: "BSchTusTonTco",
    descriptionShort: "Tussenrekening contante ontvangsten",
    descriptionLong:
      "Tussenrekening contante ontvangsten tussenrekeningen ontvangsten",
  },
  {
    code: "BSchTusTonTcv",
    descriptionShort: "Tussenrekening creditcardverkopen",
    descriptionLong:
      "Tussenrekening creditcardverkopen tussenrekeningen ontvangsten",
  },
  {
    code: "BSchTusTov",
    descriptionShort: "Tussenrekeningen overig (Vraagposten)",
    descriptionLong: "Tussenrekeningen overig (Vraagposten)",
  },
  {
    code: "BSchTusTovTbb",
    descriptionShort: "Tussenrekening beginbalans",
    descriptionLong: "Tussenrekening beginbalans tussenrekeningen overig",
  },
  {
    code: "BSchTusTovTvp",
    descriptionShort: "Tussenrekening vraagposten",
    descriptionLong: "Tussenrekening vraagposten tussenrekeningen overig",
  },
  {
    code: "BSchTusTovTov",
    descriptionShort: "Tussenrekening overige",
    descriptionLong: "Tussenrekening overige tussenrekeningen overig",
  },
  {
    code: "BSchTusLen",
    descriptionShort: "Tussenrekeningen leningen",
    descriptionLong: "Tussenrekeningen leningen",
  },
  {
    code: "BSchTusLenLog",
    descriptionShort: "Tussenrekening leningen OG",
    descriptionLong: "Tussenrekening leningen OG",
  },
  {
    code: "BSchTusLenLug",
    descriptionShort: "Tussenrekening leningen UG",
    descriptionLong: "Tussenrekening leningen UG",
  },
  {
    code: "BSchTusLenKog",
    descriptionShort: "Tussenrekening kasgeld OG",
    descriptionLong: "Tussenrekening kasgeld OG",
  },
  {
    code: "BSchTusLenKug",
    descriptionShort: "Tussenrekening kasgeld UG",
    descriptionLong: "Tussenrekening kasgeld UG",
  },
  {
    code: "BSchTusLenSde",
    descriptionShort: "Tussenrekening spaardeposito",
    descriptionLong: "Tussenrekening spaardeposito",
  },
  {
    code: "BSchTusLenDer",
    descriptionShort: "Tussenrekening derivaten",
    descriptionLong: "Tussenrekening derivaten",
  },
  {
    code: "BSchTusLenCfv",
    descriptionShort: "Tussenrekening leningen CFV",
    descriptionLong: "Tussenrekening leningen CFV",
  },
  {
    code: "BSchDha",
    descriptionShort: "Uit te keren dividend aan houders van aandelen",
    descriptionLong: "Uit te keren dividend aan houders van aandelen",
  },
  {
    code: "BSchDhaDha",
    descriptionShort: "Uit te keren dividend aan houders van aandelen",
    descriptionLong: "Uit te keren dividend aan houders van aandelen",
  },
  {
    code: "BSchDhaVde",
    descriptionShort:
      "Voorgestelde bedrag aan dividenduitkeringen aan houders van eigenvermogensinstrumenten",
    descriptionLong:
      "Voorgestelde bedrag aan dividenduitkeringen aan houders van eigenvermogensinstrumenten",
  },
  {
    code: "BSchDhp",
    descriptionShort:
      "Uit te keren dividend aan houders van preferente aandelen",
    descriptionLong:
      "Uit te keren dividend aan houders van preferente aandelen",
  },
  {
    code: "BSchDhpDhp",
    descriptionShort:
      "Uit te keren dividend aan houders van preferente aandelen",
    descriptionLong:
      "Uit te keren dividend aan houders van preferente aandelen",
  },
  {
    code: "BSchSdn",
    descriptionShort: "Schulden aan daeb-niet daeb",
    descriptionLong: "Schulden aan daeb-niet daeb",
  },
  {
    code: "BSchSdnDae",
    descriptionShort: "Schulden aan daeb tak",
    descriptionLong: "Schulden aan daeb tak",
  },
  {
    code: "BSchSdnNda",
    descriptionShort: "Schulden aan niet-daeb tak",
    descriptionLong: "Schulden aan niet-daeb tak",
  },
  {
    code: "BSchSlc",
    descriptionShort: "Schulden aan leden van de coöperatie",
    descriptionLong: "Schulden aan leden van de coöperatie",
  },
  {
    code: "BSchSlcLi1",
    descriptionShort: "Schuld aan lid A van de coöperatie",
    descriptionLong: "Schuld aan lid A van de coöperatie",
  },
  {
    code: "BSchSlcLi2",
    descriptionShort: "Schuld aan lid B van de coöperatie",
    descriptionLong: "Schuld aan lid B van de coöperatie",
  },
  {
    code: "BSchSlcLi3",
    descriptionShort: "Schuld aan lid C van de coöperatie",
    descriptionLong: "Schuld aan lid C van de coöperatie",
  },
  {
    code: "BSchSlcLi4",
    descriptionShort: "Schuld aan lid D van de coöperatie",
    descriptionLong: "Schuld aan lid D van de coöperatie",
  },
  {
    code: "BSchSlcLi5",
    descriptionShort: "Schuld aan lid E van de coöperatie",
    descriptionLong: "Schuld aan lid E van de coöperatie",
  },
  {
    code: "W",
    descriptionShort: "WINST- EN VERLIESREKENING",
    descriptionLong: "WINST- EN VERLIESREKENING",
  },
  {
    code: "WOmz",
    descriptionShort: "Netto-omzet",
    descriptionLong: "Netto-omzet",
  },
  {
    code: "WOmzNop",
    descriptionShort: "Netto-omzet uit leveringen geproduceerde goederen",
    descriptionLong:
      "Netto-omzet uit leveringen geproduceerde goederen opbrengsten uit de verkoop van goederen",
  },
  {
    code: "WOmzNopOlh",
    descriptionShort:
      "1a. Netto-omzet uit leveringen geproduceerde goederen belast met hoog tarief",
    descriptionLong:
      "1a. Netto-omzet uit leveringen geproduceerde goederen belast met hoog tarief",
  },
  {
    code: "WOmzNopOlv",
    descriptionShort:
      "1b. Netto-omzet uit leveringen geproduceerde goederen belast met laag tarief",
    descriptionLong:
      "1b. Netto-omzet uit leveringen geproduceerde goederen belast met laag tarief",
  },
  {
    code: "WOmzNopOlo",
    descriptionShort:
      "1c. Netto-omzet uit leveringen geproduceerde goederen belast met overige tarieven, behalve 0%",
    descriptionLong:
      "1c. Netto-omzet uit leveringen geproduceerde goederen belast met overige tarieven, behalve 0%",
  },
  {
    code: "WOmzNopOpg",
    descriptionShort: "1d. Netto-omzet uit privégebruik geproduceerde goederen",
    descriptionLong: "1d. Netto-omzet uit privégebruik geproduceerde goederen",
  },
  {
    code: "WOmzNopOlg",
    descriptionShort:
      "1e. Netto-omzet uit geproduceerde goederen belast met 0% of niet bij u belast",
    descriptionLong:
      "1e. Netto-omzet uit geproduceerde goederen belast met 0% of niet bij u belast",
  },
  {
    code: "WOmzNopOll",
    descriptionShort:
      "2a. Netto-omzet uit leveringen geproduceerde goederen waarbij de omzetbelasting naar u  is verlegd",
    descriptionLong:
      "2a. Netto-omzet uit leveringen geproduceerde goederen waarbij de omzetbelasting naar u  is verlegd",
  },
  {
    code: "WOmzNopOln",
    descriptionShort:
      "3a. Netto-omzet uit leveringen geproduceerde goederen naar landen buiten EU (uitvoer)",
    descriptionLong:
      "3a. Netto-omzet uit leveringen geproduceerde goederen naar landen buiten EU (uitvoer)",
  },
  {
    code: "WOmzNopOli",
    descriptionShort:
      "3b. Netto-omzet uit leveringen geproduceerde goederen naar landen binnen EU",
    descriptionLong:
      "3b. Netto-omzet uit leveringen geproduceerde goederen naar landen binnen EU",
  },
  {
    code: "WOmzNopOla",
    descriptionShort:
      "3c. Netto-omzet uit leveringen geproduceerde goederen via installatie/afstandsverkopen binnen de EU",
    descriptionLong:
      "3c. Netto-omzet uit leveringen geproduceerde goederen via installatie/afstandsverkopen binnen de EU",
  },
  {
    code: "WOmzNopOlu",
    descriptionShort:
      "4a. Netto-omzet uit belaste leveringen van geproduceerde goederen uit landen buiten de EU",
    descriptionLong:
      "4a. Netto-omzet uit belaste leveringen van geproduceerde goederen uit landen buiten de EU",
  },
  {
    code: "WOmzNopOle",
    descriptionShort:
      "4b. Netto-omzet uit belaste leveringen van geproduceerde goederen uit landen binnen de EU",
    descriptionLong:
      "4b. Netto-omzet uit belaste leveringen van geproduceerde goederen uit landen binnen de EU",
  },
  {
    code: "WOmzNopNon",
    descriptionShort:
      "Netto-omzet uit leveringen geproduceerde goederen waarvan overboeking naar andere rubriek",
    descriptionLong:
      "Netto-omzet uit leveringen geproduceerde goederen waarvan overboeking naar andere rubriek opbrengsten uit de verkoop van goederen",
  },
  {
    code: "WOmzNopNod",
    descriptionShort:
      "Netto-omzet van onbelaste leveringen geproduceerde goederen",
    descriptionLong:
      "Netto-omzet van onbelaste leveringen geproduceerde goederen",
  },
  {
    code: "WOmzNoh",
    descriptionShort: "Netto-omzet uit verkoop van handelsgoederen",
    descriptionLong:
      "Netto-omzet uit verkoop van handelsgoederen opbrengsten uit de verkoop van goederen",
  },
  {
    code: "WOmzNohOlh",
    descriptionShort:
      "1a. Netto-omzet uit verkoop van handelsgoederen belast met hoog tarief",
    descriptionLong:
      "1a. Netto-omzet uit verkoop van handelsgoederen belast met hoog tarief",
  },
  {
    code: "WOmzNohOlv",
    descriptionShort:
      "1b. Netto-omzet uit verkoop van handelsgoederen belast met laag tarief",
    descriptionLong:
      "1b. Netto-omzet uit verkoop van handelsgoederen belast met laag tarief",
  },
  {
    code: "WOmzNohOlo",
    descriptionShort:
      "1c. Netto-omzet uit verkoop van handelsgoederen belast met overige tarieven, behalve 0%",
    descriptionLong:
      "1c. Netto-omzet uit verkoop van handelsgoederen belast met overige tarieven, behalve 0%",
  },
  {
    code: "WOmzNohMai",
    descriptionShort: "Margeinkopen",
    descriptionLong: "Margeinkopen ",
  },
  {
    code: "WOmzNohOmr",
    descriptionShort: "Margeverkopen",
    descriptionLong: "Margeverkopen ",
  },
  {
    code: "WOmzNohOpg",
    descriptionShort: "1d. Netto-omzet uit privégebruik handelsgoederen",
    descriptionLong: "1d. Netto-omzet uit privégebruik handelsgoederen",
  },
  {
    code: "WOmzNohOlg",
    descriptionShort:
      "1e. Netto-omzet uit leveringen handelsgoederen belast met 0% of niet bij u belast",
    descriptionLong:
      "1e. Netto-omzet uit leveringen handelsgoederen belast met 0% of niet bij u belast",
  },
  {
    code: "WOmzNohOll",
    descriptionShort:
      "2a. Netto-omzet uit leveringen handelsgoederen waarbij de omzetbelasting naar u is verlegd",
    descriptionLong:
      "2a. Netto-omzet uit leveringen handelsgoederen waarbij de omzetbelasting naar u is verlegd",
  },
  {
    code: "WOmzNohOln",
    descriptionShort:
      "3a. Netto-omzet uit leveringen handelsgoederen naar landen buiten de EU (uitvoer)",
    descriptionLong:
      "3a. Netto-omzet uit leveringen handelsgoederen naar landen buiten de EU (uitvoer)",
  },
  {
    code: "WOmzNohOli",
    descriptionShort:
      "3b. Netto-omzet uit leveringen handelsgoederen naar landen binnen de EU",
    descriptionLong:
      "3b. Netto-omzet uit leveringen handelsgoederen naar landen binnen de EU",
  },
  {
    code: "WOmzNohOla",
    descriptionShort:
      "3c. Netto-omzet uit leveringen handelsgoederen via installatie/afstandsverkopen binnen de EU",
    descriptionLong:
      "3c. Netto-omzet uit leveringen handelsgoederen via installatie/afstandsverkopen binnen de EU",
  },
  {
    code: "WOmzNohOlu",
    descriptionShort:
      "4a. Netto-omzet uit belaste leveringen van handelsgoederen uit landen buiten de EU",
    descriptionLong:
      "4a. Netto-omzet uit belaste leveringen van handelsgoederen uit landen buiten de EU",
  },
  {
    code: "WOmzNohOle",
    descriptionShort:
      "4b. Netto-omzet uit belaste leveringen van handelsgoederen uit landen binnen de EU",
    descriptionLong:
      "4b. Netto-omzet uit belaste leveringen van handelsgoederen uit landen binnen de EU",
  },
  {
    code: "WOmzNohNon",
    descriptionShort:
      "Netto-omzet uit leveringen handelsgoederen waarvan overboeking naar andere rubriek",
    descriptionLong:
      "Netto-omzet uit leveringen handelsgoederen waarvan overboeking naar andere rubriek ",
  },
  {
    code: "WOmzNohNod",
    descriptionShort: "Netto-omzet van onbelaste verkoop van handelsgoederen",
    descriptionLong: "Netto-omzet van onbelaste verkoop van handelsgoederen",
  },
  {
    code: "WOmzNod",
    descriptionShort: "Opbrengsten uit het verlenen van diensten",
    descriptionLong: "Opbrengsten uit het verlenen van diensten",
  },
  {
    code: "WOmzNodOdh",
    descriptionShort:
      "1a. Netto-omzet uit verleende diensten belast met hoog tarief",
    descriptionLong:
      "1a. Netto-omzet uit verleende diensten belast met hoog tarief",
  },
  {
    code: "WOmzNodOdl",
    descriptionShort:
      "1b. Netto-omzet uit verleende diensten belast met laag tarief",
    descriptionLong:
      "1b. Netto-omzet uit verleende diensten belast met laag tarief",
  },
  {
    code: "WOmzNodOdo",
    descriptionShort:
      "1c.  Netto-omzet uit verleende diensten belast met overige tarieven, behalve 0%",
    descriptionLong:
      "1c.  Netto-omzet uit verleende diensten belast met overige tarieven, behalve 0%",
  },
  {
    code: "WOmzNodOpd",
    descriptionShort: "1d  Netto-omzet uit privégebruik verleende diensten",
    descriptionLong: "1d  Netto-omzet uit privégebruik verleende diensten",
  },
  {
    code: "WOmzNodOdg",
    descriptionShort:
      "1e. Netto-omzet uit verleende diensten belast met 0% of niet bij u belast",
    descriptionLong:
      "1e. Netto-omzet uit verleende diensten belast met 0% of niet bij u belast",
  },
  {
    code: "WOmzNodOdv",
    descriptionShort:
      "2a. Netto-omzet uit verleende diensten waarbij de omzetbelasting naar u is verlegd",
    descriptionLong:
      "2a. Netto-omzet uit verleende diensten waarbij de omzetbelasting naar u is verlegd",
  },
  {
    code: "WOmzNodOdb",
    descriptionShort:
      "3a. Netto-omzet uit verleende diensten naar landen buiten de EU (uitvoer)",
    descriptionLong:
      "3a. Netto-omzet uit verleende diensten naar landen buiten de EU (uitvoer)",
  },
  {
    code: "WOmzNodOdi",
    descriptionShort:
      "3b. Netto-omzet uit verleende diensten naar landen binnen de EU",
    descriptionLong:
      "3b. Netto-omzet uit verleende diensten naar landen binnen de EU",
  },
  {
    code: "WOmzNodOda",
    descriptionShort:
      "3c. Netto-omzet uit verleende diensten via installatie/afstandsverkopen binnen de EU",
    descriptionLong:
      "3c. Netto-omzet uit verleende diensten via installatie/afstandsverkopen binnen de EU",
  },
  {
    code: "WOmzNodOdu",
    descriptionShort:
      "4a. Netto-omzet uit belaste leveringen van diensten uit landen buiten de EU",
    descriptionLong:
      "4a. Netto-omzet uit belaste leveringen van diensten uit landen buiten de EU",
  },
  {
    code: "WOmzNodOde",
    descriptionShort:
      "4b. Netto-omzet uit belaste leveringen van diensten uit landen binnen de EU",
    descriptionLong:
      "4b. Netto-omzet uit belaste leveringen van diensten uit landen binnen de EU",
  },
  {
    code: "WOmzNodNon",
    descriptionShort:
      "Netto-omzet uit verleende diensten waarvan overboeking naar andere rubriek",
    descriptionLong:
      "Netto-omzet uit verleende diensten waarvan overboeking naar andere rubriek opbrengsten uit het verlenen van diensten",
  },
  {
    code: "WOmzNodNod",
    descriptionShort: "Netto-omzet van onbelaste diensten",
    descriptionLong: "Netto-omzet van onbelaste diensten",
  },
  {
    code: "WOmzAol",
    descriptionShort: "Toegerekende opbrengsten",
    descriptionLong: "Toegerekende opbrengsten",
  },
  {
    code: "WOmzAolPom",
    descriptionShort: "Productieomzet",
    descriptionLong: "Productieomzet toegerekende opbrengsten",
  },
  {
    code: "WOmzAolVpa",
    descriptionShort: "Verpakkingsvergoeding",
    descriptionLong: "Verpakkingsvergoeding toegerekende opbrengsten",
  },
  {
    code: "WOmzAolGms",
    descriptionShort: "GMO subsidie",
    descriptionLong: "GMO subsidie toegerekende opbrengsten",
  },
  {
    code: "WOmzAolVee",
    descriptionShort: "Verkoop elektra",
    descriptionLong: "Verkoop elektra toegerekende opbrengsten",
  },
  {
    code: "WOmzAolVwd",
    descriptionShort: "Vergoeding werk aan derden",
    descriptionLong: "Vergoeding werk aan derden",
  },
  {
    code: "WOmzAolOno",
    descriptionShort: "Overige niet toegerekende opbrengsten",
    descriptionLong:
      "Overige niet toegerekende opbrengsten toegerekende opbrengsten",
  },
  {
    code: "WOmzAov",
    descriptionShort: "Agrarische bedrijfsopbrengsten veeteelt",
    descriptionLong: "Agrarische bedrijfsopbrengsten veeteelt",
  },
  {
    code: "WOmzAovVmu",
    descriptionShort: "Voorraadmutatie",
    descriptionLong: "Voorraadmutatie toegerekende opbrengsten",
  },
  {
    code: "WOmzAovOzv",
    descriptionShort: "Omzet vee",
    descriptionLong: "Omzet vee toegerekende opbrengsten",
  },
  {
    code: "WOmzAovOea",
    descriptionShort: "Omzet en Aanwas vee",
    descriptionLong: "Omzet en Aanwas vee toegerekende opbrengsten",
  },
  {
    code: "WOmzAovBts",
    descriptionShort: "Bedrijfstoeslag",
    descriptionLong: "Bedrijfstoeslag toegerekende opbrengsten",
  },
  {
    code: "WOmzAovMel",
    descriptionShort: "Melkgeld",
    descriptionLong: "Melkgeld",
  },
  {
    code: "WOmzAovEie",
    descriptionShort: "Eiergeld",
    descriptionLong: "Eiergeld",
  },
  {
    code: "WOmzNoo",
    descriptionShort: "Overige netto-omzet",
    descriptionLong: "Overige netto-omzet",
  },
  {
    code: "WOmzNooNdl",
    descriptionShort: "Overige netto-omzet waarvan licenties",
    descriptionLong:
      "Overige netto-omzet waarvan licenties overige netto-omzet",
  },
  {
    code: "WOmzNooNdy",
    descriptionShort: "Opbrengsten uit royalty's",
    descriptionLong: "Opbrengsten uit royalty's overige netto-omzet",
  },
  {
    code: "WOmzNooNdd",
    descriptionShort: "Opbrengsten uit dividenden",
    descriptionLong: "Opbrengsten uit dividenden overige netto-omzet",
  },
  {
    code: "WOmzNooOur",
    descriptionShort: "Opbrengsten uit rente",
    descriptionLong: "Opbrengsten uit rente overige netto-omzet",
  },
  {
    code: "WOmzNooOnw",
    descriptionShort: "Overige netto-omzet waarvan niet elders genoemd",
    descriptionLong:
      "Overige netto-omzet waarvan niet elders genoemd overige netto-omzet",
  },
  {
    code: "WOmzNooNdo",
    descriptionShort: "Opbrengsten uit overige bronnen",
    descriptionLong: "Opbrengsten uit overige bronnen overige netto-omzet",
  },
  {
    code: "WOmzNooNon",
    descriptionShort:
      "Overige netto-omzet waarvan overboeking naar andere rubriek",
    descriptionLong:
      "Overige netto-omzet waarvan overboeking naar andere rubriek overige netto-omzet",
  },
  {
    code: "WOmzNooNtf",
    descriptionShort: "Omzet (nog te factureren)",
    descriptionLong: "Omzet (nog te factureren)",
  },
  {
    code: "WOmzOit",
    descriptionShort: "Netto-omzet intercompany transacties",
    descriptionLong: "Netto-omzet intercompany transacties",
  },
  {
    code: "WOmzOitOit",
    descriptionShort: "Omzet intercompany transacties",
    descriptionLong: "Omzet intercompany transacties",
  },
  {
    code: "WOmzOitOvg",
    descriptionShort: "Omzet verkopen aan groepsmaatschappijen",
    descriptionLong:
      "Omzet verkopen aan groepsmaatschappijen netto-omzet intercompany transacties",
  },
  {
    code: "WOmzOitOvm",
    descriptionShort: "Omzet verkopen aan overige verbonden maatschappijen",
    descriptionLong:
      "Omzet verkopen aan overige verbonden maatschappijen netto-omzet intercompany transacties",
  },
  {
    code: "WOmzOitOvd",
    descriptionShort: "Omzet verkopen aan andere deelnemingen",
    descriptionLong:
      "Omzet verkopen aan andere deelnemingen netto-omzet intercompany transacties",
  },
  {
    code: "WOmzKeb",
    descriptionShort: "Kortingen, bonussen en provisies",
    descriptionLong: "Kortingen en bonussen en provisies",
  },
  {
    code: "WOmzKebVek",
    descriptionShort: "Verleende kortingen",
    descriptionLong: "Verleende kortingen",
  },
  {
    code: "WOmzKebVekVkp",
    descriptionShort: "Verleende kortingen op geproduceerde goederen",
    descriptionLong:
      "Verleende kortingen op geproduceerde goederen verleende kortingen",
  },
  {
    code: "WOmzKebVekVkh",
    descriptionShort: "Verleende kortingen op handelsgoederen",
    descriptionLong:
      "Verleende kortingen op handelsgoederen verleende kortingen",
  },
  {
    code: "WOmzKebVekVkd",
    descriptionShort: "Verleende kortingen op diensten",
    descriptionLong: "Verleende kortingen op diensten verleende kortingen",
  },
  {
    code: "WOmzKebVekOkr",
    descriptionShort: "Overige verleende kortingen",
    descriptionLong: "Overige verleende kortingen verleende kortingen",
  },
  {
    code: "WOmzKebOmz",
    descriptionShort: "Omzetbonificaties",
    descriptionLong: "Omzetbonificaties",
  },
  {
    code: "WOmzKebOmzOog",
    descriptionShort: "Omzetbonificaties op geproduceerde goederen",
    descriptionLong:
      "Omzetbonificaties op geproduceerde goederen omzetbonificaties",
  },
  {
    code: "WOmzKebOmzOoh",
    descriptionShort: "Omzetbonificaties op handelsgoederen",
    descriptionLong: "Omzetbonificaties op handelsgoederen omzetbonificaties",
  },
  {
    code: "WOmzKebOmzOod",
    descriptionShort: "Omzetbonificaties op diensten",
    descriptionLong: "Omzetbonificaties op diensten omzetbonificaties",
  },
  {
    code: "WOmzKebOmzOov",
    descriptionShort: "Omzetbonificaties overige",
    descriptionLong: "Omzetbonificaties overige omzetbonificaties",
  },
  {
    code: "WOmzKebPrv",
    descriptionShort: "Provisies",
    descriptionLong: "Provisies",
  },
  {
    code: "WOmzKebPrvPvh",
    descriptionShort: "Provisies op verkopen handel",
    descriptionLong: "Provisies op verkopen handel provisies",
  },
  {
    code: "WOmzKebPrvPvp",
    descriptionShort: "Provisies op verkopen productie",
    descriptionLong: "Provisies op verkopen productie provisies",
  },
  {
    code: "WOmzKebPrvPvd",
    descriptionShort: "Provisies op verkopen dienstverlening",
    descriptionLong: "Provisies op verkopen dienstverlening provisies",
  },
  {
    code: "WOmzKebPrvOvp",
    descriptionShort: "Overige provisies",
    descriptionLong: "Overige provisies provisies",
  },
  {
    code: "WOmzGrp",
    descriptionShort: "Netto-omzet groepen",
    descriptionLong: "Netto-omzet groepen",
  },
  {
    code: "WOmzGrpGr1",
    descriptionShort: "Netto-omzet groep 1",
    descriptionLong: "Netto-omzet groep 1",
  },
  {
    code: "WOmzGrpGr1Pra",
    descriptionShort: "Netto-omzet groep 1 product A",
    descriptionLong: "Netto-omzet groep 1 product A",
  },
  {
    code: "WOmzGrpGr1Prb",
    descriptionShort: "Netto-omzet groep 1 product B",
    descriptionLong: "Netto-omzet groep 1 product B",
  },
  {
    code: "WOmzGrpGr1Prc",
    descriptionShort: "Netto-omzet groep 1 product C",
    descriptionLong: "Netto-omzet groep 1 product C",
  },
  {
    code: "WOmzGrpGr1Prd",
    descriptionShort: "Netto-omzet groep 1 product D",
    descriptionLong: "Netto-omzet groep 1 product D",
  },
  {
    code: "WOmzGrpGr1Pre",
    descriptionShort: "Netto-omzet groep 1 product E",
    descriptionLong: "Netto-omzet groep 1 product E",
  },
  {
    code: "WOmzGrpGr2",
    descriptionShort: "Netto-omzet groep 2",
    descriptionLong: "Netto-omzet groep 2",
  },
  {
    code: "WOmzGrpGr2Pra",
    descriptionShort: "Netto-omzet groep 2 product A",
    descriptionLong: "Netto-omzet groep 2 product A",
  },
  {
    code: "WOmzGrpGr2Prb",
    descriptionShort: "Netto-omzet groep 2 product B",
    descriptionLong: "Netto-omzet groep 2 product B",
  },
  {
    code: "WOmzGrpGr2Prc",
    descriptionShort: "Netto-omzet groep 2 product C",
    descriptionLong: "Netto-omzet groep 2 product C",
  },
  {
    code: "WOmzGrpGr2Prd",
    descriptionShort: "Netto-omzet groep 2 product D",
    descriptionLong: "Netto-omzet groep 2 product D",
  },
  {
    code: "WOmzGrpGr2Pre",
    descriptionShort: "Netto-omzet groep 2 product E",
    descriptionLong: "Netto-omzet groep 2 product E",
  },
  {
    code: "WOmzGrpGr3",
    descriptionShort: "Netto-omzet groep 3",
    descriptionLong: "Netto-omzet groep 3",
  },
  {
    code: "WOmzGrpGr3Pra",
    descriptionShort: "Netto-omzet groep 3 product A",
    descriptionLong: "Netto-omzet groep 3 product A",
  },
  {
    code: "WOmzGrpGr3Prb",
    descriptionShort: "Netto-omzet groep 3 product B",
    descriptionLong: "Netto-omzet groep 3 product B",
  },
  {
    code: "WOmzGrpGr3Prc",
    descriptionShort: "Netto-omzet groep 3 product C",
    descriptionLong: "Netto-omzet groep 3 product C",
  },
  {
    code: "WOmzGrpGr3Prd",
    descriptionShort: "Netto-omzet groep 3 product D",
    descriptionLong: "Netto-omzet groep 3 product D",
  },
  {
    code: "WOmzGrpGr3Pre",
    descriptionShort: "Netto-omzet groep 3 product E",
    descriptionLong: "Netto-omzet groep 3 product E",
  },
  {
    code: "WOmzGrpGr4",
    descriptionShort: "Netto-omzet groep 4",
    descriptionLong: "Netto-omzet groep 4",
  },
  {
    code: "WOmzGrpGr4Pra",
    descriptionShort: "Netto-omzet groep 4 product A",
    descriptionLong: "Netto-omzet groep 4 product A",
  },
  {
    code: "WOmzGrpGr4Prb",
    descriptionShort: "Netto-omzet groep 4 product B",
    descriptionLong: "Netto-omzet groep 4 product B",
  },
  {
    code: "WOmzGrpGr4Prc",
    descriptionShort: "Netto-omzet groep 4 product C",
    descriptionLong: "Netto-omzet groep 4 product C",
  },
  {
    code: "WOmzGrpGr4Prd",
    descriptionShort: "Netto-omzet groep 4 product D",
    descriptionLong: "Netto-omzet groep 4 product D",
  },
  {
    code: "WOmzGrpGr4Pre",
    descriptionShort: "Netto-omzet groep 4 product E",
    descriptionLong: "Netto-omzet groep 4 product E",
  },
  {
    code: "WOmzGrpGr5",
    descriptionShort: "Netto-omzet groep 5",
    descriptionLong: "Netto-omzet groep 5",
  },
  {
    code: "WOmzGrpGr5Pra",
    descriptionShort: "Netto-omzet groep 5 product A",
    descriptionLong: "Netto-omzet groep 5 product A",
  },
  {
    code: "WOmzGrpGr5Prb",
    descriptionShort: "Netto-omzet groep 5 product B",
    descriptionLong: "Netto-omzet groep 5 product B",
  },
  {
    code: "WOmzGrpGr5Prc",
    descriptionShort: "Netto-omzet groep 5 product C",
    descriptionLong: "Netto-omzet groep 5 product C",
  },
  {
    code: "WOmzGrpGr5Prd",
    descriptionShort: "Netto-omzet groep 5 product D",
    descriptionLong: "Netto-omzet groep 5 product D",
  },
  {
    code: "WOmzGrpGr5Pre",
    descriptionShort: "Netto-omzet groep 5 product E",
    descriptionLong: "Netto-omzet groep 5 product E",
  },
  {
    code: "WRev",
    descriptionShort: "Netto resultaat exploitatie van vastgoedportefeuille",
    descriptionLong: "Netto resultaat exploitatie van vastgoedportefeuille",
  },
  {
    code: "WRevHuo",
    descriptionShort: "Huuropbrengsten",
    descriptionLong: "Huuropbrengsten",
  },
  {
    code: "WRevHuoHuo",
    descriptionShort: "Huuropbrengsten",
    descriptionLong: "Huuropbrengsten",
  },
  {
    code: "WRevHuoHuoHur",
    descriptionShort: "Huren",
    descriptionLong: "Huren",
  },
  {
    code: "WRevHuoHuoLee",
    descriptionShort: "Frictieleegstand",
    descriptionLong: "Frictieleegstand",
  },
  {
    code: "WRevHuoHuoAfb",
    descriptionShort: "Afboekingen",
    descriptionLong: "Afboekingen",
  },
  {
    code: "WRevHuoHuoMvh",
    descriptionShort: "Mutatie voorziening huurdebiteuren",
    descriptionLong: "Mutatie voorziening huurdebiteuren",
  },
  {
    code: "WRevHuoHuoLpr",
    descriptionShort: "Leegstand projecten",
    descriptionLong: "Leegstand projecten",
  },
  {
    code: "WRevHuoHuoLvk",
    descriptionShort: "Leegstand verkoop",
    descriptionLong: "Leegstand verkoop",
  },
  {
    code: "WRevHuoHuoHko",
    descriptionShort: "Huurkortingen",
    descriptionLong: "Huurkortingen",
  },
  {
    code: "WRevOsc",
    descriptionShort: "Opbrengsten servicecontracten",
    descriptionLong: "Opbrengsten servicecontracten",
  },
  {
    code: "WRevOscOsc",
    descriptionShort: "Opbrengsten servicecontracten",
    descriptionLong: "Opbrengsten servicecontracten",
  },
  {
    code: "WRevOscOscOzd",
    descriptionShort: "Overige zaken, leveringen en diensten",
    descriptionLong: "Overige zaken, leveringen en diensten",
  },
  {
    code: "WRevOscOscVgd",
    descriptionShort: "Vergoedingsderving (verrekenbaar)",
    descriptionLong: "Vergoedingsderving (verrekenbaar)",
  },
  {
    code: "WRevOscOscTvh",
    descriptionShort: "Te verrekenen met huurders",
    descriptionLong: "Te verrekenen met huurders",
  },
  {
    code: "WRevOscOscZsv",
    descriptionShort: "Overige zaken, service en verbruik  (niet verrekenbaar)",
    descriptionLong: "Overige zaken, service en verbruik  (niet verrekenbaar)",
  },
  {
    code: "WRevOscOscZnd",
    descriptionShort: "Vergoedingsderving (niet verrekenbaar)",
    descriptionLong: "Vergoedingsderving (niet verrekenbaar)",
  },
  {
    code: "WRevLsc",
    descriptionShort: "Lasten servicecontracten",
    descriptionLong: "Lasten servicecontracten",
  },
  {
    code: "WRevLscLsc",
    descriptionShort: "Lasten servicecontracten",
    descriptionLong: "Lasten servicecontracten",
  },
  {
    code: "WRevLscLscSal",
    descriptionShort: "Toegerekende kosten salarissen",
    descriptionLong: "Toegerekende kosten salarissen",
  },
  {
    code: "WRevLscLscSoc",
    descriptionShort: "Toegerekende kosten sociale lasten",
    descriptionLong: "Toegerekende kosten sociale lasten",
  },
  {
    code: "WRevLscLscPen",
    descriptionShort: "Toegerekende kosten pensioenlasten",
    descriptionLong: "Toegerekende kosten pensioenlasten",
  },
  {
    code: "WRevLscLscAfs",
    descriptionShort: "Toegerekende kosten afschrijvingen",
    descriptionLong: "Toegerekende kosten afschrijvingen",
  },
  {
    code: "WRevLscLscObl",
    descriptionShort: "Toegerekende kosten overige bedrijfslasten",
    descriptionLong: "Toegerekende kosten overige bedrijfslasten",
  },
  {
    code: "WRevLscLscOpl",
    descriptionShort: "Toegerekende kosten overige personeelslasten",
    descriptionLong: "Toegerekende kosten overige personeelslasten",
  },
  {
    code: "WRevLscLscLld",
    descriptionShort: "Lasten leveringen en diensten",
    descriptionLong: "Lasten leveringen en diensten",
  },
  {
    code: "WRevLscLscLwl",
    descriptionShort: "Lasten warmtelevering",
    descriptionLong: "Lasten warmtelevering",
  },
  {
    code: "WRevLscLscLoz",
    descriptionShort: "Lasten overige zaken (niet verrekenbaar)",
    descriptionLong: "Lasten overige zaken (niet verrekenbaar)",
  },
  {
    code: "WRevLscLscAsc",
    descriptionShort: "Afgerekende service en stookkosten",
    descriptionLong: "Afgerekende service en stookkosten",
  },
  {
    code: "WRevLscLscLos",
    descriptionShort: "Lasten overige servicekosten",
    descriptionLong: "Lasten overige servicekosten",
  },
  {
    code: "WRevOhb",
    descriptionShort: "Overheidsbijdragen",
    descriptionLong: "Overheidsbijdragen",
  },
  {
    code: "WRevOhbOhb",
    descriptionShort: "Overheidsbijdragen",
    descriptionLong: "Overheidsbijdragen",
  },
  {
    code: "WRevLvb",
    descriptionShort: "Lasten verhuur en beheeractiviteiten",
    descriptionLong: "Lasten verhuur en beheeractiviteiten",
  },
  {
    code: "WRevLvbLvb",
    descriptionShort: "Lasten verhuur en beheeractiviteiten",
    descriptionLong: "Lasten verhuur en beheeractiviteiten",
  },
  {
    code: "WRevLvbLvbSal",
    descriptionShort: "Toegerekende kosten salarissen",
    descriptionLong:
      "Toegerekende kosten salarissen lasten lasten verhuur en beheeractiviteiten",
  },
  {
    code: "WRevLvbLvbSoc",
    descriptionShort: "Toegerekende kosten sociale lasten",
    descriptionLong:
      "Toegerekende kosten sociale lasten lasten verhuur en beheeractiviteiten",
  },
  {
    code: "WRevLvbLvbPen",
    descriptionShort: "Toegerekende kosten pensioenlasten",
    descriptionLong:
      "Toegerekende kosten pensioenlasten lasten verhuur en beheeractiviteiten",
  },
  {
    code: "WRevLvbLvbAfs",
    descriptionShort: "Toegerekende kosten afschrijvingen",
    descriptionLong:
      "Toegerekende kosten afschrijvingen lasten verhuur en beheeractiviteiten",
  },
  {
    code: "WRevLvbLvbObl",
    descriptionShort: "Toegerekende kosten overige bedrijfslasten",
    descriptionLong:
      "Toegerekende kosten overige bedrijfslasten lasten verhuur en beheeractiviteiten",
  },
  {
    code: "WRevLvbLvbOpl",
    descriptionShort: "Toegerekende kosten overige personeelslasten",
    descriptionLong: "Toegerekende kosten overige personeelslasten",
  },
  {
    code: "WRevLvbLvbAhc",
    descriptionShort: "Administratiekosten huurcontract",
    descriptionLong: "Administratiekosten huurcontract",
  },
  {
    code: "WRevLvbLvbAsk",
    descriptionShort: "Administratiekosten servicekosten",
    descriptionLong: "Administratiekosten servicekosten",
  },
  {
    code: "WRevLvbLvbOve",
    descriptionShort: "Lasten Verhuur en Beheeractiviteiten overig",
    descriptionLong: "Lasten Verhuur en Beheeractiviteiten overig",
  },
  {
    code: "WRevLoa",
    descriptionShort: "Lasten onderhoudsactiviteiten",
    descriptionLong: "Lasten onderhoudsactiviteiten",
  },
  {
    code: "WRevLoaLoa",
    descriptionShort: "Lasten onderhoudsactiviteiten",
    descriptionLong: "Lasten onderhoudsactiviteiten",
  },
  {
    code: "WRevLoaLoaCal",
    descriptionShort: "Calamiteiten",
    descriptionLong: "Calamiteiten",
  },
  {
    code: "WRevLoaLoaPmo",
    descriptionShort: "Planmatig onderhoud",
    descriptionLong: "Planmatig onderhoud",
  },
  {
    code: "WRevLoaLoaOvu",
    descriptionShort: "Mutatieonderhoud verhuur (technisch noodzakelijk)",
    descriptionLong: "Mutatieonderhoud verhuur (technisch noodzakelijk)",
  },
  {
    code: "WRevLoaLoaOve",
    descriptionShort: "Mutatieonderhoud verhuur (extra)",
    descriptionLong: "Mutatieonderhoud verhuur (extra)",
  },
  {
    code: "WRevLoaLoaOvt",
    descriptionShort: "Mutatieonderhoud verkoop (technisch noodzakelijk)",
    descriptionLong: "Mutatieonderhoud verkoop (technisch noodzakelijk)",
  },
  {
    code: "WRevLoaLoaRpo",
    descriptionShort: "Reparatieonderhoud",
    descriptionLong: "Reparatieonderhoud",
  },
  {
    code: "WRevLoaLoaAro",
    descriptionShort: "Afkoop reparatieonderhoud",
    descriptionLong: "Afkoop reparatieonderhoud",
  },
  {
    code: "WRevLoaLoaCto",
    descriptionShort: "Contractonderhoud",
    descriptionLong: "Contractonderhoud",
  },
  {
    code: "WRevLoaLoaObv",
    descriptionShort: "Onderhoudsbijdrage VVE's",
    descriptionLong: "Onderhoudsbijdrage VVE's",
  },
  {
    code: "WRevLoaLoaVio",
    descriptionShort: "Vandalisme/inbraak onderhoud",
    descriptionLong: "Vandalisme/inbraak onderhoud",
  },
  {
    code: "WRevLoaLoaOvr",
    descriptionShort: "Opstalverzekering eigen risico",
    descriptionLong: "Opstalverzekering eigen risico",
  },
  {
    code: "WRevLoaLoaRen",
    descriptionShort: "Renovatie",
    descriptionLong: "Renovatie",
  },
  {
    code: "WRevLoaLoaOoh",
    descriptionShort: "Overig onderhoud",
    descriptionLong: "Overig onderhoud",
  },
  {
    code: "WRevLoaLoaSal",
    descriptionShort: "Toegerekende kosten salarissen",
    descriptionLong: "Toegerekende kosten salarissen lasten lasten onderhoud",
  },
  {
    code: "WRevLoaLoaSoc",
    descriptionShort: "Toegerekende kosten sociale lasten",
    descriptionLong: "Toegerekende kosten sociale lasten lasten onderhoud",
  },
  {
    code: "WRevLoaLoaPen",
    descriptionShort: "Toegerekende kosten pensioenlasten",
    descriptionLong: "Toegerekende kosten pensioenlasten lasten onderhoud",
  },
  {
    code: "WRevLoaLoaOpk",
    descriptionShort: "Toerekening organisatiekosten overige personeelslasten",
    descriptionLong: "Toerekening organisatiekosten overige personeelslasten",
  },
  {
    code: "WRevLoaLoaAfs",
    descriptionShort: "Toegerekende kosten afschrijvingen",
    descriptionLong: "Toegerekende kosten afschrijvingen lasten onderhoud",
  },
  {
    code: "WRevLoaLoaObk",
    descriptionShort: "Toegerekende kosten overige bedrijfslasten",
    descriptionLong:
      "Toegerekende kosten overige bedrijfslasten lasten onderhoud",
  },
  {
    code: "WRevLoaLoaPop",
    descriptionShort: "Planmatig onderhoud overboeking naar projecten",
    descriptionLong: "Planmatig onderhoud overboeking naar projecten",
  },
  {
    code: "WRevLoaLoaDui",
    descriptionShort: "Dekking uren indirect",
    descriptionLong: "Dekking uren indirect",
  },
  {
    code: "WRevLoaLoaDue",
    descriptionShort: "Dekking uren eigen dienst",
    descriptionLong: "Dekking uren eigen dienst",
  },
  {
    code: "WRevLoaLoaDup",
    descriptionShort: "Dekking uren planmatig en contractonderhoud",
    descriptionLong: "Dekking uren planmatig en contractonderhoud",
  },
  {
    code: "WRevLoaLoaDum",
    descriptionShort: "Dekking magazijnkosten",
    descriptionLong: "Dekking magazijnkosten",
  },
  {
    code: "WRevLoaLoaDua",
    descriptionShort: "Dekking afval",
    descriptionLong: "Dekking afval",
  },
  {
    code: "WRevLoaLoaDuk",
    descriptionShort: "Dekking klein materiaal",
    descriptionLong: "Dekking klein materiaal",
  },
  {
    code: "WRevLoaLoaPvm",
    descriptionShort: "Voorraadprijsverschillen materiaal",
    descriptionLong: "Voorraadprijsverschillen materiaal",
  },
  {
    code: "WRevLoaLoaKso",
    descriptionShort: "Kosten serviceabonnement onderhoud",
    descriptionLong: "Kosten serviceabonnement onderhoud",
  },
  {
    code: "WRevLoaLoaOso",
    descriptionShort: "Opbrengsten serviceabonnement onderhoud",
    descriptionLong: "Opbrengsten serviceabonnement onderhoud",
  },
  {
    code: "WRevOol",
    descriptionShort: "Overige directe operationele lasten explotatie bezit",
    descriptionLong: "Overige directe operationele lasten explotatie bezit",
  },
  {
    code: "WRevOolOol",
    descriptionShort: "Overige directe operationele lasten explotatie bezit",
    descriptionLong: "Overige directe operationele lasten explotatie bezit",
  },
  {
    code: "WRevOolOolBel",
    descriptionShort: "Belastingen",
    descriptionLong: "Belastingen",
  },
  {
    code: "WRevOolOolVez",
    descriptionShort: "Verzekeringen",
    descriptionLong: "Verzekeringen",
  },
  {
    code: "WRevOolOolVhf",
    descriptionShort: "Verhuurdersheffing",
    descriptionLong: "Verhuurdersheffing",
  },
  {
    code: "WRevOolOolShf",
    descriptionShort: "Saneringsheffing",
    descriptionLong: "Saneringsheffing",
  },
  {
    code: "WRevOolOolBhf",
    descriptionShort: "Bijdrageheffing Autoriteit woningcorporaties",
    descriptionLong: "Bijdrageheffing Autoriteit woningcorporaties",
  },
  {
    code: "WRevOolOolCon",
    descriptionShort: "Contributies",
    descriptionLong: "Contributies",
  },
  {
    code: "WRevOolOolBiv",
    descriptionShort: "Aandeel in vereniging van eigenaren",
    descriptionLong: "Aandeel in vereniging van eigenaren",
  },
  {
    code: "WRevOolOolErp",
    descriptionShort: "Erfpacht",
    descriptionLong: "Erfpacht",
  },
  {
    code: "WRevOolOolOdl",
    descriptionShort: "Diverse directe exploitatielasten",
    descriptionLong: "Diverse directe exploitatielasten",
  },
  {
    code: "WRvi",
    descriptionShort: "Netto resultaat verkocht vastgoed in ontwikkeling",
    descriptionLong: "Netto resultaat verkocht vastgoed in ontwikkeling",
  },
  {
    code: "WRviOvo",
    descriptionShort: "Omzet verkocht vastgoed in ontwikkeling",
    descriptionLong: "Omzet verkocht vastgoed in ontwikkeling",
  },
  {
    code: "WRviOvoOvo",
    descriptionShort: "Omzet verkocht vastgoed in ontwikkeling",
    descriptionLong: "Omzet verkocht vastgoed in ontwikkeling",
  },
  {
    code: "WRviUvv",
    descriptionShort: "Uitgaven verkocht vastgoed in ontwikkeling",
    descriptionLong: "Uitgaven verkocht vastgoed in ontwikkeling",
  },
  {
    code: "WRviUvvUvv",
    descriptionShort: "Uitgaven verkocht vastgoed in ontwikkeling",
    descriptionLong: "Uitgaven verkocht vastgoed in ontwikkeling",
  },
  {
    code: "WRviUvvUvvKuw",
    descriptionShort:
      "Kosten uitbesteed werk verkocht vastgoed in ontwikkeling",
    descriptionLong: "Kosten uitbesteed werk verkocht vastgoed in ontwikkeling",
  },
  {
    code: "WRviTok",
    descriptionShort:
      "Toegerekende organisatiekosten verkocht vastgoed in ontwikkeling",
    descriptionLong:
      "Toegerekende organisatiekosten verkocht vastgoed in ontwikkeling",
  },
  {
    code: "WRviTokTok",
    descriptionShort:
      "Toegerekende organisatiekosten verkocht vastgoed in ontwikkeling",
    descriptionLong:
      "Toegerekende organisatiekosten verkocht vastgoed in ontwikkeling",
  },
  {
    code: "WRviTokTokSal",
    descriptionShort: "Toegerekende organisatiekosten salarissen",
    descriptionLong:
      "Toegerekende organisatiekosten salarissen verkocht vastgoed in ontwikkeling",
  },
  {
    code: "WRviTokTokSoc",
    descriptionShort: "Toegerekende organisatiekosten sociale lasten",
    descriptionLong:
      "Toegerekende organisatiekosten sociale lasten verkocht vastgoed in ontwikkeling",
  },
  {
    code: "WRviTokTokPen",
    descriptionShort: "Toegerekende organisatiekosten pensioenlasten",
    descriptionLong:
      "Toegerekende organisatiekosten pensioenlasten verkocht vastgoed in ontwikkeling",
  },
  {
    code: "WRviTokTokAfs",
    descriptionShort: "Toegerekende organisatiekosten afschrijvingen",
    descriptionLong:
      "Toegerekende organisatiekosten afschrijvingen verkocht vastgoed in ontwikkeling",
  },
  {
    code: "WRviTokTokObl",
    descriptionShort: "Toegerekende organisatiekosten overige bedrijfslasten",
    descriptionLong:
      "Toegerekende organisatiekosten overige bedrijfslasten verkocht vastgoed in ontwikkeling",
  },
  {
    code: "WRviTokTokGpr",
    descriptionShort: "Geactiveerde productie Vastgoed in ontwikkeling",
    descriptionLong:
      "          Geactiveerde productie Vastgoed in ontwikkeling",
  },
  {
    code: "WRviTokTokOpl",
    descriptionShort: "Toegerekende organisatiekosten overige personeelslasten",
    descriptionLong: "Toegerekende organisatiekosten overige personeelslasten",
  },
  {
    code: "WRviTfk",
    descriptionShort: "Toegerekende financieringskosten",
    descriptionLong: "Toegerekende financieringskosten",
  },
  {
    code: "WRviTfkTfk",
    descriptionShort: "Toegerekende financieringskosten",
    descriptionLong: "Toegerekende financieringskosten",
  },
  {
    code: "WRgr",
    descriptionShort:
      "Netto gerealiseerd resultaat verkoop vastgoedportefeuille",
    descriptionLong:
      "Netto gerealiseerd resultaat verkoop vastgoedportefeuille",
  },
  {
    code: "WRgrOvp",
    descriptionShort: "Verkoopopbrengst vastgoedportefeuille",
    descriptionLong: "Verkoopopbrengst vastgoedportefeuille",
  },
  {
    code: "WRgrOvpOvp",
    descriptionShort: "Verkoopopbrengst vastgoedportefeuille",
    descriptionLong: "Verkoopopbrengst vastgoedportefeuille",
  },
  {
    code: "WRgrTok",
    descriptionShort:
      "Toegerekende organisatiekosten verkoop vastgoedportefeuille",
    descriptionLong:
      "Toegerekende organisatiekosten verkoop vastgoedportefeuille",
  },
  {
    code: "WRgrTokTok",
    descriptionShort:
      "Toegerekende organisatiekosten verkoop vastgoedportefeuille",
    descriptionLong:
      "Toegerekende organisatiekosten verkoop vastgoedportefeuille",
  },
  {
    code: "WRgrTokTokSal",
    descriptionShort: "Toegerekende organisatiekosten salarissen",
    descriptionLong:
      "Toegerekende organisatiekosten salarissen resultaat verkoop vastgoedportefeuille",
  },
  {
    code: "WRgrTokTokSoc",
    descriptionShort: "Toegerekende organisatiekosten sociale lasten",
    descriptionLong:
      "Toegerekende organisatiekosten sociale lasten resultaat verkoop vastgoedportefeuille",
  },
  {
    code: "WRgrTokTokPen",
    descriptionShort: "Toegerekende organisatiekosten pensioenlasten",
    descriptionLong:
      "Toegerekende organisatiekosten pensioenlasten resultaat verkoop vastgoedportefeuille",
  },
  {
    code: "WRgrTokTokAfs",
    descriptionShort: "Toegerekende organisatiekosten afschrijvingen",
    descriptionLong:
      "Toegerekende organisatiekosten afschrijvingen resultaat verkoop vastgoedportefeuille",
  },
  {
    code: "WRgrTokTokObl",
    descriptionShort: "Toegerekende organisatiekosten overige bedrijfslasten",
    descriptionLong:
      "Toegerekende organisatiekosten overige bedrijfslasten resultaat verkoop vastgoedportefeuille",
  },
  {
    code: "WRgrTokTokOpl",
    descriptionShort: "Toegerekende organisatiekosten overige personeelslasten",
    descriptionLong: "Toegerekende organisatiekosten overige personeelslasten",
  },
  {
    code: "WRgrRvb",
    descriptionShort: "Boekwaarde verkochte vastgoedportefeuille",
    descriptionLong: "Boekwaarde verkochte vastgoedportefeuille",
  },
  {
    code: "WRgrRvbLsc",
    descriptionShort: "Boekwaarde verkochte vastgoedportefeuille",
    descriptionLong: "Boekwaarde verkochte vastgoedportefeuille",
  },
  {
    code: "WRgrDkv",
    descriptionShort: "Directe kosten inzake verkoop vastgoedportefeuille",
    descriptionLong: "Directe kosten inzake verkoop vastgoedportefeuille",
  },
  {
    code: "WRgrDkvVkk",
    descriptionShort: "Verkoopkosten",
    descriptionLong: "Verkoopkosten",
  },
  {
    code: "WRgrDkvVbm",
    descriptionShort: "Verkoopbevorderende maatregelen",
    descriptionLong: "Verkoopbevorderende maatregelen",
  },
  {
    code: "WRgrDkvMaf",
    descriptionShort: "Meerwaarde afdracht",
    descriptionLong: "Meerwaarde afdracht",
  },
  {
    code: "WWvv",
    descriptionShort: "Waardeveranderingen vastgoedportefeuille",
    descriptionLong: "Waardeveranderingen vastgoedportefeuille",
  },
  {
    code: "WWvvOwv",
    descriptionShort: "Overige waardeveranderingen van vastgoedportefeuille",
    descriptionLong: "Overige waardeveranderingen van vastgoedportefeuille",
  },
  {
    code: "WWvvOwvOwv",
    descriptionShort: "Overige waardeveranderingen van vastgoedportefeuille",
    descriptionLong: "Overige waardeveranderingen van vastgoedportefeuille",
  },
  {
    code: "WWvvOwvOwvKgp",
    descriptionShort: "Kosten afboeking gestaakte projecten",
    descriptionLong: "Kosten afboeking gestaakte projecten",
  },
  {
    code: "WWvvOwvOwvOvp",
    descriptionShort: "Overige projectkosten",
    descriptionLong: "Overige projectkosten",
  },
  {
    code: "WWvvOwvOwvDvn",
    descriptionShort: "Dotatie voorziening nieuwbouw",
    descriptionLong: "Dotatie voorziening nieuwbouw",
  },
  {
    code: "WWvvOwvOwvVvn",
    descriptionShort: "Vrijval voorziening nieuwbouw",
    descriptionLong: "Vrijval voorziening nieuwbouw",
  },
  {
    code: "WWvvOwvOwvDvr",
    descriptionShort: "Dotatie voorziening renovatie",
    descriptionLong: "Dotatie voorziening renovatie",
  },
  {
    code: "WWvvOwvOwvVvr",
    descriptionShort: "Vrijval voorziening renovatie",
    descriptionLong: "Vrijval voorziening renovatie",
  },
  {
    code: "WWvvOwvOwvDvg",
    descriptionShort: "Dotatie voorziening grondposities",
    descriptionLong: "Dotatie voorziening grondposities",
  },
  {
    code: "WWvvOwvOwvVvg",
    descriptionShort: "Vrijval voorziening grondposities",
    descriptionLong: "Vrijval voorziening grondposities",
  },
  {
    code: "WWvvOwvTok",
    descriptionShort:
      "Toegerekende organisatiekosten vastgoed in ontwikkeling t.b.v. verhuur",
    descriptionLong:
      "Toegerekende organisatiekosten vastgoed in ontwikkeling t.b.v. verhuur",
  },
  {
    code: "WWvvOwvTokSal",
    descriptionShort: "Toegerekende organisatiekosten salarissen",
    descriptionLong:
      "Toegerekende organisatiekosten salarissen vastgoed in ontwikkeling tbv verhuur",
  },
  {
    code: "WWvvOwvTokSoc",
    descriptionShort: "Toegerekende organisatiekosten sociale lasten",
    descriptionLong:
      "Toegerekende organisatiekosten sociale lasten vastgoed in ontwikkeling tbv verhuur",
  },
  {
    code: "WWvvOwvTokPen",
    descriptionShort: "Toegerekende organisatiekosten pensioenlasten",
    descriptionLong:
      "Toegerekende organisatiekosten pensioenlasten vastgoed in ontwikkeling tbv verhuur",
  },
  {
    code: "WWvvOwvTokAfs",
    descriptionShort: "Toegerekende organisatiekosten afschrijvingen",
    descriptionLong:
      "Toegerekende organisatiekosten afschrijvingen vastgoed in ontwikkeling tbv verhuur",
  },
  {
    code: "WWvvOwvTokObl",
    descriptionShort: "Toegerekende organisatiekosten overige bedrijfslasten",
    descriptionLong:
      "Toegerekende organisatiekosten overige bedrijfslasten vastgoed in ontwikkeling tbv verhuur",
  },
  {
    code: "WWvvOwvTokOpl",
    descriptionShort: "Toegerekende organisatiekosten overige personeelslasten",
    descriptionLong:
      "Toegerekende organisatiekosten overige personeelslasten vastgoed in ontwikkeling tbv verhuur",
  },
  {
    code: "WWvvOwvTokGap",
    descriptionShort: "Toegerekende organisatiekosten geactiveerde productie",
    descriptionLong: "Toegerekende organisatiekosten geactiveerde productie",
  },
  {
    code: "WWvvNwp",
    descriptionShort:
      "Niet-gerealiseerde waardeveranderingen van vastgoedportefeuille",
    descriptionLong:
      "Niet-gerealiseerde waardeveranderingen van vastgoedportefeuille",
  },
  {
    code: "WWvvNwpNwp",
    descriptionShort:
      "Niet-gerealiseerde waardeveranderingen van vastgoedportefeuille",
    descriptionLong:
      "Niet-gerealiseerde waardeveranderingen van vastgoedportefeuille",
  },
  {
    code: "WWvvNwv",
    descriptionShort:
      "Niet-gerealiseerde waardeveranderingen van vastgoedportefeuille verkocht onder voorwaarden",
    descriptionLong:
      "Niet-gerealiseerde waardeveranderingen van vastgoedportefeuille verkocht onder voorwaarden",
  },
  {
    code: "WWvvNwvNwv",
    descriptionShort:
      "Niet-gerealiseerde waardeveranderingen van vastgoedportefeuille verkocht onder voorwaarden",
    descriptionLong:
      "Niet-gerealiseerde waardeveranderingen van vastgoedportefeuille verkocht onder voorwaarden",
  },
  {
    code: "WWvvNwb",
    descriptionShort:
      "Niet-gerealiseerde waardeveranderingen van vastgoedportefeuille bestemd voor verkoop",
    descriptionLong:
      "Niet-gerealiseerde waardeveranderingen van vastgoedportefeuille bestemd voor verkoop",
  },
  {
    code: "WWvvNwbNwb",
    descriptionShort:
      "Niet-gerealiseerde waardeveranderingen van vastgoedportefeuille bestemd voor verkoop",
    descriptionLong:
      "Niet-gerealiseerde waardeveranderingen van vastgoedportefeuille bestemd voor verkoop",
  },
  {
    code: "WNoa",
    descriptionShort: "Netto resultaat overige activiteiten",
    descriptionLong: "Netto resultaat overige activiteiten",
  },
  {
    code: "WNoaOoa",
    descriptionShort: "Opbrengsten overige activiteiten",
    descriptionLong: "Opbrengsten overige activiteiten",
  },
  {
    code: "WNoaOoaOoa",
    descriptionShort: "Opbrengsten overige activiteiten",
    descriptionLong: "Opbrengsten overige activiteiten",
  },
  {
    code: "WNoaOoaOoaAop",
    descriptionShort: "Antenne opstelling",
    descriptionLong: "Antenne opstelling",
  },
  { code: "WNoaOoaOoaVve", descriptionShort: "VVE", descriptionLong: "VVE" },
  {
    code: "WNoaOoaOoaZpn",
    descriptionShort: "Zonnepanelen",
    descriptionLong: "Zonnepanelen",
  },
  {
    code: "WNoaOoaOoaWpp",
    descriptionShort: "Warmtepompen",
    descriptionLong: "Warmtepompen",
  },
  {
    code: "WNoaOoaOoaVbw",
    descriptionShort: "Vastrecht bronwarmte",
    descriptionLong: "Vastrecht bronwarmte",
  },
  {
    code: "WNoaOoaOoaWaw",
    descriptionShort: "Opbrengsten warmtewet",
    descriptionLong: "Opbrengsten warmtewet",
  },
  {
    code: "WNoaOoaOoaDer",
    descriptionShort: "Derving opbrengsten overige activiteiten",
    descriptionLong: "Derving opbrengsten overige activiteiten",
  },
  {
    code: "WNoaOoaOoaOvr",
    descriptionShort: "Overige opbrengsten",
    descriptionLong: "Overige opbrengsten",
  },
  {
    code: "WNoaKoa",
    descriptionShort: "Kosten overige activiteiten",
    descriptionLong: "Kosten overige activiteiten",
  },
  {
    code: "WNoaKoaKoa",
    descriptionShort: "Kosten overige activiteiten",
    descriptionLong: "Kosten overige activiteiten",
  },
  {
    code: "WNoaKoaKoaSal",
    descriptionShort: "Aan overige activiteiten toegerekende salarissen",
    descriptionLong: "Aan overige activiteiten toegerekende salarissen",
  },
  {
    code: "WNoaKoaKoaSoc",
    descriptionShort: "Aan overige activiteiten toegerekende sociale lasten",
    descriptionLong: "Aan overige activiteiten toegerekende sociale lasten",
  },
  {
    code: "WNoaKoaKoaPen",
    descriptionShort: "Aan overige activiteiten toegerekende pensioenlasten",
    descriptionLong: "Aan overige activiteiten toegerekende pensioenlasten",
  },
  {
    code: "WNoaKoaKoaAfs",
    descriptionShort: "Aan overige activiteiten toegerekende afschrijvingen",
    descriptionLong: "Aan overige activiteiten toegerekende afschrijvingen",
  },
  {
    code: "WNoaKoaKoaObl",
    descriptionShort:
      "Aan overige activiteiten toegerekende overige bedrijfslasten",
    descriptionLong:
      "Aan overige activiteiten toegerekende overige bedrijfslasten",
  },
  {
    code: "WNoaKoaKoaKuw",
    descriptionShort: "Kosten uitbesteed werk overige activiteiten",
    descriptionLong: "Kosten uitbesteed werk overige activiteiten",
  },
  {
    code: "WNoaKoaKoaOpl",
    descriptionShort:
      "Aan overige activiteiten toegerekende overige personeelslasten",
    descriptionLong:
      "Aan overige activiteiten toegerekende overige personeelslasten",
  },
  {
    code: "WNoaKoaKoaGpe",
    descriptionShort: "Geactiveerde productie voor het eigen bedrijf",
    descriptionLong: "Geactiveerde productie voor het eigen bedrijf",
  },
  {
    code: "WOok",
    descriptionShort: "Overige organisatiekosten",
    descriptionLong: "Overige organisatiekosten",
  },
  {
    code: "WOokOok",
    descriptionShort: "Overige organisatiekosten",
    descriptionLong: "Overige organisatiekosten",
  },
  {
    code: "WOokOokSal",
    descriptionShort: "Aan overige organisatiekosten toegerekende salarissen",
    descriptionLong: "Aan overige organisatiekosten toegerekende salarissen",
  },
  {
    code: "WOokOokSoc",
    descriptionShort:
      "Aan overige organisatiekosten toegerekende sociale lasten",
    descriptionLong:
      "Aan overige organisatiekosten toegerekende sociale lasten",
  },
  {
    code: "WOokOokPen",
    descriptionShort:
      "Aan overige organisatiekosten toegerekende pensioenlasten",
    descriptionLong:
      "Aan overige organisatiekosten toegerekende pensioenlasten",
  },
  {
    code: "WOokOokAfs",
    descriptionShort:
      "Aan overige organisatiekosten toegerekende afschrijvingen",
    descriptionLong:
      "Aan overige organisatiekosten toegerekende afschrijvingen",
  },
  {
    code: "WOokOokObl",
    descriptionShort:
      "Aan overige organisatiekosten toegerekende overige bedrijfslasten",
    descriptionLong:
      "Aan overige organisatiekosten toegerekende overige bedrijfslasten",
  },
  {
    code: "WOokOokOpl",
    descriptionShort:
      "Aan overige organisatiekosten toegerekende overige personeelskosten",
    descriptionLong:
      "Aan overige organisatiekosten toegerekende overige personeelskosten",
  },
  {
    code: "WOokOokOkn",
    descriptionShort: "Overige organisatiekosten",
    descriptionLong: "Overige organisatiekosten",
  },
  {
    code: "WOokOokDok",
    descriptionShort: "Doorberekende organisatiekosten",
    descriptionLong: "Doorberekende organisatiekosten",
  },
  {
    code: "WOokOokShf",
    descriptionShort: "Saneringsheffing",
    descriptionLong: "Saneringsheffing",
  },
  {
    code: "WOokOokBhf",
    descriptionShort: "Bijdrageheffing Autoriteit woningcorporaties",
    descriptionLong: "Bijdrageheffing Autoriteit woningcorporaties",
  },
  {
    code: "WOokOokOhf",
    descriptionShort: "Obligoheffing WSW",
    descriptionLong: "Obligoheffing WSW",
  },
  {
    code: "WKol",
    descriptionShort: "Kosten omtrent leefbaarheid",
    descriptionLong: "Kosten omtrent leefbaarheid",
  },
  {
    code: "WKolKol",
    descriptionShort: "Kosten omtrent leefbaarheid",
    descriptionLong: "Kosten omtrent leefbaarheid",
  },
  {
    code: "WKolKolLee",
    descriptionShort: "Kosten omtrent leefbaarheid",
    descriptionLong: "Kosten omtrent leefbaarheid",
  },
  {
    code: "WKolKolLeeMpo",
    descriptionShort: "Aanpak multiproblematiek en overlast",
    descriptionLong: "Aanpak multiproblematiek en overlast",
  },
  {
    code: "WKolKolLeeKli",
    descriptionShort: "Kleinschalige leefbaarheidsinitiatieven",
    descriptionLong: "Kleinschalige leefbaarheidsinitiatieven",
  },
  {
    code: "WKolKolLeeInb",
    descriptionShort: "Interventies buitenruimte",
    descriptionLong: "Interventies buitenruimte",
  },
  {
    code: "WKolKolLeeKwp",
    descriptionShort: "Kleinschalige wijkpanden",
    descriptionLong: "Kleinschalige wijkpanden",
  },
  {
    code: "WKolKolLeeWbe",
    descriptionShort: "Wijkbeheer/schoon, heel, veilig",
    descriptionLong: "Wijkbeheer/schoon, heel, veilig",
  },
  {
    code: "WKolKolLeeSal",
    descriptionShort: "Aan leefbaarheid toegerekende salarissen",
    descriptionLong: "Aan leefbaarheid toegerekende salarissen",
  },
  {
    code: "WKolKolLeeSoc",
    descriptionShort: "Aan leefbaarheid toegerekende sociale lasten",
    descriptionLong: "Aan leefbaarheid toegerekende sociale lasten",
  },
  {
    code: "WKolKolLeePen",
    descriptionShort: "Aan leefbaarheid toegerekende pensioenlasten",
    descriptionLong: "Aan leefbaarheid toegerekende pensioenlasten",
  },
  {
    code: "WKolKolLeeAfs",
    descriptionShort: "Aan leefbaarheid toegerekende afschrijvingen",
    descriptionLong: "Aan leefbaarheid toegerekende afschrijvingen",
  },
  {
    code: "WKolKolLeeObl",
    descriptionShort: "Aan leefbaarheid toegerekende overige bedrijfslasten",
    descriptionLong: "Aan leefbaarheid toegerekende overige bedrijfslasten",
  },
  {
    code: "WKolKolLeeOpl",
    descriptionShort: "Aan leefbaarheid toegerekende overige personeelslasten",
    descriptionLong: "Aan leefbaarheid toegerekende overige personeelslasten",
  },
  {
    code: "WKolKolLeeDul",
    descriptionShort: "Dekking uitgaven leefbaarheid",
    descriptionLong: "Dekking uitgaven leefbaarheid",
  },
  {
    code: "WVkf",
    descriptionShort: "Verkoopkosten",
    descriptionLong: "Verkoopkosten",
  },
  {
    code: "WVkfVkf",
    descriptionShort: "Verkoopkosten",
    descriptionLong: "Verkoopkosten",
  },
  {
    code: "WVkfVkfVkf",
    descriptionShort: "Verkoopkosten",
    descriptionLong: "Verkoopkosten",
  },
  {
    code: "WAkf",
    descriptionShort: "Algemene beheerskosten",
    descriptionLong: "Algemene beheerskosten",
  },
  {
    code: "WAkfAkf",
    descriptionShort: "Algemene beheerskosten",
    descriptionLong: "Algemene beheerskosten",
  },
  {
    code: "WAkfAkfAkf",
    descriptionShort: "Algemene beheerskosten",
    descriptionLong: "Algemene beheerskosten",
  },
  {
    code: "WWiv",
    descriptionShort: "Wijziging voorraden",
    descriptionLong: "Wijziging voorraden",
  },
  {
    code: "WWivWgp",
    descriptionShort:
      "Wijziging in voorraden grond- en hulpstoffen, gereed product en handelsgoederen",
    descriptionLong:
      "Wijziging in voorraden grond- en hulpstoffen, gereed product en handelsgoederen",
  },
  {
    code: "WWivWgpWgh",
    descriptionShort:
      "Wijziging in voorraden grond- en hulpstoffen / halffabrikaten)",
    descriptionLong:
      "Wijziging in voorraden grond- en hulpstoffen / halffabrikaten)",
  },
  {
    code: "WWivWgpWgp",
    descriptionShort: "Wijziging in voorraden gereed product",
    descriptionLong: "Wijziging in voorraden gereed product",
  },
  {
    code: "WWivWgpWhg",
    descriptionShort: "Wijziging in voorraden handelsgoederen",
    descriptionLong: "Wijziging in voorraden handelsgoederen",
  },
  {
    code: "WWivWow",
    descriptionShort: "Wijziging in voorraden onderhanden werk",
    descriptionLong: "Wijziging in voorraden onderhanden werk",
  },
  {
    code: "WWivWowWow",
    descriptionShort: "Wijziging in vooraden onderhanden werk",
    descriptionLong: "Wijziging in vooraden onderhanden werk",
  },
  {
    code: "WWivWop",
    descriptionShort:
      "Wijziging in onderhanden projecten in opdracht van derden",
    descriptionLong:
      "Wijziging in onderhanden projecten in opdracht van derden",
  },
  {
    code: "WWivWopWop",
    descriptionShort: "Wijziging in onderhanden projecten",
    descriptionLong: "Wijziging in onderhanden projecten",
  },
  {
    code: "WWivGpv",
    descriptionShort: "Geactiveerde productie voor het eigen bedrijf",
    descriptionLong: "Geactiveerde productie voor het eigen bedrijf",
  },
  {
    code: "WWivGpvGpe",
    descriptionShort: "Geactiveerde productie voor het eigen bedrijf",
    descriptionLong: "Geactiveerde productie voor het eigen bedrijf",
  },
  {
    code: "WWivGpvPge",
    descriptionShort: "Privé gebruik eigen bedrijf",
    descriptionLong: "Privé gebruik eigen bedrijf",
  },
  {
    code: "WWivWav",
    descriptionShort: "Wijziging agrarische voorraden",
    descriptionLong: "Wijziging agrarische voorraden",
  },
  {
    code: "WWivWavAaf",
    descriptionShort: "Aanwas fruitopstanden",
    descriptionLong: "Aanwas fruitopstanden",
  },
  {
    code: "WWivWavAav",
    descriptionShort: "Aanwas vee",
    descriptionLong: "Aanwas vee",
  },
  {
    code: "WWivWavOvv",
    descriptionShort: "Overige voorraadmutaties",
    descriptionLong: "Overige voorraadmutaties",
  },
  {
    code: "WWivWva",
    descriptionShort: "Waardeveranderingen van agrarische voorraden",
    descriptionLong: "Waardeveranderingen van agrarische voorraden",
  },
  {
    code: "WWivWvaWva",
    descriptionShort: "Waardeveranderingen van agrarische voorraden",
    descriptionLong: "Waardeveranderingen van agrarische voorraden",
  },
  {
    code: "WWivMrv",
    descriptionShort: "Marge-voorraden",
    descriptionLong: "Marge-voorraden",
  },
  {
    code: "WWivMrvMrv",
    descriptionShort: "Marge-voorraden",
    descriptionLong: "Marge-voorraden",
  },
  {
    code: "WKpr",
    descriptionShort: "Kostprijs van de omzet",
    descriptionLong: "Kostprijs van de omzet",
  },
  {
    code: "WKprKvg",
    descriptionShort: "Kosten van grond- en hulpstoffen / halffabrikaten",
    descriptionLong: "Kosten van grond- en hulpstoffen / halffabrikaten",
  },
  {
    code: "WKprKvgKvg",
    descriptionShort:
      "Kosten van grond- en hulpstoffen, ingekocht in Nederland",
    descriptionLong:
      "Kosten van grond- en hulpstoffen, ingekocht in Nederland kosten van grond- en hulpstoffen",
  },
  {
    code: "WKprKvgKgi",
    descriptionShort:
      "Kosten van grond- en hulpstoffen, ingekocht in het buitenland",
    descriptionLong:
      "Kosten van grond- en hulpstoffen, ingekocht in het buitenland kosten van grond- en hulpstoffen",
  },
  {
    code: "WKprKvgVrv",
    descriptionShort: "Voorraadverschillen grond- en hulpstoffen",
    descriptionLong: "Voorraadverschillen grond- en hulpstoffen",
  },
  {
    code: "WKprKvgPrv",
    descriptionShort: "Prijsverschillen inkoop grond- en hulpstoffen",
    descriptionLong: "Prijsverschillen inkoop grond- en hulpstoffen",
  },
  {
    code: "WKprKvgKhn",
    descriptionShort: "Kosten van halffabrikaten, ingekocht in Nederland",
    descriptionLong:
      "Kosten van halffabrikaten, ingekocht in Nederland van halffabrikaten",
  },
  {
    code: "WKprKvgKhb",
    descriptionShort: "Kosten van halffabrikaten, ingekocht in het buitenland",
    descriptionLong:
      "Kosten van halffabrikaten, ingekocht in het buitenland van halffabrikaten",
  },
  {
    code: "WKprKvgVvh",
    descriptionShort: "Voorraadverschillen halffabrikaten",
    descriptionLong: "Voorraadverschillen halffabrikaten",
  },
  {
    code: "WKprKvgPvh",
    descriptionShort: "Prijsverschillen inkoop halffabrikaten",
    descriptionLong: "Prijsverschillen inkoop halffabrikaten",
  },
  {
    code: "WKprKvgDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling kosten van grond- en hulpstoffen / halffabrikaten",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling kosten van grond- en hulpstoffen / halffabrikaten",
  },
  {
    code: "WKprKvp",
    descriptionShort: "Kosten van personeel",
    descriptionLong: "Kosten van personeel",
  },
  {
    code: "WKprKvpKvp",
    descriptionShort: "Kosten van personeel",
    descriptionLong: "Kosten van personeel kosten van personeel",
  },
  {
    code: "WKprKvpDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling kosten van personeel",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling kosten van personeel",
  },
  {
    code: "WKprKuw",
    descriptionShort: "Kosten uitbesteed werk en andere externe kosten",
    descriptionLong: "Kosten uitbesteed werk en andere externe kosten",
  },
  {
    code: "WKprKuwKuw",
    descriptionShort: "Kosten van uitbesteed werk",
    descriptionLong: "Kosten van uitbesteed werk",
  },
  {
    code: "WKprKuwAek",
    descriptionShort: "Andere externe kosten",
    descriptionLong: "Andere externe kosten ",
  },
  {
    code: "WKprKuwDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling kosten uitbesteed werk en andere externe kosten",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling kosten uitbesteed werk en andere externe kosten",
  },
  {
    code: "WKprAkl",
    descriptionShort: "Toegerekende kosten",
    descriptionLong: "Toegerekende kosten",
  },
  {
    code: "WKprAklTee",
    descriptionShort: "Teeltkosten",
    descriptionLong: "Teeltkosten toegerekende kosten",
  },
  {
    code: "WKprAklZpe",
    descriptionShort: "Zaai, plant en pootgoedkosten",
    descriptionLong: "Zaai, plant en pootgoedkosten toegerekende kosten",
  },
  {
    code: "WKprAklSmk",
    descriptionShort: "Substraatmateriaalkosten",
    descriptionLong: "Substraatmateriaalkosten toegerekende kosten",
  },
  {
    code: "WKprAklBdm",
    descriptionShort: "Bemestingskosten dierlijke mest",
    descriptionLong: "Bemestingskosten dierlijke mest toegerekende kosten",
  },
  {
    code: "WKprAklBek",
    descriptionShort: "Bemestingskosten kunstmest",
    descriptionLong: "Bemestingskosten kunstmest toegerekende kosten",
  },
  {
    code: "WKprAklGew",
    descriptionShort: "Gewasbeschermingskosten",
    descriptionLong: "Gewasbeschermingskosten toegerekende kosten",
  },
  {
    code: "WKprAklGvk",
    descriptionShort: "Gewassenverzekeringskosten",
    descriptionLong: "Gewassenverzekeringskosten toegerekende kosten",
  },
  {
    code: "WKprAklAft",
    descriptionShort: "Afvoerkosten teeltafval",
    descriptionLong: "Afvoerkosten teeltafval toegerekende kosten",
  },
  {
    code: "WKprAklCoe",
    descriptionShort: "CO2-, OCAP- en waterkosten",
    descriptionLong: "CO2-, OCAP- en waterkosten toegerekende kosten",
  },
  {
    code: "WKprAklPeg",
    descriptionShort: "Potten en grondkosten",
    descriptionLong: "Potten en grondkosten toegerekende kosten",
  },
  {
    code: "WKprAklOte",
    descriptionShort: "Overige teeltkosten",
    descriptionLong: "Overige teeltkosten toegerekende kosten",
  },
  {
    code: "WKprAklGko",
    descriptionShort: "Gaskosten",
    descriptionLong: "Gaskosten toegerekende kosten",
  },
  {
    code: "WKprAklEkn",
    descriptionShort: "Elektrakosten",
    descriptionLong: "Elektrakosten toegerekende kosten",
  },
  {
    code: "WKprAklWkn",
    descriptionShort: "Water kosten",
    descriptionLong: "Water kosten toegerekende kosten",
  },
  {
    code: "WKprAklOwk",
    descriptionShort: "Onderhoudskosten WKK",
    descriptionLong: "Onderhoudskosten WKK toegerekende kosten",
  },
  {
    code: "WKprAklLew",
    descriptionShort: "Leasekosten WKK",
    descriptionLong: "Leasekosten WKK toegerekende kosten",
  },
  {
    code: "WKprAklVkn",
    descriptionShort: "Veilingkosten",
    descriptionLong: "Veilingkosten toegerekende kosten",
  },
  {
    code: "WKprAklAve",
    descriptionShort: "Afzet-, verpakking- en fustkosten",
    descriptionLong: "Afzet-, verpakking- en fustkosten toegerekende kosten",
  },
  {
    code: "WKprAklPah",
    descriptionShort: "Pacht/huur",
    descriptionLong: "Pacht/huur toegerekende kosten",
  },
  {
    code: "WKprAklTra",
    descriptionShort: "Transportkosten",
    descriptionLong: "Transportkosten toegerekende kosten",
  },
  {
    code: "WKprAklCtk",
    descriptionShort: "Contractteeltkosten",
    descriptionLong: "Contractteeltkosten toegerekende kosten",
  },
  {
    code: "WKprAklVwa",
    descriptionShort: "Vergoeding werk aan derden",
    descriptionLong: "Vergoeding werk aan derden toegerekende kosten",
  },
  {
    code: "WKprAklPbk",
    descriptionShort: "Productbewerkingskosten",
    descriptionLong: "Productbewerkingskosten toegerekende kosten",
  },
  {
    code: "WKprAklAaf",
    descriptionShort: "Aanwas fruitopstanden",
    descriptionLong: "Aanwas fruitopstanden toegerekende kosten",
  },
  {
    code: "WKprAklSod",
    descriptionShort: "Sorteerkosten derden",
    descriptionLong: "Sorteerkosten derden toegerekende kosten",
  },
  {
    code: "WKprAklCkn",
    descriptionShort: "Compostkosten",
    descriptionLong: "Compostkosten toegerekende kosten",
  },
  {
    code: "WKprAklAfc",
    descriptionShort: "Afvoerkosten champost",
    descriptionLong: "Afvoerkosten champost toegerekende kosten",
  },
  {
    code: "WKprIna",
    descriptionShort: "Inkoopwaarde agrarisch",
    descriptionLong: "Inkoopwaarde agrarisch",
  },
  {
    code: "WKprInaLpk",
    descriptionShort: "Inkoopkosten planten opkweek",
    descriptionLong: "Inkoopkosten planten opkweek inkoopwaarde agrarisch",
  },
  {
    code: "WKprInaLph",
    descriptionShort: "Inkoopkosten planten handel",
    descriptionLong: "Inkoopkosten planten handel inkoopwaarde agrarisch",
  },
  {
    code: "WKprInaLpo",
    descriptionShort: "Inkoopkosten potten",
    descriptionLong: "Inkoopkosten potten inkoopwaarde agrarisch",
  },
  {
    code: "WKprInaLpt",
    descriptionShort: "Inkoopkosten potgrond",
    descriptionLong: "Inkoopkosten potgrond inkoopwaarde agrarisch",
  },
  {
    code: "WKprInaLbh",
    descriptionShort: "Inkoopkosten bloembollen handel",
    descriptionLong: "Inkoopkosten bloembollen handel inkoopwaarde agrarisch",
  },
  {
    code: "WKprAkv",
    descriptionShort: "Agrarische bedrijfskosten veeteelt",
    descriptionLong: "Agrarische bedrijfskosten veeteelt",
  },
  {
    code: "WKprAkvVks",
    descriptionShort: "Voerkosten",
    descriptionLong: "Voerkosten agrarische bedrijfskosten veeteelt",
  },
  {
    code: "WKprAkvGez",
    descriptionShort: "Gezondheidszorgkosten",
    descriptionLong: "Gezondheidszorgkosten agrarische bedrijfskosten veeteelt",
  },
  {
    code: "WKprAkvKie",
    descriptionShort: "K.I./Fokkerijkosten",
    descriptionLong: "K.I./Fokkerijkosten agrarische bedrijfskosten veeteelt",
  },
  {
    code: "WKprAkvTee",
    descriptionShort: "Teeltkosten",
    descriptionLong: "Teeltkosten agrarische bedrijfskosten veeteelt",
  },
  {
    code: "WKprAkvSkn",
    descriptionShort: "Strooiselkosten",
    descriptionLong: "Strooiselkosten agrarische bedrijfskosten veeteelt",
  },
  {
    code: "WKprAkvEne",
    descriptionShort: "Energiekosten",
    descriptionLong: "Energiekosten agrarische bedrijfskosten veeteelt",
  },
  {
    code: "WKprAkvOve",
    descriptionShort: "Overige veekosten",
    descriptionLong: "Overige veekosten agrarische bedrijfskosten veeteelt",
  },
  {
    code: "WKprAkvMes",
    descriptionShort: "Mestafzetkosten",
    descriptionLong: "Mestafzetkosten agrarische bedrijfskosten veeteelt",
  },
  {
    code: "WKprAkvLep",
    descriptionShort: "Leasekosten productierechten",
    descriptionLong:
      "Leasekosten productierechten agrarische bedrijfskosten veeteelt",
  },
  {
    code: "WKprAkvEie",
    descriptionShort: "Eiergeld",
    descriptionLong: "Eiergeld agrarische bedrijfskosten veeteelt",
  },
  {
    code: "WKprAkvKvk",
    descriptionShort: "Krachtvoerkosten",
    descriptionLong: "Krachtvoerkosten agrarische bedrijfskosten veeteelt",
  },
  {
    code: "WKprAkvRuw",
    descriptionShort: "Ruwvoerkosten",
    descriptionLong: "Ruwvoerkosten agrarische bedrijfskosten veeteelt",
  },
  {
    code: "WKprAkvBik",
    descriptionShort: "Bijproducten kosten",
    descriptionLong: "Bijproducten kosten agrarische bedrijfskosten veeteelt",
  },
  {
    code: "WKprAkvVgd",
    descriptionShort: "Voergeld",
    descriptionLong: "Voergeld agrarische bedrijfskosten veeteelt",
  },
  {
    code: "WKprAkvAvb",
    descriptionShort: "Aankopen vee",
    descriptionLong: "Aankopen vee agrarische bedrijfskosten veeteelt",
  },
  {
    code: "WKprAkvAam",
    descriptionShort: "Aankopen melkkoeien",
    descriptionLong: "Aankopen melkkoeien agrarische bedrijfskosten veeteelt",
  },
  {
    code: "WKprAkvAjo",
    descriptionShort: "Aankopen jongvee ouder dan 1 jaar",
    descriptionLong:
      "Aankopen jongvee ouder dan 1 jaar agrarische bedrijfskosten veeteelt",
  },
  {
    code: "WKprAkvMel",
    descriptionShort: "Melkgeld",
    descriptionLong: "Melkgeld agrarische bedrijfskosten veeteelt",
  },
  {
    code: "WKprAkvLve",
    descriptionShort: "Inzet- vang- en laadkosten",
    descriptionLong:
      "Inzet- vang- en laadkosten agrarische bedrijfskosten veeteelt",
  },
  {
    code: "WKprKra",
    descriptionShort: "Kosten van rente en afschrijvingen",
    descriptionLong: "Kosten van rente en afschrijvingen",
  },
  {
    code: "WKprKraKra",
    descriptionShort: "Kosten van rente",
    descriptionLong: "Kosten van rente kosten van rente en afschrijvingen",
  },
  {
    code: "WKprKraKva",
    descriptionShort: "Kosten van afschrijvingen",
    descriptionLong:
      "Kosten van afschrijvingen kosten van rente en afschrijvingen",
  },
  {
    code: "WKprKraDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling kosten van rente en afschrijvingen",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling kosten van rente en afschrijvingen",
  },
  {
    code: "WKprInh",
    descriptionShort: "Inkoopwaarde handelsgoederen",
    descriptionLong: "Inkoopwaarde handelsgoederen",
  },
  {
    code: "WKprInhInh",
    descriptionShort: "Inkoopwaarde handelsgoederen",
    descriptionLong: "Inkoopwaarde handelsgoederen",
  },
  {
    code: "WKprInhVrv",
    descriptionShort: "Voorraadverschillen handelsgoederen",
    descriptionLong: "Voorraadverschillen handelsgoederen",
  },
  {
    code: "WKprInhPrv",
    descriptionShort: "Prijsverschillen inkoop handelsgoederen",
    descriptionLong: "Prijsverschillen inkoop handelsgoederen",
  },
  {
    code: "WKprInhMai",
    descriptionShort: "Margeinkopen",
    descriptionLong: "Margeinkopen ",
  },
  {
    code: "WKprInhDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling inkoopwaarde handelsgoederen",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling inkoopwaarde handelsgoederen",
  },
  {
    code: "WKprInp",
    descriptionShort: "Inkoopwaarde productiegoederen",
    descriptionLong: "Inkoopwaarde productiegoederen",
  },
  {
    code: "WKprInpInp",
    descriptionShort: "Inkoopwaarde productiegoederen",
    descriptionLong: "Inkoopwaarde productiegoederen",
  },
  {
    code: "WKprInpVrv",
    descriptionShort: "Voorraadverschillen productiegoederen",
    descriptionLong: "Voorraadverschillen productiegoederen",
  },
  {
    code: "WKprInpPrv",
    descriptionShort: "Prijsverschillen inkoop productiegoederen",
    descriptionLong: "Prijsverschillen inkoop productiegoederen",
  },
  {
    code: "WKprInpDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling inkoopwaarde productiegoederen",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling inkoopwaarde productiegoederen",
  },
  {
    code: "WKprLeb",
    descriptionShort: "Inkoopkortingen en bonussen",
    descriptionLong: "Inkoopkortingen en bonussen",
  },
  {
    code: "WKprLebLeb",
    descriptionShort: "Inkoopkortingen en bonussen",
    descriptionLong:
      "Inkoopkortingen en bonussen inkoopwaarde handels- en productiegoederen",
  },
  {
    code: "WKprLebInp",
    descriptionShort: "Inkoopprovisie",
    descriptionLong: "Inkoopprovisie",
  },
  {
    code: "WKprLebDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling inkoopkortingen en bonussen",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling inkoopkortingen en bonussen",
  },
  {
    code: "WKprBtk",
    descriptionShort: "Betalingskortingen",
    descriptionLong: "Betalingskortingen",
  },
  {
    code: "WKprBtkBed",
    descriptionShort: "Betalingskorting debiteuren",
    descriptionLong: "Betalingskorting debiteuren",
  },
  {
    code: "WKprBtkBec",
    descriptionShort: "Betalingskortingen op inkopen",
    descriptionLong:
      "Betalingskortingen op inkopen inkoopwaarde handels- en productiegoederen",
  },
  {
    code: "WKprBtkDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling betalingskortingen",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling betalingskortingen",
  },
  {
    code: "WKprKit",
    descriptionShort: "Kostprijs intercompany transacties",
    descriptionLong: "Kostprijs intercompany transacties",
  },
  {
    code: "WKprKitKit",
    descriptionShort: "Kostprijs intercompany transacties",
    descriptionLong:
      "Kostprijs intercompany transacties inkoopwaarde handels- en productiegoederen",
  },
  {
    code: "WKprKitDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling betalingskortingen",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling betalingskortingen",
  },
  {
    code: "WKprMuo",
    descriptionShort: "Mutatie omzetvorderingen",
    descriptionLong: "Mutatie omzetvorderingen",
  },
  {
    code: "WKprMuoMuo",
    descriptionShort: "Mutatie omzetvorderingen",
    descriptionLong:
      "Mutatie omzetvorderingen inkoopwaarde handels- en productiegoederen",
  },
  {
    code: "WKprMuoDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling mutatie omzetvorderingen",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling mutatie omzetvorderingen",
  },
  {
    code: "WKprVom",
    descriptionShort: "Voorraadmutatie",
    descriptionLong: "Voorraadmutatie",
  },
  {
    code: "WKprVomVom",
    descriptionShort: "Voorraadmutatie",
    descriptionLong:
      "Voorraadmutatie inkoopwaarde handels- en productiegoederen",
  },
  {
    code: "WKprVomDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling voorraadmutatie",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling voorraadmutatie",
  },
  {
    code: "WKprPrg",
    descriptionShort: "Privé-gebruik goederen",
    descriptionLong: "Privé-gebruik goederen",
  },
  {
    code: "WKprPrgPrg",
    descriptionShort: "Privé-gebruik goederen",
    descriptionLong:
      "Privé-gebruik goederen inkoopwaarde handels- en productiegoederen",
  },
  {
    code: "WKprPrgDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling privé-gebruik goederen",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling privé-gebruik goederen",
  },
  {
    code: "WKprPrd",
    descriptionShort: "Privé-gebruik diensten",
    descriptionLong: "Privé-gebruik diensten",
  },
  {
    code: "WKprPrdPrd",
    descriptionShort: "Privé-gebruik diensten",
    descriptionLong:
      "Privé-gebruik diensten inkoopwaarde handels- en productiegoederen",
  },
  {
    code: "WKprPrdDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling privé-gebruik diensten",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling privé-gebruik diensten",
  },
  {
    code: "WKprGrp",
    descriptionShort: "Kostprijs - inkoopwaarde groepen",
    descriptionLong: "Kostprijs - inkoopwaarde groepen",
  },
  {
    code: "WKprGrpGr1",
    descriptionShort: "Kostprijs - inkoopwaarde groep 1",
    descriptionLong: "Kostprijs - inkoopwaarde groep 1",
  },
  {
    code: "WKprGrpGr1Pra",
    descriptionShort: "Kostprijs - inkoopwaarde groep 1 product A",
    descriptionLong: "Kostprijs - inkoopwaarde groep 1 product A",
  },
  {
    code: "WKprGrpGr1Prb",
    descriptionShort: "Kostprijs - inkoopwaarde groep 1 product B",
    descriptionLong: "Kostprijs - inkoopwaarde groep 1 product B",
  },
  {
    code: "WKprGrpGr1Prc",
    descriptionShort: "Kostprijs - inkoopwaarde groep 1 product C",
    descriptionLong: "Kostprijs - inkoopwaarde groep 1 product C",
  },
  {
    code: "WKprGrpGr1Prd",
    descriptionShort: "Kostprijs - inkoopwaarde groep 1 product D",
    descriptionLong: "Kostprijs - inkoopwaarde groep 1 product D",
  },
  {
    code: "WKprGrpGr1Pre",
    descriptionShort: "Kostprijs - inkoopwaarde groep 1 product E",
    descriptionLong: "Kostprijs - inkoopwaarde groep 1 product E",
  },
  {
    code: "WKprGrpGr2",
    descriptionShort: "Kostprijs - inkoopwaarde groep 2",
    descriptionLong: "Kostprijs - inkoopwaarde groep 2",
  },
  {
    code: "WKprGrpGr2Pra",
    descriptionShort: "Kostprijs - inkoopwaarde groep 2 product A",
    descriptionLong: "Kostprijs - inkoopwaarde groep 2 product A",
  },
  {
    code: "WKprGrpGr2Prb",
    descriptionShort: "Kostprijs - inkoopwaarde groep 2 product B",
    descriptionLong: "Kostprijs - inkoopwaarde groep 2 product B",
  },
  {
    code: "WKprGrpGr2Prc",
    descriptionShort: "Kostprijs - inkoopwaarde groep 2 product C",
    descriptionLong: "Kostprijs - inkoopwaarde groep 2 product C",
  },
  {
    code: "WKprGrpGr2Prd",
    descriptionShort: "Kostprijs - inkoopwaarde groep 2 product D",
    descriptionLong: "Kostprijs - inkoopwaarde groep 2 product D",
  },
  {
    code: "WKprGrpGr2Pre",
    descriptionShort: "Kostprijs - inkoopwaarde groep 2 product E",
    descriptionLong: "Kostprijs - inkoopwaarde groep 2 product E",
  },
  {
    code: "WKprGrpGr3",
    descriptionShort: "Kostprijs - inkoopwaarde groep 3",
    descriptionLong: "Kostprijs - inkoopwaarde groep 3",
  },
  {
    code: "WKprGrpGr3Pra",
    descriptionShort: "Kostprijs - inkoopwaarde groep 3 product A",
    descriptionLong: "Kostprijs - inkoopwaarde groep 3 product A",
  },
  {
    code: "WKprGrpGr3Prb",
    descriptionShort: "Kostprijs - inkoopwaarde groep 3 product B",
    descriptionLong: "Kostprijs - inkoopwaarde groep 3 product B",
  },
  {
    code: "WKprGrpGr3Prc",
    descriptionShort: "Kostprijs - inkoopwaarde groep 3 product C",
    descriptionLong: "Kostprijs - inkoopwaarde groep 3 product C",
  },
  {
    code: "WKprGrpGr3Prd",
    descriptionShort: "Kostprijs - inkoopwaarde groep 3 product D",
    descriptionLong: "Kostprijs - inkoopwaarde groep 3 product D",
  },
  {
    code: "WKprGrpGr3Pre",
    descriptionShort: "Kostprijs - inkoopwaarde groep 3 product E",
    descriptionLong: "Kostprijs - inkoopwaarde groep 3 product E",
  },
  {
    code: "WKprGrpGr4",
    descriptionShort: "Kostprijs - inkoopwaarde groep 4",
    descriptionLong: "Kostprijs - inkoopwaarde groep 4",
  },
  {
    code: "WKprGrpGr4Pra",
    descriptionShort: "Kostprijs - inkoopwaarde groep 4 product A",
    descriptionLong: "Kostprijs - inkoopwaarde groep 4 product A",
  },
  {
    code: "WKprGrpGr4Prb",
    descriptionShort: "Kostprijs - inkoopwaarde groep 4 product B",
    descriptionLong: "Kostprijs - inkoopwaarde groep 4 product B",
  },
  {
    code: "WKprGrpGr4Prc",
    descriptionShort: "Kostprijs - inkoopwaarde groep 4 product C",
    descriptionLong: "Kostprijs - inkoopwaarde groep 4 product C",
  },
  {
    code: "WKprGrpGr4Prd",
    descriptionShort: "Kostprijs - inkoopwaarde groep 4 product D",
    descriptionLong: "Kostprijs - inkoopwaarde groep 4 product D",
  },
  {
    code: "WKprGrpGr4Pre",
    descriptionShort: "Kostprijs - inkoopwaarde groep 4 product E",
    descriptionLong: "Kostprijs - inkoopwaarde groep 4 product E",
  },
  {
    code: "WKprGrpGr5",
    descriptionShort: "Kostprijs - inkoopwaarde groep 5",
    descriptionLong: "Kostprijs - inkoopwaarde groep 5",
  },
  {
    code: "WKprGrpGr5Pra",
    descriptionShort: "Kostprijs - inkoopwaarde groep 5 product A",
    descriptionLong: "Kostprijs - inkoopwaarde groep 5 product A",
  },
  {
    code: "WKprGrpGr5Prb",
    descriptionShort: "Kostprijs - inkoopwaarde groep 5 product B",
    descriptionLong: "Kostprijs - inkoopwaarde groep 5 product B",
  },
  {
    code: "WKprGrpGr5Prc",
    descriptionShort: "Kostprijs - inkoopwaarde groep 5 product C",
    descriptionLong: "Kostprijs - inkoopwaarde groep 5 product C",
  },
  {
    code: "WKprGrpGr5Prd",
    descriptionShort: "Kostprijs - inkoopwaarde groep 5 product D",
    descriptionLong: "Kostprijs - inkoopwaarde groep 5 product D",
  },
  {
    code: "WKprGrpGr5Pre",
    descriptionShort: "Kostprijs - inkoopwaarde groep 5 product E",
    descriptionLong: "Kostprijs - inkoopwaarde groep 5 product E",
  },
  {
    code: "WKprOniOn1Pra",
    descriptionShort:
      "Kostprijs van de omzet niet ingekocht bij leden product A",
    descriptionLong:
      "Kostprijs van de omzet niet ingekocht bij leden product A",
  },
  {
    code: "WKprOniOn1Prb",
    descriptionShort:
      "Kostprijs van de omzet niet ingekocht bij leden product B",
    descriptionLong:
      "Kostprijs van de omzet niet ingekocht bij leden product B",
  },
  {
    code: "WKprOniOn1Prc",
    descriptionShort:
      "Kostprijs van de omzet niet ingekocht bij leden product C",
    descriptionLong:
      "Kostprijs van de omzet niet ingekocht bij leden product C",
  },
  {
    code: "WKprOniOn1Prd",
    descriptionShort:
      "Kostprijs van de omzet niet ingekocht bij leden product D",
    descriptionLong:
      "Kostprijs van de omzet niet ingekocht bij leden product D",
  },
  {
    code: "WKprOniOn1Pre",
    descriptionShort:
      "Kostprijs van de omzet niet ingekocht bij leden product E",
    descriptionLong:
      "Kostprijs van de omzet niet ingekocht bij leden product E",
  },
  {
    code: "WKprGrpDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling kostprijs - inkoopwaarde groepen",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling kostprijs - inkoopwaarde groepen",
  },
  {
    code: "WKprOni",
    descriptionShort:
      "Kostprijs van de omzet niet ingekocht bij leden (Coöperatie)",
    descriptionLong:
      "Kostprijs van de omzet niet ingekocht bij leden (Coöperatie)",
  },
  {
    code: "WKprOniOn1",
    descriptionShort:
      "Kostprijs van de omzet niet ingekocht bij leden (Coöperatie)",
    descriptionLong:
      "Kostprijs van de omzet niet ingekocht bij leden (Coöperatie)",
  },
  {
    code: "WKprTvl",
    descriptionShort: "Totaal van lasten",
    descriptionLong: "Totaal van lasten",
  },
  {
    code: "WKprTvlIgp",
    descriptionShort: "Inkoopwaarde van geleverde producten",
    descriptionLong: "Inkoopwaarde van geleverde producten",
  },
  {
    code: "WKprTvlIgpIgp",
    descriptionShort: "Inkoopwaarde van geleverde producten",
    descriptionLong: "Inkoopwaarde van geleverde producten",
  },
  {
    code: "WKprTvlVsg",
    descriptionShort: "Verstrekte subsidies of giften",
    descriptionLong: "Verstrekte subsidies of giften",
  },
  {
    code: "WKprTvlVsgVsg",
    descriptionShort: "Verstrekte subsidies of giften",
    descriptionLong: "Verstrekte subsidies of giften",
  },
  {
    code: "WKprTvlLbd",
    descriptionShort: "Lasten besteed aan doelstellingen",
    descriptionLong: "Lasten besteed aan doelstellingen",
  },
  {
    code: "WKprTvlLbdLbd",
    descriptionShort: "Lasten besteed aan doelstellingen - overige",
    descriptionLong: "Lasten besteed aan doelstellingen - overige",
  },
  {
    code: "WKprTvlLbdLsb",
    descriptionShort: "Lasten van subsidies en bijdragen",
    descriptionLong: "Lasten van subsidies en bijdragen",
  },
  {
    code: "WKprTvlLbdAvo",
    descriptionShort: "Afdrachten aan verbonden (internationale) organisaties",
    descriptionLong: "Afdrachten aan verbonden (internationale) organisaties",
  },
  {
    code: "WKprTvlLbdLav",
    descriptionShort: "Lasten van aankopen en verwervingen",
    descriptionLong: "Lasten van aankopen en verwervingen",
  },
  {
    code: "WKprTvlLbdKuw",
    descriptionShort: "Kosten van uitbesteed werk",
    descriptionLong: "Kosten van uitbesteed werk",
  },
  {
    code: "WKprTvlLbdCom",
    descriptionShort: "Communicatiekosten",
    descriptionLong: "Communicatiekosten",
  },
  {
    code: "WKprTvlLbdPer",
    descriptionShort: "Lasten uit hoofde van personeelsbeloningen",
    descriptionLong: "Lasten uit hoofde van personeelsbeloningen",
  },
  {
    code: "WKprTvlLbdHui",
    descriptionShort: "Huisvestingskosten",
    descriptionLong: "Huisvestingskosten",
  },
  {
    code: "WKprTvlLbdKan",
    descriptionShort: "Kantoor- en algemene kosten",
    descriptionLong: "Kantoor- en algemene kosten",
  },
  {
    code: "WKprTvlLbdAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong: "Afschrijvingen",
  },
  {
    code: "WKprTvlWko",
    descriptionShort: "Wervingskosten",
    descriptionLong: "Wervingskosten",
  },
  {
    code: "WKprTvlWkoWko",
    descriptionShort: "Wervingskosten - overige",
    descriptionLong: "Wervingskosten - overige",
  },
  {
    code: "WKprTvlWkoLsb",
    descriptionShort: "Lasten van subsidies en bijdragen",
    descriptionLong: "Lasten van subsidies en bijdragen",
  },
  {
    code: "WKprTvlWkoAvo",
    descriptionShort: "Afdrachten aan verbonden (internationale) organisaties",
    descriptionLong: "Afdrachten aan verbonden (internationale) organisaties",
  },
  {
    code: "WKprTvlWkoLav",
    descriptionShort: "Lasten van aankopen en verwervingen",
    descriptionLong: "Lasten van aankopen en verwervingen",
  },
  {
    code: "WKprTvlWkoKuw",
    descriptionShort: "Kosten van uitbesteed werk",
    descriptionLong: "Kosten van uitbesteed werk",
  },
  {
    code: "WKprTvlWkoCom",
    descriptionShort: "Communicatiekosten",
    descriptionLong: "Communicatiekosten",
  },
  {
    code: "WKprTvlWkoPer",
    descriptionShort: "Lasten uit hoofde van personeelsbeloningen",
    descriptionLong: "Lasten uit hoofde van personeelsbeloningen",
  },
  {
    code: "WKprTvlWkoHui",
    descriptionShort: "Huisvestingskosten",
    descriptionLong: "Huisvestingskosten",
  },
  {
    code: "WKprTvlWkoKan",
    descriptionShort: "Kantoor- en algemene kosten",
    descriptionLong: "Kantoor- en algemene kosten",
  },
  {
    code: "WKprTvlWkoAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong: "Afschrijvingen",
  },
  {
    code: "WKprTvlKba",
    descriptionShort: "Kosten van beheer en administratie",
    descriptionLong: "Kosten van beheer en administratie",
  },
  {
    code: "WKprTvlKbaKba",
    descriptionShort: "Kosten van beheer en administratie - overige",
    descriptionLong: "Kosten van beheer en administratie - overige",
  },
  {
    code: "WKprTvlKbaLsb",
    descriptionShort: "Lasten van subsidies en bijdragen",
    descriptionLong: "Lasten van subsidies en bijdragen",
  },
  {
    code: "WKprTvlKbaAvo",
    descriptionShort: "Afdrachten aan verbonden (internationale) organisaties",
    descriptionLong: "Afdrachten aan verbonden (internationale) organisaties",
  },
  {
    code: "WKprTvlKbaLav",
    descriptionShort: "Lasten van aankopen en verwervingen",
    descriptionLong: "Lasten van aankopen en verwervingen",
  },
  {
    code: "WKprTvlKbaKuw",
    descriptionShort: "Kosten van uitbesteed werk",
    descriptionLong: "Kosten van uitbesteed werk",
  },
  {
    code: "WKprTvlKbaCom",
    descriptionShort: "Communicatiekosten",
    descriptionLong: "Communicatiekosten",
  },
  {
    code: "WKprTvlKbaPer",
    descriptionShort: "Lasten uit hoofde van personeelsbeloningen",
    descriptionLong: "Lasten uit hoofde van personeelsbeloningen",
  },
  {
    code: "WKprTvlKbaHui",
    descriptionShort: "Huisvestingskosten",
    descriptionLong: "Huisvestingskosten",
  },
  {
    code: "WKprTvlKbaKan",
    descriptionShort: "Kantoor- en algemene kosten",
    descriptionLong: "Kantoor- en algemene kosten",
  },
  {
    code: "WKprTvlKbaAfs",
    descriptionShort: "Afschrijvingen",
    descriptionLong: "Afschrijvingen",
  },
  {
    code: "WKprTvlAla",
    descriptionShort: "Andere lasten",
    descriptionLong: "Andere lasten",
  },
  {
    code: "WKprTvlAlaAla",
    descriptionShort: "Andere lasten",
    descriptionLong: "Andere lasten",
  },
  {
    code: "WOvb",
    descriptionShort: "Overige bedrijfsopbrengsten",
    descriptionLong: "Overige bedrijfsopbrengsten",
  },
  {
    code: "WOvbLpd",
    descriptionShort:
      "Baten als tegenprestatie voor de levering van producten en/of diensten",
    descriptionLong:
      "Baten als tegenprestatie voor de levering van producten en/of diensten",
  },
  {
    code: "WOvbLpdLpd",
    descriptionShort:
      "Baten als tegenprestatie voor de levering van producten en/of diensten",
    descriptionLong:
      "Baten als tegenprestatie voor de levering van producten en/of diensten",
  },
  {
    code: "WOvbLpdLpdDnb",
    descriptionShort: "Deelnemersbijdragen",
    descriptionLong: "Deelnemersbijdragen",
  },
  {
    code: "WOvbLpdLpdAbg",
    descriptionShort: "Abonnementsgelden",
    descriptionLong: "Abonnementsgelden",
  },
  {
    code: "WOvbLpdLpdHuo",
    descriptionShort: "Huuropbrengsten",
    descriptionLong: "Huuropbrengsten",
  },
  {
    code: "WOvbLpdLpdRec",
    descriptionShort: "Recettes",
    descriptionLong: "Recettes",
  },
  {
    code: "WOvbOrs",
    descriptionShort: "Subsidiebaten",
    descriptionLong: "Subsidiebaten",
  },
  {
    code: "WOvbOrsOel",
    descriptionShort: "Overheidssubsidies exclusief loonkostensubsidies",
    descriptionLong:
      "Overheidssubsidies exclusief loonkostensubsidies subsidiebaten",
  },
  {
    code: "WOvbOrsOre",
    descriptionShort: "Ontvangen restituties en subsidies",
    descriptionLong: "Ontvangen restituties en subsidies subsidiebaten",
  },
  {
    code: "WOvbOrsOreOsa",
    descriptionShort: "Ontvangen loonsubsidies",
    descriptionLong: "Ontvangen loonsubsidies subsidiebaten",
  },
  {
    code: "WOvbOrsOreOar",
    descriptionShort: "Ontvangen afdrachtrestituties",
    descriptionLong: "Ontvangen afdrachtrestituties subsidiebaten",
  },
  {
    code: "WOvbOrsOreEeo",
    descriptionShort:
      "Export- en overige restituties en subsidies ingevolge EU-regelingen",
    descriptionLong:
      "Export- en overige restituties en subsidies ingevolge EU-regelingen subsidiebaten",
  },
  {
    code: "WOvbOrsOsu",
    descriptionShort: "Overige ontvangen subsidies",
    descriptionLong: "Overige ontvangen subsidies subsidiebaten",
  },
  {
    code: "WOvbOrsOsuSro",
    descriptionShort: "Subsidiebaten van rijksoverheden",
    descriptionLong: "Subsidiebaten van rijksoverheden subsidiebaten",
  },
  {
    code: "WOvbOrsOsuSov",
    descriptionShort: "Subsidiebaten van overheden",
    descriptionLong: "Subsidiebaten van overheden subsidiebaten",
  },
  {
    code: "WOvbOrsOsuSoo",
    descriptionShort: "Subsidiebaten van overige overheden",
    descriptionLong: "Subsidiebaten van overige overheden subsidiebaten",
  },
  {
    code: "WOvbOrsOsuSeu",
    descriptionShort: "Subsidiebaten van de Europese Unie",
    descriptionLong: "Subsidiebaten van de Europese Unie subsidiebaten",
  },
  {
    code: "WOvbOrsOsuSbd",
    descriptionShort: "Subsidiebaten van bedrijven",
    descriptionLong: "Subsidiebaten van bedrijven subsidiebaten",
  },
  {
    code: "WOvbOrsOsuSpo",
    descriptionShort: "Subsidiebaten van private organisaties",
    descriptionLong: "Subsidiebaten van private organisaties subsidiebaten",
  },
  {
    code: "WOvbOrsOsuSop",
    descriptionShort: "Subsidiebaten van overige private organisaties",
    descriptionLong:
      "Subsidiebaten van overige private organisaties subsidiebaten",
  },
  {
    code: "WOvbSpd",
    descriptionShort: "Sponsorbijdragen",
    descriptionLong: "Sponsorbijdragen",
  },
  {
    code: "WOvbSpdSpd",
    descriptionShort: "Sponsorbijdragen",
    descriptionLong: "Sponsorbijdragen",
  },
  {
    code: "WOvbBue",
    descriptionShort: "Baten en giften uit fondsenwerving",
    descriptionLong: "Baten en giften uit fondsenwerving",
  },
  {
    code: "WOvbBueCol",
    descriptionShort: "Collecten",
    descriptionLong: "Collecten",
  },
  {
    code: "WOvbBueDeg",
    descriptionShort: "Donaties en giften",
    descriptionLong: "Donaties en giften",
  },
  {
    code: "WOvbBueCtb",
    descriptionShort: "Contributies",
    descriptionLong: "Contributies",
  },
  {
    code: "WOvbBueSpo",
    descriptionShort: "Sponsoring",
    descriptionLong: "Sponsoring",
  },
  {
    code: "WOvbBueNal",
    descriptionShort: "Nalatenschappen",
    descriptionLong: "Nalatenschappen",
  },
  {
    code: "WOvbBueEle",
    descriptionShort: "Eigen loterijen en prijsvragen",
    descriptionLong: "Eigen loterijen en prijsvragen",
  },
  {
    code: "WOvbBueVeg",
    descriptionShort: "Verkoop goederen",
    descriptionLong: "Verkoop goederen",
  },
  {
    code: "WOvbBueObu",
    descriptionShort: "Overige baten uit fondsenwerving",
    descriptionLong: "Overige baten uit fondsenwerving",
  },
  {
    code: "WOvbBug",
    descriptionShort: "Baten uit gezamenlijke acties",
    descriptionLong: "Baten uit gezamenlijke acties",
  },
  {
    code: "WOvbBugCol",
    descriptionShort: "Collecten",
    descriptionLong: "Collecten",
  },
  {
    code: "WOvbBugDeg",
    descriptionShort: "Donaties en giften",
    descriptionLong: "Donaties en giften",
  },
  {
    code: "WOvbBugCtb",
    descriptionShort: "Contributies",
    descriptionLong: "Contributies",
  },
  {
    code: "WOvbBugSpo",
    descriptionShort: "Sponsoring",
    descriptionLong: "Sponsoring",
  },
  {
    code: "WOvbBugNal",
    descriptionShort: "Nalatenschappen",
    descriptionLong: "Nalatenschappen",
  },
  {
    code: "WOvbBugEle",
    descriptionShort: "Eigen loterijen en prijsvragen",
    descriptionLong: "Eigen loterijen en prijsvragen",
  },
  {
    code: "WOvbBugVeg",
    descriptionShort: "Verkoop goederen",
    descriptionLong: "Verkoop goederen",
  },
  {
    code: "WOvbBugObu",
    descriptionShort: "Overige baten uit fondsenwerving",
    descriptionLong: "Overige baten uit fondsenwerving",
  },
  {
    code: "WOvbBua",
    descriptionShort: "Baten uit acties van derden",
    descriptionLong: "Baten uit acties van derden",
  },
  {
    code: "WOvbBuaCol",
    descriptionShort: "Collecten",
    descriptionLong: "Collecten",
  },
  {
    code: "WOvbBuaDeg",
    descriptionShort: "Donaties en giften",
    descriptionLong: "Donaties en giften",
  },
  {
    code: "WOvbBuaCtb",
    descriptionShort: "Contributies",
    descriptionLong: "Contributies",
  },
  {
    code: "WOvbBuaSpo",
    descriptionShort: "Sponsoring",
    descriptionLong: "Sponsoring",
  },
  {
    code: "WOvbBuaNal",
    descriptionShort: "Nalatenschappen",
    descriptionLong: "Nalatenschappen",
  },
  {
    code: "WOvbBuaEle",
    descriptionShort: "Eigen loterijen en prijsvragen",
    descriptionLong: "Eigen loterijen en prijsvragen",
  },
  {
    code: "WOvbBuaVeg",
    descriptionShort: "Verkoop goederen",
    descriptionLong: "Verkoop goederen",
  },
  {
    code: "WOvbBuaObu",
    descriptionShort: "Overige baten uit fondsenwerving",
    descriptionLong: "Overige baten uit fondsenwerving",
  },
  {
    code: "WOvbHuo",
    descriptionShort: "Huurontvangsten",
    descriptionLong: "Huurontvangsten",
  },
  {
    code: "WOvbHuoHuo",
    descriptionShort: "Huurontvangsten",
    descriptionLong: "Huurontvangsten baten uit overige activiteiten",
  },
  {
    code: "WOvbOps",
    descriptionShort: "Opbrengsten servicecontracten",
    descriptionLong: "Opbrengsten servicecontracten",
  },
  {
    code: "WOvbOpsOps",
    descriptionShort: "Opbrengsten servicecontracten",
    descriptionLong:
      "Opbrengsten servicecontracten baten uit overige activiteiten",
  },
  {
    code: "WOvbCcl",
    descriptionShort: "College-, cursus-, les- en examengelden",
    descriptionLong: "College-, cursus-, les- en examengelden",
  },
  {
    code: "WOvbCclCcl",
    descriptionShort: "College-, cursus-, les- en examengelden",
    descriptionLong:
      "College-, cursus-, les- en examengelden baten uit overige activiteiten",
  },
  {
    code: "WOvbNvv",
    descriptionShort: "Netto verkoopresultaat vastgoedportefeuille",
    descriptionLong: "Netto verkoopresultaat vastgoedportefeuille",
  },
  {
    code: "WOvbNvvNvv",
    descriptionShort: "Netto verkoopresultaat vastgoedportefeuille",
    descriptionLong:
      "Netto verkoopresultaat vastgoedportefeuille baten uit overige activiteiten",
  },
  {
    code: "WOvbBwi",
    descriptionShort: "Baten werk in opdracht van derden",
    descriptionLong: "Baten werk in opdracht van derden",
  },
  {
    code: "WOvbBwiBwi",
    descriptionShort: "Baten werk in opdracht van derden",
    descriptionLong:
      "Baten werk in opdracht van derden baten uit overige activiteiten",
  },
  {
    code: "WOvbOnm",
    descriptionShort: "Ontvangen managementvergoeding",
    descriptionLong: "Ontvangen managementvergoeding",
  },
  {
    code: "WOvbOnmOnm",
    descriptionShort: "Ontvangen managementvergoeding",
    descriptionLong:
      "Ontvangen managementvergoeding baten uit overige activiteiten",
  },
  {
    code: "WOvbOdp",
    descriptionShort: "Ontvangen doorbelasting personeelskosten",
    descriptionLong: "Ontvangen doorbelasting personeelskosten",
  },
  {
    code: "WOvbOdpOdp",
    descriptionShort: "Ontvangen doorbelasting personeelskosten",
    descriptionLong:
      "Ontvangen doorbelasting personeelskosten baten uit overige activiteiten",
  },
  {
    code: "WOvbOvo",
    descriptionShort: "Overige opbrengsten",
    descriptionLong: "Overige opbrengsten",
  },
  {
    code: "WOvbOvoOvo",
    descriptionShort: "Overige opbrengsten niet elders genoemd",
    descriptionLong:
      "Overige opbrengsten niet elders genoemd baten uit overige activiteiten",
  },
  {
    code: "WOvbVez",
    descriptionShort: "Verzekeringsuitkeringen",
    descriptionLong: "Verzekeringsuitkeringen",
  },
  {
    code: "WOvbVezUib",
    descriptionShort: "Uitkering bedrijfsschadeverzekering",
    descriptionLong:
      "Uitkering bedrijfsschadeverzekering verzekeringsuitkeringen",
  },
  {
    code: "WOvbVezOvu",
    descriptionShort: "Overige verzekeringsuitkeringen",
    descriptionLong: "Overige verzekeringsuitkeringen verzekeringsuitkeringen",
  },
  {
    code: "WOvbSgb",
    descriptionShort: "Som van de (geworven) baten",
    descriptionLong: "Bijdragen van donateurs",
  },
  {
    code: "WOvbSgbBvd",
    descriptionShort: "Bijdragen van donateurs",
    descriptionLong: "Bijdragen van donateurs",
  },
  {
    code: "WOvbSgbBvdBvd",
    descriptionShort: "Bijdragen van donateurs",
    descriptionLong: "Bijdragen van donateurs",
  },
  {
    code: "WOvbSgbBvl",
    descriptionShort: "Bijdragen van leden",
    descriptionLong: "Bijdragen van leden",
  },
  {
    code: "WOvbSgbBvlBvl",
    descriptionShort: "Bijdragen van leden",
    descriptionLong: "Bijdragen van leden",
  },
  {
    code: "WOvbSgbBvp",
    descriptionShort: "Baten van particulieren",
    descriptionLong: "Baten van particulieren",
  },
  {
    code: "WOvbSgbBvpBvp",
    descriptionShort: "Baten van particulieren",
    descriptionLong: "Baten van particulieren",
  },
  {
    code: "WOvbSgbBvb",
    descriptionShort: "Baten van bedrijfsleven",
    descriptionLong: "Baten van bedrijfsleven",
  },
  {
    code: "WOvbSgbBvbBvb",
    descriptionShort: "Baten van bedrijfsleven",
    descriptionLong: "Baten van bedrijfsleven",
  },
  {
    code: "WOvbSgbBlo",
    descriptionShort: "Baten van loterijorganisaties",
    descriptionLong: "Baten van loterijorganisaties",
  },
  {
    code: "WOvbSgbBloBlo",
    descriptionShort: "Baten van loterijorganisaties",
    descriptionLong: "Baten van loterijorganisaties",
  },
  {
    code: "WOvbSgbBso",
    descriptionShort: "Baten van subsidies van overheden",
    descriptionLong: "Baten van subsidies van overheden",
  },
  {
    code: "WOvbSgbBsoBso",
    descriptionShort: "Baten van subsidies van overheden",
    descriptionLong: "Baten van subsidies van overheden",
  },
  {
    code: "WOvbSgbBvo",
    descriptionShort: "Baten van verbonden organisaties zonder winststreven",
    descriptionLong: "Baten van verbonden organisaties zonder winststreven",
  },
  {
    code: "WOvbSgbBvoBvo",
    descriptionShort: "Baten van verbonden organisaties zonder winststreven",
    descriptionLong: "Baten van verbonden organisaties zonder winststreven",
  },
  {
    code: "WOvbSgbBao",
    descriptionShort: "Baten van andere organisaties zonder winststreven",
    descriptionLong: "Baten van andere organisaties zonder winststreven",
  },
  {
    code: "WOvbSgbBaoBao",
    descriptionShort: "Baten van andere organisaties zonder winststreven",
    descriptionLong: "Baten van andere organisaties zonder winststreven",
  },
  {
    code: "WOvbSgbAnb",
    descriptionShort: "Andere baten",
    descriptionLong: "Andere baten",
  },
  {
    code: "WOvbSgbAnbAnb",
    descriptionShort: "Andere baten",
    descriptionLong: "Andere baten",
  },
  {
    code: "WOvbEsu",
    descriptionShort: "Exploitatiesubsidies",
    descriptionLong: "Exploitatiesubsidies",
  },
  {
    code: "WOvbEsuEsu",
    descriptionShort: "Exploitatiesubsidies",
    descriptionLong: "Exploitatiesubsidies",
  },
  {
    code: "WOvbDob",
    descriptionShort: "Doorberekening overige bedrijfsopbrengsten",
    descriptionLong: "Doorberekening overige bedrijfsopbrengsten",
  },
  {
    code: "WOvbDobEvp",
    descriptionShort: "Doorberekening overige bedrijfsopbrengsten exploitatie",
    descriptionLong:
      "Doorberekening overige bedrijfsopbrengsten netto resultaat exploitatie vastgoedportefeuille",
  },
  {
    code: "WOvbDobVvo",
    descriptionShort:
      "Doorberekening overige bedrijfsopbrengsten in ontwikkeling",
    descriptionLong:
      "Doorberekening overige bedrijfsopbrengsten netto resultaat verkocht vastgoed in ontwikkeling",
  },
  {
    code: "WOvbDobGrv",
    descriptionShort: "Doorberekening overige bedrijfsopbrengsten verkoop",
    descriptionLong:
      "Doorberekening overige bedrijfsopbrengsten netto gerealiseerd resultaat verkoop vastgoedportefeuille",
  },
  {
    code: "WOvbDobWvp",
    descriptionShort:
      "Doorberekening overige bedrijfsopbrengsten waardeverandering",
    descriptionLong:
      "Doorberekening overige bedrijfsopbrengsten waardeveranderingen vastgoedportefeuille",
  },
  {
    code: "WOvbDobOac",
    descriptionShort:
      "Doorberekening overige bedrijfsopbrengsten overige activiteiten",
    descriptionLong:
      "Doorberekening overige bedrijfsopbrengsten netto resultaat overige activiteiten",
  },
  {
    code: "WOvbDobLbh",
    descriptionShort: "Doorberekening overige bedrijfsopbrengsten leefbaarheid",
    descriptionLong:
      "Doorberekening overige bedrijfsopbrengsten kosten leefbaarheid",
  },
  {
    code: "WPer",
    descriptionShort: "Lasten uit hoofde van personeelsbeloningen",
    descriptionLong: "Lasten uit hoofde van personeelsbeloningen",
  },
  {
    code: "WPerLes",
    descriptionShort: "Lonen en salarissen",
    descriptionLong: "Lonen en salarissen",
  },
  {
    code: "WPerLesSld",
    descriptionShort: "Bezoldiging van bestuurders en gewezen bestuurders",
    descriptionLong:
      "Bezoldiging van bestuurders en gewezen bestuurders lonen en salarissen",
  },
  {
    code: "WPerLesSldPbb",
    descriptionShort: "Periodiek betaalde beloning van een bestuurder",
    descriptionLong:
      "Periodiek betaalde beloning van een bestuurder lonen en salarissen",
  },
  {
    code: "WPerLesSldBtb",
    descriptionShort: "Beloningen betaalbaar op termijn van een bestuurder",
    descriptionLong:
      "Beloningen betaalbaar op termijn van een bestuurder lonen en salarissen",
  },
  {
    code: "WPerLesSldUbb",
    descriptionShort:
      "Uitkeringen bij beëindiging van het dienstverband van een bestuurder",
    descriptionLong:
      "Uitkeringen bij beëindiging van het dienstverband van een bestuurder lonen en salarissen",
  },
  {
    code: "WPerLesSldWbb",
    descriptionShort: "Winstdelingen en bonusbetalingen van een bestuurder",
    descriptionLong:
      "Winstdelingen en bonusbetalingen van een bestuurder lonen en salarissen",
  },
  {
    code: "WPerLesBvc",
    descriptionShort:
      "Bezoldiging van commissarissen en gewezen commissarissen",
    descriptionLong:
      "Bezoldiging van commissarissen en gewezen commissarissen lonen en salarissen",
  },
  {
    code: "WPerLesBvcPbc",
    descriptionShort: "Periodiek betaalde beloning van een commissaris",
    descriptionLong:
      "Periodiek betaalde beloning van een commissaris lonen en salarissen",
  },
  {
    code: "WPerLesBvcBtc",
    descriptionShort: "Beloningen betaalbaar op termijn van een commissaris",
    descriptionLong:
      "Beloningen betaalbaar op termijn van een commissaris lonen en salarissen",
  },
  {
    code: "WPerLesBvcUbc",
    descriptionShort:
      "Uitkeringen bij beëindiging van het dienstverband van een commissaris",
    descriptionLong:
      "Uitkeringen bij beëindiging van het dienstverband van een commissaris lonen en salarissen",
  },
  {
    code: "WPerLesBvcWbc",
    descriptionShort: "Winstdelingen en bonusbetalingen van een commissaris",
    descriptionLong:
      "Winstdelingen en bonusbetalingen van een commissaris lonen en salarissen",
  },
  {
    code: "WPerLesBvcKit",
    descriptionShort: "Kosten intern toezicht",
    descriptionLong: "Kosten intern toezicht",
  },
  {
    code: "WPerLesTep",
    descriptionShort: "Tantièmes en provisie",
    descriptionLong: "Tantièmes en provisie lonen en salarissen",
  },
  {
    code: "WPerLesLon",
    descriptionShort: "Lonen",
    descriptionLong: "Lonen en salarissen",
  },
  {
    code: "WPerLesLonLon",
    descriptionShort: "Lonen",
    descriptionLong: "Lonen en salarissen",
  },
  {
    code: "WPerLesLonVvg",
    descriptionShort: "Lonen vervanging",
    descriptionLong: "Lonen vervanging",
  },
  {
    code: "WPerLesLonCor",
    descriptionShort: "Lonen salariscorrectie",
    descriptionLong: "Lonen salariscorrectie",
  },
  {
    code: "WPerLesOwe",
    descriptionShort: "Overwerk",
    descriptionLong: "Overwerk lonen en salarissen",
  },
  {
    code: "WPerLesOnr",
    descriptionShort: "Onregelmatigheidstoeslag",
    descriptionLong: "Onregelmatigheidstoeslag lonen en salarissen",
  },
  {
    code: "WPerLesVag",
    descriptionShort: "Vakantiebijslag",
    descriptionLong: "Vakantiebijslag lonen en salarissen",
  },
  {
    code: "WPerLesVagVag",
    descriptionShort: "Vakantiebijslag",
    descriptionLong: "Vakantiebijslag lonen en salarissen",
  },
  {
    code: "WPerLesVagVld",
    descriptionShort: "Vakantiebijslag uitbetaling verlofdagen",
    descriptionLong: "Vakantiebijslag uitbetaling verlofdagen",
  },
  {
    code: "WPerLesVagVlu",
    descriptionShort: "Vakantiebijslag restant verlofuren",
    descriptionLong: "Vakantiebijslag restant verlofuren",
  },
  {
    code: "WPerLesVad",
    descriptionShort: "Vakantiedagen",
    descriptionLong: "Vakantiedagen lonen en salarissen",
  },
  {
    code: "WPerLesGra",
    descriptionShort: "Gratificaties",
    descriptionLong: "Gratificaties lonen en salarissen",
  },
  {
    code: "WPerLesGraGra",
    descriptionShort: "Gratificaties",
    descriptionLong: "Gratificaties lonen en salarissen",
  },
  {
    code: "WPerLesGraJuk",
    descriptionShort: "Jubileumuitkering",
    descriptionLong: "Jubileumuitkering",
  },
  {
    code: "WPerLesLin",
    descriptionShort: "Lonen in natura",
    descriptionLong: "Lonen in natura lonen en salarissen",
  },
  {
    code: "WPerLesTls",
    descriptionShort: "Spaarloon",
    descriptionLong: "Spaarloon lonen en salarissen",
  },
  {
    code: "WPerLesOnu",
    descriptionShort: "Ontslagvergoedingen",
    descriptionLong: "Ontslaguitkeringen lonen en salarissen",
  },
  {
    code: "WPerLesLiv",
    descriptionShort: "Lasten in verband met op aandelen gebaseerde betalingen",
    descriptionLong:
      "Op aandelen gebaseerde betalingen opgenomen onder lonen en salarissen",
  },
  {
    code: "WPerLesLoo",
    descriptionShort: "Loonkostenreductie",
    descriptionLong: "Loonkostenreductie lonen en salarissen",
  },
  {
    code: "WPerLesOvt",
    descriptionShort: "Overige toeslagen",
    descriptionLong: "Overige toeslagen lonen en salarissen",
  },
  {
    code: "WPerLesOlr",
    descriptionShort: "Overige lonen en salarissen",
    descriptionLong: "Overige lonen en salarissen lonen en salarissen",
  },
  {
    code: "WPerLesOlrOlr",
    descriptionShort: "Overige lonen en salarissen",
    descriptionLong: "Overige lonen en salarissen lonen en salarissen",
  },
  {
    code: "WPerLesOlrWpt",
    descriptionShort: "Overige lonen en salarissen wachtgeld/piket",
    descriptionLong: "Overige lonen en salarissen wachtgeld/piket",
  },
  {
    code: "WPerLesOlrSvg",
    descriptionShort: "Overige lonen en salarissen stagevergoeding",
    descriptionLong: "Overige lonen en salarissen stagevergoeding",
  },
  {
    code: "WPerLesOlrRvc",
    descriptionShort: "Overige lonen en salarissen kosten RVC niet salaris",
    descriptionLong: "Overige lonen en salarissen kosten RVC niet salaris",
  },
  {
    code: "WPerLesOlrNtb",
    descriptionShort: "Overige lonen en salarissen nb/bt",
    descriptionLong: "Overige lonen en salarissen nb/bt",
  },
  {
    code: "WPerLesLks",
    descriptionShort: "Loonkostensubsidie (LIV)",
    descriptionLong: "Loonkostensubsidie (LIV) lonen en salarissen",
  },
  {
    code: "WPerLesOls",
    descriptionShort: "Overige loon(kosten)subsidies",
    descriptionLong: "Overige loon(kosten)subsidies",
  },
  {
    code: "WPerLesEuk",
    descriptionShort: "Eindejaarsuitkering",
    descriptionLong: "Eindejaarsuitkering",
  },
  {
    code: "WPerLesDle",
    descriptionShort: "Doorberekende Lonen en salarissen",
    descriptionLong: "Doorberekende Lonen en salarissen lonen en salarissen",
  },
  {
    code: "WPerLesDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling lonen en salarissen",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling lonen en salarissen",
  },
  {
    code: "WPerSol",
    descriptionShort: "Sociale lasten",
    descriptionLong: "Sociale lasten",
  },
  {
    code: "WPerSolPsv",
    descriptionShort: "Premies sociale verzekeringen",
    descriptionLong: "Premies sociale verzekeringen sociale lasten",
  },
  {
    code: "WPerSolBiz",
    descriptionShort: "Bijdrage ziektekostenverzekering",
    descriptionLong: "Bijdrage ziektekostenverzekering sociale lasten",
  },
  {
    code: "WPerSolOpr",
    descriptionShort: "Overige premies",
    descriptionLong: "Overige premies sociale lasten",
  },
  {
    code: "WPerSolOsf",
    descriptionShort: "Overige sociale fondsen",
    descriptionLong: "Overige sociale fondsen sociale lasten",
  },
  {
    code: "WPerSolOss",
    descriptionShort: "Overige sociale lasten",
    descriptionLong: "Overige sociale lasten sociale lasten",
  },
  {
    code: "WPerSolOssOss",
    descriptionShort: "Overige sociale lasten",
    descriptionLong: "Overige sociale lasten sociale lasten",
  },
  {
    code: "WPerSolOssLeh",
    descriptionShort: "Overige sociale lasten loonheffing eindheffing",
    descriptionLong: "Overige sociale lasten loonheffing eindheffing",
  },
  {
    code: "WPerSolOssPkg",
    descriptionShort: "Overige sociale lasten premiekortingen",
    descriptionLong: "Overige sociale lasten premiekortingen",
  },
  {
    code: "WPerSolOssRvl",
    descriptionShort: "Overige sociale lasten restant verlofuren - verlofdagen",
    descriptionLong: "Overige sociale lasten restant verlofuren - verlofdagen",
  },
  {
    code: "WPerSolOssRvg",
    descriptionShort: "Overige sociale lasten restant vakantiegeld",
    descriptionLong: "Overige sociale lasten restant vakantiegeld",
  },
  {
    code: "WPerSolOssRvd",
    descriptionShort: "Overige sociale lasten restant vakantiedagen",
    descriptionLong: "Overige sociale lasten restant vakantiedagen",
  },
  {
    code: "WPerSolOssErd",
    descriptionShort: "Eigen risicodragerschap",
    descriptionLong: "Eigen risicodragerschap",
  },
  {
    code: "WPerSolDsl",
    descriptionShort: "Doorberekende sociale lasten",
    descriptionLong: "Doorberekende sociale lasten sociale lasten",
  },
  {
    code: "WPerSolDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling sociale lasten",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling sociale lasten",
  },
  {
    code: "WPerPen",
    descriptionShort: "Pensioenlasten",
    descriptionLong: "Pensioenlasten",
  },
  {
    code: "WPerPenPen",
    descriptionShort: "Pensioenpremies",
    descriptionLong: "Pensioenpremies pensioenlasten",
  },
  {
    code: "WPerPenPenPen",
    descriptionShort: "Pensioenpremies",
    descriptionLong: "Pensioenpremies",
  },
  {
    code: "WPerPenPenPpe",
    descriptionShort: "Pensioenpremies PP",
    descriptionLong: "Pensioenpremies PP",
  },
  {
    code: "WPerPenPenOvp",
    descriptionShort: "Pensioenpremies OVP",
    descriptionLong: "Pensioenpremies OVP",
  },
  {
    code: "WPerPenAap",
    descriptionShort: "Aanvullende pensioenlasten",
    descriptionLong: "Aanvullende pensioenlasten pensioenlasten",
  },
  {
    code: "WPerPenAapAap",
    descriptionShort: "Aanvullende pensioenlasten",
    descriptionLong: "Aanvullende pensioenlasten",
  },
  {
    code: "WPerPenAapWex",
    descriptionShort: "Aanvullende pensioenlasten WIA excedent",
    descriptionLong: "Aanvullende pensioenlasten WIA excedent",
  },
  {
    code: "WPerPenAapWpp",
    descriptionShort: "Aanvullende pensioenlasten WIA PP",
    descriptionLong: "Aanvullende pensioenlasten WIA PP",
  },
  {
    code: "WPerPenDpe",
    descriptionShort: "Dotatie pensioenvoorziening directie",
    descriptionLong: "Dotatie pensioenvoorziening directie",
  },
  {
    code: "WPerPenVpv",
    descriptionShort: "Vrijval pensioenvoorziening directie",
    descriptionLong: "Vrijval pensioenvoorziening directie",
  },
  {
    code: "WPerPenDvb",
    descriptionShort: "Dotatie voorziening backserviceverplichting directie",
    descriptionLong: "Dotatie voorziening backserviceverplichting directie",
  },
  {
    code: "WPerPenVvb",
    descriptionShort: "Vrijval voorziening backserviceverplichting directie",
    descriptionLong: "Vrijval voorziening backserviceverplichting directie",
  },
  {
    code: "WPerPenDvl",
    descriptionShort: "Dotatie voorziening lijfrenteverplichtingen",
    descriptionLong: "Dotatie voorziening lijfrenteverplichtingen",
  },
  {
    code: "WPerPenVvl",
    descriptionShort: "Vrijval voorziening lijfrenteverplichtingen",
    descriptionLong: "Vrijval voorziening lijfrenteverplichtingen",
  },
  {
    code: "WPerPenOpe",
    descriptionShort: "Overige pensioenlasten",
    descriptionLong: "Overige pensioenlasten",
  },
  {
    code: "WPerPenOpeOpe",
    descriptionShort: "Overige pensioenlasten",
    descriptionLong: "Overige pensioenlasten",
  },
  {
    code: "WPerPenOpeFlw",
    descriptionShort: "Overige pensioenlasten FLOW",
    descriptionLong: "Overige pensioenlasten FLOW",
  },
  {
    code: "WPerPenDon",
    descriptionShort: "Doorberekende pensioenlasten",
    descriptionLong: "Doorberekende pensioenlasten",
  },
  {
    code: "WPerPenDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling pensioenlasten",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling pensioenlasten",
  },
  {
    code: "WPerOlu",
    descriptionShort: "Overige lasten uit hoofde van personeelsbeloningen",
    descriptionLong: "Overige lasten uit hoofde van personeelsbeloningen",
  },
  {
    code: "WPerOluOlp",
    descriptionShort: "Overige lasten met betrekking tot personeelsbeloningen",
    descriptionLong:
      "Overige lasten met betrekking tot personeelsbeloningen overige lasten uit hoofde van personeelsbeloningen",
  },
  {
    code: "WPerOluDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling overige lasten personeel",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling overige lasten personeel",
  },
  {
    code: "WAfs",
    descriptionShort: "Afschrijvingen op immateriële en materiële vaste activa",
    descriptionLong: "Afschrijvingen op immateriële en materiële vaste activa",
  },
  {
    code: "WAfsAiv",
    descriptionShort: "Afschrijvingen op immateriële vaste activa",
    descriptionLong: "Afschrijvingen op immateriële vaste activa",
  },
  {
    code: "WAfsAivOek",
    descriptionShort:
      "Afschrijvingen kosten van oprichting en van uitgifte van aandelen",
    descriptionLong:
      "Afschrijvingen kosten van oprichting en van uitgifte van aandelen afschrijvingen op immateriële vaste activa",
  },
  {
    code: "WAfsAivKoe",
    descriptionShort: "Afschrijvingen kosten van ontwikkeling",
    descriptionLong:
      "Afschrijvingen kosten van ontwikkeling afschrijvingen op immateriële vaste activa",
  },
  {
    code: "WAfsAivCev",
    descriptionShort:
      "Afschrijvingen concessies, vergunningen en intellectuele eigendom",
    descriptionLong:
      "Afschrijvingen concessies, vergunningen en intellectuele eigendom afschrijvingen op immateriële vaste activa",
  },
  {
    code: "WAfsAivGoo",
    descriptionShort: "Afschrijvingen goodwill - fiscaal aftrekbaar",
    descriptionLong:
      "Afschrijvingen goodwill - fiscaal aftrekbaar - afschrijvingen op immateriële vaste activa",
  },
  {
    code: "WAfsAivGon",
    descriptionShort: "Afschrijvingen goodwill - fiscaal niet aftrekbaar",
    descriptionLong:
      "Afschrijvingen goodwill - fiscaal niet aftrekbaar - afschrijvingen op immateriële vaste activa",
  },
  {
    code: "WAfsAivViv",
    descriptionShort:
      "Afschrijvingen vooruitbetalingen op immateriële vaste activa",
    descriptionLong:
      "Afschrijvingen vooruitbetalingen op immateriële vaste activa afschrijvingen op immateriële vaste activa",
  },
  {
    code: "WAfsAivOiv",
    descriptionShort: "Afschrijvingen overige immateriële vaste activa",
    descriptionLong:
      "Afschrijvingen overige immateriële vaste activa afschrijvingen op immateriële vaste activa",
  },
  {
    code: "WAfsAivBou",
    descriptionShort: "Afschrijvingen bouwclaims",
    descriptionLong: "Afschrijvingen bouwclaims",
  },
  {
    code: "WAfsAivDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling afschrijvingen op immateriële vaste activa",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling afschrijvingen op immateriële vaste activa",
  },
  {
    code: "WAfsAmv",
    descriptionShort: "Afschrijvingen op materiële vaste activa",
    descriptionLong: "Afschrijvingen op materiële vaste activa",
  },
  {
    code: "WAfsAmvAft",
    descriptionShort: "Afschrijvingen Terreinen",
    descriptionLong: "Afschrijvingen Terreinen ",
  },
  {
    code: "WAfsAmvBeg",
    descriptionShort: "Afschrijvingen Bedrijfsgebouwen",
    descriptionLong: "Afschrijvingen Bedrijfsgebouwen ",
  },
  {
    code: "WAfsAmvHuu",
    descriptionShort: "Afschrijvingen Huurdersinvesteringen",
    descriptionLong: "Afschrijvingen Huurdersinvesteringen ",
  },
  {
    code: "WAfsAmvVeb",
    descriptionShort: "Afschrijvingen Verbouwingen",
    descriptionLong: "Afschrijvingen Verbouwingen ",
  },
  {
    code: "WAfsAmvMei",
    descriptionShort: "Afschrijvingen Machines en installaties",
    descriptionLong: "Afschrijvingen Machines en installaties ",
  },
  {
    code: "WAfsAmvObe",
    descriptionShort: "Afschrijvingen Andere vaste bedrijfsmiddelen",
    descriptionLong: "Afschrijvingen Andere vaste bedrijfsmiddelen ",
  },
  {
    code: "WAfsAmvSev",
    descriptionShort: "Afschrijvingen Vliegtuigen",
    descriptionLong: "Afschrijvingen Vliegtuigen ",
  },
  {
    code: "WAfsAmvAfs",
    descriptionShort: "Afschrijvingen Schepen",
    descriptionLong: "Afschrijvingen Schepen ",
  },
  {
    code: "WAfsAmvTev",
    descriptionShort:
      "Afschrijvingen Automobielen en overige transportmiddelen",
    descriptionLong:
      "Afschrijvingen Automobielen en overige transportmiddelen ",
  },
  {
    code: "WAfsAmvBei",
    descriptionShort: "Afschrijvingen Inventaris",
    descriptionLong: "Afschrijvingen Inventaris ",
  },
  {
    code: "WAfsAmvAmp",
    descriptionShort: "Afschrijvingen Meerjaren plantopstanden",
    descriptionLong: "Afschrijvingen Meerjaren plantopstanden ",
  },
  {
    code: "WAfsAmvAfg",
    descriptionShort: "Afschrijvingen Gebruiksvee",
    descriptionLong: "Afschrijvingen Gebruiksvee ",
  },
  {
    code: "WAfsAmvVbi",
    descriptionShort: "Afschrijvingen Vaste bedrijfsmiddelen in uitvoering",
    descriptionLong: "Afschrijvingen Vaste bedrijfsmiddelen in uitvoering ",
  },
  {
    code: "WAfsAmvAvm",
    descriptionShort:
      "Afschrijvingen Vooruitbetalingen op materiële vaste activa",
    descriptionLong:
      "Afschrijvingen Vooruitbetalingen op materiële vaste activa ",
  },
  {
    code: "WAfsAmvBgm",
    descriptionShort:
      "Afschrijvingen Niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
    descriptionLong:
      "Afschrijvingen Niet aan de bedrijfsuitoefening dienstbare materiële vaste activa ",
  },
  {
    code: "WAfsAmvOrz",
    descriptionShort:
      "Afschrijvingen Onroerende en roerende zaken ten dienste van de exploitatie",
    descriptionLong:
      "Afschrijvingen Onroerende en roerende zaken ten dienste van de exploitatie",
  },
  {
    code: "WAfsAmvDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling afschrijvingen op materiële vaste activa",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling afschrijvingen op materiële vaste activa",
  },
  {
    code: "WAfsAfv",
    descriptionShort: "Afschrijvingen vastgoed",
    descriptionLong: "Afschrijvingen vastgoed",
  },
  {
    code: "WAfsAfvAvo",
    descriptionShort: "Afschrijvingen vastgoedbeleggingen in ontwikkeling",
    descriptionLong:
      "Afschrijvingen vastgoedbeleggingen in ontwikkeling afschrijvingen vastgoed",
  },
  {
    code: "WAfsAfvAve",
    descriptionShort: "Afschrijvingen vastgoedbeleggingen in exploitatie",
    descriptionLong:
      "Afschrijvingen vastgoedbeleggingen in exploitatie afschrijvingen vastgoed",
  },
  {
    code: "WAfsAfvDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling afschrijvingen vastgoed",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling afschrijvingen vastgoed",
  },
  {
    code: "WAfsRvi",
    descriptionShort: "Boekresultaat op immateriële vaste activa",
    descriptionLong:
      "Winsten of verliezen die ontstaan als gevolg van de buitengebruikstelling of afstoting van een immaterieel vast actief",
  },
  {
    code: "WAfsRviOek",
    descriptionShort:
      "Boekresultaat kosten van oprichting en van uitgifte van aandelen",
    descriptionLong:
      "Boekresultaat kosten van oprichting en van uitgifte van aandelen boekresultaat op immateriële vaste activa",
  },
  {
    code: "WAfsRviKoe",
    descriptionShort: "Boekresultaat kosten van ontwikkeling",
    descriptionLong:
      "Boekresultaat kosten van ontwikkeling boekresultaat op immateriële vaste activa",
  },
  {
    code: "WAfsRviCev",
    descriptionShort:
      "Boekresultaat concessies, vergunningen en intellectuele eigendom",
    descriptionLong:
      "Boekresultaat concessies, vergunningen en intellectuele eigendom boekresultaat op immateriële vaste activa",
  },
  {
    code: "WAfsRviGoo",
    descriptionShort: "Boekresultaat goodwill",
    descriptionLong:
      "Boekresultaat goodwill boekresultaat op immateriële vaste activa",
  },
  {
    code: "WAfsRviViv",
    descriptionShort:
      "Boekresultaat vooruitbetalingen op immateriële vaste activa",
    descriptionLong:
      "Boekresultaat vooruitbetalingen op immateriële vaste activa boekresultaat op immateriële vaste activa",
  },
  {
    code: "WAfsRviOiv",
    descriptionShort: "Boekresultaat overige immateriële vaste activa",
    descriptionLong:
      "Boekresultaat overige immateriële vaste activa boekresultaat op immateriële vaste activa",
  },
  {
    code: "WAfsRviBou",
    descriptionShort: "Boekresultaat bouwclaims",
    descriptionLong:
      "Boekresultaat bouwclaims boekresultaat op immateriële vaste activa",
  },
  {
    code: "WAfsRviDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling boekresultaat immateriële vaste activa",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling boekresultaat immateriële vaste activa",
  },
  {
    code: "WAfsRvm",
    descriptionShort: "Boekresultaat op materiële vaste activa",
    descriptionLong:
      "Winsten of verliezen die ontstaan als gevolg van de buitengebruikstelling of afstoting van een materieel vast actief",
  },
  {
    code: "WAfsRvmBrt",
    descriptionShort: "Boekresultaat Terreinen",
    descriptionLong:
      "Boekresultaat Terreinen boekresultaat op materiële vaste activa",
  },
  {
    code: "WAfsRvmBeg",
    descriptionShort: "Boekresultaat Bedrijfsgebouwen",
    descriptionLong:
      "Boekresultaat Bedrijfsgebouwen boekresultaat op materiële vaste activa",
  },
  {
    code: "WAfsRvmHuu",
    descriptionShort: "Boekresultaat Huurdersinvesteringen",
    descriptionLong:
      "Boekresultaat Huurdersinvesteringen boekresultaat op materiële vaste activa",
  },
  {
    code: "WAfsRvmVeb",
    descriptionShort: "Boekresultaat Verbouwingen",
    descriptionLong:
      "Boekresultaat Verbouwingen boekresultaat op materiële vaste activa",
  },
  {
    code: "WAfsRvmMei",
    descriptionShort: "Boekresultaat Machines en installaties",
    descriptionLong:
      "Boekresultaat Machines en installaties boekresultaat op materiële vaste activa",
  },
  {
    code: "WAfsRvmObe",
    descriptionShort: "Boekresultaat Andere vaste bedrijfsmiddelen",
    descriptionLong:
      "Boekresultaat Andere vaste bedrijfsmiddelen boekresultaat op materiële vaste activa",
  },
  {
    code: "WAfsRvmSev",
    descriptionShort: "Boekresultaat Vliegtuigen",
    descriptionLong:
      "Boekresultaat Vliegtuigen boekresultaat op materiële vaste activa",
  },
  {
    code: "WAfsRvmSch",
    descriptionShort: "Boekresultaat Schepen",
    descriptionLong:
      "Boekresultaat Schepen boekresultaat op materiële vaste activa",
  },
  {
    code: "WAfsRvmTev",
    descriptionShort: "Boekresultaat Automobielen en overige transportmiddelen",
    descriptionLong:
      "Boekresultaat Automobielen en overige transportmiddelen boekresultaat op materiële vaste activa",
  },
  {
    code: "WAfsRvmBei",
    descriptionShort: "Boekresultaat Inventaris",
    descriptionLong:
      "Boekresultaat Inventaris boekresultaat op materiële vaste activa",
  },
  {
    code: "WAfsRvmBmp",
    descriptionShort: "Boekresultaat Meerjaren plantopstanden",
    descriptionLong:
      "Boekresultaat Meerjaren plantopstanden boekresultaat op materiële vaste activa",
  },
  {
    code: "WAfsRvmBgv",
    descriptionShort: "Boekresultaat Gebruiksvee",
    descriptionLong:
      "Boekresultaat Gebruiksvee boekresultaat op materiële vaste activa",
  },
  {
    code: "WAfsRvmVbi",
    descriptionShort: "Boekresultaat Vaste bedrijfsmiddelen in uitvoering",
    descriptionLong:
      "Boekresultaat Vaste bedrijfsmiddelen in uitvoering boekresultaat op materiële vaste activa",
  },
  {
    code: "WAfsRvmBvm",
    descriptionShort:
      "Boekresultaat Vooruitbetalingen op materiële vaste activa",
    descriptionLong:
      "Boekresultaat Vooruitbetalingen op materiële vaste activa boekresultaat op materiële vaste activa",
  },
  {
    code: "WAfsRvmBgm",
    descriptionShort:
      "Boekresultaat Niet aan de bedrijfsuitoefening dienstbare materiële vaste activa",
    descriptionLong:
      "Boekresultaat Niet aan de bedrijfsuitoefening dienstbare materiële vaste activa boekresultaat op materiële vaste activa",
  },
  {
    code: "WAfsRvmOrz",
    descriptionShort:
      "Boekresultaat Onroerende en roerende zaken ten dienste van de exploitatie",
    descriptionLong:
      "Boekresultaat Onroerende en roerende zaken ten dienste van de exploitatie",
  },
  {
    code: "WAfsRvmDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling boekresultaat materiële vaste activa",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling boekresultaat materiële vaste activa",
  },
  {
    code: "WAfsBov",
    descriptionShort: "Boekresultaat vastgoed",
    descriptionLong: "Boekresultaat vastgoed",
  },
  {
    code: "WAfsBovBvo",
    descriptionShort: "Boekresultaat vastgoedbeleggingen in ontwikkeling",
    descriptionLong:
      "Boekresultaat vastgoedbeleggingen in ontwikkeling boekresultaat vastgoed",
  },
  {
    code: "WAfsBovBve",
    descriptionShort: "Boekresultaat vastgoedbeleggingen in exploitatie",
    descriptionLong:
      "Boekresultaat vastgoedbeleggingen in exploitatie boekresultaat vastgoed",
  },
  {
    code: "WAfsBovDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling boekresultaat vastgoed",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling boekresultaat vastgoed",
  },
  {
    code: "WAfsDae",
    descriptionShort: "Doorberekende afschrijvingen en waardeveranderingen",
    descriptionLong: "Doorberekende afschrijvingen en waardeveranderingen",
  },
  {
    code: "WAfsDaeDaf",
    descriptionShort: "Doorberekende afschrijvingen",
    descriptionLong:
      "Doorberekende afschrijvingen doorberekende afschrijvingen en waardeveranderingen",
  },
  {
    code: "WAfsDaeDafDai",
    descriptionShort: "Doorberekende afschrijvingen immateriële vaste activa",
    descriptionLong:
      "Doorberekende afschrijvingen immateriële vaste activa doorberekende afschrijvingen en waardeveranderingen",
  },
  {
    code: "WAfsDaeDafDam",
    descriptionShort: "Doorberekende afschrijvingen materiële vaste activa",
    descriptionLong:
      "Doorberekende afschrijvingen materiële vaste activa doorberekende afschrijvingen en waardeveranderingen",
  },
  {
    code: "WAfsDaeDow",
    descriptionShort: "Doorberekende waardeveranderingen",
    descriptionLong:
      "Doorberekende waardeveranderingen doorberekende afschrijvingen en waardeveranderingen",
  },
  {
    code: "WAfsDaeDowDwi",
    descriptionShort:
      "Doorberekende waardeveranderingen immateriële vaste activa",
    descriptionLong:
      "Doorberekende waardeveranderingen immateriële vaste activa doorberekende afschrijvingen en waardeveranderingen",
  },
  {
    code: "WAfsDaeDowDwm",
    descriptionShort:
      "Doorberekende waardeveranderingen materiële vaste activa",
    descriptionLong:
      "Doorberekende waardeveranderingen materiële vaste activa doorberekende afschrijvingen en waardeveranderingen",
  },
  {
    code: "WAfsDaeDve",
    descriptionShort: "Doorberekende verkoopresultaten",
    descriptionLong:
      "Doorberekende verkoopresultaten doorberekende afschrijvingen en waardeveranderingen",
  },
  {
    code: "WAfsDaeDveDvi",
    descriptionShort:
      "Doorberekende verkoopresultaten immateriële vaste activa",
    descriptionLong:
      "Doorberekende verkoopresultaten immateriële vaste activa doorberekende afschrijvingen en waardeveranderingen",
  },
  {
    code: "WAfsDaeDveDvm",
    descriptionShort: "Doorberekende verkoopresultaten materiële vaste activa",
    descriptionLong:
      "Doorberekende verkoopresultaten materiële vaste activa doorberekende afschrijvingen en waardeveranderingen",
  },
  {
    code: "WAfsDaeDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling afschrijvingen en waardeveranderingen",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling afschrijvingen en waardeveranderingen",
  },
  {
    code: "WWvi",
    descriptionShort:
      "Waardeveranderingen van immateriële en materiële vaste activa en vastgoedbeleggingen",
    descriptionLong:
      "Waardeveranderingen van immateriële en materiële vaste activa en vastgoedbeleggingen",
  },
  {
    code: "WWviWvi",
    descriptionShort: "Waardeveranderingen van immateriële vaste activa",
    descriptionLong: "Waardeveranderingen van immateriële vaste activa",
  },
  {
    code: "WWviWviBwi",
    descriptionShort:
      "Bijzondere waardeverminderingen van immateriële vaste activa",
    descriptionLong:
      "Bijzondere waardeverminderingen van immateriële vaste activa waardeveranderingen van immateriële vaste activa",
  },
  {
    code: "WWviWviTbi",
    descriptionShort:
      "Terugneming van bijzondere waardeverminderingen van immateriële vaste activa",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen van immateriële vaste activa waardeveranderingen van immateriële vaste activa",
  },
  {
    code: "WWviWviDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling waardeveranderingen immateriële vaste activa",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling waardeveranderingen immateriële vaste activa",
  },
  {
    code: "WWviWvm",
    descriptionShort: "Waardeveranderingen van materiële vaste activa",
    descriptionLong: "Waardeveranderingen van materiële vaste activa",
  },
  {
    code: "WWviWvmBwm",
    descriptionShort:
      "Bijzondere waardeverminderingen van materiële vaste activa",
    descriptionLong:
      "Bijzondere waardeverminderingen van materiële vaste activa waardeveranderingen van materiële vaste activa",
  },
  {
    code: "WWviWvmTbm",
    descriptionShort:
      "Terugneming van bijzondere waardeverminderingen van materiële vaste activa",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen van materiële vaste activa waardeveranderingen van materiële vaste activa",
  },
  {
    code: "WWviWvmDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling waardeveranderingen materiële vaste activa",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling waardeveranderingen materiële vaste activa",
  },
  {
    code: "WWviWvb",
    descriptionShort: "Wijziging in de reële waarde van vastgoedbeleggingen",
    descriptionLong: "Wijziging in de reële waarde van vastgoedbeleggingen",
  },
  {
    code: "WWviWvbBwv",
    descriptionShort: "Bijzondere waardeverminderingen van vastgoedbeleggingen",
    descriptionLong:
      "Bijzondere waardeverminderingen van vastgoedbeleggingen waardeveranderingen van vastgoedbeleggingen",
  },
  {
    code: "WWviWvbBwvVie",
    descriptionShort:
      "Bijzondere waardeverminderingen van vastgoedbeleggingen in exploitatie",
    descriptionLong:
      "Bijzondere waardeverminderingen van vastgoedbeleggingen in exploitatie",
  },
  {
    code: "WWviWvbBwvVio",
    descriptionShort:
      "Bijzondere waardeverminderingen van vastgoedbeleggingen in ontwikkeling",
    descriptionLong:
      "Bijzondere waardeverminderingen van vastgoedbeleggingen in ontwikkeling",
  },
  {
    code: "WWviWvbTbw",
    descriptionShort:
      "Terugneming van bijzondere waardeverminderingen van vastgoedbeleggingen",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen van vastgoedbeleggingen waardeveranderingen van vastgoedbeleggingen",
  },
  {
    code: "WWviWvbTbwVie",
    descriptionShort:
      "Terugneming van bijzondere waardeverminderingen van vastgoedbeleggingen in exploitatie",
    descriptionLong:
      "Bijzondere waardeverminderingen van vastgoedbeleggingen in exploitatie",
  },
  {
    code: "WWviWvbTbwVio",
    descriptionShort:
      "Terugneming van bijzondere waardeverminderingen van vastgoedbeleggingen in ontwikkeling",
    descriptionLong:
      "Bijzondere waardeverminderingen van vastgoedbeleggingen in ontwikkeling",
  },
  {
    code: "WWviWvbDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling wijziging in de reële waarde van vastgoedbeleggingen",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling wijziging in de reële waarde van vastgoedbeleggingen",
  },
  {
    code: "WWviWvbDfwVie",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling wijziging in de reële waarde van vastgoedbeleggingen in exploitatie",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling wijziging in de reële waarde van vastgoedbeleggingen in exploitatie",
  },
  {
    code: "WWviWvbDfwVio",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling wijziging in de reële waarde van vastgoedbeleggingen in ontwikkeling",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling wijziging in de reële waarde van vastgoedbeleggingen in ontwikkeling",
  },
  {
    code: "WBwv",
    descriptionShort: "Overige waardeveranderingen",
    descriptionLong: "Overige waardeveranderingen",
  },
  {
    code: "WBwvObw",
    descriptionShort: "Bijzondere waardeverminderingen van vlottende activa",
    descriptionLong: "Bijzondere waardeverminderingen van vlottende activa",
  },
  {
    code: "WBwvObwBwv",
    descriptionShort: "Bijzondere waardeverminderingen van vlottende activa",
    descriptionLong: "Bijzondere waardeverminderingen van vlottende activa",
  },
  {
    code: "WBwvObwBwvBwk",
    descriptionShort:
      "Bijzondere waardevermindering vorderingen (korte termijn)",
    descriptionLong:
      "Bijzondere waardevermindering vorderingen (korte termijn) overige bijzondere waardeverminderingen",
  },
  {
    code: "WBwvObwBwvBwe",
    descriptionShort: "Bijzondere waardevermindering effecten (korte termijn)",
    descriptionLong:
      "Bijzondere waardevermindering effecten (korte termijn) overige bijzondere waardeverminderingen",
  },
  {
    code: "WBwvObwBwvBwo",
    descriptionShort: "Bijzondere waardevermindering overige vlottende activa",
    descriptionLong:
      "Bijzondere waardevermindering overige vlottende activa overige bijzondere waardeverminderingen",
  },
  {
    code: "WBwvObwBwvLim",
    descriptionShort: "Liquide middelen",
    descriptionLong: "Liquide middelen",
  },
  {
    code: "WBwvObwDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling wijziging in de reële waarde van vastgoedbeleggingen",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling wijziging in de reële waarde van vastgoedbeleggingen",
  },
  {
    code: "WBwvGwb",
    descriptionShort: "Gerealiseerde waardeveranderingen van beleggingen",
    descriptionLong: "Gerealiseerde waardeveranderingen van beleggingen",
  },
  {
    code: "WBwvGwbGwb",
    descriptionShort: "Gerealiseerde waardeveranderingen van beleggingen",
    descriptionLong: "Gerealiseerde waardeveranderingen van beleggingen",
  },
  {
    code: "WBwvGwbGwbGwg",
    descriptionShort:
      "Gerealiseerde waardeveranderingen van beleggingen in groepsmaatschappijen",
    descriptionLong:
      "Gerealiseerde waardeveranderingen van beleggingen in groepsmaatschappijen",
  },
  {
    code: "WBwvGwbGwbGwd",
    descriptionShort:
      "Gerealiseerde waardeveranderingen van beleggingen in andere deelnemingen",
    descriptionLong:
      "Gerealiseerde waardeveranderingen van beleggingen in andere deelnemingen",
  },
  {
    code: "WBwvGwbGwbGwt",
    descriptionShort:
      "Gerealiseerde waardeveranderingen van beleggingen in terreinen en gebouwen",
    descriptionLong:
      "Gerealiseerde waardeveranderingen van beleggingen in terreinen en gebouwen",
  },
  {
    code: "WBwvGwbGwbGwa",
    descriptionShort:
      "Gerealiseerde waardeveranderingen van beleggingen in andere beleggingen",
    descriptionLong:
      "Gerealiseerde waardeveranderingen van beleggingen in andere beleggingen",
  },
  {
    code: "WBwvGwbDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling gerealiseerde waardeveranderingen van beleggingen",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling gerealiseerde waardeveranderingen van beleggingen",
  },
  {
    code: "WBwvNwb",
    descriptionShort: "Niet-gerealiseerde waardeveranderingen van beleggingen",
    descriptionLong: "Niet-gerealiseerde waardeveranderingen van beleggingen",
  },
  {
    code: "WBwvNwbNwb",
    descriptionShort: "Niet-gerealiseerde waardeveranderingen van beleggingen",
    descriptionLong: "Niet-gerealiseerde waardeveranderingen van beleggingen",
  },
  {
    code: "WBwvNwbNwbGwg",
    descriptionShort:
      "Niet-gerealiseerde waardeveranderingen van beleggingen in groepsmaatschappijen",
    descriptionLong:
      "Niet-gerealiseerde waardeveranderingen van beleggingen in groepsmaatschappijen",
  },
  {
    code: "WBwvNwbNwbGwd",
    descriptionShort:
      "Niet-gerealiseerde waardeveranderingen van beleggingen in andere deelnemingen",
    descriptionLong:
      "Niet-gerealiseerde waardeveranderingen van beleggingen in andere deelnemingen",
  },
  {
    code: "WBwvNwbNwbGwt",
    descriptionShort:
      "Niet-gerealiseerde waardeveranderingen van beleggingen in terreinen en gebouwen",
    descriptionLong:
      "Niet-gerealiseerde waardeveranderingen van beleggingen in terreinen en gebouwen",
  },
  {
    code: "WBwvNwbNwbGwa",
    descriptionShort:
      "Niet-gerealiseerde waardeveranderingen van beleggingen in andere beleggingen",
    descriptionLong:
      "Niet-gerealiseerde waardeveranderingen van beleggingen in andere beleggingen",
  },
  {
    code: "WBwvNwbDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling niet-gerealiseerde waardeveranderingen van beleggingen",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling niet-gerealiseerde waardeveranderingen van beleggingen",
  },
  {
    code: "WBed",
    descriptionShort: "Overige bedrijfskosten",
    descriptionLong: "Overige bedrijfskosten",
  },
  {
    code: "WBedBno",
    descriptionShort: "Baten uit niet-ondernemingsactiviteiten",
    descriptionLong: "Baten uit niet-ondernemingsactiviteiten",
  },
  {
    code: "WBedBnoBno",
    descriptionShort: "Baten uit niet-ondernemingsactiviteiten",
    descriptionLong: "Baten uit niet-ondernemingsactiviteiten",
  },
  {
    code: "WBedLno",
    descriptionShort: "Lasten uit niet-ondernemingsactiviteiten",
    descriptionLong: "Lasten uit niet-ondernemingsactiviteiten",
  },
  {
    code: "WBedLnoLno",
    descriptionShort: "Lasten uit niet-ondernemingsactiviteiten",
    descriptionLong: "Lasten uit niet-ondernemingsactiviteiten",
  },
  {
    code: "WBedWkr",
    descriptionShort: "Werkkostenregeling - detail",
    descriptionLong: "Werkkostenregeling - detail",
  },
  {
    code: "WBedWkrWkf",
    descriptionShort: "Werkkosten vrije ruimte",
    descriptionLong:
      "Werkkosten vrije ruimte overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfVtw",
    descriptionShort: "Verteer werknemers (buiten werkplek, extern)",
    descriptionLong:
      "Verteer werknemers (buiten werkplek, extern) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfMow",
    descriptionShort: "Maaltijden op de werkplek",
    descriptionLong:
      "Maaltijden op de werkplek overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfVcn",
    descriptionShort:
      "Vaste vergoeding voor consumpties (niet-ambulante werknemer)",
    descriptionLong:
      "Vaste vergoeding voor consumpties (niet-ambulante werknemer) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfRvn",
    descriptionShort:
      "Rentevoordeel personeelslening (niet eigen woning of (elektrische) fiets/elektrische scooter)",
    descriptionLong:
      "Rentevoordeel personeelslening (niet eigen woning of (elektrische) fiets/elektrische scooter) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfHei",
    descriptionShort:
      "Huisvesting en inwoning (incl energie,water, bewassing) niet ter vervulling dienstbetrekking",
    descriptionLong:
      "Huisvesting en inwoning (incl energie,water, bewassing) niet ter vervulling dienstbetrekking overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfVmn",
    descriptionShort:
      "Vergoeding/verstrekking mobiele telefoon incl. abonnement (indien niet noodzakelijk)",
    descriptionLong:
      "Vergoeding/verstrekking mobiele telefoon incl. abonnement (indien niet noodzakelijk) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfVtb",
    descriptionShort:
      "Vergoeding telefoonabonnementen/internetabonnementen bij werknemer thuis (indien niet noodzakelijk)",
    descriptionLong:
      "Vergoeding telefoonabonnementen/internetabonnementen bij werknemer thuis (indien niet noodzakelijk) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfVvt",
    descriptionShort:
      "Vergoeding/verstrekking van tablet (indien niet noodzakelijk)",
    descriptionLong:
      "Vergoeding/verstrekking van tablet (indien niet noodzakelijk) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfVlp",
    descriptionShort:
      "Vergoeding/verstrekking van laptop (indien niet noodzakelijk)",
    descriptionLong:
      "Vergoeding/verstrekking van laptop (indien niet noodzakelijk) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfVdt",
    descriptionShort:
      "Vergoeding/verstrekking van desktop (indien niet noodzakelijk)",
    descriptionLong:
      "Vergoeding/verstrekking van desktop (indien niet noodzakelijk) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfVcp",
    descriptionShort:
      "Vergoeding/verstrekking computerprogrammatuur (indien niet noodzakelijk)",
    descriptionLong:
      "Vergoeding/verstrekking computerprogrammatuur (indien niet noodzakelijk) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfIwt",
    descriptionShort: "Inrichting werkplek thuis (exclusief arbovoorzieningen)",
    descriptionLong:
      "Inrichting werkplek thuis (exclusief arbovoorzieningen) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfVrh",
    descriptionShort:
      "Vergoeding reiskosten voorzover boven € 0,19 per kilometer",
    descriptionLong:
      "Vergoeding reiskosten voorzover boven € 0,19 per kilometer overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfVpb",
    descriptionShort:
      "Vergoeding van kosten van persoonlijke beschermingsmiddelen aan werknemer",
    descriptionLong:
      "Vergoeding van kosten van persoonlijke beschermingsmiddelen aan werknemer overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfVww",
    descriptionShort:
      "Vergoeding van kosten van werkkleding die nagenoeg uitsluitend geschikt is om in te werken",
    descriptionLong:
      "Vergoeding van kosten van werkkleding die nagenoeg uitsluitend geschikt is om in te werken overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfVka",
    descriptionShort:
      "Vergoeding van kosten van kleding die achterblijft op de werkplek",
    descriptionLong:
      "Vergoeding van kosten van kleding die achterblijft op de werkplek overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfVog",
    descriptionShort: "Verstrekking/vergoeding van overige kleding",
    descriptionLong:
      "Verstrekking/vergoeding van overige kleding overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfEho",
    descriptionShort: "Eerste huisvestingskosten (tot 18% van het loon)",
    descriptionLong:
      "Eerste huisvestingskosten (tot 18% van het loon) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfZve",
    descriptionShort:
      "Zakelijke verhuiskosten exclusief kosten overbrenging boedel (boven gerichte vrijstelling)",
    descriptionLong:
      "Zakelijke verhuiskosten exclusief kosten overbrenging boedel (boven gerichte vrijstelling) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfPfe",
    descriptionShort: "Personeelsfeesten (buiten de werkplek)",
    descriptionLong:
      "Personeelsfeesten (buiten de werkplek) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfKrs",
    descriptionShort: "Kerstpakket aan personeel en postactieven",
    descriptionLong:
      "Kerstpakket aan personeel en postactieven overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfGmi",
    descriptionShort:
      "Geschenken met in hoofzaak ideële waarde bij feestdagen en jubilea",
    descriptionLong:
      "Geschenken met in hoofzaak ideële waarde bij feestdagen en jubilea overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfAgn",
    descriptionShort: "Andere geschenken in natura",
    descriptionLong:
      "Andere geschenken in natura overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfAgg",
    descriptionShort: "Andere geschenken in de vorm van een geldsom",
    descriptionLong:
      "Andere geschenken in de vorm van een geldsom overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfFie",
    descriptionShort: "Fietsvergoeding",
    descriptionLong: "Fietsvergoeding overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfBbd",
    descriptionShort: "Bedrijfsfitness buiten de werkplek",
    descriptionLong:
      "Bedrijfsfitness buiten de werkplek overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfKpu",
    descriptionShort:
      "Producten uit eigen bedrijf en kortingen voor zover niet vrijgesteld",
    descriptionLong:
      "Producten uit eigen bedrijf en kortingen voor zover niet vrijgesteld overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfWep",
    descriptionShort: "Werkgeversbijdrage personeelsvereniging",
    descriptionLong:
      "Werkgeversbijdrage personeelsvereniging overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfVbp",
    descriptionShort: "Vergoeding werknemersbijdrage personeelsvereniging",
    descriptionLong:
      "Vergoeding werknemersbijdrage personeelsvereniging overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfVev",
    descriptionShort: "Vergoeding vakbondscontributie",
    descriptionLong:
      "Vergoeding vakbondscontributie overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfPrz",
    descriptionShort: "Personeelsreizen",
    descriptionLong: "Personeelsreizen overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfPwn",
    descriptionShort:
      "Parkeren bij werk (niet zijnde auto van de zaak) (geen eigen parkeerterrein, parkeervergunning)",
    descriptionLong:
      "Parkeren bij werk (niet zijnde auto van de zaak) (geen eigen parkeerterrein, parkeervergunning) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfPvn",
    descriptionShort:
      "Parkeer-, veer- en tolgelden (niet zijnde auto van de zaak)",
    descriptionLong:
      "Parkeer-, veer- en tolgelden (niet zijnde auto van de zaak) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfPev",
    descriptionShort: "Persoonlijke verzorging",
    descriptionLong:
      "Persoonlijke verzorging overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfRaw",
    descriptionShort:
      "Representatievergoeding/relatiegeschenken aan werknemers",
    descriptionLong:
      "Representatievergoeding/relatiegeschenken aan werknemers overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfEbd",
    descriptionShort:
      "Eigen bijdrage werknemers voor kinderopvang op werkplek (dagopvang)",
    descriptionLong:
      "Eigen bijdrage werknemers voor kinderopvang op werkplek (dagopvang) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfEbb",
    descriptionShort:
      "Eigen bijdrage werknemers voor kinderopvang op werkplek (bso)",
    descriptionLong:
      "Eigen bijdrage werknemers voor kinderopvang op werkplek (bso) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfKbd",
    descriptionShort:
      "Kinderopvang buiten de werkplek (factuurwaarde incl. btw of WEV)",
    descriptionLong:
      "Kinderopvang buiten de werkplek (factuurwaarde incl. btw of WEV) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfEbw",
    descriptionShort:
      "Eigen bijdrage werknemers voor kinderopvang buiten de werkplek",
    descriptionLong:
      "Eigen bijdrage werknemers voor kinderopvang buiten de werkplek overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfDkd",
    descriptionShort:
      "Door inhoudingsplichte verrichte kinderopvang op werkplek (dagopvang)",
    descriptionLong:
      "Door inhoudingsplichte verrichte kinderopvang op werkplek (dagopvang) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfDkb",
    descriptionShort:
      "Door inhoudingsplichte verrichte kinderopvang op werkplek (bso)",
    descriptionLong:
      "Door inhoudingsplichte verrichte kinderopvang op werkplek (bso) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfOwr",
    descriptionShort: "Overige werkkosten vrije ruimte",
    descriptionLong:
      "Overige werkkosten vrije ruimte overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkfDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling werkkosten vrije ruimte",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling werkkosten vrije ruimte",
  },
  {
    code: "WBedWkrWkn",
    descriptionShort: "Werkkosten met nihilwaardering",
    descriptionLong:
      "Werkkosten met nihilwaardering overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWknVwo",
    descriptionShort: "Verteer werknemers op werkplek (geen maaltijden)",
    descriptionLong:
      "Verteer werknemers op werkplek (geen maaltijden) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWknHit",
    descriptionShort:
      "Huisvesting en inwoning (incl energie,water, bewassing) ter vervulling dienstbetrekking",
    descriptionLong:
      "Huisvesting en inwoning (incl energie,water, bewassing) ter vervulling dienstbetrekking overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWknRve",
    descriptionShort:
      "Rentevoordeel personeelslening eigen woning en (elektrische) fiets of elektrische scooter",
    descriptionLong:
      "Rentevoordeel personeelslening eigen woning en (elektrische) fiets of elektrische scooter overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWknTbs",
    descriptionShort: "Ter beschikking stellen desktop computer op werkplek",
    descriptionLong:
      "Ter beschikking stellen desktop computer op werkplek overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWknLwe",
    descriptionShort: "Inrichting werkplek (niet thuis)",
    descriptionLong:
      "Inrichting werkplek (niet thuis) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWknLwa",
    descriptionShort: "Inrichting werkplek arbo-voorzieningen (thuis)",
    descriptionLong:
      "Inrichting werkplek arbo-voorzieningen (thuis) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWknPwp",
    descriptionShort:
      "Parkeren werkplek (niet zijnde auto van de zaak)(op parkeerterrein van werkgever)",
    descriptionLong:
      "Parkeren werkplek (niet zijnde auto van de zaak)(op parkeerterrein van werkgever) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWknTbg",
    descriptionShort:
      "Ter beschikking gestelde openbaarvervoerkaart/voordeelurenkaart (mede zakelijk gebruikt)",
    descriptionLong:
      "Ter beschikking gestelde openbaarvervoerkaart/voordeelurenkaart (mede zakelijk gebruikt) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWknVbm",
    descriptionShort:
      "Verstrekking van persoonlijke beschermingsmiddelen (veiligheidsbril, werkschoenen) door werkgever",
    descriptionLong:
      "Verstrekking van persoonlijke beschermingsmiddelen (veiligheidsbril, werkschoenen) door werkgever overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWknVwk",
    descriptionShort:
      "Verstrekking van werkkleding die nagenoeg uitsluitend geschikt is om in te werken door werkgever",
    descriptionLong:
      "Verstrekking van werkkleding die nagenoeg uitsluitend geschikt is om in te werken door werkgever overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWknVvk",
    descriptionShort:
      "Verstrekking van kleding die achterblijft op de werkplek",
    descriptionLong:
      "Verstrekking van kleding die achterblijft op de werkplek overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWknVkl",
    descriptionShort:
      "Verstrekking van kleding met bedrijfslogo van tenminste 70 cm²",
    descriptionLong:
      "Verstrekking van kleding met bedrijfslogo van tenminste 70 cm² overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWknArv",
    descriptionShort: "Arbovoorzieningen",
    descriptionLong: "Arbovoorzieningen overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWknPfw",
    descriptionShort: "Personeelsfeesten (op de werkplek)",
    descriptionLong:
      "Personeelsfeesten (op de werkplek) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWknBod",
    descriptionShort: "Bedrijfsfitness op de werkplek",
    descriptionLong:
      "Bedrijfsfitness op de werkplek overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWknOwn",
    descriptionShort: "Overige werkkosten nihilwaardering",
    descriptionLong:
      "Overige werkkosten nihilwaardering overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWknDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling werkkosten met nihilwaardering",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling werkkosten met nihilwaardering",
  },
  {
    code: "WBedWkrWkg",
    descriptionShort: "Werkkosten gericht vrijgesteld",
    descriptionLong:
      "Werkkosten gericht vrijgesteld overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkgVro",
    descriptionShort: "Vergoeding reiskosten (tot € 0,19) per kilometer",
    descriptionLong:
      "Vergoeding reiskosten (tot € 0,19) per kilometer overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkgCem",
    descriptionShort: "Consumpties en maaltijden dienstreis",
    descriptionLong:
      "Consumpties en maaltijden dienstreis overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkgMbo",
    descriptionShort: "Maaltijden bij overwerk/werk op koopavonden",
    descriptionLong:
      "Maaltijden bij overwerk/werk op koopavonden overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkgVca",
    descriptionShort: "Vaste vergoeding voor consumpties (ambulante werknemer)",
    descriptionLong:
      "Vaste vergoeding voor consumpties (ambulante werknemer) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkgOsc",
    descriptionShort:
      "Opleidingen, studies, cursussen, congressen, seminars, symposia, excursies, studiereizen",
    descriptionLong:
      "Opleidingen, studies, cursussen, congressen, seminars, symposia, excursies, studiereizen overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkgVak",
    descriptionShort: "Werkkosten gericht vrijgesteld, waarvan vakliteratuur",
    descriptionLong:
      "Werkkosten gericht vrijgesteld, waarvan vakliteratuur overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkgIwr",
    descriptionShort:
      "Inschrijving wettelijk en door beroepsgroep opgelegde registers",
    descriptionLong:
      "Inschrijving wettelijk en door beroepsgroep opgelegde registers overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkgDuh",
    descriptionShort: "Dubbele huisvestingskosten",
    descriptionLong:
      "Dubbele huisvestingskosten overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkgEkl",
    descriptionShort: "Extra kosten levensonderhoud",
    descriptionLong:
      "Extra kosten levensonderhoud overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkgKap",
    descriptionShort:
      "Kosten aanvragen/omzetten papieren (verblijfsvergunningen, visa, rijbewijzen)",
    descriptionLong:
      "Kosten aanvragen/omzetten papieren (verblijfsvergunningen, visa, rijbewijzen) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkgKmk",
    descriptionShort: "Kosten medische keuringen, vaccinaties",
    descriptionLong:
      "Kosten medische keuringen, vaccinaties overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkgRnl",
    descriptionShort:
      "Reiskosten naar land herkomst (familiebezoek, gezinshereniging)",
    descriptionLong:
      "Reiskosten naar land herkomst (familiebezoek, gezinshereniging) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkgCtw",
    descriptionShort: "Cursuskosten taal werkland (werknemer + gezin)",
    descriptionLong:
      "Cursuskosten taal werkland (werknemer + gezin) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkgEhb",
    descriptionShort: "Eerste huisvestingskosten (boven 18% van het loon)",
    descriptionLong:
      "Eerste huisvestingskosten (boven 18% van het loon) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkgEtk",
    descriptionShort:
      "Extra (niet-zakelijke) telefoonkosten (gesprek) met land van herkomst",
    descriptionLong:
      "Extra (niet-zakelijke) telefoonkosten (gesprek) met land van herkomst overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkgOkb",
    descriptionShort: "Opslagkosten boedel",
    descriptionLong:
      "Opslagkosten boedel overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkgKkw",
    descriptionShort: "Kosten kennismakingsreis werkland",
    descriptionLong:
      "Kosten kennismakingsreis werkland overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkgK3r",
    descriptionShort: "Kosten 30% regeling",
    descriptionLong:
      "Kosten 30% regeling overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkgZvk",
    descriptionShort: "Zakelijke verhuiskosten: kosten overbrenging boedel",
    descriptionLong:
      "Zakelijke verhuiskosten: kosten overbrenging boedel overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkgZvo",
    descriptionShort:
      "Zakelijke verhuiskosten exclusief kosten overbrenging boedel",
    descriptionLong:
      "Zakelijke verhuiskosten exclusief kosten overbrenging boedel overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkgOut",
    descriptionShort: "Outplacementkosten",
    descriptionLong: "Outplacementkosten overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkgHow",
    descriptionShort: "(Hotel)overnachtingen in verband met werk",
    descriptionLong:
      "(Hotel)overnachtingen in verband met werk overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkgVpr",
    descriptionShort:
      "Verstrekte producten en kortingen op producten uit eigen bedrijf (voor zover vrijgesteld)",
    descriptionLong:
      "Verstrekte producten en kortingen op producten uit eigen bedrijf (voor zover vrijgesteld) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkgOwv",
    descriptionShort: "Overige werkkosten gericht vrijgesteld",
    descriptionLong:
      "Overige werkkosten gericht vrijgesteld overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkgArv",
    descriptionShort: "Arbovoorzieningen",
    descriptionLong: "Arbovoorzieningen overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkgTwv",
    descriptionShort: "Thuiswerkvergoeding",
    descriptionLong:
      "Thuiswerkvergoeding overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkgDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling werkkosten gericht vrijgesteld",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling werkkosten gericht vrijgesteld",
  },
  {
    code: "WBedWkrWkc",
    descriptionShort: "Werkkosten noodzakelijkheidscriterium",
    descriptionLong:
      "Werkkosten noodzakelijkheidscriterium overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkcVmt",
    descriptionShort:
      "Vergoeding/verstrekking mobiele telefoon incl. abonnement (mits noodzakelijk)",
    descriptionLong:
      "Vergoeding/verstrekking mobiele telefoon incl. abonnement (mits noodzakelijk) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkcVtn",
    descriptionShort: "Vergoeding/verstrekking van tablet (mits noodzakelijk)",
    descriptionLong:
      "Vergoeding/verstrekking van tablet (mits noodzakelijk) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkcVln",
    descriptionShort: "Vergoeding/verstrekking van laptop (mits noodzakelijk)",
    descriptionLong:
      "Vergoeding/verstrekking van laptop (mits noodzakelijk) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkcVdn",
    descriptionShort: "Vergoeding/verstrekking van desktop (mits noodzakelijk)",
    descriptionLong:
      "Vergoeding/verstrekking van desktop (mits noodzakelijk) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkcVcm",
    descriptionShort:
      "Vergoeding/verstrekking computerprogrammatuur (mits noodzakelijk)",
    descriptionLong:
      "Vergoeding/verstrekking computerprogrammatuur (mits noodzakelijk) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkcOwm",
    descriptionShort: "Overige werkkosten noodzakelijkheidscriterium",
    descriptionLong:
      "Overige werkkosten noodzakelijkheidscriterium overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkcDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling werkkosten noodzakelijkscriterium",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling werkkosten noodzakelijkscriterium",
  },
  {
    code: "WBedWkrWki",
    descriptionShort: "Werkkosten intermediair",
    descriptionLong:
      "Werkkosten intermediair overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkiMmz",
    descriptionShort: "Maaltijden met zakelijke relaties",
    descriptionLong:
      "Maaltijden met zakelijke relaties overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkiPva",
    descriptionShort: "Parkeer-, veer- en tolgelden (auto van de zaak)",
    descriptionLong:
      "Parkeer-, veer- en tolgelden (auto van de zaak) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkiPwa",
    descriptionShort:
      "Parkeren bij werk (auto van de zaak) (geen eigen parkeerterrein, parkeervergunning)",
    descriptionLong:
      "Parkeren bij werk (auto van de zaak) (geen eigen parkeerterrein, parkeervergunning) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkiOwi",
    descriptionShort: "Overige werkkosten intermediair",
    descriptionLong:
      "Overige werkkosten intermediair overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkiDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling werkkosten intermediair",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling werkkosten intermediair",
  },
  {
    code: "WBedWkrWkb",
    descriptionShort: "Werkkosten belast loon",
    descriptionLong:
      "Werkkosten belast loon overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkbPga",
    descriptionShort: "Werkkosten belast loon t.a.v. privé-gebruik auto's",
    descriptionLong:
      "Werkkosten belast loon t.a.v. privé-gebruik auto's overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkbGed",
    descriptionShort: "Genot dienstwoning",
    descriptionLong: "Genot dienstwoning overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkbGbu",
    descriptionShort: "Geldboetes buitenlandse autoriteiten",
    descriptionLong:
      "Geldboetes buitenlandse autoriteiten overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkbGbi",
    descriptionShort: "Geldboetes binnenlandse autoriteiten",
    descriptionLong:
      "Geldboetes binnenlandse autoriteiten overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkbVzm",
    descriptionShort: "Vergoedingen en verstrekkingen ter zake van misdrijven",
    descriptionLong:
      "Vergoedingen en verstrekkingen ter zake van misdrijven overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkbVwm",
    descriptionShort:
      "Vergoedingen en verstrekkingen ter zake van wapens en munitie",
    descriptionLong:
      "Vergoedingen en verstrekkingen ter zake van wapens en munitie overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkbVdr",
    descriptionShort:
      "Vergoedingen en verstrekkingen ter zake van agressieve dieren",
    descriptionLong:
      "Vergoedingen en verstrekkingen ter zake van agressieve dieren overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkbOwb",
    descriptionShort: "Overige werkkosten belast loon",
    descriptionLong:
      "Overige werkkosten belast loon overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkbBas",
    descriptionShort: "Bestuurdersaansprakelijkheid",
    descriptionLong: "Bestuurdersaansprakelijkheid",
  },
  {
    code: "WBedWkrWkbDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling werkkosten belast loon",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling werkkosten belast loon",
  },
  {
    code: "WBedWkrWkv",
    descriptionShort: "Werkkosten geen of vrijgesteld loon",
    descriptionLong:
      "Werkkosten geen of vrijgesteld loon overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkvFrb",
    descriptionShort: "Fruitmand, rouwkrans, bloemetje",
    descriptionLong:
      "Fruitmand, rouwkrans, bloemetje overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkvKgs",
    descriptionShort:
      "Kleine geschenken (geen geld of waardebon) maximaal € 25",
    descriptionLong:
      "Kleine geschenken (geen geld of waardebon) maximaal € 25 overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkvEub",
    descriptionShort:
      "Eenmalige uitkering/verstrekking bij 25/40-jarig diensttijdjubileum werknemer (voorzover = kleiner dan 1 x maandloon)",
    descriptionLong:
      "Eenmalige uitkering/verstrekking bij 25/40-jarig diensttijdjubileum werknemer (voorzover = kleiner dan 1 x maandloon) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkvWpv",
    descriptionShort:
      "Werkgeversbijdrage personeelsvereniging (als werknemers geen aanspraak hebben op uitkeringen uit de pv)",
    descriptionLong:
      "Werkgeversbijdrage personeelsvereniging (als werknemers geen aanspraak hebben op uitkeringen uit de pv) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkvUtv",
    descriptionShort:
      "Uitkering/verstrekking tot vergoeding door werknemer ivm met werk gelden schade/verlies persoonlijke zaken",
    descriptionLong:
      "Uitkering/verstrekking tot vergoeding door werknemer ivm met werk gelden schade/verlies persoonlijke zaken overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkvEuo",
    descriptionShort:
      "Eenmalige uitkering/verstrekking bij overlijden werknemer, zijn partner of kinderen (voorzover kleiner dan 3 x maandloon)",
    descriptionLong:
      "Eenmalige uitkering/verstrekking bij overlijden werknemer, zijn partner of kinderen (voorzover kleiner dan 3 x maandloon) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkvUue",
    descriptionShort: "Uitkering/verstrekking uit een personeelsfonds",
    descriptionLong:
      "Uitkering/verstrekking uit een personeelsfonds overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkvMpi",
    descriptionShort:
      "Meewerkvergoeding partner inhoudingsplichtige (indien lager dan € 5.000)",
    descriptionLong:
      "Meewerkvergoeding partner inhoudingsplichtige (indien lager dan € 5.000) overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkvOwg",
    descriptionShort: "Overige werkkosten geen of vrijgesteld loon",
    descriptionLong:
      "Overige werkkosten geen of vrijgesteld loon overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkvDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling werkkosten geen of vrijgesteld",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling werkkosten geen of vrijgesteld",
  },
  {
    code: "WBedWkrWko",
    descriptionShort: "Werkkosten overig",
    descriptionLong: "Werkkosten overig overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkoWee",
    descriptionShort: "Werkkosten eindheffing",
    descriptionLong:
      "Werkkosten eindheffing overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkoCib",
    descriptionShort: "Correctie inzake BTW",
    descriptionLong:
      "Correctie inzake BTW overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkoObw",
    descriptionShort: "Overboeking werkkosten",
    descriptionLong:
      "Overboeking werkkosten overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkoDwk",
    descriptionShort: "Doorberekende werkkosten",
    descriptionLong:
      "Doorberekende werkkosten overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedWkrWkoDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling werkkosten overig",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling werkkosten overig",
  },
  {
    code: "WBedOvp",
    descriptionShort: "Overige personeelsgerelateerde kosten",
    descriptionLong: "Overige personeelsgerelateerde kosten",
  },
  {
    code: "WBedOvpUik",
    descriptionShort: "Uitzendkrachten",
    descriptionLong: "Uitzendkrachten overige personeelskosten",
  },
  {
    code: "WBedOvpUikUik",
    descriptionShort: "Uitzendkrachten",
    descriptionLong: "Uitzendkrachten overige personeelskosten",
  },
  {
    code: "WBedOvpUikFor",
    descriptionShort: "Uitzendkrachten  formatief",
    descriptionLong: "Uitzendkrachten  formatief",
  },
  {
    code: "WBedOvpUikPrj",
    descriptionShort: "Uitzendkrachten projectmatig",
    descriptionLong: "Uitzendkrachten projectmatig",
  },
  {
    code: "WBedOvpUikBfo",
    descriptionShort: "Uitzendkrachten boven formatief",
    descriptionLong: "Uitzendkrachten boven formatief",
  },
  {
    code: "WBedOvpUikPro",
    descriptionShort: "Uitzendkrachten programma's",
    descriptionLong: "Uitzendkrachten programma's",
  },
  {
    code: "WBedOvpUit",
    descriptionShort: "Uitzendbedrijven",
    descriptionLong: "Uitzendbedrijven overige personeelskosten",
  },
  {
    code: "WBedOvpMaf",
    descriptionShort: "Management fee",
    descriptionLong: "Management fee overige personeelskosten",
  },
  {
    code: "WBedOvpZzp",
    descriptionShort: "Ingehuurde ZZP-ers",
    descriptionLong: "Ingehuurde ZZP-ers overige personeelskosten",
  },
  {
    code: "WBedOvpPay",
    descriptionShort: "Ingehuurde payrollers",
    descriptionLong: "Ingehuurde payrollers overige personeelskosten",
  },
  {
    code: "WBedOvpOip",
    descriptionShort: "Overig ingeleend personeel",
    descriptionLong: "Overig ingeleend personeel overige personeelskosten",
  },
  {
    code: "WBedOvpWer",
    descriptionShort: "Wervingskosten",
    descriptionLong: "Wervingskosten overige personeelskosten",
  },
  {
    code: "WBedOvpWkv",
    descriptionShort: "Werkkleding",
    descriptionLong: "Werkkleding",
  },
  {
    code: "WBedOvpWpt",
    descriptionShort: "Werkplek thuis",
    descriptionLong: "Werkplek thuis",
  },
  {
    code: "WBedOvpThv",
    descriptionShort: "Thuiswerkvergoeding",
    descriptionLong: "Thuiswerkvergoeding",
  },
  {
    code: "WBedOvpMlv",
    descriptionShort: "Maaltijden en consumpties personeel",
    descriptionLong: "Maaltijden en consumpties personeel",
  },
  {
    code: "WBedOvpPug",
    descriptionShort: "Personeeluitjes en -geschenken",
    descriptionLong: "Personeeluitjes en -geschenken",
  },
  {
    code: "WBedOvpWbp",
    descriptionShort: "Werkgeversbijdrage personeelsvereniging, -fonds belast",
    descriptionLong: "Werkgeversbijdrage personeelsvereniging, -fonds belast",
  },
  {
    code: "WBedOvpOvb",
    descriptionShort: "(Overige) Vergoedingen personeel",
    descriptionLong: "(Overige) Vergoedingen personeel",
  },
  {
    code: "WBedOvpAbd",
    descriptionShort: "Arbodienst",
    descriptionLong: "Arbodienst overige personeelskosten",
  },
  {
    code: "WBedOvpDdd",
    descriptionShort: "Diensten door derden",
    descriptionLong: "Diensten door derden overige personeelskosten",
  },
  {
    code: "WBedOvpZie",
    descriptionShort: "Ziekengeldverzekering",
    descriptionLong: "Ziekengeldverzekering overige personeelskosten",
  },
  {
    code: "WBedOvpOzi",
    descriptionShort: "Ontvangen ziekengelden",
    descriptionLong: "Ontvangen ziekengelden overige personeelskosten",
  },
  {
    code: "WBedOvpDvr",
    descriptionShort: "Dotatie voorziening in verband met reorganisaties",
    descriptionLong:
      "Dotatie voorziening in verband met reorganisaties overige personeelskosten",
  },
  {
    code: "WBedOvpVvr",
    descriptionShort: "Vrijval voorziening in verband met reorganisaties",
    descriptionLong:
      "Vrijval voorziening in verband met reorganisaties overige personeelskosten",
  },
  {
    code: "WBedOvpDoa",
    descriptionShort: "Dotatie arbeidsongeschiktheidsvoorziening",
    descriptionLong:
      "Dotatie arbeidsongeschiktheidsvoorziening overige personeelskosten",
  },
  {
    code: "WBedOvpDva",
    descriptionShort:
      "Dotatie voorziening voor verzekering van ziekte of arbeidsongeschiktheid",
    descriptionLong:
      "Dotatie voorziening voor verzekering van ziekte of arbeidsongeschiktheid",
  },
  {
    code: "WBedOvpDvz",
    descriptionShort:
      "Dotatie voorziening voor verplichtingen uit hoofde van ziekte of arbeidsongeschiktheid",
    descriptionLong:
      "Dotatie voorziening voor verplichtingen uit hoofde van ziekte of arbeidsongeschiktheid",
  },
  {
    code: "WBedOvpDoj",
    descriptionShort: "Dotatie jubileumvoorziening",
    descriptionLong: "Dotatie jubileumvoorziening overige personeelskosten",
  },
  {
    code: "WBedOvpVva",
    descriptionShort: "Vrijval arbeidsongeschiktheidsvoorziening",
    descriptionLong:
      "Vrijval arbeidsongeschiktheidsvoorziening overige personeelskosten",
  },
  {
    code: "WBedOvpVvv",
    descriptionShort:
      "Vrijval voorziening voor verzekering van ziekte of arbeidsongeschiktheid",
    descriptionLong:
      "Vrijval voorziening voor verzekering van ziekte of arbeidsongeschiktheid",
  },
  {
    code: "WBedOvpVvz",
    descriptionShort:
      "Vrijval voorziening voor verplichtingen uit hoofde van ziekte of arbeidsongeschiktheid",
    descriptionLong:
      "Vrijval voorziening voor verplichtingen uit hoofde van ziekte of arbeidsongeschiktheid",
  },
  {
    code: "WBedOvpVrj",
    descriptionShort: "Vrijval jubileumvoorziening",
    descriptionLong: "Vrijval jubileumvoorziening overige personeelskosten",
  },
  {
    code: "WBedOvpObp",
    descriptionShort: "Overige belastingen inzake personeel",
    descriptionLong:
      "Overige belastingen inzake personeel overige personeelskosten",
  },
  {
    code: "WBedOvpOvp",
    descriptionShort: "Overige personeelskosten niet elders genoemd",
    descriptionLong:
      "Overige personeelskosten niet elders genoemd overige personeelskosten",
  },
  {
    code: "WBedOvpDop",
    descriptionShort: "Doorberekende overige personeelskosten",
    descriptionLong:
      "Doorberekende overige personeelskosten overige personeelskosten",
  },
  {
    code: "WBedOvpLbo",
    descriptionShort: "Loopbaanontwikkeling",
    descriptionLong: "Loopbaanontwikkeling",
  },
  {
    code: "WBedOvpOpk",
    descriptionShort: "Opleidingskosten",
    descriptionLong: "Opleidingskosten",
  },
  {
    code: "WBedOvpDlb",
    descriptionShort: "Dotatie loopbaan begeleiding voorziening",
    descriptionLong: "Dotatie loopbaan begeleiding voorziening",
  },
  {
    code: "WBedOvpVlb",
    descriptionShort: "Vrijval loopbaan begeleiding voorziening",
    descriptionLong: "Vrijval loopbaan begeleiding voorziening",
  },
  {
    code: "WBedOvpDvp",
    descriptionShort: "Dotatie voorziening uit hoofde van personeelsbeloningen",
    descriptionLong: "Dotatie voorziening uit hoofde van personeelsbeloningen",
  },
  {
    code: "WBedOvpVvp",
    descriptionShort: "Vrijval voorziening uit hoofde van personeelsbeloningen",
    descriptionLong: "Vrijval voorziening uit hoofde van personeelsbeloningen",
  },
  {
    code: "WBedOvpDab",
    descriptionShort:
      "Dotatie voorziening uit hoofde van op aandelen gebaseerde betalingen",
    descriptionLong:
      "Dotatie voorziening uit hoofde van op aandelen gebaseerde betalingen",
  },
  {
    code: "WBedOvpVab",
    descriptionShort:
      "Vrijval voorziening uit hoofde van op aandelen gebaseerde betalingen",
    descriptionLong:
      "Vrijval voorziening uit hoofde van op aandelen gebaseerde betalingen",
  },
  {
    code: "WBedOvpDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling overige  personeelsgerelateerde kosten",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling overige  personeelsgerelateerde kosten",
  },
  {
    code: "WBedHui",
    descriptionShort: "Huisvestingskosten",
    descriptionLong: "Huisvestingskosten",
  },
  {
    code: "WBedHuiErf",
    descriptionShort: "Erfpacht",
    descriptionLong: "Erfpacht huisvestingskosten",
  },
  {
    code: "WBedHuiLee",
    descriptionShort: "Leefbaarheid",
    descriptionLong: "Leefbaarheid huisvestingskosten",
  },
  {
    code: "WBedHuiLas",
    descriptionShort: "Lasten servicecontracten",
    descriptionLong: "Lasten servicecontracten huisvestingskosten",
  },
  {
    code: "WBedHuiBeh",
    descriptionShort: "Betaalde huur",
    descriptionLong: "Betaalde huur huisvestingskosten",
  },
  {
    code: "WBedHuiOhu",
    descriptionShort: "Ontvangen huursuppletie",
    descriptionLong: "Ontvangen huursuppletie huisvestingskosten",
  },
  {
    code: "WBedHuiHuw",
    descriptionShort: "Huurwaarde woongedeelte",
    descriptionLong: "Huurwaarde woongedeelte huisvestingskosten",
  },
  {
    code: "WBedHuiOnt",
    descriptionShort: "Onderhoud terreinen",
    descriptionLong: "Onderhoud terreinen huisvestingskosten",
  },
  {
    code: "WBedHuiOng",
    descriptionShort: "Onderhoud gebouwen",
    descriptionLong: "Onderhoud gebouwen huisvestingskosten",
  },
  {
    code: "WBedHuiSch",
    descriptionShort: "Schoonmaakkosten",
    descriptionLong: "Schoonmaakkosten huisvestingskosten",
  },
  {
    code: "WBedHuiSer",
    descriptionShort: "Servicekosten",
    descriptionLong: "Servicekosten huisvestingskosten",
  },
  {
    code: "WBedHuiGwe",
    descriptionShort: "Gas,water en elektra (algemeen)",
    descriptionLong: "Gas,water en elektra (algmeen)",
  },
  {
    code: "WBedHuiGweGas",
    descriptionShort: "Gas",
    descriptionLong: "Gas huisvestingskosten",
  },
  {
    code: "WBedHuiGweElk",
    descriptionShort: "Elektra",
    descriptionLong: "Elektra huisvestingskosten",
  },
  {
    code: "WBedHuiGweWat",
    descriptionShort: "Water",
    descriptionLong: "Water huisvestingskosten",
  },
  {
    code: "WBedHuiGweNed",
    descriptionShort: "Netdiensten",
    descriptionLong: "Netdiensten huisvestingskosten",
  },
  {
    code: "WBedHuiGas",
    descriptionShort: "Energiekosten gas",
    descriptionLong: "Energiekosten gas",
  },
  {
    code: "WBedHuiWat",
    descriptionShort: "Energiekosten water",
    descriptionLong: "Energiekosten water",
  },
  {
    code: "WBedHuiElk",
    descriptionShort: "Energiekosten elektra",
    descriptionLong: "Energiekosten elektra",
  },
  {
    code: "WBedHuiTrg",
    descriptionShort: "Teruglevering elektra",
    descriptionLong: "Teruglevering elektra",
  },
  {
    code: "WBedHuiPre",
    descriptionShort: "Privé-gebruik energie",
    descriptionLong: "Privé-gebruik energie huisvestingskosten",
  },
  {
    code: "WBedHuiAoz",
    descriptionShort: "Assurantiepremies onroerende zaak",
    descriptionLong: "Assurantiepremies onroerende zaak huisvestingskosten",
  },
  {
    code: "WBedHuiOnz",
    descriptionShort: "Onroerende zaakbelasting",
    descriptionLong: "Onroerende zaakbelasting huisvestingskosten",
  },
  {
    code: "WBedHuiMez",
    descriptionShort: "Milieuheffingen en zuiveringsleges",
    descriptionLong: "Milieuheffingen en zuiveringsleges huisvestingskosten",
  },
  {
    code: "WBedHuiObh",
    descriptionShort: "Overige belastingen inzake huisvesting",
    descriptionLong:
      "Overige belastingen inzake huisvesting huisvestingskosten",
  },
  {
    code: "WBedHuiOvh",
    descriptionShort: "Overige vaste huisvestingslasten",
    descriptionLong: "Overige vaste huisvestingslasten huisvestingskosten",
  },
  {
    code: "WBedHuiDrg",
    descriptionShort: "Dotatie reserve assurantie eigen risico gebouwen",
    descriptionLong:
      "Dotatie reserve assurantie eigen risico gebouwen huisvestingskosten",
  },
  {
    code: "WBedHuiVrg",
    descriptionShort: "Vrijval reserve assurantie eigen risico gebouwen",
    descriptionLong:
      "Vrijval reserve assurantie eigen risico gebouwen huisvestingskosten",
  },
  {
    code: "WBedHuiDvg",
    descriptionShort: "Dotatie voorziening groot onderhoud gebouwen",
    descriptionLong:
      "Dotatie voorziening groot onderhoud gebouwen huisvestingskosten",
  },
  {
    code: "WBedHuiVgb",
    descriptionShort: "Vrijval voorziening groot onderhoud gebouwen",
    descriptionLong:
      "Vrijval voorziening groot onderhoud gebouwen huisvestingskosten",
  },
  {
    code: "WBedHuiDkg",
    descriptionShort:
      "Dotatie kostenegalisatiereserve groot onderhoud gebouwen",
    descriptionLong:
      "Dotatie kostenegalisatiereserve groot onderhoud gebouwen huisvestingskosten",
  },
  {
    code: "WBedHuiVkg",
    descriptionShort:
      "Vrijval kostenegalisatiereserve groot onderhoud gebouwen",
    descriptionLong:
      "Vrijval kostenegalisatiereserve groot onderhoud gebouwen huisvestingskosten",
  },
  {
    code: "WBedHuiOhv",
    descriptionShort: "Overige huisvestingskosten",
    descriptionLong: "Overige huisvestingskosten huisvestingskosten",
  },
  {
    code: "WBedHuiOhvOhv",
    descriptionShort: "Overige huisvestingskosten",
    descriptionLong: "Overige huisvestingskosten",
  },
  {
    code: "WBedHuiOhvGbw",
    descriptionShort: "Overige huisvestingskosten glasbewassing",
    descriptionLong: "Overige huisvestingskosten glasbewassing",
  },
  {
    code: "WBedHuiOhvSav",
    descriptionShort: "Overige huisvestingskosten sanitaire voorzieningen",
    descriptionLong: "Overige huisvestingskosten sanitaire voorzieningen",
  },
  {
    code: "WBedHuiOhvOgb",
    descriptionShort: "Overige huisvestingskosten ongedierte bestrijding",
    descriptionLong: "Overige huisvestingskosten ongedierte bestrijding",
  },
  {
    code: "WBedHuiOhvRvb",
    descriptionShort: "Overige huisvestingskosten reinigen vloer en buiten",
    descriptionLong: "Overige huisvestingskosten reinigen vloer en buiten",
  },
  {
    code: "WBedHuiOhvBev",
    descriptionShort: "Overige huisvestingskosten beveiliging",
    descriptionLong: "Overige huisvestingskosten beveiliging",
  },
  {
    code: "WBedHuiOhvKap",
    descriptionShort: "Overige huisvestingskosten kleine aanpassingen",
    descriptionLong: "Overige huisvestingskosten kleine aanpassingen",
  },
  {
    code: "WBedHuiOhvBlm",
    descriptionShort: "Overige huisvestingskosten bloemen",
    descriptionLong: "Overige huisvestingskosten bloemen",
  },
  {
    code: "WBedHuiOhvIhh",
    descriptionShort:
      "Overige huisvestingskosten interne huur huismeesterruimte",
    descriptionLong:
      "Overige huisvestigingskosten interne huur huismeesterruimte",
  },
  {
    code: "WBedHuiOhvEhr",
    descriptionShort: "Overige huisvestingskosten elektra huismeesterruimte",
    descriptionLong: "Overige huisvestingskosten elektra huismeesterruimte",
  },
  {
    code: "WBedHuiDoh",
    descriptionShort: "Doorberekende huisvestingskosten",
    descriptionLong: "Doorberekende huisvestingskosten huisvestingskosten",
  },
  {
    code: "WBedHuiDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling huisvestingskosten",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling huisvestingskosten",
  },
  {
    code: "WBedEem",
    descriptionShort: "Exploitatie- en machinekosten",
    descriptionLong: "Exploitatie- en machinekosten",
  },
  {
    code: "WBedEemRoi",
    descriptionShort: "Reparatie en onderhoud inventaris",
    descriptionLong:
      "Reparatie en onderhoud inventaris exploitatie- en machinekosten",
  },
  {
    code: "WBedEemOls",
    descriptionShort: "Operational leasing inventaris",
    descriptionLong:
      "Operational leasing inventaris exploitatie- en machinekosten",
  },
  {
    code: "WBedEemHui",
    descriptionShort: "Huur inventaris",
    descriptionLong: "Huur inventaris exploitatie- en machinekosten",
  },
  {
    code: "WBedEemKai",
    descriptionShort: "Kleine aanschaffingen inventaris",
    descriptionLong:
      "Kleine aanschaffingen inventaris exploitatie- en machinekosten",
  },
  {
    code: "WBedEemGsk",
    descriptionShort: "Gereedschapskosten",
    descriptionLong: "Gereedschapskosten exploitatie- en machinekosten",
  },
  {
    code: "WBedEemDvi",
    descriptionShort: "Dotatie voorziening groot onderhoud inventaris",
    descriptionLong:
      "Dotatie voorziening groot onderhoud inventaris exploitatie- en machinekosten",
  },
  {
    code: "WBedEemVoi",
    descriptionShort: "Vrijval voorziening groot onderhoud inventaris",
    descriptionLong:
      "Vrijval voorziening groot onderhoud inventaris exploitatie- en machinekosten",
  },
  {
    code: "WBedEemDki",
    descriptionShort:
      "Dotatie kostenegalisatiereserve groot onderhoud inventaris",
    descriptionLong:
      "Dotatie kostenegalisatiereserve groot onderhoud inventaris exploitatie- en machinekosten",
  },
  {
    code: "WBedEemVki",
    descriptionShort:
      "Vrijval kostenegalisatiereserve groot onderhoud inventaris",
    descriptionLong:
      "Vrijval kostenegalisatiereserve groot onderhoud inventaris exploitatie- en machinekosten",
  },
  {
    code: "WBedEemOki",
    descriptionShort: "Overige kosten inventaris",
    descriptionLong: "Overige kosten inventaris exploitatie- en machinekosten",
  },
  {
    code: "WBedEemRom",
    descriptionShort: "Reparatie en onderhoud machines",
    descriptionLong:
      "Reparatie en onderhoud machines exploitatie- en machinekosten",
  },
  {
    code: "WBedEemOlm",
    descriptionShort: "Operational leasing machines",
    descriptionLong:
      "Operational leasing machines exploitatie- en machinekosten",
  },
  {
    code: "WBedEemHum",
    descriptionShort: "Huur machines",
    descriptionLong: "Huur machines exploitatie- en machinekosten",
  },
  {
    code: "WBedEemOme",
    descriptionShort: "Onderhoud machines",
    descriptionLong: "Onderhoud machines exploitatie- en machinekosten",
  },
  {
    code: "WBedEemBrm",
    descriptionShort: "Brandstof  / energiekosten machines",
    descriptionLong: "Brandstof machines exploitatie- en machinekosten",
  },
  {
    code: "WBedEemKam",
    descriptionShort: "Kleine aanschaffingen machines",
    descriptionLong:
      "Kleine aanschaffingen machines exploitatie- en machinekosten",
  },
  {
    code: "WBedEemDvm",
    descriptionShort: "Dotatie voorziening groot onderhoud machines",
    descriptionLong:
      "Dotatie voorziening groot onderhoud machines exploitatie- en machinekosten",
  },
  {
    code: "WBedEemVgo",
    descriptionShort: "Vrijval voorziening groot onderhoud machines",
    descriptionLong:
      "Vrijval voorziening groot onderhoud machines exploitatie- en machinekosten",
  },
  {
    code: "WBedEemDkm",
    descriptionShort:
      "Dotatie kostenegalisatiereserve groot onderhoud machines",
    descriptionLong:
      "Dotatie kostenegalisatiereserve groot onderhoud machines exploitatie- en machinekosten",
  },
  {
    code: "WBedEemVkm",
    descriptionShort:
      "Vrijval kostenegalisatiereserve groot onderhoud machines",
    descriptionLong:
      "Vrijval kostenegalisatiereserve groot onderhoud machines exploitatie- en machinekosten",
  },
  {
    code: "WBedEemObm",
    descriptionShort: "Overige belastingen inzake exploitatie en machines",
    descriptionLong:
      "Overige belastingen inzake exploitatie en machines exploitatie- en machinekosten",
  },
  {
    code: "WBedEemOkm",
    descriptionShort: "Overige kosten machines",
    descriptionLong: "Overige kosten machines exploitatie- en machinekosten",
  },
  {
    code: "WBedEemWdi",
    descriptionShort: "Werk door derden, waarvan industriële loondiensten",
    descriptionLong: "Werk door derden, waarvan industriële loondiensten ",
  },
  {
    code: "WBedEemWdd",
    descriptionShort: "Werk door derden, overig",
    descriptionLong: "Werk door derden, overig ",
  },
  {
    code: "WBedEemDrm",
    descriptionShort: "Dotatie reserve assurantie eigen risico machines",
    descriptionLong: "Dotatie reserve assurantie eigen risico machines ",
  },
  {
    code: "WBedEemVrm",
    descriptionShort: "Vrijval reserve assurantie eigen risico machines",
    descriptionLong: "Vrijval reserve assurantie eigen risico machines ",
  },
  {
    code: "WBedEemAme",
    descriptionShort: "Assurantiepremie machines en inventaris",
    descriptionLong: "Assurantiepremie machines en inventaris ",
  },
  {
    code: "WBedEemVpm",
    descriptionShort: "Verpakkingsmaterialen",
    descriptionLong: "Verpakkingsmaterialen ",
  },
  {
    code: "WBedEemOee",
    descriptionShort: "Overige exploitatie- en machinekosten",
    descriptionLong: "Overige exploitatie- en machinekosten ",
  },
  {
    code: "WBedEemDem",
    descriptionShort: "Doorberekende exploitatie- en machinekosten",
    descriptionLong: "Doorberekende exploitatie- en machinekosten ",
  },
  {
    code: "WBedEemDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling exploitatie- en machinekosten",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling exploitatie- en machinekosten",
  },
  {
    code: "WBedVkk",
    descriptionShort: "Verkoop gerelateerde kosten",
    descriptionLong: "Verkoop gerelateerde kosten",
  },
  {
    code: "WBedVkkRea",
    descriptionShort: "Reclame- en advertentiekosten",
    descriptionLong:
      "Reclame- en advertentiekosten verkoop gerelateerde kosten",
  },
  {
    code: "WBedVkkKos",
    descriptionShort: "Kosten sponsoring",
    descriptionLong: "Kosten sponsoring verkoop gerelateerde kosten",
  },
  {
    code: "WBedVkkBeu",
    descriptionShort: "Beurskosten",
    descriptionLong: "Beurskosten verkoop gerelateerde kosten",
  },
  {
    code: "WBedVkkRel",
    descriptionShort: "Relatiegeschenken",
    descriptionLong: "Relatiegeschenken verkoop gerelateerde kosten",
  },
  {
    code: "WBedVkkKer",
    descriptionShort: "Kerstpakketten relaties",
    descriptionLong: "Kerstpakketten relaties verkoop gerelateerde kosten",
  },
  {
    code: "WBedVkkRep",
    descriptionShort: "Representatiekosten",
    descriptionLong: "Representatiekosten verkoop gerelateerde kosten",
  },
  {
    code: "WBedVkkRev",
    descriptionShort: "Reis- en verblijfkosten",
    descriptionLong: "Reis- en verblijfkosten verkoop gerelateerde kosten",
  },
  {
    code: "WBedVkkEta",
    descriptionShort: "Etalagekosten",
    descriptionLong: "Etalagekosten verkoop gerelateerde kosten",
  },
  {
    code: "WBedVkkVrk",
    descriptionShort: "Vrachtkosten",
    descriptionLong: "Vrachtkosten verkoop gerelateerde kosten",
  },
  {
    code: "WBedVkkInc",
    descriptionShort: "Incassokosten a.g.v. verkoopactiviteiten",
    descriptionLong:
      "Incassokosten a.g.v. verkoopactiviteiten verkoop gerelateerde kosten",
  },
  {
    code: "WBedVkkKmz",
    descriptionShort: "Kilometervergoeding zakelijke reizen",
    descriptionLong:
      "Kilometervergoeding zakelijke reizen verkoop gerelateerde kosten",
  },
  {
    code: "WBedVkkKmw",
    descriptionShort: "Kilometervergoeding woon-werkverkeer",
    descriptionLong:
      "Kilometervergoeding woon-werkverkeer verkoop gerelateerde kosten",
  },
  {
    code: "WBedVkkVkp",
    descriptionShort: "Verkoopprovisie",
    descriptionLong: "Verkoopprovisie verkoop gerelateerde kosten",
  },
  {
    code: "WBedVkkCom",
    descriptionShort: "Commissies",
    descriptionLong: "Commissies verkoop gerelateerde kosten",
  },
  {
    code: "WBedVkkFra",
    descriptionShort: "Franchisekosten",
    descriptionLong: "Franchisekosten verkoop gerelateerde kosten",
  },
  {
    code: "WBedVkkDvd",
    descriptionShort: "Dotatie voorziening dubieuze debiteuren",
    descriptionLong:
      "Dotatie voorziening dubieuze debiteuren verkoop gerelateerde kosten",
  },
  {
    code: "WBedVkkAdd",
    descriptionShort: "Afboeking dubieuze debiteuren",
    descriptionLong:
      "Afboeking dubieuze debiteuren verkoop gerelateerde kosten",
  },
  {
    code: "WBedVkkDog",
    descriptionShort: "Dotatie garantievoorziening",
    descriptionLong: "Dotatie garantievoorziening verkoop gerelateerde kosten",
  },
  {
    code: "WBedVkkVgv",
    descriptionShort: "Vrijval garantievoorziening",
    descriptionLong: "Vrijval garantievoorziening verkoop gerelateerde kosten",
  },
  {
    code: "WBedVkkWeb",
    descriptionShort: "Websitekosten",
    descriptionLong: "Websitekosten verkoop gerelateerde kosten",
  },
  {
    code: "WBedVkkObs",
    descriptionShort: "Overige belastingen inzake verkoopactiviteiten",
    descriptionLong:
      "Overige belastingen inzake verkoopactiviteiten verkoop gerelateerde kosten",
  },
  {
    code: "WBedVkkOvr",
    descriptionShort: "Overige verkoopkosten",
    descriptionLong: "Overige verkoopkosten verkoop gerelateerde kosten",
  },
  {
    code: "WBedVkkDbv",
    descriptionShort: "Doorberekende verkoopkosten",
    descriptionLong: "Doorberekende verkoopkosten verkoop gerelateerde kosten",
  },
  {
    code: "WBedVkkDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling verkoopkosten",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling verkoopkosten",
  },
  {
    code: "WBedAut",
    descriptionShort: "Autokosten",
    descriptionLong: "Autokosten",
  },
  {
    code: "WBedAutBra",
    descriptionShort: "Brandstofkosten auto's",
    descriptionLong: "Brandstofkosten auto's autokosten",
  },
  {
    code: "WBedAutRoa",
    descriptionShort: "Reparatie en onderhoud auto's",
    descriptionLong: "Reparatie en onderhoud auto's autokosten",
  },
  {
    code: "WBedAutAsa",
    descriptionShort: "Assurantiepremie auto's",
    descriptionLong: "Assurantiepremie auto's autokosten",
  },
  {
    code: "WBedAutMot",
    descriptionShort: "Motorrijtuigenbelasting auto's",
    descriptionLong: "Motorrijtuigenbelasting auto's autokosten",
  },
  {
    code: "WBedAutOpa",
    descriptionShort: "Operational leasing auto's",
    descriptionLong: "Operational leasing auto's autokosten",
  },
  {
    code: "WBedAutBwl",
    descriptionShort: "Bijdrage werknemers leaseregeling",
    descriptionLong: "Bijdrage werknemers leaseregeling autokosten",
  },
  {
    code: "WBedAutPga",
    descriptionShort: "Privé-gebruik auto's",
    descriptionLong: "Privé-gebruik auto's autokosten",
  },
  {
    code: "WBedAutBop",
    descriptionShort: "BTW op privé-gebruik auto's",
    descriptionLong: "BTW op privé-gebruik auto's autokosten",
  },
  {
    code: "WBedAutHua",
    descriptionShort: "Huur auto's",
    descriptionLong: "Huur auto's autokosten",
  },
  {
    code: "WBedAutKil",
    descriptionShort: "Kilometervergoeding",
    descriptionLong: "Kilometervergoeding autokosten",
  },
  {
    code: "WBedAutBeb",
    descriptionShort: "Boetes en bekeuringen",
    descriptionLong: "Boetes en bekeuringen autokosten",
  },
  {
    code: "WBedAutObv",
    descriptionShort: "Overige belastingen inzake auto's",
    descriptionLong: "Overige belastingen inzake auto's autokosten",
  },
  {
    code: "WBedAutDrv",
    descriptionShort: "Dotatie reserve assurantie eigen risico auto's",
    descriptionLong:
      "Dotatie reserve assurantie eigen risico auto's autokosten",
  },
  {
    code: "WBedAutVrv",
    descriptionShort: "Vrijval reserve assurantie eigen risico auto's",
    descriptionLong:
      "Vrijval reserve assurantie eigen risico auto's autokosten",
  },
  {
    code: "WBedAutDkv",
    descriptionShort: "Dotatie kostenegalisatiereserve groot onderhoud auto's",
    descriptionLong:
      "Dotatie kostenegalisatiereserve groot onderhoud auto's autokosten",
  },
  {
    code: "WBedAutVkv",
    descriptionShort: "Vrijval kostenegalisatiereserve groot onderhoud auto's",
    descriptionLong:
      "Vrijval kostenegalisatiereserve groot onderhoud auto's autokosten",
  },
  {
    code: "WBedAutDvv",
    descriptionShort: "Dotatie voorziening groot onderhoud auto's",
    descriptionLong: "Dotatie voorziening groot onderhoud auto's autokosten",
  },
  {
    code: "WBedAutVoa",
    descriptionShort: "Vrijval voorziening groot onderhoud auto's",
    descriptionLong: "Vrijval voorziening groot onderhoud auto's autokosten",
  },
  {
    code: "WBedAutPar",
    descriptionShort: "Parkeerkosten auto's",
    descriptionLong: "Parkeerkosten auto's autokosten",
  },
  {
    code: "WBedAutOak",
    descriptionShort: "Overige autokosten",
    descriptionLong: "Overige autokosten autokosten",
  },
  {
    code: "WBedAutDau",
    descriptionShort: "Doorberekende autokosten",
    descriptionLong: "Doorberekende autokosten autokosten",
  },
  {
    code: "WBedAutDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling autokosten",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling autokosten",
  },
  {
    code: "WBedTra",
    descriptionShort: "Transportkosten",
    descriptionLong: "Transportkosten",
  },
  {
    code: "WBedTraBrr",
    descriptionShort: "Brandstofkosten transportmiddelen",
    descriptionLong: "Brandstofkosten transportmiddelen transportkosten",
  },
  {
    code: "WBedTraRot",
    descriptionShort: "Reparatie en onderhoud transportmiddelen",
    descriptionLong: "Reparatie en onderhoud transportmiddelen transportkosten",
  },
  {
    code: "WBedTraAst",
    descriptionShort: "Assurantiepremie transportmiddelen",
    descriptionLong: "Assurantiepremie transportmiddelen transportkosten",
  },
  {
    code: "WBedTraMot",
    descriptionShort: "Motorrijtuigenbelasting transportmiddelen",
    descriptionLong:
      "Motorrijtuigenbelasting transportmiddelen transportkosten",
  },
  {
    code: "WBedTraOpt",
    descriptionShort: "Operational leasing transportmiddelen",
    descriptionLong: "Operational leasing transportmiddelen transportkosten",
  },
  {
    code: "WBedTraPgt",
    descriptionShort: "Privé-gebruik transportmiddelen",
    descriptionLong: "Privé-gebruik transportmiddelen transportkosten",
  },
  {
    code: "WBedTraBot",
    descriptionShort: "BTW op privé-gebruik transportmiddelen",
    descriptionLong: "BTW op privé-gebruik transportmiddelen transportkosten",
  },
  {
    code: "WBedTraHut",
    descriptionShort: "Huur transportmiddelen",
    descriptionLong: "Huur transportmiddelen transportkosten",
  },
  {
    code: "WBedTraObt",
    descriptionShort: "Overige belastingen inzake transportmiddelen",
    descriptionLong:
      "Overige belastingen inzake transportmiddelen transportkosten",
  },
  {
    code: "WBedTraDrt",
    descriptionShort:
      "Dotatie reserve assurantie eigen risico transportmiddelen",
    descriptionLong:
      "Dotatie reserve assurantie eigen risico transportmiddelen transportkosten",
  },
  {
    code: "WBedTraVrt",
    descriptionShort:
      "Vrijval reserve assurantie eigen risico transportmiddelen",
    descriptionLong:
      "Vrijval reserve assurantie eigen risico transportmiddelen transportkosten",
  },
  {
    code: "WBedTraDkt",
    descriptionShort:
      "Dotatie kostenegalisatiereserve groot onderhoud transportmiddelen",
    descriptionLong:
      "Dotatie kostenegalisatiereserve groot onderhoud transportmiddelen transportkosten",
  },
  {
    code: "WBedTraVkt",
    descriptionShort:
      "Vrijval kostenegalisatiereserve groot onderhoud transportmiddelen",
    descriptionLong:
      "Vrijval kostenegalisatiereserve groot onderhoud transportmiddelen transportkosten",
  },
  {
    code: "WBedTraDvt",
    descriptionShort: "Dotatie voorziening groot onderhoud transportmiddelen",
    descriptionLong:
      "Dotatie voorziening groot onderhoud transportmiddelen transportkosten",
  },
  {
    code: "WBedTraVot",
    descriptionShort: "Vrijval voorziening groot onderhoud transportmiddelen",
    descriptionLong:
      "Vrijval voorziening groot onderhoud transportmiddelen transportkosten",
  },
  {
    code: "WBedTraPar",
    descriptionShort: "Parkeerkosten transportmiddelen",
    descriptionLong: "Parkeerkosten transportmiddelen transportkosten",
  },
  {
    code: "WBedTraOtr",
    descriptionShort: "Overige transportkosten",
    descriptionLong: "Overige transportkosten transportkosten",
  },
  {
    code: "WBedTraDot",
    descriptionShort: "Doorberekende transportkosten",
    descriptionLong: "Doorberekende transportkosten transportkosten",
  },
  {
    code: "WBedTraDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling transportkosten",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling transportkosten",
  },
  {
    code: "WBedKan",
    descriptionShort: "Kantoorkosten",
    descriptionLong: "Kantoorkosten",
  },
  {
    code: "WBedKanKan",
    descriptionShort: "Kantoorbenodigdheden",
    descriptionLong: "Kantoorbenodigdheden kantoorkosten",
  },
  {
    code: "WBedKanPor",
    descriptionShort: "Porti",
    descriptionLong: "Porti kantoorkosten",
  },
  {
    code: "WBedKanTef",
    descriptionShort: "Telefoonkosten",
    descriptionLong: "Telefoonkosten kantoorkosten",
  },
  {
    code: "WBedKanPrt",
    descriptionShort: "Privé-gebruik telefoon",
    descriptionLong: "Privé-gebruik telefoon kantoorkosten",
  },
  {
    code: "WBedKanDru",
    descriptionShort: "Drukwerk",
    descriptionLong: "Drukwerk kantoorkosten",
  },
  {
    code: "WBedKanKak",
    descriptionShort: "Kleine aanschaffingen kantoorinventaris",
    descriptionLong: "Kleine aanschaffingen kantoorinventaris kantoorkosten",
  },
  {
    code: "WBedKanCea",
    descriptionShort: "Contributies en abonnementen",
    descriptionLong: "Contributies en abonnementen kantoorkosten",
  },
  {
    code: "WBedKanVak",
    descriptionShort: "Vakliteratuur",
    descriptionLong: "Vakliteratuur kantoorkosten",
  },
  {
    code: "WBedKanBoe",
    descriptionShort: "Boekhouding",
    descriptionLong: "Boekhouding kantoorkosten",
  },
  {
    code: "WBedKanInc",
    descriptionShort: "Incassokosten a.g.v. kantooractiviteiten",
    descriptionLong: "Incassokosten a.g.v. kantooractiviteiten kantoorkosten",
  },
  {
    code: "WBedKanKoa",
    descriptionShort: "Kosten automatisering",
    descriptionLong: "Kosten automatisering kantoorkosten",
  },
  {
    code: "WBedKanSof",
    descriptionShort: "Kosten software abonnementen",
    descriptionLong: "Kosten software abonnementen",
  },
  {
    code: "WBedKanAss",
    descriptionShort: "Assurantiepremie",
    descriptionLong: "Assurantiepremie kantoorkosten",
  },
  {
    code: "WBedKanOba",
    descriptionShort: "Overige administratieve belastingen",
    descriptionLong: "Overige administratieve belastingen kantoorkosten",
  },
  {
    code: "WBedKanRok",
    descriptionShort: "Reparatie en onderhoud kantoorinventaris",
    descriptionLong: "Reparatie en onderhoud kantoorinventaris kantoorkosten",
  },
  {
    code: "WBedKanOka",
    descriptionShort: "Overige kantoorkosten",
    descriptionLong: "Overige kantoorkosten kantoorkosten",
  },
  {
    code: "WBedKanDka",
    descriptionShort: "Doorberekende kantoorkosten",
    descriptionLong: "Doorberekende kantoorkosten kantoorkosten",
  },
  {
    code: "WBedKanCom",
    descriptionShort: "Communicatie",
    descriptionLong: "Communicatie",
  },
  {
    code: "WBedKanDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling kantoorkosten",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling kantoorkosten",
  },
  {
    code: "WBedOrg",
    descriptionShort: "Organisatiekosten",
    descriptionLong: "Organisatiekosten",
  },
  {
    code: "WBedOrgHol",
    descriptionShort: "Holdingkosten",
    descriptionLong: "Holdingkosten organisatiekosten",
  },
  {
    code: "WBedOrgDmf",
    descriptionShort: "Doorberekende management fee",
    descriptionLong: "Doorberekende management fee organisatiekosten",
  },
  {
    code: "WBedOrgFra",
    descriptionShort: "Franchisefee",
    descriptionLong: "Franchisefee organisatiekosten",
  },
  {
    code: "WBedOrgOeo",
    descriptionShort: "Onderzoek en ontwikkeling",
    descriptionLong: "Onderzoek en ontwikkeling organisatiekosten",
  },
  {
    code: "WBedOrgLgv",
    descriptionShort: "Leges / vergunningen",
    descriptionLong: "Leges / vergunningen organisatiekosten",
  },
  {
    code: "WBedOrgOct",
    descriptionShort: "Octrooi en licentiekosten",
    descriptionLong: "Octrooi en licentiekosten organisatiekosten",
  },
  {
    code: "WBedOrgOok",
    descriptionShort: "Overige organisatiekosten",
    descriptionLong: "Overige organisatiekosten organisatiekosten",
  },
  {
    code: "WBedOrgDoo",
    descriptionShort: "Doorberekende organisatiekosten",
    descriptionLong: "Doorberekende organisatiekosten organisatiekosten",
  },
  {
    code: "WBedOrgWrc",
    descriptionShort:
      "Bedrijfsrestaurant en verstrekkingen comsumpties werkvloer",
    descriptionLong:
      "Bedrijfsrestaurant en verstrekkingen comsumpties werkvloer",
  },
  {
    code: "WBedOrgDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling organisatiekosten",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling organisatiekosten",
  },
  {
    code: "WBedAss",
    descriptionShort: "Assurantiekosten",
    descriptionLong: "Assurantiekosten",
  },
  {
    code: "WBedAssBea",
    descriptionShort: "Bedrijfsaansprakelijkheidsverzekering",
    descriptionLong: "Bedrijfsaansprakelijkheidsverzekering assurantiekosten",
  },
  {
    code: "WBedAssOva",
    descriptionShort: "Overige assurantiepremies",
    descriptionLong: "Overige assurantiepremies assurantiekosten",
  },
  {
    code: "WBedAssScb",
    descriptionShort: "Schadevergoedingen betaald",
    descriptionLong: "Schadevergoedingen betaald assurantiekosten",
  },
  {
    code: "WBedAssSco",
    descriptionShort: "Schadevergoedingen ontvangen",
    descriptionLong: "Schadevergoedingen ontvangen assurantiekosten",
  },
  {
    code: "WBedAssDas",
    descriptionShort: "Doorberekende assurantiekosten",
    descriptionLong: "Doorberekende assurantiekosten assurantiekosten",
  },
  {
    code: "WBedAssAvk",
    descriptionShort: "Overige advieskosten",
    descriptionLong: "Overige advieskosten",
  },
  {
    code: "WBedAssBhk",
    descriptionShort: "Overige beheerskosten",
    descriptionLong: "Overige beheerskosten",
  },
  {
    code: "WBedAssDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling assurantiekosten",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling assurantiekosten",
  },
  {
    code: "WBedAea",
    descriptionShort: "Accountants- en advieskosten",
    descriptionLong: "Accountants- en advieskosten",
  },
  {
    code: "WBedAeaAea",
    descriptionShort: "Accountants- en advieskosten",
    descriptionLong: "Accountants- en advieskosten",
  },
  {
    code: "WBedAeaAeaAov",
    descriptionShort:
      "Accountantshonoraria inzake het onderzoek van de jaarrekening",
    descriptionLong:
      "Accountantshonoraria inzake het onderzoek van de jaarrekening accountants- en advieskosten",
  },
  {
    code: "WBedAeaAeaAac",
    descriptionShort: "Accountantshonoraria inzake andere controleopdrachten",
    descriptionLong:
      "Accountantshonoraria inzake andere controleopdrachten accountants- en advieskosten",
  },
  {
    code: "WBedAeaAeaAao",
    descriptionShort:
      "Accountantshonoraria inzake adviesdiensten op fiscaal terrein",
    descriptionLong:
      "Accountantshonoraria inzake adviesdiensten op fiscaal terrein accountants- en advieskosten",
  },
  {
    code: "WBedAeaAeaAnc",
    descriptionShort:
      "Accountantshonoraria inzake andere niet-controlediensten",
    descriptionLong:
      "Accountantshonoraria inzake andere niet-controlediensten accountants- en advieskosten",
  },
  {
    code: "WBedAeaPda",
    descriptionShort: "Privé-gedeelte accountant",
    descriptionLong: "Privé-gedeelte accountant accountants- en advieskosten",
  },
  {
    code: "WBedAeaNot",
    descriptionShort: "Notariskosten",
    descriptionLong: "Notariskosten accountants- en advieskosten",
  },
  {
    code: "WBedAeaAej",
    descriptionShort: "Advocaat en juridisch advies",
    descriptionLong:
      "Advocaat en juridisch advies accountants- en advieskosten",
  },
  {
    code: "WBedAeaAdv",
    descriptionShort: "Overige advieskosten",
    descriptionLong: "Overige advieskosten accountants- en advieskosten",
  },
  {
    code: "WBedAeaDae",
    descriptionShort: "Doorberekende accountants- en advieskosten",
    descriptionLong:
      "Doorberekende accountants- en advieskosten accountants- en advieskosten",
  },
  {
    code: "WBedAeaDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling accountants- en advieskosten",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling accountants- en advieskosten",
  },
  {
    code: "WBedAdl",
    descriptionShort: "Administratieve lasten",
    descriptionLong: "Administratieve lasten",
  },
  {
    code: "WBedAdlHef",
    descriptionShort: "Heffingen",
    descriptionLong: "Heffingen administratieve lasten",
  },
  {
    code: "WBedAdlOvb",
    descriptionShort: "Overige belastingen",
    descriptionLong: "Overige belastingen administratieve lasten",
  },
  {
    code: "WBedAdlKav",
    descriptionShort: "Kasverschillen",
    descriptionLong: "Kasverschillen administratieve lasten",
  },
  {
    code: "WBedAdlBan",
    descriptionShort: "Bankkosten",
    descriptionLong: "Bankkosten administratieve lasten",
  },
  {
    code: "WBedAdlVal",
    descriptionShort: "Valutaomrekeningsverschillen",
    descriptionLong: "Valutaomrekeningsverschillen administratieve lasten",
  },
  {
    code: "WBedAdlBov",
    descriptionShort: "Boekingsverschillen",
    descriptionLong: "Boekingsverschillen administratieve lasten",
  },
  {
    code: "WBedAdlBet",
    descriptionShort: "Betalingsverschillen",
    descriptionLong: "Betalingsverschillen administratieve lasten",
  },
  {
    code: "WBedAdlBev",
    descriptionShort:
      "Boetes en verhogingen belastingen en premies sociale verzekeringen",
    descriptionLong:
      "Boetes en verhogingen belastingen en premies sociale verzekeringen administratieve lasten",
  },
  {
    code: "WBedAdlNao",
    descriptionShort: "Naheffing omzetbelasting",
    descriptionLong: "Naheffing omzetbelasting administratieve lasten",
  },
  {
    code: "WBedAdlNbo",
    descriptionShort: "Niet-verrekenbare BTW op kosten",
    descriptionLong: "Niet-verrekenbare BTW op kosten administratieve lasten",
  },
  {
    code: "WBedAdlBtk",
    descriptionShort: "BTW kleine-ondernemers-regeling",
    descriptionLong: "BTW kleine-ondernemers-regeling administratieve lasten",
  },
  {
    code: "WBedAdlOad",
    descriptionShort: "Overige administratieve lasten",
    descriptionLong: "Overige administratieve lasten administratieve lasten",
  },
  {
    code: "WBedAdlDal",
    descriptionShort: "Doorberekende administratieve lasten",
    descriptionLong:
      "Doorberekende administratieve lasten administratieve lasten",
  },
  {
    code: "WBedAdlKlv",
    descriptionShort: "Kluisverschillen",
    descriptionLong: "Kluisverschillen",
  },
  {
    code: "WBedAdlOpr",
    descriptionShort: "Opbrengst pro rata",
    descriptionLong: "Opbrengst pro rata",
  },
  {
    code: "WBedAdlDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling administratieve lasten",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling administratieve lasten",
  },
  {
    code: "WBedKof",
    descriptionShort: "Kosten fondsenwerving",
    descriptionLong: "Kosten fondsenwerving",
  },
  {
    code: "WBedKofBad",
    descriptionShort: "Bestedingen aan doelstelling",
    descriptionLong: "Bestedingen aan doelstelling kosten fondsenwerving",
  },
  {
    code: "WBedKofKef",
    descriptionShort: "Kosten eigen fondsenwerwing",
    descriptionLong: "Kosten eigen fondsenwerwing kosten fondsenwerving",
  },
  {
    code: "WBedKofKgf",
    descriptionShort: "Kosten gezamenlijke fondsenwervingsacties",
    descriptionLong:
      "Kosten gezamenlijke fondsenwervingsacties kosten fondsenwerving",
  },
  {
    code: "WBedKofKfv",
    descriptionShort: "Kosten fondsenwervingsacties van derden",
    descriptionLong:
      "Kosten fondsenwervingsacties van derden kosten fondsenwerving",
  },
  {
    code: "WBedKofKvs",
    descriptionShort: "Kosten verkrijging subsidies overheden",
    descriptionLong:
      "Kosten verkrijging subsidies overheden kosten fondsenwerving",
  },
  {
    code: "WBedKofDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling kosten fondsenwerving",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling kosten fondsenwerving",
  },
  {
    code: "WBedKse",
    descriptionShort: "Kosten stamrecht en lijfrentes",
    descriptionLong: "Kosten stamrecht en lijfrentes",
  },
  {
    code: "WBedKseAbs",
    descriptionShort: "Vrijval stamrecht- en lijfrentevoorzieningen",
    descriptionLong:
      "Vrijval stamrecht- en lijfrentevoorzieningen kosten stamrecht en lijfrentes",
  },
  {
    code: "WBedKseLiu",
    descriptionShort: "Lijfrente-uitkeringen",
    descriptionLong: "Lijfrente-uitkeringen",
  },
  {
    code: "WBedKseDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling kosten stamrecht en lijfrentes",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling kosten stamrecht en lijfrentes",
  },
  {
    code: "WBedDvr",
    descriptionShort: "Dotaties en vrijval (fiscale) reserves",
    descriptionLong: "Dotaties en vrijval (fiscale) reserves",
  },
  {
    code: "WBedDvrDfr",
    descriptionShort: "Dotatie (fiscale) reserves",
    descriptionLong:
      "Dotatie (fiscale) reserves dotaties en vrijval (fiscale) reserves",
  },
  {
    code: "WBedDvrVfr",
    descriptionShort: "Vrijval (fiscale) reserves",
    descriptionLong:
      "Vrijval (fiscale) reserves dotaties en vrijval (fiscale) reserves",
  },
  {
    code: "WBedDvrDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling dotaties en vrijval (fiscale) reserves",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling dotaties en vrijval (fiscale) reserves",
  },
  {
    code: "WBedDvv",
    descriptionShort: "Dotaties en vrijval voorzieningen",
    descriptionLong: "Dotaties en vrijval voorzieningen",
  },
  {
    code: "WBedDvvDvu",
    descriptionShort:
      "Dotatie voorziening uit hoofde van claims, geschillen en rechtsgedingen",
    descriptionLong:
      "Dotatie voorziening uit hoofde van claims, geschillen en rechtsgedingen",
  },
  {
    code: "WBedDvvDvh",
    descriptionShort: "Dotatie voorziening voor herstelkosten",
    descriptionLong: "Dotatie voorziening voor herstelkosten",
  },
  {
    code: "WBedDvvDvo",
    descriptionShort:
      "Dotatie voorziening voor opruiming van aanwezige milieuvervuiling",
    descriptionLong:
      "Dotatie voorziening voor opruiming van aanwezige milieuvervuiling",
  },
  {
    code: "WBedDvvDvc",
    descriptionShort: "Dotatie voorziening voor verlieslatende contracten",
    descriptionLong: "Dotatie voorziening voor verlieslatende contracten",
  },
  {
    code: "WBedDvvDvw",
    descriptionShort: "Dotatie voorziening voor verwijderingsverplichtingen",
    descriptionLong: "Dotatie voorziening voor verwijderingsverplichtingen",
  },
  {
    code: "WBedDvvDov",
    descriptionShort: "Dotatie overige voorzieningen",
    descriptionLong: "Dotatie overige voorzieningen",
  },
  {
    code: "WBedDvvVvu",
    descriptionShort:
      "Vrijval voorziening uit hoofde van claims, geschillen en rechtsgedingen",
    descriptionLong:
      "Vrijval voorziening uit hoofde van claims, geschillen en rechtsgedingen",
  },
  {
    code: "WBedDvvVvh",
    descriptionShort: "Vrijval voorziening voor herstelkosten",
    descriptionLong: "Vrijval voorziening voor herstelkosten",
  },
  {
    code: "WBedDvvVvm",
    descriptionShort:
      "Vrijval voorziening voor opruiming van aanwezige milieuvervuiling",
    descriptionLong:
      "Vrijval voorziening voor opruiming van aanwezige milieuvervuiling",
  },
  {
    code: "WBedDvvVvc",
    descriptionShort: "Vrijval voorziening voor verlieslatende contracten",
    descriptionLong: "Vrijval voorziening voor verlieslatende contracten",
  },
  {
    code: "WBedDvvVvw",
    descriptionShort: "Vrijval voorziening voor verwijderingsverplichtingen",
    descriptionLong: "Vrijval voorziening voor verwijderingsverplichtingen",
  },
  {
    code: "WBedDvvVov",
    descriptionShort: "Vrijval overige voorzieningen",
    descriptionLong: "Vrijval overige voorzieningen",
  },
  {
    code: "WBedDvvDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling dotaties en vrijval voorzieningen",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling dotaties en vrijval voorzieningen",
  },
  {
    code: "WBedAlk",
    descriptionShort: "Andere kosten",
    descriptionLong: "Andere kosten",
  },
  {
    code: "WBedAlkOal",
    descriptionShort: "Algemene kosten",
    descriptionLong: "Algemene kosten andere kosten",
  },
  {
    code: "WBedAlkDak",
    descriptionShort: "Doorberekende kosten",
    descriptionLong: "Doorberekende kosten andere kosten",
  },
  {
    code: "WBedAlkDfw",
    descriptionShort:
      "Doorberekend / Overboeking ivm functionele indeling andere kosten",
    descriptionLong:
      "Doorberekend / Overboeking ivm functionele indeling andere kosten",
  },
  {
    code: "WOvt",
    descriptionShort:
      "Opbrengst van vorderingen die tot de vaste activa behoren en van effecten",
    descriptionLong:
      "Opbrengst van vorderingen die tot de vaste activa behoren en van effecten",
  },
  {
    code: "WOvtRof",
    descriptionShort:
      "Opbrengst van vorderingen die tot de vaste activa behoren en van effecten",
    descriptionLong:
      "Opbrengst van vorderingen die tot de vaste activa behoren en van effecten",
  },
  {
    code: "WOvtRofRig",
    descriptionShort: "Rentebaten vorderingen groepsmaatschappijen binnenland",
    descriptionLong:
      "Rentebaten vorderingen groepsmaatschappijen binnenland opbrengst van vorderingen die tot de vaste activa behoren en van effecten",
  },
  {
    code: "WOvtRofRug",
    descriptionShort: "Rentebaten vorderingen groepsmaatschappijen buitenland",
    descriptionLong:
      "Rentebaten vorderingen groepsmaatschappijen buitenland opbrengst van vorderingen die tot de vaste activa behoren en van effecten",
  },
  {
    code: "WOvtRofRvp",
    descriptionShort:
      "Rentebaten vorderingen participanten en overige deelnemingen",
    descriptionLong:
      "Rentebaten vorderingen participanten en overige deelnemingen",
  },
  {
    code: "WOvtRofRvi",
    descriptionShort:
      "Rentebaten vorderingen overige verbonden maatschappijen binnenland",
    descriptionLong:
      "Rentebaten vorderingen overige verbonden maatschappijen binnenland opbrengst van vorderingen die tot de vaste activa behoren en van effecten",
  },
  {
    code: "WOvtRofRvu",
    descriptionShort:
      "Rentebaten vorderingen overige verbonden maatschappijen buitenland",
    descriptionLong:
      "Rentebaten vorderingen overige verbonden maatschappijen buitenland opbrengst van vorderingen die tot de vaste activa behoren en van effecten",
  },
  {
    code: "WOvtRofRid",
    descriptionShort: "Rentebaten vorderingen op deelnemingen binnenland",
    descriptionLong:
      "Rentebaten vorderingen op deelnemingen binnenland opbrengst van vorderingen die tot de vaste activa behoren en van effecten",
  },
  {
    code: "WOvtRofRud",
    descriptionShort: "Rentebaten vorderingen op deelnemingen buitenland",
    descriptionLong:
      "Rentebaten vorderingen op deelnemingen buitenland opbrengst van vorderingen die tot de vaste activa behoren en van effecten",
  },
  {
    code: "WOvtRofRva",
    descriptionShort: "Rentebaten vorderingen op aandeelhouders",
    descriptionLong:
      "Rentebaten vorderingen op aandeelhouders opbrengst van vorderingen die tot de vaste activa behoren en van effecten",
  },
  {
    code: "WOvtRofRvd",
    descriptionShort: "Rentebaten vorderingen op directie",
    descriptionLong:
      "Rentebaten vorderingen op directie opbrengst van vorderingen die tot de vaste activa behoren en van effecten",
  },
  {
    code: "WOvtRofRov",
    descriptionShort: "Rentebaten overige vorderingen",
    descriptionLong:
      "Rentebaten overige vorderingen opbrengst van vorderingen die tot de vaste activa behoren en van effecten",
  },
  {
    code: "WOvtRofDiv",
    descriptionShort: "Dividend effecten",
    descriptionLong:
      "Dividend effecten opbrengst van vorderingen die tot de vaste activa behoren en van effecten",
  },
  {
    code: "WOvtRofOoe",
    descriptionShort: "Opbrengst overige effecten",
    descriptionLong:
      "Opbrengst overige effecten opbrengst van vorderingen die tot de vaste activa behoren en van effecten",
  },
  {
    code: "WOvtRofRor",
    descriptionShort: "Rentebaten overige rekeningen-courant",
    descriptionLong: "Rentebaten overige rekeningen-courant",
  },
  {
    code: "WVhe",
    descriptionShort: "Vrijval herwaarderingsreserve",
    descriptionLong: "Vrijval herwaarderingsreserve",
  },
  {
    code: "WVheVuh",
    descriptionShort: "Vrijval uit herwaarderingsreserve",
    descriptionLong: "Vrijval uit herwaarderingsreserve",
  },
  {
    code: "WVheVuhVuh",
    descriptionShort: "Vrijval uit herwaarderingsreserve",
    descriptionLong: "Vrijval uit herwaarderingsreserve",
  },
  {
    code: "WVheVei",
    descriptionShort: "Vrijval egalisatierekening IPR",
    descriptionLong: "Vrijval egalisatierekening IPR",
  },
  {
    code: "WVheVeiVei",
    descriptionShort: "Vrijval egalisatierekening IPR",
    descriptionLong: "Vrijval egalisatierekening IPR",
  },
  {
    code: "WVheVoe",
    descriptionShort: "Vrijval overige egalisatierekeningen",
    descriptionLong: "Vrijval overige egalisatierekeningen",
  },
  {
    code: "WVheVoeVoe",
    descriptionShort: "Vrijval overige egalisatierekeningen",
    descriptionLong: "Vrijval overige egalisatierekeningen",
  },
  {
    code: "WWfa",
    descriptionShort:
      "Waardeveranderingen van financiële vaste activa en van effecten",
    descriptionLong:
      "Waardeveranderingen van financiële vaste activa en van effecten",
  },
  {
    code: "WWfaBwv",
    descriptionShort:
      "Waardeveranderingen van financiële vaste activa en van effecten",
    descriptionLong:
      "Waardeveranderingen van financiële vaste activa en van effecten",
  },
  {
    code: "WWfaBwvFva",
    descriptionShort:
      "Bijzondere waardeverminderingen van financiële vaste activa",
    descriptionLong:
      "Bijzondere waardeverminderingen van financiële vaste activa",
  },
  {
    code: "WWfaBwvFvaDgr",
    descriptionShort: "Waardeveranderingen groepsmaatschappijen",
    descriptionLong: "Waardeveranderingen groepsmaatschappijen",
  },
  {
    code: "WWfaBwvFvaDee",
    descriptionShort: "Deelnemingen",
    descriptionLong: "Waardeveranderingen overige deelnemingen",
  },
  {
    code: "WWfaBwvFvaLvo",
    descriptionShort: "Langlopende vorderingen op deelnemingen",
    descriptionLong: "Langlopende vorderingen op deelnemingen",
  },
  {
    code: "WWfaBwvFvaRcm",
    descriptionShort: "Rekening-courant met deelnemingen",
    descriptionLong: "Rekening-courant met deelnemingen",
  },
  {
    code: "WWfaBwvFvaOvm",
    descriptionShort: "Overige vorderingen",
    descriptionLong: "Overige vorderingen",
  },
  {
    code: "WWfaBwvFvaRca",
    descriptionShort: "Rekening-courant aandeelhouder",
    descriptionLong: "Rekening-courant aandeelhouder",
  },
  {
    code: "WWfaBwvFvaRcd",
    descriptionShort: "Rekening-courant directie",
    descriptionLong: "Rekening-courant directie",
  },
  {
    code: "WWfaBwvWse",
    descriptionShort: "Bijzondere waardestijgingen van effecten",
    descriptionLong:
      "Bijzondere waardestijgingen van effecten waardeveranderingen van financiële vaste activa en van effecten",
  },
  {
    code: "WWfaBwvOef",
    descriptionShort: "Bijzondere waardeverminderingen van effecten",
    descriptionLong:
      "Bijzondere waardeverminderingen van effecten waardeveranderingen van financiële vaste activa en van effecten",
  },
  {
    code: "WWfaBwvTwf",
    descriptionShort:
      "Terugneming van bijzondere waardeverminderingen van financiële vaste activa",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen van financiële vaste activa waardeveranderingen van financiële vaste activa en van effecten",
  },
  {
    code: "WWfaBwvTwe",
    descriptionShort:
      "Terugneming van bijzondere waardeverminderingen van effecten",
    descriptionLong:
      "Terugneming van bijzondere waardeverminderingen van effecten waardeveranderingen van financiële vaste activa en van effecten",
  },
  {
    code: "WWfaBwvOvp",
    descriptionShort: "Verkoopopbrengst vastgoedportefeuille VOV",
    descriptionLong:
      "Verkoopopbrengst vastgoedportefeuille Verkoop Onder Voorwaarden",
  },
  {
    code: "WWfaBwvTok",
    descriptionShort: "Toegerekende organisatiekosten VOV",
    descriptionLong: "Toegerekende organisatiekosten Verkoop Onder Voorwaarden",
  },
  {
    code: "WWfaBwvTokSal",
    descriptionShort: "Toegerekende organisatiekosten salarissen VOV",
    descriptionLong:
      "Toegerekende organisatiekosten salarissen resultaat verkoop vastgoedportefeuille aan Verkoop Onder Voorwaarden",
  },
  {
    code: "WWfaBwvTokSoc",
    descriptionShort: "Toegerekende organisatiekosten sociale lasten VOV",
    descriptionLong:
      "Toegerekende organisatiekosten sociale lasten resultaat verkoop vastgoedportefeuille aan Verkoop Onder Voorwaarden",
  },
  {
    code: "WWfaBwvTokPen",
    descriptionShort: "Toegerekende organisatiekosten pensioenlasten VOV",
    descriptionLong:
      "Toegerekende organisatiekosten pensioenlasten resultaat verkoop vastgoedportefeuille aan Verkoop Onder Voorwaarden",
  },
  {
    code: "WWfaBwvTokAfs",
    descriptionShort: "Toegerekende organisatiekosten afschrijvingen VOV",
    descriptionLong:
      "Toegerekende organisatiekosten afschrijvingen resultaat verkoop vastgoedportefeuille aan Verkoop Onder Voorwaarden",
  },
  {
    code: "WWfaBwvTokObl",
    descriptionShort:
      "Toegerekende organisatiekosten overige bedrijfslasten VOV",
    descriptionLong:
      "Toegerekende organisatiekosten overige bedrijfslasten resultaat verkoop vastgoedportefeuille aan Verkoop Onder Voorwaarden",
  },
  {
    code: "WWfaBwvTokOpl",
    descriptionShort:
      "Toegerekende organisatiekosten overige personeelslasten VOV",
    descriptionLong:
      "Toegerekende organisatiekosten overige personeelslasten aan Verkoop Onder Voorwaarden",
  },
  {
    code: "WWfaBwvLsc",
    descriptionShort: "Boekwaarde verkochte vastgoedportefeuille VOV",
    descriptionLong:
      "Boekwaarde verkochte vastgoedportefeuille mbt Verkoop Onder Voorwaarden",
  },
  {
    code: "WWfaBwvVvk",
    descriptionShort: "Verkoopkosten VOV",
    descriptionLong:
      "Verkoopkosten (makelaar, notaris e.d.) mbt Verkoop Onder Voorwaarden",
  },
  {
    code: "WWfaBwvVbm",
    descriptionShort: "Verkoopbevorderende maatregelen VOV",
    descriptionLong:
      "Verkoopbevorderende maatregelen mbt Verkoop Onder Voorwaarden",
  },
  {
    code: "WFbe",
    descriptionShort: "Financiële baten en lasten",
    descriptionLong: "Financiële baten en lasten",
  },
  {
    code: "WFbeRlm",
    descriptionShort: "Rentebaten en soortgelijke opbrengsten",
    descriptionLong: "Rentebaten en soortgelijke opbrengsten",
  },
  {
    code: "WFbeRlmRgi",
    descriptionShort: "Rentebaten vorderingen groepsmaatschappijen binnenland",
    descriptionLong: "Rentebaten vorderingen groepsmaatschappijen binnenland",
  },
  {
    code: "WFbeRlmRgu",
    descriptionShort: "Rentebaten vorderingen groepsmaatschappijen buitenland",
    descriptionLong: "Rentebaten vorderingen groepsmaatschappijen buitenland",
  },
  {
    code: "WFbeRlmRmi",
    descriptionShort:
      "Rentebaten vorderingen overige verbonden maatschappijen binnenland",
    descriptionLong:
      "Rentebaten vorderingen overige verbonden maatschappijen binnenland",
  },
  {
    code: "WFbeRlmRmu",
    descriptionShort:
      "Rentebaten vorderingen overige verbonden maatschappijen buitenland",
    descriptionLong:
      "Rentebaten vorderingen overige verbonden maatschappijen buitenland",
  },
  {
    code: "WFbeRlmRdi",
    descriptionShort: "Rentebaten vorderingen deelnemingen binnenland",
    descriptionLong: "Rentebaten vorderingen deelnemingen binnenland",
  },
  {
    code: "WFbeRlmRdu",
    descriptionShort: "Rentebaten vorderingen deelnemingen buitenland",
    descriptionLong: "Rentebaten vorderingen deelnemingen buitenland",
  },
  {
    code: "WFbeRlmRlm",
    descriptionShort: "Rente liquide middelen",
    descriptionLong:
      "Rente liquide middelen rentebaten en soortgelijke opbrengsten",
  },
  {
    code: "WFbeRlmOdr",
    descriptionShort: "Rentebaten depositorekeningen",
    descriptionLong:
      "Rentebaten depositorekeningen rentebaten en soortgelijke opbrengsten",
  },
  {
    code: "WFbeRlmObr",
    descriptionShort: "Rentebaten bankrekeningen",
    descriptionLong:
      "Rentebaten bankrekeningen rentebaten en soortgelijke opbrengsten",
  },
  {
    code: "WFbeRlmRva",
    descriptionShort: "Rentebaten vorderingen op aandeelhouders",
    descriptionLong: "Rentebaten vorderingen op aandeelhouders",
  },
  {
    code: "WFbeRlmRvf",
    descriptionShort: "Rentebaten vorderingen op firmanten",
    descriptionLong: "Rentebaten vorderingen op firmanten",
  },
  {
    code: "WFbeRlmRrb",
    descriptionShort: "Rentebaten rekeningen-courant bestuurders",
    descriptionLong:
      "Rentebaten rekeningen-courant bestuurders rentebaten en soortgelijke opbrengsten",
  },
  {
    code: "WFbeRlmRrc",
    descriptionShort: "Rentebaten rekeningen-courant commissarissen",
    descriptionLong:
      "Rentebaten rekeningen-courant commissarissen rentebaten en soortgelijke opbrengsten",
  },
  {
    code: "WFbeRlmRbb",
    descriptionShort: "Rentebaten belastingen",
    descriptionLong:
      "Rentebaten belastingen rentebaten en soortgelijke opbrengsten",
  },
  {
    code: "WFbeRlmRil",
    descriptionShort: "Rentebaten interne lening",
    descriptionLong: "Rentebaten interne lening",
  },
  {
    code: "WFbeRlmKgd",
    descriptionShort: "Rentebaten kasgeld",
    descriptionLong: "Rentebaten kasgeld",
  },
  {
    code: "WFbeRlmCol",
    descriptionShort: "Rente baten collateral",
    descriptionLong: "Rente baten collateral",
  },
  {
    code: "WFbeRlmCfv",
    descriptionShort: "Rentebaten CFV",
    descriptionLong: "Rentebaten CFV",
  },
  {
    code: "WFbeRlmKsw",
    descriptionShort: "Kwijtscheldingswinst",
    descriptionLong: "Kwijtscheldingswinst",
  },
  {
    code: "WFbeRlmAre",
    descriptionShort: "Overige rentebaten",
    descriptionLong:
      "Overige rentebaten rentebaten en soortgelijke opbrengsten",
  },
  {
    code: "WFbeRlmInc",
    descriptionShort: "Rentebaten incasso",
    descriptionLong: "Rentebaten incasso",
  },
  {
    code: "WFbeRlmMvl",
    descriptionShort: "Marktwaardecorrectie van de vastrentende lening",
    descriptionLong: "Marktwaardecorrectie van de vastrentende lening",
  },
  {
    code: "WFbeRls",
    descriptionShort: "Rentelasten en soortgelijke kosten",
    descriptionLong: "Rentelasten en soortgelijke kosten",
  },
  {
    code: "WFbeRlsRal",
    descriptionShort: "Rentelasten achtergestelde leningen",
    descriptionLong: "Rentelasten achtergestelde leningen",
  },
  {
    code: "WFbeRlsRcl",
    descriptionShort: "Rentelasten converteerbare leningen",
    descriptionLong: "Rentelasten converteerbare leningen",
  },
  {
    code: "WFbeRlsRob",
    descriptionShort: "Rentelasten obligatieleningen",
    descriptionLong: "Rentelasten obligatieleningen",
  },
  {
    code: "WFbeRlsRol",
    descriptionShort: "Rentelasten onderhandse leningen",
    descriptionLong: "Rentelasten onderhandse leningen",
  },
  {
    code: "WFbeRlsRhl",
    descriptionShort: "Rentelasten hypethecaire leningen",
    descriptionLong: "Rentelasten hypethecaire leningen",
  },
  {
    code: "WFbeRlsRle",
    descriptionShort: "Rentelasten overige leningen",
    descriptionLong: "Rentelasten overige leningen",
  },
  {
    code: "WFbeRlsRef",
    descriptionShort: "Rentelasten financieringen",
    descriptionLong: "Rentelasten financieringen",
  },
  {
    code: "WFbeRlsRlk",
    descriptionShort: "Rentelasten  kredietinstellingen",
    descriptionLong: "Rentelasten  kredietinstellingen",
  },
  {
    code: "WFbeRlsRlv",
    descriptionShort: "Rentelasten leaseverplichtingen",
    descriptionLong: "Rentelasten leaseverplichtingen",
  },
  {
    code: "WFbeRlsRlo",
    descriptionShort: "Rentelasten overheid",
    descriptionLong: "Rentelasten overheid",
  },
  {
    code: "WFbeRlsRgi",
    descriptionShort: "Rentelasten schulden groepsmaatschappijen binnenland",
    descriptionLong:
      "Rentelasten schulden groepsmaatschappijen binnenland rentelasten en soortgelijke kosten",
  },
  {
    code: "WFbeRlsRgu",
    descriptionShort: "Rentelasten schulden groepsmaatschappijen buitenland",
    descriptionLong:
      "Rentelasten schulden groepsmaatschappijen buitenland rentelasten en soortgelijke kosten",
  },
  {
    code: "WFbeRlsRmi",
    descriptionShort:
      "Rentelasten schulden overige verbonden maatschappijen binnenland",
    descriptionLong:
      "Rentelasten schulden overige verbonden maatschappijen binnenland rentelasten en soortgelijke kosten",
  },
  {
    code: "WFbeRlsRmu",
    descriptionShort:
      "Rentelasten schulden overige verbonden maatschappijen buitenland",
    descriptionLong:
      "Rentelasten schulden overige verbonden maatschappijen buitenland rentelasten en soortgelijke kosten",
  },
  {
    code: "WFbeRlsRdi",
    descriptionShort: "Rentelasten schulden op deelnemingen binnenland",
    descriptionLong:
      "Rentelasten schulden op deelnemingen binnenland rentelasten en soortgelijke kosten",
  },
  {
    code: "WFbeRlsRdu",
    descriptionShort: "Rentelasten schulden op deelnemingen buitenland",
    descriptionLong:
      "Rentelasten schulden op deelnemingen buitenland rentelasten en soortgelijke kosten",
  },
  {
    code: "WFbeRlsRsa",
    descriptionShort: "Rentelasten schulden aan aandeelhouders",
    descriptionLong:
      "Rentelasten schulden aan aandeelhouders rentelasten en soortgelijke kosten",
  },
  {
    code: "WFbeRlsRsf",
    descriptionShort: "Rentelasten schulden aan firmanten",
    descriptionLong: "Rentelasten schulden aan firmanten",
  },
  {
    code: "WFbeRlsRsd",
    descriptionShort: "Rentelasten schulden aan bestuurders",
    descriptionLong:
      "Rentelasten schulden aan bestuurders rentelasten en soortgelijke kosten",
  },
  {
    code: "WFbeRlsRsc",
    descriptionShort: "Rentelasten schulden aan commissarissen",
    descriptionLong:
      "Rentelasten schulden aan commissarissen rentelasten en soortgelijke kosten",
  },
  {
    code: "WFbeRlsRil",
    descriptionShort: "Rentelasten interne lening",
    descriptionLong: "Rentelasten interne lening",
  },
  {
    code: "WFbeRlsRps",
    descriptionShort: "Rente payerswaps",
    descriptionLong: "Rente payerswaps",
  },
  {
    code: "WFbeRlsNhr",
    descriptionShort: "Nog toe te rekenen hedgeresultaat renteswaps",
    descriptionLong: "Nog toe te rekenen hedgeresultaat renteswaps",
  },
  {
    code: "WFbeOrl",
    descriptionShort: "Overige rentelasten",
    descriptionLong: "Overige rentelasten",
  },
  {
    code: "WFbeOrlRpe",
    descriptionShort: "Rentelasten pensioenverplichtingen",
    descriptionLong: "Rentelasten pensioenverplichtingen",
  },
  {
    code: "WFbeOrlRli",
    descriptionShort: "Rentelasten lijfrenteverplichtingen",
    descriptionLong: "Rentelasten lijfrenteverplichtingen",
  },
  {
    code: "WFbeOrlRlb",
    descriptionShort: "Rentelasten belastingen",
    descriptionLong: "Rentelasten belastingen",
  },
  {
    code: "WFbeOrlRos",
    descriptionShort: "Rentelasten overige schulden",
    descriptionLong:
      "Rentelasten overige schulden rentelasten en soortgelijke kosten",
  },
  {
    code: "WFbeOrlRkb",
    descriptionShort: "Rente en kosten bank",
    descriptionLong: "Rente en kosten bank",
  },
  {
    code: "WFbeOrlWbs",
    descriptionShort: "Rente waarborgsommen",
    descriptionLong: "Rente waarborgsommen",
  },
  {
    code: "WFbeOrlRld",
    descriptionShort: "Rente disagio",
    descriptionLong: "Rente disagio",
  },
  {
    code: "WFbeOrlRls",
    descriptionShort: "Rentelasten steun",
    descriptionLong: "Rentelasten steun",
  },
  {
    code: "WFbeOrlOrl",
    descriptionShort: "Overige rentelasten",
    descriptionLong: "Overige rentelasten rentelasten en soortgelijke kosten",
  },
  {
    code: "WFbeOrlWsw",
    descriptionShort: "Borgstellingsvergoeding WSW",
    descriptionLong: "Borgstellingsvergoeding WSW",
  },
  {
    code: "WFbeOrlObl",
    descriptionShort: "Bereidstellingsprovisie Obligolening WSW",
    descriptionLong: "Bereidstellingsprovisie Obligolening WSW",
  },
  {
    code: "WFbeWis",
    descriptionShort: "Wisselkoersverschillen",
    descriptionLong: "Wisselkoersverschillen",
  },
  {
    code: "WFbeWisWis",
    descriptionShort: "Valutakoersverschillen",
    descriptionLong:
      "Valutakoersverschillen rentelasten en soortgelijke kosten",
  },
  {
    code: "WFbeKvb",
    descriptionShort: "Kosten van beleggingen",
    descriptionLong: "Kosten van beleggingen",
  },
  {
    code: "WFbeKvbKvb",
    descriptionShort: "Kosten van beleggingen",
    descriptionLong:
      "Kosten van beleggingen rentelasten en soortgelijke kosten",
  },
  {
    code: "WFbeKba",
    descriptionShort: "Kosten van beheer en administratie",
    descriptionLong: "Kosten van beheer en administratie",
  },
  {
    code: "WFbeKbaKba",
    descriptionShort: "Kosten van beheer en administratie",
    descriptionLong:
      "Kosten van beheer en administratie rentelasten en soortgelijke kosten",
  },
  {
    code: "WFbeOnn",
    descriptionShort:
      "Opbrengsten uit niet op netto-vermogenswaarde e.d. gewaardeerde deelnemingen",
    descriptionLong:
      "Opbrengsten uit niet op netto-vermogenswaarde e.d. gewaardeerde deelnemingen",
  },
  {
    code: "WFbeOnnOnn",
    descriptionShort:
      "Opbrengsten uit niet op netto-vermogenswaarde e.d. gewaardeerde deelnemingen",
    descriptionLong:
      "Opbrengsten uit niet op netto-vermogenswaarde e.d. gewaardeerde deelnemingen",
  },
  {
    code: "WFbeWnn",
    descriptionShort:
      "Waardeveranderingen van niet op netto-vermogenswaarde e.d. gewaardeerde deelnemingen",
    descriptionLong:
      "Waardeveranderingen van niet op netto-vermogenswaarde e.d. gewaardeerde deelnemingen",
  },
  {
    code: "WFbeWnnWnn",
    descriptionShort:
      "Waardeveranderingen van niet op netto-vermogenswaarde e.d. gewaardeerde deelnemingen",
    descriptionLong:
      "Waardeveranderingen van niet op netto-vermogenswaarde e.d. gewaardeerde deelnemingen",
  },
  {
    code: "WFbeDer",
    descriptionShort: "Derivaten",
    descriptionLong: "Derivaten",
  },
  {
    code: "WFbeDerMmd",
    descriptionShort: "Mutatie marktwaarde derivaten",
    descriptionLong: "Mutatie marktwaarde derivaten",
  },
  {
    code: "WFbeDerMal",
    descriptionShort: "Mutatie amortisatie leningen",
    descriptionLong: "Mutatie amortisatie leningen",
  },
  {
    code: "WFbeDerMme",
    descriptionShort: "Mutatie marktwaarde embedded derivaten",
    descriptionLong: "Mutatie marktwaarde embedded derivaten",
  },
  {
    code: "WFbeDerMae",
    descriptionShort: "Mutatie amortisatie embedded leningen",
    descriptionLong: "Mutatie amortisatie embedded leningen",
  },
  {
    code: "WFbePol",
    descriptionShort:
      "Positieve verschil tussen het ontvangen bedrag en de bij het aangaan van de lening als schuld erkende hoofdsom",
    descriptionLong:
      "Positieve verschil tussen het ontvangen bedrag en de bij het aangaan van de lening als schuld erkende hoofdsom",
  },
  {
    code: "WFbePolPol",
    descriptionShort:
      "Positieve verschil tussen het ontvangen bedrag en de bij het aangaan van de lening als schuld erkende hoofdsom",
    descriptionLong:
      "Positieve verschil tussen het ontvangen bedrag en de bij het aangaan van de lening als schuld erkende hoofdsom",
  },
  {
    code: "WFbePhp",
    descriptionShort:
      "Positieve herwaarderingen van puttable financiële instrumenten",
    descriptionLong:
      "Positieve herwaarderingen van puttable financiële instrumenten",
  },
  {
    code: "WFbePhpPhp",
    descriptionShort:
      "Positieve herwaarderingen van puttable financiële instrumenten",
    descriptionLong:
      "Positieve herwaarderingen van puttable financiële instrumenten",
  },
  {
    code: "WFbeAad",
    descriptionShort: "(Amortisatie van) agio en disagio",
    descriptionLong: "(Amortisatie van) agio en disagio",
  },
  {
    code: "WFbeAadAad",
    descriptionShort: "(Amortisatie van) agio en disagio",
    descriptionLong: "(Amortisatie van) agio en disagio",
  },
  {
    code: "WFbeNol",
    descriptionShort:
      "Negatieve verschil tussen het ontvangen bedrag en de bij het aangaan van de lening als schuld erkende hoofdsom",
    descriptionLong:
      "Negatieve verschil tussen het ontvangen bedrag en de bij het aangaan van de lening als schuld erkende hoofdsom",
  },
  {
    code: "WFbeNolNol",
    descriptionShort:
      "Negatieve verschil tussen het ontvangen bedrag en de bij het aangaan van de lening als schuld erkende hoofdsom",
    descriptionLong:
      "Negatieve verschil tussen het ontvangen bedrag en de bij het aangaan van de lening als schuld erkende hoofdsom",
  },
  {
    code: "WFbeDfb",
    descriptionShort: "Doorberekende financiële baten en lasten",
    descriptionLong: "Doorberekende financiële baten en lasten",
  },
  {
    code: "WFbeDfbDrb",
    descriptionShort: "Doorberekende financiële baten",
    descriptionLong:
      "Doorberekende financiële baten doorberekende financiële baten en lasten",
  },
  {
    code: "WFbeDfbDrl",
    descriptionShort: "Doorberekende financiële lasten",
    descriptionLong:
      "Doorberekende financiële lasten doorberekende financiële baten en lasten",
  },
  {
    code: "WFbeDfbDof",
    descriptionShort: "Doorberekende overige financiële baten en lasten",
    descriptionLong: "Doorberekende overige financiële baten en lasten",
  },
  {
    code: "WFbeNhp",
    descriptionShort:
      "Negatieve herwaarderingen van puttable financiële instrumenten",
    descriptionLong:
      "Negatieve herwaarderingen van puttable financiële instrumenten",
  },
  {
    code: "WFbeNhpNhp",
    descriptionShort:
      "Negatieve herwaarderingen van puttable financiële instrumenten",
    descriptionLong:
      "Negatieve herwaarderingen van puttable financiële instrumenten",
  },
  {
    code: "WFbeAlp",
    descriptionShort: "Aflossingspremies",
    descriptionLong: "Aflossingspremies",
  },
  {
    code: "WFbeAlpAlp",
    descriptionShort: "Aflossingspremies",
    descriptionLong: "Aflossingspremies",
  },
  {
    code: "WFbeEmk",
    descriptionShort: "Emissiekosten",
    descriptionLong: "Emissiekosten",
  },
  {
    code: "WFbeEmkEmk",
    descriptionShort: "Emissiekosten",
    descriptionLong: "Emissiekosten",
  },
  {
    code: "WFbeKva",
    descriptionShort: "Kosten bij vervroegde aflossing (eenmalig)",
    descriptionLong: "Kosten bij vervroegde aflossing (eenmalig)",
  },
  {
    code: "WFbeKvaKva",
    descriptionShort: "Kosten bij vervroegde aflossing (eenmalig)",
    descriptionLong: "Kosten bij vervroegde aflossing (eenmalig)",
  },
  {
    code: "WFbeBkf",
    descriptionShort: "Bijkomende kosten ter afsluiting van een financiering",
    descriptionLong: "Bijkomende kosten ter afsluiting van een financiering",
  },
  {
    code: "WFbeBkfBkf",
    descriptionShort: "Bijkomende kosten ter afsluiting van een financiering",
    descriptionLong: "Bijkomende kosten ter afsluiting van een financiering",
  },
  {
    code: "WFbeVlr",
    descriptionShort:
      "Valutaverschillen op leningen voor zover zij als een correctie van de verschuldigde rentekosten kunnen worden aangemerkt",
    descriptionLong:
      "Valutaverschillen op leningen voor zover zij als een correctie van de verschuldigde rentekosten kunnen worden aangemerkt",
  },
  {
    code: "WFbeVlrVlr",
    descriptionShort:
      "Valutaverschillen op leningen voor zover zij als een correctie van de verschuldigde rentekosten kunnen worden aangemerkt",
    descriptionLong:
      "Valutaverschillen op leningen voor zover zij als een correctie van de verschuldigde rentekosten kunnen worden aangemerkt",
  },
  {
    code: "WFbeRfl",
    descriptionShort:
      "Rentekosten begrepen in de leasetermijn in geval van financiële leasing",
    descriptionLong:
      "Rentekosten begrepen in de leasetermijn in geval van financiële leasing",
  },
  {
    code: "WFbeRflRfl",
    descriptionShort:
      "Rentekosten begrepen in de leasetermijn in geval van financiële leasing",
    descriptionLong:
      "Rentekosten begrepen in de leasetermijn in geval van financiële leasing",
  },
  {
    code: "WBel",
    descriptionShort: "Belastingen",
    descriptionLong: "Belastingen",
  },
  {
    code: "WBelBgr",
    descriptionShort: "Belastingen over de winst of het verlies",
    descriptionLong: "Belastingen over de winst of het verlies",
  },
  {
    code: "WBelBgrLab",
    descriptionShort: "Latente belastingen",
    descriptionLong:
      "Latente belastingen belastingen over de winst of het verlies",
  },
  {
    code: "WBelBgrDlb",
    descriptionShort: "Dotatie voorziening voor latente belastingen",
    descriptionLong: "Dotatie voorziening voor latente belastingen",
  },
  {
    code: "WBelBgrVlb",
    descriptionShort: "Vrijval voorziening voor latente belastingen",
    descriptionLong: "Vrijval voorziening voor latente belastingen",
  },
  {
    code: "WBelBgrBgr",
    descriptionShort: "Belastingen uit huidig boekjaar",
    descriptionLong:
      "Belastingen uit huidig boekjaar belastingen over de winst of het verlies",
  },
  {
    code: "WBelBgrBuv",
    descriptionShort: "Belastingen uit voorgaande boekjaren",
    descriptionLong:
      "Belastingen uit voorgaande boekjaren belastingen over de winst of het verlies",
  },
  {
    code: "WBelBgrWlb",
    descriptionShort: "Waardevermindering van latente belastingvorderingen",
    descriptionLong: "Waardevermindering van latente belastingvorderingen",
  },
  {
    code: "WBelBgrOvb",
    descriptionShort: "Overige belastingen",
    descriptionLong:
      "Overige belastingen belastingen over de winst of het verlies",
  },
  {
    code: "WRed",
    descriptionShort:
      "Aandeel in resultaat van ondernemingen waarin wordt deelgenomen",
    descriptionLong:
      "Aandeel in resultaat van ondernemingen waarin wordt deelgenomen",
  },
  {
    code: "WRedAir",
    descriptionShort:
      "Aandeel in resultaat van ondernemingen waarin wordt deelgenomen",
    descriptionLong:
      "Aandeel in resultaat van ondernemingen waarin wordt deelgenomen",
  },
  {
    code: "WRedAirAwd",
    descriptionShort: "Resultaat deelnemingen (dividend)",
    descriptionLong: "Resultaat deelnemingen (dividend)",
  },
  {
    code: "WRedAirGrp",
    descriptionShort:
      "Aandeel in winst (verlies) van deelnemingen in groepsmaatschappijen",
    descriptionLong:
      "Aandeel in winst (verlies) van deelnemingen in groepsmaatschappijen",
  },
  {
    code: "WRedAirGrpGp1",
    descriptionShort: "Resultaat deelneming groepsmaatschappij 1",
    descriptionLong: "Resultaat deelneming groepsmaatschappij 1",
  },
  {
    code: "WRedAirGrpGp2",
    descriptionShort: "Resultaat deelneming groepsmaatschappij 2",
    descriptionLong: "Resultaat deelneming groepsmaatschappij 2",
  },
  {
    code: "WRedAirGrpGp3",
    descriptionShort: "Resultaat deelneming groepsmaatschappij 3",
    descriptionLong: "Resultaat deelneming groepsmaatschappij 3",
  },
  {
    code: "WRedAirGrpGp4",
    descriptionShort: "Resultaat deelneming groepsmaatschappij 4",
    descriptionLong: "Resultaat deelneming groepsmaatschappij 4",
  },
  {
    code: "WRedAirGrpGp5",
    descriptionShort: "Resultaat deelneming groepsmaatschappij 5",
    descriptionLong: "Resultaat deelneming groepsmaatschappij 5",
  },
  {
    code: "WRedAirOvd",
    descriptionShort: "Aandeel in winst (verlies) van overige deelnemingen",
    descriptionLong: "Aandeel in winst (verlies) van overige deelnemingen",
  },
  {
    code: "WRedAirDvn",
    descriptionShort: "Dotatie voorziening in verband met deelnemingen",
    descriptionLong: "Dotatie voorziening in verband met deelnemingen",
  },
  {
    code: "WRedAirVvi",
    descriptionShort: "Vrijval voorziening in verband met deelnemingen",
    descriptionLong: "Vrijval voorziening in verband met deelnemingen",
  },
  {
    code: "WRedAirAwb",
    descriptionShort: "Aandeel in winst (verlies) bestuurders",
    descriptionLong: "Aandeel in winst (verlies) bestuurders",
  },
  {
    code: "WRedArv",
    descriptionShort:
      "Aandeel in resultaat van ondernemingen waarin wordt deelgenomen - vrijstelling",
    descriptionLong:
      "Aandeel in resultaat van ondernemingen waarin wordt deelgenomen - vrijstelling",
  },
  {
    code: "WRedArvAwd",
    descriptionShort: "Resultaat deelnemingen (dividend) - vrijstelling",
    descriptionLong: "Resultaat deelnemingen (dividend) - vrijstelling",
  },
  {
    code: "WRedArvGrp",
    descriptionShort:
      "Aandeel in winst (verlies) van deelnemingen in groepsmaatschappijen - vrijstelling",
    descriptionLong:
      "Aandeel in winst (verlies) van deelnemingen in groepsmaatschappijen - vrijstelling",
  },
  {
    code: "WRedArvOvd",
    descriptionShort:
      "Aandeel in winst (verlies) van overige deelnemingen - vrijstelling",
    descriptionLong:
      "Aandeel in winst (verlies) van overige deelnemingen - vrijstelling",
  },
  {
    code: "WRedArvDvn",
    descriptionShort:
      "Dotatie voorziening in verband met deelnemingen - vrijstelling",
    descriptionLong:
      "Dotatie voorziening in verband met deelnemingen - vrijstelling",
  },
  {
    code: "WRedArvVvi",
    descriptionShort:
      "Vrijval voorziening in verband met deelnemingen - vrijstelling",
    descriptionLong:
      "Vrijval voorziening in verband met deelnemingen - vrijstelling",
  },
  {
    code: "WRedArvAwb",
    descriptionShort: "Aandeel in winst (verlies) bestuurders - vrijstelling",
    descriptionLong: "Aandeel in winst (verlies) bestuurders - vrijstelling",
  },
  {
    code: "WLbe",
    descriptionShort: "Ledenbetalingen (inclusief reeds betaalde voorschotten)",
    descriptionLong: "Ledenbetalingen (inclusief reeds betaalde voorschotten)",
  },
  {
    code: "WLbeLbv",
    descriptionShort: "Ledenbetalingen (inclusief reeds betaalde voorschotten)",
    descriptionLong: "Ledenbetalingen (inclusief reeds betaalde voorschotten)",
  },
  {
    code: "WLbeLbvLbv",
    descriptionShort: "Ledenbetalingen (inclusief reeds betaalde voorschotten)",
    descriptionLong: "Ledenbetalingen (inclusief reeds betaalde voorschotten)",
  },
  {
    code: "WAad",
    descriptionShort: "Aandeel derden",
    descriptionLong: "Aandeel derden",
  },
  {
    code: "WAadRav",
    descriptionShort: "Resultaat aandeel van derden",
    descriptionLong: "Resultaat aandeel van derden",
  },
  {
    code: "WAadRavRav",
    descriptionShort: "Resultaat aandeel van derden",
    descriptionLong: "Resultaat aandeel van derden",
  },
  {
    code: "WNer",
    descriptionShort: "Nettoresultaat",
    descriptionLong: "Nettoresultaat",
  },
  {
    code: "WNerNew",
    descriptionShort: "Nettoresultaat na belastingen (B.V.)",
    descriptionLong: "Nettoresultaat na belastingen (B.V.)",
  },
  {
    code: "WNerNewNew",
    descriptionShort: "Netto resultaat na belastingen (B.V.)",
    descriptionLong: "Netto resultaat na belastingen (B.V.)",
  },
  {
    code: "WNerKap",
    descriptionShort: "Nettoresultaat (EZ-VOF)",
    descriptionLong: "Nettoresultaat (EZ-VOF)",
  },
  {
    code: "WNerKapKap",
    descriptionShort: "Netto resultaat (EZ-VOF)",
    descriptionLong: "Netto resultaat (EZ-VOF)",
  },
  {
    code: "WMfo",
    descriptionShort: "Mutatie fiscale oudedagsreserve",
    descriptionLong: "Mutatie fiscale oudedagsreserve",
  },
  {
    code: "WMfoBel",
    descriptionShort: "Mutatie fiscale oudedagsreserve",
    descriptionLong: "Mutatie fiscale oudedagsreserve",
  },
  {
    code: "WMfoBelMfo",
    descriptionShort: "Mutatie fiscale oudedagsreserve",
    descriptionLong:
      "Mutatie fiscale oudedagsreserve belastingen over de winst of het verlies",
  },
];
