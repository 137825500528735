import { Chip } from '@mui/joy';

export const AutomationChip = ({ percentage }: { percentage: number }) => {
  let color = 'success';
  if (percentage < 100) {
    color = 'warning';
  }

  if (percentage < 90) {
    color = 'danger';
  }

  return (
    <Chip color={color as any} onClick={function () {}} variant="soft">
      {percentage}%
    </Chip>
  );
};
