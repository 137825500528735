import client from "../../../utils/axiosClient";

type TKpiChecksOverview = {
  kpi: string;
  amount: number;
};

const getKipChecks = async () => {
  const response = await client.get("/api/insights/checks/kpi");
  return response.data as TKpiChecksOverview[];
};

export default getKipChecks;
