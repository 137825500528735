import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import useFilterStore from "../stores/filter";

export default function DivisionSearch() {
  const { setDivisionSearch, divisionSearch } = useFilterStore();

  return (
    <FormControl>
      <FormLabel>Administratie</FormLabel>
      <Input
        placeholder={"Zoek op administratie"}
        onChange={(e) => {
          setDivisionSearch(e.target.value);
        }}
        value={divisionSearch}
      />
    </FormControl>
  );
}
