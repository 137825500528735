import client from '../../../utils/axiosClient';

export type GetAccountsAPIResponse = {
  accountID: string;
  accountName: string;
}[];

export const getAccounts = async () => {
  const response = await client.get<GetAccountsAPIResponse>(`/api/accounts`, {});
  return response.data;
};
