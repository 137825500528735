import client from "../../../utils/axiosClient";

type IBalanceTimeSeriesResult = {
  timeseries: {
    administrationId: string;
    financialYear: number;
    financialPeriod: number;
    balanceAmount: number;
  }[];
  rgsCode: string;
  administrationId: string;
};

export const getRgsBalanceTimeseries = async (
  administrationId: string,
  rgsCode: string
) => {
  const response = await client.get(
    `/api/dataviz/${administrationId}/balance/timeseries`,
    {
      params: {
        rgsCode,
      },
    }
  );
  return response.data as IBalanceTimeSeriesResult;
};
