import AddCircleRounded from "@mui/icons-material/AddCircleRounded";
import FormControl from "@mui/joy/FormControl";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import { useStore } from "zustand";
import { dataVizStore } from "../../store";
import { DataVizTypes } from "./IDataVizComponent";
import { useParams } from "react-router-dom";

//generate select options based on dataviztypes
const dataVizComponents: { label: string; key: string }[] = Object.values(
  DataVizTypes
).map((type) => {
  return {
    key: type,
    label: type,
  };
});

export const DataVizSelect = ({}: {}) => {
  const { createComponent } = useStore(dataVizStore);
  const { divisionId: administrationId } = useParams<{ divisionId: string }>();

  if (!administrationId) {
    return null;
  }

  return (
    <FormControl>
      <Select
        placeholder={"Toevoegen"}
        onChange={(_, newValue) => {
          if (!newValue) return;
          createComponent(
            newValue as DataVizTypes,
            administrationId,
            "default"
          );
        }}
        startDecorator={<AddCircleRounded />}
        value={""}
      >
        {dataVizComponents.map((item) => {
          return (
            <Option key={item.key} value={item.key}>
              {item.label}
            </Option>
          );
        })}
      </Select>
    </FormControl>
  );
};
