import { ModalDialog, Tab, TabList, TabPanel, Tabs } from '@mui/joy';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import { useKpiChecksByAdministration } from '../../hooks/insights/useKpiChecksByAdministration copy';
import { BalanceCheckEditor } from './balanceCheckEditor';
import { KpiCheckEditor } from './kpiCheckEditor';

type Props = {
  divisionCode: string;
  setOpen: (open: boolean) => void;
  open: boolean;
};

export default function ConfigureChecks({ divisionCode, open, setOpen }: Props) {
  const insightChecksByAdministrationQuery = useKpiChecksByAdministration(divisionCode);

  return (
    <Modal
      aria-labelledby="close-modal-title"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ModalDialog size="lg">
        <ModalClose variant="soft" color="primary" />
        <Tabs defaultValue={'balance'}>
          <TabList>
            <Tab variant="plain" value={'balance'} color="neutral">
              Balans
            </Tab>
            <Tab variant="plain" value={'kengetal'} color="neutral" disabled>
              Kengetal
            </Tab>
          </TabList>
          <TabPanel value={'balance'}>
            <BalanceCheckEditor administrationId={divisionCode} />
          </TabPanel>
          <TabPanel value={'kengetal'}>
            <KpiCheckEditor administrationId={divisionCode} />
          </TabPanel>
        </Tabs>
      </ModalDialog>
    </Modal>
  );
}
