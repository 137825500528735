import { Stack } from "@mui/joy";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import DivisionSearch from "../../components/divisionSearch";
import { useAssignedAdministrations } from "../../hooks/useAssignedAdministrations";
import { useUsers } from "../../hooks/useUsers";
import { assignAdministrationToUser } from "../../services/api/assignAdministrationToUser";
import { unassignUserAdministration } from "../../services/api/unassignUserAdministrationcopy";
import useFilterStore from "../../stores/filter";
import { AssignedAdministrationsTable } from "./assignedAdministrations";
import { DivisionsTable } from "./divisionsTable";

type Props = {
  userID: string;
  setOpen: (open: boolean) => void;
  open: boolean;
};

export const UserModal = (props: Props) => {
  const { setOpen, userID, open } = props;
  const usersQuery = useUsers();
  const assignedAdministrationsQuery = useAssignedAdministrations(userID);
  const { divisionSearch, setDivisionSearch } = useFilterStore();

  const user = (usersQuery.data || []).find((item) => item.id === userID);

  const save = async () => {
    setOpen(false);
  };

  const assign = async (administration: string) => {
    await assignAdministrationToUser(userID, administration);
    await usersQuery.refetch();
    await assignedAdministrationsQuery.refetch();
  };

  const unassign = async (userId: string, administrationId: string) => {
    await unassignUserAdministration(userId, administrationId);
    await usersQuery.refetch();
    await assignedAdministrationsQuery.refetch();
  };

  const close = () => setOpen(false);
  if (!userID) return null;

  return (
    <Modal
      aria-labelledby="close-modal-title"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "80%",
        margin: "auto",
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          minWidth: 300,
          borderRadius: "md",
          p: 3,
        }}
      >
        <ModalClose variant="soft" color="primary" />
        <Typography
          component="h2"
          id="close-modal-title"
          level="h4"
          textColor="inherit"
          fontWeight="lg"
        >
          {`Administraties van ${user?.email}`}
        </Typography>

        <Stack direction={"column"} gap={3}>
          <DivisionSearch />
          <Stack direction={"row"} gap={3}>
            <AssignedAdministrationsTable
              unassignAdministration={unassign}
              userId={user?.id || ""}
            />
            <DivisionsTable
              divisionSearch={divisionSearch}
              onClick={assign}
              userId={userID}
            />
          </Stack>
        </Stack>
      </Sheet>
    </Modal>
  );
};
