import client from "../../../utils/axiosClient";

export type TTransaction = {
  transactionId: string;
  administrationId: string;
  transactionDate: string;
  financialYear: number;
  financialPeriod: number;
  description: string;
  transactionAmount: number;
  glAccountCode: string;
  rgsL1: string;
  rgsL2: string;
  rgsL3: string;
  rgsL4: string;
  rgsL5: string;
  source: string;
  automated: number;
  type: string;
  acountID: string;
  accountName: string;
  AdministrationName: string;
};

export const getTransactions = async (config: {
  administrationId?: string;
  rgsCodes?: string[];
  startDate?: string;
  endDate?: string;
  offset?: number;
  limit?: number;
  minAmount?: number;
}) => {
  const response = await client.get("/api/transactions", {
    params: config,
  });
  return response.data as TTransaction[];
};
