import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import ArrowDropUpRoundedIcon from "@mui/icons-material/ArrowDropUpRounded";
import { Stack } from "@mui/joy";
import { CSSProperties } from "react";

export enum SortDirection {
  asc = "asc",
  desc = "desc",
}

type ThSortProps = {
  label: string;
  sortDir: SortDirection;
  setSortDir: (sortDir: SortDirection) => void;
  sort: string;
  setSort: (sort: any) => void;
  sortKey: string;
  style?: CSSProperties | undefined;
};

export const ThSort = (props: ThSortProps) => {
  const { sort, setSort, sortDir, setSortDir, sortKey, label, style } = props;

  const onClick = () => {
    toggleDirection();
    setSort(sortKey);
  };

  const toggleDirection = () => {
    if (sortDir === SortDirection.asc) {
      setSortDir(SortDirection.desc);
    } else {
      setSortDir(SortDirection.asc);
    }
  };

  return (
    <th onClick={onClick} style={style}>
      <Stack direction={"row"} alignItems={"center"}>
        {label}
        {sort === sortKey ? (
          sortDir === SortDirection.asc ? (
            <ArrowDropUpRoundedIcon color="primary" />
          ) : (
            <ArrowDropDownRoundedIcon color="primary" />
          )
        ) : null}
      </Stack>
    </th>
  );
};
