import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Stack,
  Typography,
} from "@mui/joy";
import Input from "@mui/joy/Input";
import { useState } from "react";
import { Check } from ".";
import { RgsFilters, RgsSelect } from "../../../components/rgsSelectv2";
import createQualityCheck from "../../../services/api/createQualityCheck";
import { updateQualityCheck } from "../../../services/api/updateQualityCheck";

type Props = {
  cancel: () => void;
  selectedCheck: Check | null;
};

export default function Form({ cancel, selectedCheck }: Props) {
  const [rgsCode, setRgsCode] = useState<string>(selectedCheck?.RgsCodes || "");
  const [label, setLabel] = useState<string>(selectedCheck?.ID || "");
  const [applyGlobally, setApplyGLobally] = useState<boolean>(false);

  const createCheck = async () => {
    if (selectedCheck) {
      await updateQualityCheck(selectedCheck.ID, rgsCode, applyGlobally);
      cancel();
      return;
    }
    await createQualityCheck(label.trim(), rgsCode, applyGlobally);
    cancel();
  };

  return (
    <Stack direction={"column"} gap={3}>
      <Typography
        component="h2"
        id="close-modal-title"
        level="h4"
        textColor="inherit"
        fontWeight="lg"
      >
        Check toevoegen
      </Typography>

      <FormControl>
        <FormLabel>Label</FormLabel>
        <Input
          placeholder="Bijv. Management Fee"
          // label="Label"
          onChange={(e) => {
            // Regular expression to match only alphanumeric characters and spaces
            const regex = /^[a-zA-Z0-9 ]*$/;
            const value = e.target.value;
            if (regex.test(value)) {
              setLabel(value);
            }
          }}
          value={label}
          disabled={selectedCheck !== null}
        />
      </FormControl>

      <RgsSelect
        onChange={(rgsCode) => {
          setRgsCode(rgsCode);
        }}
        value={rgsCode}
        type={RgsFilters.RGS4}
      />

      <Checkbox
        label="Toepassen op alle administraties"
        onClick={() => {
          setApplyGLobally((checked) => !checked);
        }}
      />

      <Stack direction={"row"} justifyContent={"space-between"}>
        <Button onClick={cancel} color="neutral" variant="outlined">
          Annuleren
        </Button>
        <Button onClick={createCheck}>
          {selectedCheck ? "Bijwerken" : "Toevoegen"}
        </Button>
      </Stack>
    </Stack>
  );
}
