import { useQuery } from "@tanstack/react-query";
import queryTypes from "../types/hookTypes";
import getCompletenessByDivision from "../services/api/getCompletenessByDivision";

export const useQualityByDivsion = (
  divisionCode: string,
  yearStart: number,
  yearEnd: number,
  monthStart: number,
  monthEnd: number
) => {
  return useQuery(
    [
      queryTypes.completenessByDivision,
      divisionCode,
      yearStart,
      yearEnd,
      monthStart,
      monthEnd,
    ],
    async () => {
      if (!divisionCode) {
        return null;
      }
      const response = await getCompletenessByDivision(
        divisionCode,
        yearStart,
        yearEnd,
        monthStart,
        monthEnd
      );
      return response;
    }
  );
};
