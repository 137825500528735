import DirectionsCarFilledRoundedIcon from '@mui/icons-material/DirectionsCarFilledRounded';
import { Button } from '@mui/joy';
import Stack from '@mui/joy/Stack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import useDivision from '../../../hooks/useDivision';
import { sendNxxtEmail } from '../../../services/api/sendNxxtEmail';
import { sendResultsEmail } from '../../../services/api/sendResultsEmail';
import useDomainStore from '../../../stores/domain';

export const NXXT_DOMAIN = 'a3958678-9e57-491e-bb49-ddaf63a6544c';

export const AdministrationActions = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { divisionId } = params;
  const divisionQuery = useDivision(divisionId as string);
  const [tab, setTab] = useState('general');
  const [loading, setLoading] = useState(false);
  const user = useUser();
  const { domain } = useDomainStore();

  const division: any = divisionQuery.data || {};

  return (
    <Stack direction={'column'} gap={3}>
      <Stack direction={'row'} gap={3}>
        <Button
          loading={loading}
          onClick={async () => {
            setLoading(true);
            try {
              const date = new Date();
              const mailTo = user.user?.primaryEmailAddress?.emailAddress;
              if (!divisionId || !mailTo) {
                throw new Error('Invalid call to sendResultsEmail');
              }
              await sendResultsEmail(divisionId, date.getFullYear(), date.getMonth(), mailTo);
            } catch (error) {
              console.error(error);
            } finally {
              setLoading(false);
            }
          }}
        >
          Stuur resultaten afgelopen maand
        </Button>

        {domain === NXXT_DOMAIN && (
          <Button
            loading={loading}
            startDecorator={<DirectionsCarFilledRoundedIcon />}
            onClick={async () => {
              setLoading(true);
              try {
                const date = new Date();
                const mailTo = user.user?.primaryEmailAddress?.emailAddress;
                if (!divisionId || !mailTo) {
                  throw new Error('Invalid call to sendNxxtEmail');
                }
                await sendNxxtEmail(divisionId, date.getFullYear(), date.getMonth() - 1, mailTo);
              } catch (error) {
                console.error(error);
              } finally {
                setLoading(false);
              }
            }}
          >
            Stuur omzet overzicht (nxxt)
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
