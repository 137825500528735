import client from "../../utils/axiosClient";

export const updateQualityCheck = async (
  id: string,
  rgsCode: string,
  applyGlobally: boolean
) => {
  const response = await client.put(`/api/completeness/checks/${id}`, {
    rgsCode,
    applyGlobally,
  });
  return response.data;
};
