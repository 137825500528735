import { FormControl, FormLabel, Input, Stack } from "@mui/joy";
import { IBalanceCardProps } from ".";
import { BalanceRgsSelect } from "../../../../components/balanceRgsSelect";

export const BalanceCardEditor = ({
  props,
  updateProps,
}: {
  props: IBalanceCardProps;
  updateProps: (props: IBalanceCardProps) => void;
}) => {
  return (
    <Stack gap={3}>
      <FormControl>
        <FormLabel>Label</FormLabel>
        <Input
          placeholder="Debiteuren"
          value={props.label}
          onChange={(e) => {
            updateProps({
              ...props,
              label: e.target.value,
            });
          }}
        />
      </FormControl>
      <BalanceRgsSelect
        onChange={(rgsCode) => {
          updateProps({
            ...props,
            rgsCode: rgsCode,
          });
        }}
        value={props.rgsCode}
      />
    </Stack>
  );
};
