import { useQuery } from '@tanstack/react-query';
import { TInsightOverviewParams, getInsightsBalanceOverview } from '../../services/api/insights/getInsightsBalanceOverview';
import queryTypes from '../../types/hookTypes';

export const useInsightBalanceOverview = (config: TInsightOverviewParams) => {
  return useQuery([queryTypes.insightOverview, config.yearStart, config.yearEnd, config.monthStart, config.monthEnd, config.userID], async () => {
    const res = await getInsightsBalanceOverview(config);
    return res;
  });
};
