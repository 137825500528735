export const TASK_STATUS_LIST = [0, 1, 2, 3];

export enum TaskStatus {
  NOT_ACTIVE = 0,
  IN_PROGRESS = 1,
  COMPLETED = 2,
}

export const priorityList = [
  { value: 0, label: 'Laag' },
  { value: 1, label: 'Gemiddeld' },
  { value: 2, label: 'Hoog' },
];

export const mapPriorityToColor = (priority: number) => {
  switch (priority) {
    case 0:
      return 'action';
    case 1:
      return 'warning';
    case 2:
      return 'error';
    default:
      return 'action';
  }
};

export const mapStatusToLabel = (status: number) => {
  switch (status) {
    case TaskStatus.NOT_ACTIVE:
      return 'Niet actief';
    case TaskStatus.IN_PROGRESS:
      return 'In behandeling';
    case TaskStatus.COMPLETED:
      return 'Afgerond';
    default:
      return 'Onbekend';
  }
};
