import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { Dropdown, List, ListDivider, ListItem, ListItemDecorator, Menu, MenuButton, MenuItem, Typography } from '@mui/joy';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { UseQueryResult } from '@tanstack/react-query';
import { useState } from 'react';
import Spinner from '../../components/spinner';
import { useInvalidVatTransactions } from '../../hooks/transactions/useInvalidVatTransactions';
import { deleteTransactionVatFlagOverride } from '../../services/api/transactionVatFlagOverrides/deleteTransactionVatFlagOverride';
import { postTransactionVatFlagOverride } from '../../services/api/transactionVatFlagOverrides/postTransactionVatFlagOverride';
import { TTransactionWithGLAccount } from '../../services/api/transactions/getInvalidVatTransactions';
import { formatter } from '../../translations/numberFormatter';

type TInvalidVatTransactionTableProps = {
  administrationId: string;
  monthStart: number;
  monthEnd: number;
  yearStart: number;
  yearEnd: number;
};

const VERWERKT_IN_BOEKHOUDING = 'Verwerkt in boekhouding';
const IN_DEZE_TRANSACTIE_ZIT_WEL_BTW = 'In deze transactie zit wel BTW';
const ANDERS = 'Anders';
const AFGEKEURD = 'Afgekeurd';

export const InvalidVatTransactionTable = (props: TInvalidVatTransactionTableProps) => {
  const { administrationId, monthStart, yearStart, yearEnd, monthEnd } = props;
  const [isLoading, setIsLoading] = useState(false);
  const invalidVatTransactionsQuery = useInvalidVatTransactions({
    yearStart,
    yearEnd,
    monthStart,
    monthEnd,
    administrationId,
  });

  const updateTransactionVatFlagOverride = async (
    transactionId: string,
    reason: string,
    invalidVatTransactionsQuery: UseQueryResult<TTransactionWithGLAccount[]>,
  ) => {
    try {
      setIsLoading(true);
      await postTransactionVatFlagOverride({ transactionId, reason });
      await invalidVatTransactionsQuery.refetch();
    } catch (error) {
      console.error('Error updating transaction VAT flag override:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const removeTransactionVatFlagOverride = async (transactionId: string, invalidVatTransactionsQuery: UseQueryResult<TTransactionWithGLAccount[]>) => {
    try {
      setIsLoading(true);
      await deleteTransactionVatFlagOverride(transactionId);
      await invalidVatTransactionsQuery.refetch();
    } catch (error) {
      console.error('Error deleting transaction VAT flag override:', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!invalidVatTransactionsQuery.isSuccess) {
    return <Spinner />;
  }

  const rows = invalidVatTransactionsQuery.data;

  const columns: GridColDef[] = [
    {
      field: 'transactionDate',
      headerName: 'Datum',
      flex: 0.75,
      valueFormatter(params) {
        const dutchDate = new Date(params.value as string).toLocaleDateString('nl-NL');
        return dutchDate;
      },
      disableColumnMenu: true,
    },
    {
      field: 'glAccountCode',
      headerName: 'Grootboek nr',
      flex: 0.75,
      disableColumnMenu: true,
    },
    {
      field: 'glAccountDescription',
      headerName: 'Grootboek beschrijving',
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'rgsL5',
      headerName: 'RGS code',
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'description',
      headerName: 'Transactie beschrijving',
      flex: 1.5,
      disableColumnMenu: true,
    },
    {
      field: 'transactionAmount',
      headerName: 'Transactie bedrag',
      flex: 1,
      disableColumnMenu: true,
      valueFormatter(params) {
        return formatter.format(params.value as number);
      },
    },
    {
      field: 'amountVAT',
      headerName: 'BTW bedrag',
      flex: 1,
      disableColumnMenu: true,
      valueFormatter(params) {
        return formatter.format(params.value as number);
      },
      renderCell(params) {
        return (
          <Typography color={'danger'} level={'body-sm'}>
            {formatter.format(params.value as number)}
          </Typography>
        );
      },
    },
    {
      field: 'transactionVatFlagOverrideReason',
      headerName: 'Status',
      flex: 1,
      disableColumnMenu: true,
      renderCell(params) {
        return (
          <Dropdown>
            <MenuButton endDecorator={<ArrowDropDown />} disabled={isLoading} variant="soft" color={params.value ? 'success' : 'danger'}>
              <Typography>{params.value ? 'Goedgekeurd' : 'Afgekeurd'}</Typography>
            </MenuButton>
            <Menu>
              <ListItem nested>
                <List aria-label="Font sizes">
                  <MenuItem disabled>Goedkeuren:</MenuItem>
                  <MenuItem
                    onClick={() => updateTransactionVatFlagOverride(params.row.transactionId, VERWERKT_IN_BOEKHOUDING, invalidVatTransactionsQuery)}
                    disabled={params.value === VERWERKT_IN_BOEKHOUDING || isLoading}
                  >
                    <ListItemDecorator>{params.value === VERWERKT_IN_BOEKHOUDING && <CheckRoundedIcon />}</ListItemDecorator> {VERWERKT_IN_BOEKHOUDING}
                  </MenuItem>
                  <MenuItem
                    onClick={() => updateTransactionVatFlagOverride(params.row.transactionId, IN_DEZE_TRANSACTIE_ZIT_WEL_BTW, invalidVatTransactionsQuery)}
                    disabled={params.value === IN_DEZE_TRANSACTIE_ZIT_WEL_BTW || isLoading}
                  >
                    <ListItemDecorator>{params.value === IN_DEZE_TRANSACTIE_ZIT_WEL_BTW && <CheckRoundedIcon />}</ListItemDecorator>{' '}
                    {IN_DEZE_TRANSACTIE_ZIT_WEL_BTW}
                  </MenuItem>
                  <MenuItem
                    onClick={() => updateTransactionVatFlagOverride(params.row.transactionId, ANDERS, invalidVatTransactionsQuery)}
                    disabled={params.value === ANDERS || isLoading}
                  >
                    <ListItemDecorator>{params.value === ANDERS && <CheckRoundedIcon />}</ListItemDecorator> Anders
                  </MenuItem>
                </List>
              </ListItem>

              <ListDivider />
              <MenuItem
                onClick={() => removeTransactionVatFlagOverride(params.row.transactionId, invalidVatTransactionsQuery)}
                disabled={params.value === null || isLoading}
              >
                <ListItemDecorator>{params.value === null && <CheckRoundedIcon />}</ListItemDecorator> Afkeuren
              </MenuItem>
            </Menu>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 12 },
        },
        sorting: {
          sortModel: [
            {
              field: 'glAccountCodeCount',
              sort: 'desc',
            },
          ],
        },
      }}
      getRowId={(row) => row.transactionId}
    />
  );
};
