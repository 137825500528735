import client from "../../../utils/axiosClient";

export type TPostKpiCheck = {
  operator: string;
  amount: number;
  kpi: string;
  administrationId: string;
};

export const postKpiChecks = async (check: TPostKpiCheck[]) => {
  const response = await client.post(`/api/insights/checks/kpi`, check);
  return response.data;
};
