import Keys from "./keys";

const config = {
  [Keys.logout]: "Logout",
  [Keys.profile]: "Profile",
  [Keys.dashboards]: "Dashboards",
  [Keys.pages]: "Pages",
  [Keys.organization]: "Organization",
  [Keys.billing]: "Billing",
  [Keys.welcomeBack]: "Welcome back",
  [Keys.lastDatabaseUpdate]: "Last database update",
  [Keys.kiaAdvice]: "KIA Advice",
  [Keys.kiaAdviceDescription]: ` Insights into which businesses can utilize the KIA, to what extend they can receive benefits, and how much they could potentially benefit.`,
  [Keys.view]: "View",
  [Keys.completenessCheck]: "Completeness Check",
  [Keys.completenessCheckDescription]:
    "Insights into the completeness of administrations. See which entries have been booked during each month.",
  [Keys.standardFinancial]: "Standard Financial",
  [Keys.standardFinancialDescription]:
    "Standaard financieel dashboard voor het monitoren van een organisatie's performance.",
  [Keys.administrations]: "Administrations",
  [Keys.administration]: "Administration",
  [Keys.advice]: "Advice",
  [Keys.users]: "Users",
  [Keys.divisions]: "Divisions",
  [Keys.totalInvestments]: "Total investments",
  [Keys.totalPossibleRebate]: "Total possible rebate",
  [Keys.generatedAdvice]: "Generated advice",
  [Keys.investement]: "Investment",
  [Keys.rebate]: "Rebate",
  [Keys.currentKiaTier]: "Current tier",
  [Keys.details]: "Details",
  [Keys.back]: "Back",
  [Keys.amount]: "Amount",
  [Keys.description]: "Description",
  [Keys.year]: "Year",
  [Keys.completeness]: "Completeness",
  [Keys.percentage]: "Percentage",
  [Keys.overview]: "Overview",
  [Keys.profileDescription]: "Account data and settings",
  [Keys.language]: "Language",
  [Keys.toggleComplete]: "Filter complete",
  [Keys.months]: "Months",
  [Keys.quarter]: "Quarter",
  [Keys.clearFilters]: "All",
  [Keys.authorize]: "Authorize",
  [Keys.postActionPoints]: "Export action points",
  [Keys.dataLoads]: "Data",
  [Keys.runStart]: "Start load",
  [Keys.runEnd]: "End load",
  [Keys.status]: "Status",
  [Keys.sources]: "Sources",
  [Keys.insights]: "Insights",
  [Keys.code]: "Code",
  [Keys.date]: "Dat",
  [Keys.category]: "Category",
  [Keys.administrationsWithInvestments]: "Administrations with investments",
  [Keys.DEPRECIATION]: "Depreciations booked",
  [Keys.PAYROLL_JOURNEY_ENTRY]: "Payroll booked",
  [Keys.MANAGEMENT_FEE]: "Management fee booked",
  [Keys.RENTAL_COSTS]: "Rental fees booked",
  [Keys.AmountUnspecified]: "No unspecified transactions",
  [Keys.CashBalance]: "Cash balance",
  [Keys.LastTransactionDate]: "Last transaction date",
  [Keys.check]: "Check",
  [Keys.yearComplete]: "Year complete",
  [Keys.toExact]: "Link to Exact",
  [Keys.generalLedgerAccountDescription]: "General ledger description",
  [Keys.generalLedgerAccountCode]: "General ledger code",
  [Keys.PHONE_CHECK]: "Phone costs",
  [Keys.RECEIVABLES]: "Pending receivables",
  [Keys.debtor]: "Debtor",
  [Keys.FILING]: "Filing",
  [Keys.UTILITY_CHECK]: "Gwe",
  [Keys.LONG_TERM_DEBT_CHECK]: "Interest long term debts",
  [Keys.LEASE_DEBT_CHECK]: "Lease debt",
  [Keys.VEHICLE_TAX]: "Vehicle tax",
  [Keys.LEASE_CAR]: "Car lease",
  [Keys.LIABILITY_INSURANCE]: "Liability Insurance",
  [Keys.SICKNESS_INSURANCE]: "Sickness Insurance",
  [Keys.home]: "Home",
  [Keys.receivablesDashboardDescription]:
    "View receivables and export to Excel",
};

export default config;
