import client from "../../utils/axiosClient";

export type UserResponse = {
  id: string;
  email: string;
  Role: Role;
};
export type Role = "basic" | "admin" | "super";

export const getMe = async () => {
  const response = await client.get<UserResponse>(`/api/users/me`, {});
  return response.data;
};
