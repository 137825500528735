import { VariantProp } from '@mui/joy';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';

import { ReactNode } from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Box from '@mui/joy/Box';

export const TitleSubtitleCard = (props: { variant: VariantProp; title: string; subtitle: string }) => {
  return (
    <Card variant={props.variant}>
      <CardContent>
        <Typography level="title-md">{props.title}</Typography>
        <Typography>{props.subtitle}</Typography>
      </CardContent>
    </Card>
  );
};

export const TitleSubtitleCardWithInfo = (props: { variant: VariantProp; title: string; info: string; subtitle: string }) => {
  return (
    <Card variant={props.variant}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography level="title-md">{props.title}</Typography>
          <Tooltip title={props.info}>
            <IconButton size="small" sx={{ padding: 0 }}>
              <InfoOutlined fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        <Typography>{props.subtitle}</Typography>
      </CardContent>
    </Card>
  );
};
