import { XCircleIcon } from '@heroicons/react/24/solid';
import { Button, Stack } from '@mui/joy';
import { Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../../../components/spinner';
import { useGLAccountMappingOverview } from '../../../../hooks/glaccounts/useGLAccountMappingOverview';
import { ConsistencyChip } from '../../consistencyChip';

type Props = {
  filterComplete: boolean;
  userId: string | null;
  accountID: string | null;
};

export const ConsistencyTable = (props: Props) => {
  const { filterComplete, userId, accountID } = props;
  const navigate = useNavigate();
  const glAccountMappingOverviewQuery = useGLAccountMappingOverview({
    accountID,
    userId,
  });

  const onRowClick = (glAccountCode: string) => {
    navigate({
      pathname: `/efficiency/consistency/${glAccountCode}`,
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'glAccountCode',
      headerName: 'Grootboek nummer',
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'glAccountCodeCount',
      headerName: 'Aantal grootboekrekeningen',
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'score',
      headerName: 'RGS Consistentie',
      flex: 1,
      renderCell(params) {
        return <ConsistencyChip percentage={params.row.score} />;
      },
      disableColumnMenu: true,
    },
    {
      field: 'custom0',
      headerName: 'Actie',
      renderCell(params) {
        return (
          <Button size="sm" variant="soft" color="neutral" onClick={() => onRowClick(params.row.glAccountCode)}>
            Details
          </Button>
        );
      },
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  const rows = useMemo(() => {
    if (!glAccountMappingOverviewQuery.data) return [];
    return glAccountMappingOverviewQuery.data
      .map((item) => {
        return {
          glAccountCode: item.glAccountCode,
          glAccountCodeCount: item.glAccountCodeCount,
          score: item.score,
        };
      })
      .filter((item) => {
        if (filterComplete) {
          return item.score !== 100;
        }
        return true;
      });
  }, [glAccountMappingOverviewQuery.data, filterComplete]);

  if (!glAccountMappingOverviewQuery.isSuccess) {
    return <Spinner />;
  }

  if (!rows.length) {
    return (
      <Stack alignItems={'center'} direction={'column'} sx={{ my: '8rem' }} gap={1}>
        <XCircleIcon className="w-32 h-32 text-warning text-center" />
        <Typography>Geen RGS mapping data gevonden</Typography>
      </Stack>
    );
  }

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 12 },
        },
        sorting: {
          sortModel: [
            {
              field: 'glAccountCodeCount',
              sort: 'desc',
            },
          ],
        },
      }}
      getRowId={(row) => `${row.glAccountCode} ${row.glAccountCodeCount}`}
    />
  );
};
