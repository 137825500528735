import { Box } from '@mui/joy';
import Stack from '@mui/joy/Stack';
import { useParams } from 'react-router-dom';
import { PageHeader } from '../../components/pageHeader';
import { RangeSelect } from '../../components/rangeSelect';
import Spinner from '../../components/spinner';
import useDivision from '../../hooks/useDivision';
import useFilterStore from '../../stores/filter';
import { InvalidVatTransactionTable } from './table';

type Props = {
  renderHeader?: boolean;
};

export const InvalidVatDrilldown = ({ renderHeader = true }: Props) => {
  const params = useParams();
  const { administrationId: administrationIdString } = params;
  const administrationId = administrationIdString as string;
  const divisionQuery = useDivision(administrationId);
  const { range } = useFilterStore();

  if (!divisionQuery.isSuccess) {
    return <Spinner />;
  }

  return (
    <Stack direction="column" gap={3}>
      {renderHeader && <PageHeader>{`${divisionQuery.data?.Description} - BTW attentie punten`}</PageHeader>}

      <Stack direction={'row'} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Stack direction={'row'} gap={3}>
          <RangeSelect />

          <Box marginRight="1rem"></Box>
        </Stack>
      </Stack>

      <InvalidVatTransactionTable
        administrationId={administrationId}
        monthEnd={range.monthEnd}
        monthStart={range.monthStart}
        yearStart={range.yearStart}
        yearEnd={range.yearEnd}
      />
    </Stack>
  );
};
