import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Spinner from "../../components/spinner";
import useOutstandingReceivables from "../../hooks/useOutstandingReceivables";
import { ArchiveBoxArrowDownIcon } from "@heroicons/react/24/solid";
import * as XLSX from "xlsx";

type Props = {};

const OutstandingReceivablesTable = (props: Props) => {
  const outstandingReceivablesQuery = useOutstandingReceivables();

  if (!outstandingReceivablesQuery.isSuccess) {
    return <Spinner />;
  }

  const exportToExcel = (data: any) => {
    // Define the columns to include
    const columnsToInclude = [
      "AmountDC",
      "BankAccountNumber",
      "Description",
      "DueDate",
      "InvoiceNumber",
    ];

    // Filter and transform the JSON array
    const filteredData = data.map((item: any) => {
      const filteredItem: any = {};
      columnsToInclude.forEach((column) => {
        if (item.hasOwnProperty(column)) {
          filteredItem[column] = item[column];
        }
      });
      return filteredItem;
    });

    // Convert JSON data to worksheet
    const worksheet = XLSX.utils.json_to_sheet(filteredData);

    // Create a workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

    // Generate Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelData = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const excelUrl = URL.createObjectURL(excelData);

    // Create a download link and click it programmatically
    const downloadLink = document.createElement("a");
    downloadLink.href = excelUrl;
    downloadLink.download = "output.xlsx";
    downloadLink.click();
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Administratie",
      headerClassName: "font-sans bg-background font-semibold bg-opacity-100",
      flex: 1,
      valueGetter: (params) => {
        const { Hid, Description } = params.row.division;
        return `${Hid} - ${Description}`;
      },
    },
    {
      field: "pending",
      valueGetter: (params) => params.row.receivables.length,
      headerClassName: "font-sans bg-background font-semibold bg-opacity-100",
      headerName: "Openstaande verkoopfacturen ouder dan 2 maanden",
      flex: 1,
    },
    {
      field: "custom-01",
      headerClassName: "font-sans bg-background font-semibold bg-opacity-100",
      headerName: "",
      flex: 0.1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <button onClick={() => exportToExcel(params.row.receivables)}>
            <ArchiveBoxArrowDownIcon className="w-5 text-primary" />
          </button>
        );
      },
    },
  ];

  return (
    <DataGrid
      rows={outstandingReceivablesQuery.data}
      columns={columns}
      getRowId={(params) => params.division.Code}
      initialState={{
        pagination: {
          paginationModel: { pageSize: 10 },
        },
        sorting: {
          sortModel: [{ field: "pending", sort: "desc" }],
        },
      }}
      pageSizeOptions={[10, 15, 20]}
    />
  );
};

export default OutstandingReceivablesTable;
