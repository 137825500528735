import Keys from "./keys";

const config = {
  [Keys.logout]: "Log uit",
  [Keys.profile]: "Profiel",
  [Keys.dashboards]: "Dashboards",
  [Keys.pages]: "Paginas",
  [Keys.organization]: "Organisatie",
  [Keys.billing]: "Facturatie",
  [Keys.welcomeBack]: "Welkom terug",
  [Keys.lastDatabaseUpdate]: "Laatste database update",
  [Keys.kiaAdvice]: "Advies",
  [Keys.kiaAdviceDescription]: ` Inzicht in welke administraties gebruik kunnen maken van de KIA regeling, hoeveel ze gebruik kunnen van de regeling, en wat er nog mogelijk is.`,
  [Keys.view]: "Inzien",
  [Keys.completenessCheck]: "Kwaliteit administratie(s)",
  [Keys.completenessCheckDescription]:
    "Inzicht in de volledigheid van administraties. Bekijk per maand welke posten er geboekt zijn.",
  [Keys.standardFinancial]: "Standaard financieel",
  [Keys.standardFinancialDescription]:
    "A basic financial dashboard of an organization's financial health and performance.",
  [Keys.administrations]: "Administraties",
  [Keys.administration]: "Administratie",
  [Keys.users]: "Gebruikers",
  [Keys.divisions]: "Divisies",
  [Keys.totalInvestments]: "Totale investeringen",
  [Keys.totalPossibleRebate]: "Totale Potentiële aftrek",
  [Keys.generatedAdvice]: "Advies gegenereerd",
  [Keys.investement]: "Investering",
  [Keys.rebate]: "Aftrek",
  [Keys.currentKiaTier]: "Huidige trap",
  [Keys.advice]: "Advies",
  [Keys.details]: "Details",
  [Keys.back]: "Terug",
  [Keys.amount]: "Bedrag",
  [Keys.description]: "Omschrijving",
  [Keys.year]: "Jaar",
  [Keys.completeness]: "Kwaliteit",
  [Keys.percentage]: "Percentage",
  [Keys.overview]: "Overzicht",
  [Keys.profileDescription]: "Account data en instellingen",
  [Keys.language]: "Taal",
  [Keys.toggleComplete]: "Filter compleet",
  [Keys.months]: "Maanden",
  [Keys.quarter]: "Kwartaal",
  [Keys.clearFilters]: "Alles",
  [Keys.authorize]: "Autoriseer",
  [Keys.postActionPoints]: "Actiepunten doorsturen",
  [Keys.dataLoads]: "Data",
  [Keys.runStart]: "Begin verlading",
  [Keys.runEnd]: "Eind verlading",
  [Keys.status]: "Status",
  [Keys.sources]: "Bronnen",
  [Keys.insights]: "Inzicht",
  [Keys.code]: "Code",
  [Keys.date]: "Datum",
  [Keys.category]: "Categorie",
  [Keys.administrationsWithInvestments]: "Administraties met investeringen",
  [Keys.DEPRECIATION]: "Afschrijvingen geboekt",
  [Keys.PAYROLL_JOURNEY_ENTRY]: "Lonen geboekt",
  [Keys.MANAGEMENT_FEE]: "Management fee",
  [Keys.RENTAL_COSTS]: "Huurkosten geboekt",
  [Keys.AmountUnspecified]: "Geen vraagposten",
  [Keys.CashBalance]: "Cash balans",
  [Keys.LastTransactionDate]: "Laatste transactiedatum",
  [Keys.check]: "Check",
  [Keys.yearComplete]: "Jaar compleet",
  [Keys.toExact]: "Link naar Exact",
  [Keys.generalLedgerAccountDescription]: "Grootboek beschrijving",
  [Keys.generalLedgerAccountCode]: "Grootboeknummer",
  [Keys.PHONE_CHECK]: "Telefoonkosten",
  [Keys.RECEIVABLES]: "Openstaande verkoopfacturen",
  [Keys.FILING]: "Dossiervorming",
  [Keys.debtor]: "Debiteur",
  [Keys.UTILITY_CHECK]: "Gwe",
  [Keys.LONG_TERM_DEBT_CHECK]: "Rente langlopende leningen",
  [Keys.LEASE_DEBT_CHECK]: "Lease krediet",
  [Keys.VEHICLE_TAX]: "Motorrijtuigenbelasting",
  [Keys.LEASE_CAR]: "Auto lease",
  [Keys.LIABILITY_INSURANCE]: "Bedrijfsaansprakelijkheidsverzekering",
  [Keys.home]: "Home",
  [Keys.SICKNESS_INSURANCE]: "Ziekengeldverzekering",
  [Keys.receivablesDashboardDescription]:
    "Bekijk en exporteer openstaande verkoopfacturen",
};

export default config;
