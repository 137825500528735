import client from "../../utils/axiosClient";

export const sendResultsEmail = async (
  administrationId: string,
  year: number,
  month: number,
  emailTo: string
) => {
  const response = await client.post(
    `/api/email/${administrationId}/results`,
    {
      year,
      month,
      emailTo,
    },
    {}
  );
  return response.data.data;
};
