import client from "../../utils/axiosClient";

const createQualityCheck = async (
  label: string,
  rgsCode: string,
  applyGlobally: boolean
) => {
  const response = await client.post(`/api/completeness/checks`, {
    label,
    rgsCode,
    applyGlobally,
  });
  return response.data;
};

export default createQualityCheck;
