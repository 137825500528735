import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import { Dropdown, IconButton, Menu, MenuButton, MenuItem } from '@mui/joy';
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Table from '@mui/joy/Table';
import { Pagination } from '@mui/material';
import { useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { ConfirmationDialog } from '../../components/confirmationDialog';
import Spinner from '../../components/spinner';
import { useMe } from '../../hooks/useMe';
import { useUsers } from '../../hooks/useUsers';
import { deleteDomainUser } from '../../services/api/domainUsers/deleteDomainUser';
import { sendNxxtEmailBulk } from '../../services/api/sendNxxtEmailBulk';
import { sendQualityMail } from '../../services/api/sendQualityMail';
import useDomainStore from '../../stores/domain';
import { NXXT_DOMAIN } from '../administration/actions';
import { QualityProgressBar } from '../completeness/progress';

const PAGE_SIZE = 12;

export const UserTable = ({ onClickUser }: { onClickUser: (userID: string) => void }) => {
  const usersQuery = useUsers();
  const [page, setPage] = useState(0);
  const userQuery = useMe();
  const { domain } = useDomainStore();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const isAdmin = userQuery.data?.Role === 'super' || userQuery.data?.Role === 'admin';
  const [selectedUserId, setSelectedUserId] = useState('');

  const onClickSendQualityMail = async (userId: string, mailto: string) => {
    await sendQualityMail(userId, mailto);
  };

  if (!usersQuery.isSuccess) {
    return <Spinner />;
  }

  const rows = usersQuery.data || [];

  return (
    <>
      <ConfirmationDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={async () => {
          await deleteDomainUser({
            domainID: domain,
            userID: selectedUserId,
          });
          await usersQuery.refetch();
          setDeleteDialogOpen(false);
        }}
        title="Gebruiker verwijderen"
        content="Weet je zeker dat je deze gebruiker wilt verwijderen?"
      />
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
          minHeight: 0,
          maxHeight: '80vh',
        }}
      >
        <Table
          stickyHeader
          stickyFooter
          hoverRow
          sx={{
            '--TableCell-height': '10px',
            '& tr > *:last-child': {
              textAlign: 'right',
              paddingLeft: '1rem',
            },
          }}
        >
          <thead>
            <tr>
              <th>Naam</th>
              <th>Administraties</th>
              <th>Administratie score</th>
              <th>Acties</th>
            </tr>
          </thead>
          <tbody>
            {rows.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE).map((row) => {
              return (
                <tr key={row.id}>
                  <td>{row.email}</td>
                  <td>{row.administrations?.length}</td>
                  <td>
                    <QualityProgressBar progress={0} />
                  </td>
                  <td>
                    <Dropdown>
                      <MenuButton
                        slots={{ root: IconButton }}
                        slotProps={{
                          root: { variant: 'soft', color: 'neutral' },
                        }}
                      >
                        <MoreVertRoundedIcon />
                      </MenuButton>
                      <Menu>
                        <MenuItem
                          variant="plain"
                          disabled={!isAdmin}
                          onClick={() => {
                            if (selectedUserId === row.id) {
                              setSelectedUserId('');
                              return;
                            }
                            setSelectedUserId(row.id);
                            onClickUser(row.id);
                          }}
                        >
                          Administraties
                        </MenuItem>
                        <MenuItem
                          variant="plain"
                          // startDecorator={<EmailRoundedIcon />}
                          onClick={async () => {
                            await onClickSendQualityMail(row.id, row.email);
                          }}
                        >
                          Quality Mail Versturen
                        </MenuItem>
                        {domain === NXXT_DOMAIN && (
                          <MenuItem
                            variant="plain"
                            onClick={async () => {
                              await sendNxxtEmailBulk(new Date().getFullYear(), row.email);
                            }}
                          >
                            NXXT Mail Versturen
                          </MenuItem>
                        )}
                        {isAdmin && (
                          <MenuItem
                            variant="plain"
                            color="danger"
                            onClick={async () => {
                              setSelectedUserId(row.id);
                              setDeleteDialogOpen(true);
                            }}
                          >
                            Gebruiker verwijderen
                          </MenuItem>
                        )}
                      </Menu>
                    </Dropdown>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Sheet>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Pagination count={Math.ceil(rows.length / PAGE_SIZE)} color="primary" onChange={(_, page) => setPage(page - 1)} page={page + 1} />
      </Box>
    </>
  );
};
