import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { IconButton, Sheet, Table } from "@mui/joy";
import { useState } from "react";
import { TGLAccountMappingDrilldownRow } from "../../services/api/glaccounts/getGLAccountMappingDrilldown";
import { getRgsCode } from "../../assets/rgsCodes";

const GLAccountMappingDrilldownRow = (props: TGLAccountMappingDrilldownRow) => {
  const [open, setOpen] = useState(false);
  const rgsCodeData = getRgsCode(props.rgsCode);

  return (
    <>
      <tr key={props.glAccountCode}>
        <td>
          <IconButton
            aria-label="expand row"
            variant="plain"
            color="neutral"
            size="sm"
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? (
              <ChevronUpIcon className="w-5" />
            ) : (
              <ChevronDownIcon className="w-5" />
            )}
          </IconButton>
        </td>
        <td>{props.glAccountCode}</td>
        <td>{props.rgsCode}</td>
        <td>{props.administrations.length} Administraties</td>
        <td>{rgsCodeData ? rgsCodeData.descriptionShort : ''}</td>
      </tr>
      <tr>
        <td style={{ height: 0, padding: 0 }} colSpan={5}>
          {open && (
            <Sheet
              className="OrderTableContainer"
              variant="soft"
              sx={{
                p: 1,
                pl: 6,
                boxShadow: "inset 0 3px 6px 0 rgba(0 0 0 / 0.08)",
              }}
            >
              <Table
                borderAxis="bothBetween"
                size="sm"
                aria-label="purchases"
                sx={{
                  "& > thead > tr > th:nth-child(n + 3), & > tbody > tr > td:nth-child(n + 3)":
                    { textAlign: "right" },
                  "--TableCell-paddingX": "0.5rem",
                }}
              >
                <thead>
                  <tr>
                    <th>Administratie</th>
                    <th>Grootboek beschrijving</th>
                  </tr>
                </thead>
                <tbody>
                  {props.administrations.slice(0, 16).map((administration) => (
                    <tr key={administration.administrationId}>
                      <th scope="row">{administration.name}</th>
                      <th scope="row">{administration.description}</th>
                    </tr>
                  ))}
                  {props.administrations.length > 16 && (
                    <tr>
                      <td
                        colSpan={1}
                      >{`16 van de ${props.administrations.length} worden weergegeven`}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Sheet>
          )}
        </td>
      </tr>
    </>
  );
};

export default GLAccountMappingDrilldownRow;
