import { Sheet, Table } from '@mui/joy';
import { useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { ConfirmationDialog } from '../../components/confirmationDialog';
import Spinner from '../../components/spinner';
import { useTasks } from '../../hooks/tasks/useTasks';
import { deleteTask } from '../../services/api/tasks/deleteTask';
import { Task } from '../../services/api/tasks/getTasks';
import { patchTask as patchTaskService } from '../../services/api/tasks/patchTask';
import { useTaskStore } from '../../stores/taskStore';
import { TasksTableRow } from './taskTableRow';

export const TaskTable = () => {
  const tasksQuery = useTasks();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { selectedTask, setSelectedTask, setTaskModalOpen, loading, setLoading } = useTaskStore();

  const promptConfirmDelete = (task: Task) => {
    setSelectedTask(task);
    setDeleteModalOpen(true);
  };

  const cancelDelete = () => {
    setDeleteModalOpen(false);
    setSelectedTask(null);
  };

  const openEditModal = (task: Task) => {
    setSelectedTask(task);
    setTaskModalOpen(true);
  };

  const patchTask = async (task: Task) => {
    try {
      if (loading) {
        return;
      }
      setLoading(true);
      await patchTaskService(task);
      await tasksQuery.refetch();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const confirmDelete = async () => {
    try {
      if (!selectedTask) {
        return;
      }
      setLoading(true);
      await deleteTask(selectedTask.taskID);
      await tasksQuery.refetch();
      setDeleteModalOpen(false);
      setSelectedTask(null);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  if (!tasksQuery.isSuccess) {
    return <Spinner />;
  }

  // Apply user filter
  const tasks = tasksQuery.data || [];
  const filteredTasks = tasks.filter((task) => {
    return true;
    //     if (filterStore.user) {
    //   return task.assignedUserID === filterStore.user;
    // }
    // return true;
  });

  return (
    <>
      <ConfirmationDialog
        content="Weet je zeker dat je deze taak wilt verwijderen?"
        onClose={cancelDelete}
        onConfirm={confirmDelete}
        open={deleteModalOpen}
        title="Taak verwijderen"
        disabled={loading}
      />
      <Sheet>
        <Table>
          <thead>
            <tr>
              <th style={{ width: 40 }} aria-label="empty" />
              <th style={{ width: 40 }} aria-label="empty" />
              <th>Titel</th>
              <th>Status</th>
              <th>Uitvoerder</th>
              <th>Aangemaakt</th>
              <th>Deadline</th>
              <th style={{ width: 40 }} aria-label="empty" />
              <th style={{ width: 60 }} aria-label="empty" />
            </tr>
          </thead>
          <tbody>
            {filteredTasks.map((task) => {
              return <TasksTableRow disabled={loading} task={task} onDelete={promptConfirmDelete} patchTask={patchTask} openEditModal={openEditModal} />;
            })}
          </tbody>
        </Table>
      </Sheet>
    </>
  );
};
