import client from '../../../utils/axiosClient';

export type EOLIntegrationTaskOverview = {
  administration: string;
  description?: string;
  status?: number;
  pendingTasks: number;
  failedTasks: number;
  lastTaskCreated: number;
  lastTaskCompleted: number;
  totalTasks: number;
  completedTasks: number;
};

export type EOLIntegrationTaskOverviewParams = {};

export const getEOLIntegrationTaskOverview = async (params?: EOLIntegrationTaskOverviewParams) => {
  const response = await client.get<EOLIntegrationTaskOverview[]>('/api/eol-integration/tasks', {
    params,
  });
  return response.data;
};
