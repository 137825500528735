import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import useDomains from "../../hooks/useDomains";
import useDomainStore from "../../stores/domain";
import { useNavigate } from "react-router-dom";
import queryClient from "../../utils/queryClient";
import useFilterStore from "../../stores/filter";

export default function DomainSelect() {
  const domainsQuery = useDomains();
  const { domain, setDomain } = useDomainStore();
  const navigate = useNavigate();
  const filterStore = useFilterStore();

  const domains = domainsQuery.data || [];
  const options = domains.map((domain) => {
    return {
      key: domain.domainId,
      label: domain.title,
    };
  });

  const onSelectDomain = async (id: string) => {
    const newBaseURL = domains.find(
      (domain) => domain.domainId === id
    )?.domainId;

    if (!newBaseURL) {
      throw new Error(`User selected domain with no valid base URL`);
    }

    setDomain(newBaseURL);
    filterStore.reset();
    navigate("/");
    queryClient.clear();
  };

  return (
    <Select
      disabled={domains.length <= 1}
      onChange={(_, domainId) => {
        if (!domainId) return;
        onSelectDomain(domainId);
      }}
      value={domain}
    >
      {options.map((option) => {
        return (
          <Option key={option.key} value={option.key}>
            {option.label}
          </Option>
        );
      })}
    </Select>
  );
}
