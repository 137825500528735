import { Autocomplete, Button, Divider, FormControl, FormLabel, Grid, Radio, RadioGroup, Stack, Typography } from '@mui/joy';
import Input from '@mui/joy/Input';
import { useState } from 'react';
import { Check } from '.';
import { DivisionSelect } from '../../../components/divisionSelect';
import { RgsFilters, RgsSelect } from '../../../components/rgsSelectv2';
import useDivisions from '../../../hooks/useDivisions';
import { TPostBalanceCheck, postBalanceCheck } from '../../../services/api/insights/postBalanceCheck';
import { TPostKpiCheck, postKpiChecks } from '../../../services/api/insights/postKpiCheck';
import { BalanceCheckSubForm } from './balanceCheckSubForm';

type Props = {
  cancel: () => void;
  selectedCheck: Check | null;
};

export default function Form({ cancel, selectedCheck }: Props) {
  const [balanceChecks, setBalanceChecks] = useState<Partial<TPostBalanceCheck>[]>([]);
  const [administrationId, setAdministrationId] = useState<string>('');
  const [rgsCode, setRgsCode] = useState<string>('');
  const [applyGlobally, setApplyGlobally] = useState<boolean>(false);
  const [operator, setOperator] = useState<string>('');
  const [kpi, setKpi] = useState<string>('');
  const [checkType, setCheckType] = useState<'kpi' | 'balance'>('balance');
  const [amount, setAmount] = useState<number>(0);
  const divisionsQuery = useDivisions();
  const [loading, setLoading] = useState(false);

  const handleBalanceCheckChange = (balanceChecks: Partial<TPostBalanceCheck>[]) => {
    const newBalanceChecks: Partial<TPostBalanceCheck>[] = balanceChecks.map((check) => {
      return {
        lowerBound: check.lowerBound,
        upperBound: check.upperBound,
        label: check.label,
        icon: check.icon,
      };
    });
    setBalanceChecks(newBalanceChecks);
  };

  const handlePostBalanceChecks = async () => {
    setLoading(true);
    const checksToPost: TPostBalanceCheck[] = [];

    if (applyGlobally) {
      if (!divisionsQuery.isSuccess) return;
      const administrations = divisionsQuery.data;

      // For each administration, post the balance checks
      administrations.forEach((administration) => {
        balanceChecks.forEach((check) => {
          checksToPost.push({
            lowerBound: check.lowerBound!,
            upperBound: check.upperBound!,
            label: check.label!,
            icon: check.icon!,
            administrationId: administration.Code.toString(),
            rgsCode,
          });
        });
      });
      await postBalanceCheck(checksToPost);
      setLoading(false);
      cancel();
      return;
    }

    if (!applyGlobally) {
      // Post the balance checks for the selected administration
      balanceChecks.forEach((check) => {
        checksToPost.push({
          lowerBound: check.lowerBound!,
          upperBound: check.upperBound!,
          icon: check.icon!,
          label: check.label!,
          administrationId,
          rgsCode,
        });
      });
      await postBalanceCheck(checksToPost);
      setLoading(false);
      cancel();
      return;
    }
  };

  const handlePostKpiChecks = async () => {
    const checksToPost: TPostKpiCheck[] = [];

    if (applyGlobally) {
      if (!divisionsQuery.isSuccess) return;
      const administrations = divisionsQuery.data;

      // For each administration, post the balance checks
      administrations.forEach((administration) => {
        checksToPost.push({
          administrationId: administration.Code.toString(),
          kpi: kpi,
          operator: operator,
          amount: amount,
        });
      });
      await postKpiChecks(checksToPost);
    }

    if (!applyGlobally) {
      // Post the balance checks for the selected administration
      checksToPost.push({
        kpi: kpi,
        operator: operator,
        amount: amount,
        administrationId,
      });
      await postKpiChecks(checksToPost);
    }
  };

  const confirm = async () => {
    if (checkType === 'balance') {
      await handlePostBalanceChecks();
      return;
    }

    if (checkType === 'kpi') {
      await handlePostKpiChecks();
      return;
    }

    throw new Error(`Unknown check type: ${checkType}`);
  };

  const disabled = loading;

  return (
    <Stack direction={'column'} gap={5}>
      <Typography component="h2" id="close-modal-title" level="h4" textColor="inherit" fontWeight="lg">
        Inzicht check aanmaken
      </Typography>

      <Grid container spacing={3} sx={{ flexGrow: 1 }}>
        <Grid xs={6}>
          <Stack direction={'column'} gap={3}>
            <FormControl>
              <FormLabel>Toepassen op</FormLabel>
              <RadioGroup
                defaultValue="specific"
                name="radio-buttons-group"
                onChange={(event) => {
                  switch (event.target.value) {
                    case 'specific':
                      setApplyGlobally(false);
                      break;
                    case 'global':
                      setApplyGlobally(true);
                      break;
                  }
                }}
              >
                <Radio value="specific" label="Specifieke administratie" variant="soft" />
                <Radio value="global" label="Alle administraties" variant="soft" />
              </RadioGroup>
            </FormControl>
          </Stack>
        </Grid>
        <Grid xs={6}>
          {!applyGlobally && (
            <DivisionSelect
              onChange={(division) => {
                setAdministrationId(division);
              }}
              disabled={applyGlobally}
            />
          )}
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ flexGrow: 1 }}>
        <Grid xs={6}>
          <FormControl>
            <FormLabel>Check type</FormLabel>
            <RadioGroup
              defaultValue="balance"
              name="radio-buttons-group"
              onChange={(event) => {
                switch (event.target.value) {
                  case 'balance':
                    setCheckType('balance');
                    break;
                  case 'kpi':
                    setCheckType('kpi');
                    break;
                }
              }}
            >
              <Radio value="balance" label="RGS Code" variant="soft" />
              <Radio value="kpi" label="Kengetal" variant="soft" />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid xs={6}>
          <Stack direction={'column'} gap={1}>
            {checkType === 'balance' && (
              <RgsSelect
                key={'rgs-select'}
                onChange={(rgsCode) => {
                  setRgsCode(rgsCode);
                }}
                value={rgsCode}
                type={RgsFilters.RGS4}
              />
            )}
            {checkType === 'kpi' && (
              <FormControl>
                <FormLabel>Kengetal</FormLabel>
                <Autocomplete
                  key="kpi-select"
                  placeholder={'Selecteer een kpi'}
                  onChange={(_, value) => {
                    if (!value) {
                      setKpi('');
                      return;
                    }
                    setKpi(value);
                  }}
                  options={['cashRatio', 'quickRatio', 'currentRatio']}
                  value={kpi}
                />
              </FormControl>
            )}
          </Stack>
        </Grid>
      </Grid>

      <Divider />

      {/* KPI check form fields */}
      {checkType === 'kpi' && (
        <Grid container spacing={3}>
          {/* Operator */}
          <Grid xs={6}>
            <FormControl>
              <FormLabel>Operator</FormLabel>
              <Autocomplete
                placeholder={'Selecteer operator'}
                onChange={(_, value) => {
                  setOperator(value.key);
                }}
                value={operator as any}
                options={[
                  {
                    key: '<',
                    label: '<',
                  },
                  {
                    key: '>',
                    label: '>',
                  },
                  {
                    key: '=',
                    label: '=',
                  },
                  {
                    key: '!=',
                    label: '!=',
                  },
                ]}
              ></Autocomplete>
            </FormControl>
          </Grid>

          {/* Treshold */}
          <Grid xs={6}>
            <FormControl>
              <FormLabel>Trigger waarde</FormLabel>
              <Input
                onChange={(e) => {
                  setAmount(e.target.value as any);
                }}
                value={amount}
                placeholder="Bijv. -500"
                type="number"
              />
            </FormControl>
          </Grid>
        </Grid>
      )}

      {/* Balance check form fields */}
      {checkType === 'balance' && <BalanceCheckSubForm onChange={handleBalanceCheckChange} />}

      <Stack direction={'row'} justifyContent={'space-between'}>
        <Button onClick={cancel} color="neutral" variant="outlined" disabled={disabled}>
          Annuleren
        </Button>
        <Button onClick={confirm} disabled={disabled}>
          {selectedCheck ? 'Bijwerken' : 'Toevoegen'}
        </Button>
      </Stack>
    </Stack>
  );
}
