import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { abbreviateNumber } from "js-abbreviation-number";
import { useTranslation } from "react-i18next";
import DivisionSearch from "../../components/divisionSearch";
import { PageHeader } from "../../components/pageHeader";
import { UserSelect } from "../../components/userSelect";
import { YearSelect } from "../../components/yearSelect";
import useKiaAdvice from "../../hooks/useKiaAdvice";
import useFilterStore from "../../stores/filter";
import TranslationKeys from "../../translations/keys";
import { IndicatorEnum } from "../../types/types";
import AdviceTable from "./AdviceTable";

export default function Kia() {
  const { t } = useTranslation();
  const {
    year,
    setYear,
    options: yearOptions,
    divisionSearch,
    user,
  } = useFilterStore();
  const kiaQuery = useKiaAdvice(year, user);

  const kiaData = kiaQuery.data || [];

  const cards = [
    {
      title: `€${abbreviateNumber(
        kiaData.reduce(
          (acc: any, curr: any) =>
            acc + Number.parseFloat(`${curr.totalInvestment}`),
          0
        )
      )}`,
      subtitle: t(TranslationKeys.totalInvestments),
      indicator: IndicatorEnum.green,
    },
    {
      title: `€${abbreviateNumber(
        kiaData.reduce(
          (acc: any, curr: any) => acc + Number.parseFloat(`${curr.rebate}`),
          0
        )
      )}`,
      subtitle: t(TranslationKeys.totalPossibleRebate),
      indicator: IndicatorEnum.green,
    },
    {
      title: abbreviateNumber(kiaData.length),
      subtitle: t(TranslationKeys.generatedAdvice),
      indicator: IndicatorEnum.neutral,
    },
  ];

  return (
    <Stack direction="column" gap={3}>
      <PageHeader>KIA Advies</PageHeader>

      <Stack direction={"column"} gap={3}>
        {/* Filters */}
        <Stack direction={"row"} gap={3}>
          <DivisionSearch />

          <UserSelect />

          <YearSelect />
        </Stack>

        {/* Cards */}
        <Stack direction="row" gap={3} sx={{ width: "100%", flexWrap: "wrap" }}>
          {/* Ensure full width and wrap cards if necessary */}
          {cards.map((card) => (
            <Card
              key={`${card.title}-${card.subtitle}-${card.indicator}`}
              sx={{ flex: "1", padding: "1rem 2rem", minWidth: "240px" }} // Adjusted for equal space and minimum width
            >
              <CardContent>
                <Typography level="h3">{card.title}</Typography>
                <Typography level="body-md">{card.subtitle}</Typography>
              </CardContent>
            </Card>
          ))}
        </Stack>

        {/* Table */}
        <AdviceTable
          year={year}
          divisionSearch={divisionSearch}
          userId={user}
        />
      </Stack>
    </Stack>
  );
}
