import { useQuery } from "@tanstack/react-query";
import getProfitLossPerformance, {
  TProfitLossResponse,
} from "../services/api/getProfitLossPerformance";
import queryTypes from "../types/hookTypes";

export const useProfitLossPerformance = (
  administration: string,
  year: number,
  period: number
) => {
  return useQuery<TProfitLossResponse>(
    [queryTypes.profitLossPerformance, administration, year, period],
    async () => {
      if (!administration || !administration.length) return [];
      const res = await getProfitLossPerformance(administration, year, period);
      return res;
    }
  );
};
