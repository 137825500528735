import { format } from "date-fns";
import React from "react";
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Spinner from "../../components/dynamicSpinner";

const LineChartComponent: React.FC<{
  data: Array<{
    index: any;
    value: any;
  }>;
  loading: boolean;
  label: string;
}> = ({ data, label, loading }) => {
  if (loading) {
    return <Spinner />;
  }

  const formatter = new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
  });

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart data={data} margin={{ left: 50 }}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#129a74" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#129a74" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          axisLine={false}
          dataKey="index"
          tickFormatter={(e) => {
            return format(new Date(e), "MMM");
          }}
        />
        <YAxis axisLine={false} tickFormatter={(e) => formatter.format(e)} />
        <Tooltip
          labelFormatter={(e) => {
            return format(new Date(e), "MMMM YYY");
          }}
          formatter={(e) => {
            return formatter.format(e as number);
          }}
        />
        <Area
          type="monotone"
          dataKey="value"
          stroke="#129a74"
          fillOpacity={1}
          strokeWidth={3}
          fill="url(#colorUv)"
          // name={label}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default LineChartComponent;
