import { useQuery } from "@tanstack/react-query";
import { UserResponse, getMe } from "../services/api/getUser";
import queryTypes from "../types/hookTypes";
import domainStore from "../stores/domain";

export const useMe = () => {
  const isInitialized = domainStore.getState().isInitialized;

  return useQuery<UserResponse>([queryTypes.me], () => getMe(), {
    enabled: isInitialized, // The query will only run if isInitialized is true
  });
};
