import client from '../../../utils/axiosClient';

export const deleteBalanceCheckBulk = async (rgsCode: string) => {
  const response = await client.delete(`/api/insights/checks/balance/bulk`, {
    params: {
      rgsCode,
    },
  });
  return response.data;
};
