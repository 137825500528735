import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { Button, IconButton, Stack, Typography } from "@mui/joy";
import Box from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import { Pagination } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useState } from "react";
import useQualityChecks from "../../../hooks/useQualityChecks";
import deleteQualityCheck from "../../../services/api/deleteQualityCheck";
import { Check } from "./index";

const PAGE_SIZE = 15;

type Props = {
  setOpen: (open: boolean) => void;
  goToForm: () => void;
  setselectedCheck: (check: Check | null) => void;
};

export default function QualityChecksOverview({
  setOpen,
  goToForm,
  setselectedCheck,
}: Props) {
  const qualityChecksQuery = useQualityChecks();
  const [page, setPage] = useState(0);

  const deleteCheck = async (checkId: string) => {
    await deleteQualityCheck(checkId);
    qualityChecksQuery.refetch();
  };

  const columns: GridColDef[] = [
    {
      field: "ID",
      headerName: "Label",
      flex: 2,
      headerClassName: "font-sans bg-background font-semibold bg-opacity-100",
      renderCell: (params) => {
        return <p className="font-sans">{params.row.ID}</p>;
      },
    },
    {
      field: "RgsCodes",
      sortable: false,
      headerName: "RGS Codes",
      headerClassName: "font-sans bg-background font-semibold bg-opacity-100",
      renderCell: (params) => {
        return <p className="font-sans">{params.row.RgsCodes}</p>;
      },
      flex: 2,
    },
    {
      field: "custom-01",
      sortable: false,
      headerClassName: "font-sans bg-background font-semibold bg-opacity-100",
      headerName: "",
      flex: 1 / 4,
      align: "center",
      renderCell: (params) => {
        return (
          <PencilIcon
            className="w-5 text-primary "
            onClick={() => {
              setselectedCheck(params.row as Check);
              goToForm();
            }}
          />
        );
      },
    },
    {
      field: "custom-02",
      sortable: false,
      headerClassName: "font-sans bg-background font-semibold bg-opacity-100",
      headerName: "",
      flex: 1 / 4,
      align: "center",
      renderCell: (params) => {
        return (
          <TrashIcon
            className="w-5 text-gray-500 "
            onClick={() => {
              deleteCheck(params.id as string);
            }}
          />
        );
      },
    },
  ];

  const rows = qualityChecksQuery.data || [];

  return (
    <Stack direction={"column"} gap={3} sx={{ marginTop: "3rem" }}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography
          component="h2"
          id="close-modal-title"
          level="h4"
          textColor="inherit"
          fontWeight="lg"
        >
          Checks beheren
        </Typography>
        <Button
          onClick={() => {
            setselectedCheck(null);
            goToForm();
          }}
          className="px-4 py-2 bg-secondary rounded-full text-black font-semibold"
        >
          Check toevoegen
        </Button>
      </Stack>

      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
          maxHeight: "60vh",
        }}
      >
        <Table aria-label="basic table" stickyHeader stickyFooter>
          <thead>
            <tr>
              <th style={{ width: "40%" }}>Label</th>
              <th style={{ width: "40%" }}>RGS code</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {rows.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE).map((row) => {
              return (
                <tr key={row.ID}>
                  <td>{row.ID}</td>
                  <td>{row.RgsCodes}</td>
                  <td>
                    <IconButton
                      onClick={() => {
                        setselectedCheck(row as Check);
                        goToForm();
                      }}
                    >
                      <EditRoundedIcon />
                    </IconButton>
                  </td>
                  <td>
                    <IconButton
                      onClick={() => {
                        deleteCheck(row.ID as string);
                      }}
                    >
                      <DeleteRoundedIcon />
                    </IconButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Sheet>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          count={Math.ceil(rows.length / PAGE_SIZE)}
          color="primary"
          onChange={(_, page) => setPage(page - 1)}
          page={page + 1}
        />
      </Box>
    </Stack>
  );
}
