import { useQuery } from '@tanstack/react-query';
import { GetPaymentTermsAutomationParams, getPaymentTermsAutomation } from '../../services/api/paymentTerms/getJournalAutomation';
import queryTypes from '../../types/hookTypes';

export const usePaymentTermAutomation = (params: GetPaymentTermsAutomationParams) => {
  return useQuery([queryTypes.paymentTermAutomation, ...Object.values(params)], async () => {
    const res = await getPaymentTermsAutomation(params);
    return res;
  });
};
