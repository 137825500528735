import client from "../../../utils/axiosClient";

export type TPostBalanceCheck = {
  administrationId: string;
  rgsCode: string;
  lowerBound: number;
  upperBound: number;
  icon: string;
  label: string;
};

export const postBalanceCheck = async (checks: TPostBalanceCheck[]) => {
  const response = await client.post(`/api/insights/checks/balance`, checks);
  return response.data;
};
