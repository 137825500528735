import { useQuery } from '@tanstack/react-query';
import { getGLAccountEfficiency, GetGLAccountEfficiencyParams } from '../services/api/getGLAccountEfficiency';
import queryTypes from '../types/hookTypes';

const useHook = (params?: GetGLAccountEfficiencyParams) => {
  return useQuery([queryTypes.glAccountEfficiency, params?.accountID], async () => {
    const res = await getGLAccountEfficiency(params);
    return res;
  });
};

export default useHook;
