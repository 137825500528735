import client from "../../utils/axiosClient";

const getQualityCheckConfiguration = async (divisionCode: string) => {
  const response = await client.get(
    `/api/completeness/checks/${divisionCode}/config`,
    {}
  );
  return response.data;
};

export default getQualityCheckConfiguration;
