import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import { IconButton } from "@mui/joy";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import { useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import Spinner from "../../components/spinner";
import { useAssignedAdministrations } from "../../hooks/useAssignedAdministrations";

export const AssignedAdministrationsTable = ({
  userId,
  unassignAdministration,
}: {
  userId: string;
  unassignAdministration: (userID: string, administrationId: string) => void;
}) => {
  const assignedAdministrationsQuery = useAssignedAdministrations(userId);
  const [loading, setLoading] = useState(false);

  if (!assignedAdministrationsQuery.isSuccess) {
    return <Spinner />;
  }

  const rows = assignedAdministrationsQuery.data || [];

  return (
    <Sheet
      className="OrderTableContainer"
      variant="outlined"
      sx={{
        width: "100%",
        borderRadius: "sm",
        flexShrink: 1,
        overflow: "auto",
        minHeight: 0,
        maxHeight: "80vh",
      }}
    >
      <Table
        stickyHeader
        stickyFooter
        sx={{
          "--TableCell-height": "10px",
          "& tr > *:last-child": {
            textAlign: "right",
            paddingLeft: "1rem",
          },
        }}
      >
        <thead>
          <tr>
            <th>Administratie</th>
            <th>Toekennen</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row: any) => {
            return (
              <tr key={row.id}>
                <td>{row.Name}</td>
                <td>
                  <IconButton
                    color="warning"
                    variant="soft"
                    disabled={loading}
                    onClick={async () => {
                      setLoading(true);
                      await unassignAdministration(
                        userId,
                        row.AdministrationID.toString()
                      );
                      setLoading(false);
                    }}
                  >
                    <RemoveCircleRoundedIcon />
                  </IconButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Sheet>
  );
};
