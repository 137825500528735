enum TranslationKeys {
  administrationsWithInvestments = "administrationsWithInvestments",
  date = "date",
  code = "code",
  logout = "logout",
  profile = "profile",
  insights = "insights",
  dashboards = "dashboards",
  administrations = "administrations",
  administration = "administration",
  users = "users",
  divisions = "divisions",
  generalLedgerAccountDescription = "generalLedgerAccount",
  generalLedgerAccountCode = "generalLedgerAccountCode",
  pages = "pages",
  organization = "organization",
  sources = "Connecties",
  billing = "billing",
  welcomeBack = "welcomeBack",
  lastDatabaseUpdate = "lastDatabaseUpdate",
  kiaAdvice = "kiaAdvice",
  kiaAdviceDescription = "kiaAdviceDescription",
  view = "view",
  completenessCheck = "completenessCheck",
  completenessCheckDescription = "completenessCheckDescription",
  standardFinancial = "standardFinancial",
  standardFinancialDescription = "standardFinancialDescription",
  receivablesDashboardDescription = "receivablesDashboardDescription",
  totalInvestments = "totalInvestments",
  totalPossibleRebate = "totalPossibleRebate",
  generatedAdvice = "generatedAdvice",
  investement = "investement",
  rebate = "rebate",
  currentKiaTier = "currentKiaTier",
  advice = "advice",
  details = "details",
  back = "back",
  amount = "amount",
  description = "description",
  year = "year",
  completeness = "completeness",
  percentage = "percentage",
  overview = "overview",
  profileDescription = "profileDescription",
  language = "language",
  toggleComplete = "toggleComplete",
  months = "months",
  quarter = "quarter",
  clearFilters = "clearFilters",
  authorize = "authorize",
  postActionPoints = "postActionPoints",
  dataLoads = "dataLoads",
  runStart = "runStart",
  runEnd = "runEnd",
  status = "status",
  category = "category",
  DEPRECIATION = "DEPRECIATION",
  PAYROLL_JOURNEY_ENTRY = "PAYROLL_JOURNEY_ENTRY",
  MANAGEMENT_FEE = "MANAGEMENT_FEE",
  RENTAL_COSTS = "RENTAL_COSTS",
  PHONE_CHECK = "PHONE_CHECK",
  RECEIVABLES = "RECEIVABLES",
  AmountUnspecified = "AmountUnspecified",
  CashBalance = "CashBalance",
  LastTransactionDate = "LastTransactionDate",
  check = "check",
  yearComplete = "yearComplete",
  toExact = "toExact",
  debtor = "debtor",
  FILING = "FILING",
  UTILITY_CHECK = "UTILITY_CHECK",
  LONG_TERM_DEBT_CHECK = "LONG_TERM_DEBT_CHECK",
  LEASE_DEBT_CHECK = "LEASE_DEBT_CHECK",
  VEHICLE_TAX = "VEHICLE_TAX",
  LEASE_CAR = "LEASE_CAR",
  LIABILITY_INSURANCE = "LIABILITY_INSURANCE",
  SICKNESS_INSURANCE = "SICKNESS_INSURANCE",
  home = "home",
}

export default TranslationKeys;
