import { DataGrid, GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import Spinner from "../../components/spinner";
import useDivision from "../../hooks/useDivision";
import useExactLoadDetails from "../../hooks/useExactLoadDetails";

export default function Sidebar() {
  const params = useParams();
  const { division: divisionIdString } = params;
  const divisionId = divisionIdString as string;
  const divisionQuery = useDivision(divisionId);

  if (!divisionQuery.isSuccess) {
    return <Spinner />;
  }

  const columns: GridColDef[] = [
    {
      field: "endpoint",
      headerName: "Tabel",
      flex: 1,
    },
    {
      field: "modified",
      headerName: "Meest recente datapunt",
      flex: 1,
      valueFormatter: (params) =>
        dayjs(new Date(params.value)).format("DD/MM/YYYY HH:mm"),
    },
  ];

  return (
    <div className="w-full flex-col space-y-8 px-4 lg:px-8 pt-12">
      <section>
        <header className="flex-col justify-between lg:flex">
          <p className="text-xl font-bold text-title">Data updates</p>
        </header>
      </section>

      <main className="flex flex-col space-y-12">
        <section>
          <div className="w-full flex flex-col space-y-8">
            {/* <DataGrid
              rows={exactDetailsQuery.data}
              columns={columns}
              getRowId={(params) => params.modified}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 10 },
                },
              }}
              pageSizeOptions={[10, 15, 20]}
            /> */}
          </div>
        </section>
      </main>
    </div>
  );
}
