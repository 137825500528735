import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import DataSaverOffRoundedIcon from '@mui/icons-material/DataSaverOffRounded';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import { Box, ListItemDecorator, Tab, TabList, TabPanel, Tabs } from '@mui/joy';
import Stack from '@mui/joy/Stack';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from '../../components/pageHeader';
import Spinner from '../../components/spinner';
import useGLAccountEfficiency from '../../hooks/useGLAccountEfficiency';
import { RgsConsistency } from './tabs/consistency';
import { RgsMappingCompleteness } from './tabs/mappingCompleteness';
import { PaymentTermAutomation } from './tabs/paymentTermAutomation';

export const Efficiency = () => {
  const efficiencyQuery = useGLAccountEfficiency();
  const params = useParams();
  const { tab } = params;
  const navigate = useNavigate();

  if (!efficiencyQuery.isSuccess) {
    return <Spinner />;
  }

  const changeTab = (tab: string) => {
    let path = window.location.pathname;
    let pathArray = path.split('/');
    pathArray[pathArray.length - 1] = tab; // Change the last parameter
    const newPath = pathArray.join('/');

    navigate(newPath); // Use { replace: true } if you want to replace the current entry
  };

  return (
    <Stack direction="column" gap={3}>
      <PageHeader>Efficiëntie overzicht</PageHeader>
      <Tabs
        value={tab}
        onChange={(_, newTab) => {
          if (!newTab) return;
          changeTab(newTab as string);
        }}
        sx={{
          borderRadius: 'sm',
          boxShadow: 'sm',
          overflow: 'auto',
        }}
      >
        <TabList sx={{ py: '.5rem' }}>
          <Tab variant="plain" color="neutral" value={'completeness'}>
            <ListItemDecorator>
              <DataSaverOffRoundedIcon />
            </ListItemDecorator>
            RGS volledigheid
          </Tab>
          <Tab variant="plain" color="neutral" value={'consistency'}>
            <ListItemDecorator>
              <CachedRoundedIcon />
            </ListItemDecorator>
            RGS consistentie
          </Tab>
          <Tab variant="plain" color="neutral" value={'Boekingswijze'}>
            <ListItemDecorator>
              <ReceiptRoundedIcon />
            </ListItemDecorator>
            Boekingswijze
          </Tab>
        </TabList>

        <Box sx={{ py: '1rem' }}>
          <TabPanel value="completeness">
            <RgsMappingCompleteness />
          </TabPanel>
          <TabPanel value="consistency">
            <RgsConsistency />
          </TabPanel>
          <TabPanel value="Boekingswijze">
            <PaymentTermAutomation />
          </TabPanel>
        </Box>
      </Tabs>
    </Stack>
  );
};
