import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import {
  Autocomplete,
  Box,
  Divider,
  FormControl,
  IconButton,
  Input,
  Stack,
  Table,
  Typography,
} from "@mui/joy";
import Sheet from "@mui/joy/Sheet";
import { Pagination } from "@mui/material";
import { useState } from "react";
import Spinner from "../../components/spinner";
import { useKpiChecksByAdministration } from "../../hooks/insights/useKpiChecksByAdministration copy";
import useDivision from "../../hooks/useDivision";
import { deleteKpiCheck } from "../../services/api/insights/deleteKpiCheck";
import { TKpiCheck } from "../../services/api/insights/getKpiChecksByAdministration";
import { patchKpiCheck } from "../../services/api/insights/patchKpiCheck";

type Props = {
  administrationId: string;
};

const PAGE_SIZE = 15;

export const KpiCheckEditor = ({ administrationId }: Props) => {
  const [loading, setLoading] = useState(false);
  const insightChecksByAdministrationQuery =
    useKpiChecksByAdministration(administrationId);
  const divisionQuery = useDivision(administrationId);
  const [page, setPage] = useState(0);
  const [kpiChecksToUpdate, setKpiChecksToUpdate] = useState<TKpiCheck[]>([]);

  const updateInsightCheck = async () => {
    setLoading(true);
    for await (const kpiCheck of kpiChecksToUpdate) {
      await patchKpiCheck(kpiCheck);
    }
    await insightChecksByAdministrationQuery.refetch();
    setKpiChecksToUpdate([]);
    setLoading(false);
  };

  const rows = insightChecksByAdministrationQuery.data || [];

  const onClickDelete = async (rgsCode: string) => {
    setLoading(true);
    await deleteKpiCheck(administrationId, rgsCode);
    await insightChecksByAdministrationQuery.refetch();
    setLoading(false);
  };

  const mergeCheckIntoUpdateList = (checkToUpdate: TKpiCheck) => {
    const itemToUpdate = kpiChecksToUpdate.find((item) => {
      return item.kpi === checkToUpdate.kpi;
    });

    if (!itemToUpdate) {
      setKpiChecksToUpdate([...kpiChecksToUpdate, checkToUpdate]);
      return;
    }

    // Update existing row
    const updatedChecks = kpiChecksToUpdate.map((insightCheck) => {
      if (insightCheck.kpi === checkToUpdate.kpi) {
        return checkToUpdate;
      }
      return insightCheck;
    });
    setKpiChecksToUpdate(updatedChecks);
  };

  if (!insightChecksByAdministrationQuery.isSuccess) {
    return <Spinner />;
  }

  return (
    <div key={`balance_check_editor_${administrationId}`}>
      <Typography
        component="h2"
        id="close-modal-title"
        level="h4"
        textColor="inherit"
        fontWeight="lg"
        marginBottom={"1rem"}
      >
        {`Kengetal checks configureren voor ${divisionQuery.data?.Description}`}
      </Typography>

      <Stack direction={"column"} gap={3}>
        <Sheet
          className="OrderTableContainer"
          variant="outlined"
          sx={{
            width: "100%",
            borderRadius: "sm",
            flexShrink: 1,
            overflow: "auto",
            minHeight: 0,
            maxHeight: "80vh",
          }}
        >
          <Table
            stickyHeader
            stickyFooter
            sx={{
              "--TableCell-height": "10px",
              "& tr > *:last-child": {
                textAlign: "right",
                paddingLeft: "1rem",
              },
            }}
          >
            <thead>
              <tr>
                <th>Kengetal</th>
                <th>Operator</th>
                <th>Grens</th>
                <th style={{ width: "8%" }}></th>
              </tr>
            </thead>
            <tbody>
              {rows
                .slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE)
                .map((row) => {
                  const selecedOperator =
                    kpiChecksToUpdate.find((item) => item.kpi === row.kpi)
                      ?.operator || row.operator;

                  let value = kpiChecksToUpdate.find(
                    (item) => item.kpi === row.kpi
                  )?.amount;

                  if (value === undefined) {
                    value = row.amount;
                  }
                  return (
                    <tr key={JSON.stringify(row)}>
                      <td>{row.kpi}</td>
                      <td>
                        {/* Operator */}
                        <FormControl>
                          <Autocomplete
                            variant="soft"
                            placeholder={"Selecteer operator"}
                            onChange={(_, value) => {
                              const newCheck: TKpiCheck = {
                                ...row,
                                operator: value.key,
                              };

                              mergeCheckIntoUpdateList(newCheck);
                            }}
                            disableClearable={true}
                            sx={{ width: "80px" }}
                            isOptionEqualToValue={(option, value) => {
                              return option.key === value.key;
                            }}
                            value={{
                              key: selecedOperator,
                              label: selecedOperator,
                            }}
                            options={[
                              {
                                key: "<",
                                label: "<",
                              },
                              {
                                key: ">",
                                label: ">",
                              },
                              {
                                key: "=",
                                label: "=",
                              },
                              {
                                key: "!=",
                                label: "!=",
                              },
                            ]}
                          ></Autocomplete>
                        </FormControl>
                      </td>
                      <td>
                        <FormControl>
                          <Input
                            disabled={loading}
                            variant="soft"
                            value={value}
                            placeholder="Bijv. -500"
                            type="number"
                            onChange={(e) => {
                              const newCheck: TKpiCheck = {
                                ...row,
                                amount: e.target.value as any,
                              };

                              mergeCheckIntoUpdateList(newCheck);
                            }}
                            endDecorator={
                              <>
                                <Divider orientation="vertical" />
                                <IconButton
                                  onClick={updateInsightCheck}
                                  disabled={false}
                                >
                                  <SaveRoundedIcon />
                                </IconButton>
                              </>
                            }
                          />
                        </FormControl>
                      </td>
                      <td>
                        <IconButton onClick={() => onClickDelete(row.kpi)}>
                          <DeleteRoundedIcon />
                        </IconButton>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Sheet>
      </Stack>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          count={Math.ceil(rows.length / PAGE_SIZE)}
          color="primary"
          onChange={(_, page) => setPage(page - 1)}
          page={page + 1}
        />
      </Box>
    </div>
  );
};
