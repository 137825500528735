import client from '../../../utils/axiosClient';

export interface Task {
  taskID: string;
  title: string;
  description: string | null;
  createdAt: number; // Unix timestamp
  startedAt: number | null; // Unix timestamp
  finishedAt: number | null; // Unix timestamp
  dueDate: number | null; // Unix timestamp
  assignedUserID: string | null;
  parentTaskID: string | null;
  status: number;
  priority: number;
}

export interface TaskWithChildren extends Task {
  children: Task[];
}

export const getTasks = async (): Promise<TaskWithChildren[]> => {
  const response = await client.get<TaskWithChildren[]>(`/api/tasks`);

  return response.data;
};
