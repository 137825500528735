import { Autocomplete } from "@mui/joy";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import { EKenGetallen } from "../pages/dataViz";

export const KenGetalSelect = ({
  onChange,
  disabled = false,
  value,
}: {
  onChange: (kenGetal: EKenGetallen) => void;
  disabled?: boolean;
  value: string;
}) => {
  const options: { key: string; label: string }[] = Object.keys(
    EKenGetallen
  ).map((kenGetal) => {
    return {
      key: kenGetal,
      label: kenGetal,
    };
  });

  const selectedOption = options.find((option) => option.key === value);

  return (
    <FormControl>
      <FormLabel>Kengetal</FormLabel>
      <Autocomplete
        disabled={disabled}
        placeholder={"Selecteer een kengetal"}
        onChange={(_, value) => {
          if (!value) {
            onChange(EKenGetallen.QuickRatio);
            return;
          }
          onChange(value.key as EKenGetallen);
        }}
        options={options}
        value={selectedOption}
      ></Autocomplete>
    </FormControl>
  );
};
