import InfoIcon from '@mui/icons-material/Info';
import { Alert, Box, Button, Chip, ColorPaletteProp, ModalDialog, Stack, Table } from '@mui/joy';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import { Pagination } from '@mui/material';
import { QueryObserverSuccessResult } from '@tanstack/react-query';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { getRgsCode } from '../../assets/rgsCodes';
import Spinner from '../../components/spinner';
import useDivision from '../../hooks/useDivision';
import useQualityCheckConfiguration from '../../hooks/useQualityCheckConfiguration';
import { useRecommendationsByDivision } from '../../hooks/useRecommendationsByDivision';
import createQualityCheck from '../../services/api/createQualityCheck';
import { TRecommendation } from '../../services/api/getRecommendationsByDivision';
import setQualityCheckStatus from '../../services/api/setQualityCheckStatus';

type Props = {
  divisionCode: string;
  setOpen: (open: boolean) => void;
  open: boolean;
};

type Config = {
  check: string;
  enabled: boolean;
  rgsCodes: string;
};

const PAGE_SIZE = 15;

const maybeCreateAndEnableQualityCheck = async (
  setLoading: (loading: boolean) => void,
  qualityCheckConfigQuery: QueryObserverSuccessResult<any>,
  divisionCode: string,
  recommendationsQuery: QueryObserverSuccessResult<TRecommendation[] | null>,
  rgsCode: string,
) => {
  setLoading(true);
  const checks = qualityCheckConfigQuery.data || [];
  const checkExists = checks.some((check: Config) => check.rgsCodes === rgsCode);

  const rgsCodeDescription = getRgsCode(rgsCode)?.descriptionShort;
  if (!rgsCodeDescription) {
    console.error(`RGS code beschrijving niet gevonden voor code: ${rgsCode}`);
    toast.error(`RGS code beschrijving niet gevonden voor code: ${rgsCode}`);
    return;
  }

  if (!checkExists) {
    await createQualityCheck(rgsCodeDescription, rgsCode, false);
  }

  await setQualityCheckStatus(divisionCode, rgsCodeDescription, true);

  await recommendationsQuery.refetch();
  await qualityCheckConfigQuery.refetch();
  setLoading(false);
};

export default function Recommendations({ divisionCode, open, setOpen }: Readonly<Props>) {
  const recommendationsQuery = useRecommendationsByDivision(divisionCode);
  const qualityCheckConfigQuery = useQualityCheckConfiguration(divisionCode);
  const divisionQuery = useDivision(divisionCode);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const rows = recommendationsQuery.data || [];

  if (!recommendationsQuery.isSuccess || !qualityCheckConfigQuery.isSuccess) {
    return <Spinner />;
  }

  return (
    <Modal
      aria-labelledby="close-modal-title"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ModalDialog size="md">
        <ModalClose variant="soft" color="primary" />
        <Alert variant="soft" color="primary" startDecorator={<InfoIcon />}>
          {`Deze feature is in ontwikkeling: voor grote administraties kan het toevoegen van checks wat langer duren (max 30 seconden). Hier wordt aan gewerkt.`}
        </Alert>

        <Typography component="h2" id="close-modal-title" level="h4" textColor="inherit" fontWeight="lg">
          {`Aanbevolen checks voor ${divisionQuery.data?.Description}`}
        </Typography>

        <Typography id="close-modal-title" level="body-md">
          {`De score is gebaseerd op hoe consistent er de afgelopen 24 maanden op deze RGS code geboekt is.`}
        </Typography>

        <Stack direction={'column'} gap={3}>
          <Sheet
            className="OrderTableContainer"
            variant="outlined"
            sx={{
              width: '100%',
              borderRadius: 'sm',
              flexShrink: 1,
              overflow: 'auto',
              minHeight: 0,
              maxHeight: '80vh',
            }}
          >
            <Table
              stickyHeader
              stickyFooter
              hoverRow
              sx={{
                '--TableCell-height': '10px',
                '& tr > *:last-child': {
                  textAlign: 'right',
                  paddingLeft: '1rem',
                },
              }}
            >
              <thead>
                <tr>
                  <th style={{ width: '20%' }}>RGS code</th>
                  <th>Beschrijving</th>
                  <th style={{ width: '10%' }}>Score</th>
                  <th style={{ width: '25%' }}></th>
                </tr>
              </thead>
              <tbody>
                {rows.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE).map((row: any) => {
                  let color: ColorPaletteProp = 'success';
                  if (row.score < 90) {
                    color = 'warning';
                  }
                  if (row.score < 80) {
                    color = 'danger';
                  }

                  return (
                    <tr key={row.rgsL4}>
                      <td>{row.rgsL4}</td>
                      <td>{getRgsCode(row.rgsL4)?.descriptionShort ?? ''}</td>
                      <td>
                        <Chip color={color}>{row.score}%</Chip>
                      </td>
                      <td>
                        <Button
                          disabled={qualityCheckConfigQuery.isLoading}
                          loading={loading}
                          onClick={() =>
                            maybeCreateAndEnableQualityCheck(setLoading, qualityCheckConfigQuery, divisionCode, recommendationsQuery, row.rgsL4)
                          }
                        >
                          Activeren
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Sheet>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Pagination count={Math.ceil(rows.length / PAGE_SIZE)} color="primary" onChange={(_, page) => setPage(page - 1)} page={page + 1} />
          </Box>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}
