import { BalanceCardComponent, IBalanceCardProps } from "../balanceCard";
import {
  BalanceTimeseriesCardComponent,
  IBalanceTimeseriesCardProps,
} from "../balanceTimeseries";
import { IKenGetalCardProps, KenGetalCardComponent } from "../kengetal";
import {
  IProfitLossCardProps,
  ProfitLossCardComponent,
} from "../profitLossCard";

export enum DataVizTypes {
  ProfitLossCard = "profitLossCard",
  BalanceCard = "balanceCard",
  KenGetal = "kenGetal",
  balanceTimeseries = "balanceTimeseries",
  // Add more types here
}

// Base interface for positioning and sizing data visualization components.
export interface IDataVizMetadata {
  id: string;
  x: number;
  y: number;
  w: number;
  h: number;
  administrationId: string;
  layoutId: string;
}

// Type definitions for data visualization components with specific properties.
export type IDataVizComponentProps =
  | (IDataVizMetadata & {
      type: DataVizTypes.ProfitLossCard;
      props: IProfitLossCardProps;
    })
  | (IDataVizMetadata & {
      type: DataVizTypes.BalanceCard;
      props: IBalanceCardProps;
    })
  | (IDataVizMetadata & {
      type: DataVizTypes.KenGetal;
      props: IKenGetalCardProps;
    })
  | (IDataVizMetadata & {
      type: DataVizTypes.balanceTimeseries;
      props: IBalanceTimeseriesCardProps;
    });
// Add more types here

export const DataVizComponent = ({ type, props }: IDataVizComponentProps) => {
  switch (type) {
    case DataVizTypes.ProfitLossCard:
      return <ProfitLossCardComponent {...props} />;
    case DataVizTypes.BalanceCard:
      return <BalanceCardComponent {...props} />;
    case DataVizTypes.KenGetal:
      return <KenGetalCardComponent {...props} />;
    case DataVizTypes.balanceTimeseries:
      return <BalanceTimeseriesCardComponent {...props} />;
    // Add more components here
    default:
      return null; // Optional: Handle unexpected types or remove if the types are exhaustive.
  }
};
