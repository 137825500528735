import { useQuery } from "@tanstack/react-query";
import { getRgsBalanceTimeseries } from "../../services/api/dataviz/getBalanceTimeseries";
import queryTypes from "../../types/hookTypes";

const useHook = (administrationId: string, rgsCode: string) => {
  return useQuery(
    [queryTypes.rgsBalanceTimeseries, administrationId, rgsCode],
    async () => {
      const res = await getRgsBalanceTimeseries(administrationId, rgsCode);
      return res;
    }
  );
};

export default useHook;
