import client from '../../../utils/axiosClient';

export type PostQualityCheckOverrideBody = {
  administrationId: string;
  year: number;
  period: number;
  checkId: string;
};

export const postQualityCheckOverride = async (data: PostQualityCheckOverrideBody) => {
  const response = await client.post(`/api/qualityCheckOverrides`, data);
  return response.data;
};
