import { Modal } from "@mui/joy";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import { useEffect, useState } from "react";
import Form from "./form";
import Overview from "./overview";

type Props = {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
};

export type Check = {
  ID: string;
  RgsCodes: string;
};

export default function CheckModal(props: Props) {
  const { isOpen, setOpen } = props;
  const [selectedCheck, setSelectedCheck] = useState<Check | null>(null);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (!showForm) setSelectedCheck(null);
  }, [showForm]);

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={isOpen}
      onClose={() => setOpen(false)}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Sheet
        variant="outlined"
        sx={{
          maxWidth: "60vw",
          borderRadius: "md",
          p: 3,
        }}
      >
        <ModalClose variant="plain" sx={{ m: 1 }} />
        {showForm ? (
          <Form
            selectedCheck={selectedCheck}
            cancel={() => setShowForm(false)}
          />
        ) : (
          <Overview
            setselectedCheck={setSelectedCheck}
            goToForm={() => setShowForm(true)}
            setOpen={setOpen}
          />
        )}
      </Sheet>
    </Modal>
  );
}
