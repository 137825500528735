import client from '../../../utils/axiosClient';

type TGLAccountMappingOverviewRow = {
  glAccountCode: string;
  score: number;
  glAccountCodeCount: number;
};

export type GetGLAccountMappingOverviewParams = {
  accountID?: string | null;
  userId?: string | null;
};

export const getGLAccountMappingOverview = async (params?: GetGLAccountMappingOverviewParams) => {
  const response = await client.get<TGLAccountMappingOverviewRow[]>('/api/glaccounts/rgsMappingOverview', {
    params,
  });

  return response.data;
};
