import client from "../../utils/axiosClient";

export type TProfitLossResponse = {
  data: any[];
  sbiCode: number;
  sectorSize: number;
};

const getProfitLossPerformance = async (
  administration: string,
  year: number,
  period: number
) => {
  const response = await client.get("/api/profitLoss/performance", {
    params: {
      administration,
      year,
      period,
    },
  });
  return response?.data;
};

export default getProfitLossPerformance;
