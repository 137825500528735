import client from "../../../utils/axiosClient";
import { TKpiCheck } from "./getKpiChecksByAdministration";

export const patchKpiCheck = async (check: TKpiCheck) => {
  const response = await client.patch(
    `/api/insights/checks/kpi/${check.administrationId}/${check.kpi}`,
    check
  );
  return response.data;
};
