import client from "../../utils/axiosClient";

export type TDomainInvite = {
  domain: string;
  email: string;
};

export const getDomainInvites = async (domainId: string) => {
  const response = await client.get(`/api/domains/${domainId}/invites`, {});
  return response.data as TDomainInvite[];
};
