import { Box, Checkbox, ModalDialog, Stack, Table } from "@mui/joy";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "../../components/spinner";
import useDivision from "../../hooks/useDivision";
import useQualityCheckConfiguration from "../../hooks/useQualityCheckConfiguration";
import setQualityCheckStatus from "../../services/api/setQualityCheckStatus";
import { Pagination } from "@mui/material";

type Props = {
  divisionCode: string;
  setOpen: (open: boolean) => void;
  open: boolean;
};

const PAGE_SIZE = 15;

export default function ConfigureChecks({
  divisionCode,
  open,
  setOpen,
}: Props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const qualityCheckConfigQuery = useQualityCheckConfiguration(divisionCode);
  const divisionQuery = useDivision(divisionCode);
  const [page, setPage] = useState(0);

  const rows = qualityCheckConfigQuery.data || [];

  if (!qualityCheckConfigQuery.isSuccess) {
    return <Spinner />;
  }

  return (
    <Modal
      aria-labelledby="close-modal-title"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ModalDialog size="md">
        <ModalClose variant="soft" color="primary" />
        <Typography
          component="h2"
          id="close-modal-title"
          level="h4"
          textColor="inherit"
          fontWeight="lg"
        >
          {`Checks configureren voor ${divisionQuery.data?.Description}`}
        </Typography>

        <Stack direction={"column"} gap={3}>
          <Sheet
            className="OrderTableContainer"
            variant="outlined"
            sx={{
              width: "100%",
              borderRadius: "sm",
              flexShrink: 1,
              overflow: "auto",
              minHeight: 0,
              maxHeight: "80vh",
            }}
          >
            <Table
              stickyHeader
              stickyFooter
              hoverRow
              sx={{
                "--TableCell-height": "10px",
                "& tr > *:last-child": {
                  textAlign: "right",
                  paddingLeft: "1rem",
                },
              }}
            >
              <thead>
                <tr>
                  <th>Check</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {rows
                  .slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE)
                  .map((row: any) => {
                    const checked = !!row.enabled;

                    return (
                      <tr key={row.check}>
                        <td>{row.check}</td>
                        <td>
                          <Checkbox
                            checked={checked}
                            onClick={async () => {
                              setLoading(true);
                              await setQualityCheckStatus(
                                divisionCode,
                                row.check,
                                !row.enabled
                              );
                              await qualityCheckConfigQuery.refetch();
                              setLoading(false);
                            }}
                            disabled={loading}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Sheet>
        </Stack>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            count={Math.ceil(rows.length / PAGE_SIZE)}
            color="primary"
            onChange={(_, page) => setPage(page - 1)}
            page={page + 1}
          />
        </Box>
      </ModalDialog>
    </Modal>
  );
}
