import client from "../../../utils/axiosClient";

export type TKpiCheck = {
  administrationId: string;
  administrationName: string;
  kpi: string;
  operator: string;
  amount: number;
};

export const getKpiChecksByAdministration = async (
  administrationId: string
) => {
  const response = await client.get(
    `/api/insights/checks/kpi/${administrationId}`
  );
  return response.data as TKpiCheck[];
};
