import { create } from "zustand";
import {
  DataVizTypes,
  IDataVizComponentProps,
} from "./components/_common/IDataVizComponent";
import { EKenGetallen } from ".";

type DataVizState = {
  components: IDataVizComponentProps[];
  setComponents: (components: IDataVizComponentProps[]) => void;
  pushComponent: (component: IDataVizComponentProps) => void;
  selectedComponent: IDataVizComponentProps | null;
  setSelectedComponent: (component: IDataVizComponentProps | null) => void;
  updateComponent: (component: IDataVizComponentProps) => void;
  deleteComponent: (id: string) => void;
  save: () => void;
  createComponent: (
    type: DataVizTypes,
    administrationId: string,
    layoutId: string
  ) => void;
  reset: () => void;
};

export const dataVizStore = create<DataVizState>((set, get) => ({
  components: [],
  selectedComponent: null,
  setComponents: (components) => set(() => ({ components })),
  setSelectedComponent: (selectedComponent) =>
    set(() => ({ selectedComponent })),
  pushComponent: (component) => {
    set((state) => {
      return {
        components: [...state.components, component],
      };
    });
  },
  updateComponent: (component) => {
    set((state) => {
      const index = state.components.findIndex((c) => c.id === component.id);
      const components = [...state.components];
      components[index] = component;
      return { components };
    });
  },
  deleteComponent: (id) => {
    set((state) => {
      return {
        components: state.components.filter((c) => c.id !== id),
      };
    });
  },
  save: () => {
    // Save the components to the server
    console.log("saving");
    console.log(get().components);
  },
  reset: () => {
    set(() => {
      return {
        components: [],
        selectedComponent: null,
      };
    });
  },
  createComponent: (type, administrationId, layoutId) => {
    let newComponent: IDataVizComponentProps;
    switch (type) {
      case DataVizTypes.ProfitLossCard:
        newComponent = {
          id: Math.random().toString(36).substring(7),
          administrationId,
          layoutId,
          x: 0,
          y: 0,
          w: 3,
          h: 3,
          props: {
            label: "w/v component",
            rgsCode: "Nieuwe component",
            administrationId,
          },
          type,
        };
        break;
      case DataVizTypes.BalanceCard:
        newComponent = {
          id: Math.random().toString(36).substring(7),
          x: 0,
          y: 0,
          w: 3,
          h: 3,
          administrationId,
          layoutId,
          props: {
            label: "Balans component",
            rgsCode: "Nieuwe component",
            administrationId,
          },
          type,
        };
        break;
      case DataVizTypes.KenGetal:
        newComponent = {
          id: Math.random().toString(36).substring(7),
          x: 0,
          y: 0,
          w: 3,
          h: 3,
          administrationId,
          layoutId,
          props: {
            kenGetal: EKenGetallen.QuickRatio,
            administrationId,
          },
          type,
        };
        break;
      case DataVizTypes.balanceTimeseries:
        newComponent = {
          id: Math.random().toString(36).substring(7),
          x: 0,
          y: 0,
          w: 6,
          h: 8,
          administrationId,
          layoutId,
          props: {
            rgsCode: "BLim",
            administrationId,
            label: "BLim Serie",
          },
          type,
        };
        break;
      default:
        console.error("Unknown component type");
    }
    set((state) => {
      return {
        components: [...state.components, newComponent],
      };
    });
  },
}));
