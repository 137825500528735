import { GlobeAltIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import AccessTimeFilledRoundedIcon from "@mui/icons-material/AccessTimeFilledRounded";
import LightbulbRoundedIcon from "@mui/icons-material/LightbulbRounded";
import PieChartRoundedIcon from "@mui/icons-material/PieChartRounded";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import Box from "@mui/joy/Box";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import KpiCard from "../../components/KpiCard";
import useDivisions from "../../hooks/useDivisions";
import TranslationKeys from "../../translations/keys";
import DashboardCard, { Props as DashboardCardProps } from "./dashboardCard";
import { PageHeader } from "../../components/pageHeader";
import { useUsers } from "../../hooks/useUsers";

export default function Sidebar() {
  const { t } = useTranslation();
  const divisions = useDivisions();
  const navigate = useNavigate();
  const users = useUsers();

  const dashboards: Array<DashboardCardProps> = [
    {
      title: "1. Efficiency",
      Icon: AccessTimeFilledRoundedIcon,
      description: "Inzicht in de efficiëntie van het kantoor",
      to: "/efficiency/completeness",
    },
    {
      title: "2. Kwaliteit",
      Icon: VerifiedRoundedIcon,
      description: "Analyses van de kwaliteit van de administraties",
      to: "/completeness",
    },
    {
      title: "3. Inzicht",
      Icon: PieChartRoundedIcon,
      description: "Inzicht in de financiële situatie van de klant",
      to: "/insights",
    },
    {
      title: "4. Advies",
      Icon: LightbulbRoundedIcon,
      description: "Advieskansen voor de klant",
      to: "/kia",
    },
  ];

  const mockKpis = [
    {
      label: t(TranslationKeys.administrations),
      value: divisions.data?.length.toString() || "loading...",
      Icon: GlobeAltIcon,
      onClick: () => navigate("/administrations"),
    },
    {
      label: t(TranslationKeys.users),
      value: users.data?.length.toString() || "loading...",
      Icon: UserGroupIcon,
      onClick: () => {},
    },
  ];

  return (
    <Stack direction="column" gap={3}>
      <PageHeader>Home</PageHeader>

      <Stack direction={"row"} gap={3}>
        {mockKpis.map((kpi) => {
          return (
            <Box sx={{ width: "25%" }}>
              <KpiCard
                label={kpi.label}
                value={kpi.value}
                key={kpi.label}
                onClick={kpi.onClick}
              />
            </Box>
          );
        })}
        <Box sx={{ width: "25%" }} />
        <Box sx={{ width: "25%" }} />
      </Stack>

      {/* Dashboards */}
      <Typography level="h3">Dashboards</Typography>
      <Stack direction={"row"} gap={3}>
        {dashboards.map((dashboard) => {
          return (
            <Box sx={{ width: "25%" }}>
              <DashboardCard {...dashboard} key={dashboard.to} />
            </Box>
          );
        })}
      </Stack>
    </Stack>
  );
}
