import { useQuery } from '@tanstack/react-query';
import queryTypes from '../../types/hookTypes';
import { getGLAccountMappingOverview, GetGLAccountMappingOverviewParams } from '../../services/api/glaccounts/getGLAccountMappingOverview';

export const useGLAccountMappingOverview = (params?: GetGLAccountMappingOverviewParams) => {
  return useQuery([queryTypes.glAccountMappingOverview, params?.accountID, params?.userId], async () => {
    const res = await getGLAccountMappingOverview(params);
    return res;
  });
};
