import { useQuery } from "@tanstack/react-query";
import { EKenGetallen } from "../../pages/dataViz";
import { getKenGetal } from "../../services/api/dataviz/getKenGetal";
import queryTypes from "../../types/hookTypes";

const useHook = (administrationId: string, kengetal: EKenGetallen) => {
  return useQuery(
    [queryTypes.kenGetal, administrationId, kengetal],
    async () => {
      const res = await getKenGetal(administrationId, kengetal);
      return res;
    }
  );
};

export default useHook;
