import { useQuery } from "@tanstack/react-query";
import { getUserAdministrations } from "../services/api/getUserAdministrations";
import queryTypes from "../types/hookTypes";

type AssignedAdministrationsResponse = {
  AdministrationID: string;
  Name: string;
}[];

export const useAssignedAdministrations = (userId?: string) => {
  return useQuery<AssignedAdministrationsResponse>(
    [queryTypes.assignedAdministrations],
    async () => {
      try {
        const res = await getUserAdministrations(userId);
        return res;
      } catch (error) {
        return [];
      }
    }
  );
};
