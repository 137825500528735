import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import {
  Checkbox,
  FormControl,
  FormLabel,
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from "@mui/joy";
import Button from "@mui/joy/Button";
import Stack from "@mui/joy/Stack";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { PageHeader } from "../../components/pageHeader";
import Spinner from "../../components/spinner";
import { useAssignedAdministrations } from "../../hooks/useAssignedAdministrations";
import useDivision from "../../hooks/useDivision";
import { useMe } from "../../hooks/useMe";
import { BalanceInsightTable } from "./balanceInsightTable";
import ConfigureChecks from "./configureInsightChecks";
import { KpiInsightsTable } from "./kpiInsightTable";
import { RangeSelect } from "../../components/rangeSelect";
import useFilterStore from "../../stores/filter";

type Props = {
  renderHeader?: boolean;
};

export default function CompletenessDrilldown({ renderHeader = true }: Props) {
  const params = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const { divisionId: divisionIdString } = params;
  const divisionId = divisionIdString as string;
  const divisionQuery = useDivision(divisionId);
  const assignedAdministrationsQuery = useAssignedAdministrations();
  const userQuery = useMe();
  const { range } = useFilterStore();
  const [showAmounts, setShowAmount] = useState(false);

  if (!divisionQuery.isSuccess) {
    return <Spinner />;
  }

  const canEdit =
    assignedAdministrationsQuery.data?.some(
      (admin) => admin.AdministrationID === divisionId
    ) ||
    userQuery.data?.Role === "super" ||
    userQuery.data?.Role === "admin";

  return (
    <Stack direction="column" gap={3} sx={{ marginTop: "-1rem" }}>
      {renderHeader && (
        <PageHeader>{`Inzicht voor ${divisionQuery.data.Description}`}</PageHeader>
      )}

      <Tabs defaultValue={"rgs"}>
        <TabList>
          <Tab variant="plain" value={"rgs"} color="neutral">
            Balans checks
          </Tab>
          <Tab variant="plain" value={"kpi"} color="neutral" disabled>
            Kengetal checks
          </Tab>
        </TabList>

        <TabPanel value={"rgs"}>
          <Stack gap={3}>
            <Stack direction={"row"} gap={5}>
              <RangeSelect />

              <FormControl>
                <FormLabel>Bedragen</FormLabel>
                <Checkbox
                  label="Bedragen"
                  onClick={() => {
                    setShowAmount((checked) => !checked);
                  }}
                />
              </FormControl>
            </Stack>
            <BalanceInsightTable
              division={divisionId}
              yearStart={range.yearStart}
              yearEnd={range.yearEnd}
              monthStart={range.monthStart}
              monthEnd={range.monthEnd}
              showBalance={showAmounts}
            />
          </Stack>
        </TabPanel>
        <TabPanel value={"kpi"}>
          <Stack gap={3}>
            <RangeSelect />
            <KpiInsightsTable administration={divisionId} />
          </Stack>
        </TabPanel>
      </Tabs>

      <ConfigureChecks
        divisionCode={divisionId}
        open={modalOpen}
        setOpen={setModalOpen}
      />

      <Button
        sx={{
          position: "fixed",
          bottom: "2rem",
          right: "2rem",
          borderRadius: "3rem",
          paddingX: "1rem",
          paddingY: ".5rem",
          fontSize: "1.2rem",
        }}
        onClick={() => {
          setModalOpen(true);
        }}
        disabled={!canEdit}
        startDecorator={<SettingsRoundedIcon />}
      >
        Checks
      </Button>
    </Stack>
  );
}
