import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import ForwardRoundedIcon from "@mui/icons-material/ForwardRounded";
import Box from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Table from "@mui/joy/Table";
import { FormControl, FormLabel, Pagination } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DivisionSearch from "../../components/divisionSearch";
import { PageHeader } from "../../components/pageHeader";
import Spinner from "../../components/spinner";
import useDivisions from "../../hooks/useDivisions";
import useFilterStore from "../../stores/filter";
import TranslationKeys from "../../translations/keys";
import { Checkbox } from "@mui/joy";

const PAGE_SIZE = 15;

export default function Sidebar() {
  const { t } = useTranslation();
  const divisionsQuery = useDivisions();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const { divisionSearch } = useFilterStore();
  const [filterAssigned, setFilterAssigned] = useState(false);

  const rows = useMemo(() => {
    return (divisionsQuery.data || [])
      .filter((row) =>
        row.Description.toLowerCase().includes(divisionSearch.toLowerCase())
      )
      .filter((row) => {
        if (filterAssigned) {
          return !row.isAssigned;
        }
        return true;
      });
  }, [divisionsQuery.data, divisionSearch, page, filterAssigned]);

  useEffect(() => {
    // When filters change, we should reset the page to 0
    if (rows.length < PAGE_SIZE * page) {
      setPage(0);
    }
  }, [rows]);

  if (!divisionsQuery.isSuccess) {
    return <Spinner />;
  }

  return (
    <Stack direction={"column"} gap={3}>
      <PageHeader>{t(TranslationKeys.administrations)}</PageHeader>

      <Stack direction={"row"} gap={3}>
        <DivisionSearch />
        <FormControl>
          <FormLabel></FormLabel>
          <Checkbox
            className="mt-8"
            label="Filter niet toegekend"
            onClick={() => {
              setFilterAssigned((filterAssigned) => !filterAssigned);
            }}
          />
        </FormControl>
      </Stack>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
          maxHeight: "60vh",
        }}
      >
        <Table
          aria-label="basic table"
          stickyHeader
          stickyFooter
          hoverRow
          sx={{
            "& tr": {
              "&:hover": {
                cursor: "pointer",
              },
            },
          }}
        >
          <thead>
            <tr>
              {/* <th style={{ width: "30%" }}>Code</th> */}
              <th>Administratie</th>
              <th style={{ width: "10%" }}>Toegekend</th>
              <th style={{ width: "5%" }}></th>
            </tr>
          </thead>
          <tbody>
            {rows.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE).map((row) => {
              return (
                <tr
                  key={`${row.Code}-${row.Description}`}
                  onClick={() => {
                    navigate(`/administrations/${row.Code}/general`);
                  }}
                >
                  {/* <td>{row.Code}</td> */}
                  <td>{row.Description}</td>
                  <td>
                    {row.isAssigned ? (
                      <CheckCircleRoundedIcon sx={{ color: "#51BC51" }} />
                    ) : (
                      <ErrorRoundedIcon sx={{ color: "#EA9A3E" }} />
                    )}
                  </td>
                  <td>
                    <ForwardRoundedIcon />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Sheet>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          count={Math.ceil(rows.length / PAGE_SIZE)}
          color="primary"
          onChange={(_, page) => setPage(page - 1)}
          page={page + 1}
        />
      </Box>
    </Stack>
  );
}
