import { Button } from '@mui/joy';
import Stack from '@mui/joy/Stack';
import axios from 'axios';
import { useEffect, useState } from 'react';
import domainStore from '../../stores/domain';
import { clerk } from '../../utils/clerk';

export const UbtEmbed = () => {
  const activeDomain = domainStore.getState().domain;
  const [ubtUrl, setUbtUrl] = useState<string | null>(null);

  const goToUBT = async () => {
    if (!activeDomain) {
      console.error(`No active domain found`);
      return;
    }
    const token = (await clerk.session!.getToken()) as string;
    const httpClient = axios.create({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const response = await httpClient.post<{
      token: string;
    }>('https://aida-api.ubtechnologies.nl/api-token-auth-token/');

    setUbtUrl(`https://aida-insights.ubtechnologies.nl/?token=${response.data.token}`);
  };

  useEffect(() => {
    goToUBT();
  }, []);

  return (
    <Stack direction="column" gap={3}>
      {ubtUrl && <iframe src={ubtUrl} style={{ minHeight: '85vh' }} />}
    </Stack>
  );
};
