import { FormControl, FormLabel, Input, Stack } from "@mui/joy";
import { IProfitLossCardProps } from ".";
import { ProfitLossRgsSelect } from "../../../../components/profitLossRgsSelect";

export const ProfitLossCardEditor = ({
  props,
  updateProps,
}: {
  props: IProfitLossCardProps;
  updateProps: (props: IProfitLossCardProps) => void;
}) => {
  return (
    <Stack gap={3}>
      <FormControl>
        <FormLabel>Label</FormLabel>
        <Input
          placeholder="Debiteuren"
          value={props.label}
          onChange={(e) =>
            updateProps({
              ...props,
              label: e.target.value,
            })
          }
        />
      </FormControl>
      <ProfitLossRgsSelect
        onChange={(rgsCode) => {
          updateProps({
            ...props,
            rgsCode: rgsCode,
          });
        }}
        value={props.rgsCode}
      />
    </Stack>
  );
};
