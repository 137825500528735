import { FormControl } from '@mui/joy';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import * as React from 'react';

type ViewTypeRadioGroupProps = {
  onChange: (newValue: ViewTypeRadioGroupOptions) => void;
  value: ViewTypeRadioGroupOptions;
};

export enum ViewTypeRadioGroupOptions {
  checks = 'checks',
  transactionAmounts = 'transactionAmounts',
  invoiceAmounts = 'invoiceAmounts',
}

const ViewTypeRadioGroupOptionsWithLabels = [
  { value: ViewTypeRadioGroupOptions.checks, label: 'Standaard' },
  { value: ViewTypeRadioGroupOptions.transactionAmounts, label: 'Bedragen' },
  { value: ViewTypeRadioGroupOptions.invoiceAmounts, label: 'Facturen' },
];

export const ViewTypeRadioGroup = (props: ViewTypeRadioGroupProps) => {
  const { onChange } = props;
  return (
    <FormControl sx={{ marginTop: '.5rem' }}>
      <RadioGroup
        orientation="horizontal"
        aria-labelledby="segmented-controls-example"
        name="justify"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value as ViewTypeRadioGroupOptions)}
        sx={{
          'minHeight': 48,
          'padding': '4px',
          'borderRadius': '12px',
          'bgcolor': 'neutral.softBg',
          '--RadioGroup-gap': '4px',
          '--Radio-actionRadius': '8px',
        }}
        value={props.value}
      >
        {ViewTypeRadioGroupOptionsWithLabels.map((item) => (
          <Radio
            key={item.value}
            color="neutral"
            value={item.value}
            disableIcon
            label={item.label}
            variant="plain"
            sx={{
              px: 2,
              alignItems: 'center',
            }}
            slotProps={{
              action: ({ checked }) => ({
                sx: {
                  ...(checked && {
                    'bgcolor': 'background.surface',
                    'boxShadow': 'sm',
                    '&:hover': {
                      bgcolor: 'background.surface',
                    },
                  }),
                },
              }),
            }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
