import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { IconButton, Stack } from "@mui/joy";
import Typography from "@mui/joy/Typography";
import { useNavigate } from "react-router-dom";

export const PageHeader = ({ children }: { children: any }) => {
  const navigate = useNavigate();
  return (
    <Stack direction={"row"} gap={1}>
      <IconButton variant="plain" color="neutral" onClick={() => navigate(-1)}>
        <ArrowBackIosNewRoundedIcon />
      </IconButton>
      <Typography level="h2">{children}</Typography>
    </Stack>
  );
};
