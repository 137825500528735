import client from '../../../utils/axiosClient';

export type TGetInvalidVatTransactions = {
  yearStart: number;
  yearEnd: number;
  monthStart: number;
  monthEnd: number;
  administrationId: string;
};

export type TTransactionWithGLAccount = {
  transactionDate: string;
  transactionId: string;
  description: string;
  transactionAmount: number;
  amountVAT: number;
  rgsL5: string;
  glAccountDescription: string;
  glAccountCode: string;
}[];

export const getInvalidVatTransactions = async (config: TGetInvalidVatTransactions) => {
  const response = await client.get<TTransactionWithGLAccount[]>(`/api/transactions/vat/invalid-vat-transactions`, {
    params: config,
  });
  return response.data;
};
