import client from '../../../utils/axiosClient';

export type DeleteDomainUserParams = {
  userID: string;
  domainID: string;
};

export const deleteDomainUser = async (params: DeleteDomainUserParams) => {
  const response = await client.delete(`/api/domainUsers/domains/${params.domainID}/users/${params.userID}`);
  return response.data;
};
