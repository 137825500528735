import { useQuery } from "@tanstack/react-query";
import { Division } from "../types/types";
import queryTypes from "../types/hookTypes";
import getDivision from "../services/api/getDivision";

const useHook = (divisionCode: string) => {
  return useQuery<Division>([queryTypes.division, divisionCode], async () => {
    const res = await getDivision(divisionCode);
    return res;
  });
};

export default useHook;
