import client from "../../../utils/axiosClient";

export type TCreateNote = {
  administrationId: string;
  rgsCode: string;
  financialYear: number;
  financialPeriod: number;
  text: string;
};

export const createNote = async (createNote: TCreateNote) => {
  const response = await client.post(`/api/notes`, createNote, {});
  return response.data;
};
