import { Stack, Typography } from "@mui/joy";
import { useEffect } from "react";
import { PeriodSelect } from "../../components/monthSelect";
import NoData from "../../components/noData";
import { UserSelect } from "../../components/userSelect";
import { YearSelect } from "../../components/yearSelect";
import useDivisions from "../../hooks/useDivisions";
import useFilterStore from "../../stores/filter";
import { ProfitLossPerformance } from "./profitLossPerformance";
import { DivisionSelect } from "../../components/divisionSelect";
import { PageHeader } from "../../components/pageHeader";

// const LineChartComponent: React.FC<{
//   data: Array<{
//     index: number;
//     value: number;
//   }>;
//   label: string;
// }> = ({ data, label }) => {
//   return (
//     <ResponsiveContainer width="100%" height="100%">
//       <AreaChart data={data}>
//         <defs>
//           <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
//             <stop offset="5%" stopColor="#129a74" stopOpacity={0.8} />
//             <stop offset="95%" stopColor="#129a74" stopOpacity={0} />
//           </linearGradient>
//         </defs>
//         <Tooltip
//           labelFormatter={(e) => {
//             return format(new Date().setMonth(e), "MMMM");
//           }}
//         />
//         <Area
//           type="monotone"
//           dataKey="value"
//           stroke="#129a74"
//           fillOpacity={1}
//           strokeWidth={3}
//           fill="url(#colorUv)"
//           name={label}
//         />
//       </AreaChart>
//     </ResponsiveContainer>
//   );
// };

// const KpiCard = ({
//   history,
//   label,
//   loading,
// }: {
//   label: string;
//   history: Array<number>;
//   loading: boolean;
// }) => {
//   const increase =
//     -100 +
//       Math.round(
//         (history[history.length - 1] / history[history.length - 2]) * 100
//       ) || 0;
//   return (
//     <div className="flex flex-col space-y-1 text-center w-full">
//       {/* Header */}
//       <div className="bg-white rounded-t-lg py-2 shadow-md font-bold text-title">
//         {label}
//       </div>
//       <div className="bg-white rounded-b-lg flex flex-col space-y-3 py-5 shadow-md h-[225px] items-center justify-center">
//         {loading && <Spinner />}
//         {!loading && (
//           <>
//             <p className="font-bold text-title text-3xl">
//               {history[history.length - 1] ?? "-"}
//             </p>
//             <div className="w-full h-[50px] px-8">
//               <LineChartComponent
//                 data={history.map((value, index) => {
//                   return {
//                     index,
//                     value,
//                   };
//                 })}
//                 label={label}
//               />
//             </div>
//             <div>
//               <p
//                 className={clsx(
//                   "font-semibold",
//                   increase > 0 ? "text-positive" : "text-danger"
//                 )}
//               >{`${increase > 0 ? "+" : ""}${increase}%`}</p>
//               <p className="text-sm">vs. previous month</p>
//             </div>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

export default function FinancialDashboard() {
  const { year, setSelectedDivision, selectedDivision, period, user } =
    useFilterStore();
  const divisionsQuery = useDivisions(user === "none" ? null : user);

  useEffect(() => {
    if (!divisionsQuery.data || !divisionsQuery.data.length) {
      setSelectedDivision(null);
    }
  }, [divisionsQuery.data]);

  return (
    <Stack gap={3} direction={"column"}>
      <PageHeader>Inzicht (Work in progress)</PageHeader>

      {/* Filters */}
      <Stack gap={3} direction={"row"}>
        {/* <UserSelect /> */}
        <DivisionSelect />
        <YearSelect />
        <PeriodSelect />
      </Stack>

      {/* Content */}
      {selectedDivision ? (
        <ProfitLossPerformance
          administration={selectedDivision}
          period={period}
          year={year}
        />
      ) : (
        <NoData />
      )}
    </Stack>
  );
}
