import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { Sheet, Table } from "@mui/joy";
import { useMemo } from "react";
import "react-circular-progressbar/dist/styles.css";
import Spinner from "../../components/dynamicSpinner";
import NoData from "../../components/noData";
import { useInsightKpiDrilldown } from "../../hooks/insights/useInsightKpiDrilldown";

type Props = {
  administration: string;
};

export const KpiInsightsTable = ({ administration }: Props) => {
  const drilldownQuery = useInsightKpiDrilldown(administration);

  const rows = useMemo(() => {
    if (!drilldownQuery.isSuccess) {
      return [];
    }
    const data = drilldownQuery.data;
    if (!data) {
      return [];
    }
    return data;
  }, [drilldownQuery.data]);

  if (!drilldownQuery.isSuccess) {
    return <Spinner />;
  }

  if (!rows.length) {
    return <NoData />;
  }

  return (
    <>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
          maxHeight: "80vh",
        }}
      >
        <Table
          aria-label="basic table"
          stickyHeader
          stickyFooter
          hoverRow
          sx={{
            "--TableCell-height": "3rem",
            "& tr": {
              "&:hover": {
                cursor: "pointer",
              },
            },
          }}
        >
          <thead>
            <tr>
              <th>Kengetal</th>
              <th>Drempelwaarde</th>
              <th>Waarde</th>
              <th style={{ width: "5%" }}>Status</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => {
              return (
                <tr key={JSON.stringify(row)}>
                  <td>{row.checkCode}</td>
                  <td>{row.triggerAmount}</td>
                  <td>{row.value}</td>
                  <td>
                    {row.triggered ? (
                      <ErrorRoundedIcon style={{ color: "#ef4444" }} />
                    ) : (
                      <CheckCircleRoundedIcon sx={{ color: "#22c55e" }} />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Sheet>
    </>
  );
};
