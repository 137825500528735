import { Grid, Stack } from "@mui/joy";
import ExactLogo from "../../assets/ExactLogo.png";
import TwinfieldLogo from "../../assets/TwinfieldLogo.png";
import YukiLogo from "../../assets/YukiLogo.png";
import AidaLogo from "../../assets/logo.12cd8c0cadebea1ba0c3.png";
import { PageHeader } from "../../components/pageHeader";
import { IntegrationCard } from "./integrationCard";
import { useState } from "react";
import { TwinfieldConnectorModal } from "./modals/twinfield";

export const IntegrationsPage = () => {
  const [twinfieldModalOpen, setTwinfieldModalOpen] = useState(false);

  return (
    <>
      {/* Modals */}
      <TwinfieldConnectorModal open={twinfieldModalOpen} setOpen={setTwinfieldModalOpen} />

      {/* Cards */}
      <Stack direction="column" gap={3}>
        <PageHeader>Koppelingen</PageHeader>
        <Grid container spacing={3}>
          <Grid xs={12} lg={4}>
            <IntegrationCard
              description="De standaard connectie voor AIDA"
              imageUrl={AidaLogo}
              title="AIDA"
              key="integration_card_aida"
              status={2}
              onClick={() => {}}
            />
          </Grid>
          <Grid xs={12} lg={4}>
            <IntegrationCard
              description="Neem contact op"
              imageUrl={TwinfieldLogo}
              title="Twinfield"
              key="integration_card_twinfield"
              status={0}
              onClick={() => setTwinfieldModalOpen(true)}
            />
          </Grid>
          <Grid xs={12} lg={4}>
            <IntegrationCard
              description="Neem contact op"
              imageUrl={ExactLogo}
              title="Exact Online"
              key="integration_card_exact"
              status={2}
              onClick={() => {}}
              metaLink="/dashboards/eol-integration"
            />
          </Grid>
          <Grid xs={12} lg={4}>
            <IntegrationCard imageUrl={YukiLogo} title="Yuki" description="Neem contact op" key="integration_card_yuki" status={0} onClick={() => {}} />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};
