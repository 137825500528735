import { useQuery } from "@tanstack/react-query";
import { Division } from "../types/types";
import queryTypes from "../types/hookTypes";
import getDivisions from "../services/api/getDivisions";

const useHook = (userFilter: string | null = null) => {
  return useQuery<Array<Division>>([queryTypes.divisions, userFilter], () =>
    getDivisions(userFilter)
  );
};

export default useHook;
