import Stack from '@mui/joy/Stack';
import { useParams } from 'react-router-dom';
import { PageHeader } from '../../components/pageHeader';
import EfficiencyTable from './table';
import { AccountSelect } from '../../components/accountSelect';

export const EfficiencyDrilldown = () => {
  const params = useParams();

  return (
    <Stack direction="column" gap={3}>
      <PageHeader>RGS Consistentie overzicht - grootboek nummer {params.glaccountcode as string}</PageHeader>

      <Stack direction={'row'} gap={3}>
        <AccountSelect />
      </Stack>

      <EfficiencyTable />
    </Stack>
  );
};
