import FlagRoundedIcon from "@mui/icons-material/FlagRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LabelImportantRoundedIcon from "@mui/icons-material/LabelImportantRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import { Avatar, AvatarGroup, Dropdown, ListItemDecorator, Menu, MenuButton, MenuItem, Option, Select, Sheet, Stack, Table } from "@mui/joy";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import { useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useUsers } from "../../hooks/useUsers";
import { Task, TaskWithChildren } from "../../services/api/tasks/getTasks";
import dayjs from "../../utils/dayjs";
import { StatusChip } from "./statusChip";
import { mapPriorityToColor, TASK_STATUS_LIST } from "./utils";

export const TasksTableRow = (props: {
  task: TaskWithChildren;
  disabled: boolean;
  onDelete: (task: Task) => void;
  patchTask: (task: Task) => void;
  openEditModal: (task: Task) => void;
}) => {
  const { task, onDelete, patchTask, disabled, openEditModal } = props;
  const [open, setOpen] = useState(false);
  const usersQuery = useUsers();
  const users = usersQuery.data || [];

  const uniqueUsers = task.children.reduce(
    (acc, subtask) => {
      if (!subtask.assignedUserID) return acc;

      if (acc.indexOf(subtask.assignedUserID) === -1) {
        acc.push(subtask.assignedUserID);
      }

      return acc;
    },
    [task.assignedUserID] as string[],
  );

  const EditButton = (props: { task: Task }) => {
    const { task } = props;
    return (
      <Dropdown>
        <MenuButton
          slots={{ root: IconButton }}
          slotProps={{
            root: { variant: "soft", color: "neutral" },
          }}
          disabled={disabled}
        >
          <MoreVertRoundedIcon />
        </MenuButton>
        <Menu>
          <MenuItem
            variant="plain"
            onClick={async () => {
              openEditModal(task);
            }}
          >
            Aanpassen
          </MenuItem>
          <MenuItem variant="plain" onClick={() => onDelete(task)}>
            Verwijderen
          </MenuItem>
        </Menu>
      </Dropdown>
    );
  };

  const UserSelect = (props: { task: Task }) => {
    const { task } = props;
    return (
      <Select
        variant="plain"
        sx={{
          width: "14rem",
        }}
        renderValue={(value) => {
          const userLabel = users.find((user) => user.id === (value?.value as any))?.email || (value?.value as any) || "Onbekend"; // Try to display user email, if not available display user id, if not available display 'Onbekend'
          return (
            <Stack direction={"row"} gap={1} alignItems={"center"}>
              <ListItemDecorator>
                <Avatar size="sm" />
              </ListItemDecorator>
              <Typography>{userLabel}</Typography>
            </Stack>
          );
        }}
        value={task.assignedUserID}
        size="sm"
        disabled={disabled}
        onChange={async (_, newValue) => {
          if (newValue === null || newValue === task.assignedUserID) return; // Only update if there's a change
          await patchTask({ ...task, assignedUserID: newValue as any });
        }}
      >
        {users.map((user) => {
          return (
            <Option value={user.id}>
              <Avatar size="sm" />
              <Typography>{user.email}</Typography>
            </Option>
          );
        })}
      </Select>
    );
  };

  const TaskStatusSelect = (props: { task: Task }) => {
    const { task } = props;
    return (
      <Select
        variant="plain"
        sx={{
          width: "12rem",
        }}
        size="sm"
        renderValue={(value) => <StatusChip status={value?.value as any} />}
        value={task.status}
        disabled={disabled}
        onChange={async (_, newValue) => {
          if (newValue === null || newValue === task.status) return; // Only update if there's a change
          await patchTask({ ...task, status: newValue as any });
        }}
      >
        {TASK_STATUS_LIST.map((status) => {
          return (
            <Option value={status}>
              <ListItemDecorator>
                <StatusChip status={status} />
              </ListItemDecorator>
            </Option>
          );
        })}
      </Select>
    );
  };

  return (
    <React.Fragment>
      <tr>
        <td>
          {task.children.length > 0 ? (
            <IconButton aria-label="expand row" variant="plain" color="neutral" size="sm" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          ) : null}
        </td>
        <td>
          <LabelImportantRoundedIcon color="info" />
        </td>
        <td>{task.title}</td>
        <td>
          <TaskStatusSelect task={task} key={`${task.taskID}-status`} />
        </td>
        <td>
          <AvatarGroup>
            {uniqueUsers.map(() => {
              return <Avatar size="sm" />;
            })}
          </AvatarGroup>
        </td>
        <td>{task.dueDate ? dayjs.unix(task.createdAt / 1000).fromNow() : "-"}</td>
        <td>{task.dueDate ? dayjs.unix(task.dueDate / 1000).fromNow() : "-"}</td>
        <td>
          <FlagRoundedIcon color={mapPriorityToColor(task.priority)} />
        </td>
        <td>
          <EditButton task={task} />
        </td>
      </tr>
      <tr>
        <td style={{ height: 0, padding: 0 }} colSpan={9}>
          {open && (
            <Sheet variant="plain" sx={{ p: 1, pl: 6 }}>
              <Typography level="body-lg" component="div">
                Subtaken
              </Typography>
              <Table size="sm">
                <thead>
                  <tr>
                    <th>Naam</th>
                    <th>Status</th>
                    <th>Uitvoerder</th>
                    <th style={{ width: 60 }} aria-label="empty" />
                  </tr>
                </thead>
                <tbody>
                  {task.children.map((subtask) => {
                    return (
                      <tr key={subtask.taskID}>
                        <td>{subtask.title}</td>
                        <td>
                          <TaskStatusSelect task={subtask} key={`${task.taskID}-status`} />
                        </td>
                        <td>
                          <UserSelect task={task} />
                        </td>
                        <td>
                          <EditButton task={task} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Sheet>
          )}
        </td>
      </tr>
    </React.Fragment>
  );
};
