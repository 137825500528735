import { EKenGetallen } from "../../../pages/dataViz";
import client from "../../../utils/axiosClient";

type IKenGetalApiResult = {
  kenGetal: EKenGetallen;
  amount: number;
  administrationId: string;
};

export const getKenGetal = async (
  administrationId: string,
  kenGetal: EKenGetallen
) => {
  const response = await client.get(
    `/api/dataviz/${administrationId}/kenGetal`,
    {
      params: {
        kenGetal,
      },
    }
  );
  return response.data as IKenGetalApiResult;
};
