import { useQuery } from '@tanstack/react-query';
import { getGLAccountMappingDrilldown, GetGLAccountMappingDrilldownParams } from '../../services/api/glaccounts/getGLAccountMappingDrilldown';
import queryTypes from '../../types/hookTypes';

export const useGLAccountMappingDrilldown = (params?: GetGLAccountMappingDrilldownParams) => {
  return useQuery([queryTypes.glAccountMappingDrilldown, params?.accountID, params?.code], async () => {
    const res = await getGLAccountMappingDrilldown(params);
    return res;
  });
};
