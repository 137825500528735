import { TTransaction } from '../services/api/transactions/getTransactions';

export enum IndicatorEnum {
  yellow,
  red,
  green,
  neutral,
}

export type AdviceType = {
  HID: number;
  advice: string;
  division: number;
  name: string;
  rebate: number;
  tier: number;
  totalInvestment: number;
  qualityScore: number;
  containsCar: boolean;
};

export type TableMonitoringResponse = Array<{
  ID: string;
  TableName: string;
  Date: string;
  RowCount: number;
}>;

export type minimalTransactionType = {
  GLAccount: string;
  ID: string;
  amountDC: string;
  AmountFC: string;
  Description: string;
  DivisionDescription: string;
  DivisionCode: string;
};

export type KiaTransaction = {
  administrationId: string;
  TransactionID: string;
  AdministrationName: string;
  Description: string;
  transactionAmount: number;
  GLAccountID: string;
  TransactionDate: string;
  Year: number;
};

export type KiaLegibleTransactionsType = {
  division: number;
  transactions: TTransaction[];
};

export type AdministrationType = {
  name: string;
  updatedAt: Date;
  joinedAt: Date;
  completeness: number;
};

export type Tab = {
  label: String;
  key: string;
  Component: React.ReactNode;
  Icon: React.ReactNode;
};

export type User = {
  id: string;
  email: string;
  role: string;
  administrations?: string[];
};

export type TQualityCheck = {
  ID: string;
  RgsCodes: string;
};

export type Domain = {
  domainId: string;
  description: string;
  redirectUrl: string;
  title: string;
  users?: Array<User>;
};

export enum PipelineStatus {
  Succeeded = 'Succeeded',
  Failed = 'Failed',
  Cancelled = 'Cancelled',
  Queued = 'Queued',
  InProgress = 'InProgress',
  Canceling = 'Canceling',
}

export type PipelineRun = {
  pipelineName: string;
  runId: string;
  runStart: Date;
  runEnd: Date;
  durationInMs: number;
  status: PipelineStatus;
};

export type Division = {
  Code: number;
  Hid: number;
  CustomerName: string;
  Description: string;
  isAssigned: 0 | 1;
};

export const quarters = ['q1', 'q2', 'q3', 'q4'];

// TODO: refactor backend to take a range of month numbers. And to the quarter stuff here but more nicely.
export const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

export const mapQuarterToMonthNrs = (quarter: string) => {
  if (quarter === 'q1') return [0, 1, 2];
  if (quarter === 'q2') return [3, 4, 5];
  if (quarter === 'q3') return [6, 7, 8];
  if (quarter === 'q4') return [9, 10, 11];
  throw new Error(`Invalid quarter: ${quarter}`);
};

export type ExactLoadDetails = Array<{ [endpoint: string]: Date }>;
