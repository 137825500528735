import {
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
} from "@heroicons/react/24/solid";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import clsx from "clsx";
import { abbreviateNumber } from "js-abbreviation-number";
import Spinner from "../../components/dynamicSpinner";
import NoData from "../../components/noData";
import { useProfitLossPerformance } from "../../hooks/useProfitLossPerformance";

const rgsMap: { [_: string]: string } = {
  WOmz: "Omzet",
  WFbe: "Financiële baten & lasten",
  WBed: "Overige bedrijfskosten",
  WKpr: "Kostprijs van de omzet",
};

const formatDiff = (rgs: string, value: number) => {
  if (["WOmz"].indexOf(rgs) > -1) {
    return (
      <div
        className={clsx(
          value < 0 ? "text-red-500" : "text-green-500",
          "flex items-center space-x-3"
        )}
      >
        {value < 0 ? (
          <ArrowDownCircleIcon className="w-5" />
        ) : (
          <ArrowUpCircleIcon className="w-5" />
        )}
        <p>{abbreviateNumber(Math.round(value * 100) / 100)}</p>
      </div>
    );
  }

  if (["Resultaat"].indexOf(rgs) > -1) {
    return (
      <div
        className={clsx(
          value >= 0 ? "text-red-500" : "text-green-500",
          "flex items-center space-x-3"
        )}
      >
        {value >= 0 ? (
          <ArrowDownCircleIcon className="w-5" />
        ) : (
          <ArrowUpCircleIcon className="w-5" />
        )}
        <p>{abbreviateNumber(Math.round(value * 100) / 100)}</p>
      </div>
    );
  }
  return (
    <div
      className={clsx(
        value < 0 ? "text-red-500" : "text-green-500",
        "flex items-center space-x-3"
      )}
    >
      {value >= 0 ? (
        <ArrowDownCircleIcon className="w-5" />
      ) : (
        <ArrowUpCircleIcon className="w-5" />
      )}
      <p>{abbreviateNumber(Math.round(value * 100) / 100)}</p>
    </div>
  );
};

export const ProfitLossPerformance = ({
  administration,
  year,
  period,
}: {
  administration: string;
  year: number;
  period: number;
}) => {
  const profitLossPerformanceQuery = useProfitLossPerformance(
    administration,
    year,
    period
  );

  const rows = profitLossPerformanceQuery?.data?.data || [];

  if (profitLossPerformanceQuery.isLoading) {
    return <Spinner />;
  }

  if (!rows.length) {
    return <NoData />;
  }

  return (
    <Sheet
      className="OrderTableContainer"
      variant="outlined"
      sx={{
        width: "100%",
        borderRadius: "sm",
        flexShrink: 1,
        overflow: "auto",
        minHeight: 0,
        maxHeight: "80vh",
      }}
    >
      <Table aria-label="basic table" stickyHeader stickyFooter>
        <thead>
          <tr>
            <th style={{ width: "30%" }}>Resultatenrekening</th>
            <th>Bedrijf</th>
            <th>Benchmark</th>
            <th>Verschil</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row: any) => {
            return (
              <tr>
                <td>{rgsMap[row?.rgs] || "-"}</td>
                <td>{`€${Math.round(row?.target || 0 * 100) / 100}`}</td>
                <td>{`€${Math.round(row?.sector || 0 * 100) / 100}`}</td>
                <td>{formatDiff(row?.rgs, row?.diff || 0)}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Sheet>
  );
};
