import { useQuery } from '@tanstack/react-query';
import { getTasks } from '../../services/api/tasks/getTasks';
import queryTypes from '../../types/hookTypes';

export const useTasks = () => {
  return useQuery([queryTypes.tasks], async () => {
    const tasks = await getTasks();
    return tasks;
  });
};
