import client from '../../utils/axiosClient';

export type TGLAccountEfficiencyResponse = {
  nonRgsGLAccounts: {
    GLAccountID: string;
    GLAccountCode: string;
    DivisionCode: string;
    GLAccountDescription: string;
    DivisionDescription: string;
    accountID: string;
    accountName: string;
    Hid: string;
  }[];
  totalGLaccounts: number;
  percentage: number;
};

export type GetGLAccountEfficiencyParams = {
  accountID?: string | null;
};

export const getGLAccountEfficiency = async (params?: GetGLAccountEfficiencyParams) => {
  const response = await client.get<TGLAccountEfficiencyResponse>('/api/efficiency/glAccounts', {
    params,
  });
  return response.data;
};
