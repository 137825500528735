import client from "../../utils/axiosClient";

export type UserAdministrations = {
  AdministrationID: string;
  Name: string;
}[];

export const getUserAdministrations = async (userId?: string) => {
  try {
    const response = await client.get(`/api/users/assignedAdministrations`, {
      params: {
        userId,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
