import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { IconButton } from "@mui/joy";

const stopPropagation = (event: any) => {
  event.stopPropagation();
};

export const DataVizEditButton = ({
  onClickEdit,
}: {
  onClickEdit: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) => {
  return (
    <IconButton
      variant="soft"
      color="neutral"
      onClick={onClickEdit}
      onMouseDown={stopPropagation}
      onTouchStart={stopPropagation}
    >
      <EditRoundedIcon />
    </IconButton>
  );
};
