import SaveRounded from "@mui/icons-material/SaveRounded";
import { Button, Stack } from "@mui/joy";
import React from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { useParams } from "react-router-dom";
import { useStore } from "zustand";
import NoData from "../../components/noData";
import { DataVizComponent } from "./components/_common/IDataVizComponent";
import { DataVizComponentEditor } from "./components/_common/dataVizComponentEditor";
import { DataVizSelect } from "./components/_common/dataVizSelect";
import { DataVizModalWrapper } from "./components/_common/modalWrapper";
import { dataVizStore } from "./store";
import { DataVizEditButton } from "./components/_common/editButton";
import { DataVizDeleteButton } from "./components/_common/deleteButton";

const ResponsiveGridLayout = WidthProvider(Responsive);

export enum EKenGetallen {
  "QuickRatio" = "QuickRatio",
  "CurrentRatio" = "CurrentRatio",
  "DebtRatio" = "DebtRatio",
  "CashRatio" = "CashRatio",
  "Solvabiliteitsratio" = "Solvabiliteitsratio",
}

export const DataViz = () => {
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const { divisionId: administrationId } = useParams<{ divisionId: string }>();
  const {
    components,
    selectedComponent,
    setSelectedComponent,
    updateComponent,
    deleteComponent,
    save,
  } = useStore(dataVizStore);

  if (!administrationId) {
    return <NoData />;
  }

  return (
    <>
      <DataVizModalWrapper open={editModalOpen} setOpen={setEditModalOpen}>
        {selectedComponent && <DataVizComponentEditor />}
      </DataVizModalWrapper>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <DataVizSelect />
        <Button
          color="primary"
          variant="soft"
          onClick={save}
          startDecorator={<SaveRounded />}
        >
          Opslaan
        </Button>
      </Stack>
      <ResponsiveGridLayout
        className="layout"
        preventCollision={true}
        compactType={null}
        style={{ margin: 0 }}
        cols={{
          lg: 12,
          md: 12,
          sm: 12,
          xs: 12,
          xxs: 12,
        }}
        rowHeight={30}
        containerPadding={[0, 16]}
        onLayoutChange={(layout) => {
          // We need to update the component store and set the new coordinates for each component by id
          layout.forEach((item) => {
            const component = components.find((c) => c.id === item.i);
            if (!component) {
              // Realistically, this should never happen
              console.error("Component not found", item.i);
              return;
            }
            const updatedComponent = {
              ...component,
              x: item.x,
              y: item.y,
              w: item.w,
              h: item.h,
            };
            updateComponent(updatedComponent);
          });
        }}
      >
        {components.map((item) => {
          return (
            <div
              key={item.id}
              data-grid={{
                x: item.x,
                y: item.y,
                w: item.w,
                h: item.h,
              }}
            >
              <DataVizComponent key={item.id} {...item} />
              <Stack
                sx={{ position: "absolute", right: "1rem", top: "1rem" }}
                direction={"column"}
                gap={1}
                zIndex={10}
              >
                <DataVizEditButton
                  onClickEdit={() => {
                    setEditModalOpen(true);
                    setSelectedComponent(item);
                  }}
                />
                <DataVizDeleteButton
                  onClickDelete={() => {
                    deleteComponent(item.id);
                  }}
                />
              </Stack>
            </div>
          );
        })}
      </ResponsiveGridLayout>
    </>
  );
};
