import { create } from "zustand";
import { Task } from "../services/api/tasks/getTasks";

type TaskStoreState = {
  taskModalOpen: boolean;
  setTaskModalOpen: (taskModalOpen: boolean) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  selectedTask: Task | null;
  setSelectedTask: (selectedTask: Task | null) => void;
};

export const useTaskStore = create<TaskStoreState>((set) => {
  return {
    taskModalOpen: false,
    setTaskModalOpen: (taskModalOpen: boolean) => {
      return set({ taskModalOpen });
    },
    loading: false,
    setLoading: (loading: boolean) => {
      return set({ loading });
    },
    selectedTask: null,
    setSelectedTask: (selectedTask: Task | null) => {
      return set({ selectedTask });
    },
  };
});
