import client from '../../../utils/axiosClient';

export type QualityCheckOverride = {
  id: string;
  administrationId: string;
  year: number;
  period: number;
  creatorId: string;
  checkId: string;
  createdAt: string;
  modifiedAt?: string;
};

export type GetQualityCheckOverridesParams = {
  administrationID: string;
};

export const getQualityCheckOverrides = async (params: GetQualityCheckOverridesParams) => {
  const response = await client.get<QualityCheckOverride[]>(`/api/qualityCheckOverrides`, { params });
  return response.data;
};
