import { Box, Button, Stack, Typography } from "@mui/joy";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import { useStore } from "zustand";
import { dataVizStore } from "../../store";

type Props = {
  setOpen: (open: boolean) => void;
  open: boolean;
  children: React.ReactNode;
};

export const DataVizModalWrapper = (props: Props) => {
  const { setOpen, open, children } = props;
  const { selectedComponent, setSelectedComponent, updateComponent } =
    useStore(dataVizStore);

  return (
    <Modal
      aria-labelledby="close-modal-title"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto",
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          borderRadius: "md",
          p: [1, 3, 3, 3],
        }}
      >
        <ModalClose variant="soft" color="primary" />
        <Stack sx={{ padding: "0 2 2 2" }} gap={5}>
          <Typography>Component aanpassen</Typography>
          <Box sx={{ minWidth: 300 }}>{children}</Box>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              flexDirection: { xs: "column", sm: "row-reverse" },
            }}
          >
            <Button
              variant="solid"
              color="primary"
              onClick={() => {
                if (!selectedComponent) {
                  return;
                }
                updateComponent(selectedComponent);
                setSelectedComponent(null);
                setOpen(false);
              }}
            >
              Opslaan
            </Button>
            <Button
              variant="outlined"
              color="neutral"
              onClick={() => setOpen(false)}
            >
              Annuleren
            </Button>
          </Box>
        </Stack>
      </Sheet>
    </Modal>
  );
};
