import { Autocomplete, FormHelperText } from "@mui/joy";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import { TRgsCode, rgsCodes } from "../../assets/rgsCodes";
import { useMemo } from "react";

export enum RgsFilters {
  "RGS1" = "RGS1",
  "RGS2" = "RGS2",
  "RGS3" = "RGS3",
  "RGS4" = "RGS4",
  "RGS5" = "RGS5",
  "BALANCE" = "BALANCE",
  "PROFITLOSS" = "PROFITLOSS",
}

export const RgsSelect = ({
  onChange,
  disabled = false,
  type,
}: {
  onChange: (rgsCode: string) => void;
  disabled?: boolean;
  value: string;
  type: RgsFilters;
}) => {
  const filters: { [filter in RgsFilters]: (rgsCode: TRgsCode) => boolean } = {
    [RgsFilters.RGS1]: (rgsCode) => rgsCode.code.length === 1,
    [RgsFilters.RGS2]: (rgsCode) => rgsCode.code.length === 4,
    [RgsFilters.RGS3]: (rgsCode) => rgsCode.code.length === 7,
    [RgsFilters.RGS4]: (rgsCode) => rgsCode.code.length === 10,
    [RgsFilters.RGS5]: (rgsCode) => rgsCode.code.length === 13,
    [RgsFilters.BALANCE]: (rgsCode) => rgsCode.code.startsWith("B"),
    [RgsFilters.PROFITLOSS]: (rgsCode) => rgsCode.code.startsWith("W"),
  };

  const options = useMemo(() => {
    return rgsCodes.filter(filters[type]).map((rgsCode) => ({
      key: rgsCode.code,
      label: `${rgsCode.descriptionShort} - (${rgsCode.code})`,
    }));
  }, [rgsCodes, filters, type]);

  return (
    <FormControl>
      <FormLabel>Rgs Code</FormLabel>
      <Autocomplete
        disabled={disabled}
        placeholder={"Selecteer een rgs code"}
        onChange={(_, value) => {
          if (!value) {
            onChange("");
            return;
          }
          onChange(value.key);
        }}
        filterOptions={(options, state) => {
          // Only show autocomplete suggestions if input length is at least 2 characters
          if (state.inputValue.length < 3) {
            return [];
          }
          return options.filter((option) => {
            const show = option.label
              .toLowerCase()
              .includes(state.inputValue.toLowerCase());
            return show;
          });
        }}
        options={options}
        isOptionEqualToValue={(option, value) => {
          return option.key === value.key;
        }}
      ></Autocomplete>
      <FormHelperText>Typ 3 karakters om te zoeken</FormHelperText>
    </FormControl>
  );
};
