import InfoOutlined from "@mui/icons-material/InfoOutlined";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Typography,
} from "@mui/joy";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import { useState } from "react";
import { createDomainInvite } from "../../services/api/createDomainInvite";
import useDomainStore from "../../stores/domain";

type Props = {
  setOpen: (open: boolean) => void;
  open: boolean;
};

export const UserInviteModal = (props: Props) => {
  const { setOpen, open } = props;
  const { domain } = useDomainStore();
  const [loading, setLoading] = useState(false);

  const [invitedUser, setInvitedUser] = useState("");

  const inviteUser = async () => {
    setLoading(true);
    await createDomainInvite(domain, invitedUser);
    setInvitedUser("");
    setLoading(false);
    setOpen(false);
  };

  const regexp = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  const validEmail = regexp.test(invitedUser);

  return (
    <Modal
      aria-labelledby="close-modal-title"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "80%",
        margin: "auto",
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          minWidth: 500,
          borderRadius: "md",
          p: 3,
        }}
      >
        <ModalClose variant="soft" color="primary" />
        <Typography
          component="h2"
          id="close-modal-title"
          level="h4"
          textColor="inherit"
          fontWeight="lg"
        >
          {`Gebruiker toevoegen`}
        </Typography>

        <Stack
          direction={"column"}
          gap={3}
          sx={{
            py: 5,
          }}
        >
          <FormControl>
            <FormLabel>Trigger waarde</FormLabel>
            <Input
              error={!validEmail && invitedUser.length > 0}
              onChange={(e) => {
                setInvitedUser(e.target.value);
              }}
              value={invitedUser}
              placeholder="Bijv. gebruiker@kantoor.nl"
              type="email"
            />
            {!validEmail && invitedUser.length > 0 ? (
              <FormHelperText>
                <InfoOutlined />
                Geen geldig e-mail formaat
              </FormHelperText>
            ) : null}
          </FormControl>
        </Stack>

        <Stack direction={"row"} justifyContent={"space-between"}>
          <Button
            onClick={() => setOpen(false)}
            color="neutral"
            variant="outlined"
            disabled={loading}
          >
            Annuleren
          </Button>
          <Button disabled={!validEmail || loading} onClick={inviteUser}>
            Uitnodigen
          </Button>
        </Stack>
      </Sheet>
    </Modal>
  );
};
