import { useQuery } from "@tanstack/react-query";
import {
  TGetInvalidVatDashboarParams,
  getInvalidVatDashboard,
} from "../../services/api/transactions/getInvalidVatTransactionsDashboard";
import queryTypes from "../../types/hookTypes";

export const useInvalidVatDashboard = (
  config: TGetInvalidVatDashboarParams
) => {
  return useQuery(
    [queryTypes.invalidVatDashboard, Object.values(config)],
    async () => {
      const res = await getInvalidVatDashboard(config);
      return res;
    }
  );
};
