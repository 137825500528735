import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Table from '@mui/joy/Table';
import { Pagination } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '../../components/spinner';
import { SortDirection, ThSort } from '../../components/tables/thSort';
import { useGLAccountMappingDrilldown } from '../../hooks/glaccounts/useGLAccountMappingDrilldown';
import useFilterStore from '../../stores/filter';
import GLAccountMappingDrilldownRow from './tableRow';

enum SortOptions {
  consistency = 'consistency',
}

const PAGE_SIZE = 15;

const EfficiencyTable = () => {
  const { divisionSearch, account } = useFilterStore();
  const params = useParams();
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState<SortOptions>(SortOptions.consistency);
  const [sortDir, setSortDir] = useState<SortDirection>(SortDirection.asc);
  const glAccountMappingOverviewQuery = useGLAccountMappingDrilldown({
    code: params.glaccountcode as string,
    accountID: account,
  });

  const rows = glAccountMappingOverviewQuery.data || [];

  useEffect(() => {
    setPage(0);
  }, [divisionSearch]);

  if (!glAccountMappingOverviewQuery.isSuccess) {
    return <Spinner />;
  }

  return (
    <>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          '& > thead > tr > th:nth-child(n + 3), & > tbody > tr > td:nth-child(n + 3)': { textAlign: 'right' },
          '& > tbody > tr:nth-child(odd) > td, & > tbody > tr:nth-child(odd) > th[scope="row"]': {
            borderBottom: 0,
          },
        }}
      >
        <Table aria-label="basic table" stickyHeader stickyFooter hoverRow>
          <thead>
            <tr>
              <td style={{ width: '3rem' }} />
              <ThSort label="Grootboeknummer" setSort={setSort} sort={sort} sortDir={sortDir} setSortDir={setSortDir} sortKey={SortOptions.consistency} />
              <ThSort label="RGS Code" setSort={setSort} sort={sort} sortDir={sortDir} setSortDir={setSortDir} sortKey={SortOptions.consistency} />
              <th>Aantal mappings</th>
              <th>Beschrijving</th>
            </tr>
          </thead>
          <tbody>
            {rows.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE).map((row) => {
              return <GLAccountMappingDrilldownRow {...row} />;
            })}
          </tbody>
        </Table>
      </Sheet>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Pagination count={Math.ceil(rows.length / PAGE_SIZE)} color="primary" onChange={(_, page) => setPage(page - 1)} page={page + 1} />
      </Box>
    </>
  );
};

export default EfficiencyTable;
