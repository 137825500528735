import client from '../../../utils/axiosClient';

type PaymentTermAutomationMetadata = {
  totalInvoices: number;
  totalManualInvoices: number;
};

export type PaymentTermAutomationForAdministration = {
  administrationId: string;
  administrationName: string;
  administrationHid: string;
  import: number;
  manualEntry: number;
  scanHerken: number;
  electronicInvoice: number;
  total: number;
  journals: {
    journalCode: string;
    journalDescription: string;
    import: number;
    manualEntry: number;
    scanHerken: number;
    electronicInvoice: number;
    total: number;
  }[];
};

export type PaymentTermAutomationDashboardResponse = {
  administrations: PaymentTermAutomationForAdministration[];
  metadata: PaymentTermAutomationMetadata;
};

export type GetPaymentTermsAutomationParams = {
  year: number;
  accountID?: string | null;
};

export const getPaymentTermsAutomation = async (params: GetPaymentTermsAutomationParams) => {
  const response = await client.get<PaymentTermAutomationDashboardResponse>(`/api/paymentTerms/automation`, { params });
  return response.data;
};
