import AccountBalanceWalletRoundedIcon from "@mui/icons-material/AccountBalanceWalletRounded";
import { Stack } from "@mui/joy";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CircularProgress from "@mui/joy/CircularProgress";
import Typography from "@mui/joy/Typography";
import useRgsBalance from "../../../../hooks/dataviz/useRgsBalance";
import { DataVizEditButton } from "../_common/editButton";
import { DataVizDeleteButton } from "../_common/deleteButton";

export interface IBalanceCardProps {
  label: string;
  rgsCode: string;
  administrationId: string;
}

export const BalanceCardComponent = (props: IBalanceCardProps) => {
  const { label, rgsCode, administrationId } = props; // Assuming these are directly on the props
  const formatter = new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
  });

  const rgsBalanceQuery = useRgsBalance(administrationId, rgsCode);

  let value = "Er is iets mis gegaan";
  if (rgsBalanceQuery.isLoading) {
    value = "loading...";
  }
  if (rgsBalanceQuery.isSuccess) {
    value = formatter.format(rgsBalanceQuery.data.amount);
  }

  return (
    <Card
      variant="outlined"
      color="neutral"
      sx={{
        height: "-webkit-fill-available",
        ":hover": { cursor: "pointer" },
      }}
    >
      <CardContent orientation="horizontal">
        <CircularProgress size="lg" thickness={3} value={100} color="success">
          <AccountBalanceWalletRoundedIcon />
        </CircularProgress>
        <CardContent>
          <Typography level="body-md">{label}</Typography>
          <Typography level="h2">{value}</Typography>
        </CardContent>
      </CardContent>
    </Card>
  );
};
