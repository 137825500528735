import { useQuery } from "@tanstack/react-query";
import {
  TGetInvalidVatTransactions,
  getInvalidVatTransactions,
} from "../../services/api/transactions/getInvalidVatTransactions";
import queryTypes from "../../types/hookTypes";

export const useInvalidVatTransactions = (
  config: TGetInvalidVatTransactions
) => {
  return useQuery(
    [queryTypes.invalidVatTransactions, Object.values(config)],
    async () => {
      const res = await getInvalidVatTransactions(config);
      return res;
    }
  );
};
