import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";
import { Stack } from "@mui/joy";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CircularProgress from "@mui/joy/CircularProgress";
import Typography from "@mui/joy/Typography";
import useRgsBalanceTimeseries from "../../../../hooks/dataviz/useRgsBalanceTimeseries";
import LineChartComponent from "../../../financial-dashboard/lineChart";

export interface IBalanceTimeseriesCardProps {
  label: string;
  rgsCode: string;
  administrationId: string;
}

export const BalanceTimeseriesCardComponent = (
  props: IBalanceTimeseriesCardProps
) => {
  const { label, rgsCode, administrationId } = props; // Assuming these are directly on the props
  const formatter = new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
  });

  const rgsBalanceQuery = useRgsBalanceTimeseries(administrationId, rgsCode);

  let message = "Er is iets mis gegaan";
  let values: {
    index: Date;
    value: number;
  }[] = [];
  if (rgsBalanceQuery.isLoading) {
    message = "loading...";
  }

  if (rgsBalanceQuery.isSuccess) {
    values = rgsBalanceQuery.data.timeseries.map((item) => {
      const date = new Date();
      date.setFullYear(item.financialYear);
      date.setMonth(item.financialPeriod);
      return {
        index: date,
        value: item.balanceAmount,
      };
    });
  }

  let currentValue = "Geen data";
  if (values.length > 0) {
    currentValue = formatter.format(values[values.length - 1]?.value);
  }

  return (
    <Card
      variant="outlined"
      color="neutral"
      sx={{
        height: "-webkit-fill-available",
        ":hover": { cursor: "pointer" },
      }}
    >
      <CardContent orientation="vertical">
        <Stack direction={"row"} gap={3}>
          <CircularProgress size="lg" thickness={3} value={100} color="success">
            <TimelineRoundedIcon />
          </CircularProgress>
          <CardContent>
            <Typography level="body-md">{label}</Typography>
            <Typography level="h2">{currentValue}</Typography>
          </CardContent>
        </Stack>
        <CardContent
          sx={{
            marginTop: "1rem",
          }}
        >
          <LineChartComponent
            data={values}
            label={label}
            loading={rgsBalanceQuery.isLoading}
          />
        </CardContent>
      </CardContent>
    </Card>
  );
};
