import { useQuery } from "@tanstack/react-query";
import { getDomainInvites } from "../services/api/getDomainInvites";
import queryTypes from "../types/hookTypes";

const useHook = (domainId: string) => {
  return useQuery([queryTypes.domainInvites, domainId], () =>
    getDomainInvites(domainId)
  );
};

export default useHook;
