import Stack from '@mui/joy/Stack';
import { PaymentTermAutomationTable } from './table';
import DivisionSearch from '../../../../components/divisionSearch';
import { YearSelect } from '../../../../components/yearSelect';
import { AccountSelect } from '../../../../components/accountSelect';
import { Checkbox, FormControl, FormLabel } from '@mui/joy';
import { useState } from 'react';
import Tooltip from '@mui/joy/Tooltip';

export const PaymentTermAutomation = () => {
  const [filterActiveAdministrations, setFilterActiveAdministrations] = useState(true);

  return (
    <Stack direction="column" gap={3}>
      <Stack direction={'row'} gap={3}>
        <AccountSelect />
        <DivisionSearch />
        <YearSelect />
        <FormControl>
          <Tooltip title="Administraties met een totaal groter dan 0" placement="top">
            <FormLabel>Alleen actieve administraties</FormLabel>
          </Tooltip>
          <Checkbox
            label="aan/uit"
            onClick={() => {
              setFilterActiveAdministrations((checked) => !checked);
            }}
            defaultChecked={filterActiveAdministrations}
          />
        </FormControl>
      </Stack>
      <PaymentTermAutomationTable filterActiveAdministrations={filterActiveAdministrations} />
    </Stack>
  );
};
