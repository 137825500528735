import client from "../../utils/axiosClient";

const deleteQualityCheck = async (checkId: string) => {
  const response = await client.delete(
    `/api/completeness/checks/${checkId}`,
    {}
  );
  return response.data;
};

export default deleteQualityCheck;
