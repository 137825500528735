import { useQuery } from "@tanstack/react-query";
import queryTypes from "../types/hookTypes";
import { KiaLegibleTransactionsType } from "../types/types";
import getKiaTransactions from "../services/api/getKiaTransactions";

const useHook = (division: string, year: number) => {
  return useQuery<KiaLegibleTransactionsType>(
    [queryTypes.KiaLegibleTransactions, division, year],
    async () => {
      return getKiaTransactions(division, year);
    }
  );
};

export default useHook;
