import MoreVert from '@mui/icons-material/MoreVert';
import { Dropdown, FormControl, FormLabel, Menu, MenuButton, MenuItem, Stack, Typography } from '@mui/joy';
import IconButton from '@mui/joy/IconButton';
import { AccountSelect } from '../../../../components/accountSelect';
import DivisionSearch from '../../../../components/divisionSearch';
import KpiCard from '../../../../components/KpiCard';
import Spinner from '../../../../components/spinner';
import { UserSelect } from '../../../../components/userSelect';
import useGLAccountEfficiency from '../../../../hooks/useGLAccountEfficiency';
import useFilterStore from '../../../../stores/filter';
import { IndicatorEnum } from '../../../../types/types';
import { exportEfficiencyCompleteness } from './export';
import EfficiencyTable from './table';

export const RgsMappingCompleteness = () => {
  const { account } = useFilterStore();
  const efficiencyQuery = useGLAccountEfficiency({ accountID: account });

  if (!efficiencyQuery.isSuccess) {
    return <Spinner />;
  }

  const cards = [
    {
      title: `${efficiencyQuery.data.percentage}%`,
      subtitle: 'Percentage gekoppeld',
      indicator: IndicatorEnum.green,
    },
    {
      title: `${new Intl.NumberFormat('nl-NL').format(efficiencyQuery.data.nonRgsGLAccounts.length)}`,
      subtitle: 'Niet gekoppeld',
      indicator: IndicatorEnum.green,
    },
    {
      title: `${new Intl.NumberFormat('nl-NL').format(efficiencyQuery.data.totalGLaccounts - efficiencyQuery.data.nonRgsGLAccounts.length)}`,
      subtitle: 'Gekoppeld',
      indicator: IndicatorEnum.green,
    },
  ];

  return (
    <Stack gap={3}>
      <Stack direction={'row'} gap={3}>
        <AccountSelect />
        <DivisionSearch />
        <UserSelect />
        <FormControl disabled={!efficiencyQuery.isSuccess}>
          <FormLabel>Export</FormLabel>
          <Dropdown>
            <MenuButton slots={{ root: IconButton }} slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}>
              <Typography sx={{ paddingX: '.75rem' }}>Kies...</Typography>
              <MoreVert />
            </MenuButton>
            <Menu>
              <MenuItem onClick={() => exportEfficiencyCompleteness(efficiencyQuery.data)} disabled={!efficiencyQuery.isSuccess}>
                Export Excel
              </MenuItem>
            </Menu>
          </Dropdown>
        </FormControl>
      </Stack>
      <Stack gap={3} direction={'row'}>
        {cards.map((card) => (
          <KpiCard key={`${card.title}-${card.subtitle}-${card.indicator}`} value={card.title} label={card.subtitle} onClick={() => {}} />
        ))}
      </Stack>

      <EfficiencyTable />
    </Stack>
  );
};
