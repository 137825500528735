import { Chip, Grid, Stack } from "@mui/joy";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import DivisionSearch from "../../components/divisionSearch";
import Spinner from "../../components/dynamicSpinner";
import { PageHeader } from "../../components/pageHeader";
import { useEOLIntegrationTaskOverview } from "../../hooks/eolIntegration/useTaskOverview";
import useFilterStore from "../../stores/filter";
import dayjs from "../../utils/dayjs";

export const IntegrationDashboardExact = () => {
  const taskOverviewQuery = useEOLIntegrationTaskOverview({});
  const { divisionSearch } = useFilterStore();

  if (!taskOverviewQuery.isSuccess) {
    return <Spinner />;
  }

  const rows = taskOverviewQuery.data.filter((row) => {
    if (divisionSearch) {
      return row.description?.toLowerCase().includes(divisionSearch.toLowerCase());
    }
    return true;
  });

  // Define mui datagrid cols
  const columns: GridColDef[] = [
    { field: "description", headerName: "name", flex: 1, valueFormatter: (params) => params.value || "N/A" },
    { field: "administrationID", headerName: "code", flex: 1 / 2 },
    {
      field: "status",
      headerName: "status",
      flex: 2 / 5,
      type: "number",
      align: "left",
      headerAlign: "left",
      renderCell(params) {
        const value = Number(params.value);
        if (value === 0) {
          return <Chip color="neutral">Inactief</Chip>;
        }
        if (value === 1) {
          return <Chip color="success">Actief</Chip>;
        }
        return <Chip color="warning">{`warning: ${value}`}</Chip>;
      },
    },
    { field: "pendingTasks", headerName: "pendingTasks", flex: 2 / 5, type: "number" },
    { field: "completedTasks", headerName: "completedTasks", flex: 2 / 5, type: "number" },
    { field: "totalTasks", headerName: "totalTasks", flex: 2 / 5, type: "number" },
    {
      field: "failedTasks",
      headerName: "failedTasks",
      flex: 2 / 5,
      type: "number",
      renderCell: (params) => {
        // Make red if >1
        const value = Number(params.value);
        if (value > 0) {
          return <Chip color="warning">{value}</Chip>;
        }
        return value;
      },
    },
    {
      field: "lastTaskCompleted",
      headerName: "lastTaskCompleted",
      flex: 1,
      align: "right",
      headerAlign: "right",
      valueFormatter(params) {
        const parsed = Number.parseInt(params.value) / 1000; // sql server stores it as string because its a bigint
        const formatted = dayjs.unix(parsed).fromNow();
        return formatted;
      },
    },
  ];

  return (
    <Stack gap={5}>
      <PageHeader>Integration Dashboard Exact</PageHeader>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <DivisionSearch />
        </Grid>
        <Grid xs={12}>
          <DataGrid rows={rows} columns={columns} getRowId={(row) => row.administrationID} />
        </Grid>
      </Grid>
    </Stack>
  );
};
