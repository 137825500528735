import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/nl";
import localeData from "dayjs/plugin/localeData";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import i18n from "../translations/i18n";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(localeData);
dayjs.locale(i18n.language);

export default dayjs;
