import { create } from "zustand";

type State = {
  page: number;
  setPage: (page: number) => void;
};

export const useQualityStore = create<State>((set) => {
  return {
    page: 0,
    setPage: (page: number) => {
      return set({ page });
    },
  };
});
