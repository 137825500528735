import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Stack from '@mui/joy/Stack';
import { useEffect, useState } from 'react';
import DivisionSearch from '../../components/divisionSearch';
import { PageHeader } from '../../components/pageHeader';
import { RangeSelect } from '../../components/rangeSelect';
import { UserSelect } from '../../components/userSelect';
import { useMe } from '../../hooks/useMe';
import useFilterStore from '../../stores/filter';
import Modal from './modal';
import CompletenessTable from './table';
import { AccountSelect } from '../../components/accountSelect';

export default function Completeness() {
  const [modalOpen, setModalOpen] = useState(false);
  const { range, divisionSearch, user, account } = useFilterStore();
  const userQuery = useMe();

  const isAdmin = userQuery.data?.Role === 'super' || userQuery.data?.Role === 'admin';

  return (
    <Stack direction="column" gap={3}>
      <PageHeader>Administratie kwaliteit</PageHeader>

      <Stack direction={'row'} sx={{ display: 'flex', justifyContent: 'space-between' }} gap={3}>
        <Stack direction={'row'} gap={3}>
          <UserSelect />
          <AccountSelect />
          <DivisionSearch />
          <RangeSelect />
        </Stack>

        <FormControl>
          <FormLabel></FormLabel>
          <Button
            endDecorator={<SettingsRoundedIcon />}
            onClick={() => {
              setModalOpen(true);
            }}
            disabled={!isAdmin}
            sx={{ marginTop: '1rem' }}
          >
            Checks
          </Button>
        </FormControl>
      </Stack>
      <CompletenessTable
        yearStart={range.yearStart}
        yearEnd={range.yearEnd}
        filterComplete={false}
        monthStart={range.monthStart}
        monthEnd={range.monthEnd}
        divisionSearch={divisionSearch}
        userId={user}
        accountID={account}
      />

      <Modal isOpen={modalOpen} setOpen={setModalOpen} />
    </Stack>
  );
}
