import React from "react";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";

type props = {
  label: string;
  value: string;
  onClick: () => void;
};

function KpiCard(props: props) {
  const { label, value, onClick } = props;

  return (
    <Card
      key={label}
      sx={{ flex: "1", padding: "1rem 2rem", minWidth: "240px" }} // Adjusted for equal space and minimum width
    >
      <CardContent>
        <Typography level="h3">{value}</Typography>
        <Typography level="body-md">{label}</Typography>
      </CardContent>
    </Card>
  );
}

export default KpiCard;
