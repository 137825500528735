import client from "../../utils/axiosClient";

export const unassignUserAdministration = async (
  userId: string,
  administrationId: string
) => {
  const response = await client.delete(
    `/api/users/${userId}/unassignAdministration`,
    { params: { administrationId } }
  );
  return response.data;
};
