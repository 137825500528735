import { useQuery } from '@tanstack/react-query';
import queryTypes from '../types/hookTypes';
import { getCompletenessV2 } from '../services/api/getCompletenessv2';

export const useCompletenessV2 = (
  yearStart: number,
  yearEnd: number,
  monthEnd: number,
  monthStart: number,
  userId: string | null,
  accountID: string | null,
) => {
  return useQuery([queryTypes.completeness, yearStart, yearEnd, monthStart, monthEnd, userId, accountID], async () => {
    const response = await getCompletenessV2(yearStart, yearEnd, monthStart, monthEnd, userId, accountID);
    return response;
  });
};
