import { Stack } from "@mui/joy";
import { IKenGetalCardProps } from ".";
import { KenGetalSelect } from "../../../../components/kenGetalSelect";

export const KenGetalEditor = ({
  props,
  updateProps,
}: {
  props: IKenGetalCardProps;
  updateProps: (props: IKenGetalCardProps) => void;
}) => {
  return (
    <Stack gap={3}>
      <KenGetalSelect
        onChange={(kenGetal) => {
          updateProps({
            ...props,
            kenGetal,
          });
        }}
        value={props.kenGetal}
      />
    </Stack>
  );
};
