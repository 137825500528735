import { useQuery } from "@tanstack/react-query";
import getQualityChecks from "../services/api/getQualityChecks";
import queryTypes from "../types/hookTypes";
import { TQualityCheck } from "../types/types";

const useHook = () => {
  return useQuery<TQualityCheck[]>(
    [queryTypes.qualityChecks],
    getQualityChecks
  );
};

export default useHook;
