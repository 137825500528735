// const languageOptions: Array<Option> = [
//   {
//     key: "en",
//     label: "English",
//   },
//   {
//     key: "nl",
//     label: "Nederlands",
//   },
// ];

export default function Profile() {
  return <p>Under maintenance</p>;

  // const changeLanguage = async (language: string) => {
  //   await i18n.changeLanguage(language);
  // };

  // const currentLanguage: Option = languageOptions.find(
  //   (option) => option.key === i18n.language
  // ) as Option;

  // return (
  //   <main className="m-8 flex flex-col space-y-8">
  //     {/* Header */}
  //     <header className="flex-col justify-between lg:flex">
  //       <p className="text-xl font-bold text-title">
  //         {t(TranslationKeys.profile)}
  //       </p>
  //     </header>

  //     <section className="flex flex-col space-y-8">
  //       {/* Microsoft account data */}
  //       <article className="bg-white border-border border-2 rounded-md p-5 w-[400px] flex flex-col space-y-5">
  //         <div className="flex h-24 w-24 items-center justify-center rounded-full bg-gray-300 text-white">
  //           <UserIcon className="w-12" />
  //         </div>

  //         <ul className="text-black font-medium text-lg flex flex-col space-y-3">
  //           <li className="flex space-x-3">
  //             <UserIcon className="w-5" />
  //             <p>{account.name}</p>
  //           </li>

  //           <li className="flex space-x-3">
  //             <AtSymbolIcon className="w-5" />
  //             <p>{account.username}</p>
  //           </li>

  //           <li>
  //             <button
  //               className="flex w-full space-x-2 items-center border-border border-2 bg-tertiary rounded-md py-2 px-5"
  //               onClick={() => {
  //                 msal.instance.logout();
  //               }}
  //             >
  //               <ArrowLeftOnRectangleIcon className="w-5" />
  //               <p>{t(TranslationKeys.logout)}</p>
  //             </button>
  //           </li>
  //         </ul>
  //       </article>

  //       {/* Settings */}
  //       <header className="flex-col justify-between lg:flex">
  //         <p className="text-xl font-bold text-title">Instellingen</p>
  //       </header>
  //       <ul className="max-w-3xl w-full">
  //         <li>
  //           <span className="flex items-center justify-between text-black">
  //             <div className="flex space-x-3 items-center">
  //               <GlobeAltIcon className="w-5" />
  //               <p className="font-semibold">{t(TranslationKeys.language)}</p>
  //             </div>
  //             <Dropdown
  //               disabled={false}
  //               className="w-48"
  //               onSelect={(option: Option) => {
  //                 changeLanguage(option.key as string);
  //               }}
  //               options={languageOptions}
  //               value={currentLanguage.key}
  //             />
  //           </span>
  //         </li>
  //       </ul>
  //     </section>
  //   </main>
  // );
}
