import { Checkbox, FormControl, FormLabel } from '@mui/joy';
import Stack from '@mui/joy/Stack';
import { useState } from 'react';
import { AccountSelect } from '../../../../components/accountSelect';
import Spinner from '../../../../components/spinner';
import { UserSelect } from '../../../../components/userSelect';
import useGLAccountEfficiency from '../../../../hooks/useGLAccountEfficiency';
import useFilterStore from '../../../../stores/filter';
import { ConsistencyTable } from './table';

export const RgsConsistency = () => {
  const efficiencyQuery = useGLAccountEfficiency();
  const [filterComplete, setFilterComplete] = useState(true);
  const { user, account } = useFilterStore();

  if (!efficiencyQuery.isSuccess) {
    return <Spinner />;
  }

  return (
    <Stack direction="column" gap={3}>
      <Stack direction={'row'} gap={3}>
        <AccountSelect />
        <UserSelect />
        <FormControl>
          <FormLabel>Filter onvolledige koppelingen</FormLabel>
          <Checkbox
            label="aan/uit"
            onClick={() => {
              setFilterComplete((checked) => !checked);
            }}
            defaultChecked={filterComplete}
          />
        </FormControl>
      </Stack>
      <ConsistencyTable filterComplete={filterComplete} userId={user} accountID={account} />
    </Stack>
  );
};
