import { useQuery } from "@tanstack/react-query";
import queryTypes from "../types/hookTypes";
import getQualityCheckConfiguration from "../services/api/getQualityCheckConfiguration";

const useHook = (divisionCode: string) => {
  return useQuery<any>(
    [queryTypes.qualityCheckConfiguration, divisionCode],
    async () => {
      if (!divisionCode) return [];
      const response = await getQualityCheckConfiguration(divisionCode);
      return response;
    }
  );
};

export default useHook;
