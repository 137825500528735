import { useQuery } from "@tanstack/react-query";
import { getRgsBalance } from "../../services/api/dataviz/getBalance";
import queryTypes from "../../types/hookTypes";

const useHook = (administrationId: string, rgsCode: string) => {
  return useQuery(
    [queryTypes.rgsBalance, administrationId, rgsCode],
    async () => {
      const res = await getRgsBalance(administrationId, rgsCode);
      return res;
    }
  );
};

export default useHook;
