import DeleteRounded from "@mui/icons-material/DeleteRounded";
import { IconButton } from "@mui/joy";

const stopPropagation = (event: any) => {
  event.stopPropagation();
};

export const DataVizDeleteButton = ({
  onClickDelete,
}: {
  onClickDelete: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) => {
  return (
    <IconButton
      variant="soft"
      color="neutral"
      onClick={onClickDelete}
      onMouseDown={stopPropagation}
      onTouchStart={stopPropagation}
    >
      <DeleteRounded />
    </IconButton>
  );
};
