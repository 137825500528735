import { useQuery } from "@tanstack/react-query";
import queryTypes from "../../types/hookTypes";
import { getKpiChecksByAdministration } from "../../services/api/insights/getKpiChecksByAdministration";

export const useKpiChecksByAdministration = (administrationId: string) => {
  return useQuery(
    [queryTypes.kpiChecksByAdministration, administrationId],
    () => getKpiChecksByAdministration(administrationId)
  );
};
