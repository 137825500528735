import client from "../../utils/axiosClient";

const getDivisions = async (userFilter: string | null) => {
  const response = await client.get("/api/divisions", {
    params: { userFilter },
  });
  return response.data.data;
};

export default getDivisions;
