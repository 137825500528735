import { ModalClose, ModalDialog, Stack, Typography } from "@mui/joy";
import Modal from "@mui/joy/Modal";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import "react-circular-progressbar/dist/styles.css";
import useDomainInvites from "../../hooks/useDomainInvites";
import useDomainStore from "../../stores/domain";

type Props = {
  setOpen: (open: boolean) => void;
  open: boolean;
};

export const DomainInvitesModal = (props: Props) => {
  const { setOpen, open } = props;
  const { domain } = useDomainStore();
  const domainInvitesQuery = useDomainInvites(domain);

  const domainInvites = domainInvitesQuery.data || [];

  return (
    <Modal
      aria-labelledby="close-modal-title"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "80%",
        margin: "auto",
      }}
    >
      <ModalDialog size="md">
        <ModalClose variant="plain" sx={{ m: 1 }} />
        <Typography level="h2">Openstaande uitnodigingen</Typography>
        <Sheet
          className="OrderTableContainer"
          variant="outlined"
          sx={{
            width: "100%",
            borderRadius: "sm",
            flexShrink: 1,
            overflow: "auto",
            minHeight: 0,
            maxHeight: "80vh",
          }}
        >
          <Stack direction={"column"} gap={3}>
            <Table
              stickyHeader
              stickyFooter
              hoverRow
              sx={{
                "--TableCell-height": "10px",
                "& tr > *:last-child": {
                  paddingLeft: "1rem",
                },
              }}
            >
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {domainInvites.map((row) => {
                  return (
                    <tr key={row.email}>
                      <td>{row.email}</td>
                      <td>Niet verwerkt</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Stack>
        </Sheet>
      </ModalDialog>
    </Modal>
  );
};
