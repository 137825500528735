import { FormControl, FormLabel, Input } from "@mui/joy";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import useDivision from "../../../hooks/useDivision";

export const GeneralAdministrationInfo = () => {
  const params = useParams();
  const { divisionId } = params;
  const divisionQuery = useDivision(divisionId as string);

  const division: any = divisionQuery.data || {};

  return (
    <Grid container spacing={2}>
      {Object.keys(division).map((key) => {
        return (
          <Grid item xs={12} md={6}>
            <FormControl>
              <FormLabel>{key}</FormLabel>
              <Input
                placeholder={"Niet beschikbaar"}
                value={division[key]}
                disabled
              />
            </FormControl>
          </Grid>
        );
      })}
    </Grid>
  );
};
