import client from "../../../utils/axiosClient";

export type TDeleteNoteParams = {
  noteId: string;
};

export const deleteNote = async (params: TDeleteNoteParams) => {
  const response = await client.delete(`/api/notes/${params.noteId}`);
  return response.data;
};
