import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Box, Button, Divider, Input, Option, Select, Stack } from '@mui/joy';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { TPostBalanceCheck } from '../../../services/api/insights/postBalanceCheck';

export const LARGEST_VALUE = 999999999999;

type BalanceChekSubFormProps = {
  onChange: (checkData: Partial<TPostBalanceCheck>[]) => void;
  balanceChecks?: TPostBalanceCheck[];
};

export const balanceCheckIconOptions: {
  id: string;
  label: string;
  component: any;
  color: string;
}[] = [
  {
    component: WarningRoundedIcon,
    id: 'warning',
    label: 'Warning',
    color: '#f59e0b',
  },
  {
    component: ErrorRoundedIcon,
    id: 'error',
    label: 'Error',
    color: '#ef4444',
  },
];

export const BalanceCheckSubForm = ({ onChange }: BalanceChekSubFormProps) => {
  const [state, setState] = useState<Partial<TPostBalanceCheck>[]>([
    {
      icon: 'warning',
      lowerBound: -LARGEST_VALUE,
      upperBound: LARGEST_VALUE,
      label: '',
    },
  ]);

  useEffect(() => {
    onChange(state);
  }, [state]);

  return (
    <Stack direction={'column'} gap={3}>
      {state.map((check, index) => {
        return (
          <Stack direction={'row'} gap={2}>
            <Stack direction={'column'} gap={1}>
              <Button
                variant="plain"
                onClick={() => {
                  const newState = [...state].filter((_, i) => i !== index);
                  setState(newState);
                }}
                sx={{ padding: 0 }}
              >
                <RemoveCircleRoundedIcon />
              </Button>
              <Box />
            </Stack>
            <Stack direction={'column'} gap={1}>
              {/* Metadata */}
              <Stack direction={'row'} gap={5} justifyContent={'space-between'}>
                <Input
                  placeholder={'Naam'}
                  onChange={(e) => {
                    const newState = [...state];
                    newState[index].label = e.target.value;
                    setState(newState);
                  }}
                />
                <Select
                  defaultValue={balanceCheckIconOptions[0].id}
                  renderValue={(selectedOption) => {
                    if (!selectedOption) {
                      return <Typography>Icoon</Typography>;
                    }
                    const option = balanceCheckIconOptions.find((option) => option.id === selectedOption.value);
                    if (!option) {
                      return <Typography>Icoon</Typography>;
                    }
                    return <option.component sx={{ color: option.color }} />;
                  }}
                  onChange={(_, value) => {
                    setState((state) => {
                      if (!value) return state;
                      const newState = [...state];
                      newState[index].icon = value;
                      return newState;
                    });
                  }}
                >
                  {balanceCheckIconOptions.map((option) => {
                    return (
                      <Option key={option.id} value={option.id}>
                        <option.component sx={{ color: option.color }} /> {option.label}
                      </Option>
                    );
                  })}
                </Select>
              </Stack>

              {/* Details */}
              <Stack direction={'row'} gap={5} justifyContent={'space-between'}>
                <Input
                  placeholder={'min'}
                  type="number"
                  onChange={(e) => {
                    const newState = [...state];
                    const newValue = parseInt(e.target.value);
                    if (isNaN(newValue)) {
                      newState[index].lowerBound = -LARGEST_VALUE;
                      setState(newState);
                      return;
                    }

                    newState[index].lowerBound = newValue;
                    setState(newState);
                  }}
                />
                <Typography sx={{ alignContent: 'center' }}>t/m</Typography>
                <Input
                  placeholder={'max'}
                  type="number"
                  onChange={(e) => {
                    const newState = [...state];
                    const newValue = parseInt(e.target.value);
                    if (isNaN(newValue)) {
                      newState[index].upperBound = LARGEST_VALUE;
                      setState(newState);
                      return;
                    }

                    newState[index].upperBound = newValue;
                    setState(newState);
                  }}
                />
              </Stack>
            </Stack>
            <Divider />
          </Stack>
        );
      })}

      <Stack alignItems={'end'}>
        <Button
          variant="plain"
          endDecorator={<AddCircleRoundedIcon />}
          onClick={() => {
            setState([
              ...state,
              {
                icon: 'warning',
                lowerBound: -LARGEST_VALUE,
                upperBound: LARGEST_VALUE,
              },
            ]);
          }}
        >
          Regel toevoegen
        </Button>
      </Stack>
      <Divider />
    </Stack>
  );
};
