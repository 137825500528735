import { useQuery } from "@tanstack/react-query";
import queryTypes from "../types/hookTypes";
import getOutstandingReceivables from "../services/api/getOutstandingReceivables";

const useHook = () => {
  return useQuery<any>([queryTypes.outstandingReceivables], async () => {
    const response = await getOutstandingReceivables();
    return response;
  });
};

export default useHook;
