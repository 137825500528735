import OutstandingReceivablesTable from "./table";

export default function Receivables() {
  return (
    <div className="h-full w-full flex-col space-y-5 p-12">
      <header className="flex-col justify-between lg:flex">
        <p className="text-xl font-bold text-title">
          Openstaande Verkoopfacturen
        </p>
      </header>

      <OutstandingReceivablesTable />
    </div>
  );
}
