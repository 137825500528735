import client from '../../../utils/axiosClient';

export type PostTransactionVatFlagOverrideBody = {
  transactionId: string;
  reason: string;
};

export const postTransactionVatFlagOverride = async (data: PostTransactionVatFlagOverrideBody) => {
  const response = await client.post(`/api/transactionVatFlagOverrides`, data);
  return response.data;
};
