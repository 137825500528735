import { Autocomplete } from '@mui/joy';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import { useAccounts } from '../../hooks/accounts/useAccounts';
import useFilterStore from '../../stores/filter';

export const AccountSelect = ({ disabled = false }: { disabled?: boolean }) => {
  const { setAccount, account } = useFilterStore();
  const accountsQuery = useAccounts();

  const accounts = accountsQuery.data || [];

  const options: { key: string; label: string }[] = accounts.map((account) => {
    return {
      key: account.accountID,
      label: account.accountName,
    };
  });

  return (
    <FormControl>
      <FormLabel>Omgeving</FormLabel>
      <Autocomplete
        disabled={disabled}
        placeholder={'Selecteer een omgeving'}
        onChange={(_, value) => {
          setAccount(value?.key || null);
        }}
        options={options}
        value={options.find((option) => option.key === account)}
      ></Autocomplete>
    </FormControl>
  );
};
