import { zodResolver } from "@hookform/resolvers/zod";
import FlagRoundedIcon from "@mui/icons-material/FlagRounded";
import {
  Autocomplete,
  AutocompleteOption,
  Avatar,
  Box,
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  ListItemContent,
  ListItemDecorator,
  ModalDialog,
  Option,
  Select,
  Stack,
  Textarea,
} from "@mui/joy";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import { Divider, Typography } from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTasks } from "../../hooks/tasks/useTasks";
import { useUsers } from "../../hooks/useUsers";
import { dateToUnix } from "../../services/api/_utils";
import { Task } from "../../services/api/tasks/getTasks";
import { patchTask } from "../../services/api/tasks/patchTask";
import { newTaskFormSchema, NewTaskFormType, postTask } from "../../services/api/tasks/postTask";
import { useTaskStore } from "../../stores/taskStore";
import { mapPriorityToColor, priorityList } from "./utils";

const formatDateForInput = (unixTimestamp: number) => {
  if (!unixTimestamp) return ""; // Handle cases where dueDate might be null or undefined
  const date = new Date(unixTimestamp);
  // Get the local date and time in "YYYY-MM-DDTHH:mm" format
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const formatted = `${year}-${month}-${day}T${hours}:${minutes}`;
  return formatted;
};

type NewTaskModalProps = {};

export const TaskUpsertModal = (props: NewTaskModalProps) => {
  const { taskModalOpen, setTaskModalOpen, loading, setLoading, selectedTask, setSelectedTask } = useTaskStore();
  const tasksQuery = useTasks();
  const usersQuery = useUsers();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<NewTaskFormType>({
    resolver: zodResolver(newTaskFormSchema),
  });

  const close = () => setTaskModalOpen(false);

  const cancel = () => {
    setSelectedTask(null);
    close();
  };

  const getSubmitLabel = () => {
    return selectedTask ? "Taak bijwerken" : "Taak aanmaken";
  };

  const tasks = tasksQuery.data || [];
  const users = usersQuery.data || [];
  const onSubmit = handleSubmit(async (data) => {
    try {
      setLoading(true);
      if (selectedTask) {
        // If a task is selected, we are editing it
        const updatedTask: Task = { ...selectedTask, ...data, dueDate: dateToUnix(new Date(data.dueDate)) };
        await patchTask(updatedTask);
      } else {
        // If no task is selected, we are creating a new task
        const newTask: NewTaskFormType = { ...data, dueDate: dateToUnix(new Date(data.dueDate)) };
        await postTask(newTask);
      }

      tasksQuery.refetch();
      setSelectedTask(null);
      close();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    if (selectedTask) {
      console.log("assigned id input", selectedTask.assignedUserID);
      reset({
        title: selectedTask.title || "",
        description: selectedTask.description || "",
        dueDate: selectedTask.dueDate || new Date().getTime(), // Unix timestamp
        priority: selectedTask.priority || 0,
        parentTaskID: selectedTask.parentTaskID || null,
        assignedUserID: selectedTask.assignedUserID || null,
      });
    } else {
      reset({
        title: "",
        description: "",
        dueDate: new Date().getTime(), // Unix timestamp
        priority: 0,
        parentTaskID: null,
        assignedUserID: null,
      });
    }
  }, [selectedTask, reset]);

  return (
    <Modal aria-labelledby="close-modal-title" open={taskModalOpen} onClose={cancel}>
      <ModalDialog size="lg">
        <DialogTitle>Nieuwe taak aanmaken</DialogTitle>
        <DialogContent>Vul de velden in om een taak te maken</DialogContent>
        <ModalClose variant="outlined" />

        <form onSubmit={onSubmit}>
          <fieldset disabled={loading}>
            <Stack direction={"row"} gap={3}>
              {/* Left column */}
              <Stack gap={1}>
                <FormControl error={!!errors.title}>
                  <FormLabel>Titel*</FormLabel>
                  <Input type="text" placeholder="Titel" {...register("title")} />
                  {errors.title && <FormHelperText>{errors.title.message}</FormHelperText>}
                </FormControl>
                <FormControl error={!!errors.description}>
                  <FormLabel>Beschrijving (optioneel)</FormLabel>
                  <Textarea placeholder="Beschrijving" {...register("description")} minRows={8} sx={{ width: "35rem" }} />
                  {errors.description && <FormHelperText>{errors.description.message}</FormHelperText>}
                </FormControl>
              </Stack>

              <Divider orientation="vertical" flexItem />

              {/* Right column */}
              <Stack spacing={1} justifyContent={"space-between"}>
                <FormControl error={!!errors.parentTaskID}>
                  <FormLabel>Parent (optioneel)</FormLabel>
                  <Controller
                    name="parentTaskID"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        options={tasks}
                        getOptionLabel={(option) => option.title}
                        isOptionEqualToValue={(option, value) => option.taskID === value.taskID}
                        onChange={(event, newValue) => {
                          field.onChange(newValue ? newValue.taskID : null);
                        }}
                      />
                    )}
                  />
                  {errors.parentTaskID && <FormHelperText>{errors.parentTaskID?.message}</FormHelperText>}
                </FormControl>
                <FormControl error={!!errors.dueDate}>
                  <FormLabel>Deadline*</FormLabel>
                  <Controller
                    name="dueDate"
                    control={control}
                    render={({ field }) => (
                      <Input
                        type="datetime-local"
                        value={formatDateForInput(field.value)} // Format Unix timestamp for display
                        onChange={(e) => {
                          const dateValue = e.target.value;
                          const unixTimestamp = new Date(dateValue).getTime(); // Convert back to Unix timestamp
                          field.onChange(unixTimestamp);
                        }}
                      />
                    )}
                  />
                  {errors.dueDate && <FormHelperText>{errors.dueDate.message}</FormHelperText>}
                </FormControl>
                <FormControl error={!!errors.priority}>
                  <FormLabel>Prioriteit*</FormLabel>
                  <Controller
                    name="priority"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        onChange={(_, newValue) => {
                          if (newValue === null) return;
                          const parsedValue = Number.parseInt(newValue as any);
                          field.onChange(parsedValue);
                        }}
                        renderValue={(value) => {
                          if (value === null) return null; // Check for null or undefined
                          const flagColor = mapPriorityToColor(Number.parseInt(value.value as any)); // Bit finicky, but it works
                          const label = priorityList.find((item) => item.value === value.value)?.label;
                          return (
                            <Stack direction={"row"}>
                              <ListItemDecorator>
                                <FlagRoundedIcon key={flagColor} color={flagColor} sx={{ marginRight: ".5rem" }} />
                              </ListItemDecorator>
                              <Typography>{label}</Typography>
                            </Stack>
                          );
                        }}
                      >
                        {priorityList.map((item) => {
                          const flagColor = mapPriorityToColor(item.value);
                          return (
                            <Option key={item.value} value={item.value}>
                              <ListItemDecorator>
                                <FlagRoundedIcon color={flagColor} />
                              </ListItemDecorator>
                              {item.label}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  />
                  {errors.priority && <FormHelperText>{errors.priority.message}</FormHelperText>}
                </FormControl>

                <FormControl error={!!errors.assignedUserID}>
                  <FormLabel>Verantwoordelijke (optioneel)</FormLabel>
                  <Controller
                    name="assignedUserID"
                    control={control}
                    render={({ field }) => {
                      const selectedOption = users.find((user) => user.id === field.value) || null;
                      return (
                        <Autocomplete
                          options={users}
                          getOptionLabel={(option) => option.email}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          value={selectedOption}
                          onChange={(event, newValue) => {
                            field.onChange(newValue ? newValue.id : null);
                          }}
                          placeholder="Select User"
                          renderOption={(props, option) => (
                            <AutocompleteOption {...props}>
                              <ListItemDecorator sx={{ marginRight: ".5rem" }}>
                                <Avatar />
                              </ListItemDecorator>
                              <ListItemContent sx={{ fontSize: "sm" }}>{option.email}</ListItemContent>
                            </AutocompleteOption>
                          )}
                        />
                      );
                    }}
                  />
                  {errors.assignedUserID && <FormHelperText>{errors.assignedUserID?.message}</FormHelperText>}
                </FormControl>

                <Box sx={{ marginY: ".3rem" }} />
              </Stack>
            </Stack>

            <Box sx={{ marginY: "3rem" }} />
            <Stack justifyContent={"end"} direction={"row"} gap={1}>
              <Button color="neutral" variant="soft" onClick={cancel} loading={loading}>
                Annuleren
              </Button>
              <Button loading={loading} type="submit">
                {getSubmitLabel()}
              </Button>
            </Stack>
          </fieldset>
        </form>
      </ModalDialog>
    </Modal>
  );
};
