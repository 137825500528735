import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Box, Button } from "@mui/joy";
import Stack from "@mui/joy/Stack";
import "react-circular-progressbar/dist/styles.css";
import { PageHeader } from "../../components/pageHeader";
import { useTaskStore } from "../../stores/taskStore";
import { TaskTable } from "./taskTable";
import { TaskUpsertModal } from "./taskUpsertModal";

export const TasksPage = () => {
  const taskStore = useTaskStore();
  const openCreateTaskModal = () => taskStore.setTaskModalOpen(true);

  return (
    <>
      <TaskUpsertModal />

      <Stack direction="column" gap={3}>
        <PageHeader>Taken</PageHeader>

        <Stack direction={"column"} gap={3}>
          {/* Filters */}
          <Stack direction={"row"} gap={3} justifyContent={"space-between"} alignItems={"end"}>
            <Stack direction={"row"} gap={3}>
              {/* <UserSelect /> */}
            </Stack>
            <Box>
              <Button startDecorator={<AddRoundedIcon />} onClick={openCreateTaskModal}>
                Taak
              </Button>
            </Box>
          </Stack>

          {/* Content */}
          <TaskTable />
        </Stack>
      </Stack>
    </>
  );
};
