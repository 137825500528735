import { useQuery } from '@tanstack/react-query';
import { GetQualityCheckOverridesParams, getQualityCheckOverrides } from '../../services/api/qualityCheckOverrides/getQualityCheckOverrides';
import queryTypes from '../../types/hookTypes';

export const useQualityCheckOverrides = (params: GetQualityCheckOverridesParams) => {
  return useQuery([queryTypes.qualityCheckOverrides, ...Object.values(params)], async () => {
    const res = await getQualityCheckOverrides(params);
    return res;
  });
};
