import client from '../../utils/axiosClient';

export type TRecommendation = {
  rgsL4: string;
  score: number;
};

const getRecommendationsByDivision = async (divisionCode: string) => {
  const response = await client.get<TRecommendation[]>(`/api/completeness/checks/${divisionCode}/recommendations`);
  return response.data;
};

export default getRecommendationsByDivision;
