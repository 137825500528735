import { useQuery } from "@tanstack/react-query";
import queryTypes from "../types/hookTypes";
import getCompletenessTransactions from "../services/api/getCompletenessTransactions";

const useHook = (
  divisionCode: string,
  year: number,
  month: number,
  checkId: string
) => {
  return useQuery(
    [queryTypes.completenessTransactions, divisionCode, year, month, checkId],
    async () => {
      if (!divisionCode || !year || !month || !checkId) return [];
      const response = await getCompletenessTransactions(
        divisionCode,
        year,
        month,
        checkId
      );
      return response;
    }
  );
};

export default useHook;
