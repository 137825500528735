import { Grid } from '@mui/joy';
import { Typography } from '@mui/material';
import { useNotes } from '../../hooks/notes/useNotes';
import { Note } from '../completeness-drilldown/note';

export const Notes = (props: { administrationIds: string[]; year: number; period: number }) => {
  const { administrationIds, year, period } = props;
  const { data, isLoading, error, isError, refetch } = useNotes({
    administrationIds,
    year,
    period,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>{JSON.stringify(error)}</div>;
  }

  if (!data.length) {
    return (
      <Typography alignContent={'center'} textAlign={'center'} marginY={'5rem'}>
        Geen notities gevonden
      </Typography>
    );
  }

  return (
    <Grid container spacing={1}>
      {data.map((note) => (
        <Grid xs={12}>
          <Note note={note} refetch={refetch} />
        </Grid>
      ))}
    </Grid>
  );
};
