import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import { Avatar, Box, Button, DialogContent, DialogTitle, List, ListItem, Modal, ModalDialog, Typography } from "@mui/joy";
import { ListItemIcon, ListItemText } from "@mui/material";
import TwinfieldLogo from "../../../assets/TwinfieldLogo.png";
import useDomainStore from "../../../stores/domain";

export type TwinfieldConnectorModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const TwinfieldConnectorModal = (props: TwinfieldConnectorModalProps) => {
  const { open, setOpen } = props;
  const { domain } = useDomainStore();

  const redirectUrl = `${window.location.href}/twinfield/callback`;
  const nonce = "123";
  const clientId = "bottleneck_AIDA";
  const scope = "openid+offline_access+twf.organisation+twf.organisationUser+twf.user";
  const state = domain;
  const authURL = `https://login.twinfield.com/auth/authentication/connect/authorize?client_id=${clientId}&redirect_uri=${redirectUrl}&response_type=code&scope=${scope}&nonce=${nonce}&state=${state}`;

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <ModalDialog>
        <DialogTitle sx={{ alignItems: "center" }}>
          <Avatar src={TwinfieldLogo} sx={{ marginRight: "1rem" }} />
          Maak een API Connectie met Twinfield
        </DialogTitle>
        <DialogContent>
          <Typography id="nested-modal-description" textColor="text.tertiary">
            Koppel eenvoudig uw Twinfield-account aan AIDA om naadloos uw boekhoudgegevens te synchroniseren. Door verbinding te maken, kunt u uw financiële
            gegevens automatisch importeren zonder handmatige invoer.
          </Typography>
          <br />
          Hoe werkt het?
          <List>
            <ListItem>
              <ListItemIcon>
                <ArrowRightRoundedIcon />
              </ListItemIcon>
              <ListItemText>Klik op de knop 'Verbinding maken met Twinfield'.</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ArrowRightRoundedIcon />
              </ListItemIcon>
              <ListItemText>Log in met uw Twinfield-account.</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ArrowRightRoundedIcon />
              </ListItemIcon>
              <ListItemText>Autoriseer de toegang tot uw gegevens.</ListItemText>
            </ListItem>
          </List>
          Zodra de koppeling tot stand is gebracht, worden uw Twinfield-gegevens automatisch gesynchroniseerd.
          <Box
            sx={{
              mt: 1,
              display: "flex",
              gap: 1,
              flexDirection: { xs: "column", sm: "row-reverse" },
            }}
          >
            <Button
              variant="solid"
              color="primary"
              onClick={() => {
                window.location.href = authURL;
              }}
            >
              Verbinding maken met Twinfield
            </Button>
            <Button variant="outlined" color="neutral" onClick={() => setOpen(false)}>
              Annuleren
            </Button>
          </Box>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};
