import { useQuery } from '@tanstack/react-query';
import queryTypes from '../types/hookTypes';
import getRecommendationsByDivision from '../services/api/getRecommendationsByDivision';

export const useRecommendationsByDivision = (
  divisionCode: string,
) => {
  return useQuery(
    [
      queryTypes.recommendationsByDivision,
      divisionCode,
    ],
    async () => {
      if (!divisionCode) {
        return null;
      }
      return await getRecommendationsByDivision(
        divisionCode,
      );
    },
  );
};
