import client from "../../../utils/axiosClient";

export type TGetNotesParams = {
  administrationIds?: string[];
  year?: number;
  period?: number;
};

export type TNote = {
  id: string;
  administrationId: string;
  rgsCode: string;
  financialYear: number;
  financialPeriod: number;
  createdAt: string;
  modifiedAt: string;
  text: string;
  status: number;
};

export const getNotes = async (params: TGetNotesParams) => {
  const response = await client.get<TNote[]>(`/api/notes`, {
    params,
  });
  return response.data;
};
