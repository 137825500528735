import { Button, Modal, ModalDialog, Stack, Typography } from '@mui/joy';
import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';
import Table from '@mui/joy/Table';
import { useTransactions } from '../../hooks/transactions/useTransactions';
import dayjs from '../../utils/dayjs';
import { useState } from 'react';
import { FormControl, FormLabel } from '@mui/material';

export const SuggestedKiaTransactions = ({ year, administrationId }: { year: number; administrationId: string }) => {
  const startDate = `${year}-01-01`;
  const endDate = `${year + 1}-01-01`;
  const suggestedTransactionsQuery = useTransactions({
    administrationId,
    rgsCodes: ['WBedEemKai', 'WBedEemKam', 'WBedKanKak', 'WBedKanTef'],
    minAmount: 450,
    startDate,
    endDate,
  });
  const [open, setOpen] = useState(false);

  if (!suggestedTransactionsQuery.isSuccess) {
    return null;
  }

  const formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
  });

  const rows = suggestedTransactionsQuery.data || [];

  return (
    <>
      <FormControl>
        <FormLabel>Kia omboek suggesties</FormLabel>
        <Button variant="soft" disabled={rows.length === 0} sx={{ width: '16rem' }} onClick={() => setOpen(true)}>
          {`Bekijken (${rows.length})`}
        </Button>
      </FormControl>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <ModalDialog size="md">
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Stack direction={'column'} gap={3}>
            {/* Title */}

            <Typography level="h2">{`KIA Omboek suggesties`}</Typography>

            <Sheet
              className="OrderTableContainer"
              variant="outlined"
              sx={{
                width: '100%',
                borderRadius: 'sm',
              }}
            >
              <Table aria-label="basic table" stickyHeader stickyFooter>
                <thead>
                  <tr>
                    <th>Datum</th>
                    <th>Omschrijving</th>
                    <th>Bedrag</th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row) => (
                    <tr key={row.transactionId}>
                      <td>{dayjs(row.transactionDate).format('DD-MM-YYYY')}</td>
                      <td>{row.description}</td>
                      <td>{formatter.format(row.transactionAmount)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Sheet>
          </Stack>
        </ModalDialog>
      </Modal>
    </>
  );
};
