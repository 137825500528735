import { ClerkProvider, RedirectToSignIn, SignedIn, SignedOut } from '@clerk/clerk-react';
import { nlNL } from '@clerk/localizations';
import '@fontsource/inter';
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {
  THEME_ID as MATERIAL_THEME_ID,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  experimental_extendTheme as materialExtendTheme,
} from '@mui/material/styles';
import { QueryClientProvider } from '@tanstack/react-query';
import { nl } from 'date-fns/locale';
import setDefaultOptions from 'date-fns/setDefaultOptions';
import { useEffect, useState } from 'react';
import 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import Layout from './components/layout';
import Spinner from './components/spinner';
import { AdministrationDetails } from './pages/administration';
import Administrations from './pages/administrations';
import ExactData from './pages/administrations-exact-data';
import CompletenessDrilldown from './pages/completeness-drilldown';
import Completeness from './pages/completeness/index';
import { OpenAPIDocumentation } from './pages/docs';
import { Efficiency } from './pages/efficiency';
import { EfficiencyDrilldown } from './pages/efficiency-drilldown';
import FinancialDashboard from './pages/financial-dashboard';
import Home from './pages/home';
import Insights from './pages/insights';
import InsightsDrilldown from './pages/insights-drilldown';
import { IntegrationDashboardExact } from './pages/integration-dashboard-eol';
import { IntegrationsPage } from './pages/integrations';
import Kia from './pages/kia';
import KiaTransactions from './pages/kia-transactions';
import Profile from './pages/profile';
import ReceivablesDashboard from './pages/receivables-dashboard';
import { TasksPage } from './pages/tasks';
import { UbtEmbed } from './pages/ubt';
import { UserPage } from './pages/users';
import { InvalidVatDashboard } from './pages/vat';
import { InvalidVatDrilldown } from './pages/vat-drilldown';
import useDomainStore from './stores/domain';
import useFilterStore from './stores/filter';
import { colorSchemes } from './theme';
import './translations/i18n';
import { clerk } from './utils/clerk';
import queryClient from './utils/queryClient';

// Set default locale for date-fns
setDefaultOptions({ locale: nl });

// Ensure Clerk publishable key is present
if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error('Missing Publishable Key');
}

// Extend Material UI theme
const materialTheme = materialExtendTheme({ colorSchemes });

// Reusable loading screen component
const LoadingScreen = ({ children }: { children: React.ReactNode }) => (
  <div className="w-screen h-screen flex items-center justify-center bg-background relative">
    <div className="absolute top-[100px]">{children}</div>
  </div>
);

// SubPrivateRoute component
const SubPriveRoute = () => {
  const [loading, setLoading] = useState(true);
  const domainStore = useDomainStore();
  const filterStore = useFilterStore();

  const initializePortalClient = async () => {
    setLoading(true);
    await domainStore.init();
    await filterStore.setDefaultFilters();
    setLoading(false);
  };

  useEffect(() => {
    if (domainStore.isInitialized) return;
    initializePortalClient();
  }, [domainStore]);

  if (loading) {
    return (
      <LoadingScreen>
        <Spinner />
      </LoadingScreen>
    );
  }

  if (!domainStore.domain) {
    return (
      <LoadingScreen>
        <p>Je bent nog niet toegevoegd aan een domein</p>
      </LoadingScreen>
    );
  }

  return <Outlet />;
};

// PrivateRoute component
const PrivateRoute = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadClerk = async () => {
      await clerk.load();
      setLoading(false);
    };
    loadClerk();
  }, []);

  if (loading) {
    return (
      <LoadingScreen>
        <Spinner />
      </LoadingScreen>
    );
  }

  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <CssVarsProvider disableTransitionOnChange theme={extendTheme({ colorSchemes })}>
        <CssBaseline enableColorScheme />
        <ClerkProvider localization={nlNL} publishableKey={process.env.REACT_APP_CLERK_PUBLISHABLE_KEY!} Clerk={clerk}>
          <Toaster position="bottom-center" reverseOrder={false} />
          <SignedIn>
            <QueryClientProvider client={queryClient}>
              <Layout>
                <SubPriveRoute />
              </Layout>
            </QueryClientProvider>
          </SignedIn>
          <SignedOut>
            <RedirectToSignIn />
          </SignedOut>
        </ClerkProvider>
      </CssVarsProvider>
    </MaterialCssVarsProvider>
  );
};

// Router configuration
const router = createBrowserRouter([
  {
    path: '/docs',
    element: <OpenAPIDocumentation />,
  },
  {
    element: <PrivateRoute />,
    children: [
      { path: '/', element: <Home /> },
      { path: '/profile', element: <Profile /> },
      { path: '/kia', element: <Kia /> },
      { path: '/efficiency/:tab', element: <Efficiency /> },
      { path: '/efficiency/consistency/:glaccountcode', element: <EfficiencyDrilldown /> },
      { path: '/insights', element: <Insights /> },
      { path: '/insights/:divisionId', element: <InsightsDrilldown /> },
      { path: '/kia/transactions/:divisionId', element: <KiaTransactions /> },
      { path: '/financial-dashboard', element: <FinancialDashboard /> },
      { path: '/completeness', element: <Completeness /> },
      { path: '/ubt', element: <UbtEmbed /> },
      { path: '/receivables', element: <ReceivablesDashboard /> },
      { path: '/dashboards/vat', element: <InvalidVatDashboard /> },
      { path: '/dashboards/vat/:administrationId', element: <InvalidVatDrilldown /> },
      { path: '/completeness/:divisionId', element: <CompletenessDrilldown /> },
      { path: '/administrations/:division/data/exact', element: <ExactData /> },
      { path: '/administrations', element: <Administrations /> },
      { path: '/administrations/:divisionId/:tab', element: <AdministrationDetails /> },
      { path: '/oauth/success', element: <Spinner /> },
      { path: '/users', element: <UserPage /> },
      { path: '/dashboards/eol-integration', element: <IntegrationDashboardExact /> },
      {
        path: '/tasks',
        element: <TasksPage />,
      },
      {
        path: '/integrations',
        element: <IntegrationsPage />,
      },
    ],
  },
]);

// App component
const App = () => <RouterProvider router={router} />;

export default App;
