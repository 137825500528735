import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Typography from '@mui/joy/Typography';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { AutomationChip } from './automationChip';
import { Grid, Stack } from '@mui/joy';
import { YearSelect } from '../../../../components/yearSelect';
import { TitleSubtitleCardWithInfo } from './card';
import { formatter, plainFormatter } from '../../../../translations/numberFormatter';
type JournalAutomationModalProps = {
  journals: {
    journalCode: number;
    journalDescription: string;
    import: number;
    manualEntry: number;
    totalManualInvoices: number;
    scanHerken: number;
    total: number;
  }[];
  administrationName: string;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const JournalAutomationModal = ({ open, setOpen, administrationName, journals }: JournalAutomationModalProps) => {
  const columns: GridColDef[] = [
    {
      field: 'journalDescription',
      headerName: 'journalDescription',
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'journalCode',
      headerName: 'journalCode',
      flex: 0.5,
      disableColumnMenu: true,
    },
    {
      field: 'custom_01',
      headerName: 'Automatisering',
      flex: 0.5,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <AutomationChip percentage={params.value} />;
      },
      valueGetter: (params) => {
        if (params.row.total === 0) return 0;
        return Math.round(((params.row.scanHerken + params.row.automatic) / params.row.total) * 100);
      },
    },
    {
      field: 'import',
      headerName: 'Import',
      flex: 0.5,
      disableColumnMenu: true,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (params) => {
        return params.value.toLocaleString('nl-NL');
      },
    },
    {
      field: 'semiAutomatic',
      headerName: 'Digitaal',
      flex: 0.5,
      disableColumnMenu: true,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (params) => {
        return params.value.toLocaleString('nl-NL');
      },
    },
    {
      field: 'manualEntry',
      headerName: 'Handmatig',
      flex: 0.5,
      disableColumnMenu: true,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (params) => {
        return params.value.toLocaleString('nl-NL');
      },
    },
    {
      field: 'scanHerken',
      headerName: 'Webservice',
      flex: 0.5,
      disableColumnMenu: true,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (params) => {
        return params.value.toLocaleString('nl-NL');
      },
    },
    {
      field: 'automatic',
      headerName: 'Automatisch',
      flex: 0.5,
      disableColumnMenu: true,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (params) => {
        return params.value.toLocaleString('nl-NL');
      },
    },
    {
      field: 'total',
      headerName: 'Totaal',
      flex: 0.5,
      disableColumnMenu: true,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (params) => {
        return params.value.toLocaleString('nl-NL');
      },
    },
  ];

  const totalSum = journals.reduce((acc, journal) => acc + journal.total, 0);
  const totalManualEntries = journals.reduce((acc, journal) => acc + journal.totalManualInvoices, 0);
  const percentageManual = Math.floor((totalManualEntries / totalSum) * 100).toString();
  const hoursSaved = (totalManualEntries * 3) / 60;
  const salary = 50;
  const costSaved = formatter.format(Math.round(hoursSaved * 50));

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <ModalDialog aria-labelledby="nested-modal-title" aria-describedby="nested-modal-description" size={'md'} minWidth={'75vw'}>
        <Typography id="nested-modal-title" level="h2">
          {administrationName}
        </Typography>

        <Stack direction={'row'} gap={3}>
          <YearSelect />
        </Stack>
        <Grid container spacing={3}>
          <Grid xs={12} lg={12 / 5}>
            <TitleSubtitleCardWithInfo
              variant="outlined"
              title="Facturen"
              info={`Totaal aantal verwerkte facturen: ${plainFormatter.format(totalSum).toString()}`}
              subtitle={plainFormatter.format(totalSum).toString()}
            />
          </Grid>
          <Grid xs={12} lg={12 / 5}>
            <TitleSubtitleCardWithInfo
              variant="outlined"
              title="Handmatig/digitaal"
              info={`Van alle ${plainFormatter.format(totalSum).toString()} facturen zijn ${plainFormatter
                .format(totalManualEntries)
                .toString()} handmatig of digitaal verwerkt, zonder automatisering`}
              subtitle={plainFormatter.format(totalManualEntries).toString()}
            />
          </Grid>
          <Grid xs={12} lg={12 / 5}>
            <TitleSubtitleCardWithInfo
              variant="outlined"
              title="Als percentage"
              info={`${percentageManual}% van de facturen zijn handmatig of digitaal verwerkt.`}
              subtitle={percentageManual + '%'}
            />
          </Grid>
          <Grid xs={12} lg={12 / 5}>
            <TitleSubtitleCardWithInfo
              variant="outlined"
              title="Te besparen uren"
              info={`Potentiële tijdsbesparing door automatisering: ${plainFormatter.format(hoursSaved)} uur.`}
              subtitle={plainFormatter.format(hoursSaved) + ' uren'}
            />
          </Grid>
          <Grid xs={12} lg={12 / 5}>
            <TitleSubtitleCardWithInfo
              variant="outlined"
              title="Geld te besparen"
              info={`Potentiële besparing door automatisering: : ${costSaved}, berekend met een €${salary} uurtarief.`}
              subtitle={costSaved}
            />
          </Grid>
        </Grid>

        <DataGrid
          rows={journals}
          columns={columns}
          getRowId={(row) => row.journalCode}
          initialState={{
            sorting: {
              sortModel: [{ field: 'manualEntry', sort: 'desc' }],
            },
          }}
        />

        <Box
          sx={{
            mt: 1,
            display: 'flex',
            gap: 1,
            flexDirection: { xs: 'column', sm: 'row-reverse' },
          }}
        >
          <Button variant="outlined" color="neutral" onClick={() => setOpen(false)}>
            Sluiten
          </Button>
        </Box>
      </ModalDialog>
    </Modal>
  );
};
