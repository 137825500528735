import { useQuery } from "@tanstack/react-query";
import {
  TInsightBalanceDrilldownParams,
  getInsightBalanceDrilldown as getInsightBalanceDrilldownService,
} from "../../services/api/insights/getInsightBalanceDrilldown";
import queryTypes from "../../types/hookTypes";

export const useInsightBalanceDrilldown = (
  administrationId: string,
  params: TInsightBalanceDrilldownParams
) => {
  return useQuery(
    [
      queryTypes.insightBalanceDrilldown,
      "balance",
      administrationId,
      params.yearStart,
      params.yearEnd,
      params.monthStart,
      params.monthEnd,
    ],
    async () => {
      const res = await getInsightBalanceDrilldownService(
        administrationId,
        params
      );
      return res;
    }
  );
};
