import client from "../../../utils/axiosClient";

export type TInsightKpiDrilldownRow = {
  triggerAmount: string;
  triggered: boolean;
  checkCode: string;
  value: number;
  status: number;
};

export const getInsightKpiDrilldown = async (administrationId: string) => {
  const response = await client.get<TInsightKpiDrilldownRow[]>(
    `/api/insights/kpi/${administrationId}`,
    {}
  );
  return response.data;
};
