import { Button } from "@mui/joy";
import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import { useNavigate } from "react-router-dom";

export type IntegrationCardProps = {
  title: string;
  description: string;
  imageUrl: string;
  status: 0 | 1 | 2 | 3; // 0 = not available, 1 = available, 2 = connected, 3 = disconnected
  onClick: () => void;
  metaLink?: string;
};

export const IntegrationCard = (props: IntegrationCardProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (props.status === 1) {
      props.onClick();
      return;
    }

    if (props.status === 2) {
      const url = props.metaLink;
      if (!url) {
        console.error(`IntegrationCard: metaLink is not set for ${props.title}`);
        return;
      }
      navigate(url);
      return;
    }
  };

  return (
    <Card
      variant="outlined"
      orientation="horizontal"
      sx={{
        height: "100%",
      }}
      onClick={handleClick}
    >
      <AspectRatio ratio="1" sx={{ width: 90, borderRadius: "100%" }}>
        <img src={props.imageUrl} loading="lazy" alt="" />
      </AspectRatio>
      <CardContent sx={{ justifyContent: "space-between" }}>
        <Typography level="title-lg" id="card-description">
          {props.title}
        </Typography>
        <Typography level="body-sm" aria-describedby="card-description" sx={{ mb: 1 }}>
          <Link overlay underline="none" sx={{ color: "text.tertiary" }}>
            {props.description}
          </Link>
        </Typography>
        {props.status === 0 && (
          <Button disabled={true} color="neutral">
            Niet beschikbaar
          </Button>
        )}

        {props.status === 1 && (
          <Button disabled={false} color="success">
            Connectie maken
          </Button>
        )}

        {props.status === 2 && (
          <Button color="success" variant="outlined">
            Connectie gemaakt
          </Button>
        )}

        {props.status === 3 && (
          <Button disabled={true} color="warning">
            Connectie problemen
          </Button>
        )}
      </CardContent>
    </Card>
  );
};
