import { z } from "zod";
import client from "../../../utils/axiosClient";

export const newTaskFormSchema = z.object({
  title: z.string().min(1, "Title is required"),
  description: z.string().nullable(),
  dueDate: z.number(),
  priority: z.number().int("Priority is required"),
  parentTaskID: z.string().optional().nullable(),
  assignedUserID: z.string().optional().nullable(),
});

export type NewTaskFormType = z.infer<typeof newTaskFormSchema>;

export const postTask = async (task: NewTaskFormType) => {
  const response = await client.post(`/api/tasks`, task);

  return response.data;
};
