import SellRoundedIcon from "@mui/icons-material/SellRounded";
import { Stack } from "@mui/joy";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CircularProgress from "@mui/joy/CircularProgress";
import Typography from "@mui/joy/Typography";
import useProfitLossPeriodTotal from "../../../../hooks/dataviz/useProfitLossPeriodTotal";

export interface IProfitLossCardProps {
  label: string;
  administrationId: string;
  rgsCode: string;
}

export const ProfitLossCardComponent = (props: IProfitLossCardProps) => {
  const { label, administrationId, rgsCode } = props;
  const rgsBalanceQuery = useProfitLossPeriodTotal(administrationId, rgsCode);

  const formatter = new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
  });

  let value = "Er is iets mis gegaan";
  if (rgsBalanceQuery.isLoading) {
    value = "loading...";
  }
  if (rgsBalanceQuery.isSuccess) {
    value = formatter.format(rgsBalanceQuery.data.amount);
  }

  return (
    <Card
      variant="outlined"
      color="neutral"
      sx={{
        height: "-webkit-fill-available",
        ":hover": { cursor: "pointer" },
      }}
    >
      <CardContent orientation="horizontal">
        <CircularProgress size="lg" thickness={3} value={100} color="warning">
          <SellRoundedIcon />
        </CircularProgress>
        <CardContent>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            sx={{ display: "relative" }}
          >
            <Typography level="body-md">{label}</Typography>
          </Stack>
          <Typography level="h2">{value}</Typography>
        </CardContent>
      </CardContent>
    </Card>
  );
};
