import client from '../../../utils/axiosClient';

export type TGLAccountMappingDrilldownRow = {
  glAccountCode: string;
  rgsCode: string;
  administrations: {
    administrationId: string;
    name: string;
    description: string;
  }[];
};

export type GetGLAccountMappingDrilldownParams = {
  code: string;
  accountID?: string | null;
};

export const getGLAccountMappingDrilldown = async (params?: GetGLAccountMappingDrilldownParams) => {
  const response = await client.get('/api/glaccounts/mapping', {
    params,
  });
  return response.data as TGLAccountMappingDrilldownRow[];
};
