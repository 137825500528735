import client from "../../utils/axiosClient";

export const sendQualityMail = async (userId: string, mailTo: string) => {
  const response = await client.post(
    `/api/email/quality`,
    {
      userId,
      mailTo,
    },
    {}
  );
  return response.data.data;
};
