import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import { useNavigate } from "react-router-dom";
import Link from "@mui/joy/Link";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";

export type Props = {
  to: string;
  title: string;
  description: string;
  Icon: React.ElementType;
};

export default function DashboardCard({ description, Icon, title, to }: Props) {
  const navigate = useNavigate();

  return (
    <Card
      key={`${title}-${description}`}
      sx={{
        flex: "1",
        padding: "2rem",
        minWidth: "240px",
        height: "100%",
        "&:hover": {
          boxShadow: "sm",
          borderColor: "neutral.outlinedHoverBorder",
        },
      }} // Adjusted for equal space and minimum width
    >
      <CardContent onClick={() => navigate(to)}>
        <Link overlay underline="none" sx={{ color: "text.tertiary" }}></Link>
        <Stack direction="column" spacing={2}>
          <Stack direction="row" sx={{ justifyContent: "space-between" }}>
            <Typography level="h3">{title}</Typography>
            {/* <Card variant="soft" sx={{ padding: ".2rem" }}>
              <Icon sx={{ color: "primary.500" }} />
            </Card> */}
          </Stack>
          <Typography level="body-md">{description}</Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}
