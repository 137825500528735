import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Table from '@mui/joy/Table';
import { Pagination } from '@mui/material';
import { abbreviateNumber } from 'js-abbreviation-number';
import { useState } from 'react';
import { KiaTransaction } from '../../types/types';
import dayjs from '../../utils/dayjs';
import { TTransaction } from '../../services/api/transactions/getTransactions';

const PAGE_SIZE = 15;

type Props = {
  transactions: TTransaction[];
};

const TransactionTable = (props: Props) => {
  const { transactions } = props;
  const [page, setPage] = useState(0);

  const rows = transactions || [];

  return (
    <>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
          minHeight: 0,
          maxHeight: '60vh',
        }}
      >
        <Table aria-label="basic table" stickyHeader stickyFooter>
          <thead>
            <tr>
              <th>Datum</th>
              <th>Omschrijving</th>
              <th>Bedrag</th>
              <th>Crediteur</th>
            </tr>
          </thead>
          <tbody>
            {rows.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE).map((row) => (
              <tr key={row.transactionId}>
                <td>{dayjs(row.transactionDate).format('DD-MM-YYYY')}</td>
                <td>{row.description}</td>
                <td>€{abbreviateNumber(row.transactionAmount)}</td>
                <td>{row.accountName}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Pagination count={Math.ceil(rows.length / PAGE_SIZE)} color="primary" onChange={(_, page) => setPage(page - 1)} page={page + 1} />
      </Box>
    </>
  );
};

export default TransactionTable;
