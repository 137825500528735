import { useQuery } from "@tanstack/react-query";
import { getInsightKpiDrilldown as getInsightKpiDrilldownService } from "../../services/api/insights/getInsightKpiDrilldown";
import queryTypes from "../../types/hookTypes";

export const useInsightKpiDrilldown = (administrationId: string) => {
  return useQuery(
    [queryTypes.insightOverview, "kpi", administrationId],
    async () => {
      const res = await getInsightKpiDrilldownService(administrationId);
      return res;
    }
  );
};
