// This component will render the right editor form based on the type of component

import { useStore } from "zustand";
import { dataVizStore } from "../../store";
import { BalanceCardEditor } from "../balanceCard/edit";
import { KenGetalEditor } from "../kengetal/edit";
import { ProfitLossCardEditor } from "../profitLossCard/edit";
import { DataVizTypes } from "./IDataVizComponent";

const componentEditorMapper: {
  [key in DataVizTypes]: any;
} = {
  [DataVizTypes.ProfitLossCard]: ProfitLossCardEditor,
  [DataVizTypes.BalanceCard]: BalanceCardEditor,
  [DataVizTypes.KenGetal]: KenGetalEditor,
  [DataVizTypes.balanceTimeseries]: BalanceCardEditor,
  // Add more editors here
};

export const DataVizComponentEditor = () => {
  const { selectedComponent, setSelectedComponent } = useStore(dataVizStore);

  if (!selectedComponent) {
    return null;
  }

  return componentEditorMapper[selectedComponent.type]({
    props: selectedComponent.props,
    updateProps: (props: any) =>
      setSelectedComponent({ ...selectedComponent, props }),
  });
};
