import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import useFilterStore from "../stores/filter";

const options: Array<{ key: number; label: number }> = [
  { key: 2020, label: 2020 },
  { key: 2021, label: 2021 },
  { key: 2022, label: 2022 },
  { key: 2023, label: 2023 },
  { key: 2024, label: 2024 },
].sort((a, b) => b.key - a.key);

export const YearSelect = () => {
  const { year, setYear } = useFilterStore();

  return (
    <FormControl>
      <FormLabel>Jaar</FormLabel>
      <Select
        sx={{ minWidth: "12rem" }}
        onChange={(_, newValue) => {
          if (!newValue) {
            console.error(`Year ${newValue} not found`);
            return;
          }
          setYear(newValue);
        }}
        value={year}
      >
        {options.map((user) => {
          return (
            <Option key={user.key} value={user.key}>
              {user.label}
            </Option>
          );
        })}
      </Select>
    </FormControl>
  );
};
