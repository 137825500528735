import { Typography } from "@mui/joy";
import Stack from "@mui/joy/Stack";
import { useParams } from "react-router-dom";
import NoData from "../../components/noData";
import { PageHeader } from "../../components/pageHeader";
import Spinner from "../../components/spinner";
import { YearSelect } from "../../components/yearSelect";
import useDivision from "../../hooks/useDivision";
import useKiaLegibleTransactions from "../../hooks/useKiaLegibleTransactions";
import useFilterStore from "../../stores/filter";
import { SuggestedKiaTransactions } from "./suggestedTransactions";
import TransactionTable from "./transactionTable";

type Props = {
  renderHeader?: boolean;
};

export default function KiaTransactions({ renderHeader = true }: Props) {
  const params = useParams();
  const divisionString = params.divisionId;
  const division = divisionString as string;
  const { year } = useFilterStore();
  const transactionQuery = useKiaLegibleTransactions(division, year);
  const divisionQuery = useDivision(division);

  if (!transactionQuery.isSuccess || !divisionQuery.isSuccess) {
    return <Spinner />;
  }

  return (
    <Stack direction="column" gap={3}>
      {renderHeader && (
        <PageHeader>KIA voor: {divisionQuery.data.Description}</PageHeader>
      )}

      <Stack direction="row" justifyContent={"space-between"} gap={3}>
        <YearSelect />
        <SuggestedKiaTransactions administrationId={division} year={year} />
      </Stack>

      {transactionQuery.data.transactions.length ? (
        <TransactionTable transactions={transactionQuery.data.transactions} />
      ) : (
        <NoData />
      )}
    </Stack>
  );
}
