import { Chip } from '@mui/joy';
import { mapStatusToLabel, TaskStatus } from './utils';

export const StatusChip = (props: { status: number }) => {
  const mapStatusToColor = (status: number) => {
    switch (status) {
      case TaskStatus.NOT_ACTIVE:
        return 'neutral';
      case TaskStatus.IN_PROGRESS:
        return 'primary';
      case TaskStatus.COMPLETED:
        return 'success';
      default:
        return 'neutral';
    }
  };

  return (
    <Chip color={mapStatusToColor(props.status)} sx={{ minWidth: '3rem', textAlign: 'center' }}>
      {mapStatusToLabel(props.status)}
    </Chip>
  );
};
