import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { IconButton } from "@mui/joy";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import { useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import Spinner from "../../components/spinner";
import { useAssignedAdministrations } from "../../hooks/useAssignedAdministrations";
import useDivisions from "../../hooks/useDivisions";

export const DivisionsTable = ({
  onClick,
  userId,
  divisionSearch,
}: {
  onClick: (administration: string) => void;
  userId: string;
  divisionSearch: string;
}) => {
  const divisonsQuery = useDivisions();
  const assignedAdministrationsQuery = useAssignedAdministrations(userId);
  const [loading, setLoading] = useState(false);

  if (divisonsQuery.isLoading || assignedAdministrationsQuery.isLoading) {
    return <Spinner />;
  }

  const assignedAdministrations = assignedAdministrationsQuery.data || [];
  const rows = (divisonsQuery.data || [])
    .filter((item) => {
      return !assignedAdministrations
        .map((item: any) => item.AdministrationID)
        .includes(item.Code.toString());
    })
    .filter((item) =>
      item.Description.toLowerCase().includes(divisionSearch.toLowerCase())
    );

  return (
    <Sheet
      className="OrderTableContainer"
      variant="outlined"
      sx={{
        width: "100%",
        borderRadius: "sm",
        flexShrink: 1,
        overflow: "auto",
        minHeight: 0,
        maxHeight: "80vh",
      }}
    >
      <Table
        stickyHeader
        stickyFooter
        sx={{
          "--TableCell-height": "10px",
          "& tr > *:last-child": {
            textAlign: "right",
            paddingLeft: "1rem",
          },
        }}
      >
        <thead>
          <tr>
            <th>Administratie</th>
            <th>Toekennen</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row: any) => {
            return (
              <tr key={row.id}>
                <td>{row.Description}</td>
                <td>
                  <IconButton
                    color="success"
                    variant="soft"
                    disabled={loading}
                    onClick={async () => {
                      setLoading(true);
                      await onClick(row.Code.toString());
                      setLoading(false);
                    }}
                  >
                    <AddCircleRoundedIcon />
                  </IconButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Sheet>
  );
};
