import Autocomplete from '@mui/joy/Autocomplete';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import { useState } from 'react';
import { useUsers } from '../hooks/useUsers';
import useFilterStore from '../stores/filter';

export const UserSelect = () => {
  const { user, setUser } = useFilterStore();
  const usersQuery = useUsers();
  const [inputValue, setInputValue] = useState('');

  const users = usersQuery.data || [];

  return (
    <FormControl>
      <FormLabel>Gebruiker</FormLabel>
      <Autocomplete
        placeholder="Selecteer een gebruiker"
        sx={{ minWidth: '12rem' }}
        options={users.map((user) => user.id)}
        getOptionLabel={(option) =>
          users
            .find((u) => u.id === option)
            ?.email.split('@')[0]
            .replace('.', ' ') || ''
        }
        value={user}
        onChange={(_, newValue) => {
          if (!newValue) {
            setUser(null);
            return;
          }
          const userId = users.find((u) => u.id === newValue)?.id || '';
          setUser(userId);
        }}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
        isOptionEqualToValue={(option, value) => option === value}
      />
    </FormControl>
  );
};
