import client from '../../../utils/axiosClient';

export type TInsightBalanceOverviewResponse = {
  range: Array<{ year: number; month: number }>;
  rows: {
    administrationId: string;
    administrationName: string;
    triggers: {
      [year: number]: { [month: number]: boolean };
    };
  }[];
};

export type TInsightOverviewParams = {
  monthStart: number;
  monthEnd: number;
  yearStart: number;
  yearEnd: number;
  userID?: string | null;
};

export const getInsightsBalanceOverview = async (params: TInsightOverviewParams) => {
  const response = await client.get('/api/insights/overview/balance', {
    params,
  });
  return response.data as TInsightBalanceOverviewResponse;
};
