import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import dictionaryEnglish from "./en";
import dictionaryDutch from "./nl";
import LanguageDetector from "i18next-browser-languagedetector";

export const defaultNS = "ns1";
export const resources = {
  en: {
    ns1: dictionaryEnglish,
  },
  nl: {
    ns1: dictionaryDutch,
  },
} as const;

i18n.use(initReactI18next).use(LanguageDetector).init({
  fallbackLng: "nl",
  lng: i18n.language,
  defaultNS,
  resources,
});

export default i18n;
