import { Stack } from '@mui/joy';
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Table from '@mui/joy/Table';
import { Pagination } from '@mui/material';
import { useState } from 'react';
import NoData from '../../components/noData';
import Spinner from '../../components/spinner';
import useCompletenessTransactions from '../../hooks/useCompletenessTransactions';
import { AutomatedIcon } from '../completeness-drilldown/table';

const PAGE_SIZE = 10;

type Props = {
  division: any;
  year: number;
  month: number;
  checkId: string;
};

const TransactionTable = (props: Props) => {
  const { division, year, checkId, month } = props;
  const completenessTransactionsQuery = useCompletenessTransactions(division, year, month + 1, checkId);
  const [page, setPage] = useState(0);

  if (!completenessTransactionsQuery.isSuccess) {
    return <Spinner />;
  }

  const rows = completenessTransactionsQuery.data.filter((row: any) => !!row.TransactionID);

  if (!rows.length) {
    return <NoData />;
  }

  const formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
  });

  return (
    <Stack gap={3}>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          borderRadius: 'sm',
          overflow: 'auto',
        }}
      >
        <Table aria-label="basic table" stickyHeader stickyFooter>
          <thead>
            <tr>
              <th>Omschrijving</th>
              <th>Grootboek</th>
              <th>Grootboek beschrijving</th>
              <th>Boekstuknummer</th>
              <th>Factuurnummer</th>
              <th>Journaal</th>
              <th>Bedrag</th>
              <th>Automatisch</th>
            </tr>
          </thead>
          <tbody>
            {rows.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE).map((row) => {
              return (
                <tr key={row.TransactionID}>
                  <td>{row.TransactionDescription}</td>
                  <td>{row.GeneralLedgerCode}</td>
                  <td>{row.GeneralLedgerDescription}</td>
                  <td>{row.entryNumber}</td>
                  <td>{row.invoiceNumber}</td>
                  <td>{row.journalDescription}</td>
                  <td>{formatter.format(row.TransactionAmount)}</td>
                  <td>
                    <AutomatedIcon automatedCount={row.automated ? 1 : 0} transactionCount={1} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Sheet>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Pagination count={Math.ceil(rows.length / PAGE_SIZE)} color="primary" onChange={(_, page) => setPage(page - 1)} page={page + 1} />
      </Box>
    </Stack>
  );
};

export default TransactionTable;
