import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import { Badge, Box, Button } from "@mui/joy";
import Stack from "@mui/joy/Stack";
import { useState } from "react";
import { PageHeader } from "../../components/pageHeader";
import { UserInviteModal } from "./inviteModal";
import { UserModal } from "./modal";
import { DomainInvitesModal } from "./invitesModal";
import { UserTable } from "./userTable";
import useDomainStore from "../../stores/domain";
import useDomainInvites from "../../hooks/useDomainInvites";

export const UserPage = () => {
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [domainInvitesModalOpen, setDomainInvitesModalOpen] = useState(false);
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const { domain } = useDomainStore();
  const domainInvitesQuery = useDomainInvites(domain);

  const domainInvites = domainInvitesQuery.data || [];

  return (
    <Stack direction="column" gap={3}>
      <PageHeader>Gebruikers</PageHeader>
      <Stack justifyContent={"space-between"} direction={"row"}>
        <Box></Box>
        <Stack direction={"row"} gap={1}>
          <Button
            startDecorator={<PersonAddAltRoundedIcon />}
            onClick={() => {
              setInviteModalOpen(true);
            }}
          >
            Uitnodigen
          </Button>
          <Button
            startDecorator={<EmailRoundedIcon />}
            onClick={() => {
              setDomainInvitesModalOpen(true);
            }}
            color="neutral"
          >
            <Badge
              badgeContent={domainInvites.length}
              badgeInset="-8%"
              color="neutral"
            >
              Openstaande uitnodigingen
            </Badge>
          </Button>
        </Stack>
      </Stack>

      <UserModal
        open={userModalOpen}
        setOpen={setUserModalOpen}
        userID={selectedUser}
      />
      <UserInviteModal open={inviteModalOpen} setOpen={setInviteModalOpen} />
      <DomainInvitesModal
        open={domainInvitesModalOpen}
        setOpen={setDomainInvitesModalOpen}
      />
      <UserTable
        onClickUser={(userId: string) => {
          setSelectedUser(userId);
          setUserModalOpen(true);
        }}
      />
    </Stack>
  );
};
