import client from "../../utils/axiosClient";

const setQualityCheckStatus = async (
  divisionCode: string,
  qualityCheckId: string,
  status: boolean
) => {
  const response = await client.post(
    `/api/completeness/checks/${divisionCode}/config/${qualityCheckId}`,
    { status }
  );
  return response.data;
};

export default setQualityCheckStatus;
