import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Table from '@mui/joy/Table';
import { Pagination } from '@mui/material';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate } from 'react-router-dom';
import NoData from '../../components/noData';
import Spinner from '../../components/spinner';
import { SortDirection, ThSort } from '../../components/tables/thSort';
import { useCompletenessV2 } from '../../hooks/useCompletenessV2';
import { useQualityStore } from '../../stores/qualityStore';
import { QualityProgressBar } from './progress';
const PAGE_SIZE = 13;

type Props = {
  yearStart: number;
  yearEnd: number;
  filterComplete: boolean;
  monthStart: number;
  monthEnd: number;
  divisionSearch: string;
  userId: string | null;
  accountID: string | null;
};

enum SortOptions {
  score = 'score',
  name = 'name',
}

const AdministrationTable = (props: Props) => {
  const { yearStart, yearEnd, filterComplete, monthEnd, monthStart, divisionSearch, userId, accountID } = props;
  const [sort, setSort] = useState<SortOptions>(SortOptions.score);
  const [sortDir, setSortDir] = useState<SortDirection>(SortDirection.desc);
  const completenessQuery = useCompletenessV2(yearStart, yearEnd, monthEnd + 1, monthStart + 1, userId, accountID);
  const { page, setPage } = useQualityStore();

  const months: Array<number> = [];
  for (let index = monthStart; index <= monthEnd; index += 1) {
    months.push(index);
  }

  const navigate = useNavigate();
  const onRowClick = (id: string) => {
    navigate({
      pathname: `/administrations/${id}/quality`,
    });
  };

  const rows = useMemo(() => {
    if (!completenessQuery.data) {
      return [];
    }

    const filteredRows = completenessQuery.data.rows.filter((row) => {
      let show = true;
      if (filterComplete && row.rangeScore < 100) {
        show = false;
      }

      if (!row.administrationName?.toLowerCase().includes(divisionSearch.toLowerCase())) {
        show = false;
      }

      return show;
    });

    const orderedRows = filteredRows.sort((item1, item2) => {
      if (sort === SortOptions.score) {
        if (sortDir === SortDirection.asc) {
          return item1.rangeScore - item2.rangeScore;
        }
        if (sortDir === SortDirection.desc) {
          return item2.rangeScore - item1.rangeScore;
        }
      }

      if (sort === SortOptions.name) {
        if (sortDir === SortDirection.asc) {
          return item1.administrationName.localeCompare(item2.administrationName);
        }
        if (sortDir === SortDirection.desc) {
          return item2.administrationName.localeCompare(item1.administrationName);
        }
      }

      return 0;
    });

    return orderedRows;
  }, [completenessQuery.data, completenessQuery.isSuccess, filterComplete, divisionSearch, sort, sortDir]);

  useEffect(() => {
    // When filters change, we should reset the page to 0
    if (rows.length < PAGE_SIZE * page) {
      setPage(0);
    }
  }, [rows]);

  if (!completenessQuery.isSuccess) {
    return <Spinner />;
  }

  if (!rows.length) {
    return <NoData />;
  }

  return (
    <>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
          minHeight: 0,
          maxHeight: '80vh',
        }}
      >
        <Table
          aria-label="basic table"
          stickyHeader
          stickyFooter
          hoverRow
          sx={{
            '--TableCell-height': '3rem',
            '& tr': {
              '&:hover': {
                cursor: 'pointer',
              },
            },
          }}
        >
          <thead>
            <tr>
              <ThSort
                style={{ width: '20%' }}
                label="Administratie"
                setSort={setSort}
                sort={sort}
                sortDir={sortDir}
                setSortDir={setSortDir}
                sortKey={SortOptions.name}
              />
              <ThSort
                style={{ width: '10%' }}
                label="Volledig"
                setSort={setSort}
                sort={sort}
                sortDir={sortDir}
                setSortDir={setSortDir}
                sortKey={SortOptions.score}
              />
              {completenessQuery.data.range.map((yearMonth) => {
                // Get month label in dutch
                const month = new Date(yearMonth.year, yearMonth.month - 1).toLocaleString('nl-NL', { month: 'long' });
                return (
                  <th
                    style={{
                      width: `${70 / completenessQuery.data.range.length}%`,
                    }}
                  >
                    {month}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {rows.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE).map((row) => {
              const now = new Date();
              return (
                <tr
                  key={row.administrationId}
                  onClick={() => {
                    onRowClick(row.administrationId);
                  }}
                >
                  <td>{row.administrationName}</td>
                  <td>
                    <QualityProgressBar progress={row.rangeScore} />
                  </td>
                  {completenessQuery.data.range.map((yearMonth) => {
                    const data = row.scores[yearMonth.year][yearMonth.month];

                    if (isNaN(data)) {
                      return <td>-</td>;
                    }

                    // Disabled if in the future
                    now.setFullYear(yearMonth.year);
                    now.setMonth(yearMonth.month - 1);

                    const disabled = now > new Date();

                    if (data === 100) {
                      let color = 'text-green-500';
                      if (disabled) color = 'text-neutral';
                      return (
                        <td>
                          <CheckCircleRoundedIcon className={clsx('w-5', color)} />
                        </td>
                      );
                    }

                    let color = 'text-yellow-500';
                    if (disabled) color = 'text-neutral';
                    return (
                      <td>
                        <ErrorRoundedIcon className={clsx('w-5', color)} />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Sheet>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Pagination count={Math.ceil(rows.length / PAGE_SIZE)} color="primary" onChange={(_, page) => setPage(page - 1)} page={page + 1} />
      </Box>
    </>
  );
};

export default AdministrationTable;
