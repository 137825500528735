import client from "../../../utils/axiosClient";

export type TBalanceCheck = {
  id: string;
  rgsCode: string;
  lowerBound: number;
  upperBound: number;
  icon: string;
  administrationId: string;
  label: string;
};

export type TInsightBalanceDrilldownResponse = {
  range: Array<{ year: number; month: number }>;
  rows: (TBalanceCheck & {
    triggers: {
      [year: number]: {
        [month: number]: {
          triggered: boolean;
          balance: number;
        };
      };
    };
  })[];
};

export type TInsightBalanceDrilldownParams = {
  monthStart: number;
  monthEnd: number;
  yearStart: number;
  yearEnd: number;
};

export const getInsightBalanceDrilldown = async (
  administrationId: string,
  params: TInsightBalanceDrilldownParams
) => {
  const response = await client.get<TInsightBalanceDrilldownResponse>(
    `/api/insights/balance/${administrationId}`,
    { params }
  );
  return response.data;
};
