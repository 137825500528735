import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { Stack } from "@mui/joy";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CircularProgress from "@mui/joy/CircularProgress";
import Typography from "@mui/joy/Typography";
import { EKenGetallen } from "../..";
import useKenGetal from "../../../../hooks/dataviz/useKenGetal";

export interface IKenGetalCardProps {
  administrationId: string;
  kenGetal: EKenGetallen;
}

export const KenGetalCardComponent = (props: IKenGetalCardProps) => {
  const { administrationId, kenGetal } = props;
  const rgsBalanceQuery = useKenGetal(administrationId, kenGetal);

  let value = "Er is iets mis gegaan";
  if (rgsBalanceQuery.isLoading) {
    value = "loading...";
  }
  if (rgsBalanceQuery.isSuccess) {
    value = rgsBalanceQuery.data.amount.toString();
  }

  return (
    <Card
      variant="outlined"
      color="neutral"
      sx={{
        height: "-webkit-fill-available",
        ":hover": { cursor: "pointer" },
      }}
    >
      <CardContent orientation="horizontal">
        <CircularProgress size="lg" thickness={3} value={100} color="primary">
          <StarRoundedIcon />
        </CircularProgress>
        <CardContent>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            sx={{ display: "relative" }}
          >
            <Typography level="body-md">{kenGetal}</Typography>
          </Stack>
          <Typography level="h2">{value}</Typography>
        </CardContent>
      </CardContent>
    </Card>
  );
};
