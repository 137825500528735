import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import useFilterStore from "../stores/filter";

const options: Array<{ key: number; label: string }> = [
  { key: 1, label: "Januari" },
  { key: 2, label: "Februari" },
  { key: 3, label: "Maart" },
  { key: 4, label: "April" },
  { key: 5, label: "Mei" },
  { key: 6, label: "Juni" },
  { key: 7, label: "Juli" },
  { key: 8, label: "Augustus" },
  { key: 9, label: "September" },
  { key: 10, label: "Oktober" },
  { key: 11, label: "November" },
  { key: 12, label: "December" },
].sort((a, b) => b.key - a.key);

export const PeriodSelect = () => {
  const { period, setPeriod } = useFilterStore();

  return (
    <FormControl>
      <FormLabel>Periode</FormLabel>
      <Select
        sx={{ minWidth: "12rem" }}
        onChange={(_, newValue) => {
          if (!newValue) {
            console.error(`Period ${newValue} not found`);
            return;
          }
          setPeriod(newValue);
        }}
        value={period}
      >
        {options.map((user) => {
          return (
            <Option key={user.key} value={user.key}>
              {user.label}
            </Option>
          );
        })}
      </Select>
    </FormControl>
  );
};
