import * as XLSX from "xlsx";
import { TGLAccountEfficiencyResponse } from "../../../../services/api/getGLAccountEfficiency";

export const exportEfficiencyCompleteness = (
  data: TGLAccountEfficiencyResponse
) => {
  // Create a worksheet from the data
  const worksheet = XLSX.utils.json_to_sheet(data.nonRgsGLAccounts);

  // Create a new workbook and add the worksheet to it
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "GLAccounts");

  // Generate a file and trigger a download
  XLSX.writeFile(workbook, "GLAccounts.xlsx");
};
